import React, {Suspense} from 'react';
import PropTypes from 'prop-types';
import Icon from '@ant-design/icons';

import empty from './empty.jsx';
import AED from './flag.AED.jsx';
import ARS from './flag.ARS.jsx';
import AUD from './flag.AUD.jsx';
import BRL from './flag.BRL.jsx';
import CAD from './flag.CAD.jsx';
import CHF from './flag.CHF.jsx';
import CNY from './flag.CNY.jsx';
import CZK from './flag.CZK.jsx';
import DKK from './flag.DKK.jsx';
import EUR from './flag.EUR.jsx';
import GBP from './flag.GBP.jsx';
import HKD from './flag.HKD.jsx';
import HUF from './flag.HUF.jsx';
import INR from './flag.INR.jsx';
import JPY from './flag.JPY.jsx';
import MXN from './flag.MXN.jsx';
import MYR from './flag.MYR.jsx';
import NOK from './flag.NOK.jsx';
import NZD from './flag.NZD.jsx';
import PHP from './flag.PHP.jsx';
import PLN from './flag.PLN.jsx';
import RNB from './flag.RNB.jsx';
import SEK from './flag.SEK.jsx';
import SGD from './flag.SGD.jsx';
import THB from './flag.THB.jsx';
import USCA from './flag.USCA.jsx';
import USD from './flag.USD.jsx';
import {match} from 'library/helpers/utilities';

const Flag = ({size = 1, country, style}) => {
    const styles = {
        width: size + 'rem',
        height: size + 'rem',
        maxHeight: size + 'rem',
        fontSize: size + 'rem',
        ...style,
    };

    const icon = match(country, {
        AED: () => AED,
        ARS: () => ARS,
        AUD: () => AUD,
        BRL: () => BRL,
        CAD: () => CAD,
        CHF: () => CHF,
        CNY: () => CNY,
        CZK: () => CZK,
        DKK: () => DKK,
        EUR: () => EUR,
        GBP: () => GBP,
        HUF: () => HUF,
        INR: () => INR,
        JPY: () => JPY,
        MXN: () => MXN,
        MYR: () => MYR,
        NOK: () => NOK,
        NZD: () => NZD,
        PHP: () => PHP,
        PLN: () => PLN,
        RNB: () => RNB,
        SEK: () => SEK,
        SGD: () => SGD,
        HKD: () => HKD,
        THB: () => THB,
        USCA: () => USCA,
        USD: () => USD,
        empty: () => empty,
        default: () => empty,
    });

    if (!country || !icon) return '';

    return (
        <Suspense fallback={''}>
            <Icon component={icon} style={styles} />
        </Suspense>
    );
};

Flag.propTypes = {
    country: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.any,
};

export default Flag;
