export const historyActions = {
    PUSH_HISTORY: 'PUSH_HISTORY',
    POP_HISTORY: 'POP_HISTORY',

    pushHistory: (payload) => ({
        type: historyActions.PUSH_HISTORY,
        payload,
    }),
    popHistory: () => ({
        type: historyActions.POP_HISTORY,
    }),
};
