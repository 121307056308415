const checkoutLookupActions = {
  FETCH_CHECKOUT_LOOKUP_DATA_START: 'FETCH_CHECKOUT_LOOKUP_DATA_START',
  FETCH_CHECKOUT_LOOKUP_DATA_SUCCESS: 'FETCH_CHECKOUT_LOOKUP_DATA_SUCCESS',
  FETCH_CHECKOUT_LOOKUP_DATA_ERROR: 'FETCH_CHECKOUT_LOOKUP_DATA_ERROR',
  fetchCheckoutLookupDataStart: (payload) => ({
    type: checkoutLookupActions.FETCH_CHECKOUT_LOOKUP_DATA_START,
    payload,
  }),
  fetchCheckoutLookupDataSuccess: (payload) => {
    return {
      type: checkoutLookupActions.FETCH_CHECKOUT_LOOKUP_DATA_SUCCESS,
      payload,
    };
  },
  fetchCheckoutLookupDataError: (payload) => ({
    type: checkoutLookupActions.FETCH_CHECKOUT_LOOKUP_DATA_ERROR,
    payload,
  }),

  CLEAR_CHECKOUT_LOOKUP_DATA: 'CLEAR_CHECKOUT_LOOKUP_DATA',
  clearCheckoutLookupData: () => ({
    type: checkoutLookupActions.CLEAR_CHECKOUT_LOOKUP_DATA,
  }),

  LOCAL_SET_CHECKOUT_LOOKUP_DATA: 'LOCAL_SET_CHECKOUT_LOOKUP_DATA',
    localSetCheckoutLookupData: (payload) => ({
        type: checkoutLookupActions.LOCAL_SET_CHECKOUT_LOOKUP_DATA,
        payload,
    }),
};

export default checkoutLookupActions;
