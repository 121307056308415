import dayjs from 'dayjs';

import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(weekday);

/**
 * @typedef ExtendedType
 * @property {function(date: any, format: any): Dayjs} parseFormat
 * @property {function(date: Date, ...rest: any): Dayjs} local
 * @property {function(date: any, ...rest: any): Dayjs} utc
 */

/**
 * @type {ExtendedType & DayjsTimezone & Dayjs}
 * @param {string|null} date
 */
export const ggDate = new Proxy(dayjs.tz, {});

ggDate.parseFormat = (date, format) => dayjs.tz(date, format, dayjs.tz()?.$x.$timezone || 'America/New_York');

ggDate.local = (date = undefined, ...rest) => dayjs(date, ...rest);

ggDate.utc = (date = undefined, ...rest) => dayjs.utc(date, ...rest);

ggDate.isValid = (date, format = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]', strict) => {
    if (date === null) return false;

    try {
        ggDate.parseFormat(date, format);
    } catch (e) {
        console.log(e);
        return false;
    }

    return true;
};

export const getExpirationDate = (month, year) => {
    return ggDate()
        .set('year', Number('20' + year))
        .set('month', Number(month) - 1)
        .format('MM/YYYY');
};

export const checkExpired = (date) => {
    const exp_date = ggDate.parseFormat(date, 'MM/YYYY').add(1, 'month');
    const today = ggDate();

    return exp_date.toISOString() < today.toISOString();
};
