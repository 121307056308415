import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'components/UI';
import {FormikConsumer} from 'formik';
import {lang} from 'config/lang';

const ResetButton = (props) => {
    return (
        <FormikConsumer>
            {(formProps) => (
                <Button
                    htmlType="button"
                    type="default"
                    size={props.size}
                    block={props.block}
                    disabled={false}
                    className={props.shadow ? 'btn-shadow' : ''}
                    onClick={(e) => {
                        e.preventDefault();
                        formProps.resetForm();
                    }}
                >
                    {props.children || props.label || lang.button.reset}
                </Button>
            )}
        </FormikConsumer>
    );
};

ResetButton.defaultProps = {
    block: false,
    button: {},
    children: null,
    label: null,
    shadow: false,
    size: 'large',
    style: {},
    type: 'default',
};

ResetButton.propTypes = {
    block: PropTypes.bool,
    button: PropTypes.object,
    children: PropTypes.any,
    label: PropTypes.string,
    shadow: PropTypes.bool,
    size: PropTypes.string,
    style: PropTypes.object,
    type: PropTypes.string,
};

export default ResetButton;
