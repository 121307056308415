import {createSelector} from 'reselect';

const companies = (state) => state.companies;

export const getCreditCardsEnabled = createSelector(
    [companies],
    (companies) => companies.data?.data?.credit_card?.enabled,
);

export const getACHEnabled = createSelector([companies], (companies) => companies.data?.data?.payment_methods?.ach);
export const getCompanyPublicAPIData = createSelector([companies], (companies) => companies.data?.data?.public_api);

export const geCreditInvoiceEnabled = createSelector(
    [companies],
    (companies) => companies.data?.data?.credit_invoice?.enabled,
);

export const getWhitelistData = createSelector([companies], (companies) => {
    const {team_admin, recipient} = companies?.data?.data || {};

    const _generateList = (domains) => domains?.map((domain) => `@${domain}`).join(', ');
    const _emailInDomains = (email, domainList) => domainList?.includes(email?.split('@')[1]);

    const _shapeData = (category) => {
        const allowedDomains = category?.allowed_invite_domains ?? category?.allowed_email_domains ?? [];

        return {
            data: allowedDomains,
            list: _generateList(allowedDomains),
            isValidEmail: (email) => allowedDomains?.length === 0 || _emailInDomains(email, allowedDomains),
        };
    };

    return {
        teamsWhitelist: _shapeData(team_admin),
        recipientsWhitelist: _shapeData(recipient),
    };
});

export const getMaxDenominations = createSelector(
    [companies],
    (companies) => companies?.data?.data?.max_denomination ?? 500,
);

export const getEnabledPrimaryProducts = createSelector(
    [companies],
    (companies) =>
        companies?.data?.all_primary_products?.filter((product) =>
            companies?.data?.data?.primary_product_ids.includes(product.id),
        ) ?? [],
);
