import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil';
import {amplifyState} from 'services/amplify/atoms/amplify';
import {Authenticator} from 'aws-amplify-react';
import {history} from 'redux/store';
import {Auth} from 'aws-amplify';
import {authState, defaultAuthState} from 'services/auth/atoms/auth';
import {matchPath, useHistory} from 'react-router-dom';
import {
    getIntendedUrl,
    goToRoute,
    isLoginAsPath,
    isNeutralRoute,
    isPrivateRoute,
    isPublicRoute,
    isPunchoutPath,
    isValidRoute,
    setIntendedUrl,
} from 'library/helpers/routing';
import {amplifyIsConfigured} from 'services/amplify/selectors';
import {usePrev} from 'hooks/utility/usePrev';
import {useDispatch, useSelector} from 'react-redux';
import appActions from 'redux/app/actions';
import {api, hardRefresh} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';
import useAuth from 'hooks/auth/useAuth';
import {getCompany} from 'redux/selectors';
import {getSessionCache, setSessionCache} from 'library/helpers/localStore';
import {LAST_REFRESH} from 'config/redux';
import {ggDate} from 'library/helpers/date';
import {clearInactivityTimeout, setInactivityTimeout} from 'scripts/utilities';
import noop from 'lodash/noop';
import {routes} from 'config/routes';

//set localstorage with the correct app
const app = window.location.hostname.split('.').shift();
localStorage.setItem('appName', app);

const currentPath = history.pathname;
const defaultState = currentPath === '/sign-up' ? 'signUp' : false;

const Amplifier = (props) => {
    const {children} = props;
    const {location} = useHistory();
    const {pathname} = location;
    const dispatch = useDispatch();
    const configured = useRecoilValue(amplifyIsConfigured);
    const {signUpRequired, authenticated, token, amazonAuthenticated, ssoAuthenticated} = useRecoilValue(authState);
    const setAuthState = useSetRecoilState(authState);
    const [amplify, setAmplifyState] = useRecoilState(amplifyState);
    const prevAuthenticated = usePrev(authenticated);
    const [initialized, setInitialized] = useState(false);
    const resetAmplify = useResetRecoilState(amplifyState);
    const setRecoilState = useSetRecoilState(authState);
    const company = useSelector(getCompany);

    const companyVerificationPending = company?.data?.verified === 0 && company?.data?.pending_verification === 0;

    const {logIntoLaravel} = useAuth();

    /**
     * Setup amplify configuration
     * @returns {Promise<void>}
     */
    const startAmplify = useCallback(async (config) => {
        await Auth.configure(config);
    }, []);

    const setAuth = useCallback(
        (payload) => {
            setAuthState((auth) => {
                return {...auth, ...payload};
            });
        },
        [setAuthState],
    );

    const setAmplify = useCallback(
        (payload) => {
            setAmplifyState((amplify) => {
                return {...amplify, ...payload};
            });
        },
        [setAmplifyState],
    );

    /**
     * Checks to see if valid Laravel session exists
     * @returns {Promise<boolean>}
     */
    const checkAuth = useCallback(
        async () =>
            await api
                .get(apiEndpoints.auth.check)
                .then(() => true)
                .catch(() => {
                    setRecoilState(defaultAuthState);

                    // if we get here then we are not logged in. so we'll go to sign in
                    if (!isPublicRoute(location.pathname)) {
                        // first handle intended. If this is a real route we'll set intended
                        if (isPrivateRoute(location.pathname) || isNeutralRoute(location.pathname)) {
                            setIntendedUrl(location);
                        }

                        if (isNeutralRoute(location.pathname)) {
                            return false;
                        }

                        // push us over to sign-in
                        goToRoute(routes.public.paths.SIGN_IN);
                    }

                    return false;
                }),

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    /**
     * Validates cognito session and laravel session
     * @type {function(): Promise<boolean|undefined>}
     */
    const handleAuthentication = useCallback(async () => {
        // do not run under these conditions
        if (authenticated && token) return true;

        // check cognito authentication
        await Auth.currentSession()
            .then(async (sess) => {
                // cognito is logged in so get the token
                let token = sess.idToken.jwtToken;
                await logIntoLaravel(token).then(() => {
                    const lastRefresh = getSessionCache(LAST_REFRESH);
                    if (!lastRefresh) {
                        setSessionCache(LAST_REFRESH, ggDate());
                        hardRefresh();
                    } else {
                        dispatch(appActions.initialize());
                    }
                });
            })
            .catch(() => {
                setAuth({
                    token: null,
                    authenticated: false,
                    amazonAuthenticated: null,
                });
                // cognito not logged in
                if (isPrivateRoute(pathname)) {
                    goToRoute(routes.public.paths.SIGN_IN);
                }
            })
            .finally(() => {
                setAuth({
                    authenticating: false,
                });
                setInitialized(true);
            });
    }, [dispatch, logIntoLaravel, token, setInitialized, authenticated, setAuth, pathname]);

    const init = useCallback(() => {
        setInitialized(true);

        checkAuth()
            .then((check) => {
                if (check) {
                    setAuth({authenticated: true});

                    // Don't run initialize if we are coming through LoginAs/Punchout
                    if (!isLoginAsPath(pathname) && !isPunchoutPath(pathname)) dispatch(appActions.initialize());
                }

                startAmplify(amplify.config).then(() => {
                    setAmplify((state) => {
                        return {
                            ...state,
                            configured: true,
                        };
                    });
                });
            })
            .catch(noop);
    }, [checkAuth, startAmplify, amplify.config, dispatch, pathname, setAmplify, setAuth]);

    const prevClientId = usePrev(amplify.config.userPoolWebClientId);
    const shouldAmplifyStart = useCallback(() => {
        if (Object.keys(amplify).length === 0) {
            resetAmplify();
            return;
        }

        if (prevClientId && prevClientId !== amplify.config.userPoolWebClientId) {
            startAmplify(amplify.config).then(() => console.info('reconfigured'));
        }

        // eslint-disable-next-line
    }, [checkAuth, amplify, configured, prevClientId, startAmplify, resetAmplify]);

    const ignoreSignUpRequiredRoutes = useMemo(
        () =>
            ![
                routes.private.paths.FINISH_COMPANY_SIGNUP,
                routes.neutral.paths.LOGOUT,
                routes.public.paths.SIGN_IN,
            ].includes(pathname),
        [pathname],
    );

    // handle redirects based on authentication status
    const handleRedirects = useCallback(() => {
        const pathname = location.pathname;

        if (pathname === '/logout') return;

        // Check this first always
        if ((signUpRequired || companyVerificationPending) && authenticated && ignoreSignUpRequiredRoutes) {
            goToRoute(routes.private.paths.FINISH_COMPANY_SIGNUP);
            return '';
        }

        if (matchPath(pathname, {path: routes.neutral.paths.PROCUREMENT_SESSION_REDIRECT})) {
            return '';
        }

        if (matchPath(pathname, {path: routes.neutral.paths.RETURN_TO_PROCUREMENT_REDIRECT})) {
            return '';
        }

        // we do not require any more signup, but we're the hat page. let's get out of here scooby
        if (
            authenticated &&
            !signUpRequired &&
            !companyVerificationPending &&
            pathname === routes.private.paths.FINISH_COMPANY_SIGNUP
        ) {
            const intended = getIntendedUrl();

            goToRoute(
                intended === routes.private.paths.FINISH_COMPANY_SIGNUP ? routes.private.paths.DASHBOARD : intended,
                {
                    from: pathname,
                },
            );
            return;
        }

        // if an unauthenticated person tried to go to finish signup
        if (!authenticated && pathname === routes.private.paths.FINISH_COMPANY_SIGNUP) {
            goToRoute(routes.public.paths.SIGN_IN);
            return;
        }

        // if sso successfully logged in and we are not at a private route
        if (ssoAuthenticated && !isPrivateRoute(pathname)) {
            goToRoute(getIntendedUrl(), {from: pathname});
            return;
        }

        // if this is a neutral route
        if (isNeutralRoute(pathname)) {
            // setIntendedUrl(pathname);
            setIntendedUrl(location);
        }

        // if we are authenticated and at the home page
        if (!authenticated && pathname === '/') {
            goToRoute(routes.public.paths.SIGN_IN);
        }

        // if the route doesn't exist
        if (!isValidRoute(pathname) && pathname !== '/') {
            goToRoute(routes.public.paths.PAGE_404);
        }

        // if we were previously NOT authenticate but now ARE authenticated
        if (prevAuthenticated !== null && authenticated === null) {
            goToRoute(routes.public.paths.SIGN_IN);
        }

        // if we are authenticated but not initialized
        if (authenticated !== null && !initialized) {
            setInitialized(true);
        }

        // if we are authenticated and this is a public route
        if (authenticated && isPublicRoute(pathname) && pathname !== '/404' && pathname !== '/500') {
            goToRoute(getIntendedUrl(), {from: pathname});
        }

        // we are not authenticated and at a private route
        if (!authenticated && isPrivateRoute(pathname)) {
            // setIntendedUrl(pathname);
            setIntendedUrl(location);
            goToRoute(routes.public.paths.SIGN_IN);
        }
    }, [
        location,
        authenticated,
        companyVerificationPending,
        ignoreSignUpRequiredRoutes,
        signUpRequired,
        ssoAuthenticated,
        prevAuthenticated,
        initialized,
    ]);

    useEffect(() => {
        if (!authenticated) {
            clearInactivityTimeout();

            return;
        }

        setInactivityTimeout();
    }, [authenticated]);

    useEffect(() => {
        if (!initialized) {
            init();
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        shouldAmplifyStart();
    }, [amplify, shouldAmplifyStart]);

    useEffect(() => {
        if (configured && !authenticated) handleAuthentication();
    }, [configured, authenticated, handleAuthentication]);

    useEffect(() => {
        if (amazonAuthenticated !== null) handleAuthentication();
    }, [authenticated, amazonAuthenticated, handleAuthentication]);

    useEffect(() => {
        if (authenticated !== null) handleRedirects();
    }, [authenticated, handleRedirects]);

    return (
        <Authenticator amplifyConfig={amplify.config} hideDefault={true} authState={defaultState}>
            {children}
        </Authenticator>
    );
};

Amplifier.propTypes = {
    children: PropTypes.array.isRequired,
};

export default Amplifier;
