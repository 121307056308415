import PropTypes from 'prop-types';
import React from 'react';
import {useField} from 'formik';
import {FormItem} from 'components/FormikForm';
import {InputNumber} from 'components/UI';

const FormInputNumber = (props) => {
    // eslint-disable-next-line no-unused-vars
    const [field, _, helpers] = useField(props.field.name);
    const disabled = props.form.isSubmitting || props.isSubmitting ? true : props.disabled || false;

    const handleChange = (value) => {
        helpers.setValue(value);
        if (typeof props.onChange === 'function') props.onChange(value);
    };

    const inputProps = {...props};
    delete inputProps.noLoading;
    delete inputProps.wrapperCol;

    return (
        <FormItem {...props} skelType="input">
            <InputNumber
                {...field}
                {...inputProps}
                {...props.ant}
                disabled={disabled}
                onChange={handleChange}
                size={props.size}
            />
        </FormItem>
    );
};

FormInputNumber.defaultProps = {
    noLoading: false,
    size: 'large',
};

FormInputNumber.propTypes = {
    ant: PropTypes.object,
    disabled: PropTypes.bool,
    xchange: PropTypes.object,
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool,
    noLoading: PropTypes.bool,
    onChange: PropTypes.func,
    size: PropTypes.string,
};

export default FormInputNumber;
