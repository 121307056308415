const creditCardsActions = {
    FETCH_CREDIT_CARDS_DATA_START: 'FETCH_CREDIT_CARDS_DATA_START',
    FETCH_CREDIT_CARDS_DATA_SUCCESS: 'FETCH_CREDIT_CARDS_DATA_SUCCESS',
    FETCH_CREDIT_CARDS_DATA_FAILURE: 'FETCH_CREDIT_CARDS_DATA_FAILURE',
    fetchCreditCardsDataStart: (payload) => ({
        type: creditCardsActions.FETCH_CREDIT_CARDS_DATA_START,
        payload,
    }),
    fetchCreditCardsDataSuccess: (payload) => ({
        type: creditCardsActions.FETCH_CREDIT_CARDS_DATA_SUCCESS,
        payload,
    }),
    fetchCreditCardsDataFailure: (error) => ({
        type: creditCardsActions.FETCH_CREDIT_CARDS_DATA_FAILURE,
        payload: error,
    }),

    UPDATE_DEFAULT_CREDIT_CARDS_START: 'UPDATE_DEFAULT_PAYMENT_METHODS_START',
    UPDATE_DEFAULT_CREDIT_CARDS_SUCCESS: 'UPDATE_DEFAULT_PAYMENT_METHODS_SUCCESS',
    UPDATE_DEFAULT_CREDIT_CARDS_FAILURE: 'UPDATE_DEFAULT_PAYMENT_METHODS_FAILURE',
    updateDefaultCreditCardsDataStart: (payload) => ({
        type: creditCardsActions.UPDATE_DEFAULT_CREDIT_CARDS_START,
        payload,
    }),
    updateDefaultCreditCardsDataSuccess: () => ({
        type: creditCardsActions.UPDATE_DEFAULT_CREDIT_CARDS_SUCCESS,
    }),
    updateDefaultCreditCardsDataFailure: (error) => ({
        type: creditCardsActions.UPDATE_DEFAULT_CREDIT_CARDS_FAILURE,
        payload: error,
    }),

    UPDATE_CREDIT_CARD_DISPLAY_NAME_START: 'UPDATE_CREDIT_CARD_DISPLAY_NAME_START',
    UPDATE_CREDIT_CARD_DISPLAY_NAME_SUCCESS: 'UPDATE_CREDIT_CARD_DISPLAY_NAME_SUCCESS',
    UPDATE_CREDIT_CARD_DISPLAY_NAME_FAILURE: 'UPDATE_CREDIT_CARD_DISPLAY_NAME_FAILURE',
    updateCreditCardDisplayNameStart: (payload) => ({
        type: creditCardsActions.UPDATE_CREDIT_CARD_DISPLAY_NAME_START,
        payload,
    }),
    updateCreditCardDisplayNameSuccess: () => ({
        type: creditCardsActions.UPDATE_DEFAULT_CREDIT_CARDS_SUCCESS,
    }),
    updateCreditCardDisplayNameFailure: (error) => ({
        type: creditCardsActions.UPDATE_DEFAULT_CREDIT_CARDS_FAILURE,
        payload: error,
    }),

    CREATE_CREDIT_CARDS_START: 'CREATE_CREDIT_CARDS_START',
    CREATE_CREDIT_CARDS_SUCCESS: 'CREATE_CREDIT_CARDS_SUCCESS',
    CREATE_CREDIT_CARDS_FAILURE: 'CREATE_CREDIT_CARDS_FAILURE',
    createCreditCardsDataStart: (payload) => ({
        type: creditCardsActions.CREATE_CREDIT_CARDS_START,
        payload,
    }),
    createCreditCardsDataSuccess: (payload) => ({
        type: creditCardsActions.CREATE_CREDIT_CARDS_SUCCESS,
        payload,
    }),
    createCreditCardsDataFailure: (error) => ({
        type: creditCardsActions.CREATE_CREDIT_CARDS_FAILURE,
        payload: error,
    }),

    DELETE_CREDIT_CARDS_START: 'DELETE_CREDIT_CARDS_START',
    DELETE_CREDIT_CARDS_SUCCESS: 'DELETE_CREDIT_CARDS_SUCCESS',
    DELETE_CREDIT_CARDS_FAILURE: 'DELETE_CREDIT_CARDS_FAILURE',
    deleteCreditCardsDataStart: (payload) => ({
        type: creditCardsActions.DELETE_CREDIT_CARDS_START,
        payload,
    }),
    deleteCreditCardsDataSuccess: (payload) => ({
        type: creditCardsActions.DELETE_CREDIT_CARDS_SUCCESS,
        payload,
    }),
    deleteCreditCardsDataFailure: (error) => ({
        type: creditCardsActions.DELETE_CREDIT_CARDS_FAILURE,
        payload: error,
    }),

    CLEAR_NEW_CARD: 'CLEAR_NEW_CARD',
    clearNewCard: (payload) => ({
        type: creditCardsActions.CLEAR_NEW_CARD,
        payload,
    }),
};

export default creditCardsActions;
