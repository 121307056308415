const adjustmentsActions = {
    FETCH_ADJUSTMENTS_START: 'FETCH_ADJUSTMENTS_START',
    FETCH_ADJUSTMENTS_SUCCESS: 'FETCH_ADJUSTMENTS_SUCCESS',
    FETCH_ADJUSTMENTS_FAILURE: 'FETCH_ADJUSTMENTS_FAILURE',

    fetchAdjustmentsStart: (payload) => ({
        type: adjustmentsActions.FETCH_ADJUSTMENTS_START,
        payload,
    }),

    fetchAdjustmentsSuccess: (payload) => ({
        type: adjustmentsActions.FETCH_ADJUSTMENTS_SUCCESS,
        payload,
    }),

    fetchAdjustmentsFailure: (error) => ({
        type: adjustmentsActions.FETCH_ADJUSTMENTS_FAILURE,
        payload: error,
    }),

    RESET_ADJUSTMENTS_START: 'RESET_ADJUSTMENTS_START',

    resetAdjustments: () => ({
        type: adjustmentsActions.RESET_ADJUSTMENTS_START,
    }),
};

export default adjustmentsActions;
