import React from 'react';
import {MainNavHorizontal, MainNavMobile, MainNavSettings, TeamSwitcher} from 'components/Layout';
import {Breakpoint, Layout, Col, Row} from 'components/UI';
import PropTypes from 'prop-types';

const {Header} = Layout;

const MainNav = () => {
    return (
        <Header>
            <Row>
                <Col flex="auto">
                    <Breakpoint xs={false} sm={false} md={false} lg={false} inverse={<MainNavMobile />}>
                        <MainNavHorizontal />
                    </Breakpoint>
                </Col>

                <Col flex="none">
                    <MainNavSettings />
                </Col>
            </Row>
            <TeamSwitcher />
        </Header>
    );
};

MainNav.propTypes = {
    buttonStyles: PropTypes.any,
    buttonText: PropTypes.any,
    label: PropTypes.any,
    to: PropTypes.any,
};

export default MainNav;
