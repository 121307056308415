import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';
import createRootReducer from './root-reducer';
import rootSaga from './root-saga';
import {persistStore} from 'redux-persist';
import {getProcessEnvValue} from 'library/helpers/process';
import {SYSTEM_ENVIRONMENT} from 'config/constants';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware, routerMiddleware(history)];

const bindMiddleware = (middleware) => {
    if (
        !(
            getProcessEnvValue('REACT_APP_ENV') === SYSTEM_ENVIRONMENT.PRODUCTION ||
            getProcessEnvValue('REACT_APP_ENV') === SYSTEM_ENVIRONMENT.SANDBOX
        )
    ) {
        const {composeWithDevTools} = require('redux-devtools-extension');

        // We can use this to do redux stack traces if we need it
        const options = getProcessEnvValue('REACT_APP_DEBUG_TRACE') ? {trace: true, traceLimit: 25} : {};
        const composeEnhancers = composeWithDevTools(options);
        return composeEnhancers(applyMiddleware(...middleware));
    }

    return applyMiddleware(...middleware);
};

const store = createStore(createRootReducer(history), bindMiddleware(middlewares));

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export {store, persistor};
