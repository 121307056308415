import React from 'react';
import PropTypes from 'prop-types';
import {Tag as AntTag} from 'antd';
import {Space, FontAwesome, Popover} from 'components/UI';
import {lang} from 'config/lang';
import {match} from 'library/helpers/utilities';

const tagIndex = {
    api: {
        color: 'processing',
        icon: 'code',
        text: 'API',
    },
    discount: {
        color: 'geekblue',
        icon: 'badge-percent',
        text: lang.discount,
    },
    smart: {
        color: 'warning',
        icon: 'circle-bolt',
        text: lang.smart.smart,
    },
};

const TagExplainer = ({explainer, explainerButtonStyle, help = false, children}) => {
    return (
        <Popover
            content={explainer}
            trigger="hover"
            placement="right"
            overlayStyle={{width: '340px'}}
            help={help}
            buttonStyle={explainerButtonStyle}
        >
            {children}
        </Popover>
    );
};

TagExplainer.propTypes = {
    children: PropTypes.any,
    explainer: PropTypes.any,
    explainerButtonStyle: PropTypes.any,
    help: PropTypes.bool,
};

const TagTemplate = ({color, icon, text, ...props}) => {
    return (
        <AntTag {...props} color={color}>
            <Space size={6}>
                <FontAwesome icon={icon} />
                {text}
            </Space>
        </AntTag>
    );
};

TagTemplate.propTypes = {
    color: PropTypes.string,
    icon: PropTypes.string,
    props: PropTypes.any,
    text: PropTypes.string,
};

const Tag = ({
    api = false,
    discountContext,
    smart = false,
    explainer,
    explainerButtonStyle = {},
    help = false,
    ...restProps
}) => {
    const hasExplainer = !!explainer;

    const templatedTagType = match(true, {
        [api]: () => 'api',
        [smart]: () => 'smart',
        [discountContext]: () => 'discount',
        default: () => null,
    });

    switch (true) {
        case discountContext:
            return (
                <>
                    <TagTemplate {...tagIndex['discount']} {...restProps} />
                    <TagExplainer explainer={lang.discount_explainer.replace(':CONTEXT', discountContext)} />
                </>
            );

        case hasExplainer && templatedTagType && help:
            return (
                <>
                    <TagTemplate {...tagIndex[smart ? 'smart' : 'api']} {...restProps} />
                    <TagExplainer explainer={explainer} explainerButtonStyle={explainerButtonStyle} help={help} />
                </>
            );
        case hasExplainer && templatedTagType:
            return (
                <TagExplainer explainer={explainer} explainerButtonStyle={explainerButtonStyle} help={help}>
                    <TagTemplate {...tagIndex[smart ? 'smart' : 'api']} {...restProps} />
                </TagExplainer>
            );
        case hasExplainer && help:
            return (
                <>
                    <AntTag {...restProps} />
                    <TagExplainer explainer={explainer} explainerButtonStyle={explainerButtonStyle} help={help} />
                </>
            );
        case hasExplainer:
            return (
                <TagExplainer explainer={explainer} explainerButtonStyle={explainerButtonStyle}>
                    <AntTag {...restProps} />
                </TagExplainer>
            );
        case !!templatedTagType:
            return <TagTemplate {...tagIndex[smart ? 'smart' : 'api']} {...restProps} />;
        default:
            return <AntTag {...restProps} />;
    }
};

Tag.propTypes = {
    api: PropTypes.bool,
    discountContext: PropTypes.string,
    smart: PropTypes.bool,
    explainer: PropTypes.any,
    explainerButtonStyle: PropTypes.any,
    help: PropTypes.bool,
};

export default Tag;
