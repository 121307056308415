import {UPDATE_CHECKOUT} from 'redux/checkout/actions';
import {checkoutState} from 'redux/checkout/state';

const checkoutReducer = (state = checkoutState, action) => {
  const {type, payload} = action;
  switch (type) {
    case UPDATE_CHECKOUT:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default checkoutReducer;
