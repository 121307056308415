import React from 'react';

const icon = () => (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <circle cx="256" cy="256" fill="#f0f0f0" r="256" />
        <g fill="#d80027">
            <path d="m512 256c0-101.494-59.065-189.19-144.696-230.598v461.195c85.631-41.407 144.696-129.103 144.696-230.597z" />
            <path d="m0 256c0 101.494 59.065 189.19 144.696 230.598v-461.196c-85.631 41.408-144.696 129.104-144.696 230.598z" />
            <path d="m300.522 289.391 44.521-22.261-22.26-11.13v-22.261l-44.522 22.261 22.261-44.522h-22.261l-22.261-33.391-22.261 33.391h-22.261l22.261 44.522-44.522-22.261v22.261l-22.26 11.13 44.521 22.261-11.13 22.261h44.522v33.391h22.26v-33.391h44.522z" />
        </g>
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
    </svg>
);

export default icon;
