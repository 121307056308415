export const fetchMessagesStart = () => ({
  type: savedMessagesActions.FETCH_SAVED_MESSAGES_START,
});
export const saveMessage = (payload) => ({
  type: savedMessagesActions.SAVE_SAVED_MESSAGES_START,
  payload,
});

const savedMessagesActions = {
  FETCH_SAVED_MESSAGES_START: 'FETCH_SAVED_MESSAGES_START',
  FETCH_SAVED_MESSAGES_SUCCESS: 'FETCH_SAVED_MESSAGES_SUCCESS',
  FETCH_SAVED_MESSAGES_ERROR: 'FETCH_SAVED_MESSAGES_ERROR',
  fetchStart: () => ({
    type: savedMessagesActions.FETCH_SAVED_MESSAGES_START,
  }),
  fetchSuccess: (payload) => {
    return {
      type: savedMessagesActions.FETCH_SAVED_MESSAGES_SUCCESS,
      payload,
    };
  },
  fetchError: (error) => ({
    type: savedMessagesActions.FETCH_SAVED_MESSAGES_ERROR,
    payload: error,
  }),

  SAVE_SAVED_MESSAGES_START: 'SAVE_SAVED_MESSAGES_START',
  SAVE_SAVED_MESSAGES_SUCCESS: 'SAVE_SAVED_MESSAGES_SUCCESS',
  SAVE_SAVED_MESSAGES_ERROR: 'SAVE_SAVED_MESSAGES_ERROR',
  saveStart: (payload) => ({
    type: savedMessagesActions.SAVE_SAVED_MESSAGES_START,
    payload,
  }),
  saveSuccess: () => {
    return {
      type: savedMessagesActions.SAVE_SAVED_MESSAGES_SUCCESS,
    };
  },
  saveError: (error) => ({
    type: savedMessagesActions.SAVE_SAVED_MESSAGES_ERROR,
    payload: error,
  }),

  DELETE_SAVED_MESSAGES_START: 'DELETE_SAVED_MESSAGES_START',
  DELETE_SAVED_MESSAGES_SUCCESS: 'DELETE_SAVED_MESSAGES_SUCCESS',
  DELETE_SAVED_MESSAGES_ERROR: 'DELETE_SAVED_MESSAGES_ERROR',
  deleteStart: (payload) => ({
    type: savedMessagesActions.DELETE_SAVED_MESSAGES_START,
    payload,
  }),
  deleteSuccess: () => {
    return {
      type: savedMessagesActions.DELETE_SAVED_MESSAGES_SUCCESS,
    };
  },
  deleteError: (error) => ({
    type: savedMessagesActions.DELETE_SAVED_MESSAGES_ERROR,
    payload: error,
  }),
};

export default savedMessagesActions;
