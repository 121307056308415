import PropTypes from 'prop-types';
import React from 'react';
import {heroImages} from 'config/auth';
import {Col, Row, Typography, Layout, Card} from 'components/UI';
import {AuthNav} from 'components/Layout';

const {Content} = Layout;
const {Paragraph} = Typography;

const randomImg = heroImages[Math.floor(Math.random() * heroImages.length)];

const AuthLayout = ({
    noShadow = false,
    content,
    extraContent,
    label,
    buttonText,
    mobileButtonText,
    linkTo,
    backgroundImage,
}) => {
    return (
        <Layout className="auth-layout">
            <AuthNav label={label} buttonText={buttonText} mobileButtonText={mobileButtonText} linkTo={linkTo} />
            <Content className="auth-content-wrapper">
                <Row>
                    <Col xl={8} md={11} xs={0} className="auth-side-panel-wrapper">
                        <div
                            className="auth-side-panel"
                            style={{
                                backgroundImage: `url(${backgroundImage ? backgroundImage : randomImg.image})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center center',
                                backgroundSize: 'cover',
                            }}
                        >
                            {!backgroundImage && (
                                <Row align="middle" gutter={[24, 16]}>
                                    <Col flex="none">
                                        <img src={randomImg.logo} alt={`${randomImg.brand} gift card`} />
                                    </Col>

                                    <Col flex="auto">
                                        <Paragraph strong style={{marginBottom: 0}}>
                                            {randomImg.name}
                                        </Paragraph>
                                        <Paragraph style={{marginBottom: 0}}>
                                            Chose a {`${randomImg.denom} ${randomImg.brand}`} gift card with{' '}
                                            {randomImg.pronoun} Giftogram
                                        </Paragraph>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </Col>
                    <Col flex xl={16} md={13} xs={24} className="auth-content-wrapper">
                        <Card className="auth-content" title={false} size="large" shadow={!noShadow} bordered={false}>
                            {content}
                        </Card>
                        {extraContent && (
                            <Card
                                className="auth-content"
                                title={false}
                                size="large"
                                shadow={!noShadow}
                                bordered={false}
                            >
                                {extraContent}
                            </Card>
                        )}
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
};

AuthLayout.propTypes = {
    backgroundImage: PropTypes.string,
    buttonText: PropTypes.string,
    content: PropTypes.any.isRequired,
    extraContent: PropTypes.any,
    label: PropTypes.string,
    linkTo: PropTypes.string,
    mobileButtonText: PropTypes.string,
    noShadow: PropTypes.bool,
};

export default AuthLayout;
