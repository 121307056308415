const orderActions = {
    FETCH_ORDER_DATA_START: 'FETCH_ORDER_DATA_START',
    FETCH_ORDER_DATA_FRESH: 'FETCH_ORDER_DATA_FRESH',
    FETCH_ORDER_DATA_SUCCESS: 'FETCH_ORDER_DATA_SUCCESS',
    FETCH_ORDER_DATA_FAILURE: 'FETCH_ORDER_DATA_FAILURE',
    fetchOrderDataStart: (payload) => ({
        type: orderActions.FETCH_ORDER_DATA_START,
        payload,
    }),

    fetchOrderDataFresh: (payload) => ({
        type: orderActions.FETCH_ORDER_DATA_FRESH,
        payload,
    }),

    fetchOrderDataSuccess: (payload) => ({
        type: orderActions.FETCH_ORDER_DATA_SUCCESS,
        payload,
    }),

    fetchOrderDataFailure: (error) => ({
        type: orderActions.FETCH_ORDER_DATA_FAILURE,
        payload: error,
    }),

    CLEAR_ORDER_DATA: 'CLEAR_ORDER_DATA',
    clearOrderData: () => ({
        type: orderActions.CLEAR_ORDER_DATA,
    }),

    REORDER_PHYSICAL_DATA_START: 'REORDER_PHYSICAL_DATA_START',
    REORDER_PHYSICAL_DATA_SUCCESS: 'REORDER_PHYSICAL_DATA_SUCCESS',
    REORDER_PHYSICAL_DATA_FAILURE: 'REORDER_PHYSICAL_DATA_FAILURE',

    reorderPhysicalStart: (payload) => ({
        type: orderActions.REORDER_PHYSICAL_DATA_START,
        payload,
    }),

    reorderPhysicalSuccess: (payload) => ({
        type: orderActions.REORDER_PHYSICAL_DATA_SUCCESS,
        payload,
    }),

    reorderPhysicalFailure: (error) => ({
        type: orderActions.REORDER_PHYSICAL_DATA_FAILURE,
        payload: error,
    }),

    REORDER_DIGITAL_DATA_START: 'REORDER_DIGITAL_DATA_START',
    REORDER_DIGITAL_DATA_SUCCESS: 'REORDER_DIGITAL_DATA_SUCCESS',
    REORDER_DIGITAL_DATA_FAILURE: 'REORDER_DIGITAL_DATA_FAILURE',

    reorderDigitalStart: (payload) => ({
        type: orderActions.REORDER_DIGITAL_DATA_START,
        payload,
    }),

    reorderDigitalSuccess: (payload) => ({
        type: orderActions.REORDER_DIGITAL_DATA_SUCCESS,
        payload,
    }),

    reorderDigitalFailure: (error) => ({
        type: orderActions.REORDER_DIGITAL_DATA_FAILURE,
        payload: error,
    }),

    CANCEL_PHYSICAL_DATA_START: 'CANCEL_PHYSICAL_DATA_START',
    CANCEL_PHYSICAL_DATA_SUCCESS: 'CANCEL_PHYSICAL_DATA_SUCCESS',
    CANCEL_PHYSICAL_DATA_FAILURE: 'CANCEL_PHYSICAL_DATA_FAILURE',

    cancelPhysicalStart: (payload) => ({
        type: orderActions.CANCEL_PHYSICAL_DATA_START,
        payload,
    }),

    cancelPhysicalSuccess: (success) => ({
        type: orderActions.CANCEL_PHYSICAL_DATA_SUCCESS,
        payload: success,
    }),

    cancelPhysicalFailure: (error) => ({
        type: orderActions.CANCEL_PHYSICAL_DATA_FAILURE,
        payload: error,
    }),

    CANCEL_DIGITAL_DATA_START: 'CANCEL_DIGITAL_DATA_START',
    CANCEL_DIGITAL_DATA_SUCCESS: 'CANCEL_DIGITAL_DATA_SUCCESS',
    CANCEL_DIGITAL_DATA_FAILURE: 'CANCEL_DIGITAL_DATA_FAILURE',

    cancelDigitalStart: (payload) => ({
        type: orderActions.CANCEL_DIGITAL_DATA_START,
        payload,
    }),

    cancelDigitalSuccess: (success) => ({
        type: orderActions.CANCEL_DIGITAL_DATA_SUCCESS,
        payload: success,
    }),

    cancelDigitalFailure: (error) => ({
        type: orderActions.CANCEL_DIGITAL_DATA_FAILURE,
        payload: error,
    }),

    RESET_ORDERS_ACTION: 'RESET_ORDERS_ACTION',

    resetOrdersAction: () => ({
        type: orderActions.RESET_ORDERS_ACTION,
    }),

    GET_ORDER_RECIPIENT: 'GET_ORDER_RECIPIENT',

    getOrderRecipient: (payload) => ({
        type: orderActions.GET_ORDER_RECIPIENT,
        payload,
    }),
};

export default orderActions;
