import recipientsActions from './actions';

import teamActions from 'redux/team/actions';
import {LOGOUT} from 'redux/auth/auth.types';

const initialState = {
    data: null,
    deleting: false,
    loading: false,
    error: null,
    loaded: false,
    deleted: false,
    query: {
        page: '1',
        per_page: '25',
        sort: 'last_name',
        sort_direction: 'asc',
    },
};

const recipientsReducer = (state = initialState, action) => {
    switch (action.type) {
        case recipientsActions.FETCH_RECIPIENTS_DATA_START:
            return {
                ...state,
                query: action.payload,
                loading: true,
                error: null,
                loaded: false,
            };

        case recipientsActions.FETCH_RECIPIENTS_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null,
                loaded: true,
            };

        case recipientsActions.FETCH_RECIPIENTS_DATA_FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload,
                loaded: false,
            };

        case recipientsActions.REFETCH_RECIPIENTS_DATA_START:
            return {
                ...state,
            };

        case recipientsActions.REFETCH_RECIPIENTS_DATA_SUCCESS:
            return {
                ...state,
                refetchData: action.payload,
            };

        case recipientsActions.REFETCH_RECIPIENTS_DATA_FAILURE:
            return {
                ...state,
            };

        case recipientsActions.TOGGLE_RECIPIENTS_STATUS_START:
            return {
                ...state,
                payload: action.payload,
                adding: true,
                error: null,
            };

        case recipientsActions.TOGGLE_RECIPIENTS_STATUS_SUCCESS:
            delete state.payload;

            return {
                ...state,
                adding: false,
                error: null,
            };

        case recipientsActions.TOGGLE_RECIPIENTS_STATUS_FAILURE:
            delete state.payload;
            return {
                ...state,
                adding: false,
                error: action.payload,
            };

        case recipientsActions.DELETE_INACTIVE_RECIPIENTS_START:
            return {
                ...state,
                payload: action.payload,
                deleting: true,
                deleted: false,
                error: null,
            };

        case recipientsActions.DELETE_INACTIVE_RECIPIENTS_SUCCESS:
            return {
                ...state,
                deleting: false,
                deleted: true,
                error: null,
            };

        case recipientsActions.DELETE_INACTIVE_RECIPIENTS_FAILURE:
            return {
                ...state,
                deleting: false,
                deleted: false,
                error: action.payload,
            };

        case LOGOUT:
        case teamActions.CHANGE_TEAM_SUCCESS:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};

export default recipientsReducer;
