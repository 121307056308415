import React, {useEffect} from 'react';
import {usePrev} from './usePrev';
import {Alert, message} from 'components/UI';
import isEqual from 'react-fast-compare';
import capitalize from 'lodash/capitalize';

const useReaction = ({value, condition, duration = 5, type, content, renderCustomError, react = true, callback}) => {
    const prevCondition = usePrev(condition);
    const prevValue = usePrev(value);

    useEffect(() => {
        if (!condition) return;
        if (isEqual(prevValue, value) && condition === prevCondition) return;

        if (callback) callback();

        if (react) message[type](typeof content === 'string' ? content : capitalize(type), duration);
    }, [callback, condition, content, duration, prevCondition, prevValue, react, type, value]);

    return {
        alertErrorElement: condition && type && <Alert type={type} message={content ?? 'An error has occurred.'} />,
        customErrorElement: condition && renderCustomError && renderCustomError(content),
        prevValue,
    };
};

export default useReaction;
