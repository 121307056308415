import {api} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';
import {all, put, takeEvery} from 'redux-saga/effects';
import adjustmentsActions from './actions';
import {buildQuery, getRoute} from 'library/helpers/routing';

function* fetchAdjustments({payload = {}}) {
    try {
        const adjustments = yield api
            .get(`${getRoute(apiEndpoints.adjustments.get, {id: payload.id})}?${buildQuery(payload)}`)
            .then((res) => res.data);
        yield put(adjustmentsActions.fetchAdjustmentsSuccess(adjustments));
    } catch (e) {
        yield put(adjustmentsActions.fetchAdjustmentsFailure(e));
    }
}

export default function* adjustmentsSaga() {
    yield all([takeEvery(adjustmentsActions.FETCH_ADJUSTMENTS_START, fetchAdjustments)]);
}
