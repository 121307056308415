const senderNamesActions = {
    FETCH_SENDER_NAMES_START: 'FETCH_SENDER_NAMES_START',
    FETCH_SENDER_NAMES_SUCCESS: 'FETCH_SENDER_NAMES_SUCCESS',
    FETCH_SENDER_NAMES_ERROR: 'FETCH_SENDER_NAMES_ERROR',
    fetchStart: (indexType) => ({
        type: senderNamesActions.FETCH_SENDER_NAMES_START,
        payload: indexType,
    }),
    fetchSuccess: (payload) => {
        return {
            type: senderNamesActions.FETCH_SENDER_NAMES_SUCCESS,
            payload,
        };
    },
    fetchError: (error) => ({
        type: senderNamesActions.FETCH_SENDER_NAMES_ERROR,
        payload: error,
    }),

    SAVE_SENDER_NAMES_START: 'SAVE_SENDER_NAMES_START',
    SAVE_SENDER_NAMES_SUCCESS: 'SAVE_SENDER_NAMES_SUCCESS',
    SAVE_SENDER_NAMES_ERROR: 'SAVE_SENDER_NAMES_ERROR',
    saveStart: (payload) => ({
        type: senderNamesActions.SAVE_SENDER_NAMES_START,
        payload,
    }),
    saveSuccess: (payload) => {
        return {
            type: senderNamesActions.SAVE_SENDER_NAMES_SUCCESS,
            payload,
        };
    },
    saveError: (error) => ({
        type: senderNamesActions.SAVE_SENDER_NAMES_ERROR,
        payload: error,
    }),

    DELETE_SENDER_NAMES_START: 'DELETE_SENDER_NAMES_START',
    DELETE_SENDER_NAMES_SUCCESS: 'DELETE_SENDER_NAMES_SUCCESS',
    DELETE_SENDER_NAMES_ERROR: 'DELETE_SENDER_NAMES_ERROR',
    deleteStart: (payload) => ({
        type: senderNamesActions.DELETE_SENDER_NAMES_START,
        payload,
    }),
    deleteSuccess: () => {
        return {
            type: senderNamesActions.DELETE_SENDER_NAMES_SUCCESS,
        };
    },
    deleteError: (error) => ({
        type: senderNamesActions.DELETE_SENDER_NAMES_ERROR,
        payload: error,
    }),

    RESET_SENDER_NAMES_ACTIONS: 'RESET_SENDER_NAMES_ACTIONS',
    resetActions: () => {
        return {
            type: senderNamesActions.RESET_SENDER_NAMES_ACTIONS,
        };
    },

    CLEAR_DEFAULT_SENDER_NAMES_START: 'CLEAR_DEFAULT_SENDER_NAMES_START',
    CLEAR_DEFAULT_SENDER_NAMES_SUCCESS: 'CLEAR_DEFAULT_SENDER_NAMES_SUCCESS',
    CLEAR_DEFAULT_SENDER_NAMES_ERROR: 'CLEAR_DEFAULT_SENDER_NAMES_ERROR',
    clearDefaultStart: () => ({
        type: senderNamesActions.CLEAR_DEFAULT_SENDER_NAMES_START,
    }),
    clearDefaultSuccess: () => {
        return {
            type: senderNamesActions.CLEAR_DEFAULT_SENDER_NAMES_SUCCESS,
        };
    },
    clearDefaultError: (error) => ({
        type: senderNamesActions.CLEAR_DEFAULT_SENDER_NAMES_ERROR,
        payload: error,
    }),

    SET_SENDER_NAMES: 'SET_SENDER_NAMES',
    setSenderNames: (payload) => ({
        type: senderNamesActions.SET_SENDER_NAMES,
        payload,
    }),
};

export default senderNamesActions;
