import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    Breakpoint,
    Button,
    Col,
    Drawer,
    FontAwesome,
    Pagination,
    Popover,
    Row,
    Space,
    Form,
    Divider,
} from 'components/UI';
import {lang} from 'config/lang';
import {numberFormatter} from 'library/helpers/format';
import TableInlineToolbar from './TableInlineToolbar';
import useDeepCompareEffect from 'hooks/utility/useDeepCompareEffect';
import useToolbarFilters from 'components/Integrations/merge/useToolbarFilters';
import useIntegrationsStore from 'hooks/integrations/useIntegrationsStore';
import useUrl from 'hooks/utility/useUrl';
import {useDispatch} from 'react-redux';
import {resetSyncFilters, setConfiguredFiltersStore} from 'redux/integrations/actions';
import isEqual from 'react-fast-compare';
import {usePrev} from 'hooks/utility/usePrev';

const TableToolbar = (props) => {
    const dispatch = useDispatch();
    const {query} = useUrl();
    const {store} = useIntegrationsStore({query});

    const [showMoreFilters, setShowMoreFilters] = useState(false);

    const configuredFilters = store?.configuredFilters;

    const handleSetConfiguredFilters = useCallback(
        (configuredFilters) => {
            dispatch(setConfiguredFiltersStore(configuredFilters));
        },
        [dispatch],
    );

    const {handleFilter, getFilterEnabled} = useToolbarFilters(store);

    const [form] = Form.useForm();

    const prevFilters = usePrev(store?.sync_job?.filters);
    useEffect(() => {
        if (prevFilters !== null && prevFilters !== undefined) return;
        if (!store?.sync_job?.filters) return;

        dispatch(setConfiguredFiltersStore([...Object.keys(store?.sync_job?.filters), 'submit_filters']));
    }, [dispatch, prevFilters, store?.sync_job?.filters]);

    useDeepCompareEffect(() => {
        if (configuredFilters !== null) return;
        if (!props?.filters?.length > 0 || store.sync_job?.filters?.length > 0) return;

        const activeFilters = store.sync_job?.filters ? Object.keys(store.sync_job.filters) : [];

        return handleSetConfiguredFilters([...activeFilters, 'submit_filters']);
    }, [configuredFilters, props.filters]);

    const getVisibleFilters = useCallback(() => {
        if (!props?.filters || props.filters?.length < 1) return [];

        return props.filters
            .sort((a, b) => (a?.order < b?.order ? -1 : 1))
            .filter((filter) => filter?.visible !== false);
    }, [props.filters]);

    const handleChange = useCallback(
        (page, pageSize) => {
            props.onPageChange(page, pageSize);
        },
        [props],
    );

    const handleOnClick = useCallback((e, callback) => {
        if (typeof callback === 'function') callback(e);
    }, []);

    const itemRender = useCallback(
        (current, type, originalElement) => {
            if (!props.dataLoaded) return;

            if (type === 'prev') {
                return (
                    <span className="ant-pagination-item-link">
                        <FontAwesome icon="angle-left" />
                    </span>
                );
            }

            if (type === 'next') {
                return (
                    <span className="ant-pagination-item-link">
                        <FontAwesome icon="angle-right" />
                    </span>
                );
            }

            return originalElement;
        },
        [props.dataLoaded],
    );

    const renderButton = useCallback(
        (item) => (
            <Button
                type="default"
                size="small"
                loading={item.loading || false}
                icon={item.icon}
                htmlType={item.htmlType || 'button'}
                onClick={(e) => handleOnClick(e, item.onClick)}
                disabled={item.disabled || false}
            >
                {item.label}
            </Button>
        ),
        [handleOnClick],
    );

    const renderPagination = useCallback(() => {
        if (props.pagination.component) return props.pagination.component;

        return (
            <Row justify="middle" gutter={12} wrap={false} align="middle" className="gg-table-pagination-row">
                <Col>
                    {!props.pagination.component && (
                        <Pagination
                            {...props.pagination}
                            size="default"
                            itemRender={itemRender}
                            showSizeChanger={false}
                            onChange={handleChange}
                            onShowSizeChange={handleChange}
                            hideOnSinglePage={props.hideOnSinglePage}
                            showLessItems={true}
                            showTitle={false}
                            showTotal={(total) => `${numberFormatter(total)} ${props.totalsText}`}
                            simple
                        />
                    )}
                </Col>

                {!props.pagination.component && props.showSizeChanger && (
                    <Col>
                        <Pagination
                            {...props.pagination}
                            size="default"
                            showSizeChanger={props.showSizeChanger}
                            onChange={handleChange}
                            onShowSizeChange={handleChange}
                            hideOnSinglePage={props.hideOnSinglePage}
                            showLessItems={true}
                            className="page-size-dropdown-only"
                            locale={{items_per_page: 'per page'}}
                            showTitle={false}
                        />
                    </Col>
                )}
            </Row>
        );
    }, [props.pagination, props.showSizeChanger, props.totalsText, props.hideOnSinglePage, handleChange, itemRender]);

    const renderFilterItems = useCallback((filters, items) => {
        const makeRow = (item) => (
            <Col key={'col-' + item.id}>
                <Space size={16}>
                    {!item?.hideLabelInToolbar && item.label && item.label}
                    {item.help && <Popover content={item.help} help arrowPointAtCenter />}
                    {item.component && item.component}
                </Space>
            </Col>
        );
        const alwaysShowRows = filters.filter((f) => f?.alwaysShowInToolbar).map((f) => makeRow(f));
        const filterRows = filters
            .filter((f) => !f?.alwaysShowInToolbar)
            .slice(0, items)
            .map((item) => makeRow(item));

        const divider =
            alwaysShowRows.length > 0 ? <Divider key="element-divider" type="vertical" orientation="center" /> : false;
        return [...alwaysShowRows, divider, ...filterRows].filter(Boolean);
    }, []);

    const renderFilterItemsOverflow = useCallback((filters) => {
        return filters
            .map((item) => {
                if (item.hideInDrawer) return false;

                return (
                    <Form.Item
                        name={item.key}
                        key={'filter-' + item.key}
                        label={item?.label}
                        help={item?.help}
                        required={item.required}
                    >
                        {item.component}
                    </Form.Item>
                );
            })
            .filter(Boolean);
    }, []);

    const renderFilters = useCallback(
        (filters, showFilters, num = 0) => {
            if (!filters || filters?.length === 0 || !showFilters) return '';

            const itemCount = num ? num : 1;
            const initialValues = filters.reduce((carry, filter) => {
                if (!filter?.key) return carry;

                return {
                    ...carry,
                    [filter.key]: filter?.defaultValue || undefined,
                };
            }, {});

            if (!isEqual(form.getFieldsValue(), initialValues)) {
                form.setFieldsValue(initialValues);
            }

            const resetToolbarFilters = () => {
                const values = form.getFieldsValue();

                // if item has no value because it is a component, we must find the component and resrt it there

                filters.forEach((filter) => {
                    const {key, component} = filter;

                    if (key === 'submit_filters') return;
                    if (!component) return;

                    const onChange = component?.props?.handleChange;
                    const options = [
                        {
                            value: 0,
                            label: lang['all'],
                        },
                        ...(component.props.options || []),
                    ];

                    if (!onChange) return;

                    onChange({[key]: options[0].value});
                });

                Object.entries(values)
                    .filter(([_, value]) => !!value)
                    .forEach(([key]) => {
                        if (key === 'submit_filters') return;

                        form.setFieldValue(key, -1);
                    });

                props.onReset && props.onReset();
            };

            if (props.toolbarInline) {
                return (
                    <Space direction="vertical">
                        <Form
                            name={'toolbar-filters'}
                            form={form}
                            direction="horizontal"
                            initialValues={initialValues}
                            labelCol={{span: 24}}
                            wrapperCol={{span: 24}}
                        >
                            {renderFilterItemsOverflow(filters)}
                            <Form.Item key="toolbar-inlint-reset-button">
                                <Button htmlType="button" onClick={resetToolbarFilters} block size="large">
                                    Reset Filters
                                </Button>
                            </Form.Item>
                        </Form>
                    </Space>
                );
            }

            return (
                <Row align="middle" gutter={[18, 12]}>
                    {renderFilterItems(filters, itemCount)}

                    {filters.length > itemCount && (
                        <>
                            <Col>
                                <Divider orientation="center" type="vertical" />
                            </Col>

                            <Col key="col-overflow-btn">
                                <Form
                                    form={form}
                                    direction="horizontal"
                                    initialValues={initialValues}
                                    labelCol={{span: 24}}
                                    wrapperCol={{span: 24}}
                                >
                                    <Drawer
                                        open={showMoreFilters}
                                        title={lang['filters']}
                                        placement="right"
                                        closable={false}
                                        maskClosable={true}
                                        onClose={() => setShowMoreFilters(false)}
                                        width={480}
                                        footer={
                                            <Row gutter={[16, 16]}>
                                                <Col span={24}>
                                                    <Button onClick={resetToolbarFilters} block size="large">
                                                        Reset Filters
                                                    </Button>
                                                </Col>
                                            </Row>
                                        }
                                    >
                                        <Space direction="vertical">{renderFilterItemsOverflow(filters)}</Space>
                                    </Drawer>
                                </Form>

                                <Button
                                    size="large"
                                    icon="ellipsis"
                                    icontype="regular"
                                    onClick={() => setShowMoreFilters(true)}
                                    type="text"
                                >
                                    {lang.more_filters}
                                </Button>
                            </Col>
                        </>
                    )}
                </Row>
            );
        },
        [form, props, renderFilterItems, showMoreFilters, renderFilterItemsOverflow],
    );

    const renderPropsButtons = useCallback(
        () =>
            props.buttons.map((item) => (
                <Col key={item.id + '-col'}>
                    {Object.prototype.hasOwnProperty.call(item, 'component') ? item.component : renderButton(item)}
                </Col>
            )),
        [props.buttons, renderButton],
    );

    if (props.toolbarInline) {
        const selectOptions = props.filters
            ?.filter((item) => item.key !== 'submit_filters')
            ?.filter((item) => item?.visible !== false)
            .map((item) => {
                return {
                    value: item.key,
                    label: item.label,
                    required: item.required,
                };
            });

        const handleSelectedFilterChange = (value) => {
            if (value?.length === 0) {
                dispatch(resetSyncFilters());

                handleSetConfiguredFilters(['submit_filters']);

                return;
            }

            const filtersToRemove = configuredFilters?.filter(
                (filter) => !value.includes?.(filter) && filter !== 'submit_filters',
            );

            if (filtersToRemove?.length > 0) {
                filtersToRemove.forEach((filter) => {
                    handleFilter(filter, null);
                });
            }

            handleSetConfiguredFilters([...value, 'submit_filters']);
        };

        const filterOptions = props?.filters?.sort?.((a, b) => {
            if (a.type === 'select' && b.type !== 'select') return -1;
            if (a.type !== 'select' && b.type === 'select') return 1;

            return 0;
        });

        const filterData = filterOptions?.filter((item) => getFilterEnabled(item));

        return (
            <TableInlineToolbar
                selectOptions={selectOptions}
                filtersChangeOnSubmit={handleSelectedFilterChange}
                configuredFilters={configuredFilters}
                handleSetConfiguredFilters={handleSetConfiguredFilters}
                loading={props.loading}
                loaded={!props.loading && props?.filters?.length !== 0}
                resetFilters={() => props.onReset && props.onReset()}
                renderedFilters={renderFilters(filterData, props.showFilters)}
            />
        );
    }

    return (
        <Row gutter={[0, 24]}>
            {(props.filters || props.showPagination) && (
                <Col span={24}>
                    <Row gutter={[0, 24]}>
                        <Col span={24}>
                            <Row gutter={[0, 16]} align="middle">
                                <Col flex="auto">
                                    <Row gutter={[24, 16]} align="middle">
                                        {props.search && props.showSearch && <Col flex="none">{props.search}</Col>}
                                        <Col flex="auto">
                                            <Breakpoint
                                                xs={false}
                                                sm={false}
                                                md={false}
                                                lg={false}
                                                inverse={renderFilters(
                                                    getVisibleFilters(),
                                                    props.showFilters,
                                                    props.filtersDisplayCount,
                                                )}
                                            >
                                                {renderFilters(
                                                    getVisibleFilters(),
                                                    props.showFilters,
                                                    props.filtersDisplayCount,
                                                )}
                                            </Breakpoint>
                                        </Col>
                                    </Row>
                                </Col>
                                {props.showPagination && <Col flex="none">{renderPagination()}</Col>}
                            </Row>
                        </Col>
                    </Row>
                </Col>
            )}
            {props.buttons && props.showButtons && (
                <Col span={24}>
                    <Row gutter={[16, 0]} align="middle">
                        <Col>
                            <strong>Options:</strong>
                        </Col>

                        {renderPropsButtons()}
                    </Row>
                </Col>
            )}
        </Row>
    );
};

// TODO: Deprecated, convert to default params
TableToolbar.defaultProps = {
    buttons: [],
    filtersDisplayCount: 1,
    help: false,
    hideOnSinglePage: true,
    showButtons: true,
    showFilters: true,
    showTotals: true,
    showSearch: true,
    showSizeChanger: true,
    toolbarInline: false,
};

TableToolbar.propTypes = {
    buttons: PropTypes.array,
    className: PropTypes.string,
    dataLoaded: PropTypes.bool.isRequired,
    filters: PropTypes.array,
    filtersDisplayCount: PropTypes.number,
    help: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    hideOnSinglePage: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onReset: PropTypes.func,
    pagination: PropTypes.object.isRequired,
    search: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    showButtons: PropTypes.bool,
    showFilters: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    showSearch: PropTypes.bool,
    showPagination: PropTypes.bool.isRequired,
    showSizeChanger: PropTypes.bool,
    showTotals: PropTypes.bool,
    style: PropTypes.object,
    toolbarInline: PropTypes.bool,
    total: PropTypes.number,
    totalsText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default TableToolbar;
