const primaryProductsActions = {
    FETCH_COMPANY_PRIMARY_PRODUCTS_DATA_START: 'FETCH_COMPANY_PRIMARY_PRODUCTS_DATA_START',
    FETCH_COMPANY_PRIMARY_PRODUCTS_DATA_SUCCESS: 'FETCH_COMPANY_PRIMARY_PRODUCTS_DATA_SUCCESS',
    FETCH_COMPANY_PRIMARY_PRODUCTS_DATA_FAILURE: 'FETCH_COMPANY_PRIMARY_PRODUCTS_DATA_FAILURE',

    fetchCompanyPrimaryProductsDataStart: () => ({
        type: primaryProductsActions.FETCH_COMPANY_PRIMARY_PRODUCTS_DATA_START,
    }),

    fetchCompanyPrimaryProductsDataSuccess: (payload) => {
        return {
            type: primaryProductsActions.FETCH_COMPANY_PRIMARY_PRODUCTS_DATA_SUCCESS,
            payload,
        };
    },

    fetchCompanyPrimaryProductsDataFailure: (error) => ({
        type: primaryProductsActions.FETCH_COMPANY_PRIMARY_PRODUCTS_DATA_FAILURE,
        payload: error,
    }),

    FETCH_ALL_PRIMARY_PRODUCTS_DATA_START: 'FETCH_ALL_PRIMARY_PRODUCTS_DATA_START',
    FETCH_ALL_PRIMARY_PRODUCTS_DATA_SUCCESS: 'FETCH_ALL_PRIMARY_PRODUCTS_DATA_SUCCESS',
    FETCH_ALL_PRIMARY_PRODUCTS_DATA_FAILURE: 'FETCH_ALL_PRIMARY_PRODUCTS_DATA_FAILURE',

    fetchAllPrimaryProductsDataStart: () => ({
        type: primaryProductsActions.FETCH_ALL_PRIMARY_PRODUCTS_DATA_START,
    }),

    fetchAllPrimaryProductsDataSuccess: (payload) => {
        return {
            type: primaryProductsActions.FETCH_ALL_PRIMARY_PRODUCTS_DATA_SUCCESS,
            payload,
        };
    },

    fetchAllPrimaryProductsDataFailure: (error) => ({
        type: primaryProductsActions.FETCH_ALL_PRIMARY_PRODUCTS_DATA_FAILURE,
        payload: error,
    }),
};

export default primaryProductsActions;
