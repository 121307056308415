import {lazy} from 'react';
import {publicPaths} from 'config/routes/public/paths';

export const publicContainers = [
    {
        name: 'signinCallback',
        path: publicPaths.AUTH_CALLBACK,
        component: lazy(() => import('containers/Auth/SignIn/SignIn.Callback')),
        exact: true,
    },
    {
        name: 'page404',
        path: publicPaths.PAGE_404,
        component: lazy(() => import('containers/404/404')),
        exact: true,
        title: '404',
    },
    {
        name: 'page500',
        path: publicPaths.PAGE_500,
        component: lazy(() => import('containers/500/500')),
        exact: true,
        title: '500',
    },
    {
        name: 'signIn',
        path: publicPaths.SIGN_IN,
        component: lazy(() => import('containers/Auth/SignIn/SignIn')),
        exact: true,
        title: true,
    },
    {
        name: 'successSignUp',
        path: publicPaths.SIGN_UP_SUCCESS,
        component: lazy(() => import('containers/Auth/SignUp/SignUp.Success')),
        exact: true,
    },
    {
        name: 'forgotPassword',
        path: publicPaths.FORGOT_PASSWORD,
        component: lazy(() => import('containers/Auth/PasswordReset/PasswordReset')),
        exact: true,
        title: true,
    },
    {
        name: 'signUp',
        path: publicPaths.SIGN_UP,
        component: lazy(() => import('containers/Auth/SignUp/SignUp')),
        exact: false,
        title: true,
    },
    {
        name: 'verifyEmail',
        path: publicPaths.VERIFY_EMAIL,
        component: lazy(() => import('containers/Auth/SignUp/SignUp.VerifyEmail')),
        exact: true,
        title: true,
    },
];
