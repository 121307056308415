import React, {forwardRef} from 'react';
import {Input as AntInput} from 'antd';
import {match} from 'library/helpers/utilities';
import InputColorPicker from './modules/InputColorPicker';

/**
 * Typehint the extended Input.Password, Input.Search, and Input.TextArea components for the IDE.
 * @type {import('antd/lib/input/Input').default & { Password: typeof import('antd/lib/input/Password').default; Search: typeof import('antd/lib/input/Search').default; TextArea: typeof import('antd/lib/input/TextArea').default; }}
 */
// eslint-disable-next-line react/display-name
const Input = forwardRef((props, ref) => {
    const {size = 'large', type} = props;

    return match(type, {
        color_picker: <InputColorPicker size={size} {...props} />,
        password: <AntInput.Password autoComplete="off" ref={ref} size={size} {...props} />,
        textarea: <AntInput.TextArea ref={ref} size={size} {...props} />,
        default: <AntInput ref={ref} size={size} {...props} />,
    });
});

Input.Password = AntInput.Password;
Input.Search = AntInput.Search;
Input.TextArea = AntInput.TextArea;

export default Input;
