import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {ColorPicker, FontAwesome, Space, Input} from 'components/UI';
import {formatHex} from 'library/helpers/format';
import omit from "lodash/omit";

const getColorValue = (evt, defaultColor) => {
    const value = evt?.target?.value?.toUpperCase() ?? evt;

    if (!defaultColor || value) {
        return value;
    }

    return defaultColor;
};

/**
 * InputColorPicker component
 * @param value {string}
 * @param onClosed {function}
 * @param onChange {function}
 * @param defaultColor {string}
 * @param type {"color_picker"}
 * @returns {JSX.Element}
 */
const InputColorPicker = ({value, onClosed, onChange, defaultColor, ...props}) => {
    const [internalValue, setInternalValue] = useState(value?.replace?.('#', ''));

    const spreadProps = omit(props, ['type']);

    const handleChange = useCallback(
        (evt) => {
            const colorValue = getColorValue(evt, defaultColor);

            onChange(colorValue);
            setInternalValue(formatHex(colorValue));
        },
        [defaultColor, onChange],
    );

    return (
        <Space size={12}>
            <Input
                {...spreadProps}
                name="color"
                addonBefore={'#'}
                allowClear={{
                    clearIcon: <FontAwesome icon="circle-xmark" type="solid" />,
                }}
                value={internalValue}
                onChange={handleChange}
            />

            <ColorPicker color={internalValue} onChange={handleChange} onClosed={onClosed} />
        </Space>
    );
};

InputColorPicker.propTypes = {
    onClosed: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.string,
    defaultColor: PropTypes.string,
};

export default InputColorPicker;
