import {apiEndpoints} from 'config/api';
import {call} from './helpers';
import {getRoute} from 'library/helpers/routing';

// push man push!
export const integrationApiService = {
    createSync: async (payload) => await call('post', apiEndpoints.integrations.sync.post, payload),
    /**
     * @return {Promise<Object|string>}
     */
    initializeIntegration: async ({id, sync_id, querystring}) =>
        await call(
            'get',
            getRoute(apiEndpoints.integrations.initialize, {id}) + '?sync_id=' + sync_id + '&' + querystring,
        ),

    loadCompanyIntegrations: async () => await call('get', apiEndpoints.integrations.get),

    runIntegrationFilter: async (payload) => await call('post', apiEndpoints.integrations.filter, payload),

    importContacts: async (payload) => await call('post', apiEndpoints.integrations.sync.post, payload),

    loadSyncs: async (querystring = '') => await call('get', apiEndpoints.integrations.sync.all + querystring),

    loadSync: async ({id}) => await call('get', getRoute(apiEndpoints.integrations.sync.get, {id})),

    deleteSyncs: async (payload) => await call('delete', apiEndpoints.integrations.sync.delete, {data: {ids: payload}}),

    updateSync: async (payload) => await call('put', getRoute(apiEndpoints.integrations.sync.put, payload), payload),
    updateSyncName: async (payload) =>
        await call('put', getRoute(apiEndpoints.integrations.sync.put_name, payload), payload),
    updateSyncStatus: async (payload) =>
        await call('put', getRoute(apiEndpoints.integrations.sync.put_status, payload), payload),
};
