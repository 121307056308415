import appActions from './actions';

const initState = {
    initialized: false,
    defaultStoresLoaded: true,
};

const appReducer = (state = initState, action) => {
    switch (action.type) {
        case appActions.UNINITIALIZE:
            return {
                ...state,
                initialized: false,
            };
        case appActions.INITIALIZE:
            return {
                ...state,
                initialized: false,
            };
        case appActions.INITIALIZE_COMPLETE:
            return {
                ...state,
                initialized: true,
            };
        default:
            return state;
    }
};

export default appReducer;
