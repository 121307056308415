import {useCallback} from 'react';
import useGetExchangeRates from 'hooks/funding/useGetExchangeRates';
import {currencyFormatter} from 'library/helpers/format';
import {DEFAULT_REGION} from 'config/regions';

export default function useExchange() {
    const {getExchangeRate} = useGetExchangeRates();

    return useCallback(
        ({
            amount,
            exchange_rate = false,
            region = DEFAULT_REGION,
            approximate = false,
            currency = null,
            decimals = 2,
        }) => {
            const processedAmount = amount
                ? exchange_rate
                    ? amount * getExchangeRate(region.currency ?? currency)
                    : amount
                : null;

            const approx = approximate && amount > 0 ? '~' : '';
            const value = currencyFormatter(processedAmount, decimals, region.currency);

            return `${approx}${value}`;
        },
        [getExchangeRate],
    );
}
