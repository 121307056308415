import {createSelector} from 'reselect';

const funding = (state) => state.funding;

export const getFunding = createSelector([funding], (funding) => funding);
export const getFundingSubmitted = createSelector(
  [funding],
  (funding) => funding.submitted,
);

export const getFundingTransferring = createSelector(
  [funding],
  (funding) => funding.transferring,
);
