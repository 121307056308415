const authenticationActions = {
  PING: 'PING',
  SOFT_LOGIN: 'SOFT_LOGIN',
  softLogin: (payload) => {
    return {
      type: authenticationActions.SOFT_LOGIN,
      payload,
    };
  },
  AUTHENTICATION_START: 'AUTHENTICATION_START',
  authenticationStart: (payload) => {
    return {
      type: authenticationActions.AUTHENTICATION_START,
      payload,
    };
  },
  AUTHENTICATION_SUCCESS: 'AUTHENTICATION_SUCCESS',
  authenticationSuccess: () => {
    return {
      type: authenticationActions.AUTHENTICATION_SUCCESS,
    };
  },
  AUTHENTICATION_FAILURE: 'AUTHENTICATION_FAILURE',
  authenticationError: (error) => ({
    type: authenticationActions.AUTHENTICATION_FAILURE,
    payload: error,
  }),
  AUTHENTICATION_CHECK: 'AUTHENTICATION_CHECK',
  authenticationCheck: () => ({
    type: authenticationActions.AUTHENTICATION_CHECK,
  }),
  LOGOUT: 'LOGOUT',
  logout: () => ({
    type: authenticationActions.LOGOUT,
  }),
  SET_INTENDED: 'SET_INTENDED',
  setIntended: (payload) => ({
    type: authenticationActions.SET_INTENDED,
    payload,
  }),
  REFRESH: 'REFRESH',
  refresh: () => ({
    type: authenticationActions.REFRESH,
  }),
};

export default authenticationActions;
