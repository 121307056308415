const campaignsActions = {
  LIST_CAMPAIGNS_START: 'LIST_CAMPAIGNS_START',
  LIST_CAMPAIGNS_SUCCESS: 'LIST_CAMPAIGNS_SUCCESS',
  LIST_CAMPAIGNS_FAILURE: 'LIST_CAMPAIGNS_FAILURE',
  listCampaignsStart: () => {
    return {
      type: campaignsActions.LIST_CAMPAIGNS_START,
    };
  },
  listCampaignsSuccess: (campaigns) => {
    return {
      type: campaignsActions.LIST_CAMPAIGNS_SUCCESS,
      payload: campaigns,
    };
  },
  listCampaignsError: (error) => ({
    type: campaignsActions.LIST_CAMPAIGNS_FAILURE,
    payload: error,
  }),

  FETCH_CAMPAIGNS_DATA_START: 'FETCH_CAMPAIGNS_DATA_START',
  FETCH_CAMPAIGNS_DATA_SUCCESS: 'FETCH_CAMPAIGNS_DATA_SUCCESS',
  FETCH_CAMPAIGNS_DATA_FAILURE: 'FETCH_CAMPAIGNS_DATA_FAILURE',
  fetchCampaignsStart: (payload = {page: 1}) => {
    return {
      type: campaignsActions.FETCH_CAMPAIGNS_DATA_START,
      payload,
    };
  },
  fetchCampaignsSuccess: (campaigns) => {
    return {
      type: campaignsActions.FETCH_CAMPAIGNS_DATA_SUCCESS,
      payload: campaigns,
    };
  },
  fetchCampaignsError: (error) => ({
    type: campaignsActions.FETCH_CAMPAIGNS_DATA_FAILURE,
    payload: error,
  }),
  RESET_CAMPAIGNS_ERROR: 'RESET_CAMPAIGNS_ERROR',
  resetCampaignsError: () => ({
    type: campaignsActions.RESET_CAMPAIGNS_ERROR,
  }),

  SET_CURRENT_CAMPAIGN: 'SET_CURRENT_CAMPAIGN',
  setCurrentCampaign: (payload) => {
    return {
      type: campaignsActions.SET_CURRENT_CAMPAIGN,
      payload,
    };
  },

  SAVE_SMART_CAMPAIGN_DATA_START: 'SAVE_SMART_CAMPAIGN_DATA_START',
  SAVE_SMART_CAMPAIGN_DATA_SUCCESS: 'SAVE_SMART_CAMPAIGN_DATA_SUCCESS',
  SAVE_SMART_CAMPAIGN_DATA_FAILURE: 'SAVE_SMART_CAMPAIGN_DATA_FAILURE',
  saveSmartCampaignStart: (payload) => ({
    type: campaignsActions.SAVE_SMART_CAMPAIGN_DATA_START,
    payload,
  }),
  saveSmartCampaignSuccess: () => {
    return {
      type: campaignsActions.SAVE_SMART_CAMPAIGN_DATA_SUCCESS,
    };
  },
  saveSmartCampaignFailure: (error) => ({
    type: campaignsActions.SAVE_SMART_CAMPAIGN_DATA_FAILURE,
    payload: error,
  }),

  SAVE_STANDARD_CAMPAIGN_DATA_START: 'SAVE_STANDARD_CAMPAIGN_DATA_START',
  SAVE_STANDARD_CAMPAIGN_DATA_SUCCESS: 'SAVE_STANDARD_CAMPAIGN_DATA_SUCCESS',
  SAVE_STANDARD_CAMPAIGN_DATA_FAILURE: 'SAVE_STANDARD_CAMPAIGN_DATA_FAILURE',
  saveStandardCampaignStart: (payload) => ({
    type: campaignsActions.SAVE_STANDARD_CAMPAIGN_DATA_START,
    payload,
  }),
  saveStandardCampaignSuccess: () => {
    return {
      type: campaignsActions.SAVE_STANDARD_CAMPAIGN_DATA_SUCCESS,
    };
  },
  saveStandardCampaignFailure: (error) => ({
    type: campaignsActions.SAVE_STANDARD_CAMPAIGN_DATA_FAILURE,
    payload: error,
  }),

  TOGGLE_CAMPAIGNS_DATA_START: 'TOGGLE_CAMPAIGNS_DATA_START',
  TOGGLE_CAMPAIGNS_DATA_SUCCESS: 'TOGGLE_CAMPAIGNS_DATA_SUCCESS',
  TOGGLE_CAMPAIGNS_DATA_FAILURE: 'TOGGLE_CAMPAIGNS_DATA_FAILURE',
  toggleCampaignsStart: (payload) => ({
    type: campaignsActions.TOGGLE_CAMPAIGNS_DATA_START,
    payload,
  }),
  toggleCampaignsSuccess: () => {
    return {
      type: campaignsActions.TOGGLE_CAMPAIGNS_DATA_SUCCESS,
    };
  },
  toggleCampaignsFailure: (error) => ({
    type: campaignsActions.TOGGLE_CAMPAIGNS_DATA_FAILURE,
    payload: error,
  }),

  DELETE_CAMPAIGNS_DATA_START: 'DELETE_CAMPAIGNS_DATA_START',
  DELETE_CAMPAIGNS_DATA_SUCCESS: 'DELETE_CAMPAIGNS_DATA_SUCCESS',
  DELETE_CAMPAIGNS_DATA_FAILURE: 'DELETE_CAMPAIGNS_DATA_FAILURE',
  deleteCampaignsStart: (payload) => ({
    type: campaignsActions.DELETE_CAMPAIGNS_DATA_START,
    payload,
  }),
  deleteCampaignsSuccess: (payload) => {
    return {
      type: campaignsActions.DELETE_CAMPAIGNS_DATA_SUCCESS,
      payload,
    };
  },
  deleteCampaignsFailure: (error) => ({
    type: campaignsActions.DELETE_CAMPAIGNS_DATA_FAILURE,
    payload: error,
  }),

  RESET_CAMPAIGNS_SAVED_STATUS: 'RESET_CAMPAIGN_SAVED_STATUS',
  resetCampaignsSavedStatus: () => {
    return {
      type: campaignsActions.RESET_CAMPAIGNS_SAVED_STATUS,
    };
  },
};

export default campaignsActions;
