import React from 'react';

const icon = () => (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <circle cx="256" cy="256" fill="#f0f0f0" r="256" />
        <circle cx="256" cy="256" fill="#d80027" r="111.304" />
    </svg>
);

export default icon;
