import axios from 'axios';
import {
    BUILD_FAILED_ERROR,
    CANCEL_SOURCE,
    CANCELLED_ERROR,
    ERROR_COUNT_HASH,
    REDIRECT_ERROR,
    REDIRECT_STATUSES,
    RESET_ERROR_AFTER_MS,
    THROTTLED_ERROR,
} from 'config/api';
import {isNeutralRoute, shouldRedirectToLogin} from 'library/helpers/routing';
import {getSessionCache, setSessionCache} from 'library/helpers/localStore';
import {BUILD_UPDATE_FAILED, RETRY_WINDOW_RELOAD} from 'config/redux';
import {deepStringifySort, isProduction} from 'library/helpers/utilities';
import noop from 'lodash/noop';
import set from 'lodash/set';
import logger from '../../vendor/loggly';
import {getCurrentTeam, getProfileData} from 'redux/selectors';
import {store} from 'redux/store';
import {stripLeading} from 'library/helpers/string';
import {getProcessEnvValue} from 'library/helpers/process';
import {routes} from 'config/routes';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import {MESSAGE_FORM, SEND_FORM} from 'config/forms';
import {flushForms} from 'library/helpers/redux';

const DEBUG_TRACE_LEVEL = 'ERROR';
let interval;

export let apiRequestsPending = 0;
const SUPPRESSION_TIME = 10000;

const errorLog = (...args) => (DEBUG_TRACE_LEVEL === 'ERROR' ? console.error('API Error: ', ...args) : noop());
const infoLog = (...args) => (DEBUG_TRACE_LEVEL === 'INFO' ? console.log('API Info: ', ...args) : noop());

export const hardRefresh = (retry = false) => {
    const currentTime = new Date().getTime();

    if (!retry) {
        setSessionCache(RETRY_WINDOW_RELOAD, String(currentTime));
        return window.location.reload();
    }

    if (!isProduction()) return false;

    const lastRefreshTime = parseInt(getSessionCache(RETRY_WINDOW_RELOAD)) || 0;

    if (currentTime - lastRefreshTime < SUPPRESSION_TIME) return false;

    setSessionCache(RETRY_WINDOW_RELOAD, String(currentTime));
    window.location.reload();
    flushForms([SEND_FORM, MESSAGE_FORM]);

    return false;
};

const getCallKey = (config) =>
    JSON.stringify({url: config?.url, method: config?.method, data: deepStringifySort(config?.data)});

export const api = axios.create({
    signal: new AbortController().signal,
    headers: {
        common: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    },
});

const resetErrorCount = (response) => {
    const callKey = getCallKey(response.config);

    if (Object.prototype.hasOwnProperty.call(ERROR_COUNT_HASH, callKey)) {
        ERROR_COUNT_HASH[callKey].count = 0;
    }

    return response;
};

const checkDeployId = (response) => {
    if (!Object.prototype.hasOwnProperty.call(response.headers, 'x-deploy-id')) return response;

    const xDeployId = String(response.headers['x-deploy-id']);
    const buildJobId = String(getProcessEnvValue('REACT_APP_BUILD_JOB_ID'));

    if (xDeployId === '0000000000') {
        if (isProduction() && !getSessionCache(BUILD_UPDATE_FAILED)) {
            console.error(BUILD_FAILED_ERROR);
            setSessionCache(BUILD_UPDATE_FAILED, 1);
        }

        return response;
    }

    if (xDeployId === buildJobId) return response;

    const initialTime = performance.now();

    clearInterval(interval);
    interval = setInterval(() => {
        if (performance.now() - initialTime < 10000 && apiRequestsPending !== 0) return;

        const suppressError = !!(hardRefresh(true) ?? true);

        // The build isn't updating, let's log it
        if (!suppressError && isProduction()) {
            logger.push({
                errorMessage: BUILD_FAILED_ERROR,
                context: omitBy(
                    {
                        url: window.location.pathname,
                        account_id: getProfileData(store.getState())?.id,
                        build_id: stripLeading(getProcessEnvValue('REACT_APP_BUILD_JOB_ID')) ?? 'UNKNOWN',
                        userAgent: navigator?.userAgent,
                    },
                    isNil,
                ),
            });
        }

        clearInterval(interval);
    });
};

const handleResponseError = (error) => {
    const callKey = getCallKey(error.config);

    if (error?.message === THROTTLED_ERROR || error?.message === CANCELLED_ERROR) {
        set(ERROR_COUNT_HASH, `${callKey}.count`, 0);

        return Promise.reject({
            message: THROTTLED_ERROR,
            api: callKey,
        });
    }

    const shouldResetErrorCount = (callKey, currentTime) =>
        currentTime - ERROR_COUNT_HASH[callKey].timestamp > RESET_ERROR_AFTER_MS;

    const incrementErrorCount = (callKey, currentTime) => {
        if (shouldResetErrorCount(callKey, currentTime)) {
            ERROR_COUNT_HASH[callKey].count = 0;
        }

        ERROR_COUNT_HASH[callKey].count += 1;
        ERROR_COUNT_HASH[callKey].timestamp = currentTime;
    };

    const currentTime = Date.now();

    if (ERROR_COUNT_HASH[callKey]) {
        incrementErrorCount(callKey, currentTime);
    } else {
        ERROR_COUNT_HASH[callKey] = {
            count: 1,
            timestamp: currentTime,
        };
    }

    const hasResponse = Object.prototype.hasOwnProperty.call(error, 'response');

    if (!hasResponse && (hasResponse || axios.isCancel(error))) {
        return Promise.reject({
            ...error.message,
            api: callKey,
        });
    }

    // handle validation errors
    if (error.response && error.response.status === 422 && error.response.data.errors) {
        error.response.data.errors = Object.values(error.response.data.errors).map((item) => item.shift());
    }

    if (
        error.response &&
        REDIRECT_STATUSES.includes(error.response.status) &&
        !isNeutralRoute(window.location.pathname)
    ) {
        if (shouldRedirectToLogin(window.location.pathname)) window.location.href = routes.public.paths.SIGN_IN;

        return Promise.reject({
            ...error.response,
            message: REDIRECT_ERROR,
            api: callKey,
        });
    }

    // return the response
    return Promise.reject(error.response);
};

const requestActions = {
    fulfilled: (config) => {
        apiRequestsPending++;

        config.cancelToken = CANCEL_SOURCE.token;

        const callKey = getCallKey(config);

        infoLog('REQUEST FULFILLED: ', config);

        const hashedRequest = ERROR_COUNT_HASH[callKey] ?? {};

        if (hashedRequest.lastRequestTS) {
            const currentTS = Date.now();
            const timeSinceLastRequest = currentTS - hashedRequest.lastRequestTS;

            if (timeSinceLastRequest < 100) {
                return Promise.reject({message: THROTTLED_ERROR, api: callKey});
            }
        }

        set(ERROR_COUNT_HASH, `${callKey}.lastRequestTS`, Date.now());

        const data = config && config.data;

        if (data && typeof data === 'object') {
            Object.keys(data).forEach(key => {
                if (data[key] === undefined) {
                    if (!data.__UNDEFINED_) {
                        data.__UNDEFINED_ = [];
                    }

                    data.__UNDEFINED_.push(key);
                }
            });
        }

        return config;
    },

    rejected: (error) => {
        errorLog('RESPONSE REJECTED: ', error);
        return Promise.reject(error);
    },
};

const responseActions = {
    fulfilled: (response) => {
        infoLog('RESPONSE FULFILLED: ', response);

        apiRequestsPending--;

        const resetResponse = resetErrorCount(response);
        const finalResponse = checkDeployId(resetResponse || response);

        return finalResponse || response;
    },

    rejected: (error) => {
        errorLog('RESPONSE REJECTED: ', error);
        apiRequestsPending--;

        return handleResponseError(error);
    },

    // Push errors to the logger
    logRejected: (error) => {
        if (
            !error ||
            !isProduction() ||
            error?.message === REDIRECT_ERROR ||
            error?.message === THROTTLED_ERROR
        ) {
            return Promise.reject(error);
        }

        const context = {
            url: window.location.pathname,
            account_id: getProfileData(store.getState())?.id,
            team_id: getCurrentTeam(store.getState()),
            userAgent: navigator?.userAgent,
            api: error?.api,
            build_id: stripLeading(getProcessEnvValue('REACT_APP_BUILD_JOB_ID')) ?? 'UNKNOWN',
        };

        logger.push({
            errorMessage: error.message ?? '',
            context: omitBy(context, isNil),
        });

        return Promise.reject(error);
    },
};

api.interceptors.request.use(requestActions.fulfilled, requestActions.rejected);
api.interceptors.response.use(responseActions.fulfilled, responseActions.rejected);
api.interceptors.response.use(null, responseActions.logRejected);
