const recentOrdersActions = {
    RESET_RECENT_ORDERS: 'RESET_RECENT_ORDERS',
    reset: () => ({
        type: recentOrdersActions.RESET_RECENT_ORDERS,
    }),
    RESET_RECENT_ORDERS_SUCCESS: 'RESET_RECENT_ORDERS_SUCCESS',
    resetSuccess: () => ({
        type: recentOrdersActions.RESET_RECENT_ORDERS_SUCCESS,
    }),
    SET_CURRENT_RECENT_ORDERS: 'SET_CURRENT_RECENT_ORDERS',
    setCurrent: (payload) => ({
        type: recentOrdersActions.SET_CURRENT_RECENT_ORDERS,
        payload,
    }),
    FETCH_RECENT_ORDERS: 'FETCH_RECENT_ORDERS',
    fetchRecentOrders: (payload) => ({
        type: recentOrdersActions.FETCH_RECENT_ORDERS,
        payload,
    }),
    FETCH_BOTH_RECENT_ORDERS: 'FETCH_BOTH_RECENT_ORDERS',
    fetchBothRecentOrders: (payload) => ({
        type: recentOrdersActions.FETCH_BOTH_RECENT_ORDERS,
        payload,
    }),
    FETCH_RECENT_ORDERS_SUCCESS: 'FETCH_RECENT_ORDERS_SUCCESS',
    fetchRecentOrdersSuccess: ({key, data}) => {
        return {
            type: recentOrdersActions.FETCH_RECENT_ORDERS_SUCCESS,
            payload: {
                key,
                data,
            },
        };
    },
    FETCH_RECENT_ORDERS_ERROR: 'FETCH_RECENT_ORDERS_ERROR',
    fetchRecentOrdersError: (error) => ({
        type: recentOrdersActions.FETCH_RECENT_ORDERS_ERROR,
        payload: error,
    }),

    MODIFY_RECENT_ORDERS: 'MODIFY_RECENT_ORDERS',
    modifyRecentOrders: (key, orderIds, newData) => ({
        type: recentOrdersActions.MODIFY_RECENT_ORDERS,
        payload: {
            key,
            orderIds,
            newData,
        },
    }),
};

export default recentOrdersActions;
