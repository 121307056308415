import {all, takeEvery, put, takeLeading} from 'redux-saga/effects';
import {api} from 'library/helpers/api';
import groupsActions from './actions';
import {apiEndpoints} from 'config/api';
import {buildQuery, getRoute} from 'library/helpers/routing';

function* listGroups() {
    try {
        const groups = yield api.get(apiEndpoints.groups.list).then((response) => response.data);

        yield put(groupsActions.listGroupsSuccess(groups));
    } catch (error) {
        console.log(error);
        yield put(groupsActions.listGroupsFailure(error));
    }
}

function* fetchGroupsData({payload}) {
    try {
        const query = buildQuery(payload);
        const url = `${apiEndpoints.groups.get}?${query}`;
        const groups = yield api.get(url).then((res) => res.data);

        //groups.data = appendKey(groups.data);
        yield put(groupsActions.fetchGroupsDataSuccess(groups));
    } catch (e) {
        yield put(groupsActions.fetchGroupsDataFailure(e));
    }
}

function* saveGroup({payload}) {
    try {
        const response =
            payload.id === undefined
                ? yield api.post(apiEndpoints.groups.post, payload)
                : yield api.put(getRoute(apiEndpoints.groups.update, {id: payload.id}), payload);

        const newId = payload.id !== undefined ? null : response.data.id;
        yield put(groupsActions.saveGroupsDataSuccess(newId));
        yield put(groupsActions.fetchGroupsDataStart());
        yield put(groupsActions.listGroupsStart());
    } catch (error) {
        yield put(groupsActions.saveGroupsDataFailure(error));
    }
}

function* deleteGroups({payload}) {
    try {
        yield api.delete(getRoute(apiEndpoints.groups.delete, {id: payload}));

        yield put(groupsActions.deleteGroupsSuccess());
        yield put(groupsActions.fetchGroupsDataStart());
        yield put(groupsActions.listGroupsStart());
    } catch (error) {
        yield put(groupsActions.deleteGroupsFailure(error));
    }
}

function* addRecipients({payload}) {
    try {
        yield processRecipients(payload.id, {
            recipients: payload.recipients,
            mode: 'add',
        });

        yield put(groupsActions.addRecipientsToGroupDataSuccess());
        yield put(groupsActions.fetchGroupsDataStart());
        yield put(groupsActions.listGroupsStart());
    } catch (error) {
        yield put(groupsActions.addRecipientsToGroupDataFailure(error));
    }
}

function* removeRecipients({payload}) {
    try {
        yield processRecipients(payload.id, {
            recipients: payload.recipients,
            mode: 'remove',
        });

        yield put(groupsActions.removeRecipientsFromGroupDataSuccess());
        yield put(groupsActions.fetchGroupsDataStart());
        yield put(groupsActions.listGroupsStart());
    } catch (error) {
        yield put(groupsActions.removeRecipientsFromGroupDataFailure(error));
    }
}

function* processRecipients(id, data) {
    const route = getRoute(apiEndpoints.groups.putRecipients, {id});
    return yield api.post(route, data);
}

function* validateSmartCampaignGroups({payload}) {
    try {
        const url = `${apiEndpoints.groups.validateSmartRecipients}?id=${payload.id}&group=${payload.group}&company_contact_property_id=${payload.company_contact_property_id}&is_milestone=${payload.isMilestone}`;
        const res = yield api.get(url).then((res) => res.data);

        yield put(groupsActions.validateSmartCampaignGroupSuccess(res));
    } catch (e) {
        yield put(groupsActions.validateSmartCampaignGroupFailure(e));
    }
}

export default function* groupsSaga() {
    yield all([
        takeLeading(groupsActions.LIST_GROUPS_START, listGroups),
        takeEvery(groupsActions.FETCH_GROUPS_DATA_START, fetchGroupsData),
        takeEvery(groupsActions.SAVE_GROUPS_DATA_START, saveGroup),
        takeEvery(groupsActions.ADD_RECIPIENTS_TO_GROUP_DATA_START, addRecipients),
        takeEvery(groupsActions.REMOVE_RECIPIENTS_FROM_GROUP_DATA_START, removeRecipients),
        takeEvery(groupsActions.DELETE_GROUPS_START, deleteGroups),
        takeEvery(groupsActions.VALIDATE_SMART_CAMPAIGN_GROUP_START, validateSmartCampaignGroups),
    ]);
}
