import {API_URL, BUILDER_URL} from 'config/url';
import axios from 'axios';

export const BUILD_FAILED_ERROR = 'build_failed';
export const CANCELLED_ERROR = 'canceled';
export const THROTTLED_ERROR = 'throttled_error';

export const REDIRECT_ERROR = 'redirect_error';
export const RESET_ERROR_AFTER_MS = 5 * 60 * 1000; // 5 minutes

export const REDIRECT_STATUSES = [401, 419];

export const ERROR_COUNT_HASH = {};

export const CANCEL_SOURCE = axios.CancelToken.source();

export const headers = {
    multipart: {'content-type': 'multipart/form-data'},
};

export const METHODS = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete',
    PATCH: 'patch',
};

export const apiEndpoints = {
    api: API_URL,
    designerUrl: BUILDER_URL,
    account: {
        get: '/account',
        getPendingDeposits: '/account/pending-deposits',
        put: '/account',
        forgotPassword: '/password/forgot',
        resetPassword: '/password/reset',
        uploadAvatar: '/file/upload/account/avatar',
        resendEmailVerification: '/verify_email/resend',
        walkthrough: '/account/walkthrough/:item',
        toggle: '/account/:id/toggle',
    },
    adjustments: {
        get: 'order/:id/adjustments',
    },
    approvals: {
        approve: '/approvals/order/approve',
        indexAll: '/approvals/order/history',
    },
    auth: {
        status: '/auth/lock-status',
        lookup: '/auth/client_lookup/:email',
        cognito: '/auth/login/cognito',
        csrf: '/csrf-cookie',
        check: '/auth/check',
        login: '/auth/login',
        logout: '/auth/logout',
        loginAs: '/auth/loginAs/:id',
    },
    brandKits: {
        get: '/brand-kits/:id',
        list: '/brand-kits',
        create: '/brand-kits',
        delete: '/brand-kits/:id',
        deleteLogo: '/brand-kits/:id/logo',
        clone: '/brand-kits/:id/clone',
        uploadLogo: '/brand-kits/:id',
        update: '/brand-kits/:id',
        preview: '/brand-kits/preview',
        getMessages: '/brand-kits/:id/messages',
        getMessage: '/brand-kits/:brandKitId/message/:messageId',
        getSenderNames: '/brand-kits/:id/sender-names',
        saveMessage: '/saved_messages',
        updateMessage: '/saved_messages/:messageId',
        deleteMessage: '/saved_messages/:id',
    },
    broadcasting: {
        authorization: {
            channel: '/broadcast/auth/channel',
            user: '/broadcast/auth/user',
        },
        notifications: {
            count: '/notifications/count',
            subscriptions: '/notification/:groupId/group/subscriptions',
            toggleSubscription: '/notification/group/:groupId/:action',
            toggleSubscriptions: '/notification/group/:action',
            mark: '/notifications/batch',
            search: '/notifications/search',
            groups: '/notification/subscriptions',
        },
    },
    bulkDownload: {
        invoices: {
            download: '/bulk_download',
        },
    },
    businessHours: {
        get: '/business_hours',
    },
    campaigns: {
        search: '/campaigns/search',
        list: '/campaigns/list',
        show: '/campaigns/:id',
        createStandard: '/campaigns/standard',
        createSmart: '/campaigns/smart',
        updateStandard: '/campaigns/standard/:id',
        updateSmart: '/campaigns/smart/:id',
        extendSmartCampaign: '/campaigns/smart/extend/:id',
        toggle: '/campaigns/:id/toggle',
        changeOwner: '/campaigns/:id/change_owner/:accountId',
        delete: '/campaigns/:id',
        setContext: 'campaigns/set-context',
    },
    campaignManagement: {
        get: '/campaign_management',
        show: '/campaign_management/:id',
        createStandard: '/campaigns/standard',
        updateStandard: '/campaigns/standard/:id',
        createSmart: '/campaigns/smart',
        updateSmart: '/campaigns/smart/:id',
        createApi: '/campaigns/api',
        updateApi: '/campaigns/api/:id',
    },
    campaignCreditCards: {
        get: '/payment_methods/campaigns/:campaignId',
    },
    campaignTypes: {
        get: '/campaigns/types',
    },
    cart: {
        get: '/cart',
        post: '/cart',
        put: '/cart',
    },
    checkoutLookup: {
        get: '/denomination/lookup',
    },
    companies: {
        get: '/companies',
        put: '/companies',
        updateSettings: '/companies/settings',
        uploadLogo: '/file/upload/company/logo',
    },
    coupons: {
        get: '/:type/coupon/:value',
    },
    creditCards: {
        delete: '/payment_methods/:id',
        get: '/payment_methods',
        post: '/payment_methods',
        put: '/payment_methods/:id',
    },
    descendants: {
        teams: '/teams/descendants',
        accounts: '/teams/members/descendants',
        campaigns: '/campaigns/descendants',
    },
    digitalOrders: {
        indexAll: '/digital/order/history',
        index: '/digital/order/history?team_id=:id',
        submit: '/digital/order/checkout/:type',
        get: '/digital/order/:id',
        reorder: '/digital/orders/reorder',
        export: '/file/download/digital/report',
        preview: '/digital/preview/order',
        previewCheckout: '/digital/preview/checkout',
        sample: '/digital/send_sample/checkout',
        cancel: '/digital/orders',
        recentHistory: '/digital/order/recent_history?team_id=:id',
        cancelBulkList: '/digital/order/bulk/:id',
        getBulkList: '/digital/bulk/:id',
        getRecipients: '/digital/:type/:id/emails',
        bulkInvoice: '/digital/bulk/:id/invoice',
        digitalInvoice: '/digital/order/:id/invoice',
        unmask: '/digital/order/:id/unmaskRecipient',
    },
    funding: {
        history: '/funds/history',
        export: '/file/download/funding/report',
        request: '/funds/request',
        deposit: '/funds/add',
        transfer: '/funds/transfer',
        transferUpload: '/file/upload/funds/transfer',
        deleteFile: '/file/upload/funds/transfer',
        processUploadTransfer: '/funds/process_upload_transfer',
        createInvoice: '/funds/invoice/:id',
        get: '/funds/:id',
        delete: '/funds/:id',
        updateNotes: '/funds/note_update/:id',
        getRoundUpTransferAmount: '/funds/round-up-amount/:amount',
        getFundingAmount: '/funds/lookupTotal',
    },
    fundingMethods: {
        get: '/funds/funding_methods',
    },
    groups: {
        get: '/groups/search',
        list: '/groups/list',
        getByCampaign: 'groups/campaigns/:id',
        listByCampaign: 'groups/campaigns/:id/list',
        post: '/groups',
        update: '/groups/:id',
        delete: '/groups/:id',
        putRecipients: '/recipients/groups/:id',
        toggleActive: '/recipients/status',
        validateSmartRecipients: '/campaigns/smart/validate-recipients',
    },
    importContacts: {
        save: '/import/contacts',
        get: '/import/contacts/:integration_id',
    },
    integrations: {
        callback: '/import/contacts/:integration/callback',
        get_user_integrations: '/integrations/user',
        toggle_active: '/integrations/user/:field/:integrationId/:status',
        update: '/integrations/user/:integrationId',
        store_token: '/integration/token',
        sync_preload: '/import/filter/preload',
        syncs: '/import/filter/sync',
        // merge
        save: '/integration/import',
        access: {
            list: '/integration/access/list',
            unlink: '/integration/access/:id/unlink',
        },
        filter: '/integrations/filter',
        get: '/integrations',
        link_token: '/integrations/link-token',
        exchange_token: '/integrations/exchange-token',
        auth: '/integrations/auth/:token',
        auth_complete: '/integrations/auth/complete',
        validate: '/integration/validate',
        scopes: '/integration/scopes',
        initialize: '/integrations/:id/initialize',
        vendor: {
            list: '/vendor/integrations',
        },
        sync: {
            put: '/syncs/:sync_id',
            put_name: '/syncs/:sync_id/name',
            put_status: '/syncs/statuses',
            post: '/syncs',
            all: '/syncs',
            get: '/syncs/:id',
            delete: '/syncs',
        },
    },
    invites: {
        get: '/invites',
        addExisting: '/invites/team/:id',
        createEmail: '/invites/email',
        createLink: '/invites/link',
        cancel: '/invites/:id',
        validate: '/invites/login/:key',
        resend: '/invites/:id',
        validateEmail: 'invites/email/check?email=:email',
    },
    mfa: {
        enable: '/mfa/enable',
        disable: '/mfa/disable',
    },
    orderHistory: {
        count: '/orders/count',
    },
    orderSummary: {
        get: '/cart/:campaignId',
        post: '/cart',
        put: '/cart',
    },
    physicalOrders: {
        indexAll: '/physical/order/history',
        index: '/physical/order/history?team_id=:id',
        get: '/physical/order/:id',
        reorder: '/physical/order/:id/reorder',
        export: '/file/download/physical/report',
        cancel: '/physical/order/:id',
        shippingRates: '/physical/shipping_rates',
        submit: '/physical/order/checkout',
        createInvoice: '/physical/order/:id/invoice',
        recentHistory: '/physical/order/history?team_id=:id',
        preview: '/physical/order/preview',
    },
    primary_products: {
        list: '/primary_products',
        all: '/primary_products/all',
        campaign: '/primary_products/:campaignId',
    },
    products: {
        designer: '/builderAuth',
        get: '/products',
        getProduct: '/products/:productId',
        getByCampaign: '/products/campaigns/:campaignId',
    },
    public_api: {
        create: 'v1/public/key',
        list: 'v1/public/key/list',
        revoke: 'v1/public/key/revoke',
        delete: 'v1/public/key/delete',
        subscription: 'v1/public/webhook',
    },
    recipientProperties: {
        get: '/recipients/properties',
        create: '/recipients/properties',
        delete: '/recipients/properties/:id',
    },
    recipients: {
        search: '/recipients/search',
        get: '/recipients/search',
        show: '/recipients/:id',
        toggle: '/recipients/:id/status',
        create: '/recipients',
        delete: '/recipients/:id',
        deleteInactive: 'recipients/inactive',
        update: '/recipients/:id',
        processToGroups: '/recipients/:id/groups',
        upload: '/file/upload/recipients',
        uploadCheck: '/file/upload/recipients/check',
        uploadBulk: '/file/upload/bulk_list',
        uploadMembers: '/file/upload/member_list',
        downloadTemplate: '/file/upload/recipients/template',
        download: '/file/download/recipients',
    },
    regions: {
        get: '/regions',
        all: '/regions/all',
    },
    reports: {
        index: '/file/download/reports',
        download: '/file/download/reports/:uuid',
    },
    savedDesign: {
        post: '/saved_designs',
        get: '/saved_designs',
        list: '/saved_designs/list',
        getByCampaign: '/saved_designs/campaigns/:campaignId',
        listByCampaign: '/saved_designs/campaigns/:campaignId/list',
        delete: '/saved_designs/:id',
    },
    savedMessages: {
        get: '/saved_messages',
        create: '/saved_messages',
        update: '/saved_messages/:id',
        delete: '/saved_messages/:id',
    },
    senderNames: {
        get: '/sender_names',
        create: '/sender_names',
        update: '/sender_names/:id',
        delete: '/sender_names/:id',
        search: '/sender_names/search',
        postTemp: '/sender_names/temporary',
        clearDefault: '/sender_names/remove_defaults',
    },
    shippingAddress: {
        index: '/shipping_addresses',
        index_campaign: '/shipping_addresses/campaigns/:id',
        get: '/shipping_addresses/:id',
        geocode: '/shipping_addresses/geocode',
        create: '/shipping_addresses',
        update: '/shipping_addresses/:id',
        default: '/shipping_addresses/:id/default',
        delete: '/shipping_addresses/:id',
    },
    signUp: {
        validateEmail: '/new/check',
        post: '/new/account',
        newInvite: '/new/invite',
        inviteLookup: '/invitation/:key',
        finishCompany: '/new/company',
    },
    teams: {
        get: '/teams/account',
    },
    team: {
        get: '/teams',
        changeContext: '/team/change/:id',
        create: '/teams',
        clone: '/teams/clone/:id',
        delete: '/teams/:id',
        update: '/teams/:id',
    },
    team_members: {
        get: '/teams/:id/members',
        changeRole: '/teams/:id/members/:memberId/roles',
        remove: '/teams/:id/members/:memberId',
    },
    togglePins: {
        range: '/order/:orderId/batches/:itemId',
        all: '/order/:orderId/batches',
    },
    validate: {
        recipientsToCampaigns: '/campaigns/:id/check_recipients',
    },
};

axios.defaults.withCredentials = true;
axios.defaults.baseURL = apiEndpoints.api;
