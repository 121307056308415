import {all, put, select, takeEvery, takeLeading} from 'redux-saga/effects';

import campaignCreditCardsActions from './actions';
import {apiEndpoints} from 'config/api';
import {getRoute} from 'library/helpers/routing';
import {api} from 'library/helpers/api';
import {CAMPAIGN_CREDIT_CARDS_STORE} from 'config/redux';
import {localFetch} from 'library/helpers/redux';
import {setLocalStore} from 'library/helpers/localStore';
import {getCurrentCampaignId} from 'redux/selectors';
import creditCardsActions from 'redux/creditCards/actions';
import profileActions from '../profile/actions';

function* fetchCampaignCreditCards({payload}) {
    const cachedCards = yield localFetch(payload, CAMPAIGN_CREDIT_CARDS_STORE);

    if (cachedCards) {
        return yield put(campaignCreditCardsActions.fetchCampaignCreditCardsDataSuccess(cachedCards));
    }

    const campaignId = yield select((state) => getCurrentCampaignId(state));
    if (campaignId === 0) {
        return yield put(campaignCreditCardsActions.fetchCampaignCreditCardsDataSuccess([]));
    }

    const route = getRoute(apiEndpoints.campaignCreditCards.get, {
        campaignId: campaignId,
    });

    let creditCards = yield api.get(route);

    creditCards.data = mapFields(creditCards.data);

    setLocalStore(CAMPAIGN_CREDIT_CARDS_STORE, creditCards.data);

    yield put(campaignCreditCardsActions.fetchCampaignCreditCardsDataSuccess(creditCards.data));
}

const mapFields = (creditCards) => {
    return creditCards.map((creditCard) => {
        creditCard.brand = creditCard.brand.toLowerCase();

        return creditCard;
    });
};

function* forceFetch() {
    try {
        yield fetchCampaignCreditCards({
            payload: {
                forceFetch: true,
            },
        });
    } catch (err) {
        console.log(err);
    }
}

export default function* creditCardsSaga() {
    yield all([
        takeLeading(campaignCreditCardsActions.FETCH_CAMPAIGN_CREDIT_CARDS_DATA_START, fetchCampaignCreditCards),
        takeEvery(creditCardsActions.UPDATE_DEFAULT_CREDIT_CARDS_SUCCESS, forceFetch),
        takeEvery(creditCardsActions.DELETE_CREDIT_CARDS_SUCCESS, forceFetch),
        takeLeading(profileActions.UPDATE_DATA_CONTEXT_CAMPAIGN_ID_SUCCESS, forceFetch),
    ]);
}
