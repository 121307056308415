import savedDesignActions from './actions';

const INITIAL_DATA = {
    data: null,
    loading: false,
    deleted: false,
    error: null,
};

const savedDesignsReducer = (state = INITIAL_DATA, action) => {
    switch (action.type) {
        case savedDesignActions.FETCH_SAVED_DESIGN_DATA_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case savedDesignActions.FETCH_SAVED_DESIGN_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null,
            };
        case savedDesignActions.FETCH_SAVED_DESIGN_DATA_FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload,
            };

        case savedDesignActions.DELETE_SAVED_DESIGN_START:
            return {
                ...state,
                deleted: false,
                error: null,
            };
        case savedDesignActions.DELETE_SAVED_DESIGN_SUCCESS:
            return {
                ...state,
                deleted: true,
                error: null,
            };
        case savedDesignActions.DELETE_SAVED_DESIGN_FAILURE:
            return {
                ...state,
                deleted: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default savedDesignsReducer;
