import {DISPERSAL_METHODS} from 'containers/TransferFunds/forms/TransferFundsForm';

export const UPLOAD_BULK = 'bulk';
export const UPLOAD_INVITE = 'members';
export const UPLOAD_FUNDS_TRANSFER = 'funds_transfer';

export const SAMPLE_DATA = {
    [UPLOAD_BULK]: {
        headers:
            '"Email Address","Denomination (Optional, must be in increments of $5)","First Name (Optional)","Last Name (Optional)"',
        fileName: 'Giftogram_List_Template.csv',
    },
    [UPLOAD_INVITE]: {
        headers: "\"Email Address\", \"Roles ('gifting', 'reporting', 'team-admin')\"",
        fileName: 'Giftogram_Members_Template.csv',
    },
    [UPLOAD_FUNDS_TRANSFER]: {
        headers: '"To Email", "Dispersal Type", "Amount"',
        sampleRows: [
            ['to@example.com,' + DISPERSAL_METHODS.ROUND_UP.key + ',100'],
            ['to1@example.com,full_amount,100'],
        ],
        fileName: 'Giftogram_Funds_Transfer_Template.csv',
    },
};
