import React from 'react';

const icon = () => (
    <svg viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <circle fill="#F0F0F0" cx="256" cy="256" r="256"></circle>
                <path
                    d="M256,0 C154.506,0 66.81,59.065 25.402,144.696 L486.597,144.696 C445.19,59.065 357.493,0 256,0 Z"
                    id="Path"
                    fill="#FF9811"
                ></path>
                <path
                    d="M256,512 C357.493,512 445.19,452.935 486.598,367.304 L25.402,367.304 C66.81,452.935 154.506,512 256,512 Z"
                    id="Path"
                    fill="#6DA544"
                ></path>
                <circle fill="#0052B4" cx="256" cy="256" r="89.043"></circle>
                <circle fill="#F0F0F0" cx="256" cy="256" r="55.652"></circle>
                <polygon
                    fill="#0052B4"
                    points="256 187.326 273.169 226.264 315.473 221.663 290.337 256 315.473 290.337 273.169 285.736 256 324.674 238.831 285.736 196.527 290.336 221.663 256 196.527 221.663 238.831 226.264"
                ></polygon>
            </g>
        </g>
    </svg>
);

export default icon;
