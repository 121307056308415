import {apiEndpoints} from 'config/api';
import {api} from 'library/helpers/api';
import {getRoute} from 'library/helpers/routing';
import {objectToQuerystring} from 'library/helpers/utilities';

const fetcher = async (route) => await api.get(route).then(({data}) => data);
const putter = async (route, data) => await api.put(route, data).then(({data}) => data);
const deleter = async (route) => await api.delete(route).then(({data}) => data);
const poster = async (route, data) => await api.post(route, data).then(({data}) => data);
const uploader = async (route, data) =>
    await api.post(route, data, {headers: {'Content-Type': 'multipart/form-data'}}).then(({data}) => data);

const brandKitsService = {
    fetchBrandKitPreview: async (query) =>
        await fetcher(apiEndpoints.brandKits.preview + '?' + objectToQuerystring(query)),
    fetchBrandKit: async (id) => await fetcher(getRoute(apiEndpoints.brandKits.get, {id})),
    fetchBrandKits: async (query) => await fetcher(apiEndpoints.brandKits.list + '?' + objectToQuerystring(query)),
    cloneBrandKit: async (id) => await putter(getRoute(apiEndpoints.brandKits.clone, {id})),
    createBrandKit: async (name) => await poster(apiEndpoints.brandKits.create, {name}),
    deleteBrandKit: async (id) => await deleter(getRoute(apiEndpoints.brandKits.delete, {id})),
    updateBrandKit: async ({id, data}) => await putter(getRoute(apiEndpoints.brandKits.update, {id}), data),
    uploadBrandKitLogo: async ({id, data}) => await uploader(getRoute(apiEndpoints.brandKits.uploadLogo, {id}), data),

    fetchBrandKitMessages: async ({id, query}) =>
        await fetcher(getRoute(apiEndpoints.brandKits.getMessages, {id}) + '?' + objectToQuerystring(query)),
    createBrandKitMessage: async (data) => await poster(apiEndpoints.brandKits.saveMessage, data),
    updateBrandKitMessage: async ({messageId, data}) =>
        await putter(getRoute(apiEndpoints.brandKits.updateMessage, {messageId}), data),
    deleteBrandKitMessage: async (id) => await deleter(getRoute(apiEndpoints.brandKits.deleteMessage, {id})),
    fetchBrandKitMessage: async ({brandKitId, messageId}) =>
        await fetcher(getRoute(apiEndpoints.brandKits.getMessage, {brandKitId, messageId})),

    updateBrandKitSenderName: async ({id, data}) => {
        return await putter(getRoute(apiEndpoints.senderNames.update, {id}), data);
    },
    createBrandKitSenderName: async ({data}) => await poster(apiEndpoints.senderNames.create, data),
    makeDefaultBrandKitSenderName: async ({id, data}) =>
        await putter(getRoute(apiEndpoints.senderNames.update, {id}), data),
    deleteBrandKitSenderName: async (id) => await deleter(getRoute(apiEndpoints.senderNames.delete, {id})),
    fetchBrandKitSenderNames: async ({id, query}) =>
        await fetcher(getRoute(apiEndpoints.brandKits.getSenderNames, {id}) + '?' + objectToQuerystring(query)),
};

export default brandKitsService;
