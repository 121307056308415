export const SMART_CAMPAIGN_TYPE_IDS = [5, 6, 7];

export const SMART_CAMPAIGN_TYPE_NAMES = ['smart_enrollment', 'smart_milestone', 'smart_yearly', 'event'];

export const API_CAMPAIGN_TYPE_ID = 2;

export const API_CAMPAIGN_TYPE_NAME = 'api';

export const CAMPAIGN_TYPE_IDENTIFIERS = {
    API: 'api',
    SMART: 'smart',
    STANDARD: 'standard',
};
