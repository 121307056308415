import React from 'react';
import PropTypes from 'prop-types';
import {Layout, PageHeader, Typography, Col, Empty, Row, Breakpoint, FontAwesome} from 'components/UI';
import {siderWidth} from 'assets/styles/common';

const {Sider, Content} = Layout;
const {Paragraph} = Typography;

export const CheckoutLayout = (props) => {
    return (
        <Layout className={props.layoutClassName && props.layoutClassName}>
            {props.pageHeader && (
                <PageHeader
                    title={props.title}
                    back={props.back}
                    backLabel={props.backLabel}
                    onBack={props.onBack}
                    buttons={props.buttons}
                    extra={props.extra}
                    content={props.titleContent}
                    ghost={props.ghost}
                    footer={props.tabs || props.footer}
                />
            )}
            {props.alert}
            {!props.empty && props.extraButtons && (
                <Breakpoint
                    xs={false}
                    sm={false}
                    md={false}
                    lg={false}
                    inverse={<div className="checkout-extra-buttons">{props.extraButtons}</div>}
                />
            )}

            {!props.empty ? (
                <Layout key="main-content">
                    <Content
                        className={
                            (props.paddedContent ? 'main-content' : '') +
                            ' ' +
                            (props.contentClassName ? props.contentClassName + ' ' : '') +
                            'has-chat'
                        }
                    >
                        {props.content}
                    </Content>
                    {props.sider && (
                        <Breakpoint xs={false} sm={false} md={false} lg={false}>
                            <Sider width={props.siderWidth} theme={props.theme} className="has-chat">
                                {props.sider}
                            </Sider>
                        </Breakpoint>
                    )}
                </Layout>
            ) : (
                <Layout key="empty-content">
                    <Content className={'main-content content-centered'}>
                        <Row justify="center" style={{width: '100%'}}>
                            <Col xs={24} md={12} xl={8}>
                                <Empty image={<FontAwesome empty />} description={props.emptyDescription}>
                                    <Paragraph>{props.emptyText}</Paragraph>
                                </Empty>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            )}
        </Layout>
    );
};

CheckoutLayout.defaultProps = {
    back: false,
    emptyIcon: 'emptyDesigns',
    ghost: false,
    paddedContent: true,
    pageHeader: true,
    sider: false,
    siderWidth: siderWidth,
    theme: 'light',
};

CheckoutLayout.propTypes = {
    alert: PropTypes.any,
    back: PropTypes.bool,
    backLabel: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    onBack: PropTypes.func,
    buttons: PropTypes.array,
    content: PropTypes.any,
    contentClassName: PropTypes.string,
    empty: PropTypes.any,
    emptyDescription: PropTypes.string,
    emptyText: PropTypes.string,
    extra: PropTypes.any,
    extraButtons: PropTypes.any,
    footer: PropTypes.any,
    ghost: PropTypes.bool.isRequired,
    layoutClassName: PropTypes.string,
    paddedContent: PropTypes.bool.isRequired,
    pageHeader: PropTypes.bool.isRequired,
    sider: PropTypes.any.isRequired,
    siderWidth: PropTypes.number.isRequired,
    tabs: PropTypes.any,
    title: PropTypes.any,
    titleContent: PropTypes.any,
    theme: PropTypes.any.isRequired,
};

export default CheckoutLayout;
