import shippingAddressCampaignActions from './actions';

import {LOGOUT} from 'redux/auth/auth.types';

const initialState = {
  data: null,
  error: false,
  loading: false,
  updated: false,
};

const shippingAddressCampaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case shippingAddressCampaignActions.FETCH_SHIPPING_ADDRESSES_CAMPAIGN_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case shippingAddressCampaignActions.FETCH_SHIPPING_ADDRESSES_CAMPAIGN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case shippingAddressCampaignActions.FETCH_SHIPPING_ADDRESSES_CAMPAIGN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default shippingAddressCampaignReducer;
