import {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getIntegrations} from 'redux/integrations/selectors';

import {
    createSyncStart,
    deleteSyncJobsStart,
    initializeIntegrationStart,
    loadSyncsStart,
    loadSyncStart,
    loadUserIntegrationsStart,
    resetSyncEditMode,
    resetSyncJob as resetSyncJobAction,
    resetSyncFilters as resetSyncFiltersAction,
    resetSyncs as resetSyncsAction,
    saveSyncJobStart,
    setIntegrationError,
    setIntegrationSuccess,
    setSavingIntegration,
    setUpdatingIntegration,
    updateSyncJobStart,
    updateSyncMapStart,
    updateSyncNameStart,
    updateSyncStatusStart,
    resetSyncCreated as resetSyncCreatedAction,
} from 'redux/integrations/actions';
import {getContactProperties} from 'redux/contactProperties/selectors';
import {getRoute} from 'library/helpers/routing';
import {useHistory} from 'react-router-dom';
import useReaction from 'hooks/utility/useReaction';
import {routes} from 'config/routes';

const baseMappingSelectorFields = [
    {key: 'ignore', value: 'Ignore'},
    {key: 'first_name', value: 'First Name'},
    {key: 'last_name', value: 'Last Name'},
    {key: 'email', value: 'Email Address'},
    {key: 'company', value: 'Company'},
    {key: 'department', value: 'Department'},
    {key: 'mobile_phone', value: 'Mobile #'},
    {key: 'remote_id', value: 'Internal ID'},
];

export default function useIntegrationsStore() {
    const dispatch = useDispatch();
    const {push} = useHistory();
    const {data: contactProperties} = useSelector(getContactProperties);
    const store = useSelector(getIntegrations);

    const mapFields = useMemo(() => {
        const fields = [...baseMappingSelectorFields];

        if (contactProperties && Array.isArray(contactProperties)) {
            contactProperties.forEach((property) => {
                fields.push({key: property.id, value: property.name});
            });
        }

        return fields;
    }, [contactProperties]);

    const resetSyncCreated = useCallback(() => dispatch(resetSyncCreatedAction()), [dispatch]);
    const resetError = useCallback(() => dispatch(setIntegrationError('')), [dispatch]);
    const resetSuccess = useCallback(() => dispatch(setIntegrationSuccess('')), [dispatch]);
    const setSavingSync = useCallback((payload) => dispatch(setSavingIntegration(payload)), [dispatch]);
    const setUpdatingSync = useCallback((payload) => dispatch(setUpdatingIntegration(payload)), [dispatch]);
    const loadUserIntegrations = useCallback((payload) => dispatch(loadUserIntegrationsStart(payload)), [dispatch]);
    const createSync = useCallback((payload) => dispatch(createSyncStart(payload)), [dispatch]);
    const loadSyncs = useCallback((payload) => dispatch(loadSyncsStart(payload)), [dispatch]);
    const loadSync = useCallback((payload) => dispatch(loadSyncStart(payload)), [dispatch]);
    const updateSync = useCallback((payload) => dispatch(updateSyncJobStart(payload)), [dispatch]);
    const updateSyncName = useCallback((payload) => dispatch(updateSyncNameStart(payload)), [dispatch]);
    const updateSyncStatus = useCallback((payload) => dispatch(updateSyncStatusStart(payload)), [dispatch]);
    const saveSyncJob = useCallback((payload) => dispatch(saveSyncJobStart(payload)), [dispatch]);
    const deleteSyncJobs = useCallback((payload) => dispatch(deleteSyncJobsStart(payload)), [dispatch]);
    const initializeStore = useCallback((payload) => dispatch(initializeIntegrationStart(payload)), [dispatch]);
    const updateMap = useCallback((payload) => dispatch(updateSyncMapStart(payload)), [dispatch]);
    const resetSyncs = useCallback(() => dispatch(resetSyncsAction()), [dispatch]);
    const resetSyncFilters = useCallback(() => dispatch(resetSyncFiltersAction()), [dispatch]);
    const resetSyncEdit = useCallback(() => dispatch(resetSyncEditMode()), [dispatch]);
    const resetSyncJob = useCallback(() => dispatch(resetSyncJobAction()), [dispatch]);
    const gotoSync = useCallback(
        (syncId) => {
            push(getRoute(routes.private.paths.INTEGRATIONS_SYNC_JOB_EDIT, {id: syncId}));
        },
        [push],
    );

    const {alertErrorElement} = useReaction({
        value: store?.error_message,
        type: 'error',
        condition: store?.error_message,
        content: store?.error_message,
        react: false,
    });

    const useSuccessReaction = () =>
        useReaction({
            value: store?.success_message,
            type: 'error',
            condition: store?.success_message,
            content: store?.success_message,
            callback: () => {
                resetSuccess();
            },
        });

    return {
        resetSyncCreated,
        resetSyncFilters,
        resetSyncs,
        resetError,
        resetSyncEdit,
        resetSyncJob,
        updateSyncStatus,
        updateSyncName,
        setUpdatingSync,
        setSavingSync,
        store,
        gotoSync,
        loadSync,
        deleteSyncJobs,
        loadUserIntegrations,
        loadSyncs,
        updateSync,
        saveSyncJob,
        updateMap,
        mapFields,
        initializeStore,
        createSync,
        alertErrorElement,
        useSuccessReaction,
    };
}
