import {CAMPAIGN_MANAGEMENT_FORM, MESSAGE_FORM, SEND_FORM} from 'config/forms';
import {match} from 'library/helpers/utilities';
import {getDefaultSendData} from 'library/helpers/send';
import storage from 'redux-persist/lib/storage';
import {sendResetFilter, sendSetFilter} from 'redux/forms/filters/send';
import {campaignManagementDefaultState} from '../../providers/CampaignContext';

export const getDefaultState = (formName, __global = null, {campaigns, profile = null} = {}) => {
    return match(formName, {
        [MESSAGE_FORM]: () => ({}),
        [CAMPAIGN_MANAGEMENT_FORM]: () => campaignManagementDefaultState.formData,
        [SEND_FORM]: () => getDefaultSendData({profile: __global?.profile ?? profile, campaigns}),
    });
};

export const formsPersistConfig = {
    key: 'forms',
    storage,
    transforms: [sendSetFilter, sendResetFilter],
};
