import {all, put, takeLeading} from 'redux-saga/effects';
import ordersActions from './actions';
import {apiEndpoints} from 'config/api';
import {api} from 'library/helpers/api';
import {buildQuery} from 'library/helpers/routing';
import {DIGITAL_ORDER, APPROVALS_ORDER} from 'config/orders';
import {appendKey, match} from 'library/helpers/utilities';
import omit from 'lodash/omit';

function* fetchOrders(type, query) {
    const root = match(type, {
        [DIGITAL_ORDER]: apiEndpoints.digitalOrders.indexAll,
        [APPROVALS_ORDER]: apiEndpoints.approvals.indexAll,
        default: apiEndpoints.physicalOrders.indexAll,
    });

    const url = `${root}?${query}`;

    return yield api.get(url).then((response) => response.data);
}

function* fetchOrderDataEffect(orderData) {
    const payload = omit(orderData.payload, ['type']);
    const query = buildQuery(payload);

    try {
        const orders = yield fetchOrders(orderData.payload.type, query);
        orders.data = appendKey(orders.data);

        yield put(ordersActions.fetchOrdersDataSuccess(orders));
    } catch (error) {
        yield put(ordersActions.fetchOrdersDataFailure(error));
    }
}

export default function* ordersSaga() {
    yield all([takeLeading(ordersActions.FETCH_ORDERS_DATA_START, fetchOrderDataEffect)]);
}
