export const SHOW_PASSWORD_RESET_MODAL = 'SHOW_PASSWORD_RESET_MODAL';
export const showPasswordResetModal = () => ({
    type: SHOW_PASSWORD_RESET_MODAL,
});
export const HIDE_PASSWORD_RESET_MODAL = 'HIDE_PASSWORD_RESET_MODAL';
export const hidePasswordResetModal = () => ({
    type: HIDE_PASSWORD_RESET_MODAL,
});

const profileActions = {
    SHOW_RESET_MODAL: 'SHOW_RESET_MODAL',
    showResetModal: () => ({
        type: profileActions.SHOW_RESET_MODAL,
    }),
    HIDE_RESET_MODAL: 'HIDE_RESET_MODAL',
    hideResetModal: () => ({
        type: profileActions.HIDE_RESET_MODAL,
    }),
    FETCH_PROFILE_DATA_START: 'FETCH_PROFILE_DATA_START',
    fetchProfileDataStart: (payload) => ({
        type: profileActions.FETCH_PROFILE_DATA_START,
        payload,
    }),
    FETCH_PROFILE_DATA_SUCCESS: 'FETCH_PROFILE_DATA_SUCCESS',
    fetchProfileDataSuccess: (profile) => ({
        type: profileActions.FETCH_PROFILE_DATA_SUCCESS,
        payload: profile,
    }),
    FETCH_PROFILE_DATA_FAILURE: 'FETCH_PROFILE_DATA_FAILURE',
    fetchProfileDataFailure: (error) => ({
        type: profileActions.FETCH_PROFILE_DATA_FAILURE,
        payload: error,
    }),

    UPDATE_PROFILE_DATA_START: 'UPDATE_PROFILE_DATA_START',
    UPDATE_PROFILE_DATA_SUCCESS: 'UPDATE_PROFILE_DATA_SUCCESS',
    UPDATE_PROFILE_DATA_FAILURE: 'UPDATE_PROFILE_DATA_FAILURE',
    RESET_UPDATED: 'RESET_UPDATED',

    resetUpdated: () => ({
        type: profileActions.RESET_UPDATED,
    }),

    updateProfileDataStart: (updateData) => ({
        type: profileActions.UPDATE_PROFILE_DATA_START,
        payload: updateData,
    }),

    updateProfileDataSuccess: () => ({
        type: profileActions.UPDATE_PROFILE_DATA_SUCCESS,
    }),

    updateProfileDataFailure: (error) => ({
        type: profileActions.UPDATE_PROFILE_DATA_FAILURE,
        payload: error,
    }),

    UPDATE_AVATAR_PROFILE_DATA_START: 'UPDATE_AVATAR_PROFILE_DATA_START',
    UPDATE_AVATAR_PROFILE_DATA_SUCCESS: 'UPDATE_AVATAR_PROFILE_DATA_SUCCESS',
    UPDATE_AVATAR_PROFILE_DATA_FAILURE: 'UPDATE_AVATAR_PROFILE_DATA_FAILURE',

    updateAvatarProfileDataStart: () => ({
        type: profileActions.UPDATE_AVATAR_PROFILE_DATA_START,
    }),

    updateAvatarProfileDataSuccess: (avatar) => ({
        type: profileActions.UPDATE_AVATAR_PROFILE_DATA_SUCCESS,
        payload: avatar,
    }),

    updateAvatarProfileDataFailure: (error) => ({
        type: profileActions.UPDATE_AVATAR_PROFILE_DATA_FAILURE,
        payload: error,
    }),

    UPDATE_CREDIT_AVAILABLE: 'UPDATE_CREDIT_AVAILABLE',

    updateCreditAvailable: (payload) => ({
        type: profileActions.UPDATE_CREDIT_AVAILABLE,
        payload,
    }),

    UPDATE_DATA_CONTEXT: 'UPDATE_DATA_CONTEXT',
    updateDataContext: (payload) => ({
        type: profileActions.UPDATE_DATA_CONTEXT,
        payload,
    }),

    UPDATE_DATA_CONTEXT_TEAM_ID_SUCCESS: 'UPDATE_DATA_CONTEXT_TEAM_ID_SUCCESS',
    updateDataContextTeamIdSuccess: (payload) => ({
        type: profileActions.UPDATE_DATA_CONTEXT_TEAM_ID_SUCCESS,
        payload,
    }),

    UPDATE_DATA_CONTEXT_CAMPAIGN_ID_SUCCESS: 'UPDATE_DATA_CONTEXT_CAMPAIGN_ID_SUCCESS',
    updateDataContextCampaignIdSuccess: (payload) => ({
        type: profileActions.UPDATE_DATA_CONTEXT_CAMPAIGN_ID_SUCCESS,
        payload,
    }),

    RESET_PROFILE_PASSWORD_DATA_START: 'RESET_PROFILE_PASSWORD_DATA_START',
    RESET_PROFILE_PASSWORD_DATA_SUCCESS: 'RESET_PROFILE_PASSWORD_DATA_SUCCESS',
    RESET_PROFILE_PASSWORD_DATA_FAILURE: 'RESET_PROFILE_PASSWORD_DATA_FAILURE',

    resetPasswordProfileDataStart: (resetPasswordData) => ({
        type: profileActions.RESET_PROFILE_PASSWORD_DATA_START,
        payload: resetPasswordData,
    }),

    resetPasswordProfileDataSuccess: () => ({
        type: profileActions.RESET_PROFILE_PASSWORD_DATA_SUCCESS,
        payload: '',
    }),

    resetPasswordProfileDataFailure: (payload) => ({
        type: profileActions.RESET_PROFILE_PASSWORD_DATA_FAILURE,
        payload,
    }),

    WALKTHROUGH_SET: 'WALKTHROUGH_SET',
    WALKTHROUGH_CLEAR: 'WALKTHROUGH_CLEAR',

    walkthroughSetItem: (item) => ({
        type: profileActions.WALKTHROUGH_SET,
        payload: item,
    }),

    walkthroughClearItem: (item) => ({
        type: profileActions.WALKTHROUGH_CLEAR,
        payload: item,
    }),
};

export default profileActions;
