import {Avatar, Flag, Tooltip} from 'components/UI/index';
import React from 'react';
import PropTypes from 'prop-types';

const CurrencyGroup = ({regions, style = {}, avatarProps = {}, tooltip, tooltipOverrideCallback}) => {
    return (
        <Avatar.Group size="small" maxCount={5} style={style}>
            {regions.map((region) => {
                return (
                    <Tooltip
                        title={
                            !tooltip
                                ? ''
                                : tooltipOverrideCallback
                                ? tooltipOverrideCallback(region)
                                : (region?.label ?? region?.country_name ?? '') +
                                  ' (' +
                                  (region?.currency ?? 'Error Loading') +
                                  ')'
                        }
                        key={region?.currency ?? ''}
                    >
                        <Avatar
                            size="small"
                            icon={<Flag key={region?.currency ?? ''} country={region?.currency ?? ''} size={1.25} />}
                            className="flag-avatar"
                            {...avatarProps}
                        />
                    </Tooltip>
                );
            })}
        </Avatar.Group>
    );
};

CurrencyGroup.defaultProps = {
    tooltip: true,
};

CurrencyGroup.propTypes = {
    avatarProps: PropTypes.object,
    regions: PropTypes.array.isRequired,
    style: PropTypes.object,
    tooltip: PropTypes.bool,
    tooltipOverrideCallback: PropTypes.func,
};

export default CurrencyGroup;
