import React from 'react';
import {Layout, Typography, Col, Row, message, Breakpoint, Button} from 'components/UI';
import {lang} from 'config/lang';
import logo from 'assets/images/giftogram-logo-light.svg';
import colorLogo from 'assets/images/giftogram-logo-color.svg';
import PropTypes from 'prop-types';
import {isSandbox} from "library/helpers/utilities";
import {routes} from "config/routes";

const {Header} = Layout;

const {Text} = Typography;

const AuthNav = ({label, linkTo, buttonText, mobileButtonText}) => {
    const onClick = () => {
        message.destroy();
    };

    const mobileButton = () => {
        return (
            <Button size="large" href={linkTo} onClick={onClick}>
                {mobileButtonText ? mobileButtonText : buttonText}
            </Button>
        );
    };

    const sandbox = isSandbox();
    const isSignIn = linkTo !== routes.public.paths.SIGN_IN;

    const suppressAuthNav = sandbox && isSignIn;

    return (
        <Header className="transparent-header static-header">
            <Row justify="space-between">
                <Col>
                    <div className="logo">
                        <a href="https://www.giftogram.com/">
                            <Breakpoint
                                xs={false}
                                sm={false}
                                inverse={<img src={colorLogo} alt={lang.brand_name} height={30} />}
                            >
                                <img src={logo} alt={lang.brand_name} height={30} />
                            </Breakpoint>
                        </a>
                    </div>
                </Col>

                {!suppressAuthNav && label && (
                    <Col flex="auto">
                        <Row justify="end" gutter={12}>
                            <Breakpoint xs={false} sm={false}>
                                <Col>
                                    <Text>{label}</Text>
                                </Col>
                            </Breakpoint>

                            <Col>
                                <Breakpoint xs={false} sm={false} inverse={mobileButton()}>
                                    <Button size="large" href={linkTo} onClick={onClick}>
                                        {buttonText}
                                    </Button>
                                </Breakpoint>
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>
        </Header>
    );
};

AuthNav.propTypes = {
    buttonStyles: PropTypes.any,
    buttonText: PropTypes.any,
    label: PropTypes.any,
    linkTo: PropTypes.any,
    mobileButtonText: PropTypes.any,
};

export default AuthNav;
