import React from 'react';
import {getCompany} from 'redux/selectors';
import {useSelector} from 'react-redux';
import SandboxBanner from 'components/NotificationBar/Sandbox/Banner';

const Alerts = () => {
    const company = useSelector((state) => getCompany(state));

    const notifyStatus = localStorage.getItem('notifyStatus0423');

    // Broadcast top page alerts here

    // const notifyDismiss = () => {
    //     localStorage.setItem('notifyStatus0423', 'dismissed');
    // };
    //
    // const broadcastBar = () => {
    //     return (
    //         <Alert
    //             key={'company_verification_alert'}
    //             banner={true}
    //             showIcon={false}
    //             type="hype"
    //             closeText="Dismiss"
    //             afterClose={notifyDismiss}
    //             message={
    //                 <span>
    //                     <span role="img" aria-label="party-popper" style={{fontSize: '21px', marginRight: '6px'}}>
    //                         👉
    //                     </span>{' '}
    //                     <Typography.Text>
    //                         We've made some exciting changes to support multiple currencies.
    //                     </Typography.Text>{' '}
    //                     <Typography.Link
    //                         href="https://blog.giftogram.com/send-gift-cards-internationally"
    //                         target="_blank"
    //                     >
    //                         <Typography.Text underline>Learn more</Typography.Text>
    //                     </Typography.Link>
    //                 </span>
    //             }
    //             closable
    //             style={{
    //                 textAlign: 'center',
    //             }}
    //         />
    //     );
    // };

    const getAlerts = () => {
        let alerts = [];

        if (company.data && notifyStatus !== 'dismissed') {
            alerts = [...alerts, <SandboxBanner key='sandbox-banner' />];
        }

        return alerts;
    };

    return getAlerts();
};

export default Alerts;
