import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Modal, List, Typography, Space} from 'components/UI';
import {useSelector} from 'react-redux';
import CatalogModalItem from '../CatalogModalItem';
import {getCompanyPrimaryProducts} from 'redux/primaryProducts/selectors';
import {lang} from 'config/lang';

const CatalogModal = ({productIds, visible, setVisible}) => {
    const companyPrimaryProducts = useSelector(getCompanyPrimaryProducts);

    const setVisibleCallback = useMemo(
        () => ({
            [true]: () => setVisible(true),
            [false]: () => setVisible(false),
        }),
        [setVisible],
    );

    return (
        <Modal
            open={visible}
            closable={false}
            maskClosable={true}
            width={500}
            onOk={setVisibleCallback['false']}
            onCancel={setVisibleCallback['false']}
            okButtonProps={{style: {display: 'none'}}}
            cancelText={lang.go_back}
            title={'Campaign Catalogs'}
        >
            <Space direction="vertical" size={8}>
                <Typography.Paragraph>
                    The following gift card catalogs are configured with this campaign:
                </Typography.Paragraph>
                <List
                    className="bulk-upload-list-results"
                    size="default"
                    loading={!companyPrimaryProducts}
                    itemLayout="horizontal"
                    dataSource={productIds}
                    renderItem={(product) => (
                        <CatalogModalItem product={product} companyPrimaryProducts={companyPrimaryProducts} />
                    )}
                />
            </Space>
        </Modal>
    );
};

CatalogModal.propTypes = {
    productIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    visible: PropTypes.bool.isRequired,
    setVisible: PropTypes.func.isRequired,
};

export default CatalogModal;
