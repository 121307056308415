const productActions = {
  FETCH_PRODUCT_DATA_START: 'FETCH_PRODUCT_DATA_START',
  fetchProductsStart: () => ({
    type: productActions.FETCH_PRODUCT_DATA_START,
  }),

  FETCH_PRODUCT_DATA_SUCCESS: 'FETCH_PRODUCT_DATA_SUCCESS',
  fetchProductsSuccess: (products) => ({
    type: productActions.FETCH_PRODUCT_DATA_SUCCESS,
    payload: products,
  }),

  FETCH_PRODUCT_DATA_FAILURE: 'FETCH_PRODUCT_DATA_FAILURE',
  fetchProductsError: (error) => ({
    type: productActions.FETCH_PRODUCT_DATA_FAILURE,
    payload: error,
  }),

  FILTER_PRODUCTS_CATEGORY: 'FILTER_PRODUCTS_CATEGORY',
  filterProductsCategory: (error) => ({
    type: productActions.FILTER_PRODUCTS_CATEGORY,
    payload: error,
  }),

  SET_CATEGORIES: 'SET_CATEGORIES',
  setCategories: (payload) => ({
    type: productActions.SET_CATEGORIES,
    payload,
  }),

  SET_CUSTOM: 'SET_CUSTOM',
  setCustom: (payload) => ({
    type: productActions.SET_CUSTOM,
    payload,
  }),

  SAVE_FILTER_PRODUCTS: 'SAVE_FILTER_PRODUCTS',
  saveFilteredProducts: (payload) => ({
    type: productActions.SAVE_FILTER_PRODUCTS,
    payload,
  }),
};

export default productActions;
