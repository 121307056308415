import React from 'react';
import {Modal as AntModal} from 'antd';
import {FontAwesome} from 'components/UI';
import PropTypes from 'prop-types';
import ModalFooter from './modules/ModalFooter';

export const Modal = (props) => {
    return (
        <AntModal
            {...props}
            onOk={props.onOk ?? props.handleOk}
            onCancel={props.onCancel ?? props.handleCancel}
            closeIcon={<FontAwesome icon="xmark" type="solid" size="lg" />}
            footer={props.footer && <ModalFooter modalProps={{...props}} />}
        />
    );
};

Modal.destroyAll = AntModal.destroyAll;

// TODO: Deprecated, move to default params
Modal.defaultProps = {
    cancelText: 'Go Back',
    closable: false,
    destroyOnClose: true,
    disabled: false,
    footer: true,
    loading: false,
    okText: 'Ok',
    showCancel: true,
    showOk: true,
};

Modal.propTypes = {
    afterClose: PropTypes.func,
    cancelButtonProps: PropTypes.any,
    cancelText: PropTypes.string,
    closable: PropTypes.bool.isRequired,
    destroyOnClose: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    footer: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func,
    handleOk: PropTypes.func,
    loading: PropTypes.bool.isRequired,
    okButtonProps: PropTypes.any,
    okText: PropTypes.string,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    showCancel: PropTypes.bool.isRequired,
    showOk: PropTypes.bool.isRequired,
};

export default Modal;
