import {PRICES} from 'config/orders/physical';

export const getCartQuantity = (items) => items.reduce((a, c) => a + c.quantity, 0);

export const calculateTax = (order, taxRate, discount) => {
    const itemTax = order.items.reduce((carry, item) => {
        if (!item.has_carriers && !item.has_sleeves) return carry;

        const itemPrice = PRICES[item.has_carriers ? 'greetingCards' : 'sleeves'];
        carry += taxRate * item.quantity * itemPrice;

        return Math.round(carry * 100) / 100;
    }, 0);

    const taxDiscount = taxRate * discount;

    return Number(itemTax - taxDiscount).toFixed(2);
};
