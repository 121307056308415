import {all, put, takeEvery} from 'redux-saga/effects';
import teamsActions from './actions';
import {apiEndpoints} from 'config/api';
import {getRoute} from 'library/helpers/routing';
import {api} from 'library/helpers/api';
import teamActions from 'redux/team/actions';
import descendantsActions from 'redux/descendants/actions';

function* fetchTeams() {
    try {
        const teams = yield api.get(apiEndpoints.teams.get);

        yield put(teamsActions.fetchTeamsDataSuccess(teams.data));
    } catch (error) {
        yield put(teamsActions.fetchTeamsDataFailure(error));
    }
}

function* createTeam({payload}) {
    const data = {name: payload.name, parent_team_id: payload.parent_team_id};

    try {
        const response = yield api.post(apiEndpoints.team.create, data);
        yield api.put(getRoute(apiEndpoints.team.update, {id: response.data.id}), payload);

        yield put(teamsActions.fetchTeamsDataStart());
        yield put(teamActions.fetchTeamDataStart());
        yield put(descendantsActions.fetchTeamDescendantsStart());
        yield put(teamsActions.saveTeamsSuccess());
    } catch (error) {
        yield put(teamsActions.saveTeamsFailure(error));
    }
}

function* updateTeam({payload}) {
    try {
        yield api.put(getRoute(apiEndpoints.team.update, {id: payload.id}), payload.data);
        yield put(teamActions.fetchTeamDataStart());
        yield put(teamsActions.fetchTeamsDataStart());
        yield put(teamsActions.updateTeamsSuccess());
    } catch (error) {
        yield put(teamsActions.updateTeamsFailure(error));
    }
}

function* cloneTeam({payload}) {
    try {
        yield api.post(getRoute(apiEndpoints.team.clone, {id: payload.id}), payload);
        yield put(teamActions.fetchTeamDataStart());
        yield put(teamsActions.fetchTeamsDataStart());
        yield put(descendantsActions.fetchTeamDescendantsStart());
        yield put(teamsActions.cloneTeamsSuccess());
    } catch (error) {
        yield put(teamsActions.cloneTeamsFailure(error));
    }
}

function* deleteTeam({payload}) {
    try {
        yield api.delete(getRoute(apiEndpoints.team.delete, {id: payload}));

        yield put(teamActions.fetchTeamDataStart());
        yield put(teamsActions.fetchTeamsDataStart());
        yield put(teamsActions.deleteTeamsSuccess());
    } catch (error) {
        yield put(teamsActions.deleteTeamsFailure(error));
    }
}

export default function* teamsSaga() {
    yield all([
        takeEvery(teamsActions.FETCH_TEAMS_DATA_START, fetchTeams),
        takeEvery(teamsActions.SAVE_TEAMS_START, createTeam),
        takeEvery(teamsActions.UPDATE_TEAMS_START, updateTeam),
        takeEvery(teamsActions.CLONE_TEAMS_START, cloneTeam),
        takeEvery(teamsActions.DELETE_TEAMS_START, deleteTeam),
    ]);
}
