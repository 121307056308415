import contactPropertiesActions from './actions';

import {LOGOUT} from 'redux/auth/auth.types';

const initialState = {
    data: null,
    deleted: false,
    loading: false,
    error: null,
    loaded: false,
    updated: false,
};

const contactPropertiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case contactPropertiesActions.FETCH_CONTACT_PROPERTIES_DATA_START:
            return {
                ...state,
                deleted: false,
                loading: true,
                error: null,
                loaded: false,
            };

        case contactPropertiesActions.FETCH_CONTACT_PROPERTIES_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                deleted: false,
                loading: false,
                error: null,
                loaded: true,
            };
        case contactPropertiesActions.FETCH_CONTACT_PROPERTIES_DATA_FAILURE:
            return {
                ...state,
                deleted: false,
                loading: null,
                error: action.payload,
                loaded: false,
            };

        case LOGOUT:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default contactPropertiesReducer;
