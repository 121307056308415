const initialState = [];

const historyReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'PUSH_HISTORY':
            if (state.length > 100) {
                state.shift();
            }

            return [...state, action.payload];

        case 'POP_HISTORY':
            return state.slice(0, state.length - 1);

        default:
            return state;
    }
};

export default historyReducer;
