import creditCardActions from './actions';

import teamActions from 'redux/team/actions';
import {LOGOUT} from 'redux/auth/auth.types';
import orderSummaryActions from 'redux/orderSummary/actions';

const initialState = {
    data: null,
    newCard: null,
    error: null,
    loading: false,
    updating: false,
};

const creditCardsReducer = (state = initialState, action) => {
    switch (action.type) {
        case creditCardActions.FETCH_CREDIT_CARDS_DATA_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case creditCardActions.FETCH_CREDIT_CARDS_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null,
            };
        case creditCardActions.FETCH_CREDIT_CARDS_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case creditCardActions.UPDATE_CREDIT_CARD_DISPLAY_NAME_START:
            return {
                ...state,
                updating: true,
                error: null,
            };

        case creditCardActions.UPDATE_CREDIT_CARD_DISPLAY_NAME_SUCCESS:
            return {
                ...state,
                updating: false,
                error: null,
            };

        case creditCardActions.UPDATE_CREDIT_CARD_DISPLAY_NAME_FAILURE:
            return {
                ...state,
                updating: false,
                error: action.payload,
            };

        case creditCardActions.UPDATE_DEFAULT_CREDIT_CARDS_START:
            return {
                ...state,
                cardId: action.payload,
                updating: true,
                error: null,
            };
        case creditCardActions.UPDATE_DEFAULT_CREDIT_CARDS_SUCCESS:
            return {
                ...state,
                updating: false,
                error: null,
            };
        case creditCardActions.UPDATE_DEFAULT_CREDIT_CARDS_FAILURE:
            return {
                ...state,
                updating: false,
                error: action.payload,
            };

        case creditCardActions.CREATE_CREDIT_CARDS_START:
            return {
                ...state,
                newCard: null,
                updating: true,
                error: null,
            };
        case creditCardActions.CREATE_CREDIT_CARDS_SUCCESS:
            return {
                ...state,
                newCard: action.payload,
                updating: false,
                error: null,
            };
        case creditCardActions.CREATE_CREDIT_CARDS_FAILURE:
            return {
                ...state,
                updating: false,
                error: action.payload,
            };

        case orderSummaryActions.RESET_ORDER_SUMMARY:
        case creditCardActions.CLEAR_NEW_CARD:
            return {
                ...state,
                newCard: null,
            };

        case creditCardActions.DELETE_CREDIT_CARDS_START:
            return {
                ...state,
                updating: true,
                error: null,
            };
        case creditCardActions.DELETE_CREDIT_CARDS_SUCCESS:
            return {
                ...state,
                updating: false,
                error: null,
            };
        case creditCardActions.DELETE_CREDIT_CARDS_FAILURE:
            return {
                ...state,
                updating: false,
                error: action.payload,
            };

        case LOGOUT:
        case teamActions.CHANGE_TEAM_SUCCESS:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default creditCardsReducer;
