import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Select, Col, Empty, Row, Typography, Divider, Button, InputNumber} from 'components/UI';
import {lang} from 'config/lang';
import {FormItem, hasError} from 'components/FormikForm';
import FontAwesome from 'components/UI/FontAwesome';

const {Text} = Typography;

const FormSelect = (props) => {
    const {
        custom,
        parser,
        width,
        options,
        form,
        onChange,
        formatter,
        field,
        help,
        extra,
        notFoundContent,
        ant,
        step,
        precision,
        min,
        max,
        onCustomChange,
    } = props;

    const [customValue, setCustomValue] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const closeDropdown = () => {
        setDropdownOpen(false);
    };

    const clearCustomValue = () => setCustomValue('');
    const validateCustom = (value, func) => custom.validate(value, func);
    const handleCustomChange = (value) => {
        const cleanValue = value && parseInt(value.toString().replace(/\D/g, ''));
        if (validateCustom(value, 'changed')) setCustomValue(cleanValue);
        if (onCustomChange) onCustomChange(cleanValue);
    };
    const customEnterPressed = (e) => {
        e.preventDefault();
        if (!custom.enterPressed) return;
        clearCustomValue();

        if (!validateCustom(customValue, 'enterPressed')) return;

        custom.enterPressed(parser(customValue), form.setValues);
    };
    const handleCustomButtonClicked = (e) => {
        e.preventDefault();
        if (!custom.onClick) return;

        if (!validateCustom(customValue, 'clicked')) return;

        custom.onClick(parser(customValue), form.setValues);

        clearCustomValue();
        closeDropdown();
    };
    const handleChange = (v) => {
        const value = parser(v);

        form.setValues({
            ...form.values,
            [field.name]: value,
        });
        if (typeof onChange === 'function') onChange(value);
    };
    const renderDropdown = (menu) => {
        if (!custom.show) return menu;
        const menuOrder = custom.after ? 2 : 0;

        return custom.component && custom.component === 'input' ? (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', flexDirection: 'column', order: 1}}>
                    <div style={{order: 1}}>{menu}</div>
                    <Divider style={{order: menuOrder, margin: '12px 0'}} />
                </div>
                <FormItem
                    help={custom.error ? custom.error : undefined}
                    status={custom.error.length && 'error'}
                    style={{margin: '12px 12px 0'}}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={16}>
                            <InputNumber
                                id="custom_amount"
                                name="custom_amount"
                                addonBefore={custom.addonBefore}
                                placeholder={custom.placeholder}
                                onPressEnter={customEnterPressed}
                                onChange={handleCustomChange}
                                size="large"
                                value={customValue}
                                step={step}
                                precision={precision}
                                min={min}
                                max={max}
                                wrapperCol={custom.inputWrapperCol}
                            />
                        </Col>
                        <Col span={8}>
                            <Button block onClick={handleCustomButtonClicked} disabled={custom.disabled} size="large">
                                {custom.buttonText}
                            </Button>
                        </Col>
                    </Row>
                </FormItem>
            </div>
        ) : (
            <>
                <div style={{display: 'flex', flexDirection: 'column', order: 1}}>
                    <div style={{order: 1}}>{menu}</div>
                    <Divider style={{order: menuOrder}} />
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'nowrap',
                            order: menuOrder,
                        }}
                    >
                        {custom.component}
                    </div>
                </div>
            </>
        );
    };

    const selectProps = {...props};
    delete selectProps.options;
    delete selectProps.ant;
    delete selectProps.custom;
    delete selectProps.wrapperCol;
    delete selectProps.labelCol;
    delete selectProps.noLoading;
    delete selectProps.formatter;
    delete selectProps.parser;

    const selectValue = () => (form.values === null ? null : formatter(form.values[field.name]));

    return (
        <FormItem {...props} errors={hasError(field.name, form)} skelType="select" extra={extra} help={help}>
            {
                <Select
                    {...field}
                    {...selectProps}
                    {...ant}
                    value={selectValue()}
                    onChange={handleChange}
                    style={{width}}
                    dropdownRender={renderDropdown}
                    size={props.size}
                    open={dropdownOpen}
                    onDropdownVisibleChange={setDropdownOpen}
                    notFoundContent={
                        notFoundContent || (
                            <Empty
                                className="search-results"
                                description={<Text style={{fontSize: '1rem'}}>{lang['no_results']}</Text>}
                            />
                        )
                    }
                    options={options}
                />
            }
        </FormItem>
    );
};

FormSelect.defaultProps = {
    custom: {
        show: false,
        after: false,
        placeholder: '',
        buttonText: null,
        disabled: false,
        validate: () => true,
    },
    defaultValue: '',
    formatter: (value) => value,
    labelInValue: false,
    mode: 'default',
    options: [],
    parser: (value) => value,
    showSearch: false,
    size: 'large',
    suffixIcon: <FontAwesome icon="chevron-down" type="solid" size="xs" />,
    width: '1fr',
    min: null,
    max: null,
    precision: 0,
};

FormSelect.propTypes = {
    ant: PropTypes.any,
    custom: PropTypes.object,
    defaultValue: PropTypes.any,
    extra: PropTypes.any,
    field: PropTypes.object,
    form: PropTypes.object,
    formatter: PropTypes.func,
    help: PropTypes.any,
    labelInValue: PropTypes.bool,
    max: PropTypes.number,
    min: PropTypes.number,
    mode: PropTypes.string,
    notFoundContent: PropTypes.any,
    onChange: PropTypes.func,
    onCustomChange: PropTypes.func,
    options: PropTypes.any.isRequired,
    parser: PropTypes.func,
    precision: PropTypes.number,
    showSearch: PropTypes.bool,
    size: PropTypes.string,
    step: PropTypes.number,
    suffixIcon: PropTypes.any,
    validate: PropTypes.func,
    width: PropTypes.string,
};

export default FormSelect;
