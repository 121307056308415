import React from 'react';
import PropTypes from 'prop-types';
import {DEFAULT_REGION} from 'config/regions';
import {Typography} from 'components/UI';
import useExchange from 'hooks/funding/useExchange';

const CurrencyValue = (res) => {
    const getExchangeValue = useExchange();

    return (
        <Typography.Text style={{color: 'inherit', fontWeight: 'inherit'}} className="nowrap">
            {getExchangeValue(res)}
        </Typography.Text>
    );
};
CurrencyValue.defaultProps = {
    approximate: false,
    currency: null,
    decimals: 2,
    exchange_rate: false,
    region: DEFAULT_REGION,
};

CurrencyValue.propTypes = {
    amount: PropTypes.any.isRequired,
    approximate: PropTypes.bool,
    currency: PropTypes.string,
    exchange_rate: PropTypes.bool,
    decimals: PropTypes.number,
    region: PropTypes.object,
};

export default CurrencyValue;
