import recipientCampaignActions from './actions';

const INITIAL_DATA = {
    data: null,
    loading: false,
    error: null,
    loaded: false,
};

const recipientCampaignReducer = (state = INITIAL_DATA, action) => {
    switch (action.type) {
        case recipientCampaignActions.SET_RECIPIENT_CAMPAIGN_DATA_START:
            return {
                data: action.payload,
                loading: true,
                error: false,
                loaded: false,
            };
        case recipientCampaignActions.SET_RECIPIENT_CAMPAIGN_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                loaded: true,
            };
        case recipientCampaignActions.SET_RECIPIENT_CAMPAIGN_DATA_FAILURE:
            return {
                ...state,
                loading: null,
                error: true,
                loaded: false,
            };

        case recipientCampaignActions.UPDATE_RECIPIENT_CAMPAIGN_DATA_START:
            return {
                data: action.payload.campaigns,
                loading: true,
                error: false,
                loaded: false,
            };
        case recipientCampaignActions.UPDATE_RECIPIENT_CAMPAIGN_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                loaded: true,
            };
        case recipientCampaignActions.UPDATE_RECIPIENT_CAMPAIGN_DATA_FAILURE:
            return {
                ...state,
                loading: null,
                error: true,
                loaded: false,
            };
        default:
            return state;
    }
};

export default recipientCampaignReducer;
