import {SET_FORM, RESET_FORM, SET_FORM_VALUES} from './actions';
import {CAMPAIGN_MANAGEMENT_FORM, SEND_FORM} from 'config/forms';
import {getDefaultState} from 'redux/forms/config';
import sendTransform from 'redux/forms/transforms/send';
import {sendValidator} from 'redux/forms/validators/send';
import {REHYDRATE} from 'redux-persist/es/constants';

const validateFormData = (formName, formData, __global) => {
    const validators = {
        [SEND_FORM]: sendValidator,
    };

    const validator = validators[formName];

    if (validator) {
        return validator(formData, formName, __global);
    }

    return formData;
};

const transformFormData = (formName, data, __global) => {
    switch (formName) {
        case SEND_FORM:
            return sendTransform(data, __global);

        default:
            return data;
    }
};

const updateFormData = (key, values, state, __global) => {
    const transformedFormData = transformFormData(key, values, __global);
    const validatedFormData = validateFormData(key, transformedFormData, __global);

    return {
        ...state,
        data: {
            ...state.data,
            [key]: validatedFormData,
        },
    };
};

const formReducer = (state, action) => {
    switch (action.type) {
        case SET_FORM: {
            const {key, values} = action.payload;

            return updateFormData(key, values, state);
        }

        case SET_FORM_VALUES: {
            const {key, values} = action.payload;
            const {__global = {}} = values;

            const updatedFormData = {
                ...(state?.data?.[key] ?? {}),
                ...action.payload.values,
            };

            return updateFormData(key, updatedFormData, state, __global);
        }

        case REHYDRATE:
            if (action.payload) {
                const incoming = action.payload.data;

                if (incoming) {
                    return {
                        ...state,
                        data: {
                            ...state.data,
                            ...incoming,
                            [CAMPAIGN_MANAGEMENT_FORM]: getDefaultState(CAMPAIGN_MANAGEMENT_FORM),
                        },
                    };
                }
            }
            return state;

        case RESET_FORM:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload]: null,
                },
            };

        default:
            return state;
    }
};

export default formReducer;
