import ordersActions from './actions';
import {LOGOUT} from 'redux/auth/auth.types';

const initialState = {
    data: null,
    error: null,
    loading: false,
    query: {page: 1},
};

const ordersReducer = (state = initialState, action) => {
    switch (action.type) {
        case ordersActions.FETCH_ORDERS_DATA_START:
            return {
                ...state,
                type: action.payload.type,
                query: action.payload,
                loading: true,
                error: null,
            };

        case ordersActions.FETCH_ORDERS_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null,
            };

        case ordersActions.FETCH_ORDERS_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case ordersActions.CLEAR_ORDERS_DATA:
            return {
                ...state,
                data: null,
            };

        case LOGOUT:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};

export default ordersReducer;
