import {useCallback, useEffect, useMemo, useState} from 'react';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import {getAbilities, getRoles, getTeamInfo} from 'redux/selectors';
import {useSelector} from 'react-redux';
import {scalarToArray} from 'library/helpers/utilities';
import {ROLES} from 'config/roles';

const usePermissions = () => {
    const abilities = useSelector(getAbilities);
    const roles = useSelector(getRoles);
    const teamInfo = useSelector(getTeamInfo);

    const isSuperAdmin = roles?.includes?.(ROLES.SUPER_ADMIN);
    const isTeamAdmin = isSuperAdmin || roles?.includes?.(ROLES.TEAM_ADMIN);
    const isGiftingUser = isSuperAdmin || isTeamAdmin || roles?.includes?.(ROLES.GIFTING);
    const isGiftingOnlyUser = roles?.includes(ROLES.GIFTING) && !isSuperAdmin && !isTeamAdmin;
    const isReportingUser = isSuperAdmin || isTeamAdmin || roles?.includes?.(ROLES.REPORTING);

    const [ready, setReady] = useState(false);

    const canAbilities = useMemo(
        () =>
            abilities?.reduce((prev, ability) => {
                const perm =
                    'can' +
                    ability
                        .split('-')
                        .map((i) => capitalize(i))
                        .join('');
                return {...prev, [perm]: true};
            }, {}),
        [abilities],
    );

    /**
     * Check is user is authorized to perform a task
     * @param permissions
     * @param abilityOnly - if true isSuperAdmin is ignored
     * @returns boolean
     */
    const canUser = useCallback(
        (permissions, abilityOnly = false) => {
            if (!ready) return false;
            if (!abilityOnly && isSuperAdmin) return true;

            return scalarToArray(permissions).every((permission) => {
                // handle special permissions
                switch (permission) {
                    case 'add-payment-methods':
                    case 'add-funds':
                        return (
                            abilities.includes(permission) ||
                            !!(isGiftingUser && teamInfo?.data?.data?.account_credit_cards)
                        );
                    case 'manage-contacts':
                        return (
                            abilities.includes(permission) ||
                            !!(isGiftingUser && teamInfo?.data?.data?.gifting_manage_recipients)
                        );
                    default:
                        return abilities.includes(permission);
                }
            });
        },
        [abilities, isSuperAdmin, isGiftingUser, teamInfo, ready],
    );

    useEffect(() => {
        if (!ready && roles?.length > 0 && abilities?.length > 0) {
            setReady(true);
        }
    }, [roles, abilities, ready]);

    return {
        ready: !isEmpty(canAbilities),
        isGiftingUser,
        isGiftingOnlyUser,
        isReportingUser,
        isSuperAdmin,
        isTeamAdmin,
        roles,
        abilities: canAbilities,
        canUser,
    };
};

export default usePermissions;
