import {all, takeEvery, takeLeading, put, select} from 'redux-saga/effects';
import campaignsActions from 'redux/campaigns/actions';
import {api} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';
import {buildQuery, getRoute} from 'library/helpers/routing';
import teamActions from 'redux/team/actions';
import campaignActions from 'redux/campaign/actions';
import profileActions from 'redux/profile/actions';
import savedDesignActions from 'redux/savedDesigns/actions';

function* setDefault(campaigns) {
    // if we have the campaign stored use that
    const profile = yield select((state) => {
        return state.profile;
    });

    const cachedCampaignId = profile?.data?.data?.context?.campaign_id;

    if (cachedCampaignId) {
        const campaign = yield campaigns
            .filter((f) => f.active && f.id === cachedCampaignId)
            .map((item) => item.id)
            .pop();

        // If campaign exists, change to it otherwise continue
        if (campaign) {
            return yield put(profileActions.updateDataContext({campaign_id: cachedCampaignId}));
        }
    }

    let newCampaign = yield campaigns
        .filter((f) => f.active)
        .map((item) => {
            if (!item.data['is_default_campaign']) return false;
            return item.id;
        })
        .filter((f) => f)
        .pop();

    newCampaign =
        newCampaign === undefined || newCampaign.length === 0
            ? campaigns
                  .filter((f) => f.active)
                  .slice(0, 1)
                  .map((item) => item.id)
                  .pop()
            : newCampaign;

    yield put(profileActions.updateDataContext({campaign_id: newCampaign ?? 0}));
}

function* listCampaigns() {
    const campaigns = yield api.get(apiEndpoints.campaigns.list).then((response) => response.data);
    yield setDefault(campaigns);
    yield put(campaignsActions.listCampaignsSuccess({campaigns}));
    yield put(savedDesignActions.fetchSavedDesignsStart());
}

function* fetchCampaigns(query) {
    const url = `${apiEndpoints.campaigns.search}?${query}`;
    return yield api.get(url).then((response) => response.data);
}

function* fetchCampaignData({payload = {page: '1', per_page: '25'}}) {
    const query = buildQuery(payload);
    const campaigns = yield fetchCampaigns(query);

    yield put(campaignsActions.fetchCampaignsSuccess({campaigns}));
}

function* saveSmartCampaignData({payload}) {
    try {
        yield api.post(apiEndpoints.campaigns.createSmart, payload);
        yield put(campaignsActions.saveSmartCampaignSuccess());
        yield put(campaignsActions.fetchCampaignsStart());
        yield put(campaignsActions.listCampaignsStart());
    } catch (error) {
        yield put(campaignsActions.saveSmartCampaignFailure(error));
    }
}

function* saveStandardCampaignData({payload}) {
    try {
        yield api.post(apiEndpoints.campaigns.createStandard, payload);
        yield put(campaignsActions.saveStandardCampaignSuccess());
        yield put(campaignsActions.fetchCampaignsStart());
        yield put(campaignsActions.listCampaignsStart());
    } catch (error) {
        yield put(campaignsActions.saveStandardCampaignFailure(error));
    }
}

function* toggleCampaignsData({payload}) {
    try {
        yield api.put(getRoute(apiEndpoints.campaigns.toggle, {id: payload.id}), {
            active: !payload.active,
        });

        yield put(campaignsActions.toggleCampaignsSuccess());
        yield put(campaignsActions.fetchCampaignsStart());
        yield put(campaignsActions.listCampaignsStart());
        yield put(campaignActions.fetchCampaignStart(payload.id));
    } catch (error) {
        yield put(campaignsActions.toggleCampaignsFailure(error));
    }
}

function* deleteCampaignsData({payload}) {
    const url = apiEndpoints.campaigns.delete.replace(':id', payload);

    try {
        yield api.delete(url);
        yield put(campaignsActions.deleteCampaignsSuccess());
        yield put(campaignsActions.fetchCampaignsStart());
        yield put(campaignsActions.listCampaignsStart());
    } catch (error) {
        yield put(campaignsActions.deleteCampaignsFailure());
    }
}

export default function* campaignSaga() {
    yield all([
        takeLeading(campaignsActions.FETCH_CAMPAIGNS_DATA_START, fetchCampaignData),
        takeLeading(campaignsActions.LIST_CAMPAIGNS_START, listCampaigns),
        takeEvery(campaignsActions.SAVE_SMART_CAMPAIGN_DATA_START, saveSmartCampaignData),
        takeEvery(campaignsActions.SAVE_STANDARD_CAMPAIGN_DATA_START, saveStandardCampaignData),
        takeEvery(campaignsActions.TOGGLE_CAMPAIGNS_DATA_START, toggleCampaignsData),
        takeEvery(campaignsActions.DELETE_CAMPAIGNS_DATA_START, deleteCampaignsData),

        takeEvery(teamActions.CHANGE_TEAM_SUCCESS, listCampaigns),
        takeEvery(teamActions.CHANGE_TEAM_SUCCESS, fetchCampaignData),
    ]);
}
