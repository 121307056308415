import {atom} from 'recoil';
import {recoilPersist} from 'library/vendor/recoil-persist';
import {getProcessEnvValue} from 'library/helpers/process';
export const defaultAmplifyState = {
    configured: false,
    client: {
        client_id: getProcessEnvValue('REACT_APP_COGNITO_POOL_WEB_CLIENT_ID'),
        company_name: 'Giftogram',
        is_default: true,
        providers: [
            {
                data: {
                    button_text: '',
                    icon: '',
                },
                provider: 'COGNITO',
            },
            {
                data: {
                    icon: 'google',
                    button_text: 'Google',
                    colors: {
                        backgroundColor: '#0C7BE2',
                        color: 'white',
                    },
                },
                provider: 'Google',
            },
        ],
    },
    config: {
        region: getProcessEnvValue('REACT_APP_COGNITO_REGION'),
        userPoolId: getProcessEnvValue('REACT_APP_COGNITO_POOL_ID'),
        userPoolWebClientId: getProcessEnvValue('REACT_APP_COGNITO_POOL_WEB_CLIENT_ID'),
        oauth: {
            domain: getProcessEnvValue('REACT_APP_COGNITO_DOMAIN'),
            scopes: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: getProcessEnvValue('REACT_APP_COGNITO_REDIRECT_SIGN_IN'),
            redirectSignOut: getProcessEnvValue('REACT_APP_COGNITO_REDIRECT_SIGN_OUT'),
            responseType: 'code',
        },
        loginWith: {
            oauth: {
                domain: getProcessEnvValue('REACT_APP_COGNITO_DOMAIN'),
                scopes: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                redirectSignIn: getProcessEnvValue('REACT_APP_COGNITO_REDIRECT_SIGN_IN'),
                redirectSignOut: getProcessEnvValue('REACT_APP_COGNITO_REDIRECT_SIGN_OUT'),
                responseType: 'code',
            },
        },
    },
    federationTarget: 'COGNITO_USER_POOLS',
};

const {persistAtom} = recoilPersist({
    storage: localStorage,
});

export const amplifyState = atom({
    key: 'amplifyState',
    default: defaultAmplifyState,
    effects_UNSTABLE: [persistAtom],
});
