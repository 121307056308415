import uploadActions from './actions';

const DEFAULT_DATA = {
    data: null,
    formData: null,
    loading: false,
    error: false,
};

const initialState = {
    bulk: {
        ...DEFAULT_DATA,
    },
    members: {
        ...DEFAULT_DATA,
    },
    funds_transfer: {
        ...DEFAULT_DATA,
    },
};

const uploadReducer = (state = initialState, action) => {
    const UPLOAD_TYPE = action.payload?.type;

    if (!initialState?.[UPLOAD_TYPE]) return state;

    switch (action.type) {
        case uploadActions.UPLOAD_START:
            return {
                ...state,
                [UPLOAD_TYPE]: {
                    ...state[UPLOAD_TYPE],
                    formData: action.payload,
                    data: null,
                    loading: true,
                    error: false,
                },
            };

        case uploadActions.UPLOAD_SUCCESS:
            return {
                ...state,
                [UPLOAD_TYPE]: {
                    ...state[UPLOAD_TYPE],
                    formData: null,
                    data: action.payload,
                    loading: false,
                    error: false,
                },
            };

        case uploadActions.UPLOAD_ERROR:
            return {
                ...state,
                [UPLOAD_TYPE]: {
                    ...state[UPLOAD_TYPE],
                    loading: false,
                    error: action.payload,
                },
            };

        case uploadActions.UPLOAD_RESET:
            return {
                ...state,
                [UPLOAD_TYPE]: {
                    ...DEFAULT_DATA,
                },
            };

        default:
            return state;
    }
};

export default uploadReducer;
