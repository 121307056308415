import React from 'react';

const icon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <circle cx="256" cy="256" r="256" fill="#f0f0f0"></circle>
        <g fill="#d80027">
            <path d="M244.87 256H512c0-23.107-3.08-45.489-8.819-66.783H244.87zM244.87 122.435h229.556a257.328 257.328 0 00-59.069-66.783H244.87zM256 512c60.249 0 115.626-20.824 159.357-55.652H96.643C140.374 491.176 195.751 512 256 512zM37.574 389.565h436.852a254.492 254.492 0 0028.755-66.783H8.819a254.46 254.46 0 0028.755 66.783z"></path>
        </g>
        <path fill="#0052b4" d="M256 256V0C114.616 0 0 114.616 0 256z"></path>
        <g fill="#ffda44">
            <path d="M170.234 219.13c-34.962 0-63.304-28.343-63.304-63.304s28.343-63.304 63.304-63.304c10.901 0 21.158 2.757 30.113 7.609-14.048-13.737-33.26-22.217-54.461-22.217-43.029 0-77.913 34.883-77.913 77.913s34.884 77.913 77.913 77.913c21.201 0 40.413-8.48 54.461-22.217-8.955 4.85-19.211 7.607-30.113 7.607z"></path>
            <path d="M188.073 111.304l11.239 23.502 25.381-5.864-11.366 23.439 20.412 16.187-25.414 5.729.071 26.051-20.323-16.298-20.324 16.298.07-26.051-25.414-5.729 20.412-16.187-11.367-23.439 25.383 5.864z"></path>
        </g>
    </svg>
);

export default icon;
