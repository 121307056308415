import {laravelLogin} from 'redux/auth/services';
import {Auth} from 'aws-amplify';
import {useCallback} from 'react';
import {useSetRecoilState} from 'recoil';
import {errorState} from '../../services/error/atoms/error';
import {authState} from '../../services/auth/atoms/auth';
import {awsForgotPasswordStep1, awsIsSso} from 'library/vendor/aws';

const useAuth = () => {
    const setAuthState = useSetRecoilState(authState);
    const setAuth = useCallback(
        (payload) => {
            setAuthState((auth) => {
                return {...auth, ...payload};
            });
        },
        [setAuthState],
    );
    const setError = useSetRecoilState(errorState);

    const forgotPassword = async (email) => {
        const {success, code, message} = await awsForgotPasswordStep1(email.toLowerCase());

        if (!success && message) {
            if (message.indexOf('no registered/verified email') !== -1) {
                setError((data) => {
                    return {
                        ...data,
                        login: {
                            email,
                            code: 'UserNotConfirmedException',
                            message,
                        },
                    };
                });
            } else {
                setError((data) => {
                    return {
                        ...data,
                        login: {
                            code,
                            message,
                        },
                    };
                });
            }
        }
        return success;
    };

    const logIntoLaravel = async (token) => {
        // now we need to log into laravel
        try {
            const {error, data} = await laravelLogin(token);

            let authenticated = true;
            if (error) {
                token = null;
                authenticated = false;
                await Auth.signOut();

                const code = error?.data?.message.includes('SSORequiredException') ? 'SSORequiredException' : null;

                setError((data) => {
                    return {
                        ...data,
                        login: {
                            code,
                            message: 'Invalid username or password',
                        },
                    };
                });
            }

            const ssoAuthenticated = await awsIsSso();

            // update recoil state
            setAuth({
                ssoAuthenticated,
                amazonAuthenticated: authenticated,
                signUpRequired: data?.finish_signup,
                authenticated,
                token,
            });
        } catch (error) {
            setAuth({
                token: null,
                amazonAuthenticated: false,
                authenticated: false,
            });

            return {error};
        }
    };
    return {logIntoLaravel, forgotPassword};
};

export default useAuth;
