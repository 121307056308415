import {store} from 'redux/store';
import {getCompanyPrimaryProducts} from 'redux/primaryProducts/selectors';

export const generateCategoryData = (products) => {
    if (!products) return [];
    return products
        .map((product) => product.categories)
        .reduce((combined, categories) => {
            return combined.concat(categories);
        }, [])
        .reduce((prev, category) => {
            const doesNotExists = prev.filter((item) => item.id === category.id).length === 0;

            // if it doesn't exist create it with a count of one
            if (doesNotExists) {
                category.count = 1;
                prev.push(category);

                return prev;
            }

            // if it exists increment the count
            prev.forEach((item) => {
                if (item.id === category.id) item.count = item.count + 1;
            });

            return prev;
        }, [])
        .sort((a, b) => a.weight - b.weight)
        .reverse();
};

export const getStorefront = (primary_products, id) => {
    if (!primary_products || !id) return '';

    return primary_products.filter((product) => product.id === id)[0];
};

export const getStorefronts = (keys, key) => {
    if (!keys?.length > 0) return '';

    const primaryProducts = getCompanyPrimaryProducts(store.getState());

    if (!primaryProducts) return '';

    if (!key) {
        return primaryProducts.filter((product) => keys.includes(product.id));
    }

    return primaryProducts.filter((product) => keys.includes(product[key]));
};
