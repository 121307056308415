import React from 'react';
import {Alert as AntAlert, Space} from 'antd';
import FontAwesome from 'components/UI/FontAwesome';
import Button from 'components/UI/Button';
import PropTypes from 'prop-types';
import {classNames} from 'library/helpers/utilities';

const iconMap = {
    error: 'triangle-exclamation',
    success: 'circle-check',
    warning: 'triangle-exclamation',
    info: 'circle-info',
};

export const Alert = ({action = false, showIcon = true, icon = '', iconType = 'solid', className, type, ...props}) => {
    return (
        <AntAlert
            {...props}
            type={type}
            className={classNames(action && 'ant-alert-has-action', className)}
            icon={<FontAwesome type={iconType} icon={icon ? icon : iconMap[type]} />}
            action={
                action && [
                    <Space size={16} key="page-header-buttons">
                        {action.map((item) => {
                            return (
                                <Button
                                    key={`button-${item.id}`}
                                    type="default"
                                    size="small"
                                    onClick={item.onClick}
                                    icon={item.icon && <FontAwesome icon={item.icon} />}
                                >
                                    {item.label}
                                </Button>
                            );
                        })}
                    </Space>,
                ]
            }
            showIcon={showIcon}
        />
    );
};

Alert.propTypes = {
    action: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.array]),
    actionText: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.string,
    iconType: PropTypes.string,
    message: PropTypes.any,
    showIcon: PropTypes.bool,
    type: PropTypes.string.isRequired,
};

export default Alert;
