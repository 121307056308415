import procurementActions from './actions';

const initialState = {
    redirectUrl: null,
};
const procurementReducer = (state = initialState, action) => {
    switch (action.type) {
        case procurementActions.SET_REDIRECT_URL:
            return {
                ...state,
                redirectUrl: action.payload,
            };
        case procurementActions.RESET_REDIRECT_URL:
            return {
                ...state,
                redirectUrl: null,
            };

        default:
            return state;
    }
};

export default procurementReducer;
