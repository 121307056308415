export const fundingTransferActions = {
    PROCESS_UPLOAD_TRANSFER_START: 'PROCESS_UPLOAD_TRANSFER_START',
    PROCESS_UPLOAD_TRANSFER_COMPLETE: 'PROCESS_UPLOAD_TRANSFER_COMPLETE',
    PROCESS_UPLOAD_TRANSFER_ERROR: 'PROCESS_UPLOAD_TRANSFER_ERROR',
    PROCESS_UPLOAD_TRANSFER_RESET: 'PROCESS_UPLOAD_TRANSFER_RESET',
    PROCESS_UPLOAD_TRANSFER_DELETE_FILE: 'PROCESS_UPLOAD_TRANSFER_DELETE_FILE',
    processUploadTransferStart: (payload) => ({
        type: fundingTransferActions.PROCESS_UPLOAD_TRANSFER_START,
        payload,
    }),
    processUploadTransferComplete: (payload) => ({
        type: fundingTransferActions.PROCESS_UPLOAD_TRANSFER_COMPLETE,
        payload,
    }),
    processUploadTransferError: (payload) => ({
        type: fundingTransferActions.PROCESS_UPLOAD_TRANSFER_ERROR,
        payload,
    }),
    reset: function () {
        return {
            type: fundingTransferActions.PROCESS_UPLOAD_TRANSFER_RESET,
        };
    },
    deleteFile: (payload) => ({
        type: fundingTransferActions.PROCESS_UPLOAD_TRANSFER_DELETE_FILE,
        payload,
    }),
};
