import React from 'react';

const icon = () => (
    <svg viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <path
                    d="M15.923,166.957 C5.633,194.691 0,224.686 0,256 C0,287.314 5.633,317.311 15.923,345.043 L256,367.304 L496.077,345.043 C506.367,317.311 512,287.314 512,256 C512,224.686 506.367,194.691 496.077,166.957 L256,144.696 L15.923,166.957 Z"
                    fill="#F0F0F0"
                ></path>
                <path
                    d="M256,0 C145.93,0 52.094,69.472 15.924,166.957 L496.079,166.957 C459.906,69.472 366.072,0 256,0 Z"
                    fill="#D80027"
                ></path>
                <path
                    d="M256,512 C366.072,512 459.906,442.528 496.078,345.043 L15.924,345.043 C52.094,442.528 145.93,512 256,512 Z"
                    fill="#6DA544"
                ></path>
            </g>
        </g>
    </svg>
);

export default icon;
