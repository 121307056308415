import React, {useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {iconStyle} from 'assets/styles/common';
import {lang} from 'config/lang';
import {goToRoute} from 'library/helpers/routing';
import usePermissions from 'hooks/auth/usePermissions';
import orderSummaryActions from 'redux/orderSummary/actions';
import {getOrderSummaryData} from 'redux/orderSummary/selectors';
import {getProfileData, getTeamsData, getTeamInfo, getNotifications, getAllTeamMembers} from 'redux/selectors';
import teamActions from 'redux/team/actions';
import {FontAwesome, Space, Menu, Badge, Breakpoint, CurrencyValue, Skeleton} from 'components/UI';
import {routes} from 'config/routes';

const MainNavSettings = ({mode}) => {
    const {pathname} = useLocation();

    const dispatch = useDispatch();

    const order = useSelector(getOrderSummaryData);
    const profile = useSelector(getProfileData);

    const members = useSelector(getAllTeamMembers);

    const notifications = useSelector(getNotifications);
    const team = useSelector(getTeamInfo);
    const teams = useSelector(getTeamsData);

    const {canUser, isSuperAdmin, isTeamAdmin} = usePermissions();

    const isAdmin = isTeamAdmin || isSuperAdmin;

    const fundsSubmenu = useMemo(() => {
        if (!members) return null;
        if (!profile) return null;

        const subMenu = {
            key: 'funds-submenu',
            theme: 'light',
            icon: <FontAwesome size="lg" type={iconStyle} icon="wallet" />,
            label: (
                <Space size={12}>
                    <Breakpoint xs={false} sm={false} md={false} lg={false} xl={false}>
                        <Space size={8}>
                            {!isNaN(profile.credit_available) ? (
                                <CurrencyValue amount={profile.credit_available} />
                            ) : (
                                <Skeleton.Input size="small" style={{verticalAlign: 'middle'}} active />
                            )}
                            {profile.region.currency !== 'USD' && (
                                <span>
                                    (
                                    <CurrencyValue
                                        amount={profile.credit_available}
                                        exchange_rate
                                        approximate
                                        region={profile.region}
                                    />
                                    )
                                </span>
                            )}
                        </Space>
                    </Breakpoint>
                    {mode === 'horizontal' && <FontAwesome size="2xs" type="solid" icon="chevron-down" />}
                </Space>
            ),
            children: [],
        };

        if (canUser('add-payment-methods')) {
            subMenu.children.push({
                key: '/deposit',
                onClick: () => goToRoute(routes.private.paths.DEPOSIT_FUNDS),
                label: lang.funding.deposit.label.deposit,
            });
        }

        if (members?.length > 1 && canUser('send-gifts')) {
            subMenu.children.push({
                key: '/request',
                onClick: () => goToRoute(routes.private.paths.REQUEST_FUNDS),
                label: lang.funding.request.label,
            });
        }

        if (members?.length > 1 && canUser('transfer-funds-team')) {
            subMenu.children.push({
                key: '/funding/transfer',
                onClick: () => goToRoute(routes.private.paths.TRANSFER_FUNDS),
                label: lang.funding.transfer.label,
            });
        }

        subMenu.children.push({
            key: 'mainnav-settings-divider',
            type: 'divider',
        });

        subMenu.children.push({
            key: '/funding',
            onClick: () => goToRoute(routes.private.paths.BALANCE_HISTORY),
            label: lang.funding.history.label,
        });

        return subMenu;
    }, [canUser, members, mode, profile]);

    const cartItem = useMemo(() => {
        if (!order.items.length) return null;

        const toggleCart = () => dispatch(orderSummaryActions.toggleVisibleOrderSummary('toggle'));

        if (
            !canUser('send-gifts') ||
            order.items.length === 0 ||
            pathname === routes.private.paths.CHECKOUT.SHIPPING ||
            Object.values(routes.private.paths.SEND).includes(pathname)
        )
            return null;

        return {
            key: 'cart',
            onClick: () => toggleCart(),
            icon: <FontAwesome size="lg" type={iconStyle} icon="bag-shopping" />,
            label: <Badge count={order.items.length} offset={[-4, -4]} style={{backgroundColor: '#52c41a'}} />,
        };
    }, [canUser, dispatch, order.items.length, pathname]);

    const notificationsItem = useMemo(() => {
        return {
            key: 'notifications',
            onClick: () => goToRoute(routes.private.paths.NOTIFICATIONS),
            icon: <FontAwesome size="lg" type={iconStyle} icon="bell" />,
            className: notifications?.count?.data > 0 ? '' : 'no-title',
            label: <Badge count={notifications?.count?.data} offset={[-4, -4]} style={{backgroundColor: '#52c41a'}} />,
        };
    }, [notifications]);

    const settingsItem = useMemo(() => {
        if (!isAdmin) return null;

        return {
            key: 'settings',
            onClick: () => goToRoute(routes.private.paths.SETTINGS_USER),
            icon: <FontAwesome size="lg" type={iconStyle} icon="gear" />,
            className: 'no-title',
        };
    }, [isAdmin]);

    const accountSubmenu = useMemo(() => {
        if (!team.data) return null;
        if (!teams) return null;

        const toggleTeamSwitch = () => dispatch(teamActions.toggleSwitcherVisible(true));

        const accountSubmenuItem = {
            key: 'account-submenu',
            theme: 'light',
            icon: <FontAwesome size="lg" type={iconStyle} icon="user-circle" color={'rgb(255, 255, 255)'} />,
            label: (
                <Space size={12}>
                    <Breakpoint xs={false} sm={false} md={false} lg={false} xl={false}>
                        <span>{team.data.name}</span>
                    </Breakpoint>
                    {mode === 'horizontal' && <FontAwesome size="2xs" type="solid" icon="chevron-down" />}
                </Space>
            ),
            children: [],
        };

        accountSubmenuItem.children.push({
            key: '/dashboard',
            onClick: () => goToRoute(routes.private.paths.DASHBOARD),
            label: lang.dashboard.menu.label,
        });

        accountSubmenuItem.children.push({
            key: '/settings#user',
            onClick: () => goToRoute(routes.private.paths.SETTINGS_USER),
            label: lang['manage_account'],
        });

        if (teams.length > 1) {
            accountSubmenuItem.children.push({
                key: 'team-switch',
                onClick: toggleTeamSwitch,
                label: lang['switch_team'],
            });
        }

        accountSubmenuItem.children.push({
            key: 'logout',
            onClick: () => goToRoute(routes.neutral.paths.LOGOUT),
            label: lang['logout'],
        });

        return accountSubmenuItem;
    }, [dispatch, mode, team.data, teams]);

    /**
     * @type {[]}
     */
    const menuItems = useMemo(() => {
        return [fundsSubmenu, cartItem, notificationsItem, settingsItem, accountSubmenu].filter(Boolean);
    }, [accountSubmenu, cartItem, fundsSubmenu, notificationsItem, settingsItem]);

    if (!profile || !members || !team.data || !teams)
        return (
            <Space style={{marginRight: '1rem'}} size={16}>
                <Skeleton.Avatar size="small" active />
                <Skeleton.Input size="small" active />
                <Skeleton.Avatar size="small" active />
                <Skeleton.Avatar size="small" active />
                <Skeleton.Avatar size="small" active />
                <Skeleton.Input size="small" active />
            </Space>
        );

    return (
        <Menu
            theme="dark"
            mode={mode}
            defaultSelectedKeys={[pathname]}
            disabledOverflow
            triggerSubMenuAction="click"
            items={menuItems}
        />
    );
};

MainNavSettings.defaultProps = {
    mode: 'horizontal',
};

MainNavSettings.propTypes = {
    mode: PropTypes.string.isRequired,
};

export default MainNavSettings;
