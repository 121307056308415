import React from 'react';
import {Dropdown as AntDropdown} from 'antd';
import {FontAwesome, Button, Space} from 'components/UI';
import PropTypes from 'prop-types';

export const Dropdown = (props) => {
    return (
        <AntDropdown {...props} trigger={props.trigger}>
            {props.children ? (
                props.children
            ) : (
                <Button
                    id={props.id}
                    icon={props.icon}
                    type={props.type}
                    size={props.size}
                    disabled={props.disabled}
                    onClick={props.onClick}
                    style={props.buttonStyle}
                    loading={props.loading}
                >
                    <span>
                        <Space size={8}>
                            {props.label}
                            {props.suffixIcon && (
                                <FontAwesome type="sharp" icon="chevron-down" size="xs" className="dropdown-arrow" />
                            )}
                            {props.menuButton && <FontAwesome type="solid" icon="ellipsis" size="lg" />}
                        </Space>
                    </span>
                </Button>
            )}
        </AntDropdown>
    );
};

export default Dropdown;

Dropdown.defaultProps = {
    arrow: false,
    disabled: false,
    loading: false,
    size: 'large',
    suffixIcon: false,
    menuButton: false,
    trigger: 'click',
    type: 'default',
};

Dropdown.propTypes = {
    arrow: PropTypes.bool.isRequired,
    buttonStyle: PropTypes.any,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.any,
    loading: PropTypes.bool.isRequired,
    menuButton: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    size: PropTypes.any.isRequired,
    suffixIcon: PropTypes.bool.isRequired,
    trigger: PropTypes.any.isRequired,
    type: PropTypes.any.isRequired,
};
