import orderSummaryActions from './actions';

import {LOGOUT} from 'redux/auth/auth.types';
import {SHIPPING_METHOD_GROUND} from 'config/orders/physical';

const initialData = {
    data: {
        items: [],
        total: 0,
        count: 0,
        discount: 0,
        tax: 0,
        taxRate: 0,
        shippingCosts: 0,
    },
    form: {
        data: {
            accepted_terms: false,
            campaign_id: null,
            payment_method_id: null,
            payment_card_id: null,
            shipping_address_id: 0,
            shipping_method_id: SHIPPING_METHOD_GROUND,
            latest_delivery_date: null,
            coupon_id: null,
            customer_notes: null,
            reference_number: null,
        },
        selectedAddress: {},
        region: null,
        submitting: false,
        submittable: false,
    },
    loading: false,
    adding: false,
    error: null,
    visible: false,
};

const orderSummaryReducer = (state = initialData, action) => {
    let visible;
    switch (action.type) {
        case orderSummaryActions.TOGGLE_VISIBLE_ORDER_SUMMARY:
            visible = action.payload === 'toggle' ? !state.visible : action.payload;
            return {
                ...state,
                visible,
            };

        case orderSummaryActions.FETCH_ORDER_SUMMARY_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case orderSummaryActions.FETCH_ORDER_SUMMARY_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
                loading: false,
                error: null,
            };
        case orderSummaryActions.FETCH_ORDER_SUMMARY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case orderSummaryActions.ADD_TO_ORDER_SUMMARY_START:
            return {
                ...state,
                adding: true,
                added: false,
                error: null,
            };
        case orderSummaryActions.ADD_TO_ORDER_SUMMARY_SUCCESS:
            return {
                ...state,
                adding: false,
                added: true,
                error: null,
            };
        case orderSummaryActions.ADD_TO_ORDER_SUMMARY_ERROR:
            return {
                ...state,
                adding: false,
                added: false,
                error: action.payload,
            };

        case orderSummaryActions.DELETE_FROM_ORDER_SUMMARY_START:
            return {
                ...state,
                payload: action.payload,
                changingId: action.payload,
                changing: true,
                error: null,
            };
        case orderSummaryActions.DELETE_FROM_ORDER_SUMMARY_SUCCESS:
            return {
                ...state,
                changingId: null,
                changing: false,
                error: null,
            };
        case orderSummaryActions.DELETE_FROM_ORDER_SUMMARY_ERROR:
            return {
                ...state,
                changingId: null,
                changing: false,
                error: action.payload,
            };

        case orderSummaryActions.UPDATE_ORDER_SUMMARY_START:
            return {
                ...state,
                payload: action.payload,
                error: null,
            };
        case orderSummaryActions.UPDATE_ORDER_SUMMARY_SUCCESS:
            return {
                ...state,
                payload: null,
                error: null,
            };
        case orderSummaryActions.UPDATE_ORDER_SUMMARY_ERROR:
            return {
                ...state,
                payload: null,
                error: action.payload,
            };
        case orderSummaryActions.MODIFY_ORDER_DATA_START:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
            };

        case orderSummaryActions.MODIFY_ORDER_FORM_ITEMS_START:
            return {
                ...state,
                form: {
                    ...state.form,
                    ...action.payload,
                },
            };

        case orderSummaryActions.MODIFY_ORDER_FORM_DATA_ITEMS_START:
            return {
                ...state,
                form: {
                    ...state.form,
                    data: {
                        ...state.form.data,
                        ...action.payload,
                    },
                },
            };

        case orderSummaryActions.RESET_ORDER_FORM_DATA_START:
            return {
                ...state,
                form: {
                    ...state.form,
                    submitting: false,
                    submittable: false,
                    data: {
                        ...state.form.data,
                        accepted_terms: false,
                        payment_method_id: null,
                        payment_card_id: null,
                        shipping_address_id: 0,
                        shipping_method_id: SHIPPING_METHOD_GROUND,
                        coupon_id: null,
                        notes: null,
                    },
                },
            };

        case orderSummaryActions.RESET_ORDER_SUMMARY:
        case LOGOUT:
            return {
                ...initialData,
            };

        default:
            return state;
    }
};

export default orderSummaryReducer;
