const teamsActions = {
  FETCH_TEAMS_DATA_START: 'FETCH_TEAMS_DATA_START',
  FETCH_TEAMS_DATA_SUCCESS: 'FETCH_TEAMS_DATA_SUCCESS',
  FETCH_TEAMS_DATA_FAILURE: 'FETCH_TEAMS_DATA_FAILURE',
  fetchTeamsDataStart: () => ({
    type: teamsActions.FETCH_TEAMS_DATA_START,
  }),
  fetchTeamsDataSuccess: (payload) => ({
    type: teamsActions.FETCH_TEAMS_DATA_SUCCESS,
    payload,
  }),
  fetchTeamsDataFailure: (error) => ({
    type: teamsActions.FETCH_TEAMS_DATA_FAILURE,
    payload: error,
  }),

  SAVE_TEAMS_START: 'SAVE_TEAMS_START',
  SAVE_TEAMS_SUCCESS: 'SAVE_TEAMS_SUCCESS',
  SAVE_TEAMS_FAILURE: 'SAVE_TEAMS_FAILURE',
  saveTeamsStart: (payload) => ({
    type: teamsActions.SAVE_TEAMS_START,
    payload,
  }),
  saveTeamsSuccess: () => ({
    type: teamsActions.SAVE_TEAMS_SUCCESS,
  }),
  saveTeamsFailure: (error) => ({
    type: teamsActions.SAVE_TEAMS_FAILURE,
    payload: error,
  }),

  UPDATE_TEAMS_START: 'UPDATE_TEAMS_START',
  UPDATE_TEAMS_SUCCESS: 'UPDATE_TEAMS_SUCCESS',
  UPDATE_TEAMS_FAILURE: 'UPDATE_TEAMS_FAILURE',
  updateTeamsStart: (payload) => ({
    type: teamsActions.UPDATE_TEAMS_START,
    payload,
  }),
  updateTeamsSuccess: () => ({
    type: teamsActions.UPDATE_TEAMS_SUCCESS,
  }),
  updateTeamsFailure: (error) => ({
    type: teamsActions.UPDATE_TEAMS_FAILURE,
    payload: error,
  }),

  CLONE_TEAMS_START: 'CLONE_TEAMS_START',
  CLONE_TEAMS_SUCCESS: 'CLONE_TEAMS_SUCCESS',
  CLONE_TEAMS_FAILURE: 'CLONE_TEAMS_FAILURE',
  cloneTeamsStart: (payload) => ({
    type: teamsActions.CLONE_TEAMS_START,
    payload,
  }),
  cloneTeamsSuccess: () => {
    return {
      type: teamsActions.CLONE_TEAMS_SUCCESS,
    };
  },
  cloneTeamsFailure: (error) => ({
    type: teamsActions.CLONE_TEAMS_FAILURE,
    payload: error,
  }),

  DELETE_TEAMS_START: 'DELETE_TEAMS_START',
  DELETE_TEAMS_SUCCESS: 'DELETE_TEAMS_SUCCESS',
  DELETE_TEAMS_FAILURE: 'DELETE_TEAMS_FAILURE',
  deleteTeamsStart: (payload) => ({
    type: teamsActions.DELETE_TEAMS_START,
    payload,
  }),
  deleteTeamsSuccess: () => {
    return {
      type: teamsActions.DELETE_TEAMS_SUCCESS,
    };
  },
  deleteTeamsFailure: (error) => ({
    type: teamsActions.DELETE_TEAMS_FAILURE,
    error,
    payload: error,
  }),

  RESET_TEAMS_ERROR: 'RESET_TEAMS_ERROR',
  resetTeamsError: () => ({
    type: teamsActions.RESET_TEAMS_ERROR,
  }),
};

export default teamsActions;
