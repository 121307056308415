import React, {useCallback, useEffect, useMemo} from 'react';
import {DatePicker} from 'antd';
import {useDispatch} from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {resetSyncComplete, runIntegrationFilterStart, updateSyncJob} from 'redux/integrations/actions';
import {Button, Divider, Select} from 'components/UI';
import DateRanger from 'components/Integrations/merge/DateRanger';
import useIntegrationsStore from 'hooks/integrations/useIntegrationsStore';
import {intersection} from 'lodash/array';

export default function useToolbarFilters({sync_complete: syncComplete, sync_job: syncJob, filters}) {
    const dispatch = useDispatch();
    const {store} = useIntegrationsStore();

    const configuredFilters = store?.configuredFilters;

    const syncFilters = useMemo(
        /**
         *
         * @return {{}}
         */
        () => syncJob?.filters || {},
        [syncJob],
    );

    /**
     * adds a filter to the store.sync_job.filters key
     * if the value is -1 it will remove the key from filters
     * @type {function(*): *&{filters: *}}
     */
    const addFilter = useCallback(
        (filter) => {
            const newFilters = {...syncJob.filters, ...filter};

            // remove the filter is it is -1 "all" filter
            if (Object.values(filter)[0] === -1) {
                delete newFilters[Object.keys(filter)[0]];
            }

            return {...syncJob, filters: newFilters};
        },
        [syncJob],
    );

    const handleFilter = useCallback(
        (key, value) => {
            const currentQueryFilters = syncFilters || {};

            // if it exists and is falsy, we'll delete it
            if (currentQueryFilters[key] && !value) {
                delete currentQueryFilters[key];
            }

            const newQuery = value ? {[key]: value} : {};

            const querystring = !isEmpty(currentQueryFilters) ? {...currentQueryFilters, ...newQuery} : newQuery;

            dispatch(updateSyncJob(addFilter(querystring)));
        },
        [addFilter, dispatch, syncFilters],
    );

    const removeFilter = useCallback(
        (item) => {
            if (!store.sync_job?.filters?.[item.key]) return false;

            handleFilter(item.key, null);

            return false;
        },
        [handleFilter, store.sync_job?.filters],
    );

    const getFilterEnabled = useCallback(
        (item) => {
            // No parent key and exists on the sync job, so allow it.
            if (!item.parent_key && store?.sync_job?.filters?.[item.key] !== undefined) return true;

            // The filter doesn't have a parent key, so we allow it.
            if (configuredFilters?.includes(item.key)) return true;

            // Configured filters haven't been loaded yet, so just pass.
            if (!configuredFilters || !configuredFilters?.length) return true;

            // The parent key doesn't exist in the configured filters, so we block the filter.
            if (!configuredFilters.includes(item.parent_key)) return removeFilter(item);

            // The parent key exists and is set to the correct key, so we allow the filter.
            const isSet = (store?.sync_job?.filters?.[item?.parent_key] ?? null) === item.value_key;

            return isSet ? true : removeFilter(item);
        },
        [configuredFilters, removeFilter, store?.sync_job?.filters],
    );

    const runFilter = useCallback(() => {
        if (!syncFilters) return;

        const {i, ...filters} = syncFilters ? syncFilters : {i: null};

        dispatch(
            runIntegrationFilterStart({
                i_id: syncJob.user_integration_id,
                f: filters,
            }),
        );
    }, [dispatch, syncFilters, syncJob?.user_integration_id]);

    // handles the pusher response if the user is sitting on the page when the sync is complete
    useEffect(() => {
        if (syncComplete) {
            //runFilter();
            dispatch(resetSyncComplete(false));
        }
    }, [dispatch, runFilter, syncComplete]);

    const toolbarFilters = useMemo(
        () =>
            Object.values(filters).map((filter) => {
                let defaultValue = ['data', 'date-range'].includes(filter.type) ? 'Select Date' : filter.defaultValue;

                // if we have a query, we'll set the default value to the query value
                if (syncFilters && Object.keys(syncFilters).includes(filter.key)) {
                    defaultValue = syncFilters[filter.key];
                }

                const constructConfig = (filter, localIndex = 0) => ({
                    id: filter.type + '-' + localIndex,
                    parent_key: filter.parent_key,
                    value_key: filter.value_key,
                    key: filter.key,
                    label: filter.label,
                    type: filter.type,
                    required: !!filter?.required,
                    defaultValue,
                    component: '',
                    visible: !filter.parent_key,
                });

                const elementConfig = {
                    ...constructConfig(filter),
                };

                const buttonType = filter.button_type || 'primary';

                if (filter.type === 'select') {
                    const sortedFilters = filter?.options?.sort((a, b) => a.label.localeCompare(b.label));

                    const options = [...sortedFilters];

                    if (!filter.api_all) {
                        options.push({
                            label: `All ${filter.label}`,
                            value: -1,
                            required: false,
                        });

                        if (!elementConfig.defaultValue) {
                            elementConfig.defaultValue = -1;
                        }
                    }

                    elementConfig.component = (
                        <Select
                            onChange={async (value) => handleFilter(filter.key, value)}
                            options={options}
                            placeholder={filter.api_all ? 'Select an option' : null}
                            required={filter.required}
                        />
                    );
                }

                if (filter.type === 'link') {
                    elementConfig.component = (
                        <Button type="primary" size="large" onClick={() => handleFilter(filter.key, filter.href)}>
                            {filter.text}
                        </Button>
                    );
                }

                if (filter.type === 'label') {
                    elementConfig.component = <label>{filter.text}</label>;
                }

                if (filter.type === 'button') {
                    elementConfig.component = (
                        <Button type={buttonType} size="large" onClick={() => handleFilter(filter.key, filter.href)}>
                            {filter.text}
                        </Button>
                    );
                }

                if (filter.type === 'date') {
                    elementConfig.component = (
                        <DatePicker
                            style={{width: '100%'}}
                            size="large"
                            onChange={(v) => handleFilter(filter.key, v)}
                            placeholder={`All Dates`}
                        />
                    );
                }

                if (filter.type === 'date-range') {
                    elementConfig.component = (
                        <DateRanger
                            style={{width: '100%'}}
                            size="large"
                            onChange={(v) => handleFilter(filter.key, v)}
                        />
                    );
                }

                if (filter.type === 'divider') {
                    elementConfig.component = <Divider />;
                }

                return elementConfig;
            }),
        [filters, handleFilter, syncFilters],
    );

    const allRequiredFilterKeys = useMemo(
        () =>
            Object.values(filters)
                .filter((filter) => filter.required)
                .map((filter) => filter.key),
        [filters],
    );

    const activeFilterKeys = Object.values(filters)
        ?.filter?.((item) => getFilterEnabled(item))
        ?.map((filter) => filter.key);

    const areRequiredFiltersSet = useMemo(() => {
        if (allRequiredFilterKeys.length === 0) return true;

        const requiredKeys = intersection(allRequiredFilterKeys, activeFilterKeys);

        if (requiredKeys.length === 0) return true;

        return requiredKeys.every((key) => syncFilters?.[key] !== undefined);
    }, [allRequiredFilterKeys, activeFilterKeys, syncFilters]);

    const toolbarButtons = [
        {
            id: 'submit-filters',
            key: 'submit_filters',
            singleRow: true,
            component: (
                <Button
                    type="primary"
                    ghost
                    block
                    size="large"
                    onClick={runFilter}
                    style={{marginTop: '1rem'}}
                    disabled={!areRequiredFiltersSet}
                >
                    Apply Filters
                </Button>
            ),
        },
    ];

    if (isEmpty(filters)) return [];

    return {
        areRequiredFiltersSet,
        getFilterEnabled,
        handleFilter,
        runFilter,
        toolbarFilters: [...toolbarFilters, ...toolbarButtons],
    };
}
