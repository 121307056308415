export const SET_FORM = 'SET_FORM';
export const SET_FORM_VALUES = 'SET_FORM_VALUES';
export const RESET_FORM = 'RESET_FORM';

const formsActions = {
    set: (key, values) => {
        return {
            type: SET_FORM,
            payload: {
                key,
                values,
            },
        };
    },

    setValues: (key, values) => {
        return {
            type: SET_FORM_VALUES,
            payload: {
                key,
                values,
            },
        };
    },

    reset: (key) => {
        return {
            type: RESET_FORM,
            payload: key,
        };
    },
};

export default formsActions;
