import {lazy} from 'react';
import {neutralPaths} from 'config/routes/neutral/paths';
import {externalPaths} from 'config/routes/external/paths';

export const neutralContainers = [
    {
        name: 'invites',
        path: neutralPaths.INVITES,
        component: lazy(() => import('containers/Auth/SignUp/SignUp.Invite')),
        exact: true,
        title: true,
    },
    {
        exact: true,
        title: true,
        path: neutralPaths.HELP,
        name: 'help',
        component: () => {
            window.location.href = externalPaths.SUPPORT;
            return false;
        },
    },
    {
        name: 'procurementRedirect',
        exact: true,
        path: neutralPaths.RETURN_TO_PROCUREMENT_REDIRECT,
        component: lazy(() => import('containers/Auth/Linking/Procurement')),
        title: true,
    },
    {
        exact: true,
        title: true,
        path: neutralPaths.CONTACT,
        name: 'contact',
        component: () => {
            window.location.href = externalPaths.CONTACT;
            return false;
        },
    },
    {
        exact: true,
        title: true,
        path: neutralPaths.LOGOUT,
        name: 'logout',
        component: lazy(() => import('containers/Auth/Logout')),
    },
    {
        name: 'procurementRedirect',
        // title: true,
        path: neutralPaths.PROCUREMENT_SESSION_REDIRECT,
        component: lazy(() => import('containers/Auth/SignIn/SignIn.Procurement')),
        // exact: true,
    },
];
