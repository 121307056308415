import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import businessHoursAction from 'redux/businessHours/actions';

const useBusinessHours = () => {
    const businessHours = useSelector(({businessHours}) => businessHours);

    useEffect(() => {
        if (businessHours.loaded || businessHours.loading) return;

        businessHoursAction.fetchBusinessHoursStart();
    }, [businessHours.loaded, businessHours.loading]);

    return {...businessHours};
};

export default useBusinessHours;
