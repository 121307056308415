import orderActions from './actions';

import ordersActions from 'redux/orders/actions';
import {LOGOUT} from 'redux/auth/auth.types';
import {PHYSICAL_ORDER} from 'config/orders';

const initialState = {
    data: null,
    error: null,
    success: null,
    loading: false,
    loaded: false,
    type: PHYSICAL_ORDER,
    action: null,
    recipient: null,
};

const ordersReducer = (state = initialState, action) => {
    switch (action.type) {
        case orderActions.FETCH_ORDER_DATA_FRESH:
            return {
                ...state,
                type: action.payload.type,
                loading: true,
                loaded: false,
                error: null,
            };

        case orderActions.FETCH_ORDER_DATA_START:
            return {
                ...state,
                type: action.payload.type,
                loading: true,
                error: null,
            };
        case orderActions.FETCH_ORDER_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                loaded: true,
                error: null,
            };
        case orderActions.FETCH_ORDER_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case orderActions.CLEAR_ORDER_DATA:
            return {
                ...initialState,
            };

        case orderActions.REORDER_PHYSICAL_DATA_START:
        case orderActions.REORDER_DIGITAL_DATA_START:
            return {
                ...state,
                action: null,
                payload: action.payload,
                success: null,
                error: null,
            };
        case orderActions.REORDER_PHYSICAL_DATA_SUCCESS:
        case orderActions.REORDER_DIGITAL_DATA_SUCCESS:
            return {
                ...state,
                action: 'reorder',
                success: action.payload,
                error: null,
            };
        case orderActions.REORDER_PHYSICAL_DATA_FAILURE:
        case orderActions.REORDER_DIGITAL_DATA_FAILURE:
            return {
                ...state,
                success: null,
                error: action.payload,
            };

        case orderActions.CANCEL_PHYSICAL_DATA_START:
        case orderActions.CANCEL_DIGITAL_DATA_START:
            return {
                ...state,
                payload: action.payload,
                success: null,
                error: null,
            };
        case orderActions.CANCEL_PHYSICAL_DATA_SUCCESS:
        case orderActions.CANCEL_DIGITAL_DATA_SUCCESS:
            return {
                ...state,
                success: action.payload,
                error: null,
            };
        case orderActions.CANCEL_PHYSICAL_DATA_FAILURE:
        case orderActions.CANCEL_DIGITAL_DATA_FAILURE:
            return {
                ...state,
                success: null,
                error: action.payload,
            };

        case orderActions.GET_ORDER_RECIPIENT:
            return {
                ...state,
                recipient: {
                    loading: false,
                    loaded: true,
                    data: {
                        data: action.payload
                            ? [
                                  {
                                      id: action.payload?.contact_id,
                                      email: action.payload?.email,
                                      name: `${action.payload?.first_name} ${action.payload?.last_name}`,
                                      status: action.payload?.status,
                                      value: action.payload?.value,
                                      delivered_at: action.payload?.delivered_at,
                                      delivered_response: action.payload?.delivered_response,
                                      bounced_at: action.payload?.bounced_at,
                                      bounced_response: action.payload?.bounced_response,
                                      can_reorder: action.payload?.can_reorder,
                                      can_cancel: action.payload?.can_cancel,
                                      disabled:
                                          action.payload?.can_reorder === false && action.payload?.can_cancel === false,
                                  },
                              ]
                            : null,
                        total: 1,
                    },
                },
            };

        //On the Orders store type change, lets clear up existing orders
        case ordersActions.CLEAR_ORDERS_DATA:
            return {
                ...initialState,
            };

        case orderActions.RESET_ORDERS_ACTION:
            return {
                ...state,
                action: null,
                success: null,
                error: null,
            };

        case LOGOUT:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};

export default ordersReducer;
