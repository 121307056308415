const sendActions = {
    SEND_SUBMIT: 'SEND_SUBMIT',
    SEND_SUBMIT_ERROR: 'SEND_SUBMIT_ERROR',
    SEND_SUBMIT_SUCCESS: 'SEND_SUBMIT_SUCCESS',
    SEND_SUBMIT_RESET: 'SEND_SUBMIT_RESET',

    submit: (payload) => ({
        type: sendActions.SEND_SUBMIT,
        payload,
    }),

    submitError: (payload) => ({
        type: sendActions.SEND_SUBMIT_ERROR,
        payload,
    }),

    reset: () => ({
        type: sendActions.SEND_SUBMIT_RESET,
    }),
};

export default sendActions;
