import {getLocalStore, removeLocalStore} from 'library/helpers/localStore';
import {persistor, store} from 'redux/store';
import formsActions from 'redux/forms/actions';

const forceFetch = (payload) =>
    payload &&
    typeof payload === 'object' &&
    Object.prototype.hasOwnProperty.call(payload, 'forceFetch') &&
    payload.forceFetch;

export function* localFetch(payload, store, type) {
    if (forceFetch(payload)) {
        yield removeLocalStore(store);

        return false;
    }
    return yield getLocalStore(store, type);
}

export const flushForms = (forms) => {
    persistor.pause();
    persistor.flush().then(() => persistor.purge());

    forms.forEach((form) => {
        store.dispatch(formsActions.reset(form));
    });
};
