import {all, put, takeEvery} from 'redux-saga/effects';
import campaignTypesActions from 'redux/campaignTypes/actions';
import {api} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';
import {API_CAMPAIGN_TYPE_NAME, SMART_CAMPAIGN_TYPE_NAMES} from 'config/campaigns';
import capitalize from 'lodash/capitalize';

function* fetchCampaignTypeData({payload}) {
    try {
        const campaignTypes = mapFields(yield fetchTypes());

        yield put(campaignTypesActions.fetchCampaignTypesSuccess(campaignTypes));
    } catch (error) {
        yield put(campaignTypesActions.fetchCampaignTypesError(error));
    }
}

// TODO: This is a little obscure... maybe we should keep this in the company_campaign_types table.
const mapFields = (fields) =>
    fields.map((field) => {
        field.displayName = SMART_CAMPAIGN_TYPE_NAMES.includes(field.name)
            ? `Smart ${capitalize(field.name.split('_')[1])}`
            : field.name === API_CAMPAIGN_TYPE_NAME
            ? 'API'
            : 'Standard';
        return field;
    });

function* fetchTypes() {
    return yield api.get(apiEndpoints.campaignTypes.get).then((response) => response.data);
}

export default function* campaignTypeSaga() {
    yield all([takeEvery(campaignTypesActions.FETCH_CAMPAIGN_TYPES_DATA_START, fetchCampaignTypeData)]);
}
