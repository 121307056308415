import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getProfileData} from 'redux/selectors';
import {useCallback, useEffect, useMemo, useState} from 'react';
import profileActions from 'redux/profile/actions';
import notificationsActions from 'redux/notifications/actions';
import {FontAwesome, notification} from 'components/UI';
import NotificationDescription from 'components/Pusher/PusherNotificationDescription';
import {sharedWorkerFallback} from 'library/vendor/pusher';
import {usePrev} from 'hooks/utility/usePrev';
import isEqual from 'react-fast-compare';
// eslint-disable-next-line
import workerUrl from 'worker-plugin/loader!../../workers/shared/pusher-worker.js';
import {integrationsDataSyncComplete} from 'redux/integrations/actions';
import {getPusherChannels} from 'redux/pusher/selectors';
import pusherActions from 'redux/pusher/actions';
import {matchPath} from 'react-router-dom';
import {fundingTransferActions} from 'redux/fundingTransfer/actions';
import descendantsActions from 'redux/descendants/actions';
import {routes} from 'config/routes';
import {isProduction} from "library/helpers/utilities";

export const useBroadcast = () => {
    const {id} = useSelector(getProfileData) || {};

    const channels = useSelector(getPusherChannels);

    const [pusherLoaded, setPusherLoaded] = useState(false);

    const dispatch = useDispatch();

    // Data hooks
    /**
     * @param {Object} PusherHooksObject - Event hooks object
     * @param {array} PusherHooksObject.* - Maps an event key to an array of redux actions to dispatch with the event payload
     */
    const pusherDataHooks = useMemo(
        () => ({
            'balance:updated': {
                dispatch: [profileActions.updateCreditAvailable],
            },
            'context:updated': {
                dispatch: [profileActions.updateDataContext],
            },
            'count:updated': {
                dispatch: [notificationsActions.updateNotificationsCount],
            },
            'post:created': {
                dispatch: [notificationsActions.updateNotificationsCount, notificationsActions.fetchNotificationsStart],
            },
            'sync:completed': {
                dispatch: [integrationsDataSyncComplete],
                suppressionRoutes: [routes.private.paths.INTEGRATIONS_SYNC_JOB_EDIT],
            },
            'funding_transfer:complete': {
                dispatch: [
                    fundingTransferActions.reset,
                    profileActions.fetchProfileDataStart,
                    descendantsActions.fetchAccountDescendantsStart,
                ],
                suppressionRoutes: [routes.private.paths.TRANSFER_FUNDS],
            },
        }),
        [],
    );

    const displayNotification = useCallback((payload) => {
        if (!payload?.web) return;

        notification.open({
            key: payload?.group_id,
            message: payload?.subject,
            description: <NotificationDescription payload={payload} />,
            closeIcon: <FontAwesome icon="circle-xmark" size="lg" />,
        });
    }, []);

    const initializeLocalPusher = useCallback(() => {
        isProduction() &&
            console.error('This browser does not support SharedWorkers. Fallback will be used.');

        sharedWorkerFallback(id, Object.keys(pusherDataHooks), (_, payload) => {
            const newChannels = {...channels, ...payload};

            dispatch(pusherActions.setChannels(newChannels));
        });

        setPusherLoaded(true);
    }, [channels, dispatch, id, pusherDataHooks]);

    const initialize = useCallback(
        (attempt = 1) => {
            if (pusherLoaded) return;
            if (!id) return;

            if (id === 0) {
                throw new Error("Pusher Debug: User's id is 0. Cannot initialize pusher.");
            }

            if (
                typeof window.SharedWorker === 'undefined' ||
                !isProduction()
            ) {
                return initializeLocalPusher();
            }

            const pusherWorker = new SharedWorker(workerUrl, {
                credentials: 'include',
                name: 'giftogram-pusher-worker',
                type: 'module',
            });

            pusherWorker.port.start();

            pusherWorker.port.onmessage = function (evt) {
                const {type, payload} = evt.data;

                switch (type) {
                    case 'LOAD_PUSHER':
                        break;
                    case 'PUSHER_LOADED':
                        setPusherLoaded(true);

                        break;
                    case 'PUSHER_EVENT': {
                        const newChannels = {...channels, ...payload};

                        dispatch(pusherActions.setChannels(newChannels));

                        break;
                    }
                    case 'PUSHER_ERROR':
                        console.error(`Pusher Error: ${payload}`);
                        pusherWorker.port.close();

                        // Restart worker
                        if (attempt < 4) {
                            setTimeout(() => initialize(attempt + 1), 5000 * attempt);
                        } else {
                            console.error(`Pusher Failed to load: ${payload}`);
                            initializeLocalPusher();
                        }

                        break;
                    default:
                        console.error('Pusher Debug: Unknown message type.');
                }
            };

            pusherWorker.port.postMessage({
                type: 'LOAD_PUSHER',
                payload: {id, eventList: Object.keys(pusherDataHooks)},
            });
        },
        [pusherLoaded, id, pusherDataHooks, initializeLocalPusher, channels, dispatch],
    );

    // Initialize pusher
    useEffect(() => {
        initialize();
    }, [channels, id, initialize]);

    const prevChannels = usePrev(channels);

    // Update pusher data
    useEffect(() => {
        Object.entries(pusherDataHooks).forEach(([eventKey, value]) => {
            const {dispatch: actionArray} = value;

            const notificationData = channels?.[eventKey]?.payload;

            if (notificationData === undefined || notificationData === null) {
                return;
            }

            if (isEqual(channels?.[eventKey]?.payload, prevChannels?.[eventKey]?.payload)) {
                return;
            }

            actionArray.forEach((action) => {
                dispatch(action(notificationData));
            });

            if (
                value.suppressionRoutes?.some((route) => {
                    return (
                        matchPath(window.location.pathname, {
                            path: route,
                            exact: false,
                            strict: false,
                        })?.url === window.location.pathname
                    );
                })
            ) {
                return;
            }

            displayNotification(notificationData);
        });
    }, [channels, dispatch, displayNotification, prevChannels, pusherDataHooks]);
};
