import {useEffect, useRef} from 'react';

/**
 * @param value
 * @param initialValue
 *
 * @returns {any}
 */
export const usePrev = (value, initialValue = null) => {
    const ref = useRef(initialValue);

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};
