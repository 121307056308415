import React from 'react';
import {Badge} from 'antd';
import PropTypes from 'prop-types';

const getRibbon = (props) => <Badge.Ribbon {...props}>{props.children}</Badge.Ribbon>;

const getChildren = ({children}) => children;

const Ribbon = (props) => (props.show ? getRibbon(props) : getChildren(props));

Ribbon.defaultProps = {
    show: true,
};

Ribbon.propTypes = {
    show: PropTypes.bool.isRequired,
};

export default Ribbon;
