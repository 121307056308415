import React, {useCallback} from 'react';
import {Card as AntCard} from 'antd';
import {FontAwesome, Dropdown, Breakpoint, Button, Space} from 'components/UI';
import PropTypes from 'prop-types';

export const Card = (props) => {
    const handleClick = (e, callback) => {
        if (typeof callback === 'function') return callback(e);
    };

    const getMenuItems = useCallback(() => {
        if (!props?.buttons || props.buttons.length === 0) return [];

        return props.buttons.map((item) => {
            return {
                key: `second-nav-menu-${item.id}`,
                disabled: item.disabled,
                onClick: (e) => handleClick(e, item.onClick),
                label: item.label,
            };
        });
    }, [props.buttons]);

    const RenderButtons = useCallback(() => {
        return [
            <Space size={props.buttonSpace} key="render-buttons">
                {props.buttons.map((item) => {
                    return (
                        <Button
                            key={`button-${item.id}`}
                            type={item.type}
                            ghost={item.ghost}
                            htmlType={item.htmlType || 'button'}
                            disabled={!!(props.loading || item.disabled || props.disabled)}
                            onClick={(e) => handleClick(e, item.onClick)}
                            className={item?.className}
                            icon={item.icon && item.icon}
                            size={item.size ? item.size : 'large'}
                            href={item.href}
                            target={item.target}
                            loading={item.loading}
                        >
                            {item.label}
                        </Button>
                    );
                })}
            </Space>,
        ];
    }, [props.buttons, props.disabled, props.loading, props.buttonSpace]);

    const renderExtra = useCallback(() => {
        return (
            <Breakpoint
                xs={false}
                sm={false}
                md={false}
                inverse={
                    <Dropdown
                        menu={{items: getMenuItems()}}
                        trigger={['click']}
                        placement="bottomRight"
                        type="text"
                        label={<FontAwesome size="xl" type="solid" icon="ellipsis" />}
                        arrow={false}
                        suffixIcon={false}
                    />
                }
            >
                <RenderButtons />
            </Breakpoint>
        );
    }, [getMenuItems]);

    return (
        <AntCard
            actions={props.actions}
            activeTabKey={props.activeTabKey}
            bodyStyle={props.bodyStyle}
            bordered={props.bordered}
            className={
                props.className + (props.shadow ? ' card-shadow' : '') + (!props.titleBorder ? ' no-title-border' : '')
            }
            cover={props.cover}
            defaultActiveTabKey={props.defaultActiveTabKey}
            description={props.description}
            extra={props.buttons ? renderExtra() : props.extra}
            headStyle={props.headStyle}
            hoverable={props.hoverable}
            loading={props.loading}
            size={props.size}
            style={props.style}
            tabBarExtraContent={props.tabBarExtraContent}
            tabList={props.tabList}
            tabProps={props.tabProps}
            title={props.title}
            type={props.type}
            onTabChange={props.onTabChange}
        >
            {props.children && props.children}
        </AntCard>
    );
};

// TODO: Deprecated, move to default params
Card.defaultProps = {
    buttons: false,
    buttonSpace: 32,
    extra: false,
    loading: false,
    shadow: false,
    title: '',
    titleBorder: true,
};

Card.propTypes = {
    actions: PropTypes.any,
    activeTabKey: PropTypes.any,
    bodyStyle: PropTypes.object,
    bordered: PropTypes.bool,
    buttons: PropTypes.any,
    buttonSpace: PropTypes.number,
    children: PropTypes.any,
    className: PropTypes.string,
    cover: PropTypes.any,
    defaultActiveTabKey: PropTypes.any,
    description: PropTypes.any,
    disabled: PropTypes.bool,
    extra: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    headStyle: PropTypes.object,
    hoverable: PropTypes.bool,
    loading: PropTypes.bool,
    onTabChange: PropTypes.func,
    shadow: PropTypes.bool,
    size: PropTypes.any,
    style: PropTypes.object,
    tabBarExtraContent: PropTypes.any,
    tabList: PropTypes.any,
    tabProps: PropTypes.any,
    title: PropTypes.any,
    titleBorder: PropTypes.bool,
    type: PropTypes.any,
};

export default Card;
