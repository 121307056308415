import {
    CLEAR_INTEGRATION_ALERTS,
    INITIALIZE_INTEGRATION_COMPLETE,
    INITIALIZE_INTEGRATION_START,
    LOAD_USER_INTEGRATIONS_COMPLETE,
    LOAD_USER_INTEGRATIONS_START,
    RUN_INTEGRATION_FILTER_START,
    RUN_INTEGRATION_FILTER_COMPLETED,
    SET_CACHING_INTEGRATION,
    SET_CURRENT_INTEGRATION,
    SET_SYNC_ERROR,
    IMPORT_SET_GROUPS,
    IMPORT_CONTACTS_START,
    IMPORT_CONTACTS_COMPLETE,
    SAVE_SYNC_JOB_START,
    LOAD_SYNCS_START,
    LOAD_SYNCS_COMPLETE,
    DELETE_SYNC_JOBS_START,
    DELETE_SYNC_JOBS_COMPLETE,
    LOAD_SYNC_START,
    LOAD_SYNC_COMPLETE,
    RESET_SYNC_EDIT_MODE,
    UPDATE_SYNC_MAP_COMPLETE,
    UPDATE_SYNC_JOB_COMPLETE,
    CREATE_SYNC_START,
    CREATE_SYNC_COMPLETE,
    SET_SAVING_INTEGRATION,
    SET_UPDATING_INTEGRATION,
    RESET_SYNC_COMPLETE,
    UPDATE_SYNC_NAME_START,
    UPDATE_SYNC_NAME_COMPLETE,
    UPDATE_SYNC_JOB,
    UPDATE_SYNC_STATUS_START,
    UPDATE_SYNC_STATUS_COMPLETE,
    RESET_SYNCS,
    RESET_SYNC_FILTERS,
    SET_SUCCESS,
    RESET_SYNC_JOB,
    UPDATE_SYNC_NAME_ERROR,
    RESET_SYNC_CREATED,
    LOAD_INSTALLED_INTEGRATIONS_COMPLETE,
    LOAD_INSTALLED_INTEGRATIONS_START,
    SET_CONFIGURED_FILTERS,
} from 'redux/integrations/actions';
import isEmpty from 'lodash/isEmpty';

/**
 * @typedef {Object} IntegrationsState
 * @type {{error_message: string, loading_integrations: boolean, import: {groupIds: *[]}, initializing: boolean, filters: {}, user_integrations: *[], caching: boolean, loading_contact: boolean, warning_message: string, current_integration: {}, sync_complete: boolean, success_message: string, contacts: {total: number, supported_fields: *[], results: *[]}}}
 */
const defaultState = {
    error_message: '',
    success_message: '',
    warning_message: '',
    deleting_sync: [],
    syncing: [],
    user_integrations: [],
    contacts: {
        results: [],
        supported_fields: [],
        total: 0,
    },
    current_integration: {},
    filters: {},
    configuredFilters: [],
    import: {
        groupId: 0,
    },
    installed: {
        loading: false,
        data: null,
        error: null,
    },
    syncs: {},
    sync_job: {},
    caching: true,
    created: false,
    importing: false,
    initializing: false,
    loading_contact: false,
    loading_integrations: false,
    loading_sync: false,
    loading_syncs: false,
    saving: false,
    sync_complete: false,
    updating: false,
    updating_sync_name: false,
    updating_sync_name_error: false,
    updating_sync_status: false,
};

export default function integrationsReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_SYNC_ERROR:
            return {
                ...state,
                error_message: action.payload ?? 'Something went wrong',
                initializing: false,
                loading_integrations: false,
                loading_contact: false,
                loading_syncs: false,
                loading_sync: false,
                saving: false,
                updating: false,
                updating_sync_name: false,
            };

        case SET_SUCCESS:
            return {
                ...state,
                success_message: action.payload ?? 'Success',
            };

        case IMPORT_CONTACTS_START:
            return {
                ...state,
                importing: true,
            };
        case IMPORT_CONTACTS_COMPLETE:
            return {
                ...state,
                importing: false,
            };
        case RUN_INTEGRATION_FILTER_START:
            return {
                ...state,
                loading_contacts: true,
            };
        case RUN_INTEGRATION_FILTER_COMPLETED:
            return {
                ...state,
                caching: false,
                loading_contacts: false,
                contacts: action.payload,
            };
        case SET_CACHING_INTEGRATION:
            return {
                ...state,
                caching: action.payload,
            };
        case CLEAR_INTEGRATION_ALERTS:
            return {
                ...state,
                error_message: '',
                success_message: '',
                warning_message: '',
            };
        case INITIALIZE_INTEGRATION_START:
            return {
                ...state,
                initializing: true,
            };
        case INITIALIZE_INTEGRATION_COMPLETE:
            return {
                ...state,
                sync_complete: true,
                caching: false,
                initializing: false,
                fields: action.payload.contacts.supported_fields,
                contacts: action.payload.contacts,
                filters: action.payload.filters,
                error_message: '',
                sync_job: {
                    ...state.sync_job,
                    cached: true,
                },
            };
        case LOAD_USER_INTEGRATIONS_START:
            return {
                ...state,
                loading_integrations: true,
            };
        case LOAD_USER_INTEGRATIONS_COMPLETE:
            return {
                ...state,
                loading_integrations: false,
                user_integrations: action.payload,
                error_message: '',
            };
        case SET_CURRENT_INTEGRATION:
            return {
                ...state,
                current_integration: action.payload,
            };
        case IMPORT_SET_GROUPS:
            return {
                ...state,
                import: {
                    ...state.import,
                    groupId: action.payload,
                },
            };
        case SAVE_SYNC_JOB_START:
            return {
                ...state,
                syncing: [...state.syncing, action.payload.id],
                sync_job: {
                    ...state.sync_job,
                    ...action.payload,
                },
            };
        case RESET_SYNC_FILTERS:
            return {
                ...state,
                sync_job: {
                    ...state.sync_job,
                    filters: {},
                },
            };
        case UPDATE_SYNC_JOB:
            return {
                ...state,
                sync_job: {
                    ...state.sync_job,
                    ...action.payload,
                },
            };
        case UPDATE_SYNC_JOB_COMPLETE: {
            const toggles = {updating: false, saving: false};

            if (isEmpty(state.syncs)) {
                return {
                    ...state,
                    sync_job: {...state.sync_job, ...action.payload},
                };
            }

            let updateSyncs = state.syncs;

            updateSyncs.data = updateSyncs.data.map((stateSync) => {
                if (stateSync.id === action.payload.id) {
                    return {
                        ...stateSync,
                        ...action.payload,
                    };
                }

                return stateSync;
            });

            return {
                ...state,
                syncs: updateSyncs,
                ...toggles,
            };
        }
        case LOAD_SYNC_START:
            return {
                ...state,
                sync_job: {},
                loading_sync: true,
            };
        case LOAD_SYNC_COMPLETE:
            return {
                ...state,
                sync_job: action.payload,
                loading_sync: false,
                sync_complete: false,
            };

        case LOAD_SYNCS_START:
            return {
                ...state,
                loading_syncs: true,
            };

        case LOAD_SYNCS_COMPLETE:
            return {
                ...state,
                loading_syncs: false,
                syncs: action.payload,
            };

        case RESET_SYNCS:
            return {
                ...state,
                syncs: defaultState.syncs,
            };
        case DELETE_SYNC_JOBS_START:
            return {
                ...state,
                loading_syncs: true,
            };
        case DELETE_SYNC_JOBS_COMPLETE: {
            const data = state.syncs.data.filter((s) => !action.payload.includes(Number(s.id)));

            return {
                ...state,
                loading_syncs: false,
                syncs: {
                    ...state.syncs,
                    data,
                },
            };
        }
        case UPDATE_SYNC_MAP_COMPLETE:
            return {
                ...state,
                sync_job: {
                    ...state.sync_job,
                    mapping: {
                        ...state.sync_job.mapping,
                        ...action.payload,
                    },
                },
            };
        case RESET_SYNC_EDIT_MODE:
            return {
                ...state,
                filters: defaultState.filters,
                contacts: defaultState.contacts,
                sync_job: defaultState.sync_job,
                syncs: defaultState.syncs,
            };
        case CREATE_SYNC_START:
            return {
                ...state,
                loading_syncs: true,
                created: false,
            };
        case CREATE_SYNC_COMPLETE:
            return {
                ...state,
                loading_syncs: false,
                syncs: action.payload,
                created: true,
            };
        case RESET_SYNC_CREATED:
            return {
                ...state,
                created: state.created,
            };
        case SET_SAVING_INTEGRATION:
            return {
                ...state,
                saving: action.payload,
            };
        case SET_UPDATING_INTEGRATION:
            return {
                ...state,
                updating: action.payload,
            };
        case RESET_SYNC_COMPLETE:
            return {
                ...state,
                sync_complete: false,
            };
        case RESET_SYNC_JOB:
            return {
                ...state,
                contacts: defaultState.contacts,
                sync_job: defaultState.sync_job,
            };
        case UPDATE_SYNC_NAME_START:
            return {
                ...state,
                updating_sync_name: true,
                updating_sync_name_error: false,
            };
        case UPDATE_SYNC_NAME_COMPLETE:
            return {
                ...state,
                updating_sync_name: false,
                updating_sync_name_error: false,
            };

        case UPDATE_SYNC_NAME_ERROR:
            return {
                ...state,
                updating_sync_name: false,
                updating_sync_name_error: action.payload,
            };

        case LOAD_INSTALLED_INTEGRATIONS_START:
            return {
                ...state,
                installed: {
                    loading: true,
                    data: null,
                },
            };

        case LOAD_INSTALLED_INTEGRATIONS_COMPLETE:
            return {
                ...state,
                installed: {
                    loading: false,
                    data: action.payload,
                },
            };

        case UPDATE_SYNC_STATUS_START:
            return {
                ...state,
                updating_sync_status: true,
            };

        case UPDATE_SYNC_STATUS_COMPLETE: {
            const newSyncs = state.syncs.data.map((sync) => {
                // we have action.payload.invalid_ids
                // this should be used and stored
                if (action.payload.updated_ids.includes(sync.id)) {
                    sync.active_status = action.payload.state;
                }

                return sync;
            });

            const errorSyncsMessage =
                action.payload.invalid_ids.length > 0
                    ? 'Following items could not be change: ' +
                      state.syncs.data
                          .filter((sync) => action.payload.invalid_ids.includes(sync.id))
                          .map((sync) => {
                              return sync.name;
                          })
                    : '';

            return {
                ...state,
                error_message: errorSyncsMessage,
                syncs: {
                    ...state.syncs,
                    data: newSyncs,
                },
                updating_sync_status: false,
            };
        }
        case SET_CONFIGURED_FILTERS:
            return {
                ...state,
                configuredFilters: action.payload,
            };

        default:
            return {...state};
    }
}
