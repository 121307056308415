import {api} from 'library/helpers/api';
import {getRoute} from 'library/helpers/routing';
import {apiEndpoints} from 'config/api';
import {setLocalCache} from 'library/helpers/localStore';
import {LARAVEL_AUTH_STATUS} from 'config/redux';

export const getCookie = async () => {
    return await api
        .get(apiEndpoints.auth.csrf)
        .then((res) => res)
        .catch((e) => {
            console.log('error fetching cookie', e);
        });
};
export const laravelLogin = async (token) => {
    // send our cognito login
    return await api
        .post(getRoute(apiEndpoints.auth.cognito), {idToken: token})
        .then((res) => {
            setLocalCache(LARAVEL_AUTH_STATUS, true);
            return res;
        })
        .catch((error) => {
            setLocalCache(LARAVEL_AUTH_STATUS, false);
            return {error};
        });
};
export const laravelLogout = async () => {
    return await api
        .post(getRoute(apiEndpoints.auth.logout))
        .then((res) => res)
        .catch((e) => e);
};
export const laravelRegister = async (values) => {
    const endpointKey = values.invite_key ? 'newInvite' : 'post';
    return await api
        .post(apiEndpoints.signUp[endpointKey], values)
        .then((res) => {
            return res;
        })
        .catch((e) => e);
};
export const updateAuthMfa = (enabled) => {
    return api.put(apiEndpoints.mfa[enabled ? 'enable' : 'disable']);
};
