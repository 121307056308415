import PropTypes from 'prop-types';
import React from 'react';
import Joyride, {ACTIONS as JoyrideACTIONS, EVENTS as JoyrideEVENTS, STATUS as JoyrideSTATUS} from 'react-joyride';
import {lang} from 'config/lang';

const joyrideStyles = {
    defaultOptions: {
        arrowColor: '#fff',
        backgroundColor: '#fff',
        beaconSize: 24,
        overlayColor: 'rgba(0, 0, 0, 0.7)',
        primaryColor: '#1169ed',
        spotlightShadow: '0 0 0.9375rem rgba(0, 0, 0, 0.5)',
        textColor: 'rgba(0, 0, 0, 0.85)',
        width: undefined,
        zIndex: 100,
        fontSize: '15px',
        lineHeight: 1.5715,
        textAlign: 'left',
    },
    buttonBack: {
        backgroundColor: 'transparent',
        borderRadius: 5,
        color: '#1169ed',
        fontSize: '15px',
        outline: 0,
        padding: '0 16px',
        lineHeight: '40px',
    },
    buttonNext: {
        backgroundColor: '#1169ed',
        borderRadius: 5,
        outline: 0,
        color: '#fff',
        fontSize: '15px',
        padding: '0 16px',
        lineHeight: '40px',
    },
    buttonLast: {
        backgroundColor: '#1169ed',
        borderRadius: 5,
        color: '#fff',
        outline: 0,
        fontSize: '15px',
        padding: '0 16px',
        lineHeight: '40px',
        marginLeft: '0.5rem',
    },
    buttonSkip: {
        backgroundColor: 'transparent',
        color: '#1169ed',
        fontSize: 15,
        lineHeight: '40px',
        outline: 0,
        padding: 0,
    },
    buttonClose: {display: 'none'},
    tooltip: {
        boxSizing: 'border-box',
        fontSize: 15,
        padding: 24,
        position: 'relative',
    },
    tooltipContainer: {
        textAlign: 'left',
    },
    tooltipTitle: {
        fontSize: 21,
        fontWeight: 'bold',
        lineHeight: 1.4,
        margin: '0 0 24px 0',
        color: 'rgba(0, 0, 0, 0.85)',
    },
    tooltipContent: {
        padding: '0',
        color: 'rgba(0, 0, 0, 0.85)',
        lineHeight: 1.5715,
        marginBottom: '16px',
    },
};

const Walkthrough = (props) => {
    return (
        <Joyride
            callback={props.callback}
            steps={props.steps}
            stepIndex={props.stepIndex}
            continuous={true}
            run={props.run}
            floaterProps={{
                styles: {
                    arrow: {
                        length: 12,
                        spread: 16,
                        margin: 16,
                    },
                },
            }}
            spotlightPadding={0}
            styles={{...joyrideStyles}}
            showSkipButton={true}
            disableOverlayClose={true}
            disableCloseOnEsc={true}
            disableScrolling={true}
            locale={{
                skip: lang.joyride.skip,
                next: lang.joyride.next,
                back: lang.joyride.back,
                last: lang.joyride.last,
            }}
        />
    );
};

export default Walkthrough;

export const EVENTS = JoyrideEVENTS;
export const ACTIONS = JoyrideACTIONS;
export const STATUS = JoyrideSTATUS;
export const joyrideState = {
    joyrideRun: false,
    joyrideSteps: null,
    joyrideIndex: 0,
};

Walkthrough.propTypes = {
    callback: PropTypes.func,
    callbackAdvance: PropTypes.func,
    callbackDismiss: PropTypes.func,
    run: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    stepIndex: PropTypes.number,
    steps: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};
