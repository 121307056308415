import recipientsUploadActions from './actions';

const initData = {
    data: null,
    formData: null,
    loading: false,
    error: null,
    loaded: false,
    saved: false,
    running: false,
};

const recipientsUploadReducer = (state = initData, action) => {
    const {payload} = action;
    const hasPayload = !!payload;
    let saved = false;

    if (state.saved || action.type === recipientsUploadActions.SAVE_RECIPIENTS_UPLOAD_DATA_SUCCESS) {
        saved = true;
    }

    switch (action.type) {
        case recipientsUploadActions.SET_RECIPIENTS_UPLOAD_DATA_START:
            return {
                ...state,
                data: null,
                formData: action.payload,
                loading: true,
                error: false,
                loaded: hasPayload,
                saved,
            };
        case recipientsUploadActions.SET_RECIPIENTS_UPLOAD_DATA_SUCCESS:
            return {
                ...state,
                formData: null,
                data: action.payload,
                loading: false,
                error: false,
                loaded: true,
                saved,
            };
        case recipientsUploadActions.SET_RECIPIENTS_UPLOAD_DATA_FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload,
                loaded: false,
                saved,
            };

        case recipientsUploadActions.SAVE_RECIPIENTS_UPLOAD_DATA_START:
            return {
                mapping: payload.mapping,
                ...state,
                loading: true,
                error: false,
                loaded: hasPayload,
                saved,
            };
        case recipientsUploadActions.SAVE_RECIPIENTS_UPLOAD_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                loaded: !!state.data,
                saved: true,
            };
        case recipientsUploadActions.SAVE_RECIPIENTS_UPLOAD_DATA_FAILURE:
            return {
                ...state,
                loading: null,
                error: true,
                loaded: false,
                saved,
            };

        case recipientsUploadActions.REMOVE_RECIPIENTS_UPLOAD_DATA_START:
            return {
                ...state,
                data: null,
                loading: true,
                error: true,
                loaded: false,
                saved,
            };
        case recipientsUploadActions.REMOVE_RECIPIENTS_UPLOAD_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                loaded: false,
                saved,
            };
        case recipientsUploadActions.REMOVE_RECIPIENTS_UPLOAD_DATA_FAILURE:
            return {
                ...state,
                loading: null,
                error: true,
                loaded: false,
                saved,
            };

        case recipientsUploadActions.FETCH_RECIPIENTS_UPLOAD_DATA_START:
            return {
                data: payload,
                loading: true,
                error: false,
                loaded: hasPayload,
                saved,
            };
        case recipientsUploadActions.FETCH_RECIPIENTS_UPLOAD_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                loaded: true,
                saved,
            };
        case recipientsUploadActions.FETCH_RECIPIENTS_UPLOAD_DATA_FAILURE:
            return {
                ...state,
                loading: null,
                error: true,
                loaded: false,
                saved,
            };

        case recipientsUploadActions.CHECK_RECIPIENTS_JOB_RUNNING_START:
            return {
                ...state,
                error: false,
                running: false,
            };
        case recipientsUploadActions.CHECK_RECIPIENTS_JOB_RUNNING_SUCCESS:
            return {
                ...state,
                running: false,
            };
        case recipientsUploadActions.CHECK_RECIPIENTS_JOB_RUNNING_FAILURE:
            return {
                ...state,
                running: true,
            };

        case recipientsUploadActions.CLEAR_RECIPIENTS_UPLOAD_JOB_START:
            return {
                ...state,
                error: false,
                running: false,
            };
        case recipientsUploadActions.CLEAR_RECIPIENTS_UPLOAD_JOB_SUCCESS:
            return {
                ...state,
                error: false,
                running: false,
            };
        case recipientsUploadActions.CLEAR_RECIPIENTS_UPLOAD_JOB_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case recipientsUploadActions.RESET_UPLOAD_STORE:
            return {
                ...initData,
            };
        default:
            return state;
    }
};

export default recipientsUploadReducer;
