import recipientGroupActions from './actions';

const INITIAL_DATA = {
    data: null,
    loading: false,
    error: null,
    loaded: false,
};

const recipientReducer = (state = INITIAL_DATA, action) => {
    switch (action.type) {
        case recipientGroupActions.SET_RECIPIENT_GROUP_DATA_START:
            return {
                data: action.payload,
                loading: true,
                error: false,
                loaded: false,
            };
        case recipientGroupActions.SET_RECIPIENT_GROUP_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                loaded: true,
            };
        case recipientGroupActions.SET_RECIPIENT_GROUP_DATA_FAILURE:
            return {
                ...state,
                loading: null,
                error: true,
                loaded: false,
            };

        case recipientGroupActions.UPDATE_RECIPIENT_GROUP_DATA_START:
            return {
                ...state,
                // data: action.payload.groups,
                loading: true,
                error: false,
                loaded: false,
            };
        case recipientGroupActions.UPDATE_RECIPIENT_GROUP_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                loaded: true,
            };
        case recipientGroupActions.UPDATE_RECIPIENT_GROUP_DATA_FAILURE:
            return {
                ...state,
                loading: null,
                error: true,
                loaded: false,
            };
        default:
            return state;
    }
};

export default recipientReducer;
