import {NEUTRAL_ROUTES} from 'config/routes/neutral';
import {PRIVATE_ROUTES} from 'config/routes/private';
import {PUBLIC_ROUTES} from 'config/routes/public';
import {EXTERNAL_ROUTES} from 'config/routes/external';

export const publicRoutesList = Object.keys(PUBLIC_ROUTES.paths).map((key) => PUBLIC_ROUTES.paths[key]);

export const routes = {
    neutral: NEUTRAL_ROUTES,
    private: PRIVATE_ROUTES,
    public: PUBLIC_ROUTES,
    external: EXTERNAL_ROUTES,
};
