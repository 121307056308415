import React from 'react';
import {Dropdown as AntDropdown} from 'antd';
import {FontAwesome, Space} from 'components/UI';
import {iconStyle} from 'assets/styles/common';
import PropTypes from 'prop-types';

export const DropdownButton = (props) => {
    return (
        <AntDropdown.Button
            {...props}
            trigger={props.trigger}
            icon={<FontAwesome type="solid" icon="ellipsis" size="lg" />}
        >
            <Space>
                {props.icon && <FontAwesome type={iconStyle} icon={props.icon} />}
                {props.children}
            </Space>
        </AntDropdown.Button>
    );
};

// TODO: Deprecated, move to default params
DropdownButton.defaultProps = {
    disabled: false,
    loading: false,
    size: 'large',
    trigger: 'click',
    type: 'default',
    placement: 'bottomLeft',
};

DropdownButton.propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    size: PropTypes.any.isRequired,
    trigger: PropTypes.any.isRequired,
    type: PropTypes.any.isRequired,
    placement: PropTypes.string,
};

export default DropdownButton;
