import React from 'react';

const icon = () => (
    <svg viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <circle fill="#0052B4" cx="256" cy="256" r="256"></circle>
                <g transform="translate(100.175000, 100.174000)" fill="#FFDA44">
                    <polygon points="155.826 0 164.115 25.509 190.935 25.509 169.236 41.274 177.525 66.783 155.826 51.017 134.126 66.783 142.415 41.274 120.716 25.509 147.537 25.509"></polygon>
                    <polygon points="45.639 45.64 69.539 57.816 88.504 38.852 84.307 65.342 108.206 77.519 81.715 81.715 77.519 108.207 65.342 84.308 38.852 88.505 57.817 69.54"></polygon>
                    <polygon points="4.26325641e-14 155.826 25.509 147.537 25.509 120.716 41.273 142.416 66.783 134.127 51.016 155.826 66.783 177.525 41.273 169.237 25.509 190.936 25.509 164.115"></polygon>
                    <polygon points="45.639 266.012 57.816 242.112 38.852 223.147 65.343 227.345 77.518 203.446 81.715 229.937 108.205 234.133 84.309 246.31 88.504 272.8 69.539 253.835"></polygon>
                    <polygon points="155.826 311.652 147.536 286.143 120.716 286.143 142.416 270.378 134.126 244.871 155.826 260.635 177.525 244.871 169.236 270.378 190.935 286.143 164.114 286.143"></polygon>
                    <polygon points="266.012 266.012 242.113 253.836 223.147 272.801 227.344 246.309 203.447 234.133 229.937 229.937 234.133 203.446 246.309 227.345 272.799 223.147 253.834 242.114"></polygon>
                    <polygon points="311.651 155.826 286.142 164.115 286.142 190.936 270.377 169.236 244.87 177.525 260.635 155.826 244.87 134.127 270.378 142.416 286.142 120.716 286.142 147.538"></polygon>
                    <polygon points="266.012 45.64 253.835 69.54 272.8 88.505 246.308 84.307 234.133 108.206 229.937 81.715 203.447 77.518 227.344 65.342 223.147 38.853 242.114 57.817"></polygon>
                </g>
            </g>
        </g>
    </svg>
);

export default icon;
