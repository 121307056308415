import shippingRatesActions from './actions';

const initialState = {
  loading: null,
  error: null,
  data: null,
};

const shippingRatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case shippingRatesActions.FETCH_SHIPPING_RATES_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case shippingRatesActions.FETCH_SHIPPING_RATES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case shippingRatesActions.FETCH_SHIPPING_RATES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default shippingRatesReducer;
