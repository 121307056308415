const brandKitsActions = {
    /*FETCH*/
    FETCH_BRAND_KITS: 'FETCH_BRAND_KITS',
    fetchBrandKits: (payload) => {
        return {
            type: brandKitsActions.FETCH_BRAND_KITS,
            payload,
        };
    },
    FETCH_BRAND_KITS_SUCCESS: 'FETCH_BRAND_KITS_SUCCESS',
    fetchBrandKitsSuccess: (payload) => ({
        type: brandKitsActions.FETCH_BRAND_KITS_SUCCESS,
        payload,
    }),
    FETCH_BRAND_KITS_ERROR: 'FETCH_BRAND_KITS_ERROR',
    fetchBrandKitsError: (payload) => ({
        type: brandKitsActions.FETCH_BRAND_KITS_ERROR,
        payload,
    }),

    /*FETCH BRAND KIT*/
    FETCH_BRAND_KIT: 'FETCH_BRAND_KIT',
    fetchBrandKit: (payload) => {
        return {
            type: brandKitsActions.FETCH_BRAND_KIT,
            payload,
        };
    },
    FETCH_BRAND_KIT_SUCCESS: 'FETCH_BRAND_KIT_SUCCESS',
    fetchBrandKitSuccess: (payload) => ({
        type: brandKitsActions.FETCH_BRAND_KIT_SUCCESS,
        payload,
    }),
    FETCH_BRAND_KIT_ERROR: 'FETCH_BRAND_KIT_ERROR',
    fetchBrandKitError: (payload) => ({
        type: brandKitsActions.FETCH_BRAND_KITS_ERROR,
        payload,
    }),

    /*FETCH MESSAGES*/
    FETCH_BRAND_KIT_MESSAGES: 'FETCH_BRAND_KIT_MESSAGES',
    fetchBrandKitMessages: (payload) => {
        return {
            type: brandKitsActions.FETCH_BRAND_KIT_MESSAGES,
            payload,
        };
    },
    FETCH_BRAND_KIT_MESSAGES_SUCCESS: 'FETCH_BRAND_KIT_MESSAGES_SUCCESS',
    fetchBrandKitMessagesSuccess: (payload) => ({
        type: brandKitsActions.FETCH_BRAND_KIT_MESSAGES_SUCCESS,
        payload,
    }),
    FETCH_BRAND_KIT_MESSAGES_ERROR: 'FETCH_BRAND_KIT_MESSAGES_ERROR',
    fetchBrandKitMessagesError: (payload) => ({
        type: brandKitsActions.FETCH_BRAND_KIT_MESSAGES_ERROR,
        payload,
    }),

    /*FETCH SENDER NAMES*/
    FETCH_BRAND_KIT_SENDER_NAMES: 'FETCH_BRAND_KIT_SENDER_NAMES',
    fetchBrandKitSenderNames: (payload) => {
        return {
            type: brandKitsActions.FETCH_BRAND_KIT_SENDER_NAMES,
            payload,
        };
    },
    FETCH_BRAND_KIT_SENDER_NAMES_SUCCESS: 'FETCH_BRAND_KIT_SENDER_NAMES_SUCCESS',
    fetchBrandKitSenderNamesSuccess: (payload) => ({
        type: brandKitsActions.FETCH_BRAND_KIT_SENDER_NAMES_SUCCESS,
        payload,
    }),
    FETCH_BRAND_KIT_SENDER_NAMES_ERROR: 'FETCH_BRAND_KIT_SENDER_NAMES_ERROR',
    fetchBrandKitSenderNamesError: (payload) => ({
        type: brandKitsActions.FETCH_BRAND_KIT_SENDER_NAMES_ERROR,
        payload,
    }),

    /*UPDATE_FIELD*/
    UPDATE_BRAND_KIT_FIELD: 'UPDATE_BRAND_KIT_FIELD',
    updateBrandKitField: (payload) => ({
        type: brandKitsActions.UPDATE_BRAND_KIT_FIELD,
        payload,
    }),

    /*RESET BRAND KIT ERROR*/
    RESET_BRAND_KIT_ERROR: 'RESET_BRAND_KIT_ERROR',
    resetBrandKitError: () => ({
        type: brandKitsActions.RESET_BRAND_KIT_ERROR,
    }),

    /*SET UNSAVED*/
    SET_UNSAVED_FORM_DATA: 'SET_UNSAVED_FORM_DATA',
    modifyUnsavedFormData: (payload) => ({
        type: brandKitsActions.SET_UNSAVED_FORM_DATA,
        payload,
    }),

    /*RESET BRAND KIT*/
    RESET_BRAND_KIT: 'RESET_BRAND_KIT',
    resetBrandKit: () => ({
        type: brandKitsActions.RESET_BRAND_KIT,
    }),
    /* RESET BRAND KITS */
    RESET_BRAND_KITS: 'RESET_BRAND_KITS',
    resetBrandKits: () => ({
        type: brandKitsActions.RESET_BRAND_KITS,
    }),
    /*RESET MESSAGES*/
    RESET_MESSAGES: 'RESET_MESSAGES',
    resetMessages: () => ({
        type: brandKitsActions.RESET_MESSAGES,
    }),

    /*RESET SENDER NAMES*/
    RESET_SENDER_NAMES: 'RESET_SENDER_NAMES',
    resetSenderNames: () => ({
        type: brandKitsActions.RESET_SENDER_NAMES,
    }),

    /*RESET ALL*/
    RESET_ALL: 'RESET_ALL',
    resetAll: () => ({
        type: brandKitsActions.RESET_ALL,
    }),
};

export default brandKitsActions;
