import {selector} from 'recoil';
import {
  amplifyState,
  defaultAmplifyState,
} from 'services/amplify/atoms/amplify';

export const amplifyDefault = selector({
  key: 'amplifyDefault',
  get: () => defaultAmplifyState,
});

export const amplifyIsConfigured = selector({
  key: 'isConfigured',
  get: ({get}) => {
    const state = get(amplifyState);
    return state.configured;
  },
});

export const showUsernameAndPasswordSelector = selector({
  key: 'showUsernameAndPassword',
  get: ({get}) => {
    const {client} = get(amplifyState);
    if (!client || !client.providers) return false;

    return client.providers.some((p) => p.provider === 'COGNITO');
  },
});
