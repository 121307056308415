import {createContext} from 'react';
import {getCampaignKey, getCampaignValue} from 'library/helpers/campaign';

const formData = {
    0: {
        _isValid: false,
        [getCampaignKey('name')]: getCampaignValue('name'),
        [getCampaignKey('sendingType')]: getCampaignValue('sendingType'),
        [getCampaignKey('regionIds')]: getCampaignValue('regionIds'),
        [getCampaignKey('denomination')]: getCampaignValue('denomination'),
        [getCampaignKey('primaryProductIds')]: getCampaignValue('primaryProductIds'),
        [getCampaignKey('startDate')]: getCampaignValue('startDate'),
        [getCampaignKey('endDate')]: getCampaignValue('endDate'),
        [getCampaignKey('companyContactProperty')]: getCampaignValue('companyContactProperty'),
        [getCampaignKey('type')]: getCampaignValue('type'),
        [getCampaignKey('smart_campaign_type')]: getCampaignValue('smart_campaign_type'),
        [getCampaignKey('requireAdminApproval')]: getCampaignValue('requireAdminApproval'),
        [getCampaignKey('interval_years')]: getCampaignValue('interval_years'),
    },
    1: {
        _isValid: false,
        [getCampaignKey('brandKitId')]: getCampaignValue('brandKitId'),
        [getCampaignKey('senderNameId')]: getCampaignValue('senderNameId'),
        [getCampaignKey('senderName')]: getCampaignValue('senderName'),
        [getCampaignKey('subject')]: getCampaignValue('subject'),
        [getCampaignKey('message')]: getCampaignValue('message'),
        [getCampaignKey('allowEditMessage')]: getCampaignValue('allowEditMessage'),
        [getCampaignKey('overrideSenderFrom')]: getCampaignValue('overrideSenderFrom'),
        [getCampaignKey('allowEditSender')]: getCampaignValue('allowEditSender'),
        [getCampaignKey('designs')]: getCampaignValue('designs'),
    },
    2: {
        _isValid: false,
        [getCampaignKey('groups')]: getCampaignValue('groups'),
        [getCampaignKey('giftingEnterEmail')]: getCampaignValue('giftingEnterEmail'),
        [getCampaignKey('allowDuplicateSending')]: getCampaignValue('allowDuplicateSending'),
    },
    3: {
        _isValid: false,
        [getCampaignKey('paymentMethods')]: getCampaignValue('paymentMethods'),
        [getCampaignKey('shippingAddresses')]: getCampaignValue('shippingAddresses'),
    },
};

export const campaignManagementDefaultState = {
    formData: {...formData},
    initialValues: {...formData},
};

export const CampaignsContext = createContext(campaignManagementDefaultState);
