import companyActions from './actions';

import {LOGOUT} from 'redux/auth/auth.types';

const initialState = {
    data: null,
    updating: false,
    loading: false,
    loaded: false,
    error: null,
};

const companyReducer = (state = initialState, action) => {
    switch (action.type) {
        case companyActions.FETCH_COMPANY_DATA_START:
            return {
                ...state,
                loading: true,
                loaded: false,
                error: null,
            };
        case companyActions.FETCH_COMPANY_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                loaded: true,
                error: null,
            };
        case companyActions.FETCH_COMPANY_DATA_FAILURE:
            return {
                loading: false,
                loaded: false,
                error: action.payload,
            };

        case companyActions.UPDATE_COMPANY_DATA_START:
            return {
                ...state,
                data: action.payload,
                updating: true,
                error: null,
            };
        case companyActions.UPDATE_COMPANY_DATA_SUCCESS:
            return {
                ...state,
                updating: false,
                error: null,
            };
        case companyActions.UPDATE_COMPANY_DATA_FAILURE:
            return {
                ...state,
                updating: false,
                error: action.payload,
            };

        case companyActions.UPDATE_COMPANY_SETTINGS_START:
            return {
                ...state,
                updating: true,
                error: null,
            };
        case companyActions.UPDATE_COMPANY_SETTINGS_SUCCESS:
            return {
                ...state,
                updating: false,
                error: null,
            };
        case companyActions.UPDATE_COMPANY_SETTINGS_FAILURE:
            return {
                ...state,
                updating: false,
                error: action.payload,
            };

        case companyActions.UPDATE_COMPANY_LOGO_START:
            return {
                ...state,
                logo: action.payload,
                updating: true,
                error: null,
            };
        case companyActions.UPDATE_COMPANY_LOGO_SUCCESS:
            return {
                ...state,
                data: {...state.data, logo: action.payload},
                updating: false,
                error: null,
            };
        case companyActions.UPDATE_COMPANY_LOGO_FAILURE:
            return {
                ...state,
                updating: false,
                error: action.payload,
            };
        case companyActions.CLEAR_COMPANY_DATA:
            return {
                ...initialState,
            };

        case LOGOUT:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default companyReducer;
