import bulkOrderActions from './actions';

const initialState = {
    order: {
        data: null,
        loading: false,
        loaded: false,
        error: null,
    },
    recipients: {
        data: null,
        loading: false,
        loaded: false,
        error: null,
    },
    error: null,
    success: null,
    queued: null,
    failed: null,
};

const calculateRecipientsDisabled = (recipientsData) => {
    return {
        ...recipientsData,
        data: recipientsData.data.map((recipient) => {
            if (recipient.can_cancel === false && recipient.can_reorder === false) {
                recipient.disabled = true;
            }

            return recipient;
        }),
    };
};

const bulkOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case bulkOrderActions.FETCH_BULK_ORDER_START:
            return {
                ...state,
                order: {
                    data: null,
                    loading: true,
                    loaded: false,
                    error: null,
                },
                queued: null,
                failed: null,
            };

        case bulkOrderActions.FETCH_BULK_ORDER_SUCCESS:
            return {
                ...state,
                order: {
                    data: action.payload,
                    loading: false,
                    loaded: true,
                    error: null,
                },
            };

        case bulkOrderActions.FETCH_BULK_ORDER_FAILURE:
            return {
                ...state,
                order: {
                    data: null,
                    loading: false,
                    error: action.payload,
                    recipients: null,
                },
            };

        case bulkOrderActions.CLEAR_BULK_ORDER_DATA:
            return {
                ...initialState,
            };

        case bulkOrderActions.FETCH_BULK_ORDER_RECIPIENTS_START:
            return {
                ...state,
                recipients: {
                    data: null,
                    loading: true,
                    loaded: false,
                    error: null,
                },
                queued: null,
                failed: null,
            };

        case bulkOrderActions.FETCH_BULK_ORDER_RECIPIENTS_SUCCESS:
            return {
                ...state,
                recipients: {
                    data: calculateRecipientsDisabled(action.payload),
                    loading: false,
                    loaded: true,
                    error: null,
                },
            };

        case bulkOrderActions.FETCH_BULK_ORDER_RECIPIENTS_FAILURE:
            return {
                ...state,
                recipients: {
                    data: null,
                    loading: false,
                    error: action.payload,
                    recipients: null,
                },
            };

        case bulkOrderActions.CANCEL_BULK_ORDER_START:
            return {
                ...state,
                success: null,
                error: null,
            };

        case bulkOrderActions.CANCEL_BULK_ORDER_SUCCESS:
            return {
                ...state,
                success: action.payload,
                error: null,
            };

        case bulkOrderActions.CANCEL_BULK_ORDER_FAILURE:
            return {
                ...state,
                success: null,
                error: action.payload ?? 'Failed to cancel order.',
            };

        case bulkOrderActions.CANCEL_BULK_ORDER_INDIVIDUAL_START:
            return {
                ...state,
                success: null,
                error: null,
            };

        case bulkOrderActions.CANCEL_BULK_ORDER_INDIVIDUAL_SUCCESS:
            return {
                ...state,
                success: action.payload,
                error: null,
            };

        case bulkOrderActions.CANCEL_BULK_ORDER_INDIVIDUAL_FAILURE:
            return {
                ...state,
                success: null,
                error: action.payload ?? 'Failed to cancel order.',
            };

        case bulkOrderActions.REORDER_BULK_ORDER_INDIVIDUAL_START:
            return {
                ...state,
                action: null,
                payload: action.payload,
                success: null,
                error: null,
                queued: null,
                failed: null,
            };

        case bulkOrderActions.REORDER_BULK_ORDER_INDIVIDUAL_SUCCESS:
            return {
                ...state,
                action: 'reorder',
                success: action.payload,
                error: null,
                queued: action.payload.queued,
                failed: action.payload.failed,
            };

        case bulkOrderActions.REORDER_BULK_ORDER_INDIVIDUAL_FAILURE:
            return {
                ...state,
                success: null,
                failed: action.payload,
            };

        default:
            return state;
    }
};

export default bulkOrderReducer;
