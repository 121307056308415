import React, {Suspense} from 'react';
import Amplifier from 'containers/Amplifier';
import NeutralRoutes from 'containers/Routes/NeutralRoutes';
import PublicRoutes from 'containers/Routes/PublicRoutes';
import PrivateRoutes from 'containers/Routes/PrivateRoutes';
import ErrorBoundary from 'containers/ErrorBoundary';
import {history} from 'redux/store';
import {PageTransition} from 'components/Layout';
import {Router as ReactRouter, Switch} from 'react-router-dom';

const Router = () => {
    return (
        <ErrorBoundary key="authedRoutes">
            <ReactRouter history={history}>
                <Suspense fallback={<PageTransition loaded={false} />}>
                    <Switch>
                        <Amplifier>
                            <NeutralRoutes key="neutralRoutes" />
                            <PublicRoutes key="publicRoutes" />
                            <PrivateRoutes key="privateRoutes" />
                        </Amplifier>
                    </Switch>
                </Suspense>
            </ReactRouter>
        </ErrorBoundary>
    );
};

export default Router;
