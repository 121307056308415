import {ggDate} from '../date';
import range from 'lodash/range';

const monthArray = range(1, 13);
const dayArray = range(1, 32);

const formatMMMDDYYYY = (currentValue) => {
    if (currentValue.length < 2) return currentValue;

    let month = currentValue.slice(0, 2);
    let day = currentValue.slice(2, 4);
    let year = currentValue.slice(4, 8);

    // recurse if value is invalid
    if (
        (month?.length === 2 && !monthArray.includes(Number(month))) ||
        (day?.length === 2 && !dayArray.includes(Number(day)))
    ) {
        return formatMMMDDYYYY(currentValue.slice(0, -1));
    }

    const resultArray = [month, day, year];
    let result = '';

    for (let i = 0; i < 3; i++) {
        if (!resultArray[i]) break;

        if (i !== 0) result += '/';

        result += resultArray[i];
    }

    return result;
};

export const formatDate = (value, format = 'MM/DD/YYYY') => {
    if (!value) return value;

    const currentValue = value.replace(/\D/g, '');

    switch (format) {
        case 'MM/DD':
            return currentValue;
        case 'MM/DD/YYYY':
            return formatMMMDDYYYY(currentValue);
        default:
            return currentValue;
    }
};

export const parseDate = (value, format = 'MM/DD/YYYY') => {
    if (!value) return value;

    const lengthMap = {'MM/DD': 4, 'MM/DD/YYYY': 8};

    return value.replace(/[^0-9]/g, '').substr(0, lengthMap[format]); // We add dashes on submit, not here
};
/* Phone Numbers */

export const formatPhone = (value, previousValue) => {
    if (!value) return value;

    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
        // returns: "x", "xx", "xxx"
        if (cvLength < 4) return currentValue;

        // returns: "(xxx) xxx",
        if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

        // returns: "(xxx) xxx-xxxx"
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
};

export const parsePhone = (value) => {
    if (!value) return value;

    return value.replace(/[^\d]+/g, '').substr(0, 10);
};

/* Zip Codes */

export const formatUSCANZip = (value, previousValue) => {
    if (!value) return value;

    const currentValue = value.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
        // returns: "xxxxx"
        if (cvLength < 7) return currentValue;

        // returns: "xxxxx-xxxx" (i.e. zip + 4)
        return `${currentValue.slice(0, 5)}-${currentValue.slice(5, 9)}`;
    }
};

export const parseUSCANZip = (value) => {
    if (!value) return value;

    return value
        .replace(/[^A-Za-z0-9]+/g, '')
        .substr(0, 9)
        .toUpperCase();
};

export const formatUSZip = (value, previousValue) => {
    if (!value) return value;

    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
        // returns: "xxxxx"
        if (cvLength < 6) return currentValue;

        // returns: "xxxxx-xxxx" (i.e. zip + 4)
        return `${currentValue.slice(0, 5)}-${currentValue.slice(5, 9)}`;
    }
};

export const parseUSZip = (value) => {
    if (!value) return value;

    return value.replace(/[^\d]+/g, '').substr(0, 9);
};

export const hexToRGB = (hex) => {
    return hex
        .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1)
        .match(/.{2}/g)
        .map((x) => parseInt(x, 16));
};

export const checkShade = (hex) => {
    if (hex.length < 6) return null;

    const rgb = hexToRGB('#' + hex);

    return Math.round((parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[2]) * 114) / 1000);
};

export const floorToPrecision = (value, precision) => {
    const multiplier = Math.pow(10, precision);

    return Math.floor(value * multiplier) / multiplier;
};

export const exchangeToUsd = (amount, rate) => floorToPrecision(amount / rate, 2);

export const numberFormatter = (number) => new Intl.NumberFormat().format(number);

export const currencyFormatter = (number, decimals, currency, exchange_rate) => {
    const amount = exchange_rate ? exchangeToUsd(number, exchange_rate) : number;
    const currency_type = currency ? currency : 'USD';

    if (isNaN(amount)) return '';

    return Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency_type,
        minimumFractionDigits: decimals,
    }).format(amount);
};

export const adjustmentFormatter = (number) => {
    return 0 - number;
};

export const getCurrencySymbol = (currency) => {
    const symbol = new Intl.NumberFormat('en', {
        style: 'currency',
        currency: currency ?? 'USD',
    })
        .formatToParts(1)
        .find((x) => x.type === 'currency');

    if (!symbol) return null;

    return symbol.value;
};

export const dateFormatter = (date) => ggDate(date).format('MM/DD/YYYY');

export const phoneFormatter = (phoneNumberString) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }

    return null;
};

// Display in 5 minute intervals so the user can see a time consistent with our intervals
export const roundUpTo = (time, minutes = 5) => {
    let date = ggDate.parseFormat(time, 'YYYY-MM-DD HH:mm:ss');
    let minute = date.minute();
    let remainder = minutes - (minute % minutes);

    if (remainder === minutes) {
        remainder = 0;
    }

    return date.add(remainder, 'minute').startOf('minute').format('YYYY-MM-DD HH:mm:ss');
};

export const maskPhone = (phone) => phone && `***-***-${phone.slice(-4)}`;

export const formatHex = (value) =>
    !value
        ? value
        : value
              .replace(/[^a-fA-F0-9]+/g, '')
              .toUpperCase()
              .slice(0, 6)
              .trim();
