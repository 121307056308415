import React, {forwardRef, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {FormItem, hasError, NoResults} from 'components/FormikForm';
import {TreeSelect} from 'components/UI';
import omit from 'lodash/omit';
import FontAwesome from 'components/UI/FontAwesome';

// eslint-disable-next-line react/display-name
const FormTreeSelect = forwardRef((props, ref) => {
    const [name] = useState(props.field.name);

    const handleChange = useCallback(
        (v) => {
            const value = props.parser(v);
            props.form.setValues({
                ...props.form.values,
                [name]: value,
            });
            if (typeof props.onChange === 'function') props.onChange(value);
        },
        [name, props],
    );

    const selectProps = omit(props, [
        'options',
        'ant',
        'wrapperCol',
        'labelCol',
        'noLoading',
        'formatter',
        'parser',
        'placeholder',
        'showSearch',
        'expandAll',
    ]);

    const selectValue = () => {
        return props.form.values === null ? null : props.formatter(props.form.values[name]);
    };

    return (
        <FormItem
            {...props}
            errors={hasError(props.field.name, props.form)}
            skelType="select"
            extra={props.extra}
            help={props.help}
        >
            <TreeSelect
                {...props.field}
                {...selectProps}
                {...props.ant}
                style={{width: '100%'}}
                suffixIcon={props.suffixIcon}
                onChange={handleChange}
                value={selectValue()}
                disabled={props.disabled}
                treeData={props.options}
                treeDefaultExpandAll={props.expandAll}
                dropdownStyle={{maxHeight: 425, overflow: 'auto'}}
                ref={ref}
                showSearch={props.showSearch}
                notFoundContent={<NoResults />}
            />
        </FormItem>
    );
});

// TODO: Deprecated, add to params
FormTreeSelect.defaultProps = {
    defaultValue: '',
    options: [],
    suffixIcon: <FontAwesome icon="chevron-down" type="solid" size="xs" />,
    expandAll: true,
    showSearch: true,
    formatter: (value) => value,
    parser: (value) => value,
};

FormTreeSelect.propTypes = {
    ant: PropTypes.any,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    expandAll: PropTypes.bool,
    extra: PropTypes.string,
    field: PropTypes.object,
    form: PropTypes.object,
    formatter: PropTypes.func,
    help: PropTypes.any,
    onChange: PropTypes.func,
    options: PropTypes.any.isRequired,
    parser: PropTypes.func,
    showSearch: PropTypes.bool,
    suffixIcon: PropTypes.any,
    validate: PropTypes.func,
};

export default FormTreeSelect;
