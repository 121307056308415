/**
 * Recoil module to persist state to storage
 *
 * @param config Optional configuration object
 * @param config.key Used as key in local storage, defaults to `recoil-persist`
 * @param config.storage Local storage to use, defaults to `localStorage`
 */
import {DefaultValue} from 'recoil';

export const recoilPersist = (config) => {
    const {key = 'gg-persist', storage = localStorage} = config;

    if (typeof window === 'undefined') {
        return {
            persistAtom: () => {},
        };
    }

    const persistAtom = ({onSet, node, trigger, setSelf}) => {
        if (trigger === 'get') {
            if (containsKey(node.key)) setSelf(retrieveValue(node.key));
        }

        onSet(persistValue(node.key));
    };

    const containsKey = (key) => {
        const state = getState();

        return Object.prototype.hasOwnProperty.call(state, key);
    };

    const retrieveValue = (key) => {
        const state = getState();
        return state[key];
    };

    const persistValue = (name) => (newValue) => {
        let state = getState();
        state[name] = newValue;

        if (newValue instanceof DefaultValue) {
            delete state[name];
        }

        setState(state);
    };

    const getState = () => {
        const toParse = storage.getItem(key);
        if (toParse) {
            try {
                return JSON.parse(toParse);
            } catch (e) {
                console.error(e);
                return {};
            }
        } else {
            return {};
        }
    };

    const setState = (state) => {
        try {
            storage.setItem(key, JSON.stringify(state));
        } catch (e) {
            console.error(e);
        }
    };

    return {persistAtom};
};
