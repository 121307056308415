import {getRouteComponents} from 'containers/Routes';
import {routes} from 'config/routes';
import usePublicHooks from 'hooks/routes/usePublicHooks';

const PublicRoutes = (props) => {
    usePublicHooks();

    return getRouteComponents(routes.public.containers, props);
};

export default PublicRoutes;
