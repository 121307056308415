import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {FontAwesome, Layout} from 'components/UI';

const {Content} = Layout;

const PageLoading = ({key, minHeight}) => (
    <Layout key={key} className="transparent-bg" style={{minHeight: minHeight}}>
        <Content className="main-content content-centered">
            <FontAwesome waiting size="2xl" />
        </Content>
    </Layout>
);

PageLoading.propTypes = {
    key: PropTypes.string,
    minHeight: PropTypes.number,
};

/** @param children {JSX.Element} */
const getContent = (children) => children;

const PageTransition = ({loaded, loadingTimeout = 0, minHeight = 0, ...props}) => {
    useEffect(() => {
        if (!loadingTimeout) return;

        const interval = setInterval(() => {
            throw new Error('Page loading failed');
        }, loadingTimeout * 1000);

        return () => {
            clearInterval(interval);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return loaded ? getContent(props.children) : <PageLoading minHeight={minHeight} />;
};

PageTransition.propTypes = {
    children: PropTypes.any,
    loaded: PropTypes.bool,
    loadingTimeout: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    minHeight: PropTypes.number,
};

export default PageTransition;
