import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'components/UI';
import {FormikConsumer} from 'formik';
import {lang} from 'config/lang';

const FormSubmitButton = (props) => {
    return (
        <FormikConsumer>
            {(formProps) => {
                const submittable =
                    (Object.keys(formProps.touched).length > 0 || formProps.dirty || props.cleanSubmittable) &&
                    !props.button.disabled;

                const isLoading = props.isSubmitting ? props.isSubmitting : formProps.isSubmitting;
                return (
                    <Button
                        htmlType="submit"
                        type={props.type}
                        size={props.size}
                        block={props.block}
                        loading={isLoading}
                        {...props.button}
                        disabled={props.disabled === false ? false : props.disabled || !submittable}
                        className={props.className}
                        style={props.style}
                    >
                        {props.children || props.label || lang.button.submit}
                    </Button>
                );
            }}
        </FormikConsumer>
    );
};

FormSubmitButton.defaultProps = {
    block: false,
    button: {disabled: false},
    className: '',
    cleanSubmittable: false,
    isSubmit: true,
    isSubmitting: false,
    size: 'large',
    style: {},
    type: 'primary',
};

FormSubmitButton.propTypes = {
    block: PropTypes.bool,
    button: PropTypes.object,
    children: PropTypes.any,
    className: PropTypes.string,
    cleanSubmittable: PropTypes.bool,
    disabled: PropTypes.bool,
    isSubmit: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    label: PropTypes.any,
    shadow: PropTypes.any,
    size: PropTypes.string,
    style: PropTypes.object,
    type: PropTypes.string,
};

export default FormSubmitButton;
