const campaignCreditCardsActions = {
  FETCH_CAMPAIGN_CREDIT_CARDS_DATA_START:
    'FETCH_CAMPAIGN_CREDIT_CARDS_DATA_START',
  FETCH_CAMPAIGN_CREDIT_CARDS_DATA_SUCCESS:
    'FETCH_CAMPAIGN_CREDIT_CARDS_DATA_SUCCESS',
  FETCH_CAMPAIGN_CREDIT_CARDS_DATA_FAILURE:
    'FETCH_CAMPAIGN_CREDIT_CARDS_DATA_FAILURE',
  fetchCampaignCreditCardsDataStart: (payload) => ({
    type: campaignCreditCardsActions.FETCH_CAMPAIGN_CREDIT_CARDS_DATA_START,
    payload,
  }),
  fetchCampaignCreditCardsDataSuccess: (payload) => ({
    type: campaignCreditCardsActions.FETCH_CAMPAIGN_CREDIT_CARDS_DATA_SUCCESS,
    payload,
  }),
  fetchCreditCardsDataFailure: (error) => ({
    type: campaignCreditCardsActions.FETCH_CAMPAIGN_CREDIT_CARDS_DATA_FAILURE,
    payload: error,
  }),
}

export default campaignCreditCardsActions
