import savedDesignsCampaignActions from './actions';
import profileAction from 'redux/profile/actions';

const INITIAL_DATA = {
    data: null,
    loading: false,
    error: null,
};

const savedDesignsCampaignReducer = (state = INITIAL_DATA, action) => {
    switch (action.type) {
        case savedDesignsCampaignActions.FETCH_SAVED_DESIGNS_CAMPAIGN_START:
            return {
                ...state,
                loading: true,
                error: false,
            };

        case savedDesignsCampaignActions.FETCH_SAVED_DESIGNS_CAMPAIGN_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
            };

        case savedDesignsCampaignActions.FETCH_SAVED_DESIGNS_CAMPAIGN_FAILURE:
            return {
                ...state,
                loading: null,
                error: true,
            };

        case profileAction.UPDATE_DATA_CONTEXT_CAMPAIGN_ID_SUCCESS:
            return {
                ...state,
                loading: true,
                error: false,
            };

        default:
            return state;
    }
};

export default savedDesignsCampaignReducer;
