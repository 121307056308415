import {all, takeEvery, put, select, takeLeading} from 'redux-saga/effects';
import companyActions from 'redux/company/actions';
import {api} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';
import regionsActions from 'redux/regions/actions';
import {getAllRegionsData} from '../regions/selectors';

function* fetchCompany() {
    try {
        const {data} = yield api.get(apiEndpoints.companies.get);

        // in addition to company info, also fetch all primary products and add it to 'data'
        let allPriamryProducts = yield api.get(apiEndpoints.primary_products.all);

        data.all_primary_products = allPriamryProducts.data;

        yield put(companyActions.fetchCompanySuccess(data));
    } catch (error) {
        yield put(companyActions.fetchCompanyFailure(error));
    }
}

function* updateCompanyData({payload}) {
    try {
        const data = {
            legal_name: payload.legal_name,
            default_currency: payload.default_currency,
            name: payload.display_name,
            address: payload.address,
            address_2: payload.address_2,
            city: payload.city,
            state: payload.state,
            zip: payload.zip,
            country: payload.country,
            logo: payload.logo,
            brand_color: payload.brand_color,
            url: payload.url ? payload.url.replace(/(^\w+:|^)\/\//, '') : payload.url,
        };

        yield api.put(apiEndpoints.companies.put, data);

        yield put(companyActions.updateCompanyDataSuccess(payload));
        yield put(companyActions.fetchCompanyStart());
    } catch (error) {
        yield put(companyActions.updateCompanyDataFailure(error));
    }
}

function* updateCompanySettings({payload}) {
    try {
        yield api.put(apiEndpoints.companies.updateSettings, payload);

        if (payload?.region_ids) {
            const allRegions = yield select(getAllRegionsData);

            yield put(
                regionsActions.updateRegions(allRegions.filter((region) => payload.region_ids.includes(region.id))),
            );
        }

        yield put(companyActions.updateCompanySettingsSuccess(payload));
        yield put(companyActions.fetchCompanyStart());
    } catch (error) {
        yield put(companyActions.updateCompanySettingsFailure(error));
    }
}

export default function* companySaga() {
    yield all([
        takeLeading(companyActions.FETCH_COMPANY_DATA_START, fetchCompany),
        takeEvery(companyActions.UPDATE_COMPANY_DATA_START, updateCompanyData),
        takeEvery(companyActions.UPDATE_COMPANY_SETTINGS_START, updateCompanySettings),
    ]);
}
