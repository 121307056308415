import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Switch, Space} from 'components/UI';
import {FormItem, hasError} from 'components/FormikForm';

/**
 *
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
const FormCheckbox = (props) => {
    const {type = 'checkbox', ant, checkboxLabel, disabledProp, isSubmitting, field, form, onChange} = props;
    const {name} = field;
    const disabled = form.isSubmitting || isSubmitting ? true : disabledProp || false;

    const handleChange = (e) => {
        const event = type === 'checkbox' ? e.target.checked : e;

        const values = {
            ...form.values,
            [name]: event,
        };

        form.setValues(values);

        if (onChange) onChange(values);
    };

    return (
        <FormItem {...props} errors={hasError(name, form)} skelType="input">
            {type === 'checkbox' && (
                <Checkbox name={name} onChange={handleChange} disabled={disabled} {...ant}>
                    {checkboxLabel}
                </Checkbox>
            )}

            {type === 'switch' && (
                <Space size={6}>
                    <Switch name={name} onChange={handleChange} disabled={disabled} {...ant} /> {checkboxLabel}
                </Space>
            )}
        </FormItem>
    );
};

FormCheckbox.propTypes = {
    ant: PropTypes.object,
    checkboxLabel: PropTypes.any,
    disabledProp: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    field: PropTypes.object,
    form: PropTypes.object,
    onChange: PropTypes.func,
    type: PropTypes.string,
};

export default FormCheckbox;
