import React from 'react';
import PropTypes from 'prop-types';
import {Button, Empty, Layout, Space, FontAwesome, Alert, Breakpoint} from 'components/UI';
import {Table as AntTable} from 'antd';
import TableToolbar from './modules/TableToolbar';
import defaultConfig from 'components/UI/Table/config/defaultConfig';

const {Sider, Content} = Layout;

/**
 *
 */
class Table extends React.PureComponent {
    state = {
        buttonsDisabled: true,
        selectedRowKeys: [],
        disabledRowKeys: [],
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedRowKeys, disabledRowKeys} = this.props;

        if (prevProps.selectedRowKeys !== selectedRowKeys) {
            this.setState({
                selectedRowKeys: selectedRowKeys,
            });
        }
        if (prevProps.disabledRowKeys !== disabledRowKeys) {
            this.setState({
                disabledRowKeys: disabledRowKeys,
            });
        }
    }

    handleOnChange = (page, perPage) => {
        this.props.onChange({
            page,
            per_page: Number(perPage),
        });
    };

    handleTableChange = (pagination, filters, sorter) => {
        this.props.onSort({
            field: sorter.field,
            order: sorter.order,
            key: sorter.columnKey,
        });
    };

    /**
     * @param {PaginationData} props ,
     * @return {PaginationData} object
     */
    pagination = (props) => {
        return {
            component: props.paginationComponent,
            defaultCurrent: props.defaultCurrent || 1,
            current: props.dataSource?.current_page || 1,
            total: props.dataSource?.total || 0,
            position: props.position,
            size: props.paginationSize,
            pageSizeOptions: props.pageSizeOptions,
            hideOnSinglePage: props.hideOnSinglePage,
            showSizeChanger: props.showSizeChanger,
            pageSize: Number(props.dataSource.per_page) || defaultConfig.per_page,
            defaultPageSize: Number(props.dataSource.per_page) || defaultConfig.per_page,
            showTotal: () => props.showTotal || this.renderTotal(),
        };
    };

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({selectedRowKeys});

        if (this.props.onRowSelect) {
            this.props.onRowSelect(selectedRowKeys, selectedRows);
        }

        if (this?.props?.rowSelection?.onSelect) {
            this.props.rowSelection.onSelect(selectedRowKeys, selectedRows);
        }
    };

    renderTotal = () => `${this.props.dataSource.total} ${this.props.name} found`;

    render() {
        const tableLoading = {
            spinning: this.props.loading,
            indicator: <FontAwesome waiting size="2xl" />,
        };

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            type: this.props.selectable,
            onChange: this.onSelectChange,
            getCheckboxProps: (record) => ({
                disabled: this.state.disabledRowKeys.includes(record.id),
            }),
        };

        const tableEmpty = {
            emptyText: (
                <Empty
                    image={<FontAwesome icon={this.props.emptyIcon} type="duotone" empty={!this.props.emptyIcon} />}
                    description={this.props.emptyTitle}
                    style={{opacity: !this.props.loading ? 1 : 0}}
                >
                    {this.props.emptyDescription && (
                        <Space direction="vertical" size={32} align="center">
                            {this.props.emptyDescription && this.props.emptyDescription}
                            {this.props.emptyButton && (
                                <Button
                                    disabled={this.props.emptyButton.disabled}
                                    type={this.props.emptyButton.type}
                                    onClick={this.props.emptyButton.onClick}
                                    href={this.props.emptyButton.href}
                                    size="large"
                                >
                                    {this.props.emptyButton.label}
                                </Button>
                            )}
                        </Space>
                    )}
                </Empty>
            ),
        };

        const renderToolbar = (props) => {
            const _props = {...this.props};

            return (
                <TableToolbar
                    buttons={this.props.toolbarButtons}
                    buttonsAction={this.props.toolbarButtonsAction}
                    buttonState={this.state.buttonsDisabled}
                    filters={this.props.toolbarFilters}
                    filtersDisplayCount={this.props.filtersDisplayCount}
                    search={this.props.toolbarSearch}
                    onPageChange={this.handleOnChange}
                    pagination={this.pagination(_props)}
                    showPagination={
                        !!(
                            this.props.showPagination &&
                            this.props.dataSource.data &&
                            this.props.dataSource.data.length > 0
                        )
                    }
                    showTotals={this.props.showTotals}
                    showSizeChanger={this.props.showSizeChanger}
                    totalsText={this.props.totalsText}
                    dataLoaded={this.props.dataSource.data !== undefined}
                    loading={this.props.loading}
                    showButtons={props.showButtons}
                    showFilters={props.showFilters && (this.props.dataSource.length > 0 || this.props.dataSource.data)}
                    showSearch={props.showSearch}
                    toolbarInline={props.toolbarInline}
                    additionalFooterButtons={this.props.additionalToolbarFooterButtons}
                    onReset={this.props.onReset}
                    hideOnSinglePage={this.props.hideOnSinglePage}
                />
            );
        };

        const renderTable = () => {
            return (
                <>
                    {this.props.alerts && (
                        <Alert
                            closable={false}
                            key={`alert-${this.props.alerts.type}`}
                            type={this.props.alerts.type}
                            message={this.props.alerts.message}
                        />
                    )}
                    <AntTable
                        bordered={false}
                        onChange={this.handleTableChange}
                        pagination={false}
                        loading={tableLoading}
                        locale={tableEmpty}
                        dataSource={this.props.dataSourceData ? this.props.dataSource.data : this.props.dataSource}
                        columns={this.props.columns}
                        rowKey={this.props.rowKey}
                        size={this.props.tableSize}
                        showHeader={this.props.showHeader}
                        showSorterTooltip={this.props.showSorterTooltip}
                        scroll={this.props.scroll}
                        onRow={this.props.onRow && this.props.onRow}
                        rowSelection={
                            this.props.rowSelection
                                ? this.props.rowSelection
                                : this.props.selectable
                                ? rowSelection
                                : false
                        }
                        rowClassName={this.props.rowClassName}
                        className={this.props.className + (this.props.outerBorder && ' outer-border')}
                        expandable={
                            this.props.expandable && {
                                ...this.props.expandable,
                                defaultExpandAllRows: this.props.defaultExpandAllRows,
                                expandIcon: ({expanded, onExpand, record}) =>
                                    expanded ? (
                                        <Button
                                            type="text"
                                            onClick={(e) => onExpand(record, e)}
                                            className={
                                                record.children && record.children.length === 0 ? 'expand-disabled' : ''
                                            }
                                            style={{marginRight: '12px'}}
                                        >
                                            <FontAwesome icon="caret-down" type="solid" />
                                        </Button>
                                    ) : (
                                        <Button
                                            type="text"
                                            onClick={(e) => onExpand(record, e)}
                                            className={
                                                record.children && record.children.length === 0 && 'expand-disabled'
                                            }
                                            style={{marginRight: '12px'}}
                                        >
                                            <FontAwesome icon="caret-right" type="solid" />
                                        </Button>
                                    ),
                            }
                        }
                    />
                </>
            );
        };

        // This is a special format for the sync table
        // This entire component needs to be cleaned up
        if (this.props.toolbarInline) {
            return (
                <Layout key="main-content">
                    {this.props.showToolbar && (
                        <Breakpoint xs={false} sm={false} md={false}>
                            <Sider width={400} theme="light" style={{paddingBottom: 0}}>
                                {renderToolbar({
                                    prefix: 'top-',
                                    showFilters: true,
                                    showButtons: false,
                                    showSearch: false,
                                    toolbarInline: true,
                                })}
                            </Sider>
                        </Breakpoint>
                    )}

                    <Content className={'main-content has-chat'}>
                        {renderTable()}

                        {renderToolbar({
                            prefix: 'bottom-',
                            showButtons: false,
                            showFilters: false,
                            showSearch: false,
                        })}
                    </Content>
                </Layout>
            );
        }

        return (
            <Space
                size={this.props.showToolbar ? 24 : 0}
                direction="vertical"
                style={{display: !this.props.showToolbar ? 'block' : 'inline-flex'}}
                className="gg-table-pagination"
            >
                {this.props.showToolbar &&
                    renderToolbar({
                        prefix: 'top-',
                        showFilters: true,
                        showButtons: this.props.selectedRowKeys.length > 0,
                    })}

                {renderTable()}

                {this.props.showToolbar &&
                    renderToolbar({
                        prefix: 'bottom-',
                        showButtons: false,
                        showFilters: false,
                        showSearch: false,
                    })}
            </Space>
        );
    }
}

Table.defaultProps = {
    buttonsBottom: true,
    buttonsDisabled: true,
    dataSourceData: true,
    defaultCurrent: 1,
    emptyButton: null,
    emptyDescription: null,
    emptyIcon: '',
    emptyTitle: 'No Data',
    hideOnSinglePage: false,
    loading: false,
    name: 'items',
    onRowSelect: null,
    onSort: () => {},
    outerBorder: true,
    pageSizeOptions: [25, 50, 100, 250, 500],
    position: 'both',
    rowKey: 'id',
    rowSelection: null,
    scroll: {},
    selectable: false,
    selectedRowKeys: [],
    disabledRowKeys: [],
    selectionType: 'checkbox',
    showHeader: true,
    showPagination: true,
    showSearch: true,
    showToolbar: false,
    showSizeChanger: true,
    showSorterTooltip: false,
    showTotal: null,
    showTotals: true,
    tableSize: 'middle',
    toolbarButtons: null,
    toolbarButtonsAction: null,
    toolbarInline: false,
    toolbarSearch: false,
    totalsText: 'items',
    expandable: false,
    defaultExpandAllRows: false,
    additionalToolbarFooterButtons: [],
};

Table.propTypes = {
    alerts: PropTypes.object,
    buttonsBottom: PropTypes.bool,
    buttonsDisabled: PropTypes.bool,
    className: PropTypes.string,
    columns: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]).isRequired,
    dataSource: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    dataSourceData: PropTypes.bool.isRequired,
    defaultCurrent: PropTypes.number,
    emptyButton: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    emptyDescription: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    emptyIcon: PropTypes.string,
    emptyTitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hideOnSinglePage: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onReset: PropTypes.func,
    onRow: PropTypes.func,
    onRowSelect: PropTypes.func,
    onSort: PropTypes.func,
    outerBorder: PropTypes.bool,
    pageSizeOptions: PropTypes.array,
    paginationSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    position: PropTypes.string,
    rowClassName: PropTypes.func,
    rowKey: PropTypes.string,
    rowSelection: PropTypes.object,
    scroll: PropTypes.object,
    selectable: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    selectedRowKeys: PropTypes.array,
    disabledRowKeys: PropTypes.array,
    selectionType: PropTypes.string,
    showButtons: PropTypes.any,
    showFilters: PropTypes.any,
    showSearch: PropTypes.any,
    showHeader: PropTypes.bool,
    showToolbar: PropTypes.bool,
    showPagination: PropTypes.bool,
    showSizeChanger: PropTypes.bool,
    showSorterTooltip: PropTypes.bool,
    showTotal: PropTypes.func,
    showTotals: PropTypes.bool,
    tableSize: PropTypes.string,
    toolbarButtons: PropTypes.array,
    toolbarButtonsAction: PropTypes.string,
    toolbarFilters: PropTypes.any,
    filtersDisplayCount: PropTypes.number,
    toolbarInline: PropTypes.bool,
    toolbarSearch: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    totalsText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    id: PropTypes.any,
    expandable: PropTypes.any,
    defaultExpandAllRows: PropTypes.bool,
    additionalToolbarFooterButtons: PropTypes.array,
};

export {defaultConfig};

export default Table;
