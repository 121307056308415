import React from 'react';
import PropTypes from 'prop-types';

const IFrame = ({iframeLoaded, style, url}) => {
    if (!url) return null;

    return (
        <iframe
            onLoad={iframeLoaded && iframeLoaded}
            id={`iframe_${url}`}
            title="Giftogram"
            name="giftogram"
            src={url}
            style={{...style, width: '100%'}}
            frameBorder="0"
        />
    );
};

IFrame.propTypes = {
    iframeLoaded: PropTypes.func,
    style: PropTypes.object,
    url: PropTypes.string.isRequired,
};

export default IFrame;
