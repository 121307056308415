import {store} from 'redux/store';
import uploadActions from 'redux/upload/actions';
import {ggDate} from 'library/helpers/date';
import {getDefaultSendData} from 'library/helpers/send';

const sendTransform = (data, __global) => {
    const profile = __global?.profile;
    const campaigns = __global?.campaigns;

    if (data === null) {
        store.dispatch(uploadActions.uploadReset({type: 'bulk'}));
    }

    const storedData = data
        ? {
              ...data,
              saved_at: ggDate().format('YYYY-MM-DD HH:mm:ss'),
          }
        : getDefaultSendData({campaigns, profile});

    if (profile) {
        storedData.context = {...(profile?.data?.data?.context ?? {}), account_id: profile?.data?.id ?? 0};
    }

    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            const value = data[key];

            const setBaseValue = () => {
                storedData[key] =
                    typeof value === 'object' && !Array.isArray(value) && value !== null
                        ? {...storedData[key], ...value}
                        : value;
            };

            // mostly awful aliases or inferred values
            switch (key) {
                case 'group_name':
                    storedData.groupName = value;

                    break;

                case 'group_count':
                    storedData.group_recipient_count = value;

                    break;

                case 'recipients':
                    storedData.quantity = value.length;

                    break;

                default:
                    break;
            }

            setBaseValue();
        }
    }

    return {...storedData};
};

export default sendTransform;
