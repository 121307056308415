const recipientsActions = {
    FETCH_RECIPIENTS_DATA_START: 'FETCH_RECIPIENTS_DATA_START',
    FETCH_RECIPIENTS_DATA_SUCCESS: 'FETCH_RECIPIENTS_DATA_SUCCESS',
    FETCH_RECIPIENTS_DATA_FAILURE: 'FETCH_RECIPIENTS_DATA_FAILURE',
    fetchRecipientsStart: (payload = {query: {page: 1}}) => ({
        type: recipientsActions.FETCH_RECIPIENTS_DATA_START,
        payload,
    }),
    fetchRecipientsSuccess: (recipients) => {
        return {
            type: recipientsActions.FETCH_RECIPIENTS_DATA_SUCCESS,
            payload: recipients,
        };
    },
    fetchRecipientsError: (error) => ({
        type: recipientsActions.FETCH_RECIPIENTS_DATA_FAILURE,
        payload: error,
    }),

    REFETCH_RECIPIENTS_DATA_START: 'REFETCH_RECIPIENTS_DATA_START',
    REFETCH_RECIPIENTS_DATA_SUCCESS: 'REFETCH_RECIPIENTS_DATA_SUCCESS',
    REFETCH_RECIPIENTS_DATA_FAILURE: 'REFETCH_RECIPIENTS_DATA_FAILURE',
    refetchRecipientsStart: (payload) => ({
        type: recipientsActions.REFETCH_RECIPIENTS_DATA_START,
        payload,
    }),
    refetchRecipientsSuccess: (payload) => {
        return {
            type: recipientsActions.REFETCH_RECIPIENTS_DATA_SUCCESS,
            payload,
        };
    },
    refetchRecipientError: (error) => ({
        type: recipientsActions.REFETCH_RECIPIENTS_DATA_FAILURE,
        payload: error,
    }),

    TOGGLE_RECIPIENTS_STATUS_START: 'TOGGLE_RECIPIENTS_STATUS_START',
    TOGGLE_RECIPIENTS_STATUS_SUCCESS: 'TOGGLE_RECIPIENTS_STATUS_SUCCESS',
    TOGGLE_RECIPIENTS_STATUS_FAILURE: 'TOGGLE_RECIPIENTS_STATUS_FAILURE',
    toggleStatusRecipientsToGroupStart: (payload) => ({
        type: recipientsActions.TOGGLE_RECIPIENTS_STATUS_START,
        payload,
    }),
    toggleStatusRecipientsToGroupSuccess: () => ({
        type: recipientsActions.TOGGLE_RECIPIENTS_STATUS_SUCCESS,
    }),
    toggleStatusRecipientsToGroupFailure: (error) => ({
        type: recipientsActions.TOGGLE_RECIPIENTS_STATUS_FAILURE,
        payload: error,
    }),

    DELETE_INACTIVE_RECIPIENTS_START: 'DELETE_INACTIVE_RECIPIENTS_START',
    DELETE_INACTIVE_RECIPIENTS_SUCCESS: 'DELETE_INACTIVE_RECIPIENTS_SUCCESS',
    DELETE_INACTIVE_RECIPIENTS_FAILURE: 'DELETE_INACTIVE_RECIPIENTS_FAILURE',
    deleteInactiveRecipientsStart: (payload) => ({
        type: recipientsActions.DELETE_INACTIVE_RECIPIENTS_START,
        payload,
    }),
    deleteInactiveRecipientsSuccess: () => ({
        type: recipientsActions.DELETE_INACTIVE_RECIPIENTS_SUCCESS,
    }),
    deleteInactiveRecipientsFailure: (error) => ({
        type: recipientsActions.DELETE_INACTIVE_RECIPIENTS_FAILURE,
        payload: error,
    }),
};

export default recipientsActions;
