import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Typography} from 'components/UI';
import {lang} from 'config/lang';

const {Text} = Typography;

const Loading = () => {
    const LoadingText = ({text}) => {
        const [periodsCount, setPeriodsCount] = useState(0);
        const maxPeriods = 3;

        useEffect(() => {
            const interval = setInterval(() => {
                setPeriodsCount((prevCount) => (prevCount >= maxPeriods ? 0 : prevCount + 1));
            }, 400); // Adjust the interval time (in milliseconds) to control the speed of periods.

            return () => clearInterval(interval);
        }, [maxPeriods]);

        const getPeriods = () => {
            return text.endsWith('.') ? '' : '.'.repeat(periodsCount);
        };

        return <Text>{text + getPeriods()}</Text>;
    };

    return <LoadingText text={lang.loading} />;
};

Loading.propTypes = {
    text: PropTypes.string,
};

export default Loading;
