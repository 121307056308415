import {api} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';
import {all, put, takeEvery} from 'redux-saga/effects';
import fundingMethodActions from './actions';

function* fetchFundingMethods() {
    let fundingMethods = yield api.get(apiEndpoints.fundingMethods.get).then((res) => res.data);

    yield put(fundingMethodActions.fetchFundingMethodsSuccess(fundingMethods));
}

export default function* fundingMethodsSaga() {
    yield all([takeEvery(fundingMethodActions.FETCH_FUNDING_METHODS_START, fetchFundingMethods)]);
}
