import React from 'react';
import {lang} from 'config/lang';
import startCase from 'lodash/startCase';
import {Helmet} from "react-helmet";

export const useTitle = () => {
    const getPageTitle = (name, title) => {
        if (!title) {
            return lang['giftogram'];
        }

        if (typeof title === 'boolean') {
            return `${startCase(name)} - ${lang['giftogram']}`;
        }

        return `${title} - ${lang['giftogram']}`;
    };

    const SetPageTitle = (title, name) => {
        return (
            <Helmet>
                <title>
                    {getPageTitle(name, title)}
                </title>
            </Helmet>
        );
    };

    return {
        SetPageTitle,
    };
};
