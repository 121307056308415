import React from 'react';
import {Empty, Typography} from 'components/UI';
import {lang} from 'config/lang';

const {Text} = Typography;

class NoResults extends React.Component {
    render() {
        return (
            <Empty
                className="search-results"
                description={<Text style={{fontSize: '1rem'}}>{lang['no_results']}</Text>}
            />
        );
    }
}

export default NoResults;
