const ordersActions = {
    FETCH_ORDERS_DATA_START: 'FETCH_ORDERS_DATA_START',
    FETCH_ORDERS_DATA_SUCCESS: 'FETCH_ORDERS_DATA_SUCCESS',
    FETCH_ORDERS_DATA_FAILURE: 'FETCH_ORDERS_DATA_FAILURE',
    fetchOrdersDataStart: (payload = {page: 1}) => ({
        type: ordersActions.FETCH_ORDERS_DATA_START,
        payload,
    }),

    fetchOrdersDataSuccess: (payload) => ({
        type: ordersActions.FETCH_ORDERS_DATA_SUCCESS,
        payload,
    }),

    fetchOrdersDataFailure: (error) => ({
        type: ordersActions.FETCH_ORDERS_DATA_FAILURE,
        payload: error,
    }),

    CLEAR_ORDERS_DATA: 'CLEAR_ORDERS_DATA',
    clearOrdersData: () => ({
        type: ordersActions.CLEAR_ORDERS_DATA,
    }),
};

export default ordersActions;
