const businessHoursActions = {
    FETCH_BUSINESS_HOURS_START: 'FETCH_BUSINESS_HOURS_START',
    FETCH_BUSINESS_HOURS_SUCCESS: 'FETCH_BUSINESS_HOURS_SUCCESS',
    FETCH_BUSINESS_HOURS_ERROR: 'FETCH_BUSINESS_HOURS_ERROR',

    fetchBusinessHoursStart: () => ({
        type: businessHoursActions.FETCH_BUSINESS_HOURS_START,
    }),

    fetchBusinessHoursSuccess: (payload) => {
        return {
            type: businessHoursActions.FETCH_BUSINESS_HOURS_SUCCESS,
            payload,
        };
    },

    fetchBusinessHoursError: (error) => ({
        type: businessHoursActions.FETCH_BUSINESS_HOURS_ERROR,
        payload: error,
    }),
};

export default businessHoursActions;
