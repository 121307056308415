import React from 'react';
import {Divider as AntDivider} from 'antd';
import PropTypes from 'prop-types';

export const Divider = (props) => {
    return (
        <AntDivider
            className={!props.margin ? 'no-margin' : ''}
            dashed={props.dashed}
            orientation={props.orientation}
            orientationMargin={props.orientationMargin}
            plain={props.plain}
            style={props.style}
            type={props.type}
        >
            {props.children}
        </AntDivider>
    );
};

// TODO: Deprecated, move to default params
Divider.defaultProps = {
    margin: true,
};

Divider.propTypes = {
    children: PropTypes.node,
    dashed: PropTypes.bool,
    margin: PropTypes.bool,
    orientation: PropTypes.string,
    orientationMargin: PropTypes.string,
    plain: PropTypes.bool,
    style: PropTypes.object,
    type: PropTypes.string,
};

export default Divider;
