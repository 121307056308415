import {LOGOUT} from 'redux/auth/auth.types';
import pusherActions from 'redux/pusher/actions';

const initialState = {};

const pusherReducer = (state = initialState, action) => {
    switch (action.type) {
        case pusherActions.SET_CHANNELS:
            return {
                ...state,
                ...action.payload,
            };

        case pusherActions.RESET_CHANNELS:
            return {
                ...initialState,
            };

        case LOGOUT:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};

export default pusherReducer;
