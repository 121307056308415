import React from 'react';
import {Button, Form} from 'components/UI';
import {lang} from 'config/lang';
import PropTypes from 'prop-types';

const FormFooterButtons = ({
    submitDisabled = false,
    cancelButtonProps = {},
    onCancel,
    okButtonProps = {},
    okText = '',
    loading = false,
}) => {
    return (
        <Form.Item className="ant-modal-footer form-footer">
            <Button size="large" onClick={onCancel} type="default" {...cancelButtonProps}>
                {lang['go_back']}
            </Button>

            <Button
                loading={loading}
                size="large"
                htmlType="submit"
                type="primary"
                disabled={submitDisabled}
                {...okButtonProps}
            >
                {okText || lang.button.submit}
            </Button>
        </Form.Item>
    );
};
FormFooterButtons.propTypes = {
    cancelButtonProps: PropTypes.object,
    onCancel: PropTypes.func,
    okButtonProps: PropTypes.object,
    okText: PropTypes.string,
    loading: PropTypes.bool,
    submitDisabled: PropTypes.bool,
};

export default FormFooterButtons;
