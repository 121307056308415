const groupsActions = {
    LIST_GROUPS_START: 'LIST_GROUPS_START',
    LIST_GROUPS_SUCCESS: 'LIST_GROUPS_SUCCESS',
    LIST_GROUPS_FAILURE: 'LIST_GROUPS_FAILURE',
    listGroupsStart: () => {
        return {
            type: groupsActions.LIST_GROUPS_START,
        };
    },
    listGroupsSuccess: (payload) => ({
        type: groupsActions.LIST_GROUPS_SUCCESS,
        payload,
    }),
    listGroupsFailure: (error) => ({
        type: groupsActions.LIST_GROUPS_FAILURE,
        payload: error,
    }),

    FETCH_GROUPS_DATA_START: 'FETCH_GROUPS_DATA_START',
    FETCH_GROUPS_DATA_SUCCESS: 'FETCH_GROUPS_DATA_SUCCESS',
    FETCH_GROUPS_DATA_FAILURE: 'FETCH_GROUPS_DATA_FAILURE',
    fetchGroupsDataStart: (payload = {page: '1', per_page: '25'}) => {
        return {
            type: groupsActions.FETCH_GROUPS_DATA_START,
            payload,
        };
    },
    fetchGroupsDataSuccess: (payload) => ({
        type: groupsActions.FETCH_GROUPS_DATA_SUCCESS,
        payload,
    }),
    fetchGroupsDataFailure: (error) => ({
        type: groupsActions.FETCH_GROUPS_DATA_FAILURE,
        payload: error,
    }),

    SAVE_GROUPS_DATA_START: 'SAVE_GROUPS_DATA_START',
    SAVE_GROUPS_DATA_SUCCESS: 'SAVE_GROUPS_DATA_SUCCESS',
    SAVE_GROUPS_DATA_FAILURE: 'SAVE_GROUPS_DATA_FAILURE',
    saveGroupsDataStart: (payload) => ({
        type: groupsActions.SAVE_GROUPS_DATA_START,
        payload,
    }),
    saveGroupsDataSuccess: (payload) => ({
        type: groupsActions.SAVE_GROUPS_DATA_SUCCESS,
        payload,
    }),
    saveGroupsDataFailure: (error) => ({
        type: groupsActions.SAVE_GROUPS_DATA_FAILURE,
        payload: error,
    }),

    DELETE_GROUPS_START: 'DELETE_GROUPS_START',
    DELETE_GROUPS_SUCCESS: 'DELETE_GROUPS_SUCCESS',
    DELETE_GROUPS_FAILURE: 'DELETE_GROUPS_FAILURE',
    deleteGroupsStart: (payload) => ({
        type: groupsActions.DELETE_GROUPS_START,
        payload,
    }),
    deleteGroupsSuccess: () => ({
        type: groupsActions.DELETE_GROUPS_SUCCESS,
    }),
    deleteGroupsFailure: () => ({
        type: groupsActions.DELETE_GROUPS_FAILURE,
    }),

    ADD_RECIPIENTS_TO_GROUP_DATA_START: 'ADD_RECIPIENTS_TO_GROUP_DATA_START',
    ADD_RECIPIENTS_TO_GROUP_DATA_SUCCESS: 'ADD_RECIPIENTS_TO_GROUP_DATA_SUCCESS',
    ADD_RECIPIENTS_TO_GROUP_DATA_FAILURE: 'ADD_RECIPIENTS_TO_GROUP_DATA_FAILURE',
    addRecipientsToGroupDataStart: (payload) => ({
        type: groupsActions.ADD_RECIPIENTS_TO_GROUP_DATA_START,
        payload,
    }),
    addRecipientsToGroupDataSuccess: (payload) => ({
        type: groupsActions.ADD_RECIPIENTS_TO_GROUP_DATA_SUCCESS,
        payload,
    }),
    addRecipientsToGroupDataFailure: () => ({
        type: groupsActions.ADD_RECIPIENTS_TO_GROUP_DATA_FAILURE,
    }),

    REMOVE_RECIPIENTS_FROM_GROUP_DATA_START: 'REMOVE_RECIPIENTS_FROM_GROUP_DATA_START',
    REMOVE_RECIPIENTS_FROM_GROUP_DATA_SUCCESS: 'REMOVE_RECIPIENTS_FROM_GROUP_DATA_SUCCESS',
    REMOVE_RECIPIENTS_FROM_GROUP_DATA_FAILURE: 'REMOVE_RECIPIENTS_FROM_GROUP_DATA_FAILURE',
    removeRecipientsFromGroupDataStart: (payload) => ({
        type: groupsActions.REMOVE_RECIPIENTS_FROM_GROUP_DATA_START,
        payload,
    }),
    removeRecipientsFromGroupDataSuccess: (payload) => ({
        type: groupsActions.REMOVE_RECIPIENTS_FROM_GROUP_DATA_SUCCESS,
        payload,
    }),
    removeRecipientsFromGroupDataFailure: () => ({
        type: groupsActions.REMOVE_RECIPIENTS_FROM_GROUP_DATA_FAILURE,
    }),

    RESET_SAVED: 'RESET_SAVED',
    resetSaved: () => ({
        type: groupsActions.RESET_SAVED,
    }),

    VALIDATE_SMART_CAMPAIGN_GROUP_START: 'VALIDATE_SMART_GROUP_START',
    validateSmartCampaignGroupStart: (payload) => ({
        type: groupsActions.VALIDATE_SMART_CAMPAIGN_GROUP_START,
        payload,
    }),

    VALIDATE_SMART_CAMPAIGN_GROUP_SUCCESS: 'VALIDATE_SMART_GROUP_SUCCESS',
    validateSmartCampaignGroupSuccess: (payload) => ({
        type: groupsActions.VALIDATE_SMART_CAMPAIGN_GROUP_SUCCESS,
        payload,
    }),

    VALIDATE_SMART_CAMPAIGN_GROUP_FAILURE: 'VALIDATE_SMART_GROUP_FAILURE',
    validateSmartCampaignGroupFailure: (payload) => ({
        type: groupsActions.VALIDATE_SMART_CAMPAIGN_GROUP_FAILURE,
        payload,
    }),

    VALIDATE_SMART_CAMPAIGN_GROUP_RESET: 'VALIDATE_SMART_CAMPAIGN_GROUP_RESET',
    validateSmartCampaignGroupReset: () => ({
        type: groupsActions.VALIDATE_SMART_CAMPAIGN_GROUP_RESET,
    }),
};

export default groupsActions;
