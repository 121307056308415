import {all, takeEvery, put} from 'redux-saga/effects';
import recipientCampaignActions from 'redux/recipientCampaigns/actions';
import {api} from 'library/helpers/api';

function* updateRecipientCampaign({payload}) {
    const route = `/recipients/${payload.recipientId}/campaigns`;
    const data = {groups: payload.groups.map((group) => group.id)};
    const {mode} = payload;

    const method = mode === 'add' ? 'put' : mode === 'remove' ? 'delete' : false;

    try {
        yield api[method](route, data);

        yield put({
            type: recipientCampaignActions.UPDATE_RECIPIENT_CAMPAIGN_DATA_SUCCESS,
            payload,
        });
    } catch (error) {
        yield put(recipientCampaignActions.updateRecipientGroupFailure(error));
    }
}

export default function* recipientCampaignSaga() {
    yield all([takeEvery(recipientCampaignActions.UPDATE_RECIPIENT_CAMPAIGN_DATA_START, updateRecipientCampaign)]);
}
