const bulkOrderActions = {
    FETCH_BULK_ORDER_START: 'FETCH_BULK_ORDER_START',
    FETCH_BULK_ORDER_SUCCESS: 'FETCH_BULK_ORDER_SUCCESS',
    FETCH_BULK_ORDER_FAILURE: 'FETCH_BULK_ORDER_FAILURE',

    fetchBulkOrderStart: (payload) => ({
        type: bulkOrderActions.FETCH_BULK_ORDER_START,
        payload,
    }),

    fetchBulkOrderSuccess: (payload) => ({
        type: bulkOrderActions.FETCH_BULK_ORDER_SUCCESS,
        payload,
    }),

    fetchBulkOrderFailure: (error) => ({
        type: bulkOrderActions.FETCH_BULK_ORDER_FAILURE,
        payload: error,
    }),

    CLEAR_BULK_ORDER_DATA: 'CLEAR_BULK_ORDER_DATA',
    clearBulkOrderData: () => ({
        type: bulkOrderActions.CLEAR_BULK_ORDER_DATA,
    }),

    FETCH_BULK_ORDER_RECIPIENTS_START: 'FETCH_BULK_ORDER_RECIPIENTS_START',
    FETCH_BULK_ORDER_RECIPIENTS_SUCCESS: 'FETCH_BULK_ORDER_RECIPIENTS_SUCCESS',
    FETCH_BULK_ORDER_RECIPIENTS_FAILURE: 'FETCH_BULK_ORDER_RECIPIENTS_FAILURE',

    fetchBulkOrderRecipientsStart: (payload) => ({
        type: bulkOrderActions.FETCH_BULK_ORDER_RECIPIENTS_START,
        payload,
    }),

    fetchBulkOrderRecipientsSuccess: (payload) => ({
        type: bulkOrderActions.FETCH_BULK_ORDER_RECIPIENTS_SUCCESS,
        payload,
    }),

    fetchBulkOrderRecipientsFailure: (error) => ({
        type: bulkOrderActions.FETCH_BULK_ORDER_RECIPIENTS_FAILURE,
        payload: error,
    }),

    CANCEL_BULK_ORDER_START: 'CANCEL_BULK_ORDER_START',
    CANCEL_BULK_ORDER_SUCCESS: 'CANCEL_BULK_ORDER_SUCCESS',
    CANCEL_BULK_ORDER_FAILURE: 'CANCEL_BULK_ORDER_FAILURE',

    cancelBulkOrderStart: (payload) => ({
        type: bulkOrderActions.CANCEL_BULK_ORDER_START,
        payload,
    }),

    cancelBulkOrderSuccess: (success) => ({
        type: bulkOrderActions.CANCEL_BULK_ORDER_SUCCESS,
        payload: success,
    }),

    cancelBulkOrderFailure: (error) => ({
        type: bulkOrderActions.CANCEL_BULK_ORDER_FAILURE,
        payload: error,
    }),

    CANCEL_BULK_ORDER_INDIVIDUAL_START: 'CANCEL_BULK_ORDER_INDIVIDUAL_START',
    CANCEL_BULK_ORDER_INDIVIDUAL_SUCCESS: 'CANCEL_BULK_ORDER_INDIVIDUAL_SUCCESS',
    CANCEL_BULK_ORDER_INDIVIDUAL_FAILURE: 'CANCEL_BULK_ORDER_INDIVIDUAL_FAILURE',

    cancelBulkOrderIndividualStart: (payload) => ({
        type: bulkOrderActions.CANCEL_BULK_ORDER_INDIVIDUAL_START,
        payload,
    }),

    cancelBulkOrderIndividualSuccess: (success) => ({
        type: bulkOrderActions.CANCEL_BULK_ORDER_INDIVIDUAL_SUCCESS,
        payload: success,
    }),

    cancelBulkOrderIndividualFailure: (error) => ({
        type: bulkOrderActions.CANCEL_BULK_ORDER_INDIVIDUAL_FAILURE,
        payload: error,
    }),

    REORDER_BULK_ORDER_INDIVIDUAL_START: 'REORDER_BULK_ORDER_INDIVIDUAL_START',
    REORDER_BULK_ORDER_INDIVIDUAL_SUCCESS: 'REORDER_BULK_ORDER_INDIVIDUAL_SUCCESS',
    REORDER_BULK_ORDER_INDIVIDUAL_FAILURE: 'REORDER_BULK_ORDER_INDIVIDUAL_FAILURE',

    reorderBulkOrderIndividualStart: (payload) => ({
        type: bulkOrderActions.REORDER_BULK_ORDER_INDIVIDUAL_START,
        payload,
    }),

    reorderBulkOrderIndividualSuccess: (payload) => ({
        type: bulkOrderActions.REORDER_BULK_ORDER_INDIVIDUAL_SUCCESS,
        payload,
    }),

    reorderBulkOrderIndividualFailure: (error) => ({
        type: bulkOrderActions.REORDER_BULK_ORDER_INDIVIDUAL_FAILURE,
        payload: error,
    }),
};

export default bulkOrderActions;
