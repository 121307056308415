import {apiEndpoints} from 'config/api';
import {call} from '../helpers';
import {buildQuery, getRoute} from 'library/helpers/routing';

export const fundingApiService = {
    processUpload: async (payload) => await call('post', apiEndpoints.funding.processUploadTransfer, payload),
    deleteFile: async (payload) => await call('delete', apiEndpoints.funding.deleteFile + '?filepath=' + payload),
    getRoundUpTransferAmount: async ({accountIds, amount}) =>
        await call('get', getRoute(apiEndpoints.funding.getRoundUpTransferAmount + '?r=' + accountIds, {amount})),
    getFundingAmount: async (payload) => await call('get', `${apiEndpoints.funding.getFundingAmount}?${buildQuery(payload)}`),
};
