const pusherActions = {
    SET_CHANNELS: 'SET_CHANNELS`',
    RESET_CHANNELS: 'RESET_CHANNELS',

    setChannels: (payload) => ({
        type: pusherActions.SET_CHANNELS,
        payload,
    }),
    resetChannels: () => ({
        type: pusherActions.RESET_CHANNELS,
    }),
};

export default pusherActions;
