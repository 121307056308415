import sendActions from 'redux/send/actions';
import uploadActions from 'redux/upload/actions';

const sendReducer = (state, action) => {
    switch (action.type) {
        case sendActions.SEND_SUBMIT:
            return {
                ...state,
                loaded: true,
                error: null,
                submitted: false,
            };

        case sendActions.SEND_SUBMIT_SUCCESS:
            return {
                ...state,
                loaded: true,
                submitted: true,
            };

        case sendActions.SEND_SUBMIT_ERROR:
            return {
                ...state,
                loaded: true,
                submitted: false,
                error: action.payload,
            };

        case sendActions.SEND_SUBMIT_RESET:
            return {
                ...state,
                loaded: true,
                submitted: false,
                error: null,
            };

        case uploadActions.UPLOAD_SUCCESS:
            return {
                ...state,
                loaded: true,
            };

        default:
            return state;
    }
};

export default sendReducer;
