import {getProcessEnvValue} from 'library/helpers/process';

export const annualBudgetOptions = [
    {
        id: 'under_1k',
        value: 'under_1k',
        label: 'Less than $1,000',
    },
    {
        id: '1k_to_10K',
        value: '1k_to_10K',
        label: '$1,000 to $10,000',
    },
    {
        id: '10k_to_50k',
        value: '10k_to_50k',
        label: '$10,000 to $50,000',
    },
    {
        id: '50k_to_100K',
        value: '50k_to_100K',
        label: '$50,000 to $100,000',
    },
    {
        id: '100k+',
        value: '100k+',
        label: 'More than $100,000',
    },
];

export const goalOptions = [
    {
        id: 'trying_out',
        value: 'trying_out',
        label: 'Evaluating or researching',
    },
    {
        id: 'getting_started',
        value: 'getting_started',
        label: 'Ready to purchase',
    },
];

export const personaOptions = [
    {
        id: 'employee_rewards',
        value: 'Employee Rewards',
        label: 'Employee Rewards',
    },
    {
        id: 'customer_loyalty',
        value: 'Customer Loyalty',
        label: 'Customer Loyalty',
    },
    {
        id: 'market_research',
        value: 'Market Research',
        label: 'Market Research',
    },
    {
        id: 'payouts',
        value: 'Payouts',
        label: 'Payouts',
    },
];

export const authConfig = {
    configured: false,
    client: {
        client_id: getProcessEnvValue('REACT_APP_COGNITO_POOL_WEB_CLIENT_ID'),
        company_name: 'Giftogram',
        is_default: true,
        providers: [
            {
                data: {
                    button_text: '',
                    icon: '',
                },
                provider: 'COGNITO',
            },
            {
                data: {
                    icon: 'google',
                    button_text: 'Google',
                    colors: {
                        backgroundColor: '#0C7BE2',
                        color: 'white',
                    },
                },
                provider: 'Google',
            },
        ],
    },
    config: {
        region: getProcessEnvValue('REACT_APP_COGNITO_REGION'),
        userPoolId: getProcessEnvValue('REACT_APP_COGNITO_POOL_ID'),
        userPoolWebClientId: getProcessEnvValue('REACT_APP_COGNITO_POOL_WEB_CLIENT_ID'),
        oauth: {
            scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
            domain: getProcessEnvValue('REACT_APP_COGNITO_DOMAIN'),
            redirectSignIn: getProcessEnvValue('REACT_APP_COGNITO_REDIRECT_SIGN_IN'),
            redirectSignOut: getProcessEnvValue('REACT_APP_COGNITO_REDIRECT_SIGN_OUT'),
            responseType: 'code',
        },
    },
    federationTarget: 'COGNITO_USER_POOLS',
};

export const heroImages = [
    {
        image: '../images/login/giftogram-login-1.jpg',
        logo: '../images/login/logos/target-logo.svg',
        name: 'Samantha, 23',
        pronoun: 'her',
        denom: '$15',
        brand: 'Target',
    },
    {
        image: '../images/login/giftogram-login-2.jpg',
        logo: '../images/login/logos/hulu-logo.svg',
        name: 'Chase, 30',
        pronoun: 'his',
        denom: '$35',
        brand: 'Hulu',
    },
    {
        image: '../images/login/giftogram-login-3.jpg',
        logo: '../images/login/logos/fandango-logo.svg',
        name: 'Allison, 25',
        pronoun: 'her',
        denom: '$15',
        brand: 'Fandango',
    },
    {
        image: '../images/login/giftogram-login-4.jpg',
        logo: '../images/login/logos/amazon-logo.svg',
        name: 'Fisher, 27',
        pronoun: 'his',
        denom: '$15',
        brand: 'Amazon',
    },
    {
        image: '../images/login/giftogram-login-5.jpg',
        logo: '../images/login/logos/home-depot-logo.svg',
        name: 'Derek, 29',
        pronoun: 'his',
        denom: '$40',
        brand: 'Home Depot',
    },
    {
        image: '../images/login/giftogram-login-6.jpg',
        logo: '../images/login/logos/gap-logo.svg',
        name: 'Vanessa, 31',
        pronoun: 'her',
        denom: '$40',
        brand: 'Gap',
    },
    {
        image: '../images/login/giftogram-login-7.jpg',
        logo: '../images/login/logos/nike-logo.svg',
        name: 'James, 37',
        pronoun: 'his',
        denom: '$50',
        brand: 'Nike',
    },
];

export const SIGNUP_EMAIL_EXCEPTIONS = ['InvalidEmailAddressException', 'UsernameExistsException'];
