import React, {useCallback} from 'react';
import {PageHeader as AntPageHeader} from 'antd';
import {FontAwesome, Dropdown, Breakpoint, Button, Space} from 'components/UI';
import {lang} from 'config/lang';
import {buttonIconStyle} from 'assets/styles/common';
import PropTypes from 'prop-types';
import {goToPreviousRoute} from 'library/helpers/routing';

export const PageHeader = (props) => {
    const handleClick = useCallback((e, callback) => {
        if (typeof callback === 'function') return callback(e);
    }, []);

    const handleBack = useCallback(() => {
        props.onBack ? props.onBack() : goToPreviousRoute();
    }, [props]);

    const getButtonsAsMenu = useCallback(() => {
        if (!props.buttons || props.buttons.length === 0) return [];

        return props.buttons.map((item) => {
            return {
                key: `second-nav-menu-${item.id}`,
                eventKey: `second-nav-menu-${item.id}`,
                disabled: item.disabled,
                onClick: (e) => handleClick(e, item.onClick),
                label: item.label,
            };
        });
    }, [props.buttons, handleClick]);

    const renderButtons = useCallback(() => {
        return [
            <Space size={32} key={'page-header-buttons'}>
                {props.buttons.map((item) => {
                    return (
                        <Button
                            key={`button-${item.id ?? item.key}`}
                            type={item.type}
                            shape={item.shape}
                            size={props.size}
                            htmlType={item.htmlType || 'button'}
                            disabled={!!(props.loading || item.disabled || props.disabled)}
                            loading={item.loading}
                            onClick={(e) => handleClick(e, item.onClick)}
                            className={item?.className}
                            hidden={item.hidden}
                            icon={item.icon && <FontAwesome icon={item.icon} type={buttonIconStyle} />}
                            ghost={item.ghost}
                        >
                            {item.label}
                        </Button>
                    );
                })}
            </Space>,
        ];
    }, [props.buttons, props.size, props.loading, props.disabled, handleClick]);

    const renderExtra = useCallback(() => {
        return (
            <Breakpoint
                xs={false}
                sm={false}
                md={false}
                inverse={
                    <Dropdown
                        menu={{items: getButtonsAsMenu()}}
                        trigger={['click']}
                        placement="bottomRight"
                        type="text"
                        label={<FontAwesome size="xl" type="solid" icon="ellipsis" />}
                        arrow={false}
                        suffixIcon={false}
                    />
                }
            >
                {renderButtons()}
            </Breakpoint>
        );
    }, [getButtonsAsMenu, renderButtons]);

    const renderBackButton = useCallback(() => {
        return (
            <Button size={props.size} type="text">
                <FontAwesome icon="arrow-left" type={buttonIconStyle} />
                {props.backLabel}
            </Button>
        );
    }, [props.backLabel, props.size]);

    return (
        <>
            <AntPageHeader
                {...props}
                onBack={handleBack}
                backIcon={props.back && renderBackButton()}
                extra={props.buttons ? renderExtra() : props.extra && props.extra}
                ghost={props.ghost}
                title={props.title}
                footer={props.footer}
            >
                {props.content && props.content}
            </AntPageHeader>
        </>
    );
};

// TODO: Deprecated, move to default params
PageHeader.defaultProps = {
    back: false,
    backLabel: lang.button.back,
    buttons: false,
    container: 'fluid',
    disabled: false,
    ghost: false,
    loading: false,
    size: 'large',
    title: ' ',
};

PageHeader.propTypes = {
    back: PropTypes.bool,
    backLabel: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    buttons: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    container: PropTypes.string,
    content: PropTypes.any,
    extra: PropTypes.any,
    ghost: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    mode: PropTypes.string,
    onBack: PropTypes.func,
    style: PropTypes.object,
    size: PropTypes.any,
    title: PropTypes.any,
    footer: PropTypes.any,
};

export default PageHeader;
