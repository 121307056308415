export const lang = {
    error_404: {
        back_button: 'Go to Dashboard',
        message1: "Whatever you were looking for doesn't exist at this address.",
        message2: 'Unless you were looking for an error page, in which case, you found it!',
        title: "There's nothing to see here",
    },
    activate: 'Activate',
    activate_all: 'Activate All',
    activate_false: 'Do Not Activate',
    activate_user: 'Activate User',
    active: 'Active',
    add: 'Add',
    anyway: 'Anyway',
    add_recipient: 'Add Recipient',
    add_another_recipient: 'Add Another Recipient',
    add_recipients: 'Add Recipients',
    add_more_recipients: 'Add More Recipients',
    add_sender_name: 'Create Sender Name',
    add_to_cart: 'Add to Cart',
    add_to_group: 'Add to Group',
    remove_from_group: 'Remove from Group',
    address: 'Shipping Address',
    address_1: 'Address',
    address_2: 'Apt, Suite, Unit',
    address_owner_warning: 'Must be address owner',
    address_street: 'Street Address',
    adjustments: 'Adjustments',
    adminSettings: {
        fieldset: {
            billing: 'Billing Settings',
            billing_label: {
                checkout: 'Checkout',
            },
            currency: 'Currency Settings',
            currency_default_currency: {
                error: 'Please select a default currency',
                placeholder: 'Select Region',
            },
            currency_region_ids: {
                error: 'Please select at least one currency',
            },
            available_currency_labels: 'Available Currencies',
            gift_card_catalog_label: 'Gift Card Catalogs',
            users: 'User Settings',
            recipients: 'Recipient Settings',
            sender: {
                section_label: 'Sender Settings',
                action: 'Allow users to enter custom sender names',
                helper: 'Custom sender names are user specific and used at checkout',
                label: 'Sender Names',
            },
            security: {
                label: {
                    default: 'Security Settings',
                    checkbox_label: 'Mask Email Addresses',
                    empty_whitelist: {
                        explainer:
                            'Restrict recipients to email addresses matching these domains (leave blank for no restriction)',
                        message: 'Begin typing to add a domain...',
                        placeholder: 'Add an email domain',
                    },
                    allowed_invite_domains: {
                        label: 'User Domain Whitelist',
                        extra: 'Restrict team members to users with email addresses matching these domains (leave blank for no restriction)',
                    },
                    allowed_recipient_domains: 'Recipient Domain Whitelist',
                    mask_data: {
                        label: 'Display Email Address',
                        extra: 'If enabled, recipient email addresses will be masked (i.e. j******h@domain.com)',
                    },
                    sso: {
                        label: 'Single Sign-On',
                        message:
                            'Allow users to sign in with company credentials. Contact your account manager to change this setting.',
                    },
                },
            },
        },
        storefront_config: {
            all_storefronts: 'All available gift card catalogs',
            extra: 'Limit the gift card catalogs users can select for a campaign',
            specific_storefronts: 'Only specific gift card catalogs',
            specific_storefronts_placeholder: 'Select a gift card catalog',
        },
        update_success: 'Admin settings successfully updated',
    },
    all: 'All',
    all_teams: 'All Teams',
    allow_stock_designs: "Allow Giftogram's stock gift card designs",
    amount: 'Amount',
    any: 'Any',
    apiSettings: {
        description: 'Develop custom Giftogram integrations',
        reference: 'API Reference',
        create_key: 'Create Key',
        create_subscription: 'Create Subscription',
    },
    apply: 'Apply',
    approvals: {
        checkout_warning: 'After checkout, we will send this order to your team admin for approval.',
    },
    assigned: 'Assigned',
    authenticate: 'Authenticate',
    authenticate_token: 'Add Token',
    authenticate_edit_token: 'Edit Token',
    authenticate_refresh_token: 'Refresh Token',
    procurement: {
        title: 'Procurement',
        logging_in: 'Sit tight, logging you in',
        warning_one: 'Close this window and try again',
        help: 'Need help? Reach out to ',
        redirect: 'Please wait while you are being redirected',
        expired_link: 'Your authentication link has expired',
        error_occurred: 'There was an issue logging you in',
        support: 'Giftograms support team',
        modal: {
            continue: 'Go to Procurement System',
            deposit_title: 'Processing Your Deposit...',
            order_title: 'Processing Your Order...',
            deposit_message:
                "Do not close this page. We're redirecting you to your procurement system so that your deposit can be finalized.",
            order_message:
                "Do not close this page. We're redirecting you to your procurement system so that your order can be finalized.",
            deposit_message_2:
                "If you aren't automatically redirected in 30 seconds, click the manual redirect link below.",
            order_massage: 'Your order will be on hold until approved in your internal procurement system.',
        },
    },
    available_balance: 'Available Balance',
    avatar: 'Avatar',
    back: 'Back',
    back_to_designs: 'Back to Designs',
    balance: 'Balance',
    balance_history_empty_table_description:
        'Keep track of all deposits, purchases, and transfers within your account.',
    balance_history_empty_table_title: 'You have no balance history',
    beta: 'Beta',
    billing: 'Billing',
    blacklisted: 'Restricted Domain',
    brand_name: 'Giftogram',
    branding: 'Company Branding',
    brand_color: 'Brand Color',
    brand_settings: 'Brand Settings',
    brand_kit: {
        delete: 'Delete',
        clone: 'Duplicate',
        edit: 'Edit',
        make_default: 'Make Default',
        preview: 'Preview',
        title: 'Brand Kits',
        title_singular: 'Brand Kit',
        label: 'Brand Kit Name',
        create_button: 'Create a Brand Kit',
        create_brand_kit_name: 'Create a Brand Kit',
        create_brand_kit_description: 'Give your brand kit a descriptive name to help identify it in campaigns.',
        create_success: 'Brand kit successfully created',
        update_brand_kit_name: 'Edit Brand Kit Name',
        explainer: 'Brand kits let you configure custom logos, colors, senders, and messaging for your campaigns.',
        confirm_delete: 'Are you sure you want to delete {brand} kit?',
        confirm_delete_explainer:
            'This will delete the brand kit and custom logos. colors, senders, and messages associated with this kit. They will no longer be available.',
        confirm_delete_message: 'Are you sure you want to delete message {title}?',
        confirm_delete_message_explainer: 'This message will no longer be available',
        confirm_delete_sender: 'Are you sure you want to delete sender {name}?',
        confirm_delete_sender_explainer: 'This sender name will no longer be available',
        email_background: 'Email background color',
        email_button: 'Email button color',
        success_result: 'Brand kit successfully {action}',
        update_colors: 'Brand kit updated',
        delete_disabled_explainer:
            'This brand kit is currently in use. First remove any associated campaigns before deleting.',
        delete_default_explainer: 'This is the default brand kit and cannot be deleted.',
        empty_table_title: "You haven't created any {item} (yet)",
        empty_table_description: 'Custom reusable {item} that you create will appear here.',
        empty_table: "You haven't created any brand kits yet",
        messages: 'messages',
        message_edit_title: 'Edit Message Template',
        message_create_title: 'Create Message Template',
        senderNames: 'sender names',
        senders_profile_name: "Sender's Profile Name",
        logo_upload_title: 'Brand Logo',
        unsaved_changes: 'You have unsaved changes. Are you sure you want to leave?',
    },
    brand_color_personalize_message: 'Personalize Giftograms sent via email with your brand color',
    button: {
        add_credit_card: 'Add Payment Card',
        back: 'Back to Previous',
        continue: 'Continue',
        cancel: 'Cancel',
        continue_to_recipients: 'Continue to Recipients',
        checkout: 'Checkout',
        create_and_send: 'Create & Send',
        create_standard_campaign: 'Create Standard Campaign',
        edit_group: 'Edit Group',
        groups: 'Groups',
        import: 'Import',
        keep_shopping: 'Back to Giftogram',
        return_to_checkout: 'Back to Checkout',
        recipients: 'Recipients',
        report_download: 'Exports',
        reset: 'Reset',
        reset_password: 'Reset Password',
        send_giftogram: 'Send a Giftogram',
        send_another_giftogram: 'Send Another Giftogram',
        create_giftogram: 'Create Giftogram',
        set: 'Set',
        submit: 'Submit',
        close_window: 'Close Window',
    },
    campaign_manage: 'Manage Campaigns',
    campaign_title: 'Campaign',
    campaign: {
        title: {
            create_campaign: 'Create a Campaign',
        },
        button: {
            create_campaign: 'Create Campaign',
            update_campaign: 'Update Campaign',
        },
        confirm: {
            text: '',
            title: 'Are you sure you want to delete this campaign?',
        },
        create_success: 'Campaign successfully created',
        created: 'Owned By',
        details: 'Details',
        details_gifts: {
            title: 'Digital Gift History',
        },
        total_pending: 'Total Pending',
        total_sent: 'Total Sent',
        update_success: 'Campaign successfully updated',
        form: {
            api: {
                uuid: 'UUID',
            },
            billing: {
                label: 'Billing',
                notice: ':CAMPAIGN_TYPE campaigns are funded from your account balance',
            },
            billing_shipping: 'Billing & Shipping',
            campaign_name: {
                name: 'Name',
                extra: 'Give this campaign a descriptive name',
            },
            catalog: {
                extra: 'This is the gift card catalog the recipient will choose from',
            },
            date: {
                key_date: 'Key Date',
                extra: 'This is the date associated with your recipient we will use to send your Giftogram',
                placeholder: 'Select key date',
            },
            smart_trigger: 'Automation Details',
            enrollment: 'Enrollment',
            frequency: {
                label: 'Frequency',
                extra: 'This is how often you would like to send a Giftogram to your recipients',
            },
            milestone: {
                label: 'Milestone',
                extra: 'Number of years from the key date to run',
            },
            start_date: {
                label: 'Start Date',
                extra: 'This is when your campaign will start',
            },
            end_date: {
                label: 'End Date',
                extra: 'Leave blank to run indefinitely',
                placeholder: 'End campaign manually',
            },
            denom: {
                any: 'Any denomination',
                extra: {
                    smart: 'This is the value of the gift all recipients will receive',
                    standard: 'Leave blank to allow any denomination',
                },
                select: 'Select a denomination',
            },
            design_library: {
                mark: "Campaigns allow a user to choose any design in their team's existing design library unless you assign specific designs.",
            },
            designs: {
                label: 'Giftogram Designs',
                all: "Use team's existing design library",
                specific: 'Select specific designs',
            },
            details: 'Giftogram',
            from_name: {
                label: 'Make this the from name of the Giftogram email',
            },
            general: 'General',
            gift_card_catalog: 'Catalog',
            gift_card_currency: 'Currency',
            gift_card_settings: 'Gift Card Settings',
            gift_message: 'Giftogram Message',
            gift_value: 'Gift Card Value',
            giftogram_design: 'Giftogram Design',
            groups: {
                label: 'Groups',
                all: 'All Recipients',
                extra: {
                    allow: 'Leave blank to allow all recipients',
                    enroll: 'Leave blank to enroll all recipients',
                },
                select_group: 'Select a group',
            },
            message: {
                message: 'Message',
                checkbox: 'Allow users to enter a message of their own',
            },
            one_time: {
                label: 'One-Time Gifting',
                checkbox: 'Allow recipients to receive a gift more than once',
                extra: 'If unchecked, users may only send a gift to a recipient once',
            },
            payment: {
                label: 'Payment Method',
                extra: 'Leave blank to allow users to use any saved card',
                placeholder: 'Any credit / debit card',
            },
            permissions: 'Permissions',
            physical: {
                alert: 'Physical only campaigns do not have recipient settings',
            },
            recipients: {
                label: 'Recipients',
                checkbox: 'Allow users to enter a recipient at checkout',
                extra: 'If unchecked, users will only be able to send gifts to saved recipients',
                notice: 'API campaigns recipients are set via API request',
            },
            sender: 'Sender',
            sender_name: {
                label: 'Sender Name',
                checkbox: 'Allow users to change the sender name on their own',
                extra: 'This name will be displayed in the subject and body of your digital Giftogram email.',
            },
            shipping: {
                label: 'Shipping Addresses',
                extra: 'Leave blank to allow users to ship to any saved address',
                placeholder: 'Any address',
            },
            smart: {
                billing: 'Smart campaigns are funded from your account balance.',
                date_settings: 'Scheduling',
            },
            type: {
                label: 'Giftogram Type',
                digital: 'Digital Only',
                physical: 'Physical Only',
                physical_digital: 'Physical & Digital',
            },
        },
        campaign_type: {
            desc: {
                annual: 'Gifts will automatically send every year on the specified date.',
                enrollment: 'Gifts will send automatically when a recipient is added to the group :GROUP.',
                milestone: 'Gifts will send automatically :YEARS year(s) from the specified date.',
            },
        },
        save: {
            create: 'Create Campaign',
            update: 'Edit Campaign',
        },
        smart: 'Smart Campaign Details',
        standard: 'Campaign Details',
        terms_legend: 'Terms of Service',
        terms_country:
            'I understand the items in this order are redeemable for :CURRENCY gift cards and prepaid cards and will only work in ',
        terms_country_disclaimer:
            'This Giftogram will be redeemable for gift cards or prepaid cards that will only work in ',
    },
    campaigns: {
        pending_orders: {
            title: 'Change pending order(s)?',
            explainer: 'Changes made to this campaign will be applied to all pending order(s).',
        },
        label: 'Campaigns',
        activate: 'Activate',
        button: {
            back: 'Back to Campaigns',
            save: 'Yes, save changes',
            discard: 'No, discard changes',
        },
        change_owner: {
            button: 'Change Owner',
            info: 'Changing the owner of this smart campaign will change all currently pending gifts and send all future gifts from this person.',
            label: 'Owner',
            title: 'Change Owner',
        },
        create_transition: {
            tip: 'Getting campaign builder',
        },
        deactivate: 'Deactivate',
        delete: {
            content: 'This will cancel any pending gifts in your queue that are associated with this campaign.',
            title: 'Delete Campaign',
        },
        empty_table: {
            description: "Campaigns are like settings to help organize and automate your organization's gift programs.",
            title: "You don't have any campaigns",
        },
        load_error: 'Error loading campaigns',
        loading: 'Loading your campaigns',
        no_setup: {
            button: 'Do Not Setup Campaign',
        },
        select_title: 'Select a Campaign Type',

        setup: {
            button: 'Setup Campaign',
        },
        smart: {
            label: 'Smart Campaign',
            description: 'Automate your gifting program to send gifts on key dates like birthdays and anniversaries.',
            explainer_text:
                'Smart Campaigns auto-magically send gifts to your recipients on key dates such as birthdays or anniversaries',
            explainer_title: 'Automate Your Gifting',
            title: 'Smart Campaign Details',
        },
        standard: {
            label: 'Standard Campaign',
            description: 'Manage, organize, and simplify your gifting program with settings that you use often.',
            explainer_text:
                'Campaigns allow you to organize your gifting programs and define key attributes for consistency throughout your organization',
            explainer_title: 'Organize Your Gifting',
            null_toggles: {
                error: 'Both Product and Saved Designs can not be disabled',
            },
            title: 'Campaign Details',
        },
        title: 'Campaigns',
        toggle: {
            content_activate: 'This will activate the campaign and process any previously pending gifts.',
            content_deactivate: 'This will deactivate the campaign and pause processing on any pending gifts.',
            success: 'Campaign successfully updated',
            title_activate: 'Activate Campaign',
            title_deactivate: 'Deactivate Campaign',
        },
        transition: {
            tip: 'Getting your campaigns',
        },
    },
    cancel: 'Cancel',
    cancel_import: 'Cancel Import',
    card_charged: {
        alert: {
            title: 'Your card has already been charged',
            message: 'The cancelled amount will be refunded to your Giftogram account balance.',
        },
    },
    card_owner_warning: 'Must be card owner',
    card_picker: {
        add_category: 'Select Category',
        extra: {
            leave_blank: 'If left blank, no designs will be shared',
            select_one: 'You must select at least one design',
            smart: 'This is the design that will accompany your gift',
        },
        placeholder: 'All designs',
        saved_designs: 'Custom Designs',
        select_design: 'Select a design',
        select_designs: 'Select designs',
        shared_designs: 'Shared Designs',
        title: 'Add Category',
    },
    card_sleeves: 'Card Sleeves',
    cardholder_name: 'Cardholder Name',
    cart: 'Cart',
    cart_view: 'View Cart',
    catalog_currency: 'Catalog Currency',
    categories: 'Categories',
    change_avatar: 'Change Avatar',
    change_campaign: 'Change Campaign',
    change_campaign_confirm: {
        content:
            'You have items in your shopping cart for this this campaign. They will be lost if you change campaigns.',
        button: 'Change Campaign',
        title: 'Change Campaign',
    },
    change_email: 'Change Email',
    checkout: {
        checkout: 'Checkout',
        checkout_disclamer: 'Production of your order will begin once payment has been successfully processed.',
        button: {
            complete: 'Complete Checkout',
        },
        cancellation:
            'Giftograms scheduled via email are refundable only if cancelled before they are sent, minus processing fees. Sent Giftograms are non-refundable.',
        returns:
            'If you are unhappy with any aspect of your order, contact us within 30 days and we will correct any error or issue a full refund for all unused product, minus the cost of shipping, and processing fees.',
        shipping_method: 'Delivery Method',
        success: {
            text: 'Our customer success team will quickly review and process your order.',
            title: 'Your Giftogram order has been placed!',
        },
        terms_and_conditions:
            'Et dapibus hendrerit penatibus id montes nam dis parturient duis, mollis diam lacinia sodales ullamcorper libero lorem laoreet, justo tortor potenti arcu eu sapien auctor sed.',
        title: 'Checkout',
        us_company: 'Giftogram is a US based company.',
        turnaround: 'Delivery Time',
        physical_turnaround:
            'Please allow 2 business days + delivery transit time for your order to arrive. Tracking information will be sent to you via email when your order ships.',
    },
    choose_group: 'Choose a group',
    choose_region: 'Choose a Region',
    choose_timezone: 'Choose a Timezone',
    city: 'City',
    clear_all_recipients: 'Clear All Recipients',
    clone: 'Duplicate',
    cloned: 'duplicated',
    clone_team: 'Duplicate Team',
    close: 'Close',
    close_window: 'Close Window',
    company: 'Company',
    companySettings: {
        label: {
            default_currency: {
                label: 'Default Currency',
                extra: 'This is the currency we will default to when creating campaigns',
            },
        },
        title: 'Company Settings',
    },
    company_logo: 'Company Logo',
    company_logo_upload: 'Brand Logo',
    click_or_drag: 'Click or drag to upload',
    company_logo_extra: 'Your logo will be displayed in Giftograms sent via email',
    company_setup: {
        title: "Let's Set Up Your Account!",
        sub_title: 'This information helps us tailor Giftogram just for you.',
        label: {
            full_name: 'Your Full Name',
            company_name: 'Company Name',
            budget: 'How much do you plan to spend on rewards and payouts annually?',
            goal: 'What is your main goal today?',
            persona: 'How are you using Giftogram?',
            region: 'What currencies would you like to send gift cards in?',
            default_region: 'Which would you like to be your default currency?',
            submit_button: 'Complete Account Set-Up',
        },
        extra: {
            default_region: 'We will make this the region of your default campaign',
        },
        placeholder: {
            budget: 'Please select a budget...',
            goal: 'Please select a goal...',
            persona: 'Please select a usage type...',
        },
        label_submit_button: 'Complete Account Set-Up',
    },
    configure: 'Configure',
    copy_to_clipboard: 'Copy to Clipboard',
    country: 'Country',
    created: 'Created',
    create: {
        alert: {
            campaign:
                'This campaign does not allow the sending of digital gifts. Please select another campaign or reset your queue.',
            continue_send: {
                title: 'You have an unfinished order...',
                content: 'Would you like to pick up where you left off or start over with a new order?',
            },
            verify_company: 'Please submit your company information to place an order.',
            verify_email: 'Please verify your email address to place an order.',
        },
        assigned_next: 'Assigned in next step',
        button: {
            filter: 'Filter Categories',
            upload_design: 'Upload Design',
            add_design: 'Create a Design',
        },
        customized_tag: 'Custom',
        detail: {
            back_button: 'Back to Designs',
            digital: {
                terms_and_conditions:
                    'Codes expire 12 months from order date unless otherwise noted. For promotional and business use only.',
            },
            physical: {
                terms_and_conditions:
                    'Cards expire 18 months from order date unless otherwise noted. For promotional and business use only.',
            },
            terms_and_conditions:
                'Giftogram cards expire 18 months from order date unless otherwise noted. For promotional and business use only.',
        },
        digital: {
            terms_and_conditions:
                'Giftogram codes expire 12 months from order date unless otherwise noted. For promotional and business use only.',
        },
        menu: {
            all_designs: 'All Designs',
            custom_designs: 'Custom Designs',
        },
        messages: {
            switching_campaign: 'Switching campaign',
        },
        no_campaigns: {
            text: 'Your current team has no campaigns. If your permissions allow, you can create one to get started.',
            title: 'You have no campaigns',
        },
        no_giftograms: {
            text: 'Your current team or the current campaign does not have any designs assigned to it yet.',
            title: "You don't have any gift card designs",
        },
        recipients_in: 'recipients in',
        select_catalog: {
            modal_title: 'Choose a Gift Card Catalog',
            modal_paragraph: 'This is the selection of gift cards that the recipient will be able to choose from.',
            modal_ok: 'Change Catalog',
        },
        send_to: {
            extra: 'Send to one or more individuals or a saved group.',
            extra_plus_list: 'Send to one or more individuals, a saved group or upload a list.',
        },
        sending_to: 'Sending a Giftogram to',
        title: 'Create & Send',

        value: {
            list: {
                extra: 'This is your default denomination. You may also specify individual denominations in your uploaded list.',
            },
        },
    },
    create_design: 'Create Custom Design',
    create_property: 'Create Property',
    credit_card: 'Credit Card',
    credit_cards_disabled: 'Please contact us to activate credit card usage.',
    credit_debit_card: 'Credit/Debit Card',
    currency: 'Currency',
    current_balance: 'Current Balance',
    current_password: 'Current Password',
    current_team: 'Current Team',
    custom: 'Custom',
    custom_designs: 'Custom Designs',
    custom_designs_empty_table_description: 'Create a custom design to highlight your brand',
    custom_designs_empty_table_title: 'You have no custom designs',
    customer_support: 'Customer Support',
    customize: 'Customize',
    customize_giftogram: 'Customize your Giftogram',
    dashboard: {
        funds: {
            title: 'Your Funds',
        },
        manage_funds: {
            title: 'Manage Funds',
        },
        menu: {
            label: 'Dashboard',
        },
        order_history: {
            button: 'Go to Order History',
        },
        payments: {
            title: 'Saved Payment Methods',
        },
        team_funds: {
            title: 'Team Funds',
        },
        team_member_funds: {
            title: 'Team Members',
        },
    },
    date_field: 'Date Field',
    deactivate: 'Deactivate',
    deactivate_all: 'Deactivate All',
    deactivate_false: 'Do Not Deactivate',
    deactivate_user: 'Deactivate User',
    default: 'Default',
    delete: 'Delete',
    deleted: 'Deleted',
    delete_team: 'Delete Team',
    delete_team_text:
        'This will delete all recipients, campaigns, designs, and settings for this team. This cannot be undone.',
    delivery_method: 'Delivery Method',
    delivery_time: 'Delivery Time',
    delivery_times: 'Delivery Times',
    delivery_times_explain_1: 'Orders sent today typically send 1-2 hours after checkout.',
    delivery_times_explain_2: 'Orders scheduled for future dates will be released at their scheduled time.',
    delivery_times_explain_punchout: 'Orders will be sent after they have been approved in your procurement system',
    delivery_time_next: 'The next available send time is ',
    delivery_time_tomorrow: 'tomorrow @ ',
    denomination: 'Denomination',
    denominations: 'Denominations',
    department: 'Department',
    deposit_funds: {
        confirm: {
            content: 'Please confirm the details of your deposit.',
            title: 'Confirm Deposit',
        },
        fieldset: {
            title: {
                info: 'Deposit Details',
                optional_info: 'Optional Info.',
            },
        },
    },
    deposit_summary: 'Deposit Summary',
    description: 'Description',
    design_name: 'Design Name',
    design_owner_warning: 'Must be design owner',
    designs: 'Designs',
    details: 'Details',
    details_hide: 'Hide Details',
    details_show: 'Show Details',
    disabled: 'Disabled',
    disable: 'Disable',
    discard_changes: 'Discard Changes',
    discount: 'Discount',
    discount_explainer: 'This :CONTEXT is eligible for discounts as part of a special program.',
    display_name: 'Display Name',
    download: 'Download',
    download_template: 'Download Template',
    drag_csv: 'Drag & drop your list file here (.csv, .xls)',
    edit: 'Edit',
    edit_design: 'Edit Design',
    edit_subject_confirm_button: 'Edit Subject',
    edit_subject_confirm_text: 'Changing the subject line may affect the deliverability of your gift.',
    edit_team: 'Edit Team',
    email: 'Email',
    email_address: 'Email Address',
    email_warning: 'For your security, email address may not be changed.',
    empty_cart: 'Your cart is empty',
    enabled: 'Enabled',
    ending: 'ending',
    error: {
        invalid_password:
            'Password must be at least ten characters long and contain at least one (1) of the following: uppercase letter, lowercase letter, number, and special character.',
        mismatch_password: 'Passwords do not match',
    },
    expire_invite: 'Expire Invite',
    expire_link: 'Expire Link',
    expired: 'Expired',
    expires: 'Expires',
    expires_on: 'Expires On',
    export: 'Export',
    ext: 'Ext.',
    filter: 'Filter',
    filters: 'Filters',
    finish_order: 'Continue Order',
    first_name: 'First Name',
    forgot_password_description: 'Enter your new password below.',
    form: {
        prompt: {
            dirty: 'You have unsaved changes. Are you sure you want to leave?',
            dirty_tabs: 'You have unsaved changes. Are you sure?',
        },
    },
    from_name: 'From Name',
    funding: {
        button: {
            cancel: 'Cancel Deposit',
        },
        cancel: {
            digital: "You may cancel this deposit request before it's processed.",
            digital_funding: 'If you would like to cancel this deposit request, please contact customer support.',
            procurement: 'You may cancel this deposit request from your procurement system.',
            message: 'This cannot be undone.',
            title: 'Cancel Deposit',
        },
        credit_card: {
            cvv: 'CVV',
            default: 'Make this my default payment method',
            month: 'Exp. Month',
            number: 'Card Number',
            save: 'Save this card for future orders',
            title: 'Add a Credit or Debit Card',
            desc: 'Add a payment card to your account for easy checkout and to share with others.',
            desc_alt:
                'Add a payment card for this order. You may also save it for your future orders and to share with others.',
            year: 'Exp. Year',
        },
        deposit: {
            alert: 'For your security, deposited funds are not available for immediate use.',
            button: {
                add: 'Deposit Funds',
                cancel: 'Cancel Deposit',
                confirm: 'Confirm Deposit',
            },
            customer_notes: {
                extra: '150 characters max.',
                label: 'Notes',
            },
            extra: {
                ach_minimum: 'Min. amount:',
                check_minimum: 'Min. amount:',
                credit_card_limit: 'Max. amount:',
                credit_maximum: 'Max. amount:',
                credit_min_max: 'Min. / max. amount:',
            },
            label: {
                deposit: 'Deposit Funds',
                customer_notes: 'Notes',
                edit_notes: 'Edit Notes',
                method: 'Deposit Method',
            },
            reference_number: {
                extra: '50 characters max.',
                label: 'Ref. / PO #',
            },
            sider: {
                fees: {
                    processing: 'Processing Fee (:FEE_PERCENTAGE%)',
                    subtotal: 'Subtotal: ',
                    current_account_balance: 'Current Account Balance: ',
                    new_account_balance: 'New Account Balance: ',
                    total: 'Total: ',
                },
                availability: {
                    title: 'Deposit Availability',
                    subtitle:
                        'Deposits will not be available immediately for use. The processing time varies depending on the method used (Wire/ACH Transfer, Credit Card, or Check)',
                    helper: 'Learn more about deposits',
                },
                refunds: {
                    title: 'Refunds',
                    subtitle:
                        'Unused deposits are refundable, minus processing fees. Contact our customer support team and we will happily assist you.',
                },
            },
            deposit_instructions: 'We will email you instructions to complete your deposit',
            deposit_instructions_credit_card: 'Please select from your saved credit cards',
            deposit_instructions_procurement:
                'Your order will be submitted for approval within your internal procurement system',
        },
        detail: {
            amount: 'Deposit Amount',
            date: 'Date',
            description: 'Description',
            download_invoice: 'Download Receipt',
            id: 'Deposit #',
            name: 'User',
            notes: 'Notes',
            notes_reference_number: 'Ref. / PO #',
            team_name: 'Team',
            title: 'Deposit Details',
        },
        error: {
            missing_card: 'No credit card selected',
        },
        history: {
            filter: {
                type: 'Filter by Type:',
            },
            label: 'Balance History',
        },
        messages: {
            no_export: 'No funding history available to export',
        },
        outstanding_balance: 'Outstanding balance:',
        request: {
            button: {
                request: 'Request Funds',
            },
            explainer_text:
                'Request funds from an admin on your team. They will be notified and can transfer a balance into your account.',
            explainer_title: 'Requesting funds',
            info: 'Select a team admin to request funds from.',
            label: 'Request Funds',
            label_admin: 'Request From',
            label_amount: 'Amount',
            title: 'Request Funds',
        },
        transfer: {
            explain: 'Transfer account funds between team members.',
            label: 'Transfer Funds',
            title: 'Transfer Funds',
            amount: 'Transfer Funds',
            from: 'From',
            from_placeholder: 'Select user...',
            to_label: 'To',
            to_placeholder: 'Select user...',
        },
        remaining: {
            title_label: 'Remaining Max. Spending Limits',
            description: 'For purchases and deposits using a credit card',
            explainer: 'For your security, your account is limited to max. spending limits when using a credit card.',
        },
    },
    funding_user_has_enough_funds: 'User already has enough funds',
    funds_transferred_success: 'Funds successfully transferred',
    funds_transferred_permissions_error:
        'You lack the requisite permissions to transfer funds from this account. Please contact your company administrator.',
    funding_transfer_swap: 'Swap field values',
    generate_invite_link: 'Generate Invitation Link',
    generating: 'Generating',
    'giftSettings.title': 'Custom Designs',
    saved_messages: 'Saved Messages',
    saved_messages_description:
        'Create reusable messages for your entire organization to use in digital Giftogram campaigns',
    sender_names: 'Sender Names',
    sender_names_description: 'Personalize your digital gift card campaigns with a customized sender name',
    gift_history: 'Gift History',
    giftogram: 'Giftogram',
    giftogram_balance: 'Giftogram Balance',
    go_back: 'Go Back',
    greeting_cards: 'Greeting Cards',
    greeting_cards_prompt: {
        title: 'Add Greeting Cards?',
        description_1: 'For an additional ',
        description_2: ' each, complete your gift cards with a matching full-color greeting card.',
        description_3: 'Price includes affixing and envelopes.',
        ok_button: 'Yes, Add Greeting Cards',
        cancel_button: 'No Thanks',
    },
    group: 'Group',
    groups: {
        groups: 'Groups',
        enrollment: {
            warning:
                'This group is associated with a smart campaign that will automatically send a Giftogram to the recipient when added.',
            import_warning:
                'This group is associated with a smart campaign that will automatically send Giftograms to these recipients when imported.',
        },
        create: {
            success: 'Group successfully created',
            desc: 'Groups let you organize your recipients, share with other teams, and run campaigns specifically to that list.',
        },
        button: {
            create_group: 'Create Group',
            import_to_group: 'Import Recipients',
        },
        delete: {
            success: 'Group successfully deleted',
            title: 'Delete Group',
        },
        empty_table_description:
            'Groups allow you to categorize your recipients for detailed reporting and automated campaigns.',
        empty_table_title: "You don't have any groups",
        group_name: 'Group Name',
        recipients: {
            remove_content: 'This recipient will no longer receive gifts associated with this group',
            remove_title: 'Remove Recipient',
        },
        title_label: 'Groups',
        title_create_group: 'Create a Group',
        title_update_group: 'Edit Group Name',
        transition: {
            tip: 'Getting your groups',
        },
    },

    help: 'Help',
    hi: 'Hi',
    history: 'History',
    i_agree_to: "I agree to Giftogram's",
    agreeTo: "I agree to Giftogram's",
    import: 'Import',
    inactive: 'Inactive',
    individuals: 'Individuals',
    info: 'Info',
    integrations: {
        contacts_imported: 'Contacts Imported',
        no_contacts_selected: 'No contacts selected',
        choose_integration: 'Choose an Integration',
        use_map_enabled: 'Using custom mapping',
        use_map_disabled: 'Using default mapping',
        group_import_label: 'Import into Group',
        title: 'Integrations',
        map_select_placeholder: 'Choose Mapping Field',
        button: {
            import_contacts: 'Import Contacts',
            override: 'Overwrite all existing contacts',
            deselect: 'Do not import any contacts',
            nonImportable: 'Show invalid rows',
            importable: 'Show Importable Rows',
            unImportable: 'Show Un-importable Rows',
            save_token: 'Save Token',
        },
        confirm: {
            uninstall_desc: 'Are you sure you want to uninstall this integration?',
        },
        needs_authentication: 'Needs authentication',
        preview_title: `Import Recipients Confirmation`,
        preview_subtitle: `Confirm all the details below are correct before importing.`,
        use_map_label: 'Use Map',
        ignore_map: 'Ignore Map',
        use_map_message: `You've previously saved a map. If checked it will be used when importing the contacts.`,
        descriptions: {
            hubspot: 'Automatically sync your Hubspot contacts with your Giftogram recipients database.',
            sagehr: 'Automatically sync your Sage HR contacts with your Giftogram recipients database.',
            salesforce: 'Automatically sync your Salesforce contacts with your Giftogram recipients database.',
            slack: 'Automatically sync your Slack users with your Giftogram recipients database.',
            zenefits: 'Automatically sync your employees as recipients in your Giftogram database.',
        },
        install: 'Install',
        installed: 'Installed',
        empty: {
            title: 'No integrations installed',
            desc: 'Integrations allow you to sync any type of recipient data to Giftogram for frictionless gift sending.',
            button: 'Browse Integrations',
        },
        uninstall: 'Uninstall',
        unauthenticate: 'Unauthenticate',
        status: {
            installed: 'Installed',
            unauthenticated: 'Needs Authentication',
            token_needed: 'Needs Token',
            token_expired: 'Token Expired',
        },
        auto_refresh: {
            on: 'Turn Auto-Refresh On',
            off: 'Turn Auto-Refresh Off',
        },
        documentation: 'Documentation',
        permissions_title: ':APP Permissions',
        permissions_desc: 'This integration can access the following data from :APP.',
        permissions_tabs: {
            can_import: 'Data We Can Import',
            cannot_import: 'Data We Cannot Import',
        },
        view_permissions: 'View Permissions',
        request: 'Request',
        requested: 'Requested',
        request_success: 'Request successfully sent',
        request_error: 'There was an error sending your request.',
        request_desc:
            'Our customer support team will reach out to discuss your needs in adding the :INTEGRATION integration your account. Depending on volume, this integration may require a monthly fee.',
        request_multiple_scopes_desc:
            ":INTEGRATION has multiple integration types. Please let us know which one you're interested in.",
        request_submit: 'Submit Request',
    },
    internal_id: 'Internal ID',
    invalidCreditCardType: 'We do not accept this type of credit card',
    invitation_link: 'Invitation Link',
    invitation_link_alert: 'Anyone with this link will be able to add themselves to your team.',
    invitations_sent_success: 'Invitations successfully sent',
    invite: {
        accept: {
            title: "You've been added to a new team",
        },
        choice: {
            subtitle_1:
                'Giftogram allows you to reward your employees and customers with one card, redeemable for a gift card from 200+ retailers.',
            title: "You've been invited to join a team on Giftogram!",
        },
        list: {
            title: 'Upload User List',
        },
    },

    invites: {
        invite_to_team: 'Invite to Team',
        invite_users: 'Invite Users',
        invite_team_members: 'Invite Team Members',
        invitee: 'Invitee',
        invitee_info: 'Add User to Invite List',
        invite_list: 'Invite List',
        invite_list_empty: 'Your invite list is empty',
        invites_expired_success: 'Invite successfully expired',
        invites_generated_success: 'Invite successfully generated',
        invites_link_warning: 'Anyone with this link will be able to add themselves to your team.',
        invites_select_role: 'User Role',
        invites_select_role_help: 'This role will be applied to all users who sign up using this link',
        invites_explainer_1: 'Only invites to',
        invites_explainer_2: 'are displayed here.',
        invite_buttons: {
            sign_up: 'Create a Giftogram Account',
            sign_in: 'I already have a Giftogram Account',
        },
    },
    joyride: {
        back: 'Previous',
        last: 'Finish Tour',
        next: 'Next',
        skip: 'Skip Tour',
    },
    key_dates: 'Key Dates',
    key_dates_manage: 'Edit Key Dates',
    key_dates_title: 'Key Dates & Custom Fields',
    last_name: 'Last Name',
    legal_name: 'Legal Name',
    loading: 'Loading',
    logout: 'Sign Out',
    make_default: 'Make Default',
    manage: 'Manage',
    manage_account: 'Profile & Preferences',
    manage_campaigns: 'Manage Campaigns',
    manage_group: 'Manage Group',
    manage_group_memberships: 'Manage Group Memberships',
    manage_groups: 'Edit Groups',
    manage_payments: 'Manage Payment Methods',
    manage_settings: 'Manage Settings',
    manage_team: 'Manage Team',
    manage_teams: 'Manage Teams',
    mark: {
        read: 'Mark Read',
        unread: 'Mark Unread',
    },
    max_amount: 'Max amount ',
    max_amount_extra: 'Amount cannot be greater than ',
    max_credit_order: 'Max Credit Order',
    maybe_later: 'Maybe Later',
    members: 'Members',
    members_filter: 'Filter by Team:',
    members_invite_filter: 'Filter Invites by Team:',
    members_filter_active: 'Active',
    members_filter_all: 'All Users',
    members_status_filter: 'Filter by Status:',
    message: {
        delete: 'Delete Message',
        save_for_later: 'Save this message on checkout',
        title: 'Message',
        message_preview: 'Message Preview',
        message_body: 'Message Body',
        message_show_preview: 'Preview',
    },
    messages: {
        name: 'Message Name',
        noMessage: 'Do Not Include a Message',
        customMessage: 'Create New Message',
        giftMessage: 'Giftogram Message',
        smartCampaign: 'This is the message that will accompany your gift',
        standardCampaign: 'This is the message displayed in Giftograms sent digitally',
        selectMessageLabel: 'Select Message',
        subjectLabel: 'Subject Line',
        subjectExtra: "This is the subject of the email that will show in the recipient's inbox.",
        plainTextOnly: 'Please enter plain text only. HTML tags are not allowed.',
        button: {
            create: 'Create Template',
            no_save: 'Do Not Save Message',
            save: 'Save Message',
            back: 'Back to Messages',
        },
        changing_teams: 'Changing teams',
        create_title: 'Create a New Message',
        create_subtitle: 'Create a reusable message to be used in digital Giftogram campaigns.',
        edit_title: 'Edit Message',
        edit_subtitle: 'Make changes to this message for your digital Giftogram campaigns.',
        delete: {
            success: 'Message successfully deleted',
        },
        design_deleted: 'Design successfully deleted',
        empty_table_description: 'Save messages that you use often for use in your digital gifting campaigns.',
        empty_table_title: 'You have no saved messages',
    },

    mfa: {
        already_have_code: 'Already have a code?',
        code: {
            label: 'Verification Code',
        },
        confirm: 'Send SMS Code',
        confirmTitle: {
            enterCode: 'This is the six-digit code that was sent to you via text message.',
            enterCodeTitle: 'Enter your verification code',
            enterNumber: 'You will receive a one-time code to make sure we have the right device.',
            enterNumberTitle: 'Enter the phone number of the device you will use to receive codes',
            success:
                'For your security, we will use this device as a secondary authentication method when we need to be certain it is you accessing your account.',
            successTitle: 'Your device has been successfully configured',
        },
        disable: {
            enterNumber: 'We will send a SMS code to the device you configured for two-factor authentication.',
            modal: {
                title: 'Disable Two-Factor Authentication (2FA)',
            },
            disableTitle: {
                enterNumberTitle: 'Send verification code',
                success:
                    'For your security, certain features of your account may be restricted or you may experience delays in gift delivery.',
                successTitle: 'Two-factor authentication has been disabled',
            },
        },
        ssoWarning: 'Two-Factor Authentication is managed by your SSO provider.',
        ssoUndefined: 'Please sign-in again to see this feature.',
        disabled: 'Off',

        enabled: 'On',
        extra: 'Two-factor authentication is an enhanced security measure that requires secondary SMS confirmation when you sign in to your account. For your security, it is required for certain features on Giftogram.',
        modal: {
            title: 'Configure Two-Factor Authentication (2FA)',
        },
        phone: {
            extra: 'We will only use this number for 2FA security. Text or data rates may apply.',
            label: 'Mobile Phone #',
        },
        step1: 'Provide a Number',
        step2: 'Verify',
        step3: 'Success',
        submit_verification: 'Submit Verification',
        title: 'Two-Factor Authentication (2FA)',
    },

    min_qty_is: 'Minimum qty. is ',
    more: 'more',
    more_filters: 'More Filters',
    name: 'Name',
    new_password: 'New Password',

    new_user_prompt: {
        title: 'Send Gift Cards In Minutes',
        description: 'Watch a quick video to see how easy it is for your business to send gift cards in bulk.',
        dismiss_btn: 'Continue to Giftogram',
    },
    new_user: {
        how: 'Watch a Video',
    },
    new_window: {
        open: 'Open in New Window',
    },
    next_30: 'Next 30 Days',
    no_addresses: 'You have no saved addresses',
    no_company: 'No Company',
    no_delete: 'Do Not Delete',
    no_groups: "You don't have any groups",
    no_orders_export: 'No orders available to export',
    no_packaging: 'No Greeting Cards',
    no_results: 'No results',
    no_switch_teams: 'Do Not Switch Teams',
    no_thanks: 'No Thanks',
    no_undo: 'This cannot be undone.',
    notification: 'Notification',
    notifications: {
        title: 'Notifications',
    },
    notificationsSettings: {
        title: 'Notification Settings',
        subtitle: 'Manage which notifications we email to you',
        alert: 'Critical account notifications will always be emailed and cannot be turned off.',
        label: {
            all: 'All Notifications',
        },
    },
    num_members: 'Total Members',
    options: 'Options',

    order: {
        activate: {
            no_pins: 'No pins selected',
            activate_a: 'Activate All',
            activate_all: 'Activate All',
            activate_all_items: 'Activate All Items',
            activate_all_items_content:
                'All items in this order will be activated. Recipients will now be able to redeem your cards.',
            activate_range: 'Activate By Range',
        },
        confirm: {
            activated:
                'All cards in this order item will be activated. Recipients will now be able to redeem your cards.',
            deactivated:
                'All cards in this order item will be deactivated. Recipients will be unable to redeem your cards until they are re-activated.',
        },
        deactivate_a: 'Deactivate All',
        deactivate_all: 'Deactivate All',
        deactivate_all_items: 'Deactivate All Items',
        deactivate_all_items_content:
            'All items in this order will be deactivated. Recipients will be unable to redeem your cards until they are re-activated.',
        report: {
            alert: {
                title: 'Your report is being generated. We will email you when it is ready.',
            },
        },
        OrderSummary: {
            confirm_delete: {
                message: 'This will remove the item from this order and cannot be undone.',
                ok: 'Delete Item',
                title: 'Delete Order Item',
            },
        },
        history: {
            buttons: {
                download_attachments: 'Download Invoices/Receipts',
            },
        },
    },

    orders: {
        title: 'Orders',
        activate_cards: 'Activate Cards',
        procurement: {
            cancel: 'This order can be canceled from your procurement system.',
        },
        activation: {
            activate_all: 'Activate All',
            activate_range: 'Activate by Range',
            deactivate_all: 'Deactivate All',
            ending_number: 'Ending Card Number',
            ending_number_info: 'This is the last number in your range',
            starting_number: 'Starting Card Number',
            starting_number_info: 'This is the first number in your range',
            type: 'Activation Type',
        },
        button: {
            activate: 'Activate',
            back: 'Back to Order History',
            back_id: 'Back to Order #',
            cancel: 'Cancel Order',
            create_giftogram: 'Create a Giftogram',
            deactivate: 'Deactivate',
            invoice: 'Download Invoice',
            manage_activation: 'Manage',
            no_activate: 'Do Not Activate',
            no_cancel: 'Do Not Cancel',
            no_deactivate: 'Do Not Deactivate',
            no_resend: 'Do Not Resend',
            receipt: 'Download Receipt',
            reorder: 'Reorder',
            resend: 'Resend',
            change_email: 'Edit Recipient',
        },
        cancel: {
            digital: "You may cancel this order before it's sent.",
            digital_funding: 'If you would like to cancel this order, please contact customer support.',
            message: 'This cannot be undone.',
            physical: 'You may cancel this order before it enters production.',
            title: 'Cancel Order',
        },
        confirm: {
            activate_cards: 'The selected Giftogram cards will be activated.',
            deactivate_cards: 'The selected Giftogram cards will be deactivated',
        },
        deactivate_cards: 'Deactivate Cards',
        details: {
            activation: {
                activation: 'Activation Status',
                explainer_text:
                    'For your security, your cards are inactive when they ship and can only be activated by you upon delivery.',
                explainer_title: 'Keep your cards safe...',
                range_explainer_text:
                    "There's no need to activate all your cards, you can activate a group of cards—or just one.",
                range_explainer_title: 'Activate what you need...',
            },
            awaiting_shipping: 'Awaiting Shipping',
            billing_header: 'Billing Details',
            billing_method: 'Method',
            campaign: 'Campaign',
            gift_header: 'Gift Details',
            gift_message: 'Message',
            gift_subject: 'Subject',
            items_header: 'Order Items',
            manage_items: 'Manage Activation',
            no_message: 'This gift did not include a message',
            no_subject: 'This gift did not include a subject',
            order_date: 'Order Date',
            order_sent_date: 'Order Send Date',
            order_no: 'Order #',
            order_status: 'Order Status',
            preview_email: 'Preview Email',
            order_sent_time: 'Sent Time',
            order_queued_time: 'Queued Send Time',
            order_queued_time_explainer:
                'This is when your order is scheduled to send. For large orders, some recipients may receive their Giftogram shortly after this time as the order runs through our system.',
            recipient_department: 'Department',
            recipient_email: 'E-Mail',
            recipient_header: 'Recipient Details',
            recipient_title: 'Title',
            resend_title: 'Resend Giftogram',
            shipping_address: 'Address',
            shipping_country: 'Country',
            shipping_header: 'Shipping Details',
            shipping_phone: 'Phone',
            shipping_to: 'Shipping To',
            shipping_tracking: 'Tracking',
            shipping_method_label: 'Method',
            shipping_est_delivery: 'Est. Delivery',
            shipping_zipcode: 'Zip / Postal Code',
            summary_header: 'Order Summary',
            team: 'Team',
        },
        digital: {
            edit_text: 'You may change this email address prior to it being sent.',
            original_email: 'Original Email',
            original_name: 'Recipient',
            pending_jobs: 'You have pending orders that may not be displayed yet.',
            reorder_text: 'You are reordering this cancelled Giftogram.',
            reorder_title: 'Reorder Giftogram',
            resend_email: 'New Email',
            resend_text: 'Giftograms may be resent within 60 days of the original order date.',

            sample_email: {
                sample_email: 'Send Test Email',
                confirm: {
                    button: 'Send Test',
                    text1: 'We will send a test email to:',
                    text2: 'This is how the Giftogram will appear to your recipient. The gift code will not be active and you will not be charged for this test.',
                    title: 'Send Test Email',
                },
                message: 'Sample email has been sent',
            },
            update_email: 'Update email address',
            update_email_label: 'Update Recipient',
        },
        download: 'Download History',
        empty_table_description: 'Create your first Giftogram and you will see it here.',
        empty_table_title: "You don't have any orders",
        pending_approval: {
            empty_table_description: 'Orders that require admin approval will show here.',
            empty_table_title: "You don't have any orders to approve",
        },
        filter: {
            account: 'Filter by User:',
            activation: 'Filter by Activation Status:',
            campaign: 'Filter by Campaign:',
            category: 'Filter by Category:',
            date: 'Filter by Date:',
            digital_status: 'Filter by Order Status:',
            ordered_by: 'Filter by Ordered By:',
            region: 'Filter by Currency:',
            team: 'Filter by Team:',
            type: 'Filter by Order Type:',
            value: 'Filter by Value:',
        },
        menu: {
            digital: 'Digital',
            physical: 'Physical',
        },
        optional: {
            customer_notes: {
                extra: '150 characters max.',
                label: 'Notes',
            },
            reference_number: {
                extra: '50 characters max.',
                label: 'Ref. / PO #',
            },
            title: {
                title: 'Optional Info.',
                title2: 'Order History',
                digital: 'Digital Orders',
                physical: 'Physical Orders',
                pending_approval: 'Approval Queue',
            },
        },
        order_history: {
            title: 'Order History',
            digital: 'Digital Orders',
            physical: 'Physical Orders',
            pending_approval: 'Approval Queue',
        },
        transition: {
            tip: 'Getting your orders',
        },
    },
    orig_sent_to: 'Originally Sent To',
    owner: 'Owner',
    page_title: {
        campaign_create: 'Create Campaign',
        campaign_edit: 'Edit Campaign',
        checkout_success: 'Success',
        create_send: 'Create & Send',
        customize: 'Customize',
        deposit: 'Deposit',
        exports: 'Exports',
        import_recipients: 'Import Recipients',
        manage_integrations: 'Manage Integrations',
        manage_apps: 'Manage Apps',
        manage_activation: 'Manage Activation',
        order_history: 'Order History',
        recipients: 'Recipients',
        send: 'Send a Giftogram',
        send_success: 'Success',
        settings: 'Settings',
        sign_up_company_detail: 'Complete Account Setup',
    },
    parent_team: 'Team',
    parent_team_select: 'Please select a team',
    parent_team_title: 'Choose a Parent Team',
    parent_team_desc: 'This will be the immediate parent of your newly created team.',
    partial: 'Partial',
    passwordRequired: 'Password Required',
    password: {
        label: 'Password',
        mandatory_change:
            'We have undergone scheduled security maintenance and a mandatory password reset on your account is required.',
        mandatory_change_button: 'Click here to reset your password.',
        reset: {
            text: 'Enter the e-mail address associated with your account. We will send you an e-mail with instructions on how to reset your password.',
            step1: 'If an account exists with that address, we will send you an email with further instructions.',
        },
        reset_success: {
            text: 'You will be redirected to the sign in screen in a few seconds.',
            title: 'Your password has been updated',
        },
        reset_title: 'Password Reset',
        password_confirm: 'Confirm Password',
        rules: {
            five: 'At least one special character',
            four: 'At least one number',
            one: 'At least 10 characters long',
            three: 'At least one uppercase letter',
            two: 'At least one lowercase letter',
        },
    },
    payment_cards: 'Payment Cards',
    payment: {
        ach: {
            description:
                'Your order exceeds the max. amount for credit card purchases. After you submit your order, will confirm with further instructions.',
            physical: 'Order will ship once payment has been received.',
            title: 'ACH/Wire Transfer',
        },
        check: {
            title: 'Check',
        },
        credit_card: {
            add: 'Add payment card',
            exceeded: 'Your order exceeds the maximum amount for credit card purchases.',
        },
        list: {
            title: 'Giftogram Balance',
            description: 'Apply your balance to this order',
            title_procurement: 'Procurement System',
            description_procurement: 'Order will be shipped once it has been approved by your procurement system.',
        },
        methods: 'Payment Methods',
        require_deposited_funds: 'You do not have sufficient funds in your account to complete this order.',
    },

    payments: {
        temporary_card: "This card won't be saved",
    },
    paymentsSettings: {
        button: {
            add_card: 'Add Payment Card',
            delete: 'Delete',
        },
        delete_confirm_message:
            'This will delete this card for all users, teams, and campaigns. This cannot be undone.',
        delete_confirm_title: 'Delete Credit Card',
        no_cards: 'You have no saved cards',
        text: 'Securely add a credit or debit card for safe and convenient, one-click ordering',
        title: 'Payment Methods',
    },

    pending: 'Pending',
    pendingAmount_notice: 'Available balance reflects :AMOUNT in pending gifts over the next 7 days.',
    pending_balance: 'Pending Gifts',
    pending_gifts: 'Pending Gifts',
    pending_seven: 'Pending Gifts (Next 7-days)',
    permissions_not_allowed: 'Your current permission level does not allow you access to this area.',
    phone: 'Phone',
    phone_number: 'Phone Number',
    plain_text_only: 'Please enter plain text only. HTML tags are not allowed.',
    postal_code: 'Zip/Postal Code',
    prev_step: 'Previous Step',
    preview: 'Preview',
    print_proof: {
        title: 'Print Proof',
        view_btn: 'View Print Proof',
    },
    processing: 'Processing',
    product_card: {
        list_disclaimer: {
            title: 'Sending to a group?',
            content: 'On the next step, you can upload a custom list of gift recipients.',
        },
    },
    profile: 'Profile',
    profile_photo: 'Profile Photo',
    promo_code: 'Promo Code',

    properties: {
        button: {
            create: 'Create a Property',
        },
        modal: {
            title: 'Create a New Property',
            desc: 'Properties are used to store information about your recipients for reporting and sending gifts automatically.',
            alert: {
                message:
                    "Use a custom text property to add an internal ID to your reports or a custom date field to automatically send a Giftogram on an employee's hire date",
            },
        },
        empty_table_description:
            'Add custom recipient fields or key dates such as birthdays and anniversaries for automated gifting.',
        empty_table_title: 'You have no custom properties',
    },
    property: {
        delete: {
            text: 'This property will be deleted throughout your entire organization and cannot be undone.',
            title: 'Delete Property',
        },
    },
    qty: 'Qty',
    quantity: 'Qty',
    recent_history: 'Recent History',
    recipient: 'Recipient',
    recipient_email: 'Recipient Email',
    recipientSettings: {
        title: 'Recipient Properties',
    },
    recipient_detail: {
        history_title: {
            completed: 'Completed',
            pending: 'Pending',
        },
        no_history: 'There is no history',
        recipient_no_history: ' has no history',
        no_history_description: 'Creating and sending a custom branded Giftogram gift card is just a few clicks away.',
        smart_campaign: {
            no_history_description: 'When your smart campaign runs for the first time, you will see your history here.',
        },
        update: {
            success: 'Recipient updated successfully',
        },
    },
    recipients: {
        detail: {
            alerts: {
                shared: 'This recipient is owned by the team :TEAM and cannot be edited here.',
                synced: 'This recipient was created automatically by the integration :GROUP and cannot be edited here.',
            },
        },
        sync: {
            title: 'Integration Sync',
            nav_buttons: {
                create: 'Create Sync Job',
            },
            table: {
                empty: {
                    title: "You don't have any sync jobs",
                    desc: 'Create a sync job to automatically import recipients from your installed integrations.',
                    button: 'Create a Sync Job',
                },
            },
            modal: {
                title: {
                    create: 'Create Sync Job',
                    edit: 'Edit Sync Job',
                },
                description: {},
            },
            form: {
                recurring: 'Recurring',
                job_name: 'Job Name',
                integration: 'Integration',
                integration_placeholder: 'Select an installed integration...',
                submit: {
                    create: 'Create Sync Job',
                    save: 'Save Changes',
                },
            },
        },
        activate: 'Activate Recipients',
        activate_content: 'The selected recipients will be available for all campaigns.',
        alert: {
            belong_to_group: 'These users already belong to this group',
            export_success: 'Your export has been started. You will be notified when your file is ready to download.',
        },
        button: {
            back: 'Back to Recipients',
            create_recipient: 'Create Recipient',
            download_recipients: 'Download Recipients',
            no_create_recipient: 'Do Not Create Recipient',
        },
        dates: {
            explainer: {
                description:
                    'Assign key dates such as birthdays, hire dates, or service anniversaries for use in automatic gifting campaigns.',
                title: 'Send gifts automagically',
            },
        },
        deactivate: 'Deactivate Recipients',
        deactivate_warning:
            'Deactivating or deleting recipients will cause pending orders for those recipients to be cancelled.',
        delete: 'Delete Recipient',
        delete_inactive: 'Delete Inactive',
        delete_inactive_confirm_title: 'Delete Inactive Recipients',
        empty_table_description:
            'Recipients allows you to import, organize and view the history of people you have sent digital gifts to.',
        empty_table_title: "You don't have any recipients",
        empty_groups_table_title: "This group doesn't have any recipients",
        groups: {
            explainer: {
                description:
                    'Groups allow you to segment your recipients for detailed reporting and automated gifting campaigns.',
                title: 'Organize your recipients',
            },
        },
        import: {
            button: 'Import Recipients',
            group_label: 'Add to Group:',
            job_running: 'There is an import already in progress.',
            job_running_button: 'Cancel Import',
            missing_required:
                'Required headers [FirstName, LastName, and Email] must be selected in the column mapping',
            no_group: 'Do Not Add to a Group',
            reconcile: {
                checkbox_label: 'Sync my recipients to this list',
                content:
                    'Syncing this import with your current recipient database will cancel all pending gift cards scheduled for recipients not on this list.',
                label: 'Sync Recipients with Import:',
                title: 'Sync Recipients',
            },
            success: 'Your import has successfully started. You will be notified when it is completed.',
            title: 'Import Recipients',
        },
        message: {
            no_selected: 'No recipients selected.',
            update_success: 'Recipients successfully updated',
        },
        no_date: 'No dates',
        no_groups: " doesn't belong to any groups",
        no_key_dates: ' has no key dates',
        no_text_props: 'No text properties',
        not_enrolled: 'Recipient is not enrolled in a campaign',
        save: {
            create_title: 'Create a Recipient',
            update_title: 'Update Recipient',
        },
        title: 'Recipients',
    },

    refunds_cancellations: 'Refunds & Cancellations',
    refunds_returns: 'Refunds & Returns',
    region: 'Region',
    register_business_account: 'Auth for Giftogram and start rewarding your employees now',
    register_redeem: 'Select this option if you have a Giftogram you would like to redeem.',
    remember_device: 'Remember this device',
    remove: 'Remove',
    remove_from_team: 'Remove from Team',
    report_generating: 'Your export has been started. You will be notified when your file is ready to download.',
    report_type: 'Report Type',
    reports: {
        alert: {
            description: 'User generated exports will stay here for 20 days after creation.',
        },
        empty_table_description: 'Exports that you have generated will be displayed here.',
        empty_table_title: "You don't have any exports",
        title: 'Exports',
    },
    request_change: 'Request Change',
    requested_on: 'Requested On',
    resend_email: 'Resend Email',
    reset_queue: 'Reset queue',
    review: 'Review',
    role: 'Role',
    roles: {
        company: {
            reports_only: 'Company Reports Only',
        },
        gift_only: 'Gift Only',
        reports_only: 'Team Reports Only',
        super_admin: 'Super Admin',
        team_admin: 'Team Admin',
    },
    roles_updated_success: 'Roles successfully updated',
    sandbox: {
        banner: 'You are currently in a sandbox test environment',
    },
    sales_tax: 'Sales Tax',
    save: 'Save',
    save_changes: 'Save Changes',
    saved_addresses_text: 'Add a saved shipping address for easy and accurate shipping at checkout',
    savedDesigns: {
        delete_confirm_message:
            'This will delete this design for all users, teams, and campaigns. This cannot be undone.',
        button: {
            remove: 'Delete',
        },
        delete_confirm_title: 'Delete custom design?',
    },
    schedule: 'Schedule',
    search: 'Search',
    search_groups: 'Search groups...',
    search_recipients: 'Search recipients...',
    search_balance_history: 'Search balance history...',
    search_campaigns: 'Search campaigns...',
    search_order_history: 'Search order history...',
    security_settings_title: 'Security Settings',
    search_users: 'Search Users...',
    select_group: 'Select a group',
    select_role: 'Select a role',
    select_team: 'Select a team',
    send: {
        group: {
            extra: 'This gift will be sent to all active recipients in the group',
            team_recipients: 'Only send to group recipients that are owned by this team',
            team_recipients_label: 'Team Recipients Only',
        },
        list: {
            error: 'Only .CSV and .XLSX files are valid for upload.',
            reset: 'Reset',
            template: 'Download Template',
            how_to_link: 'How to Import Recipients',
            title: 'Upload Recipient List',
        },
        message: {
            default_subject: 'SENDER_NAME has sent you a Giftogram',
            fieldset: 'Message',
        },
        message_body: 'Message Body',
        message_name: 'Message Name',
        message_name_extra: 'Give this message a name so you can reference it later.',
        name_placeholder: 'Enter Message Name',
        recipient_details: 'Recipients',
        recipient_email: 'Recipient Email',
        recipient_name: 'Recipient Name',
        search_email_address: 'Search or enter an email address',
        search_recipient_name: 'Search or enter a recipient name',
        select_group: 'Send to a Group',
        send_now: 'Send Today',
        sender: {
            fieldset: 'Sender Name',
            label: 'Name',
        },
        subject_line_extra: "This is the subject of the email that will show in the recipient's inbox.",
        subject_line_extra_instructions:
            'Type the token SENDER_NAME where you want to personalize with the sender name.',
        success: {
            text: 'Our customer success team will quickly review and process your order.',
            title: 'Your Giftogram has been scheduled!',
            pending_approval: {
                title: 'Your order is queued for admin approval!',
                text: 'We have let your team admin know this order is ready for review.',
            },
        },
        time: {
            extra: 'Your timezone settings are set to ',
            label: 'Scheduled Date & Time',
        },
        country_warning: 'Gift cards will only work in',
    },
    send_recipients_tabs: {
        individuals: 'Send to Individuals',
        group: 'Send to a Group',
        list: 'Upload a List',
    },
    send_giftogram: 'Send Giftogram',
    send_to: 'Send To',

    sender: {
        add: {
            title: 'Create Sender Name',
            description: 'Sender names added here will only be available to you.',
        },
        button: {
            create: 'Create Sender Name',
            save: 'Save Sender Name',
        },
        delete: 'Delete Sender Name',
        delete_confirm_text:
            "If this sender name is used in a campaign, that campaign will revert to using the sender's profile name. This cannot be undone.",
        edit_title: 'Edit Sender Name',
        empty_table_title: 'You have no saved sender names',
        make_default: 'Make this the default sender name',
        modal_text: "This is the 'From' name that will appear in the recipient's inbox",
        sender_name: 'Sender Name',
    },
    server_response_label: 'Mail Server Response',
    server_response_alert:
        "The response from the recipient's mail server is below. This may be used to help diagnose any deliverability issues between Giftogram and your company's firewall.",
    settings: {
        settings_label: 'Settings',
        admin: {
            explainer_text: 'Set restrictions or permissions for all users in your organization',
        },
        brand: {
            explainer_text: "Customize the digital Giftogram email with your organization's logo and brand identity",
        },
        company: {
            explainer_text: 'Keep your company information up to date',
        },
        designs: {
            explainer_text:
                'These custom designs will be accessible to your entire team and can be shared with sub-teams',
        },
        info: {
            integrations: 'Connect your favorite business tools to sync recipients and automate sending gifts',
        },
        members: {
            explainer_text:
                'Create new users, manage membership in the current team, and remove users from your account',
        },
        messages: {
            explainer_text:
                'Customize your outgoing digital Giftograms with custom messages and sender names for your organization.',
        },

        payments: {
            explainer_text:
                'Saved credit cards will be accessible to your entire team and can be shared with sub-teams',
        },
        recipient_props: {
            explainer_text:
                'Create custom text or date fields to your recipient records for better organization and automation',
        },
        security: {
            explainer_text: 'These are your personal security settings',
        },
        shipping: {
            explainer_text:
                'Saved shipping addresses will be accessible to your entire team and can be shared with sub-teams',
        },
        teams: {
            explainer_text: 'Organize your users into teams for organizational, reporting, and partitioning purposes',
        },
        user: {
            explainer_text: 'Keep your profile and contact information up to date',
        },
    },
    brandS_settings_title: 'Brand Settings',
    settings_preferences: 'Settings & Preferences',
    shared: 'Shared',
    shared_teams: 'Shared Teams',
    ship_to: 'Ship To',

    shipping: {
        shipping_label: 'Shipping',
        add: {
            address: 'Add a Shipping Address',
            address_desc:
                'Add a shipping address to your account for easy checkout of your physical orders and to share with others.',
        },
        address: {
            deleted: 'Address updated successfully',
            edit_title: 'Edit Shipping Address',
            saved: 'Address saved successfully',
        },
        button: {
            add_address: 'Add Shipping Address',
            submit: 'Add Shipping Address',
            validate: 'Verify Address',
        },
        change_address: 'Change Address',
        use_original_address: 'Use Original Address',
        use_verified_address: 'Use Verified Address',
        verify_address_desc: 'Please confirm your shipping address details.',
        verified_address: 'Verified Address',
        what_you_entered: 'What You Entered',
    },

    shippingSettings: {
        button: {
            add_address: 'Add Shipping Address',
            delete: 'Delete',
            no_verify_address: 'Do Not Add Address',
        },
        delete_confirm_message:
            'This will delete this address for all users, teams, and campaigns. This cannot be undone.',
        delete_confirm_title: 'Delete Address',
        title: 'Shipping Addresses',
    },
    shipping_and_payment_options: 'Delivery & Payment Options',
    shipping_alert: {
        content:
            'We only support shipping physical orders to U.S. addresses via our website. Please contact customer support to place an order with delivery outside the U.S.',
    },
    show_all: 'Show All',
    show_results: 'Show Results',
    signin: {
        divider_label: 'or',
        lock: "You only have {remainingAttempts} attempt{plural} remaining before you're account is locked.",
        lockResetPassword: 'You can try resetting your password.',
        error: 'Please check your email or password.',
        googleSignUpError: 'Failed to fetch your google credentials. Please try again later.',
        waf: 'Your sign in could not be processed at this time. (Error Code: 14290)',
        federated: {
            subtitle: 'Click the button below to sign in with your :name account',
            title: 'Sign In with :name SSO',
        },
        label: 'Sign In',
        mfa: {
            label: 'Authentication Code',
            subtitle: 'We have sent a authentication code to your verified device via SMS.',
            title: 'Enter Your Authentication Code',
        },
        register_button: 'Sign Up for FREE',
        register_button_mobile: 'Sign Up',
        register_label: "Don't have an account?",
        reset_password: 'Reset password',
        sso: {
            button: {
                prefix: 'Sign In with ',
            },
            error: 'SSO credentials not found',
            label: 'Sign In with SSO',
            new_method: 'Sign In with a Different Method',
            subtitle: 'Enter your work email address',
            title: 'Sign In with Your SSO Credentials',
        },
        subtitle: 'Enter your credentials below.',
        title: 'Sign In to Giftogram',
    },
    signup: {
        label: 'Sign Up',
        already_have_account: 'Already have an account?',
        complete_registration: 'Create Account',
        marketing_opt_in: 'I would like to receive marketing and promotional emails from Giftogram.',
        next_steps: 'Continue',
        step1: {
            title: 'Create a Giftogram Account',
        },
        step2: {
            title: "You're Almost There!",
            register: {
                title: 'Create a Secure Password',
            },
        },
        subtitle: 'No obligation. Start sending gift cards—instantly.',
        subtitle2: 'Complete Your Registration',

        verify: {
            button: {
                label: 'Go to Sign In',
            },
        },
        with_google: 'Sign Up with Google',
        demo_request: {
            text: 'Want to evaluate before signing up?',
            button: 'Book a Demo',
        },
        invite: {
            alert: {
                message: "You are signing up under :COMPANY_NAME's company account",
            },
        },
        waf: 'Your account could not be created at this time. (Error Code: 14290)',
        warning: {
            button: {
                create: 'Purchase Gift Cards',
                redeem: 'Redeem a Gift Card',
            },
            text_one:
                'Giftogram works with businesses of all sizes to send branded and personalized gift cards to their employees and customers.',
            text_two:
                'Are you a business looking to purchase gift cards or did you receive a gift card that you want to redeem?',
            title: 'Pick your account type',
        },
    },
    size: 'Size',
    smart: {
        smart: 'Smart',
        explainer: {
            group: 'This group is automatically created by Giftogram for the integration :GROUP and cannot be edited',
        },
    },
    something_went_wrong: 'Something went wrong',
    standard: 'Standard',
    start_over: 'Start Over',
    start_typing: 'Start typing...',
    state: 'State',
    state_providence: 'State/Providence',
    status: 'Status',
    steps: {
        next: 'Next Step',
        prev: 'Previous Step',
        continue: 'Continue to ',
    },
    stock_designs: 'Stock Designs',
    submit: 'Submit',
    subtotal: 'Subtotal',
    supportCenter: 'Support',
    switch: 'Change',
    switch_team: 'Change Team',
    switch_teams: 'Change Teams',
    tab: {
        custom: 'Custom',
        dates: 'Dates',
        general: 'General',
        groups: 'Groups',
        tags: 'Tags',
    },
    table: {
        activation: 'Activation',
        amount: 'Amount',
        date: 'Date',
        description: 'Description',
        end: 'End',
        item: 'Item',
        order_no: 'Order #',
        ordered_by: 'Ordered By',
        sent_by: 'Sent By',
        start: 'Start',
        status: 'Status',
        total: 'Total',
        type: 'Type',
        user: 'User',
        value: 'Value',
    },
    tag: {
        adjustment: 'Adjustment',
        cancelled: 'Cancelled',
        completed: 'Completed',
        credit: 'Credit',
        order: 'Order',
        other: 'Other',
        payment: 'Payment',
        pending: 'Pending',
        promo_credit: 'Credit',
        transfer: 'Transfer',
    },
    tags: 'Tags',
    team: 'Team',
    teams_label: 'Teams',
    teams: {
        button: {
            clone: 'Duplicate Team',
            create: 'Create Team',
            no_clone: 'Do not duplicate team',
            update: 'Update Team',
            remove: 'Expire Invite',
            generate_link: 'Generate Link',
            no_remove: 'Do Not Expire',
            create_address: 'Add Shipping Address',
            submit: 'Add Shipping Address',
            validate: 'Verify Address',
            invite_existing: 'Invite Users',
            cancel_generate_link: 'Do Not Generate Link',
            remove_member: 'Remove',
            transfer_funds: 'Submit Transfer',
            cancel_invite_existing: 'Do Not Invite',
            cancel_transfer_funds: 'Do Not Transfer Funds',
            remove_button: 'Remove',
        },
        clone_info: 'Your duplicated team will inherit all the properties of',
        create: {
            addresses: {
                extra: 'Leave blank to not share any addresses',
                placeholder: 'Select addresses to share with this team',
            },
            campaigns: {
                extra: 'If you do not share a campaign, users can create their own',
                placeholder: 'Select campaigns to share with this team',
            },
            designs_title: 'Shared campaign designs',
            designs_description:
                'Designs necessary for shared campaigns are automatically shared for use in those campaigns.',
            explainer_text: 'Teams allow you to group your users for controlled settings and detailed reporting.',
            explainer_title: 'Organize Your Users',
            groups: {
                extra: 'Leave blank to not share any groups',
                placeholder: 'Select groups to share with this team',
            },
            name: {
                extra: 'Give this team a descriptive name',
            },
            payments: {
                extra: 'Leave blank to not share any credit/debit cards',
                placeholder: 'Select credit/debit cards to share with this team',
            },
            steps: {
                billing: 'Billing & Shipping',
                campaigns: 'Campaigns & Designs',
                general: 'General',
                recipients: 'Recipients',
            },
            title: 'Create Team',
            transition_tip: 'Getting team builder',
        },
        empty_table_description: 'Invite new users to your team or organization',
        empty_table_title: "You don't have any pending invites",
        invites: {
            delete_confirm_info: 'This will expire the existing invite.',
            delete_confirm_title: 'Expire Invite',
            empty_table_description: 'Invite new users to your team or organization',
            empty_table_title: "You don't have any pending invites",
            generate_link_info: 'Generate a link to invite users to your Giftogram team.',
            generate_link_title: 'Generate Invitation Link',
            title: 'Invite Users',
        },
        label: {
            campaigns: 'Shared Campaigns',
            description: 'Description',
            groups: 'Shared Groups',
            name: 'Team Name',
            payment_methods: 'Payment Cards',
            shipping_addresses: 'Shipping Addresses',
        },
        members: {
            change_role_title: 'Change Role',
            activate_confirm_info:
                "This will activate this user's account. They will be able to sign in to their account and perform all actions allowed by their permission level.",
            activate_confirm_title: 'Activate ',
            deactivate_confirm_info:
                "This will deactivate the user's account. They will no longer be able to sign in and all pending gifts in their account will be cancelled.",
            deactivate_confirm_title: 'Deactivate ',
            delete_confirm_info: 'This will only remove the user from the current team.',
            delete_confirm_title: 'Remove ',
            empty_table_description: "Here's where we display your current team members",
            empty_table_title: 'No team members',
            has_funds: {
                deactivate_confirm_info:
                    'Please transfer these funds to yourself or another team member before deactivating this user.',
                deactivate_confirm_title: 'This User Has Funds in Their Account',
            },
            invite_existing_title: 'Invite Users to Team',
        },
        payments: {
            account_credit_cards_label: 'Payment Card Sharing',
            account_funds_only: {
                label: 'Checkout',
                checkbox_label: 'Only allow checkout with deposited funds',
                extra: 'If checked, users will not be prompted for a credit card at checkout',
                extra_required: 'Users will not be prompted for a credit card at checkout (Required)',
            },
            account_credit_cards: {
                checkbox_label: 'Do not share payment cards amongst teams',
                extra: 'If checked, users may only use their own credit or debit cards',
                extra_required: 'This is required',
                confirm_info:
                    'This will delete the saved payment cards in all existing team member accounts. This cannot be undone.',
                confirm_title: 'Are you sure you want to turn payment card sharing on?',
                confirm_sharing_button: 'Turn on Sharing',
            },
        },
        recipients: {
            gifting_manage_recipients: {
                checkbox_label: 'Allow users with Gift Only permissions to manage recipients',
                label: 'Manage Recipients',
            },
        },
        saved: {
            created: 'Team successfully created',
            deleted: 'Team successfully deleted',
            success: 'Team successfully saved',
            updated: 'Team successfully updated',
        },
        title: 'Teams',
        title_clone: 'Duplicate Team',
        title_create: 'Create Team',
        title_update: 'Update Team',
        update_transition_tip: 'Getting team details',
    },
    terms_and_conditions: 'Terms of Service',
    text_field: 'Text Field',
    thumbnail: 'Thumbnail',
    timezone: 'Timezone',
    title: 'Title',
    today: 'Today',
    total: 'Total',
    total_funds: 'Total Funds',

    transfer: {
        add_funds: 'Add Funds',
        failed: 'Balance transfer failed',
        subtract_funds: 'Subtract Funds',
    },
    transfer_type: 'Transfer Type',
    type: 'Type',
    understand: 'I Understand',
    unverified: 'Unverified',
    update: 'Update',
    update_nickname: 'Update Nickname',
    add_nickname: 'Add Nickname',
    nickname: 'Nickname',
    updating: 'Updating',
    upload: {
        duplicate_warning: 'These recipients will receive more than one gift:',
        results: {
            error: 'There was an issue with your file',
            errors: 'We found {count} issues with the file and were unable to process it. Correct the issues and try again.',
            sample: 'Here is a sample of what your list looks like:',
        },
        csv_error: 'Only .CSV and .XLSX files are valid for upload.',
    },
    url_placeholder: 'www.example.com',
    userSettings: {
        button: {
            no_reset_password: 'Do Not Reset',
            reset_password: 'Reset Password',
            update: 'Update Profile',
        },
        currency: 'Currency Display',
        currency_explainer: 'This is for display purposes only. Funds are always in USD.',
        members_explainer:
            "Only members of the current team and its sub-teams are displayed here. If you don't see the team member you're looking for, try switching to a higher level team.",
        menu: {
            admin_settings: 'Admin Settings',
            api_settings: 'API',
            company_settings: 'Company Info',
            company_settings_title: 'Company Settings',
            designs_messages_settings: 'Custom Designs',
            digital_gift_settings: 'Digital Gift Settings',
            integrations_settings: 'Integrations',
            manage_members: 'Manage Users',
            notifications_settings: 'Notifications',
            notifications_integrations: 'Integrations',
            payments_settings: 'Payment Methods',
            profile_title: 'Profile & Preferences',
            security_settings: 'Security',
            shipping_settings: 'Shipping Addresses',
            teams_title: 'Team Settings',
            teams_settings: 'Manage Teams',
            user_settings: 'User Info',
        },
        message: {
            update_password: 'Your password has been successfully updated.',
        },
        modal: {
            title: 'Reset Password',
        },
        profile_updated: 'User info successfully updated',
        teams_explainer:
            "Only the current team and its sub-teams are displayed here. If you don't see the team you're looking for, try switching to a higher level team.",
        title: 'User Settings',
    },
    users: 'Users',

    usersMembersSettings: {
        title: 'Manage Users',
    },
    usersTeamsSettings: {
        title: 'Manage Teams',
    },
    utility: {
        email: 'Email',
        mobile_phone: 'Mobile',
    },
    validation: {
        campaign: {
            description_max: 'Must be 70 characters or less',
            name_max: 'Must be 70 characters or less',
        },
        cvv: 'Please enter a valid CVV for this credit card',
        whitelist: {
            send_no_match: 'You are only allowed to send to the following email domains:',
            team_no_match: 'You are only allowed to invite from the following email domains:',
        },
        email: 'Please enter a valid email address',
        existing_recipients_only: 'This campaign only permits sending to existing recipients',
        full_name: 'Please enter your full name',
        payment_method: 'Please choose a payment method',
        phone_number: 'Please enter a valid phone number',
        shipping_address: 'Please provide a shipping address',
        zip_code: 'Please enter a valid postal code',
    },
    value: 'Value',
    variables: {
        modalTitle: 'Insert personalization token',
        okButton: 'Add',
        label: 'Default Value',
        extra: "This is what will display if your recipients don't have this value in their record",
        placeholder: 'Leave blank for no value',
        explainer:
            'When sending to multiple people at once, personalize the message to each recipient with variables such as their name.',
    },
    verification_status: 'Verification Status',
    verified: 'Verified',
    view: 'View',
    view_all: 'View All',
    view_edit: 'View/Edit',
    view_help_article: 'View Help Article',
    view_in_team: 'View In Team',
    view_invite_link: 'View Invitation Link',
    view_more: 'View More',
    view_order: 'View Order',
    view_reports: 'Go to Exports',
    view_reports_error: 'Something went wrong. Please try again later.',
    website: 'Website',
    welcome_back: 'Welcome Back',
    what_is: 'What is this?',
    work_email: 'Work email',
    work_email_extra: 'We only work with businesses and organizations',
    working: 'Working',
    yes: 'Yes',
    your_role: 'Your Role',
    your_pending_gifts: 'Your Pending Gifts',
    isRequired: 'is required',
    required: 'Required',
};
