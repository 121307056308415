import siteDataActions from './actions';

const initialState = {
  urls: null,
};

const siteDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case siteDataActions.SAVE:
      return { ...action.payload };
    default:
      return state;
  }
};

export default siteDataReducer;
