import React, {lazy, Suspense, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Icon from '@ant-design/icons';

const Brand = (props) => {
    const [icon, setIcon] = useState(null);

    const styles = {
        width: props.size + 'rem',
        fontSize: props.size + 'rem',
        ...props.style,
    };

    useEffect(() => {
        const icon = lazy(() =>
            import(`./brand.${props.brand}.js`)
                .then((res) => res)
                .catch(() => import(`components/UI/Brand/empty`)),
        );
        setIcon(icon);
    }, [props.brand]);

    if (icon === null) return '';

    return (
        <Suspense fallback={''}>
            <Icon component={icon} style={styles} {...props} />
        </Suspense>
    );
};

Brand.defaultProps = {
    size: 1,
};

Brand.propTypes = {
    brand: PropTypes.string.isRequired,
    size: PropTypes.number,
    style: PropTypes.any,
};

export default Brand;
