import campaignTypesActions from './actions';

const INITIAL_DATA = {
    data: null,
    loading: false,
    error: null,
    loaded: false,
};

const campaignsReducer = (state = INITIAL_DATA, action) => {
    switch (action.type) {
        case campaignTypesActions.FETCH_CAMPAIGN_TYPES_DATA_START:
            return {
                ...state,
                loading: true,
                loaded: false,
                error: false,
            };
        case campaignTypesActions.FETCH_CAMPAIGN_TYPES_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                loaded: true,
                error: false,
            };
        case campaignTypesActions.FETCH_CAMPAIGN_TYPES_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: true,
            };
        default:
            return state;
    }
};

export default campaignsReducer;
