import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Space, Empty, Drawer, Button, Alert, FontAwesome} from 'components/UI';
import {lang} from 'config/lang';
import orderSummaryActions from 'redux/orderSummary/actions';
import {useLocation} from 'react-router-dom';
import {OrderSummary} from 'components/Cart';
import {getProfile} from 'redux/profile/selectors';
import {getCartQuantity} from 'library/helpers/orders/physical';
import profileActions from 'redux/profile/actions';
import {getOrderSummary} from 'redux/orderSummary/selectors';
import shippingRatesActions from 'redux/shippingRates/actions';
import {usePrev} from 'hooks/utility/usePrev';
import {goToRoute} from 'library/helpers/routing';
import {routes} from 'config/routes';

const CartDrawer = () => {
    const initState = {
        visible: false,
        showEmptyCart: true,
        errors: null,
        minQty: 0,
        cartQty: 0,
    };

    const [state, setState] = useState(initState);

    const order = useSelector(getOrderSummary);
    const profile = useSelector(getProfile);

    const dispatch = useDispatch();
    const {pathname} = useLocation();

    useEffect(() => {
        if (order.data !== null) return;

        dispatch(orderSummaryActions.fetchOrderSummaryStart());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            minQty: profile?.data?.min_qty || 0,
        }));
    }, [profile?.data?.min_qty]);

    const prevOrderError = usePrev(order.error);
    useEffect(() => {
        if (prevOrderError !== order.error) {
            setState((prevState) => ({
                ...prevState,
                errors: order.error,
            }));
        }
    }, [prevOrderError, order.error]);

    const prevOrderAdding = usePrev(order.adding);
    const prevOrderChanging = usePrev(order.changing);
    useEffect(() => {
        if (!prevOrderAdding && order.adding) {
            setState((prevState) => ({
                ...prevState,
                showEmptyCart: false,
                cartQty: getCartQuantity(order.data.items),
            }));
        }
        if (prevOrderAdding && !order.adding) {
            setState((prevState) => ({
                ...prevState,
                showEmptyCart: false,
                cartQty: getCartQuantity(order.data.items),
            }));
        }

        if (prevOrderChanging && !order.changing) {
            setState((prevState) => ({
                ...prevState,
                showEmptyCart: true,
                cartQty: getCartQuantity(order.data.items),
            }));
        }
    }, [prevOrderAdding, order.adding, prevOrderChanging, order.changing, order.data.items]);

    useEffect(() => {
        if (profile.data !== null && state.minQty === 0) {
            setState((prevState) => ({
                ...prevState,
                minQty: profile.data.min_qty,
            }));
        }
    }, [profile.data, state.minQty]);

    useEffect(() => {
        if (getCartQuantity(order.data.items) !== state.cartQty) {
            setState((prevState) => ({
                ...prevState,
                cartQty: getCartQuantity(order.data.items),
            }));
        }
    }, [order.data.items, state.cartQty]);

    const handleClose = () => dispatch(orderSummaryActions.toggleVisibleOrderSummary(false));

    const gotoCheckout = () => {
        handleClose();
        dispatch(profileActions.fetchProfileDataStart({forceFetch: true}));
        dispatch(shippingRatesActions.fetchShippingRatesStart({forceFetch: true}));
        goToRoute(routes.private.paths.CHECKOUT.SHIPPING);
    };

    const renderFooter = () => {
        const meetsMinQty = state.cartQty >= state.minQty;

        return pathname !== routes.private.paths.CHECKOUT.SHIPPING ? (
            <Space size={16} direction="vertical">
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        handleClose();
                    }}
                    size="large"
                    block
                >
                    {lang.button.keep_shopping}
                </Button>

                {order.data.total > 0 && state.cartQty > 0 && (
                    <Button
                        onClick={gotoCheckout}
                        type="primary"
                        size="large"
                        className="button-shadow"
                        block
                        disabled={!meetsMinQty || order.adding || order.changing}
                        loading={order.adding || order.changing}
                    >
                        {meetsMinQty ? (
                            lang.button.checkout
                        ) : (
                            <>
                                {lang['min_qty_is']} {state.minQty}
                            </>
                        )}
                    </Button>
                )}
            </Space>
        ) : (
            <Space size={16} direction="vertical">
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        handleClose();
                    }}
                    size="large"
                    block
                    icon="arrow-left"
                >
                    {lang.button.return_to_checkout}
                </Button>
            </Space>
        );
    };
    const renderDrawer = (children) => {
        return (
            <Drawer
                title="Cart"
                placement="right"
                closable={false}
                closeIcon={<FontAwesome icon="arrow-left" />}
                maskClosable={true}
                onClose={handleClose}
                open={order.visible}
                width={480}
                footer={renderFooter()}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        minHeight: '100%',
                        position: 'relative',
                    }}
                >
                    {state.errors !== null && state.errors.data && (
                        <Alert message={state.errors.data.message} type="error" />
                    )}
                    {children}

                    <OrderSummary errors={state.errors} />

                    {order.data.items.length === 0 && state.showEmptyCart && (
                        <Empty
                            image={<FontAwesome icon="bag-shopping" type="duotone" />}
                            description={lang['empty_cart']}
                            style={{marginTop: '1rem'}}
                        />
                    )}
                </div>
            </Drawer>
        );
    };

    return renderDrawer();
};

export default CartDrawer;
