import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Form as AntdForm} from 'antd';
import {lang} from 'config/lang';
import {Prompt} from 'react-router-dom';
import isEqual from 'react-fast-compare';
import noop from 'lodash/noop';
import FormGroup from 'components/UI/Form/FormGroup';
import FormFooter from 'components/UI/Form/FormFooter';

/**
 * Extends the Antd Form component to add additional functionality.
 *
 * @param {Object} props - The properties for the Form component.
 * @param {React.ReactNode} props.children - The children elements for the Form component.
 * @param {Object} props.form - The form instance from the useForm hook.
 * @param {boolean} props.hidden - If true, the Form component will be hidden.
 * @param {Object} props.initialValues - The initial values for the form fields.
 * @param {string} props.layout - The layout type for the Form component.
 * @param {function} props.onFinish - The callback function to be executed when the form is successfully submitted.
 * @param {function} props.onFinishFailed - The callback function to be executed when the form submission fails.
 * @param {function|boolean} props.onValuesChange - The callback function to be executed when the form values change.
 * @param {boolean} props.prompt - If true, the Prompt component will be displayed when the user navigates away from a dirty form.
 * @param {Object} props.style - The style object for the Form component.
 */

/**
 * Typehint the extended Form.Item and Form.List components for the IDE.
 * @type {FormComponent & { Item: import('antd/lib/form/FormItem').FormItem; List: import('antd/lib/form/FormList').default; Group: import('antd/lib/form/FormGroup').default, Footer: FormFooter }}
 */

const Form = ({children, prompt, ...props}) => {
    const {form = null, initialValues = null, onValuesChange = noop} = props;

    const [dirty, setDirty] = useState(false);

    const handleValuesChange = useCallback(
        (...args) => {
            const isDirty =
                !initialValues || !form || !isEqual(form.getFieldsValue(Object.keys(initialValues)), initialValues);

            setDirty(isDirty);
            onValuesChange(...args);
        },
        [form, initialValues, onValuesChange],
    );

    // Form extensions
    // Form Method Overloads
    useEffect(() => {
        if (!form) return;

        const originalSetFields = form.setFields;
        const originalSetFieldValue = form.setFieldValue;
        const originalResetFields = form.resetFields;

        form.originalSetFields = (fields) => {
            originalSetFields(fields);
            form.isDirty = true;
        };

        form.setFieldValue = (key, value) => {
            originalSetFieldValue(key, value);
            form.isDirty = true;
        };

        form.resetFields = (fields) => {
            originalResetFields(fields);
            form.isDirty = false;
        };
    }, [form]);
    // Form Extensions End

    return (
        <>
            {prompt && props?.form && <Prompt when={dirty} message={lang.form.prompt.dirty} />}

            <AntdForm {...props} onValuesChange={handleValuesChange}>
                {children}
            </AntdForm>
        </>
    );
};

Form.Group = FormGroup;
Form.Item = AntdForm.Item;
Form.List = AntdForm.List;
Form.Footer = FormFooter;

Form.useForm = AntdForm.useForm;
Form.useWatch = AntdForm.useWatch;

Form.propTypes = {
    children: PropTypes.any,
    form: PropTypes.object,
    hidden: PropTypes.bool,
    initialValues: PropTypes.object,
    layout: PropTypes.oneOf(['horizontal', 'vertical', 'inline']),
    onFinish: PropTypes.func,
    onFinishFailed: PropTypes.func,
    onValuesChange: PropTypes.func,
    prompt: PropTypes.bool,
    style: PropTypes.object,
};

export default Form;
