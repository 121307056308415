import {lang} from '../lang';

export const ORDER_STATUSES = [
    {
        value: 'pending',
        label: 'Pending',
    },
    {
        value: 'sent',
        label: 'Sent',
    },
    {
        value: 'sending',
        label: 'Sending',
    },
    {
        value: 'delivered',
        label: 'Delivered',
    },
    {
        value: 'bounced',
        label: 'Bounced',
    },
    {
        value: 'cancelled',
        label: 'Cancelled',
    },
    {
        value: 'in_review',
        label: 'In Review',
    },
];

export const BULK_ORDER = 'bulk';
export const BOTH_ORDER = 'both';
export const DIGITAL_ORDER = 'digital';
export const APPROVALS_ORDER = 'approvals';
export const SMART_ORDER = 'smart';
export const PHYSICAL_ORDER = 'physical';

export const ALL_CATEGORY = {
    slug: 'all',
    icon: 'gift-card',
    name: lang['all'],
};

export const CUSTOM_CATEGORY = {
    slug: 'custom-designs',
    icon: 'scribble',
    name: lang.create.menu.custom_designs,
};
