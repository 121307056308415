import React, {lazy, Suspense, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Icon from '@ant-design/icons';

const CreditCard = (props) => {
    const [icon, setIcon] = useState(null);

    const styles = {
        width: props.size + 'rem',
        fontSize: props.size + 'rem',
        ...props.style,
    };

    useEffect(() => {
        const icon = lazy(() =>
            import(`./creditCard.${props.card}.js`)
                .then((res) => res)
                .catch(() => import(`components/UI/CreditCard/empty`)),
        );
        setIcon(icon);
    }, [props.card]);

    if (icon === null) return '';

    return (
        <Suspense fallback={''}>
            <Icon component={icon} style={styles} className="credit-card-icon" {...props} />
        </Suspense>
    );
};

CreditCard.defaultProps = {
    size: 1,
};

CreditCard.propTypes = {
    card: PropTypes.string.isRequired,
    size: PropTypes.number,
    style: PropTypes.any,
};

export default CreditCard;
