import React from 'react';

const icon = () => (
    <svg viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <circle fill="#F0F0F0" cx="256" cy="256" r="256"></circle>
                <path d="M512,256 C512,397.384 397.384,512 256,512 C114.616,512 0,397.384 0,256" fill="#D80027"></path>
            </g>
        </g>
    </svg>
);

export default icon;
