import savedMessagesActions from 'redux/savedMessages/actions';

const initialState = {
  data: null,
  loading: false,
  saved: false,
  deleted: false,
  error: null,
};

const savedMessages = (state = initialState, action) => {
  switch (action.type) {
    case savedMessagesActions.FETCH_SAVED_MESSAGES_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case savedMessagesActions.FETCH_SAVED_MESSAGES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case savedMessagesActions.FETCH_SAVED_MESSAGES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case savedMessagesActions.SAVE_SAVED_MESSAGES_START:
      return {
        ...state,
        saved: false,
        error: null,
      };
    case savedMessagesActions.SAVE_SAVED_MESSAGES_SUCCESS:
      return {
        ...state,
        saved: true,
        error: null,
      };
    case savedMessagesActions.SAVE_SAVED_MESSAGES_ERROR:
      return {
        ...state,
        saved: false,
        error: action.payload,
      };

    case savedMessagesActions.DELETE_SAVED_MESSAGES_START:
      return {
        ...state,
        deleted: false,
        error: null,
      };
    case savedMessagesActions.DELETE_SAVED_MESSAGES_SUCCESS:
      return {
        ...state,
        deleted: true,
        error: null,
      };
    case savedMessagesActions.DELETE_SAVED_MESSAGES_ERROR:
      return {
        ...state,
        deleted: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default savedMessages;
