import {all, put, takeEvery} from 'redux-saga/effects';
import signupActions from './actions';
import {api} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';

function* saveSignUpData(e) {
    const payload = yield api.post(apiEndpoints.signUp.post, e.payload.data);

    if (payload) {
        const redirect = payload.redirect;
        delete payload.redirect;

        yield put({
            type: signupActions.SAVE_SIGNUP_DATA_SUCCESS,
            payload,
        });

        yield put(redirect);
    }
}

export default function* signupSaga() {
    yield all([takeEvery(signupActions.SAVE_SIGNUP_DATA_START, saveSignUpData)]);
}
