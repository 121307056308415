import {lazy} from 'react';
import {lang} from 'config/lang';
import {privatePaths} from 'config/routes/private/paths';

const GiftogramDetail = lazy(() => import('containers/CreateDetail'));
const Designer = lazy(() => import('containers/Create/modules/ProductDesigner'));
const CampaignManager = lazy(() => import('containers/CampaignsManagement'));

export const privateContainers = [
    {
        name: 'importContactsCallback',
        exact: true,
        path: privatePaths.IMPORT_CONTACT_CALLBACK,
        component: lazy(() => import('containers/RecipientIntegrations/Integration.Callback')),
        title: true,
    },
    {
        name: 'importContactsCallback',
        exact: true,
        path: privatePaths.IMPORT_CONTACT_CALLBACK,
        component: lazy(() => import('containers/RecipientIntegrations/Integration.Callback')),
        title: true,
    },
    {
        name: 'integrationCallback',
        exact: true,
        path: privatePaths.INTEGRATION_CALLBACK,
        component: lazy(() => import('containers/RecipientIntegrations/Integration.Callback.Auth')),
        title: true,
    },
    {
        name: 'integrationSync',
        exact: true,
        path: privatePaths.INTEGRATIONS_SYNC_JOBS,
        component: lazy(() => import('containers/RecipientSyncJobs/IntegrationSyncJobs')),
        title: true,
    },
    {
        name: 'integrationSyncCreate',
        exact: true,
        path: privatePaths.INTEGRATIONS_CREATE_SYNC_JOB,
        component: lazy(() => import('containers/RecipientSyncJobEdit/IntegrationSyncEditJob')),
        title: true,
    },
    {
        name: 'integrationSyncEdit',
        exact: true,
        path: privatePaths.INTEGRATIONS_SYNC_JOB_EDIT,
        component: lazy(() => import('containers/RecipientSyncJobEdit/IntegrationSyncEditJob')),
        title: true,
    },
    {
        name: 'integrationSyncDetails',
        exact: true,
        path: privatePaths.INTEGRATIONS_SYNC_JOB_DETAILS,
        component: lazy(() => import('containers/RecipientSyncJobDetails/IntegrationSyncDetails')),
        title: true,
    },
    {
        name: 'finishCompanySignup',
        exact: true,
        path: privatePaths.FINISH_COMPANY_SIGNUP,
        component: lazy(() => import('containers/Auth/SignUp/SignUp.CompanyStep')),
        title: lang.page_title.sign_up_company_detail,
    },
    {
        name: 'notifications',
        exact: true,
        path: privatePaths.NOTIFICATIONS,
        component: lazy(() => import('containers/Notifications')),
        title: true,
    },
    {
        name: 'dashboard',
        exact: true,
        path: privatePaths.DASHBOARD,
        component: lazy(() => import('containers/Dashboard/Dashboard')),
        title: true,
    },
    {
        name: 'send',
        exact: true,
        path: privatePaths.SEND,
        component: lazy(() => import('containers/Send')),
        title: lang.page_title.send,
    },
    {
        name: 'sendSuccess',
        exact: true,
        path: privatePaths.SEND_SUCCESS,
        component: lazy(() => import('containers/SendSuccess/Success')),
        title: lang.page_title.send_success,
    },
    {
        name: 'createDetail',
        exact: true,
        path: privatePaths.CREATE_DETAIL,
        component: GiftogramDetail,
        props: {isCustom: false},
        title: lang.page_title.create_send,
    },
    {
        name: 'createDetail',
        exact: true,
        path: privatePaths.CUSTOM_DETAIL,
        component: GiftogramDetail,
        props: {isCustom: true},
        title: lang.page_title.create_send,
    },
    {
        name: 'create',
        exact: true,
        path: privatePaths.CREATE,
        component: lazy(() => import('containers/Create')),
        title: lang.page_title.create_send,
    },
    {
        name: 'designer',
        exact: true,
        path: privatePaths.CREATE_DESIGNER,
        component: Designer,
        title: lang.page_title.customize,
    },
    {
        name: 'designerCustom',
        exact: true,
        path: privatePaths.CREATE_DESIGNER_CUSTOM,
        component: Designer,
        title: lang.page_title.customize,
    },
    {
        name: 'checkout',
        exact: true,
        path: privatePaths.CHECKOUT,
        component: lazy(() => import('containers/Checkout/Checkout')),
        title: true,
    },
    {
        name: 'checkoutSuccess',
        exact: true,
        path: privatePaths.CHECKOUT_SUCCESS,
        component: lazy(() => import('containers/CheckoutSuccess/Success')),
        title: lang.page_title.checkout_success,
    },
    {
        name: 'recipients',
        exact: true,
        path: privatePaths.RECIPIENTS,
        component: lazy(() => import('containers/Recipients/Recipients')),
        title: true,
    },
    {
        name: 'recipientsImport',
        exact: true,
        path: privatePaths.RECIPIENT_IMPORT,
        component: lazy(() => import('containers/RecipientImport')),
        title: lang.page_title.import_recipients,
    },
    {
        name: 'recipientDetails',
        exact: true,
        path: privatePaths.RECIPIENT_DETAILS,
        component: lazy(() => import('containers/RecipientDetails/Recipient.Details')),
    },
    {
        name: 'campaigns',
        exact: true,
        path: privatePaths.CAMPAIGNS,
        component: lazy(() => import('containers/Campaigns/Campaigns')),
        title: true,
    },
    {
        name: 'campaignsDetail',
        exact: true,
        path: privatePaths.CAMPAIGN_DETAIL,
        component: lazy(() => import('containers/CampaignDetails/CampaignDetail')),
    },
    {
        name: 'campaignManagement',
        path: privatePaths.CREATE_CAMPAIGN,
        component: CampaignManager,
        exact: true,
        title: lang.page_title.campaign_create,
    },
    {
        name: 'campaignManagement',
        path: privatePaths.UPDATE_CAMPAIGN,
        component: CampaignManager,
        exact: true,
        title: lang.page_title.campaign_edit,
    },
    {
        name: 'groups',
        exact: true,
        path: privatePaths.GROUPS,
        component: lazy(() => import('containers/Groups/Groups')),
        title: true,
    },

    {
        name: 'groupDetails',
        exact: true,
        path: privatePaths.GROUP_DETAIL,
        component: lazy(() => import('containers/Recipients/Recipients')),
    },
    {
        name: 'settings',
        exact: true,
        path: privatePaths.SETTINGS,
        component: lazy(() => import('containers/Settings/Settings')),
        title: lang.page_title.settings,
    },
    {
        name: 'preferences',
        exact: true,
        path: privatePaths.SETTINGS_DETAIL,
        component: lazy(() => import('containers/Settings/Settings')),
        title: lang.page_title.settings,
    },
    {
        name: 'orders',
        exact: true,
        path: privatePaths.ORDERS_APPROVALS,
        component: lazy(() => import('containers/OrderHistory/modules/ApprovalOrders')),
        title: lang.orders.optional.title.pending_approval,
    },
    {
        name: 'orders',
        exact: true,
        path: privatePaths.ORDERS_DIGITAL,
        component: lazy(() => import('containers/OrderHistory/modules/DigitalOrders')),
        title: lang.page_title.order_history,
    },
    {
        name: 'orders',
        exact: true,
        path: privatePaths.ORDERS_PHYSICAL,
        component: lazy(() => import('containers/OrderHistory/modules/PhysicalOrders')),
        title: lang.page_title.order_history,
    },
    {
        name: 'ordersPhysical',
        exact: true,
        path: privatePaths.ORDERS_DETAIL_PHYSICAL,
        component: lazy(() => import('containers/OrderDetail/PhysicalDetail')),
        // getter is necessary to rehydrate
        get title() {
            const splitPath = window.location.pathname.split('/');

            return `Order #${splitPath[splitPath.length - 1]} - ${lang['giftogram']}`;
        },
    },
    {
        name: 'ordersDigital',
        exact: true,
        path: privatePaths.ORDERS_DETAIL_DIGITAL,
        component: lazy(() => import('containers/OrderDetail/DigitalDetail')),
    },
    {
        name: 'ordersActivation',
        exact: true,
        path: privatePaths.ORDERS_ACTIVATION_PHYSICAL,
        component: lazy(() => import('containers/OrderActivation/ItemActivationDetail')),
        title: lang.page_title.manage_activation,
    },
    {
        name: 'balanceHistory',
        exact: true,
        path: privatePaths.BALANCE_HISTORY,
        component: lazy(() => import('containers/BalanceHistory/BalanceHistory')),
        title: true,
    },
    {
        name: 'transferFunds',
        exact: true,
        path: privatePaths.TRANSFER_FUNDS,
        component: lazy(() => import('containers/TransferFunds/TransferFunds')),
        title: true,
    },
    {
        name: 'depositFunds',
        exact: true,
        path: privatePaths.DEPOSIT_FUNDS,
        component: lazy(() => import('containers/DepositFunds')),
        title: true,
    },
    {
        name: 'requestFunds',
        exact: true,
        path: privatePaths.REQUEST_FUNDS,
        component: lazy(() => import('containers/RequestFunds/RequestFunds')),
        title: true,
    },
    {
        name: 'fundingDetails',
        exact: true,
        path: privatePaths.BALANCE_DETAIL,
        component: lazy(() => import('containers/BalanceDetails/BalanceDetails')),
    },
    {
        name: 'reports',
        exact: true,
        path: privatePaths.EXPORTS,
        component: lazy(() => import('containers/Exports/Exports')),
        title: lang.page_title.exports,
    },
    {
        name: 'reportsDownload',
        exact: true,
        path: privatePaths.EXPORTS_DOWNLOAD,
        component: lazy(() => import('containers/Exports/Exports')),
        title: lang.page_title.exports,
    },
    {
        name: 'loginAs',
        exact: true,
        path: privatePaths.LOGIN_AS,
        component: lazy(() => import('containers/LoginAs/LoginAs')),
    },
    {
        name: 'email-preview',
        exact: true,
        path: privatePaths.BRAND_KIT.EMAIL_PREVIEW,
        props: {popup: true},
        component: lazy(() => import('containers/EmailPreview')),
    },
];
