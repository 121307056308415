const recipientGroupActions = {
  SET_RECIPIENT_GROUP_DATA_START: 'SET_RECIPIENT_GROUP_DATA_START',
  SET_RECIPIENT_GROUP_DATA_SUCCESS: 'SET_RECIPIENT_GROUP_DATA_SUCCESS',
  SET_RECIPIENT_GROUP_DATA_FAILURE: 'SET_RECIPIENT_GROUP_DATA_FAILURE',
  setRecipientGroupStart: (payload) => ({
    type: recipientGroupActions.SET_RECIPIENT_GROUP_DATA_START,
    payload,
  }),

  setRecipientGroupSuccess: () => {
    return {
      type: recipientGroupActions.SET_RECIPIENT_GROUP_DATA_SUCCESS,
    }
  },

  setRecipientGroupFailure: (error) => ({
    type: recipientGroupActions.SET_RECIPIENT_GROUP_DATA_FAILURE,
    payload: error,
  }),

  UPDATE_RECIPIENT_GROUP_DATA_START: 'UPDATE_RECIPIENT_GROUP_DATA_START',
  UPDATE_RECIPIENT_GROUP_DATA_SUCCESS: 'UPDATE_RECIPIENT_GROUP_DATA_SUCCESS',
  UPDATE_RECIPIENT_GROUP_DATA_FAILURE: 'UPDATE_RECIPIENT_GROUP_DATA_FAILURE',
  updateRecipientGroupStart: (payload) => ({
    type: recipientGroupActions.UPDATE_RECIPIENT_GROUP_DATA_START,
    payload,
  }),

  updateRecipientGroupSuccess: (payload) => {
    return {
      type: recipientGroupActions.UPDATE_RECIPIENT_GROUP_DATA_SUCCESS,
      payload,
    }
  },

  updateRecipientGroupFailure: (error) => ({
    type: recipientGroupActions.UPDATE_RECIPIENT_GROUP_DATA_FAILURE,
    payload: error,
  }),
}

export default recipientGroupActions
