const shippingAddressActions = {
  FETCH_SHIPPING_ADDRESSES_START: 'FETCH_SHIPPING_ADDRESSES_START',
  FETCH_SHIPPING_ADDRESSES_SUCCESS: 'FETCH_SHIPPING_ADDRESSES_SUCCESS',
  FETCH_SHIPPING_ADDRESSES_ERROR: 'FETCH_SHIPPING_ADDRESSES_ERROR',
  fetchShippingAddressesStart: () => ({
    type: shippingAddressActions.FETCH_SHIPPING_ADDRESSES_START,
  }),
  fetchShippingAddressesSuccess: (payload) => {
    return {
      type: shippingAddressActions.FETCH_SHIPPING_ADDRESSES_SUCCESS,
      payload,
    }
  },
  fetchShippingAddressesError: (error) => ({
    type: shippingAddressActions.FETCH_SHIPPING_ADDRESSES_ERROR,
    payload: error,
  }),

  GEOCODE_SHIPPING_ADDRESSES_START: 'GEOCODE_SHIPPING_ADDRESSES_START',
  GEOCODE_SHIPPING_ADDRESSES_SUCCESS: 'GEOCODE_SHIPPING_ADDRESSES_SUCCESS',
  GEOCODE_SHIPPING_ADDRESSES_ERROR: 'GEOCODE_SHIPPING_ADDRESSES_ERROR',
  GEOCODE_SHIPPING_ADDRESSES_RESET: 'GEOCODE_SHIPPING_ADDRESSES_RESET',

  geocodeShippingAddressesStart: (payload) => {
    return {
      type: shippingAddressActions.GEOCODE_SHIPPING_ADDRESSES_START,
      payload,
    }
  },
  geocodeShippingAddressesSuccess: (payload) => {
    return {
      type: shippingAddressActions.GEOCODE_SHIPPING_ADDRESSES_SUCCESS,
      payload,
    }
  },
  geocodeShippingAddressesError: (error) => ({
    type: shippingAddressActions.GEOCODE_SHIPPING_ADDRESSES_ERROR,
    payload: error,
  }),

  geocodeShippingAddressesReset: () => ({
    type: shippingAddressActions.GEOCODE_SHIPPING_ADDRESSES_RESET,
  }),

  SAVE_SHIPPING_ADDRESSES_START: 'SAVE_SHIPPING_ADDRESSES_START',
  SAVE_SHIPPING_ADDRESSES_SUCCESS: 'SAVE_SHIPPING_ADDRESSES_SUCCESS',
  SAVE_SHIPPING_ADDRESSES_ERROR: 'SAVE_SHIPPING_ADDRESSES_ERROR',

  saveShippingAddressesStart: (payload) => {
    return {
      type: shippingAddressActions.SAVE_SHIPPING_ADDRESSES_START,
      payload,
    }
  },
  saveShippingAddressesSuccess: (payload) => {
    return {
      type: shippingAddressActions.SAVE_SHIPPING_ADDRESSES_SUCCESS,
      payload,
    }
  },
  saveShippingAddressesError: (error) => ({
    type: shippingAddressActions.SAVE_SHIPPING_ADDRESSES_ERROR,
    payload: error,
  }),

  UPDATE_SHIPPING_ADDRESSES_START: 'UPDATE_SHIPPING_ADDRESSES_START',
  UPDATE_SHIPPING_ADDRESSES_SUCCESS: 'UPDATE_SHIPPING_ADDRESSES_SUCCESS',
  UPDATE_SHIPPING_ADDRESSES_ERROR: 'UPDATE_SHIPPING_ADDRESSES_ERROR',

  updateShippingAddressesStart: (payload) => {
    return {
      type: shippingAddressActions.UPDATE_SHIPPING_ADDRESSES_START,
      payload,
    }
  },
  updateShippingAddressesSuccess: (payload) => {
    return {
      type: shippingAddressActions.UPDATE_SHIPPING_ADDRESSES_SUCCESS,
      payload,
    }
  },
  updateShippingAddressesError: (error) => ({
    type: shippingAddressActions.UPDATE_SHIPPING_ADDRESSES_ERROR,
    payload: error,
  }),

  DEFAULT_SHIPPING_ADDRESSES_START: 'DEFAULT_SHIPPING_ADDRESSES_START',
  DEFAULT_SHIPPING_ADDRESSES_SUCCESS: 'DEFAULT_SHIPPING_ADDRESSES_SUCCESS',
  DEFAULT_SHIPPING_ADDRESSES_ERROR: 'DEFAULT_SHIPPING_ADDRESSES_ERROR',

  defaultShippingAddressesStart: (payload) => {
    return {
      type: shippingAddressActions.DEFAULT_SHIPPING_ADDRESSES_START,
      payload,
    }
  },
  defaultShippingAddressesSuccess: (payload) => {
    return {
      type: shippingAddressActions.DEFAULT_SHIPPING_ADDRESSES_SUCCESS,
      payload,
    }
  },
  defaultShippingAddressesError: (error) => ({
    type: shippingAddressActions.DEFAULT_SHIPPING_ADDRESSES_ERROR,
    payload: error,
  }),

  DELETE_SHIPPING_ADDRESSES_START: 'DELETE_SHIPPING_ADDRESSES_START',
  DELETE_SHIPPING_ADDRESSES_SUCCESS: 'DELETE_SHIPPING_ADDRESSES_SUCCESS',
  DELETE_SHIPPING_ADDRESSES_ERROR: 'DELETE_SHIPPING_ADDRESSES_ERROR',

  deleteShippingAddressesStart: (payload) => {
    return {
      type: shippingAddressActions.DELETE_SHIPPING_ADDRESSES_START,
      payload,
    }
  },
  deleteShippingAddressesSuccess: (payload) => {
    return {
      type: shippingAddressActions.DELETE_SHIPPING_ADDRESSES_SUCCESS,
      payload,
    }
  },
  deleteShippingAddressesError: (error) => ({
    type: shippingAddressActions.DELETE_SHIPPING_ADDRESSES_ERROR,
    payload: error,
  }),
}

export default shippingAddressActions
