import React from 'react';

const icon = () => (
    <svg viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <circle fill="#F0F0F0" cx="256" cy="256" r="256"></circle>
                <g transform="translate(2.167000, 0.000000)" fill="#D80027">
                    <path d="M198.181,222.609 L507.666,222.609 C491.303,97.002 383.9,0 253.833,0 C234.718,0 216.101,2.113 198.181,6.085 L198.181,222.609 L198.181,222.609 Z"></path>
                    <path d="M131.398,222.609 L131.398,31.127 C61.105,69.481 10.783,139.832 0,222.609 L131.398,222.609 L131.398,222.609 Z"></path>
                    <path d="M131.398,289.391 L0,289.391 C10.783,372.168 61.105,442.519 131.398,480.872 L131.398,289.391 Z"></path>
                    <path d="M198.181,289.391 L198.181,505.915 C216.101,509.887 234.718,512 253.833,512 C383.9,512 491.303,414.998 507.666,289.391 L198.181,289.391 L198.181,289.391 Z"></path>
                </g>
            </g>
        </g>
    </svg>
);

export default icon;
