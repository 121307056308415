import React, {useState} from 'react';
import {MainNavMenu} from 'components/Layout';
import {iconStyle} from 'assets/styles/common';
import {FontAwesome, Col, Row, Drawer, Button} from 'components/UI';

const MainNavMobile = () => {
    const [mobileDrawer, setMobileDrawer] = useState(false);

    return (
        <Row>
            <Col flex="auto">
                <Button type="text" onClick={() => setMobileDrawer(true)}>
                    <FontAwesome type={iconStyle} color="white" size="lg" icon="bars" />
                </Button>
            </Col>

            <Drawer
                className="drawer-dark"
                open={mobileDrawer}
                placement="left"
                onClose={() => setMobileDrawer(false)}
                closable={false}
                footer={
                    <Button type="text" icon="arrow-left-to-line" onClick={() => setMobileDrawer(false)}>
                        Close Menu
                    </Button>
                }
            >
                <MainNavMenu mode="inline" />
            </Drawer>
        </Row>
    );
};

export default MainNavMobile;
