import {all, takeEvery, takeLeading, put} from 'redux-saga/effects';
import {buildQuery} from 'library/helpers/routing';
import {apiEndpoints} from 'config/api';
import {api} from 'library/helpers/api';
import notificationsActions from './actions';

function* fetchNotifications(query) {
    return yield api
        .get(`${apiEndpoints.broadcasting.notifications.search}?${query}`)
        .then((response) => response.data);
}

function* fetchNotificationsTypes() {
    return yield api.get(`${apiEndpoints.broadcasting.notifications.groups}`).then(({data}) => data);
}

function* fetchNotificationsCount() {
    return yield api
        .get(`${apiEndpoints.broadcasting.notifications.count}`)
        .then(({data}) => data)
        .then((data) => data.unread);
}

function* fetchNotificationsData({payload}) {
    try {
        const res = yield fetchNotifications(buildQuery(payload));

        yield put(notificationsActions.fetchNotificationsSuccess(res));
    } catch (error) {
        yield put(notificationsActions.fetchNotificationsError(error));
    }
}

function* fetchNotificationsMetadata() {
    try {
        yield put(notificationsActions.fetchNotificationsTypesSuccess(yield fetchNotificationsTypes()));
        yield put(notificationsActions.fetchNotificationsCountSuccess(yield fetchNotificationsCount()));
        yield put(notificationsActions.fetchNotificationsMetadataSuccess());
    } catch (error) {
        yield put(notificationsActions.fetchNotificationsMetadataError(error));
    }
}

function* markNotifications({payload}) {
    const {items, status, unmark} = payload;

    try {
        yield api.put(`${apiEndpoints.api}${apiEndpoints.broadcasting.notifications.mark}`, {
            items: items.map((item) => item.id),
            mark: status,
            unmark,
        });

        yield put(notificationsActions.markNotificationsSuccess(payload));
        yield put(notificationsActions.fetchNotificationsCountSuccess(yield fetchNotificationsCount()));
    } catch (error) {
        yield put(notificationsActions.markNotificationsError(error));
    }
}

export default function* notificationsSaga() {
    yield all([
        takeLeading(notificationsActions.FETCH_NOTIFICATIONS_DATA_START, fetchNotificationsData),
        takeEvery(notificationsActions.FETCH_NOTIFICATIONS_METADATA_START, fetchNotificationsMetadata),
        takeEvery(notificationsActions.MARK_NOTIFICATIONS_START, markNotifications),
    ]);
}
