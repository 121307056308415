import {connectRouter} from 'connected-react-router';
import {persistReducer} from 'redux-persist';
import modals from 'redux/modals/reducer';
import adjustments from 'redux/adjustments/reducer';
import app from 'redux/app/reducer';
import auth from 'redux/auth/auth.reducer';
import authentication from 'redux/authentication/reducer';
import bulkOrder from 'redux/bulkOrder/reducer';
import upload from 'redux/upload/reducer';
import businessHours from 'redux/businessHours/reducer';
import campaign from 'redux/campaign/reducer';
import campaignCreditCards from 'redux/campaignCreditCards/reducer';
import campaignTypes from 'redux/campaignTypes/reducer';
import campaigns from 'redux/campaigns/reducer';
import checkout from 'redux/checkout/reducer';
import checkoutLookup from 'redux/checkoutLookup/reducer';
import companies from 'redux/company/reducer';
import contactProperties from 'redux/contactProperties/reducer';
import {default as coupons} from 'redux/coupon/reducer';
import creditCards from 'redux/creditCards/reducer';
import descendants from 'redux/descendants/reducer';
import forms from 'redux/forms/reducer';
import funding from 'redux/funding/reducer';
import fundingItem from 'redux/fundingItem/reducer';
import fundingAmount from 'redux/fundingAmount/reducer';
import fundingMethods from 'redux/fundingMethods/reducer';
import groups from 'redux/groups/reducer';
import invite from 'redux/invite/reducer';
import historyReducer from 'redux/history/reducer';
import notifications from 'redux/notifications/reducer';
import order from 'redux/order/reducer';
import orderSummary from 'redux/orderSummary/reducer';
import orders from 'redux/orders/reducer';
import products from 'redux/products/reducer';
import procurement from 'redux/procurement/reducer';
import primaryProducts from 'redux/primaryProducts/reducer';
import profile from 'redux/profile/reducer';
import pusher from 'redux/pusher/reducer';
import recentOrders from 'redux/recentOrders/reducer';
import recipient from 'redux/recipient/reducer';
import recipientCampaigns from 'redux/recipientCampaigns/reducer';
import recipientGroups from 'redux/recipientGroups/reducer';
import recipientUploads from 'redux/recipientsUpload/reducer';
import recipients from 'redux/recipients/reducer';
import regions from 'redux/regions/reducer';
import savedDesigns from 'redux/savedDesigns/reducer';
import savedDesignsCampaign from 'redux/savedDesignsCampaign/reducer';
import savedMessages from 'redux/savedMessages/reducer';
import senderNames from 'redux/senderNames/reducer';
import shippingAddress from 'redux/shippingAddress/reducer';
import shippingAddressCampaign from 'redux/shippingAddressCampaign/reducer';
import shippingRates from 'redux/shippingRates/reducer';
import send from 'redux/send/reducer';
import signup from 'redux/signup/reducer';
import siteData from 'redux/siteData/reducer';
import team from 'redux/team/reducer';
import teams from 'redux/teams/reducer';
import integrations from 'redux/integrations/reducer';
import fundingTransfer from 'redux/fundingTransfer/reducer';
import brandKits from 'redux/brandKits/reducer';
import {formsPersistConfig} from 'redux/forms/config';

const customCombineReducers = (reducers) => {
    return (state = {}, action) => {
        const nextState = {};

        for (const key in reducers) {
            const reducer = reducers[key];

            nextState[key] = reducer(state[key], action, state);
        }

        return nextState;
    };
};

const createRootReducer = (history) =>
    customCombineReducers({
        integrations,
        adjustments,
        app,
        auth,
        authentication,
        brandKits,
        bulkOrder,
        businessHours,
        campaign,
        campaigns,
        campaignCreditCards,
        campaignTypes,
        checkout,
        checkoutLookup,
        companies,
        channels: pusher,
        contactProperties,
        coupons,
        creditCards,
        descendants,
        forms: persistReducer(formsPersistConfig, forms),
        funding,
        fundingItem,
        fundingAmount,
        fundingMethods,
        fundingTransfer,
        groups,
        history: historyReducer,
        invite,
        modals,
        notifications,
        order,
        orders,
        orderSummary,
        products,
        procurement,
        primaryProducts,
        profile,
        recentOrders,
        recipients,
        recipient,
        recipientGroups,
        recipientCampaigns,
        recipientUploads,
        regions,
        savedDesigns,
        savedDesignsCampaign,
        savedMessages,
        send,
        senderNames,
        shippingAddress,
        shippingAddressCampaign,
        siteData,
        shippingRates,
        signup,
        team,
        teams,
        upload,
        router: (state, action) => connectRouter(history)(state, action, state),
    });

export default createRootReducer;
