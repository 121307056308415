import {all, takeEvery, put} from 'redux-saga/effects';
import savedMessagesActions from 'redux/savedMessages/actions';
import {api} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';
import {getRoute} from 'library/helpers/routing';

function* fetchSavedMessages() {
    const messages = yield api.get(apiEndpoints.savedMessages.get);

    if (messages.status !== 200) return yield put(savedMessagesActions.fetchError(messages.error));

    yield put(savedMessagesActions.fetchSuccess(messages.data));
}

function* saveSavedMessage({payload}) {
    try {
        payload.id
            ? yield api.put(getRoute(apiEndpoints.savedMessages.update, {id: payload.id}), payload)
            : yield api.post(apiEndpoints.savedMessages.create, payload);
        yield put(savedMessagesActions.saveSuccess());
        yield put(savedMessagesActions.fetchStart());
    } catch (error) {
        yield put(savedMessagesActions.saveError(error));
    }
}

function* deleteSavedMessage({payload}) {
    try {
        yield api.delete(getRoute(apiEndpoints.savedMessages.delete, {id: payload}));
        yield put(savedMessagesActions.deleteSuccess());
        yield put(savedMessagesActions.fetchStart());
    } catch (error) {
        yield put(savedMessagesActions.deleteError(error));
    }
}

export default function* savedMessagesSaga() {
    yield all([
        takeEvery(savedMessagesActions.FETCH_SAVED_MESSAGES_START, fetchSavedMessages),
        takeEvery(savedMessagesActions.SAVE_SAVED_MESSAGES_START, saveSavedMessage),
        takeEvery(savedMessagesActions.DELETE_SAVED_MESSAGES_START, deleteSavedMessage),
    ]);
}
