import React from 'react';

const icon = () => (
    <svg viewBox="0 0 512 513" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(0.000000, 0.500500)" fillRule="nonzero">
                <g>
                    <path
                        d="M512,256.249507 C512,397.495709 397.384,512 256,512 C114.616,512 0,397.495709 0,256.249507 C0,256.309448 256,0.527984905 256,0.49901317 C397.384,0.49901317 512,115.003304 512,256.249507 Z"
                        fill="#0052B4"
                    ></path>
                    <g fill="#F0F0F0">
                        <path d="M256,0.49901317 C255.986,0.49901317 255.971,0.500012195 255.957,0.500012195 L256,0.49901317 Z"></path>
                        <path d="M255.315,256.249507 L256,256.249507 C256,256.017733 256,255.795949 256,255.565174 C255.772,255.793951 255.544,256.021729 255.315,256.249507 Z"></path>
                        <path d="M256.001,133.934835 C256.001,88.9337375 256.001,59.4455058 256.001,0.500012195 L255.957,0.500012195 C114.592,0.522989779 0,115.016292 0,256.249507 L133.565,256.249507 L133.565,181.110811 L208.777,256.249507 L255.316,256.249507 C255.545,256.021729 255.773,255.793951 256.001,255.565174 C256.001,238.334984 256.001,222.960982 256.001,209.07453 L180.788,133.934835 L256.001,133.934835 Z"></path>
                    </g>
                    <g transform="translate(33.391000, 33.857469)" fill="#D80027">
                        <path d="M96.124,7.09850216e-15 C56.085,22.7767793 22.799,56.0303376 2.84217094e-14,96.0303142 L2.84217094e-14,222.392037 L66.783,222.392037 L66.783,66.71891 L66.783,66.7179109 L222.609,66.7179109 C222.609,45.6754397 222.609,25.6289967 222.609,7.09850216e-15 L96.124,7.09850216e-15 Z"></path>
                        <path d="M222.609,190.94172 L131.656,100.077921 L100.175,100.077921 C100.175,100.077921 100.175,100.077921 100.175,100.077921 L222.609,222.392037 L222.609,222.392037 C222.609,222.392037 222.609,200.708192 222.609,190.94172 Z"></path>
                    </g>
                    <g transform="translate(97.312000, 111.694532)" fill="#F0F0F0">
                        <polygon points="57.083 189.033581 71.133 218.382949 102.86 211.057096 88.652 240.330537 114.166 260.543817 82.399 267.696839 82.488 300.229101 57.083 279.875957 31.679 300.229101 31.768 267.696839 -2.84217094e-14 260.543817 25.515 240.330537 11.305 211.057096 43.034 218.382949"></polygon>
                        <polygon points="285.972 244.631341 292.997 259.306025 308.861 255.642599 301.756 270.279319 314.514 280.386459 298.63 283.96297 298.674 300.229101 285.972 290.052029 273.27 300.229101 273.314 283.96297 257.431 280.386459 270.188 270.279319 263.084 255.642599 278.947 259.306025"></polygon>
                        <polygon points="220.621 88.9572146 227.645 103.632897 243.509 99.9684722 236.405 114.605193 249.162 124.712332 233.279 128.288843 233.322 144.554974 220.621 134.377903 207.919 144.554974 207.962 128.288843 192.079 124.712332 204.836 114.605193 197.732 99.9684722 213.596 103.632897"></polygon>
                        <polygon points="285.972 2.83940086e-14 292.997 14.6756826 308.861 11.0122566 301.757 25.6489772 314.513 35.7551178 298.63 39.3326276 298.674 55.5987586 285.972 45.4216872 273.27 55.5987586 273.314 39.3326276 257.431 35.7551178 270.187 25.6489772 263.084 11.0122566 278.947 14.6756826"></polygon>
                        <polygon points="343.056 66.7179109 350.08 81.3935936 365.944 77.7291685 358.84 92.3658891 371.597 102.473029 355.713 106.050539 355.757 122.315671 343.056 112.139598 330.354 122.315671 330.397 106.050539 314.514 102.473029 327.271 92.3658891 320.167 77.7291685 336.03 81.3935936"></polygon>
                        <polygon points="302.238 144.554974 307.763 161.5444 325.645 161.5444 311.178 172.045155 316.705 189.033581 302.238 178.533825 287.772 189.033581 293.297 172.045155 278.831 161.5444 296.712 161.5444"></polygon>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default icon;
