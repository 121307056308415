import teamsActions from './actions';

import {LOGOUT} from 'redux/auth/auth.types';

const initialState = {
    data: null,
    descendants: {
        data: null,
        loading: false,
    },
    error: null,
    current: null,
    loading: false,
    loaded: false,
    saving: false,
    saved: false,
    deleted: false,
};

const teamsReducer = (state = initialState, action) => {
    switch (action.type) {
        case teamsActions.FETCH_TEAM_DATA_START:
            return {
                ...state,
                loading: true,
                error: null,
                loaded: false,
            };
        case teamsActions.FETCH_TEAM_DATA_SUCCESS:
            return {
                ...state,
                current: action.payload,
                loading: false,
                error: null,
                loaded: true,
            };
        case teamsActions.FETCH_TEAM_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                loaded: false,
            };

        case teamsActions.FETCH_TEAMS_DATA_START:
            return {
                ...state,
                loading: true,
                error: null,
                loaded: false,
            };
        case teamsActions.FETCH_TEAMS_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null,
                loaded: true,
            };
        case teamsActions.FETCH_TEAMS_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                loaded: false,
            };

        case teamsActions.SAVE_TEAMS_START:
        case teamsActions.UPDATE_TEAMS_START:
        case teamsActions.CLONE_TEAMS_START:
            return {
                ...state,
                saving: true,
                saved: false,
                error: null,
            };
        case teamsActions.SAVE_TEAMS_SUCCESS:
        case teamsActions.UPDATE_TEAMS_SUCCESS:
        case teamsActions.CLONE_TEAMS_SUCCESS:
            return {
                ...state,
                saving: false,
                saved: true,
                error: null,
            };
        case teamsActions.SAVE_TEAMS_FAILURE:
        case teamsActions.UPDATE_TEAMS_FAILURE:
        case teamsActions.CLONE_TEAMS_FAILURE:
            return {
                ...state,
                saving: false,
                saved: false,
                error: action.payload,
            };

        case teamsActions.DELETE_TEAMS_START:
            return {
                ...state,
                deleted: false,
                error: null,
            };
        case teamsActions.DELETE_TEAMS_SUCCESS:
            return {
                ...state,
                deleted: true,
                error: null,
            };
        case teamsActions.DELETE_TEAMS_FAILURE:
            return {
                ...state,
                deleted: false,
                error: action.payload,
            };

        case teamsActions.FETCH_DESCENDANTS_START:
            return {
                ...state,
                descendants: {
                    ...state.descendants,
                    loading: true,
                },
            };
        case teamsActions.FETCH_DESCENDANTS_SUCCESS:
            return {
                ...state,
                descendants: {
                    ...state.descendants,
                    data: action.payload,
                    loading: false,
                },
            };

        case teamsActions.RESET_TEAMS_ERROR:
            return {
                ...state,
                error: null,
            };

        case LOGOUT:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default teamsReducer;
