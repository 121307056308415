import {createSelector} from 'reselect';
const contactProperties = (state) => state.contactProperties;

export const getContactProperties = createSelector([contactProperties], (contactProperties) => contactProperties);
export const getContactPropertiesDateOptions = createSelector([contactProperties], (contactProperties) =>
    contactProperties.data
        .filter((item) => item.type === 'date')
        .map((date) => {
            return {value: date.id, label: date.name};
        }),
);
