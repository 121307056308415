import adjustmentsActions from './actions';

const initialState = {
    loading: false,
    loaded: false,
    error: null,
    data: null,
};

const adjustmentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case adjustmentsActions.FETCH_ADJUSTMENTS_START:
            return {
                ...state,
                data: null,
                loading: true,
                loaded: false,
                error: null,
            };

        case adjustmentsActions.FETCH_ADJUSTMENTS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                loaded: true,
                error: null,
            };

        case adjustmentsActions.FETCH_ADJUSTMENTS_FAILURE:
            return {
                ...state,
                data: null,
                loading: false,
                loaded: true,
                error: action.payload,
            };

        case adjustmentsActions.RESET_ADJUSTMENTS_START:
            return initialState;

        default:
            return state;
    }
};

export default adjustmentsReducer;
