import {all, put, takeEvery} from 'redux-saga/effects';
import {
    CREATE_SYNC_START,
    DELETE_SYNC_JOBS_START,
    IMPORT_CONTACTS_START,
    INITIALIZE_INTEGRATION_START,
    INTEGRATIONS_DATA_SYNC_COMPLETE,
    LOAD_SYNCS_START,
    LOAD_SYNC_START,
    RUN_INTEGRATION_FILTER_START,
    LOAD_USER_INTEGRATIONS_START,
    UPDATE_SYNC_JOB_START,
    UPDATE_SYNC_MAP_START,
    UPDATE_SYNC_NAME_START,
    UPDATE_SYNC_STATUS_START,
    createSyncComplete,
    deleteSyncJobsComplete,
    importContactsComplete,
    initializeIntegrationComplete,
    loadSyncComplete,
    loadSyncsComplete,
    loadSyncsStart,
    loadUserIntegrationsComplete,
    runIntegrationFilterCompleted,
    setCachingIntegration,
    setIntegrationError,
    setSavingIntegration,
    updateSyncJob,
    updateSyncJobComplete,
    updateSyncMapComplete,
    updateSyncNameComplete,
    updateSyncStatusComplete,
    LOAD_INSTALLED_INTEGRATIONS_START,
    loadInstalledIntegrationsComplete,
} from 'redux/integrations/actions';
import {integrationApiService} from 'library/helpers/api/services/integrations';
import {objectToQuerystring, scalarToArray} from 'library/helpers/utilities';
import groupsActions from 'redux/groups/actions';
import isEmpty from 'lodash/isEmpty';
import {apiEndpoints} from 'config/api';
import {api} from 'library/helpers/api';

export default function* integrationsSaga() {
    function* loadCompanyIntegrations() {
        try {
            const response = yield integrationApiService.loadCompanyIntegrations();
            const integrations = response.filter((i) => !!i?.integration);
            yield put(loadUserIntegrationsComplete(integrations));
        } catch (err) {
            yield error({company_integrations: err.message});
        }
    }

    /**
     * Initialize an integration
     * @param {Object<{id: string, querystring: string}>} payload
     * @return {Generator<*|Promise<Object|string>, void, *>}
     */
    function* initializeIntegration({payload}) {
        try {
            const {id, sync_id, ...rest} = payload;
            const response = yield integrationApiService.initializeIntegration({
                id,
                sync_id,
                querystring: objectToQuerystring(rest),
            });

            // if we are caching the integration we don't need to go any further
            if (!response?.cached) {
                yield put(setCachingIntegration(true));
                // yield put(loadSyncsStart()); // why is this here? let's investigate at some point
                return;
            }

            yield put(initializeIntegrationComplete(response));
        } catch ({message}) {
            yield error({initialize: message});
        }
    }

    function* integrationFilter({payload}) {
        try {
            const contacts = yield integrationApiService.runIntegrationFilter(payload);
            yield put(runIntegrationFilterCompleted(contacts));
        } catch ({message}) {
            yield error({filter: message});
        }
    }

    function* importContacts({payload}) {
        try {
            const response = yield integrationApiService.importContacts(payload);

            if (response?.error) {
                yield error();
            }

            yield put(importContactsComplete());
        } catch (err) {
            yield error(err);
        }
    }

    function* updateSyncName({payload}) {
        try {
            yield integrationApiService.updateSyncName(payload);
            yield put(groupsActions.listGroupsStart());
            yield put(loadSyncsStart());

            delete payload.sync_id;

            yield put(
                updateSyncJob({
                    ...payload,
                    group: {
                        name: payload.name,
                    },
                }),
            );

            yield put(updateSyncNameComplete());
        } catch ({message}) {
            yield error({sync_name: message});
        }
    }

    function* updateSyncStatus({payload}) {
        try {
            const {invalid_ids, updated_ids} = yield integrationApiService.updateSyncStatus(payload);
            yield put(updateSyncStatusComplete({state: payload.state, invalid_ids, updated_ids}));
        } catch ({message}) {
            yield error({active_status: message});
        }
    }

    function* updateSync({payload}) {
        try {
            const response = yield integrationApiService.updateSync(payload);
            yield put(updateSyncJobComplete(response));
            yield put(setSavingIntegration(false));
        } catch ({message}) {
            let errorMessage = message;

            if (errorMessage === 'no_map') {
                errorMessage = 'Please map all required fields, First Name, Last Name, and Email.';
            }

            yield error({update_sync: errorMessage});
        }
    }

    function* loadSyncs({payload}) {
        try {
            const querystring = !isEmpty(payload) ? '?' + objectToQuerystring(payload) : '';
            const response = yield integrationApiService.loadSyncs(querystring);

            yield put(loadSyncsComplete(response));
        } catch ({message}) {
            yield error({syncs_list: message});
        }
    }

    function* deleteSync({payload}) {
        try {
            yield integrationApiService.deleteSyncs(payload);
            yield put(deleteSyncJobsComplete(payload));
        } catch ({message}) {
            yield error({delete_sync: message});
        }
    }

    function* loadSync({payload}) {
        try {
            const response = yield integrationApiService.loadSync(payload);

            yield put(loadSyncComplete(response));
        } catch ({message}) {
            yield error({load_sync: message});
        }
    }

    /**
     * @param {Object<{key: string, value: string, fields: object}>} payload
     * @return {Generator<Generator<*, void, *>|*, void, *>}
     */
    function* updateSyncMap({payload}) {
        try {
            let {map, update} = payload;

            for (const updated of scalarToArray(update)) {
                const isProperty = !!Number(updated.key);
                const key = isProperty ? 'properties' : 'fields';

                const newValue = {[updated.value]: updated.key};

                // if the mapped key exists in fields delete it
                const deletable = updated.key === 'ignore' || update.key === updated.value;
                if (map.fields?.[updated.value] && deletable) {
                    delete map.fields[updated.value];
                }
                if (map.properties?.[updated.value] && deletable) {
                    delete map.properties[updated.value];
                }

                map = {
                    ...map,
                    [key]: {
                        ...map[key],
                        ...newValue,
                    },
                };
            }

            yield put(updateSyncMapComplete(map)); // Issue the map update at the end
        } catch ({message}) {
            // TODO: this is currently unused. We should probably use it.
            yield error({update_sync_map: message});
        }
    }

    function* createSync({payload}) {
        try {
            const response = yield integrationApiService.createSync(payload);
            yield put(createSyncComplete(response));
        } catch ({message}) {
            yield error({create_sync: message});
        }
    }

    // get installed integrations
    function* loadInstalledIntegrations() {
        try {
            const response = yield api.get(apiEndpoints.integrations.access.list);
            yield put(loadInstalledIntegrationsComplete(response));
        } catch ({message}) {
            yield error({load_installed_integrations: message});
        }
    }

    function* error(message) {
        yield put(setIntegrationError(message));
    }

    yield all([
        takeEvery(LOAD_USER_INTEGRATIONS_START, loadCompanyIntegrations),
        takeEvery(INITIALIZE_INTEGRATION_START, initializeIntegration),
        takeEvery(RUN_INTEGRATION_FILTER_START, integrationFilter),
        takeEvery(IMPORT_CONTACTS_START, importContacts),
        takeEvery(DELETE_SYNC_JOBS_START, deleteSync),
        takeEvery(LOAD_SYNC_START, loadSync),
        takeEvery(UPDATE_SYNC_MAP_START, updateSyncMap),
        takeEvery(CREATE_SYNC_START, createSync),
        takeEvery(UPDATE_SYNC_JOB_START, updateSync),
        takeEvery(UPDATE_SYNC_NAME_START, updateSyncName),
        takeEvery(UPDATE_SYNC_STATUS_START, updateSyncStatus),
        takeEvery(LOAD_SYNCS_START, loadSyncs),
        takeEvery(INTEGRATIONS_DATA_SYNC_COMPLETE, initializeIntegration),
        takeEvery(LOAD_INSTALLED_INTEGRATIONS_START, loadInstalledIntegrations),
    ]);
}
