import {all, takeEvery, put} from 'redux-saga/effects';
import recipientActions from 'redux/recipient/actions';
import recipientGroupActions from '../recipientGroups/actions';
import recipientCampaignsActions from '../recipientCampaigns/actions';
import {apiEndpoints} from 'config/api';
import {getRoute} from 'library/helpers/routing';
import recipientsActions from 'redux/recipients/actions';
import {api} from 'library/helpers/api';
import {store} from 'redux/store';

function* fetchRecipientData(data) {
    if (data.loading) return '';

    const url = getRoute(apiEndpoints.recipients.show, {id: data.payload});

    try {
        const recipient = yield api.get(url).then((response) => response.data);
        const recipientDataGroups = recipient.groups;
        const recipientCampaigns = recipient.campaigns;

        // delete recipient.groups;
        yield put(recipientActions.fetchRecipientSuccess(mapRecipient(recipient)));

        yield put(recipientGroupActions.setRecipientGroupStart(recipientDataGroups));

        yield put(recipientCampaignsActions.setRecipientCampaignStart(recipientCampaigns));
    } catch (error) {
        yield put(recipientActions.fetchRecipientError(error));
    }
}

function mapRecipient(recipient) {
    return {
        ...recipient,
        full_name: `${recipient.first_name ?? ''} ${recipient.last_name ?? ''}`,
        initials: (recipient.first_name ?? '').slice(0, 1) + (recipient.last_name ?? '').slice(0, 1),
    };
}

function* updateRecipientData({payload}) {
    try {
        yield processGroupsChange({
            groups: payload.formData.groups,
            id: payload.id,
        });

        //Remove groups from payload for
        delete payload.formData.groups;
        const route = getRoute(apiEndpoints.recipients.update, {id: payload.id});
        const response = yield api.put(route, payload.formData);
        yield put(recipientActions.updateRecipientSuccess(response));
        yield put(recipientActions.fetchRecipientStart(payload.id));
        yield put(recipientsActions.refetchRecipientsStart());
    } catch (error) {
        yield put(recipientActions.updateRecipientFailure(error));
        yield put(recipientActions.fetchRecipientStart(payload.id));
        yield put(recipientsActions.refetchRecipientsStart());
    }
}

function* toggleRecipient({payload}) {
    const route = getRoute(apiEndpoints.recipients.toggle, {id: payload.id});
    try {
        yield api.put(route, {active: payload.active});
        yield put(recipientActions.toggleRecipientStatusSuccess());
        yield put(recipientsActions.refetchRecipientsStart());
    } catch (error) {
        yield put(recipientActions.toggleRecipientStatusFailure(error));
    }
}

function* processGroupsChange(payload) {
    const data = {
        id: payload.id,
        add_groups: payload.groups.add ?? [],
        remove_groups: payload.groups.remove ?? [],
    };

    if (data.add_groups.length || data.remove_groups.length) {
        yield put(recipientGroupActions.updateRecipientGroupStart(data));
    }
}

function* deleteRecipientData({payload}) {
    try {
        const route = getRoute(apiEndpoints.recipients.delete, {id: payload.id});
        yield api.delete(route);

        yield put(recipientActions.deleteRecipientSuccess());
    } catch (error) {
        yield put(recipientActions.deleteRecipientFailure(error));
    }
}

function* resetRecipient() {
    yield setTimeout(() => {
        store.dispatch(recipientActions.resetSuccess());
    }, 300);
}

export default function* recipientSaga() {
    yield all([
        takeEvery(recipientActions.FETCH_RECIPIENT_DATA_START, fetchRecipientData),
        takeEvery(recipientActions.UPDATE_RECIPIENT_DATA_START, updateRecipientData),
        takeEvery(recipientActions.TOGGLE_RECIPIENT_STATUS_START, toggleRecipient),
        takeEvery(recipientActions.DELETE_RECIPIENT_DATA_START, deleteRecipientData),
        takeEvery(recipientActions.RESET_RECIPIENT, resetRecipient),
    ]);
}
