import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Flag, Space} from 'components/UI';
import CurrencyValue from '../CurrencyValue';

const CurrencyDisplay = ({
    approximate = false,
    currency,
    flagProps = {},
    withCurrency = false,
    withLabel = false,
    withoutFlag = false,
    key = null,
    valueRegion,
}) => {
    const getLabel = useCallback(() => {
        const currencyCode = `(${currency.currency})`;
        const currencyLabel = currency.label ?? currency.country_name ?? '';

        return (
            <Space size={4}>
                {withLabel && currencyLabel}
                {withCurrency && currencyCode}
                {valueRegion && (
                    <span>
                        {approximate ? '~' : ''}
                        <CurrencyValue
                            amount={currency.value ?? currency.amount}
                            currency={currency.currency}
                            region={valueRegion}
                            exchange_rate={currency?.usd_unit_price === (currency.value ?? currency.amount)}
                        />
                    </span>
                )}
            </Space>
        );
    }, [
        approximate,
        currency.amount,
        currency.country_name,
        currency.currency,
        currency.label,
        currency.usd_unit_price,
        currency.value,
        valueRegion,
        withCurrency,
        withLabel,
    ]);

    return (
        <Space size={8} key={`currency-${key ?? currency.currency}`}>
            {!withoutFlag && (
                <Flag key={`flag-${key ?? currency.currency}`} country={currency.currency} {...flagProps} />
            )}
            {getLabel()}
        </Space>
    );
};

CurrencyDisplay.propTypes = {
    approximate: PropTypes.bool,
    currency: PropTypes.object,
    flagProps: PropTypes.object,
    key: PropTypes.string,
    valueRegion: PropTypes.object,
    withCurrency: PropTypes.bool,
    withLabel: PropTypes.bool,
    withoutFlag: PropTypes.bool,
};
export default CurrencyDisplay;
