import React from 'react';
import PropTypes from 'prop-types';
import {Space, Typography, FontAwesome, Loading} from 'components/UI';

const {Link} = Typography;

const Storefront = ({storefront, style}) => {
    if (!storefront) return <Loading />;

    if (!storefront['SampleCatalogLink']) {
        return storefront.description ?? '';
    }

    return (
        <Link href={storefront['SampleCatalogLink'] ?? ''} target="_blank" style={style} className="nowrap">
            <Space size={6} align="start">
                {storefront.description ?? ''}
                <FontAwesome icon="square-arrow-up-right" />
            </Space>
        </Link>
    );
};

Storefront.propTypes = {
    storefront: PropTypes.object,
    style: PropTypes.object,
};

export default Storefront;
