import {all, put, takeLeading} from 'redux-saga/effects';
import primaryProductsActions from './actions';
import {api} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';

function* fetchCompanyPrimaryProductsData() {
    try {
        const {data} = yield api.get(apiEndpoints.primary_products.list);

        yield put(primaryProductsActions.fetchCompanyPrimaryProductsDataSuccess(data));
    } catch (error) {
        yield put(primaryProductsActions.fetchCompanyPrimaryProductsDataFailure(error));
    }
}

function* fetchAllPrimaryProductsData() {
    try {
        const {data} = yield api.get(apiEndpoints.primary_products.all);

        yield put(primaryProductsActions.fetchAllPrimaryProductsDataSuccess(data));
    } catch (error) {
        yield put(primaryProductsActions.fetchAllPrimaryProductsDataFailure(error));
    }
}

export default function* primaryProductsSaga() {
    yield all([
        takeLeading(primaryProductsActions.FETCH_COMPANY_PRIMARY_PRODUCTS_DATA_START, fetchCompanyPrimaryProductsData),
        takeLeading(primaryProductsActions.FETCH_ALL_PRIMARY_PRODUCTS_DATA_START, fetchAllPrimaryProductsData),
    ]);
}
