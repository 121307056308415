import {fundingTransferActions} from 'redux/fundingTransfer/actions';

const initialState = {
    loading: false,
    request: {
        from: '',
        filepath: '',
    },
    response: {
        dispatched: false,
        results: [],
    },
    error: null,
};

const fundingTransferReducer = (state = initialState, action) => {
    switch (action.type) {
        case fundingTransferActions.PROCESS_UPLOAD_TRANSFER_START:
            return {
                ...state,
                loading: true,
                request: action.payload,
            };
        case fundingTransferActions.PROCESS_UPLOAD_TRANSFER_COMPLETE:
            return {
                ...state,
                loading: false,
                response: action.payload,
            };
        case fundingTransferActions.PROCESS_UPLOAD_TRANSFER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case fundingTransferActions.PROCESS_UPLOAD_TRANSFER_RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default fundingTransferReducer;
