import {all, takeEvery, put} from 'redux-saga/effects';
import orderActions from './actions';
import {apiEndpoints} from 'config/api';
import {api} from 'library/helpers/api';
import {getRoute} from 'library/helpers/routing';
import orderSummaryActions from 'redux/orderSummary/actions';
import {processError} from 'library/helpers/response';
import {DIGITAL_ORDER, PHYSICAL_ORDER} from 'config/orders';
import recentOrdersActions from '../recentOrders/actions';

function* fetchOrder(type, id) {
    const route = getRoute(type === DIGITAL_ORDER ? apiEndpoints.digitalOrders.get : apiEndpoints.physicalOrders.get, {
        id,
    });

    return yield api.get(route).then((response) => response.data);
}

function* fetchOrderData(orderData) {
    try {
        const order = yield fetchOrder(orderData.payload.type, orderData.payload.id);

        if (orderData.payload.type === DIGITAL_ORDER) {
            const first_name = order.first_name ? order.first_name + ' ' : '';
            const last_name = order.last_name ? order.last_name : '';

            order.name = first_name + last_name;
        }

        if (order.shipping_info) {
            order.shipping_info.name = `${order.shipping_info.first_name} ${order.shipping_info.last_name}`;
            order.shipping_info.address = `${order.shipping_info.street_number} ${order.shipping_info.route}`;
        }

        yield put(orderActions.getOrderRecipient(order));
        yield put(orderActions.fetchOrderDataSuccess(order));
    } catch (error) {
        yield put(orderActions.fetchOrderDataFailure(processError(error)));
    }
}

function* reorderPhysicalStart({payload}) {
    try {
        const {orderId, itemId} = payload;

        const route = getRoute(apiEndpoints.physicalOrders.reorder, {
            id: orderId,
        });

        const data = {
            order_item_id: itemId,
        };

        yield api.post(route, data);

        yield put(orderSummaryActions.fetchOrderSummaryStart({forceFetch: true}));
        yield put(orderActions.reorderPhysicalSuccess('Reorder Successful'));
    } catch (error) {
        yield put(orderActions.reorderPhysicalFailure(error));
    }
}

function* reorderDigitalStart({payload}) {
    try {
        const {email, ids, update} = payload;

        const data = {
            email: email,
            ids: ids,
            update_contact_email: update,
        };

        const {data: responseData} = yield api.post(apiEndpoints.digitalOrders.reorder, data);

        if (Object.keys(responseData?.failed ?? {})?.length && !responseData?.queued) {
            // Throw an error with a custom message based on the failed object
            const errorMessage = Object.values(responseData.failed).join(', ');

            throw new Error(errorMessage);
        }

        yield put(orderActions.reorderDigitalSuccess('Resend Successful'));
        yield put(
            orderActions.fetchOrderDataStart({
                id: ids[0],
                type: DIGITAL_ORDER,
            }),
        );

        yield put(recentOrdersActions.modifyRecentOrders(DIGITAL_ORDER, ids, {email, resent_at: new Date()}));
    } catch (error) {
        yield put(orderActions.reorderDigitalFailure(error));
    }
}

function* cancelDigitalStart({payload}) {
    try {
        const {ids} = payload;

        yield api.delete(apiEndpoints.digitalOrders.cancel, {data: {ids}});

        yield put(orderActions.fetchOrderDataStart({id: ids[0], type: DIGITAL_ORDER}));

        yield put(orderActions.cancelDigitalSuccess('Order successfully cancelled'));
    } catch (error) {
        yield put(orderActions.cancelDigitalFailure(error.response ? error.response.data.message : error.message));
    }
}

function* cancelPhysicalStart({payload}) {
    try {
        const {id} = payload;

        const route = getRoute(apiEndpoints.physicalOrders.cancel, {
            id,
        });

        yield api.delete(route);

        yield put(orderActions.fetchOrderDataStart({id, type: PHYSICAL_ORDER}));
        yield put(orderActions.cancelPhysicalSuccess('Order successfully cancelled'));
    } catch (error) {
        yield put(orderActions.cancelPhysicalFailure(error.response ? error.response.data.message : error.message));
    }
}

export default function* orderSaga() {
    yield all([
        takeEvery(orderActions.FETCH_ORDER_DATA_START, fetchOrderData),
        takeEvery(orderActions.FETCH_ORDER_DATA_FRESH, fetchOrderData),

        takeEvery(orderActions.REORDER_PHYSICAL_DATA_START, reorderPhysicalStart),
        takeEvery(orderActions.REORDER_DIGITAL_DATA_START, reorderDigitalStart),

        takeEvery(orderActions.CANCEL_PHYSICAL_DATA_START, cancelPhysicalStart),
        takeEvery(orderActions.CANCEL_DIGITAL_DATA_START, cancelDigitalStart),
    ]);
}
