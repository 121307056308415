import fundingAmountActions from './actions';

const initialState = {
  data: null,
  error: null,
  loading: false,
};

const fundingAmountReducer = (state = initialState, action) => {
  switch (action.type) {
    case fundingAmountActions.FETCH_FUNDING_AMOUNT_START:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case fundingAmountActions.FETCH_FUNDING_AMOUNT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };

    case fundingAmountActions.FETCH_FUNDING_AMOUNT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case fundingAmountActions.CLEAR_FUNDING_AMOUNT:
        return {
          ...state,
          data: null,
          error: null,
          loading: false,
        };

    default:
      return state;
  }
};

export default fundingAmountReducer;
