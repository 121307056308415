import React from 'react';

const icon = () => (
    <svg viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <circle fill="#F0F0F0" cx="256" cy="256" r="256"></circle>
                <g transform="translate(8.819000, 0.000000)" fill="#D80027">
                    <path d="M1.24344979e-14,322.783 C14.812,377.742 47.457,425.376 91.355,459.105 L91.355,322.783 L1.24344979e-14,322.783 Z"></path>
                    <path d="M224.92,511.026 C232.258,511.658 239.679,512 247.181,512 C365.46,512 464.986,431.779 494.362,322.783 L224.92,322.783 L224.92,511.026 Z"></path>
                    <path d="M494.362,189.217 C464.986,80.221 365.46,0 247.181,0 C239.679,0 232.258,0.342 224.92,0.974 L224.92,189.217 L494.362,189.217 Z"></path>
                    <path d="M91.355,52.895 C47.457,86.625 14.812,134.258 1.42108547e-14,189.217 L91.355,189.217 L91.355,52.895 Z"></path>
                </g>
                <path
                    d="M509.833,222.609 L200.349,222.609 L200.348,222.609 L200.348,6.085 C176.69,11.331 154.261,19.834 133.565,31.127 L133.565,222.607 L133.565,222.608 L2.167,222.608 C0.742,233.539 5.68434189e-14,244.683 5.68434189e-14,256 C5.68434189e-14,267.317 0.742,278.461 2.167,289.391 L133.564,289.391 L133.565,289.391 L133.565,480.872 C154.261,492.164 176.69,500.669 200.348,505.914 L200.348,289.394 L200.348,289.392 L509.833,289.392 C511.256,278.461 512,267.317 512,256 C512,244.683 511.256,233.539 509.833,222.609 L509.833,222.609 Z"
                    fill="#0052B4"
                ></path>
            </g>
        </g>
    </svg>
);

export default icon;
