import React from 'react';

const icon = () => (
    <svg id="Layer_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <circle cx="256" cy="256" fill="#f0f0f0" r="256"/>
        <path d="m0 256.001c0-141.385 114.616-256.001 256-256.001s256 114.616 256 256.001" fill="#d80027"/>
        <g fill="#f0f0f0">
            <path
                d="m155.826 133.564c0-37.298 26.213-68.456 61.217-76.101-5.38-1.174-10.961-1.811-16.696-1.811-43.031 0-77.913 34.882-77.913 77.913s34.882 77.913 77.913 77.913c5.733 0 11.315-.637 16.696-1.812-35.004-7.645-61.217-38.803-61.217-76.102z"/>
            <path
                d="m256 61.217 5.526 17.005h17.881l-14.467 10.511 5.526 17.005-14.466-10.509-14.466 10.509 5.526-17.005-14.466-10.511h17.88z"/>
            <path
                d="m212.625 94.608 5.525 17.006h17.881l-14.466 10.51 5.526 17.005-14.466-10.509-14.468 10.509 5.527-17.005-14.467-10.51h17.881z"/>
            <path
                d="m299.376 94.608 5.527 17.006h17.88l-14.467 10.51 5.527 17.005-14.467-10.509-14.466 10.509 5.526-17.005-14.466-10.51h17.88z"/>
            <path
                d="m282.681 144.695 5.526 17.006h17.88l-14.466 10.51 5.526 17.005-14.466-10.509-14.466 10.509 5.526-17.005-14.466-10.51h17.879z"/>
            <path
                d="m229.32 144.695 5.525 17.006h17.882l-14.467 10.51 5.527 17.005-14.467-10.509-14.467 10.509 5.526-17.005-14.466-10.51h17.881z"/>
        </g>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
    </svg>);

export default icon;
