import React from 'react';
import {TreeSelect as AntTreeSelect} from 'antd';
import FontAwesome from 'components/UI/FontAwesome';
import PropTypes from 'prop-types';

export const TreeSelect = (props) => {
    return (
        <AntTreeSelect
            {...props}
            dropdownStyle={props.dropdownStyle}
            size={props.size}
            suffixIcon={
                !props.loading ? (
                    <FontAwesome type="solid" icon="chevron-down" size="xs" />
                ) : (
                    <FontAwesome type="solid" icon="spinner-third" spin />
                )
            }
            switcherIcon={<FontAwesome type="solid" icon="chevron-down" size="xs" />}
            filterTreeNode={(search, item) => {
                return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
            }}
        />
    );
};

export default TreeSelect;

TreeSelect.defaultProps = {
    dropdownStyle: {maxHeight: 425, overflow: 'auto'},
    loading: false,
    size: 'large',
};

TreeSelect.propTypes = {
    dropdownStyle: PropTypes.any.isRequired,
    loading: PropTypes.bool.isRequired,
    size: PropTypes.any.isRequired,
};
