import PropTypes from 'prop-types';
import React from 'react';
import Card from 'components/UI/Card';
import Divider from 'components/UI/Divider';
import {Space, Typography} from 'antd';

const {Text} = Typography;

/**
 * @typedef {Object} SidebarDividerProps
 * @property {React.ReactNode} children
 * @property {any} [margin]
 */

/**
 * @typedef {React.FC} SidebarDivider
 */

/**
 * @type {SidebarDivider}
 */
const SidebarDivider = ({margin = false}) => {
    return <Divider margin={margin} style={{borderColor: '#F5F5F5'}} />;
};

SidebarDivider.propTypes = {
    margin: PropTypes.bool,
};

/**
 * @typedef {Object} SidebarSectionProps
 * @property {React.ReactNode} children
 * @property {any} [divider]
 * @property {string} [title]
 * @property {string} [subtitle]
 */

/**
 * @typedef {React.FC} SidebarSection
 */

/**
 * @type {SidebarSection}
 */
const SidebarSection = ({divider = false, children, subtitle = '', title = ''}) => {
    return (
        <>
            <Card bordered={false} title="" bodyStyle={!divider ? {paddingBottom: 0} : {}}>
                <Space size={12} direction="vertical">
                    {title && <Text strong>{title}</Text>}

                    {subtitle && (
                        <Text type="secondary">
                            <small>{subtitle}</small>
                        </Text>
                    )}

                    {children}
                </Space>
            </Card>

            {divider &&
                (typeof divider === 'boolean' ? <Divider margin={false} style={{borderColor: '#F5F5F5'}} /> : divider)}
        </>
    );
};

SidebarSection.propTypes = {
    children: PropTypes.node,
    divider: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    subtitle: PropTypes.string,
    title: PropTypes.string,
};

/**
 * @typedef {Object} SidebarProps
 * @property {React.ReactNode} children
 */

/**
 * @typedef {React.FC} Sidebar
 * @property {React.FC<SidebarProps>} Sidebar
 * @property {React.FC<SidebarDividerProps>} Divider
 * @property {React.FC<SidebarSectionProps>} Section
 */

/**
 * @type {Sidebar}
 */
const Sidebar = () => {};

Sidebar.Section = SidebarSection;
Sidebar.Divider = SidebarDivider;

export default Sidebar;
