import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

const emptyIcons = ['face-hand-over-mouth', 'face-hand-peeking', 'face-monocle', 'face-thinking', 'face-pleading'];
const getFa = (str) => (str ? `fa-${str}` : '');

const FontAwesome = ({className, color, empty, flip, icon, size, spin, style, type, waiting}) => {
    const [randomIcon, setRandomIcon] = useState(null);

    useEffect(() => {
        setRandomIcon(emptyIcons[Math.floor(Math.random() * emptyIcons.length)]);
    }, []);

    if (waiting)
        return (
            <i
                className={`primary-alt-icon fa-spinner-third fa-solid fa-spin ${getFa(size)}`}
                style={{...style, color}}
            ></i>
        );

    if (empty) {
        return <i className={'fa-duotone fa-' + randomIcon}></i>;
    }

    return (
        <i
            className={`${getFa(type)} ${getFa(icon)} ${getFa(spin && 'spin')} ${getFa(flip)} ${getFa(
                size,
            )} ${className}`}
            style={{...style, color}}
        ></i>
    );
};

FontAwesome.defaultProps = {
    className: '',
    color: '',
    empty: false,
    flip: false,
    icon: '',
    size: '',
    spin: false,
    style: {},
    type: 'regular',
    waiting: false,
};

FontAwesome.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    empty: PropTypes.bool,
    flip: PropTypes.bool,
    icon: PropTypes.string,
    size: PropTypes.string,
    spin: PropTypes.bool,
    style: PropTypes.object,
    type: PropTypes.string,
    waiting: PropTypes.bool,
};

export default FontAwesome;
