import {LOGOUT} from 'redux/auth/auth.types';
import checkoutLookupActions from "./actions";

const initialState = {
  data: null,
  error: null,
};

const checkoutLookupReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case checkoutLookupActions.FETCH_CHECKOUT_LOOKUP_DATA_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case checkoutLookupActions.FETCH_CHECKOUT_LOOKUP_DATA_SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
        error: null,
      };

    case checkoutLookupActions.FETCH_CHECKOUT_LOOKUP_DATA_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: payload,
      };

    case checkoutLookupActions.CLEAR_CHECKOUT_LOOKUP_DATA:
    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default checkoutLookupReducer;
