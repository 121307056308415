import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {storesLoaded} from 'redux/selectors';

const FormFieldset = ({legend, children}) => {
    const loaded = useSelector(storesLoaded);
    return (
        <fieldset>
            {legend && loaded && <legend>{legend}</legend>}
            {children}
        </fieldset>
    );
};

FormFieldset.defaultProps = {
    legend: '',
};

FormFieldset.propTypes = {
    children: PropTypes.any,
    legend: PropTypes.any,
};

export default FormFieldset;
