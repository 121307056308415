import {all, put, select, takeLeading, takeEvery} from 'redux-saga/effects';
import orderSummaryActions from './actions';
import {api} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';
import {ORDER_SUMMARY_STORE} from 'config/redux';
import {removeLocalStore, setLocalStore} from 'library/helpers/localStore';
import {localFetch} from 'library/helpers/redux';
import teamActions from 'redux/team/actions';
import {getOrderSummary} from 'redux/orderSummary/selectors';
import {getRoute} from 'library/helpers/routing';
import {getCurrentCampaignId} from 'redux/selectors';
import profileActions from 'redux/profile/actions';
import isEqual from 'react-fast-compare';
import omit from 'lodash/omit';

function* fetchOrderSummary() {
    // if exists save to OrderSummary from storage
    const campaignId = yield select(getCurrentCampaignId);

    if (campaignId === 0) {
        // return yield put(orderSummaryActions.resetOrderSummary());
        return false;
    }

    return yield api.get(getRoute(apiEndpoints.orderSummary.get, {campaignId}));
}

function* changeCampaign() {
    return yield put(orderSummaryActions.fetchOrderSummaryStart({forceFetch: true}));
}

export const shouldMergeOrderItems = (item, order) => {
    return (
        item.product_id === order.product_id &&
        item.denomination === order.denomination &&
        item.currency === order.currency &&
        item.primary_product_id === order.primary_product_id &&
        isEqual(item.builder_data_ids, order.builder_data_ids) &&
        isEqual(item.image_ids, order.image_ids) &&
        isEqual(omit(item.extra, 'quantity'), omit(order.extra, 'quantity'))
    );
};

function* fetchOrderSummaryEffect({payload}) {
    const cachedOrder = yield localFetch(payload, ORDER_SUMMARY_STORE);

    if (cachedOrder) {
        return yield put(orderSummaryActions.fetchOrderSummarySuccess(cachedOrder));
    }

    yield removeLocalStore(ORDER_SUMMARY_STORE);

    try {
        const orderSummary = yield fetchOrderSummary();

        if (!orderSummary) {
            return;
        }

        const data = {
            items: orderSummary.data || [],
        };

        yield put(orderSummaryActions.fetchOrderSummarySuccess(data));

        if (data.items.length > 0) yield setLocalStore(ORDER_SUMMARY_STORE, data);
    } catch (e) {
        yield put(orderSummaryActions.fetchOrderSummaryError(e));
    }
}

function* addOrderSummaryEffect({payload: order}, merge = true) {
    try {
        const orderSummary = yield select(getOrderSummary);

        let items = orderSummary.data.items;

        if (order) {
            items = items.map((item) => {
                if (shouldMergeOrderItems(item, order)) {
                    return merge ? {...item, quantity: item.quantity + order.quantity} : order;
                }
                return item;
            });

            if (!items.some((item) => shouldMergeOrderItems(item, order))) {
                items.push(order);
            }
        }

        items = items.filter((item) => item.quantity > 0);

        // send the new cart to the server
        yield api.post(apiEndpoints.orderSummary.post, {items});

        // refetch the cart
        yield put(orderSummaryActions.fetchOrderSummaryStart({forceFetch: true}));

        // tell everyone you're done
        yield put(orderSummaryActions.addToOrderSummarySuccess());
    } catch (e) {
        yield put(orderSummaryActions.addToOrderSummaryError(e));
    }
}

function* updateOrderSummaryQuantityStart({payload}) {
    try {
        yield api.put(apiEndpoints.orderSummary.put, payload);

        const orderSummary = yield select(getOrderSummary);

        const modifiedItem = orderSummary.data.items.find((item) => item.id === payload.id);
        modifiedItem.quantity = payload.quantity;

        yield addOrderSummaryEffect({payload: modifiedItem}, false);

        yield put(orderSummaryActions.updateQuantitySuccess());
        yield put(orderSummaryActions.fetchOrderSummaryStart({forceFetch: true}));
    } catch (error) {
        yield put(orderSummaryActions.updateQuantityError(error));
    }
}

function* removeOrderSummaryItemEffect({payload}) {
    const orderSummary = yield select((state) => state.orderSummary.data.items);
    const items = Object.keys(orderSummary)
        .map((key) => (payload !== orderSummary[key].id ? orderSummary[key] : false))
        .filter((f) => f);

    try {
        yield api.post(apiEndpoints.orderSummary.post, {items});
        removeLocalStore(ORDER_SUMMARY_STORE);
        yield put(orderSummaryActions.fetchOrderSummaryStart({forceFetch: true}));
        yield put(orderSummaryActions.deleteFromOrderSummarySuccess());
    } catch (e) {
        yield put(orderSummaryActions.deleteFromOrderSummaryError(e));
    }
}

function* resetOrderSummary() {
    try {
        yield api.post(apiEndpoints.orderSummary.post, {items: []});
        removeLocalStore(ORDER_SUMMARY_STORE);
        yield put(orderSummaryActions.deleteFromOrderSummarySuccess());
        yield put(orderSummaryActions.fetchOrderSummaryStart({forceFetch: true}));
    } catch (e) {
        yield put(orderSummaryActions.deleteFromOrderSummaryError(e));
    }
}

export default function* orderSummarySaga() {
    yield all([
        takeLeading(orderSummaryActions.FETCH_ORDER_SUMMARY_START, fetchOrderSummaryEffect),
        takeEvery(orderSummaryActions.ADD_TO_ORDER_SUMMARY_START, addOrderSummaryEffect),
        takeEvery(orderSummaryActions.DELETE_FROM_ORDER_SUMMARY_START, removeOrderSummaryItemEffect),
        takeEvery(orderSummaryActions.UPDATE_ORDER_SUMMARY_START, updateOrderSummaryQuantityStart),
        takeLeading(orderSummaryActions.RESET_ORDER_SUMMARY, resetOrderSummary),
        takeLeading(teamActions.CHANGE_TEAM_SUCCESS, resetOrderSummary),
        takeEvery(profileActions.UPDATE_DATA_CONTEXT_CAMPAIGN_ID_SUCCESS, changeCampaign),
    ]);
}
