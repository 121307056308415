import React from 'react';
import {goToRoute} from 'library/helpers/routing';
import logo from '../../assets/images/giftogram-logo-light.svg';
import {lang} from 'config/lang';
import {Skeleton, Space} from 'components/UI';
import {routes} from 'config/routes';

export const mainNav = {
    brandLogo: {
        key: routes.private.paths.DASHBOARD,
        onClick: () => goToRoute(routes.private.paths.DASHBOARD),
        className: 'brand-logo',
        label: <img src={logo} alt={lang.brand_name} height={30} style={{position: 'relative', marginTop: '-4px'}} />,
    },
    loading: {
        key: 'main-nav-loading',
        label: (
            <Space style={{marginRight: '1rem'}} size={16}>
                <Skeleton.Input size="small" active />
                <Skeleton.Input size="small" active />
                <Skeleton.Input size="small" active />
                <Skeleton.Input size="small" active />
            </Space>
        ),
    },
    create: {
        key: routes.private.paths.CREATE,
        onClick: () => goToRoute(routes.private.paths.CREATE),
        label: lang.button.create_and_send,
    },
    campaigns: {
        key: routes.private.paths.CAMPAIGNS,
        onClick: () => goToRoute(routes.private.paths.CAMPAIGNS),
        label: lang.campaigns.title,
    },
    support: {
        key: routes.neutral.paths.HELP,
        onClick: () => window.open(routes.neutral.paths.HELP, '_blank'),
        label: lang['supportCenter'],
    },
    getRecipientsSubmenu(isAdmin, hasInstalledIntegrations) {
        const recipientsSubmenu = {
            recipients: {
                key: routes.private.paths.RECIPIENTS,
                onClick: () => goToRoute(routes.private.paths.RECIPIENTS),
                label: lang.button.recipients,
            },
            groups: {
                key: routes.private.paths.GROUPS,
                onClick: () => goToRoute(routes.private.paths.GROUPS),
                label: lang.button.groups,
            },
            divider: {
                key: 'recipients-submenu-divider',
                type: 'divider',
            },
            import: {
                key: `import-recipients`,
                theme: 'light',
                label: lang.import,
                children: [
                    {
                        key: `${routes.private.paths.RECIPIENT_IMPORT}-csv`,
                        onClick: () => goToRoute(routes.private.paths.RECIPIENT_IMPORT),
                        label: 'Upload CSV',
                    },
                ],
            },
            exports: {
                key: `${routes.private.paths.EXPORTS_DOWNLOAD}-recipients`,
                onClick: () => goToRoute(routes.private.paths.EXPORTS_DOWNLOAD),
                label: lang.button.report_download,
            },
        };

        if (hasInstalledIntegrations && isAdmin) {
            recipientsSubmenu.import.children.push({
                key: `${routes.private.paths.INTEGRATIONS_SYNC_JOBS}-integrations`,
                onClick: () => goToRoute(routes.private.paths.INTEGRATIONS_SYNC_JOBS),
                label: 'Integration Sync',
            });
        }

        return recipientsSubmenu;
    },
    getApprovalsSubmenu(isAdmin) {
        if (!isAdmin) {
            return {};
        }

        return {
            balanceHistory: {
                key: routes.private.paths.ORDERS_APPROVALS,
                onClick: () => goToRoute(routes.private.paths.ORDERS_APPROVALS),
                label: lang.orders.order_history.pending_approval,
            },
        };
    },
    get ordersSubmenu() {
        const digital = {
            key: routes.private.paths.ORDERS_DIGITAL,
            onClick: () => goToRoute(routes.private.paths.ORDERS_DIGITAL),
            label: lang.orders.order_history.digital,
        };

        const physical = {
            key: routes.private.paths.ORDERS_PHYSICAL,
            onClick: () => goToRoute(routes.private.paths.ORDERS_PHYSICAL),
            label: lang.orders.order_history.physical,
        };

        return {
            key: 'orders-title',
            theme: 'light',
            label: lang.orders.order_history.title,
            children: [digital, physical],
        };
    },
    historySubmenu: {
        balanceHistory: {
            key: routes.private.paths.BALANCE_HISTORY,
            onClick: () => goToRoute(routes.private.paths.BALANCE_HISTORY),
            label: lang.funding.history.label,
        },
        divider: {
            key: 'mainnav-history-submenu-divider',
            type: 'divider',
        },
        exports: {
            key: `${routes.private.paths.EXPORTS_DOWNLOAD}-history`,
            onClick: () => goToRoute(routes.private.paths.EXPORTS_DOWNLOAD),
            label: lang.button.report_download,
        },
    },
};
