import {getLocalCache, identifier, setLocalCache} from 'library/helpers/localStore';
import siteDataActions from 'redux/siteData/actions';
import {DEPLOY_ID, SITE_DATA} from 'config/redux';
import {getProcessEnvValue, getProcessValue} from 'library/helpers/process';

const getCache = (key) => getLocalCache(key);
const setCache = (key, data) => setLocalCache(key, data);

export const getSiteData = (key = null) => {
    const data = getCache(SITE_DATA);

    if (data && key) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(key)) return data[key];

        return null;
    }

    return data;
};

export const setSiteData = (data) => {
    setCache(DEPLOY_ID, identifier);
    setCache(SITE_DATA, data);
};

/**
 * Append an item to site data
 *
 * @param data
 */
export const appendSiteData = (data) => {
    // we arrange the object this way so data can not overwrite the default data
    const newData = {
        ...getSiteData(),
        ...data,
    };

    // store site data in localStorage
    setSiteData(newData);

    return newData;
};
/**
 * Load site data
 *
 * @param dispatch React Redux dispatch function
 */
export const loadSiteData = (dispatch) => {
    const siteData = {
        id: identifier,
        urls: loadUrls(),
    };

    dispatch(siteDataActions.save(siteData));
    setSiteData(siteData);
};

export const setSiteDataClient = (client) => {
    const siteData = {
        ...getSiteData(),
        client,
    };

    setSiteData(siteData);
};

export const setSnapshot = (snapshot) => {
    const siteData = {
        ...getSiteData(),
        snapshot,
    };

    setSiteData(siteData);
};

const loadUrls = () => {
    return Object.keys(getProcessValue('env'))
        .map((key) => {
            if (key.match(/^REACT_.+_URL$/)) {
                return {
                    [key.replace('REACT_APP_', '').toLowerCase()]: getProcessEnvValue(key),
                };
            }

            return false;
        })
        .filter((f) => f);
};
