import profileActions, {SHOW_PASSWORD_RESET_MODAL} from './actions';

import {HIDE_PASSWORD_RESET_MODAL} from 'redux/profile/actions';
import {LOGOUT} from 'redux/auth/auth.types';

const initialState = {
    data: null,
    loading: false,
    updating: false,
    updated: false,
    error: null,
    showResetModal: false,
};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_PASSWORD_RESET_MODAL:
            return {
                ...state,
                showResetModal: true,
            };
        case HIDE_PASSWORD_RESET_MODAL:
            return {
                ...state,
                showResetModal: false,
            };
        case profileActions.FETCH_PROFILE_DATA_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case profileActions.FETCH_PROFILE_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null,
            };
        case profileActions.FETCH_PROFILE_DATA_FAILURE:
            return {
                ...state,
                payload: null,
                loading: false,
                error: action.payload,
            };

        case profileActions.RESET_UPDATED:
            return {
                ...state,
                updated: false,
            };

        case profileActions.UPDATE_PROFILE_DATA_START:
            return {
                ...state,
                payload: action.payload,
                updated: false,
                error: null,
            };
        case profileActions.UPDATE_PROFILE_DATA_SUCCESS:
            return {
                ...state,
                updated: true,
                error: null,
            };
        case profileActions.UPDATE_PROFILE_DATA_FAILURE:
            return {
                ...state,
                payload: null,
                updated: false,
                error: action.payload,
            };

        case profileActions.UPDATE_CREDIT_AVAILABLE: {
            const creditUpdate = parseFloat(action.payload);

            return {
                ...state,
                data: {
                    ...state.data,
                    credit_available: isNaN(creditUpdate) ? state.data.credit_available : creditUpdate,
                },
            };
        }

        case profileActions.UPDATE_DATA_CONTEXT_CAMPAIGN_ID_SUCCESS: {
            const campaign_id = action.payload;

            return {
                ...state,
                data: {
                    ...state.data,
                    data: {
                        ...state.data.data,
                        context: {
                            ...state.data.data.context,
                            campaign_id,
                        },
                    },
                },
            };
        }

        case profileActions.UPDATE_DATA_CONTEXT_TEAM_ID_SUCCESS: {
            const team_id = action.payload;

            return {
                ...state,
                data: {
                    ...state.data,
                    data: {
                        ...state.data.data,
                        context: {
                            ...state.data.data.context,
                            team_id,
                        },
                    },
                },
            };
        }

        case profileActions.UPDATE_AVATAR_PROFILE_DATA_START:
            return {
                ...state,
                error: null,
                uploading: true,
            };
        case profileActions.UPDATE_AVATAR_PROFILE_DATA_SUCCESS:
            return {
                ...state,
                error: null,
                data: {...state.data, avatar: action.payload},
                uploading: false,
            };
        case profileActions.UPDATE_AVATAR_PROFILE_DATA_FAILURE:
            return {
                ...state,
                payload: null,
                error: action.payload,
                uploading: false,
            };

        case profileActions.RESET_PROFILE_PASSWORD_DATA_START:
            return {
                ...state,
                payload: action.payload,
                updating: true,
                error: null,
            };
        case profileActions.RESET_PROFILE_PASSWORD_DATA_SUCCESS:
            return {
                ...state,
                payload: null,
                updating: false,
                updated: true,
                error: null,
            };
        case profileActions.RESET_PROFILE_PASSWORD_DATA_FAILURE:
            return {
                ...state,
                payload: null,
                updating: false,
                updated: false,
                error: action.payload,
            };

        //case teamActions.CHANGE_TEAM_SUCCESS:
        case LOGOUT:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default profileReducer;
