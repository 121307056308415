import React from 'react';
import PropTypes from 'prop-types';
import {Popover, FontAwesome} from 'components/UI';
import {HexColorPicker} from 'react-colorful';

const ColorPicker = (props) => {
    const color = props.color && props.color.length === 6 ? props.color : '';

    return (
        <Popover
            trigger={'click'}
            placement={'rightBottom'}
            content={<HexColorPicker color={color} onChange={props.onChange} />}
            onOpenChange={(open) => !open && props.onClosed()}
        >
            <div
                className={'color-picker color-picker-' + props.size + (!color ? ' component-bg' : '')}
                style={{backgroundColor: color && '#' + color}}
            >
                {!color && <FontAwesome icon="eye-dropper-half" size="lg" />}
            </div>
        </Popover>
    );
};

ColorPicker.defaultProps = {
    color: '',
    size: 'large',
    onClosed: () => {},
};

ColorPicker.propTypes = {
    onChange: PropTypes.func,
    color: PropTypes.string,
    size: PropTypes.string,
    onClosed: PropTypes.func,
};

export default ColorPicker;
