import {INACTIVITY_TIMEOUT_MINUTES, ONE_MINUTE_IN_MS} from 'config/utility';
import {goToRoute} from 'library/helpers/routing';
import {getProcessEnvValue} from 'library/helpers/process';
import noop from 'lodash/noop';
import throttle from 'lodash/throttle';
import {SYSTEM_ENVIRONMENT} from 'config/constants';
import {routes} from 'config/routes';

console.log = [SYSTEM_ENVIRONMENT.DEVELOPMENT, SYSTEM_ENVIRONMENT.STAGING, SYSTEM_ENVIRONMENT.TEST].includes(
    getProcessEnvValue('REACT_APP_ENV'),
)
    ? console.log
    : noop;

export let timer;
export let idleTime = 0;

export const clearInactivityTimeout = () => {
    idleTime = 0;
    clearTimeout(timer);
    timer = null;
};

const incrementTimer = () => {
    idleTime++;

    if (idleTime >= INACTIVITY_TIMEOUT_MINUTES) {
        clearInactivityTimeout();

        goToRoute(routes.neutral.paths.LOGOUT);
    }
};
export const setInactivityTimeout = () => {
    if (timer) {
        clearInactivityTimeout();
    }

    const resetTimer = () => {
        clearInactivityTimeout();
        timer = setInterval(incrementTimer, ONE_MINUTE_IN_MS);
    };

    const afterDOMLoaded = () => {
        clearTimeout(timer);
        timer = setInterval(incrementTimer, ONE_MINUTE_IN_MS);

        document.addEventListener('mousemove', throttle(resetTimer, 1000), false);
        document.addEventListener('click', throttle(resetTimer, 1000), false);
        document.addEventListener('keypress', throttle(resetTimer, 1000), false);
    };

    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', afterDOMLoaded);

        return;
    }

    afterDOMLoaded();
};
