import recipientActions from './actions';

import {LOGOUT} from 'redux/auth/auth.types';

const initialState = {
    id: null,
    data: null,
    saved: false,
    loading: false,
    loaded: false,
    error: null,
    deleted: false,
};

const recipientReducer = (state = initialState, action) => {
    switch (action.type) {
        case recipientActions.FETCH_RECIPIENT_DATA_START:
            return {
                ...state,
                data: null,
                loading: true,
                error: null,
                loaded: false,
            };
        case recipientActions.FETCH_RECIPIENT_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null,
                loaded: true,
            };
        case recipientActions.FETCH_RECIPIENT_DATA_FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload,
                loaded: false,
            };
        case recipientActions.RESET_RECIPIENT:
            // this will delay the trigger giving everything else a moment to do what it needs to
            return {
                ...initialState,
                current: state.current,
                resetting: true,
            };
        case recipientActions.RESET_RECIPIENT_SUCCESS:
            return {
                ...initialState,
                current: state.current,
            };

        case recipientActions.UPDATE_RECIPIENT_DATA_START:
            return {
                ...state,
                loading: true,
                saved: false,
                error: null,
                loaded: false,
            };
        case recipientActions.UPDATE_RECIPIENT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                saved: true,
                error: null,
                loaded: true,
            };
        case recipientActions.UPDATE_RECIPIENT_DATA_FAILURE:
            return {
                ...state,
                loading: null,
                saved: false,
                error: action.payload,
                loaded: false,
            };

        case recipientActions.TOGGLE_RECIPIENT_STATUS_START:
            return {
                ...state,
                loading: true,
                saved: false,
                error: null,
                loaded: false,
            };
        case recipientActions.TOGGLE_RECIPIENT_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                saved: true,
                error: null,
                loaded: true,
            };
        case recipientActions.TOGGLE_RECIPIENT_STATUS_FAILURE:
            return {
                ...state,
                loading: null,
                saved: false,
                error: action.payload,
                loaded: false,
            };

        case recipientActions.DELETE_RECIPIENT_DATA_START:
            return {
                ...state,
                payload: action.payload,
                error: null,
                deleted: false,
            };
        case recipientActions.DELETE_RECIPIENT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                deleted: true,
            };
        case recipientActions.DELETE_RECIPIENT_DATA_FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload,
                deleted: false,
            };

        case recipientActions.CLEAR_DELETED_STATUS:
            return {
                ...state,
                deleted: false,
            };

        case recipientActions.CLEAR_RECIPIENT_DATA:
        case LOGOUT:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default recipientReducer;
