import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {lang} from 'config/lang';
import {List, Typography, Space, FontAwesome} from 'components/UI';

const {Item} = List;
const {Meta} = Item;
const {Text, Link} = Typography;

const CatalogModalItem = ({companyPrimaryProducts, product}) => {
    const storefront = useMemo(() => {
        return companyPrimaryProducts?.find((companyProduct) => companyProduct.id === product);
    }, [companyPrimaryProducts, product]);

    return (
        <Item
            key={product}
            style={{paddingLeft: 0, paddingRight: 0}}
            actions={[
                storefront.SampleCatalogLink && (
                    <Link href={storefront.SampleCatalogLink} target="_blank">
                        <Space size={6} align="start">
                            {lang.preview}
                            <FontAwesome icon="square-arrow-up-right" />
                        </Space>
                    </Link>
                ),
            ]}
        >
            <Meta title={<Text>{storefront.description}</Text>} />
        </Item>
    );
};

CatalogModalItem.propTypes = {
    companyPrimaryProducts: PropTypes.array,
    product: PropTypes.any.isRequired,
};

export default CatalogModalItem;
