import React from 'react';
import {Route} from 'react-router-dom';
import isObject from 'lodash/isObject';
import {deepFlattenObject} from 'library/helpers/routing';
import {useTitle} from 'hooks/routes/useTitle';

export const getRouteComponents = (routes, props) => {
    /**
     * @param {PageRoute} route
     * @param {Number} index
     */
    return routes.map((route, index) => {
        const {SetPageTitle} = useTitle();

        const {exact, component: Component, path} = route;

        return (
            <Route
                key={index}
                loading={props.loading}
                exact={exact}
                path={isObject(path) ? deepFlattenObject(path) : path}
            >
                {SetPageTitle(route?.title ?? false, route.name)}
                <Component {...route.props} />
            </Route>
        );
    });
};
