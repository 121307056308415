// LOAD_USER_INTEGRATIONS
export const LOAD_USER_INTEGRATIONS_START = 'LOAD_USER_INTEGRATIONS_START';
export const loadUserIntegrationsStart = (payload) => ({type: LOAD_USER_INTEGRATIONS_START, payload});
export const LOAD_USER_INTEGRATIONS_COMPLETE = 'LOAD_USER_INTEGRATIONS_COMPLETE';
export const loadUserIntegrationsComplete = (payload) => ({type: LOAD_USER_INTEGRATIONS_COMPLETE, payload});

//INITIALIZE_INTEGRATION
export const INITIALIZE_INTEGRATION_START = 'INITIALIZE_INTEGRATION_START';
export const initializeIntegrationStart = (payload) => ({type: INITIALIZE_INTEGRATION_START, payload});

export const INITIALIZE_INTEGRATION_COMPLETE = 'INITIALIZE_INTEGRATION_COMPLETE';
export const initializeIntegrationComplete = (payload) => ({type: INITIALIZE_INTEGRATION_COMPLETE, payload});

// RUN_INTEGRATION_FILTER
export const RUN_INTEGRATION_FILTER_START = 'RUN_INTEGRATION_FILTER_START';
export const runIntegrationFilterStart = (payload) => ({type: RUN_INTEGRATION_FILTER_START, payload});
export const RUN_INTEGRATION_FILTER_COMPLETED = 'RUN_INTEGRATION_FILTER_COMPLETED';
export const runIntegrationFilterCompleted = (payload) => ({type: RUN_INTEGRATION_FILTER_COMPLETED, payload});

// INTEGRATIONS_DATA_SYNC
export const INTEGRATIONS_DATA_SYNC_COMPLETE = 'INTEGRATIONS_DATA_SYNC_COMPLETE';
export const integrationsDataSyncComplete = (payload) => ({type: INTEGRATIONS_DATA_SYNC_COMPLETE, payload});

// INTEGRATIONS_DATA_SYNC
export const IMPORT_SET_GROUPS = 'IMPORT_SET_GROUPS';
export const importSetGroup = (payload) => ({type: IMPORT_SET_GROUPS, payload});

// LOAD_SYNCS
export const LOAD_SYNCS_START = 'LOAD_SYNCS_START';
export const loadSyncsStart = (payload) => ({type: LOAD_SYNCS_START, payload});

export const LOAD_SYNCS_COMPLETE = 'LOAD_SYNCS_COMPLETE';
export const loadSyncsComplete = (payload) => ({type: LOAD_SYNCS_COMPLETE, payload});

// LOAD_SYNC
export const LOAD_SYNC_START = 'LOAD_SYNC_START';
export const loadSyncStart = (payload) => ({type: LOAD_SYNC_START, payload});

export const LOAD_SYNC_COMPLETE = 'LOAD_SYNC_COMPLETE';
export const loadSyncComplete = (payload) => ({type: LOAD_SYNC_COMPLETE, payload});

// SETTERS
export const SET_CACHING_INTEGRATION = 'SET_CACHING_INTEGRATION';
export const setCachingIntegration = (payload) => ({type: SET_CACHING_INTEGRATION, payload});

export const SET_CURRENT_INTEGRATION = 'SET_CURRENT_INTEGRATION';
export const setCurrentIntegration = (payload) => ({type: SET_CURRENT_INTEGRATION, payload});

export const CLEAR_INTEGRATION_ALERTS = 'CLEAR_INTEGRATION_ALERTS';
export const clearIntegrationAlerts = () => ({type: CLEAR_INTEGRATION_ALERTS});

export const SET_SYNC_ERROR = 'SET_SYNC_ERROR';
export const setIntegrationError = (payload) => ({type: SET_SYNC_ERROR, payload});

export const SET_SUCCESS = 'SET_SUCCESS';

export const setIntegrationSuccess = (payload) => ({type: SET_SUCCESS, payload});

export const IMPORT_CONTACTS_START = 'IMPORT_CONTACTS_START';
export const importContactsStart = (payload) => ({type: IMPORT_CONTACTS_START, payload});

export const IMPORT_CONTACTS_COMPLETE = 'IMPORT_CONTACTS_COMPLETE';
export const importContactsComplete = () => ({type: IMPORT_CONTACTS_COMPLETE});

export const SAVE_SYNC_JOB_START = 'SAVE_SYNC_JOB_START';
export const saveSyncJobStart = (payload) => ({type: SAVE_SYNC_JOB_START, payload});

export const UPDATE_SYNC_JOB = 'UPDATE_SYNC_JOB';
export const updateSyncJob = (payload) => ({type: UPDATE_SYNC_JOB, payload});

export const UPDATE_SYNC_JOB_START = 'UPDATE_SYNC_JOB_START';
export const updateSyncJobStart = (payload) => ({type: UPDATE_SYNC_JOB_START, payload});
export const UPDATE_SYNC_JOB_COMPLETE = 'UPDATE_SYNC_JOB_COMPLETE';
export const updateSyncJobComplete = (payload) => ({type: UPDATE_SYNC_JOB_COMPLETE, payload});

export const DELETE_SYNC_JOBS_START = 'DELETE_SYNC_JOBS_START';
export const deleteSyncJobsStart = (payload) => ({type: DELETE_SYNC_JOBS_START, payload});

export const DELETE_SYNC_JOBS_COMPLETE = 'DELETE_SYNC_JOBS_COMPLETE';
export const deleteSyncJobsComplete = (payload) => ({type: DELETE_SYNC_JOBS_COMPLETE, payload});

export const UPDATE_SYNC_MAP_START = 'UPDATE_SYNC_MAP_START';
export const updateSyncMapStart = (payload) => ({type: UPDATE_SYNC_MAP_START, payload});

export const UPDATE_SYNC_MAP_COMPLETE = 'UPDATE_SYNC_MAP_COMPLETE';
export const updateSyncMapComplete = (payload) => ({type: UPDATE_SYNC_MAP_COMPLETE, payload});

export const RESET_SYNC_EDIT_MODE = 'RESET_SYNC_EDIT_MODE';
export const resetSyncEditMode = () => ({type: RESET_SYNC_EDIT_MODE});

export const RESET_SYNC_JOB = 'RESET_SYNC_JOB';
export const resetSyncJob = () => ({type: RESET_SYNC_JOB});

export const CREATE_SYNC_START = 'CREATE_SYNC_START';
export const createSyncStart = (payload) => ({type: CREATE_SYNC_START, payload});

export const CREATE_SYNC_COMPLETE = 'CREATE_SYNC_COMPLETE';
export const createSyncComplete = (payload) => ({type: CREATE_SYNC_COMPLETE, payload});

export const SET_SAVING_INTEGRATION = 'SET_SAVING_INTEGRATION';
export const setSavingIntegration = (payload) => ({type: SET_SAVING_INTEGRATION, payload});

export const SET_UPDATING_INTEGRATION = 'SET_UPDATING_INTEGRATION';
export const setUpdatingIntegration = (payload) => ({type: SET_UPDATING_INTEGRATION, payload});

export const RESET_SYNC_COMPLETE = 'SET_COMPLETED_SYNC';
export const resetSyncComplete = (payload) => ({type: RESET_SYNC_COMPLETE, payload});

export const UPDATE_SYNC_NAME_START = 'UPDATE_SYNC_NAME_START';
export const updateSyncNameStart = (payload) => ({type: UPDATE_SYNC_NAME_START, payload});

export const UPDATE_SYNC_NAME_COMPLETE = 'UPDATE_SYNC_NAME_COMPLETE';
export const updateSyncNameComplete = () => ({type: UPDATE_SYNC_NAME_COMPLETE});

export const UPDATE_SYNC_NAME_ERROR = 'UPDATE_SYNC_NAME_ERROR';
export const updateSyncNameError = (payload) => ({type: UPDATE_SYNC_NAME_ERROR, payload});

export const UPDATE_SYNC_STATUS_START = 'UPDATE_SYNC_STATUS_START';
export const updateSyncStatusStart = (payload) => ({type: UPDATE_SYNC_STATUS_START, payload});

export const UPDATE_SYNC_STATUS_COMPLETE = 'UPDATE_SYNC_STATUS_COMPLETE';
export const updateSyncStatusComplete = (payload) => ({type: UPDATE_SYNC_STATUS_COMPLETE, payload});

export const RESET_SYNCS = 'RESET_SYNCS';
export const resetSyncs = () => ({type: RESET_SYNCS});
export const RESET_SYNC_FILTERS = 'RESET_SYNC_FILTERS';
export const resetSyncFilters = () => ({type: RESET_SYNC_FILTERS});

export const RESET_SYNC_CREATED = 'RESET_SYNC_CREATED';
export const resetSyncCreated = () => ({type: RESET_SYNC_CREATED});

export const LOAD_INSTALLED_INTEGRATIONS_START = 'LOAD_INSTALLED_INTEGRATIONS_START';
export const loadInstalledIntegrationsStart = (payload) => ({type: LOAD_INSTALLED_INTEGRATIONS_START, payload});

export const LOAD_INSTALLED_INTEGRATIONS_COMPLETE = 'LOAD_INSTALLED_INTEGRATIONS_COMPLETE';
export const loadInstalledIntegrationsComplete = (payload) => ({type: LOAD_INSTALLED_INTEGRATIONS_COMPLETE, payload});

export const SET_CONFIGURED_FILTERS = 'SET_CONFIGURED_FILTERS';
export const setConfiguredFiltersStore = (payload) => ({type: SET_CONFIGURED_FILTERS, payload});
