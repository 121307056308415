export const mapTeamNames = (teams, data) => {
    return teams && data && data.filter((f) => teams.includes(f.id)).map((i) => i.name);
};

export const mapTeamsToParent = (data, id = null, link = 'id') => {
    if (!data) return null;

    let output =
        data.length &&
        data
            .filter((t) => t[link] === id)
            .map((t) => ({
                name_plus: [t.name, t.description],
                name: t.name,
                id: t.id,
                members: t.accounts_count,
                description: t.description,
                roles: t.roles,
                children: mapTeamsToParent(data, t.id, 'parent_team_id'),
            }));

    //If top-level call doesn't return the same number of teams, lets append the missing teams
    //This would happen if we have two teams on the same level
    if (output && data.length !== output && link === 'id') {
        //Get all team ids currently mapped
        const teamIds = getMappedTeamIds(output);

        //Determine which ones are missing and add them to the list
        const missingTeams = data
            .filter((t) => teamIds.indexOf(t.id) < 0)
            .map((t) => ({
                name_plus: [t.name, t.description],
                name: t.name,
                id: t.id,
                members: t.accounts_count,
                description: t.description,
                roles: t.roles,
                children: mapTeamsToParent(data, t.id, 'parent_team_id'),
            }));

        output = [...output, ...missingTeams];
    }

    return output;
};

// Drill down and retreive all the ids
export const getMappedTeamIds = (teams) => {
    let teamIds = [];
    teams.forEach((team) => {
        let childrenTeamIds = [];
        if (team.children.length > 0) {
            childrenTeamIds = getMappedTeamIds(team.children);
        }

        teamIds = [...teamIds, team.id, ...childrenTeamIds];
    });

    return teamIds;
};

export const mapDescendantRoles = (teams, descendants) => {
    if (!teams || !descendants) return null;

    return descendants.map((item) => {
        let item2 = teams.find((i2) => i2.id === item.id);
        return item2 ? {...item, ...item2} : item;
    });
};

export const mapNestedTeamsSelect = (data) =>
    data &&
    data.map((o) => ({
        value: o.id,
        title: o.name,
        children: mapNestedTeamsSelect(o.children),
    }));

export const filterCurrentTeam = (data, teamID) => {
    if (!data || !teamID) return null;
    return data && data.filter((i) => i !== teamID);
};
