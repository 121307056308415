import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {FontAwesome, Space, ColorPicker} from 'components/UI';
import {FormItem, hasError} from 'components/FormikForm';
import {Input} from 'antd';

const {TextArea} = Input;

/**
 * FormInput is a middleware object between Formik and Antd.
 * Here we use formic to handle form and apply the affects to the antd component
 * It **MUST ALWAYS** be wrapped in <FormItem />
 *
 * The MaskInput is a third party component. https://www.npmjs.com/package/antd-mask-input
 *
 * @param props.autofocus - autofocus input
 * @param props.field - contains all the formik properties
 * @param props.input -  any additional properties to be set on the input
 * @param props.ant - any additional antd properties to be set on the input
 * @param props.isSubmitting - override for any other automatic submitting we handle
 * @param props.disabled
 * @param props.type - element type
 * @param props.showError (default: false) - if false the error will not be displayed
 *
 * formic refs: https://jaredpalmer.com/formik/docs/api/useField
 * @returns {*}
 * @constructor
 */
const FormInput = (props) => {
    const inputRef = useRef(null);
    const disabled = props.form.isSubmitting || props.isSubmitting ? true : props.disabled || false;
    const inputProps = {
        ...props.field,
        ...props.ant,
        ...props.input,
    };

    const handleChange = (e) => {
        const {form, onChange, field} = props;
        const parsedValue = props.parser(e.target.value);

        form.setValues({...form.values, [field.name]: parsedValue});
        if (typeof onChange === 'function') onChange(parsedValue);
    };

    const changeColorInput = (value) => {
        const {form, onChange, field} = props;

        const parsedValue = value.replace('#', '');
        form.setValues({...form.values, [field.name]: parsedValue});
        if (typeof onChange === 'function') onChange(parsedValue);
    };

    useEffect(() => {
        if (inputRef?.current && props?.autofocus) {
            inputRef.current.focus();
        }

        return () => {
            inputRef.current = null;
        };
    }, [props?.autofocus]);

    const renderColorPicker = () => {
        const parsedValue = props.field.value?.replace('#', '');
        return (
            <Space size={12}>
                <Input
                    ref={(ref) => (inputRef.current = ref)}
                    type={props.type}
                    {...inputProps}
                    value={props.formatter(parsedValue)}
                    onChange={handleChange}
                    disabled={disabled}
                    size={props.size}
                    addonBefore={'#'}
                    allowClear={{
                        clearIcon: <FontAwesome icon="circle-xmark" type="solid" />,
                    }}
                />
                <ColorPicker color={parsedValue} onChange={(value) => changeColorInput(value)} />
            </Space>
        );
    };

    const renderInput = () => {
        delete inputProps.value;

        return (
            <Input
                ref={(ref) => (inputRef.current = ref)}
                type={props.type}
                {...inputProps}
                value={props.formatter(props.field.value)}
                onChange={handleChange}
                disabled={disabled}
                size={props.size}
            />
        );
    };

    const renderPassword = () => {
        return (
            <Input.Password
                autoComplete="off"
                {...inputProps}
                onChange={handleChange}
                disabled={disabled}
                size={props.size}
            />
        );
    };

    const renderTextarea = () => (
        <div>
            <TextArea
                rows={props.rows}
                {...inputProps}
                disabled={disabled}
                value={props.field.value}
                onChange={handleChange}
            />
        </div>
    );

    const renderField = () => {
        switch (props.type) {
            case 'color_picker':
                return renderColorPicker();
            case 'textarea':
                return renderTextarea();
            case 'password':
                return renderPassword();
            default:
                return renderInput();
        }
    };

    //console.log(hasError(props.field.name, props.form))

    return (
        <FormItem
            {...props}
            help={props.help}
            error={hasError(props.field.name, props.form)}
            skelType="input"
            disabled={disabled}
        >
            {renderField()}
        </FormItem>
    );
};

// TODO: Deprecated, add to params
FormInput.defaultProps = {
    formatter: (value) => value,
    parser: (value) => value,
    isSubmitting: false,
    rows: 4,
    showError: false,
    type: 'text',
    size: 'large',
};

FormInput.propTypes = {
    ant: PropTypes.object,
    autofocus: PropTypes.bool,
    disabled: PropTypes.bool,
    extra: PropTypes.any,
    field: PropTypes.object,
    form: PropTypes.object,
    formatter: PropTypes.func,
    help: PropTypes.any,
    input: PropTypes.object,
    isSubmitting: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    parser: PropTypes.func,
    rows: PropTypes.number,
    showError: PropTypes.bool,
    type: PropTypes.string,
    defaultColor: PropTypes.string,
    value: PropTypes.string,
    size: PropTypes.any,
};

export default FormInput;
