import React, {useCallback} from 'react';
import {Typography} from 'antd';
import {CurrencyValue, Space} from 'components/UI';
import {currencyFormatter} from 'library/helpers/format';

const {Text} = Typography;

export default function useSelectList() {
    const renderName = useCallback((profile, member) => {
        return (
            <Space size={4}>
                <Text>
                    {member.first_name} {member.last_name} ({currencyFormatter(member.credit_available)})
                </Text>

                {profile.region?.currency !== 'USD' && (
                    <Text>
                        (
                        <CurrencyValue
                            amount={member.credit_available}
                            region={profile?.region}
                            exchange_rate
                            approximate
                        />
                        )
                    </Text>
                )}
            </Space>
        );
    }, []);

    const getFromSelectList = useCallback(
        /**
         *
         * @param {Object} profile
         * @param {Array} descendantAccounts
         * @param {Object} form
         * @return {*[]}
         */
        (profile, descendantAccounts, form) => {
            const sortedFromList = descendantAccounts.slice().sort((a, b) => a.first_name.localeCompare(b.first_name));

            return sortedFromList
                .filter((member) => member.id !== form.getFieldValue('to') && member.credit_available > 0)
                .map((member) => {
                    return {
                        value: member.id,
                        label: renderName(profile, member),
                    };
                });
        },
        [renderName],
    );

    const getToSelectList = useCallback(
        /**
         *
         * @param {Object} profile
         * @param {Array} descendantAccounts
         * @param {Number} fromField
         * @return {*|*[]}
         */
        (profile, descendantAccounts, fromField) => {
            const sortedToList = descendantAccounts.slice().sort((a, b) => a.first_name.localeCompare(b.first_name));

            if (!fromField) return [];

            return sortedToList
                .filter((member) => member.status === 'active' && member.id !== fromField)
                .map((member) => ({
                    value: member.id,
                    label: renderName(profile, member),
                }));
        },
        [renderName],
    );

    return {getToSelectList, getFromSelectList, renderName};
}
