import React from 'react';
import {MainNavMenu} from 'components/Layout';
import {Col, Row} from 'components/UI';

const MainNavHorizontal = () => {
    return (
        <Row>
            <Col flex="auto">
                <MainNavMenu mode="horizontal" />
            </Col>
        </Row>
    );
};

export default MainNavHorizontal;
