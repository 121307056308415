const inviteActions = {
  LOOKUP_INVITE_START: 'LOOKUP_INVITE_START',
  LOOKUP_INVITE_SUCCESS: 'LOOKUP_INVITE_SUCCESS',
  LOOKUP_INVITE_ERROR: 'LOOKUP_INVITE_ERROR',

  lookupInviteStart: (payload) => ({
    type: inviteActions.LOOKUP_INVITE_START,
    payload,
  }),
  lookupInviteSuccess: (payload) => ({
    type: inviteActions.LOOKUP_INVITE_SUCCESS,
    payload,
  }),
  lookupInviteError: (error) => ({
    type: inviteActions.LOOKUP_INVITE_ERROR,
    payload: error,
  }),

  RESET_INVITE: 'RESET_INVITE',
  resetInvite: () => {
    return {
      type: inviteActions.RESET_INVITE,
    };
  },
};

export default inviteActions;
