import {createSelector} from 'reselect';
import {
    CARRIER_PRICE,
    GREETING_CARDS,
    PRICES,
    SHIPPING_METHOD_GROUND,
    SLEEVE_PRICE,
    SLEEVES,
} from 'config/orders/physical';
import {calculateTax} from 'library/helpers/orders/physical';
import {match} from 'library/helpers/utilities';
import {shouldMergeOrderItems} from 'redux/orderSummary/saga';

const orderSummary = (state) => state.orderSummary;
const coupons = (state) => state.coupons.data;
const shippingMethods = (state) => state.shippingRates.data;
const addresses = (state) => state.shippingAddressCampaign.data;

export const getOrderSummaryFormData = createSelector([orderSummary], (summary) => {
    return summary['form'].data;
});
export const getOrderSummarySelectedAddress = createSelector(
    [orderSummary],
    (summary) => summary['form'].data.shipping_address_id || 0,
);

const getNumber = (value, defaultValue = 0) => Number(value) || defaultValue;
const getShippingRate = (methods, id) => getNumber(methods?.find?.((method) => method.id === id)?.rate);

const combineOrderSummaryItems = (items) => {
    return items.reduce((acc, order) => {
        const existingOrder = acc.find((item) => shouldMergeOrderItems(item, order));

        if (existingOrder) {
            existingOrder.quantity += order.quantity;
        } else {
            acc.push(order);
        }

        return acc;
    }, []);
};

const getOrderSummaryItems = (items) => {
    return combineOrderSummaryItems(items).map((item) => {
        const isProduct = item.extra && item.extra.type === 'products';

        item.name = isProduct ? item['product_name'] : item['saved_design_name'];
        item.cardPath = isProduct ? item['product_card_path'] : item['saved_design_card_path'];
        item.carrierPath = isProduct
            ? item['product_card_path']
            : item['saved_card_path'] === ''
            ? item['product_card_path']
            : item['saved_design_card_path'];

        return item;
    });
};

const getRawOrderSummaryItemTotals = (items) => {
    return {
        count: items.length,
        rawTotal: items.reduce((carry, item) => {
            const carrierPrice = item.has_carriers ? CARRIER_PRICE * item.quantity : 0;
            const sleevePrice = item.has_sleeves ? SLEEVE_PRICE * item.quantity : 0;

            const itemCost = item.quantity * item.usd_unit_price;

            return carry + itemCost + carrierPrice + sleevePrice;
        }, 0),
    };
};

const calculateOrderSummary = (summary) => {
    const {rawTotal: total, count} = getRawOrderSummaryItemTotals(summary.data.items);

    summary.data.items = getOrderSummaryItems(summary.data.items);
    summary.data.total = total;
    summary.data.count = count;

    return summary;
};

export const getOrderSummary = createSelector([orderSummary], (summary) => calculateOrderSummary(summary));

export const getOrderSummaryData = createSelector([orderSummary], (summary) => calculateOrderSummary(summary).data);

export const getCalculatedOrderSummary = createSelector(
    [orderSummary, coupons, shippingMethods, addresses],
    (summary, coupon, shippingMethods, addresses) => {
        const {rawTotal} = getRawOrderSummaryItemTotals(summary.data.items);
        const form = summary.form?.data;

        const freeShipping = form.shipping_method_id === SHIPPING_METHOD_GROUND && getNumber(rawTotal) >= 500;

        const shippingCost = getShippingRate(shippingMethods, form.shipping_method_id);

        const getTotal = (discount, tax) => {
            const summaryShippingCosts = getNumber(summary.data.shippingCosts);
            const summaryTotal = getNumber(rawTotal);
            const adjustedTotal = summaryTotal + summaryShippingCosts;

            const adjustedDiscount =
                freeShipping && shippingMethods
                    ? getNumber(discount) + getNumber(getShippingRate(shippingMethods, SHIPPING_METHOD_GROUND))
                    : getNumber(discount);

            return Number(adjustedTotal + shippingCost + getNumber(tax) - adjustedDiscount).toFixed(2);
        };

        const getDiscount = () => {
            if (!coupon) return 0;

            const [discountType = 0, price = ''] = match(coupon?.charge_type?.Code, {
                SLEEVE: () => [SLEEVES, PRICES['sleeves']],
                CARRIER: () => [GREETING_CARDS, PRICES['greetingCards']],
                default: () => [],
            });

            const itemCount = summary?.data?.items?.reduce?.((carry, item) => {
                if (
                    (item.has_carriers && discountType === GREETING_CARDS) ||
                    (item.has_sleeves && discountType === SLEEVES)
                ) {
                    carry += item.quantity;
                }

                return carry;
            }, 0);

            if (itemCount === 0) return 0;

            const percent = (coupon.percent_discount ?? 0) / 100;
            const itemDiscount = percent * itemCount * price;

            return Number(itemDiscount).toFixed(2);
        };

        const getTaxes = () => {
            if (!form.shipping_address_id) return;

            const selectedAddress = addresses.find((address) => address.id === form.shipping_address_id);

            const {tax_rate: taxRate} = selectedAddress;

            return calculateTax(summary.data, taxRate, discount);
        };

        const discount = getDiscount();
        const tax = getTaxes();

        const total = getTotal(discount, tax);

        return {
            coupon,
            freeShipping,
            fee_percentage: summary.data.items?.[0]?.fee_percentage,
            fee_total: summary.data.items?.reduce((carry, item) => carry + item.fee_total, 0),
            items: getOrderSummaryItems(summary.data.items),
            total: Math.max(total, 0),
            shippingCost: Math.max(shippingCost, 0),
            tax: Math.max(tax, 0),
            discount,
        };
    },
);
