import {api} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';
import {all, put, takeLeading} from 'redux-saga/effects';
import businessHoursActions from './actions';

function* fetchBusinessHours() {
    try {
        const hours = yield api.get(apiEndpoints.businessHours.get).then((res) => res.data);
        yield put(businessHoursActions.fetchBusinessHoursSuccess(hours));
    } catch (e) {
        yield put(businessHoursActions.fetchBusinessHoursError(e));
    }
}

export default function* businessHoursSaga() {
    yield all([takeLeading(businessHoursActions.FETCH_BUSINESS_HOURS_START, fetchBusinessHours)]);
}
