import React from 'react';

const icon = () => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" preserveAspectRatio="xMidYMid meet">
        <g fill="#d80027">
            <path d="M115.3 255 c-16.4 -1.7 -30.8 -6.1 -46.3 -14.1 -9.2 -4.7 -21.2 -12.9 -21.8 -14.9 -0.2 -0.7 14.5 -1 41.7 -1 l42.1 0 0 15.5 0 15.5 -4.2 -0.1 c-2.4 -0.1 -7.6 -0.5 -11.5 -0.9z" />
            <path d="M181 128 l0 -116 3.7 1.5 c10.2 4.3 24.4 14.8 35.6 26.4 16.9 17.5 27.7 38.2 33.3 63.9 1.3 5.5 1.7 12.2 1.7 23.7 0 23.6 -3.9 40.4 -14.1 59.7 -9.3 17.9 -26.3 36.7 -42.7 47.4 -7.5 4.8 -15.7 9.4 -17 9.4 -0.3 0 -0.5 -52.2 -0.5 -116z" />
            <path d="M19.3 194.7 c-4 -6 -10 -19.2 -12.7 -27.5 -1.4 -4.6 -2.6 -8.5 -2.6 -8.8 0 -0.2 27.2 -0.4 60.5 -0.4 l60.5 0 0 -40.5 0 -40.5 3.4 0 c3.1 0 3.8 0.6 8.6 8 l5.3 8 5.3 0 c5.3 0 5.4 0 5.4 3 0 1.7 -1.1 5.3 -2.5 8.1 l-2.6 5 5 -2.6 c2.8 -1.4 6.4 -2.5 8.1 -2.5 3 0 3 0 3 5.5 l0 5.5 5.5 2.7 c5 2.5 5.5 3 5.5 6 0 3.2 -0.4 3.5 -9.5 8 -7.3 3.7 -9.4 5.1 -8.8 6.3 0.3 0.8 0.8 3.6 1.1 6.3 l0.4 4.7 -10.6 0 -10.5 0 -0.3 8.3 c-0.3 8.1 -0.3 8.2 -3 8.5 l-2.8 0.3 0 16 0 15.9 -54.7 0 -54.8 0 -2.2 -3.3z" />
        </g>
        <g fill="#0052b4">
            <path d="M0.6 129.4 c-0.7 -1.7 1.1 -22.1 2 -23.6 0.3 -0.4 2.2 -0.8 4.3 -0.8 3.7 0 3.9 -0.2 4.3 -3.4 0.3 -2.6 0 -3.6 -1.2 -3.9 -0.9 -0.2 -2.1 -2.1 -2.7 -4.1 -1 -3.1 -0.8 -4.6 0.6 -8.9 2.7 -7.7 9.4 -20.5 15.3 -29.4 5.9 -8.7 24.9 -28.5 25.5 -26.5 0.3 0.7 2.1 1.2 4.4 1.2 3.5 0 3.9 -0.3 3.9 -2.4 0 -1.4 0.4 -2.7 1 -3.1 0.5 -0.3 0.7 -1.1 0.5 -1.8 -0.6 -1.5 3.7 -4.4 14 -9.5 14.2 -7 31 -11.3 49.8 -12.8 l8.7 -0.6 0 41.8 c0 36.9 -0.2 41.6 -1.5 39.8 -1.3 -1.7 -1.4 1 -1.5 23.9 l0 25.7 -63.4 0 c-57.7 0 -63.4 -0.1 -64 -1.6z m56.6 -27.9 c0.3 -2.5 0 -3.5 -0.9 -3.5 -0.8 0 -1.7 -1 -2.2 -2.2 l-0.8 -2.2 -0.5 2.2 c-0.4 1.4 -1.4 2.2 -3 2.3 -1.2 0 -1.7 0.3 -1 0.6 0.6 0.2 1.2 1.8 1.2 3.4 0 2.6 0.3 2.9 3.4 2.9 3.1 0 3.4 -0.3 3.8 -3.5z m46 0 c0.3 -2.4 0 -3.5 -0.9 -3.5 -0.6 0 -1.6 -0.7 -2.1 -1.6 -0.8 -1.5 -1 -1.5 -1.5 0 -0.4 0.9 -1.5 1.6 -2.5 1.6 -1.5 0 -1.7 0.6 -1.4 3.5 0.4 3.3 0.6 3.5 4.2 3.5 3.6 0 3.8 -0.2 4.2 -3.5z m-0.2 -36.9 c0 -1.2 0.5 -2.7 1.2 -3.4 0.9 -0.9 0.6 -1.2 -1.5 -1.2 -1.9 0 -2.7 -0.5 -2.7 -1.7 -0.1 -1.7 -0.1 -1.7 -1.1 0 -0.5 0.9 -2.2 1.7 -3.7 1.8 -1.5 0 -2.1 0.3 -1.4 0.6 0.6 0.2 1.2 1.9 1.2 3.7 0 3.1 0.1 3.1 4 2.8 3.3 -0.3 4 -0.8 4 -2.6z m-46 0.1 c0 -1.3 0.5 -2.8 1.2 -3.5 0.9 -0.9 0.6 -1.2 -1.5 -1.2 -1.9 0 -2.7 -0.5 -2.8 -1.7 -0.1 -1.7 -0.2 -1.7 -0.6 0 -0.3 1.1 -1.5 1.7 -3.6 1.7 -2.7 0.1 -2.9 0.2 -1.4 1.1 0.9 0.5 1.7 2.1 1.7 3.4 0 2.2 0.5 2.5 3.5 2.5 3 0 3.5 -0.3 3.5 -2.3z m46 -37 c0 -1.3 0.5 -2.8 1.2 -3.5 0.9 -0.9 0.6 -1.2 -1.5 -1.2 -1.9 0 -2.7 -0.5 -2.7 -1.7 -0.1 -1.7 -0.1 -1.7 -1.1 0 -0.5 0.9 -1.8 1.7 -2.7 1.7 -1.5 0 -1.7 0.7 -1.4 3.5 0.4 3.3 0.6 3.5 4.3 3.5 3.4 0 3.9 -0.3 3.9 -2.3z" />
        </g>
        <g fill="#f0f0f0">
            <path d="M128 242.1 l0 -14.1 -39.9 0 -39.8 0 -8.5 -8.2 c-8 -7.9 -16.1 -17.7 -18.7 -22.5 l-1.2 -2.3 54.1 0 54 0 0 -15.9 0 -16 2.8 -0.3 c2.7 -0.3 2.7 -0.4 3 -8.5 l0.3 -8.3 10.4 0 c6.2 0 10.5 -0.4 10.5 -1 0 -0.5 -1 -3 -2.2 -5.5 -1.2 -2.4 -2 -4.6 -1.7 -4.9 0.2 -0.2 5.1 -2.7 10.9 -5.6 l10.4 -5.2 -5.7 -2.9 -5.7 -2.9 0 -5.5 0 -5.5 -10.1 5.1 c-5.6 2.7 -10.3 4.8 -10.6 4.6 -0.2 -0.3 1.9 -5 4.6 -10.6 l5.1 -10.1 -5.3 0 -5.4 0 -5.6 -8.5 -5.7 -8.5 0 -39.6 0 -39.5 7.3 0.6 c16.4 1.4 30.6 4.8 43 10.2 l5.7 2.6 0 114.9 0 114.9 -4.7 2 c-12.8 5.5 -27.8 9.1 -43.5 10.5 l-7.8 0.6 0 -14.1z" />
            <path d="M4.5 159.8 c-1.3 -4 -2.9 -14.5 -3.4 -22.5 l-0.6 -9.3 63.7 0 63.8 0 0 16.5 0 16.5 -61.5 0 c-48.4 0 -61.7 -0.3 -62 -1.2z" />
            <path d="M13.1 111.4 c-5 -3.9 -5.8 -4 -9 -1.7 l-2.4 1.6 0.7 -4.8 c0.8 -5.3 4.4 -19.4 5.3 -20.9 0.4 -0.5 1.4 1.2 2.3 4 l1.7 4.9 6.1 0.5 6.2 0.5 -4.9 3.3 -4.9 3.4 1.9 6.1 c1 3.3 1.7 6.2 1.6 6.3 -0.1 0.1 -2.2 -1.3 -4.6 -3.2z" />
            <path d="M43 114.2 c0 -0.5 0.8 -3.3 1.9 -6.3 l1.8 -5.5 -4.8 -3.4 c-2.7 -1.9 -4.9 -3.7 -4.9 -4 0 -0.3 2.8 -0.5 6.1 -0.5 l6.2 0 1.8 -5.4 c1.1 -3 2.1 -5.6 2.3 -5.8 0.2 -0.2 1.3 2.2 2.3 5.4 l2 5.8 6.1 0.5 6.2 0.5 -5 3.4 c-2.7 1.9 -5 3.5 -5 3.6 0 0.1 0.9 2.8 2 6 1.1 3.2 2 6 2 6.2 0 0.1 -2.2 -1.3 -4.9 -3.2 -2.6 -1.9 -5.3 -3.5 -5.8 -3.5 -0.6 0 -3.1 1.6 -5.7 3.5 -2.5 2 -4.6 3.2 -4.6 2.7z" />
            <path d="M105 111.5 c-2.5 -1.9 -5 -3.5 -5.6 -3.5 -0.5 0 -2.9 1.4 -5.2 3.1 -2.4 1.7 -4.5 2.9 -4.8 2.7 -0.2 -0.3 0.3 -2.4 1.1 -4.8 2.2 -6.1 2 -6.7 -3 -10.2 -2.5 -1.8 -4.5 -3.4 -4.5 -3.7 0 -0.3 2.8 -0.6 6.2 -0.6 l6.2 0 1.5 -5.2 c0.9 -2.8 1.9 -5.4 2.2 -5.8 0.4 -0.3 1.5 2 2.5 5.2 l1.9 5.8 6.3 0.3 6.4 0.3 -4.4 3.1 c-2.4 1.7 -4.6 3.4 -5.1 3.7 -0.4 0.4 0.2 3.4 1.3 6.6 1.1 3.2 1.9 5.9 1.7 6.1 -0.1 0.1 -2.2 -1.3 -4.7 -3.1z" />
            <path d="M14.6 75.1 c-1.5 -1.6 -1.6 -2.3 -0.5 -4.3 1.2 -2.3 1.2 -2.3 2.5 1.2 0.7 1.9 1.3 3.8 1.3 4.3 0.2 1.3 -1.6 0.7 -3.3 -1.2z" />
            <path d="M43 76.7 c0 -0.2 0.9 -2.9 1.9 -5.9 l1.9 -5.5 -5.2 -3.9 -5.1 -3.9 6.5 -0.3 6.5 -0.3 1.9 -6.2 1.9 -6.2 2 6.2 2.1 6.2 6.5 0.3 6.6 0.3 -5.2 3.8 -5.1 3.7 1.8 6 c1 3.3 1.6 6 1.4 6 -0.2 0 -2.6 -1.6 -5.3 -3.5 l-4.8 -3.5 -4.6 3.5 c-4.2 3.2 -5.7 4 -5.7 3.2z" />
            <path d="M89 76.7 c0 -0.3 0.7 -2.4 1.5 -4.7 2.2 -6.3 1.9 -7.2 -3.4 -11 l-5 -3.5 6.6 -0.5 6.6 -0.5 2.1 -6 2 -6 2 6.3 2 6.2 6.5 0 6.6 0.1 -3.4 2.7 c-8 6.3 -7.4 5 -5.2 11.5 1.1 3.1 1.7 5.7 1.4 5.7 -0.3 0 -2.7 -1.6 -5.3 -3.5 l-4.7 -3.4 -3.4 2.5 c-3.8 2.9 -6.9 4.7 -6.9 4.1z" />
            <path d="M21 59 c0.6 -1.1 1.6 -2 2.2 -2 0.6 0 0.3 0.9 -0.7 2 -1 1.1 -2 2 -2.2 2 -0.2 0 0.1 -0.9 0.7 -2z" />
            <path d="M43.5 37.9 c2.1 -6.9 3.5 -8.7 10.2 -13.3 6.3 -4.3 7.4 -4.7 11.9 -4.4 l4.9 0.3 -5.2 3.7 -5.2 3.7 1.9 5.7 c1.1 3.2 1.9 5.9 1.7 6 -0.1 0.2 -2.6 -1.3 -5.4 -3.2 l-5 -3.6 -5.3 3.8 c-4.9 3.6 -5.2 3.6 -4.5 1.3z" />
            <path d="M89 39.2 c0 -0.5 0.7 -2.9 1.5 -5.2 0.8 -2.3 1.5 -4.8 1.5 -5.5 0 -0.6 -2.1 -2.7 -4.7 -4.6 l-4.8 -3.4 6.4 -0.5 6.4 -0.5 2.1 -6 2 -6 2 6.2 2 6.2 6.3 0.3 6.4 0.3 -5 3.7 -4.9 3.7 1.8 6.1 c2 6.5 2 6.4 -4.6 1.7 l-4.2 -2.9 -5.1 3.7 c-2.8 2 -5.1 3.3 -5.1 2.7z" />
        </g>
    </svg>
);

export default icon;
