import brandKitActions from 'redux/brandKits/actions';

const initialState = {
    // brand kits
    loading: false,
    data: null,
    pagination: {
        page: 1,
        per_page: 25,
        order: 'asc',
        sort: 'name',
    },
    error: null,

    // additional brand kit data
    brandKit: {},
    loadingBrandKit: true,
    messages: {
        data: null,
        pagination: null,
    },
    loadingMessages: false,
    senderNames: {
        data: null,
        pagination: null,
    },
    loadingSenderNames: false,
    unsavedFormData: false,
};

export default function brandKitReducer(state = initialState, action) {
    switch (action.type) {
        /*FETCH BRAND KITS*/
        case brandKitActions.FETCH_BRAND_KITS:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case brandKitActions.FETCH_BRAND_KITS_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                pagination: action.payload.meta || state.pagination,
                loading: false,
                error: null,
            };
        case brandKitActions.FETCH_BRAND_KITS_ERROR:
            return {
                ...state,
                data: null,
                loading: false,
                error: action.payload,
            };

        /*SET UNSAVED FORM DATA*/
        case brandKitActions.SET_UNSAVED_FORM_DATA:
            return {
                ...state,
                unsavedFormData: action.payload || false,
            };

        /*RESET BRAND KIT ERROR*/
        case brandKitActions.RESET_BRAND_KIT_ERROR:
            return {
                ...state,
                error: null,
            };

        /*FETCH BRAND KIT*/
        case brandKitActions.FETCH_BRAND_KIT:
            return {
                ...state,
                loadingBrandKit: true,
                error: null,
            };
        case brandKitActions.FETCH_BRAND_KIT_SUCCESS:
            return {
                ...state,
                brandKit: action.payload,
                loadingBrandKit: false,
                error: null,
            };
        case brandKitActions.FETCH_BRAND_KIT_ERROR:
            return {
                ...state,
                brandKit: null,
                loadingBrandKit: false,
                error: action.payload,
            };

        /*UPDATE BRAND KIT FIELD*/
        case brandKitActions.UPDATE_BRAND_KIT_FIELD:
            return {
                ...state,
                brandKit: {
                    ...state.brandKit,
                    [action.payload.field]: action.payload.value,
                },
            };

        /*RESET BRAND KIT*/
        case brandKitActions.RESET_BRAND_KIT:
            return {
                ...state,
                brandKit: {},
            };
        /*RESET BRAND KITS*/
        case brandKitActions.RESET_BRAND_KITS:
            return {
                ...state,
                data: null,
            };
        /*RESET MESSAGES*/
        case brandKitActions.RESET_MESSAGES:
            return {
                ...state,
                messages: {
                    data: null,
                    pagination: null,
                },
            };
        /*RESET SENDER NAMES*/
        case brandKitActions.RESET_SENDER_NAMES:
            return {
                ...state,
                senderNames: {
                    data: null,
                    pagination: null,
                },
            };

        case brandKitActions.RESET_ALL:
            return {
                ...state,
                brandKit: {},
                data: null,
                messages: {
                    data: null,
                    pagination: null,
                },
                senderNames: {
                    data: null,
                    pagination: null,
                },
            };

        /*FETCH MESSAGES*/
        case brandKitActions.FETCH_BRAND_KIT_MESSAGES:
            return {
                ...state,
                loadingMessages: true,
                error: null,
            };
        case brandKitActions.FETCH_BRAND_KIT_MESSAGES_SUCCESS:
            return {
                ...state,
                messages: {
                    data: action.payload.data,
                    pagination: action.payload.meta,
                },
                loadingMessages: false,
                error: null,
            };
        case brandKitActions.FETCH_BRAND_KIT_MESSAGES_ERROR:
            return {
                ...state,
                messages: {
                    date: null,
                    pagination: null,
                },
                loadingMessages: false,
                error: action.payload,
            };

        /*FETCH SENDER NAMES*/
        case brandKitActions.FETCH_BRAND_KIT_SENDER_NAMES:
            return {
                ...state,
                loadingSenderNames: true,
                error: null,
            };
        case brandKitActions.FETCH_BRAND_KIT_SENDER_NAMES_SUCCESS:
            return {
                ...state,
                senderNames: {
                    data: action.payload.data,
                    pagination: action.payload.meta,
                },
                loadingSenderNames: false,
                error: null,
            };
        case brandKitActions.FETCH_BRAND_KIT_SENDER_NAMES_ERROR:
            return {
                ...state,
                senderNames: {
                    data: null,
                    pagination: null,
                },
                loadingSenderNames: false,
                error: action.payload,
            };

        default:
            return state;
    }
}
