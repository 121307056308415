import createFilter from 'redux-persist-transform-filter';
import {RESET_FORM, SET_FORM_VALUES} from 'redux/forms/actions';
import {SEND_FORM} from 'config/forms';
import {sendValidator} from 'redux/forms/validators/send';

export const sendSetFilter = createFilter(SET_FORM_VALUES, [SEND_FORM], (outboundState, key, __global) =>
    sendValidator(outboundState, key, {campaigns: __global?.campaigns, profile: __global?.profile}),
);

export const sendResetFilter = createFilter(RESET_FORM, [SEND_FORM], (outboundState, key, __global) =>
    sendValidator(outboundState, key, {campaigns: __global?.campaigns, profile: __global?.profile}),
);
