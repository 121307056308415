import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useBusinessHours from 'hooks/utility/useBusinessHours';

const SUPPRESS_CHAT_ROUTES = ['designer', 'popup/brand-kits'];

const shouldSuppressChat = (pathname) =>
    SUPPRESS_CHAT_ROUTES.some((route) => pathname.includes(route));

const loadHubspotWidget = () => {
    if (window.HubSpotConversations) {
        window.HubSpotConversations.widget.load();
    } else {
        window.hsConversationsOnReady = [
            () => window.HubSpotConversations.widget.load(),
        ];
    }
};

const removeHubspotWidget = () => {
    if (window.HubSpotConversations) {
        window.HubSpotConversations.widget.remove();
    }
};

export const useChat = () => {
    const businessHours = useBusinessHours();
    const location = useLocation();

    useEffect(() => {
        const { loading, data } = businessHours;
        const isBusinessOpen = !loading && data && data.isOpen;

        if (!isBusinessOpen) {
            return;
        }

        if (shouldSuppressChat(location.pathname)) {
            removeHubspotWidget();
            return;
        }

        loadHubspotWidget();
    }, [businessHours, location]);
};