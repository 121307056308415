export const START_AUTH = 'START_AUTH';
export const POST_LOGIN = 'POST_LOGIN';
export const CHECK_USER = 'CHECK_USER';
export const SET_USER = 'SET_USER';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_COMPLETE = 'LOGOUT_COMPLETE';
export const AUTH_SET_ERROR = 'AUTH_SET_ERROR';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';
export const AUTH_SET_VERIFY = 'AUTH_SET_VERIFY';
export const AUTH_LOAD_FROM_STORAGE = 'AUTH_LOAD_FROM_STORAGE';
export const AUTH_LOAD_FROM_STORAGE_COMPLETE =
  'AUTH_LOAD_FROM_STORAGE_COMPLETE';
export const AUTH_RESET_DEFAULT_VALUE = 'AUTH_RESET_DEFAULT_VALUE';
export const AUTHENTICATE_FEDERATED = 'AUTHENTICATE_FEDERATED';
export const AUTHENTICATE_FEDERATED_LEGACY = 'AUTHENTICATE_FEDERATED_LEGACY';
export const RESET_AUTHENTICATION_ERROR = 'RESET_AUTHENTICATION_ERROR';

export const CONFIRM_REGISTER = 'CONFIRM_REGISTER';
export const CONFIRM_REGISTER_SUCCESS = 'CONFIRM_REGISTER_SUCCESS';
export const CONFIRM_REGISTER_FAILURE = 'CONFIRM_REGISTER_FAILURE';

// TODO: should be pulled out to it's own store
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const AUTHENTICATE = 'AUTHENTICATE_START';
export const SOFT_AUTHENTICATE = 'SOFT_AUTHENTICATE';
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_FAILED = 'AUTHENTICATE_FAILED';

export const REGISTER_RESET = 'REGISTER_RESET';
export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';
