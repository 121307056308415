import {all, put, takeEvery} from 'redux-saga/effects';

import {api} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';
import uploadActions from './actions';
import {match} from 'library/helpers/utilities';
import {UPLOAD_BULK, UPLOAD_FUNDS_TRANSFER, UPLOAD_INVITE} from 'config/upload';

function* upload({payload}) {
    const type = payload?.type || payload.get('type');

    const endpoint = match(type, {
        [UPLOAD_BULK]: () => apiEndpoints.recipients.uploadBulk,
        [UPLOAD_INVITE]: () => apiEndpoints.recipients.uploadMembers,
        [UPLOAD_FUNDS_TRANSFER]: () => apiEndpoints.funding.transferUpload,
    });

    try {
        const response = yield api.post(endpoint, payload, {
            headers: {'content-type': 'multipart/form-data'},
        });

        yield put(uploadActions.uploadSuccess({...response.data, type}));
    } catch (err) {
        yield put(uploadActions.uploadError({...err, type}));
    }
}

export default function* uploadSaga() {
    yield all([takeEvery(uploadActions.UPLOAD_START, upload)]);
}
