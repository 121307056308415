import {all, put, takeEvery} from 'redux-saga/effects';
import brandKitsActions from 'redux/brandKits/actions';
import brandKitsService from 'library/helpers/api/services/brandKits';
import brandKitActions from 'redux/brandKits/actions';

function* fetchBrandKit({payload}) {
    try {
        const res = yield brandKitsService.fetchBrandKit(payload);
        yield put(brandKitsActions.fetchBrandKitSuccess(res));
    } catch (error) {
        yield put(brandKitsActions.fetchBrandKitError(error));
    }
}

function* fetchBrandKits({payload}) {
    try {
        const res = yield brandKitsService.fetchBrandKits(payload);
        yield put(brandKitsActions.fetchBrandKitsSuccess(res));
    } catch (error) {
        yield put(brandKitsActions.fetchBrandKitsError(error));
    }
}

function* fetchBrandKitMessages({payload}) {
    try {
        const data = yield brandKitsService.fetchBrandKitMessages(payload);
        yield put(brandKitActions.fetchBrandKitMessagesSuccess(data));
    } catch (error) {
        yield put(brandKitActions.fetchBrandKitMessagesError(error));
    }
}

function* fetchBrandKitSenderNames({payload}) {
    try {
        const data = yield brandKitsService.fetchBrandKitSenderNames(payload);
        yield put(brandKitActions.fetchBrandKitSenderNamesSuccess(data));
    } catch (error) {
        yield put(brandKitActions.fetchBrandKitSenderNamesError(error));
    }
}

export default function* bulkOrderSaga() {
    yield all([
        takeEvery(brandKitsActions.FETCH_BRAND_KIT, fetchBrandKit),
        takeEvery(brandKitsActions.FETCH_BRAND_KITS, fetchBrandKits),
        takeEvery(brandKitActions.FETCH_BRAND_KIT_MESSAGES, fetchBrandKitMessages),
        takeEvery(brandKitActions.FETCH_BRAND_KIT_SENDER_NAMES, fetchBrandKitSenderNames),
    ]);
}
