import {lang} from 'config/lang';
import AddCreditCardForm from 'components/Payments/AddCreditCardForm';
import React from 'react';

export const CARD_MAP = {
    visa: {
        icon: 'visa',
        title: 'Visa',
    },
    mastercard: {
        icon: 'mastercard',
        title: 'Mastercard',
    },
    amex: {
        icon: 'amex',
        title: 'American Express',
    },
    discover: {
        icon: 'discover',
        title: 'Discover',
    },
};
export const chargeTypes = {
    0: 'Procurement',
    1: 'Card',
    2: 'Setup Fee',
    3: 'Custom Artwork',
    4: 'Refund',
    5: 'Carriers',
    6: 'Misc',
    7: 'Giftogram Voided Cards',
    8: 'Postage',
    10: 'Misc taxable item',
    11: 'Coupon',
    13: 'Gift Card Sleeves',
};

export const mapCreditCards = {
    visa: 'Visa',
    mastercard: 'Mastercard',
    americanExpress: 'AMEX',
    discover: 'Discover',
};

export const DEPOSIT_TYPES = {
    0: {
        type: 'Request Credit',
    },
    1: {
        type: 'Credit Card',
    },
    4: {
        type: 'Wire/ACH Transfer',
    },
    5: {
        type: 'Check',
    },
};

export const CREDIT_CARD_PAYMENT_METHOD_ID = 1;
export const CHECK_PAYMENT_METHOD_ID = 5;
export const ACH_PAYMENT_METHOD_ID = 4;
export const ACCOUNT_CREDITS_PAYMENT_METHOD_ID = 2;
export const PROCUREMENT_SYSTEM_METHOD_ID = 0;

export const PAYMENT_TYPES = {
    ach: {
        method_id: 4,
        title: 'Wire/ACH Transfer',
    },
    creditCard: {
        method_id: 1,
        title: 'Credit Card',
    },
    credit: {
        method_id: 2,
        title: 'Credit',
    },
    check: {
        method_id: 5,
        title: 'Check',
    },
};

export const addPaymentModalProps = {
    width: 500,
    footer: false,
    title: lang.funding.credit_card.title,
    children: <AddCreditCardForm />,
    closeable: false,
    maskClosable: true,
    key: 'add-payment-modal',
};
