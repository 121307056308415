import {api} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';
import {all, put, select, takeEvery} from 'redux-saga/effects';
import shippingAddressCampaignActions from './actions';
import {processError} from 'library/helpers/response';
import {getRoute} from 'library/helpers/routing';
import {getCurrentCampaignId} from 'redux/selectors';
import profileActions from '../profile/actions';

function* fetchShippingAddressesCampaign() {
    try {
        const campaignId = yield select((state) => getCurrentCampaignId(state));

        if (campaignId === 0) {
            return yield put(
                shippingAddressCampaignActions.fetchShippingAddressesCampaignError({
                    data: {message: 'No campaigns available'},
                }),
            );
        }

        const route = getRoute(apiEndpoints.shippingAddress.index_campaign, {
            id: campaignId,
        });

        const shippingAddresses = yield api.get(route).then((res) => res.data);
        yield put(shippingAddressCampaignActions.fetchShippingAddressesCampaignSuccess(shippingAddresses));
    } catch (error) {
        yield put(shippingAddressCampaignActions.fetchShippingAddressesCampaignError(processError(error)));
    }
}

function* forceFetch() {
    try {
        yield fetchShippingAddressesCampaign({
            payload: {
                forceFetch: true,
            },
        });
    } catch (err) {
        console.log(err);
    }
}

export default function* shippingAddressSaga() {
    yield all([
        takeEvery(
            shippingAddressCampaignActions.FETCH_SHIPPING_ADDRESSES_CAMPAIGN_START,
            fetchShippingAddressesCampaign,
        ),

        takeEvery(profileActions.UPDATE_DATA_CONTEXT_CAMPAIGN_ID_SUCCESS, forceFetch),
    ]);
}
