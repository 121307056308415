import PropTypes from 'prop-types';
import React from 'react';
import mascot from 'assets/images/mascot.svg';
import {Button, Col, Row, Typography} from 'components/UI';
import logger from 'library/vendor/loggly';
import StackTrace from 'stacktrace-js';
import {connect} from 'react-redux';
import {lang} from 'config/lang';
import {getCurrentTeam, getProfileData} from 'redux/selectors';
import {hardRefresh} from 'library/helpers/api';
import {stripLeading} from 'library/helpers/string';
import {getProcessEnvValue} from 'library/helpers/process';
import {routes} from 'config/routes';
import {isProduction} from "library/helpers/utilities";

const {Title, Paragraph} = Typography;

class ErrorBoundary extends React.Component {
    state = {};

    constructor(props) {
        super(props);

        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        if (error.name === 'ChunkLoadError') {
            hardRefresh(true);

            return {hasError: false};
        }

        return {hasError: true};
    }

    // eslint-disable-next-line
    componentDidCatch(error, info) {
        // log the error to loggly
        if (isProduction()) {
            StackTrace.fromError(error)
                .then((stack) => {
                    logger.push({
                        errorMessage: error.message ?? '',
                        stack: stack.slice(0, 10),
                        context: this.errorContext(),
                    });
                })
                .catch((error) => logger.push({error, context: this.errorContext()}));
        }
    }

    errorContext = () => {
        return {
            url: window.location.pathname,
            account_id: this.props.profileData ? this.props.profileData.id : 0,
            team_id: this.props.team ?? 0,
            userAgent: navigator.userAgent ?? '',
            build_id: stripLeading(getProcessEnvValue('REACT_APP_BUILD_JOB_ID')) ?? '',
        };
    };

    render() {
        if (this.state.hasError) {
            return (
                <div className="error-layout">
                    <div className="content">
                        <Row>
                            <Col
                                xl={{span: 20, offset: 2}}
                                lg={{span: 20, offset: 2}}
                                md={{span: 20, offset: 2}}
                                sm={24}
                                xs={24}
                            >
                                <div className="error-number">
                                    <span>UT</span>
                                    <span>
                                        <img src={mascot} alt="Giftogram Mascot" />
                                    </span>
                                    <span>OH</span>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col
                                xl={{span: 10, offset: 7}}
                                lg={{span: 20, offset: 2}}
                                md={{span: 20, offset: 2}}
                                sm={24}
                                xs={24}
                            >
                                <Title level={1}>There has been an error</Title>

                                <Paragraph className="lead">
                                    This is likely a temporary issue but we are working hard to correct it.
                                </Paragraph>

                                <Button
                                    type="default"
                                    size="large"
                                    href={routes.private.paths.DASHBOARD}
                                    className="btn-shadow"
                                >
                                    {lang.error_404.back_button}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            );
        }

        // eslint-disable-next-line
        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    profileData: PropTypes.any,
    team: PropTypes.any,
};

const mapStateToProps = (state) => {
    return {
        profileData: getProfileData(state),
        team: getCurrentTeam(state),
    };
};

ErrorBoundary.propTypes = {
    children: PropTypes.any,
};

export default connect(mapStateToProps)(ErrorBoundary);
