import {all, put, takeEvery, takeLeading} from 'redux-saga/effects';
import {api} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';
import recentOrdersActions from './actions';
import {getRoute} from 'library/helpers/routing';
import {store} from 'redux/store';
import {BOTH_ORDER, DIGITAL_ORDER, PHYSICAL_ORDER} from 'config/orders';

const buildRoute = (payload, keyOverride) => {
    let {key, teamId} = payload;

    key = keyOverride || key;

    let route = getRoute(apiEndpoints[`${key}Orders`].recentHistory, {
        id: teamId,
    });

    // filter by recipient
    if (payload.recipientId) route += `&contact_id=${payload.recipientId}`;

    // filter by campaign
    if (payload.campaignId) route += `&company_campaign_id=${payload.campaignId}`;

    return route;
};

function* fetchRecentOrders({payload}) {
    const {key} = payload;

    const route = buildRoute(payload);

    // load orders
    const order = yield api.get(route);

    if (order.status !== 200) {
        // errors
        yield put(
            recentOrdersActions.fetchRecentOrdersError({
                key,
                data: order.response.message,
            }),
        );

        return;
    }

    yield put(
        recentOrdersActions.fetchRecentOrdersSuccess({
            key,
            data: order.data,
        }),
    );
}

function* fetchBothRecentOrders({payload}) {
    const physicalRoute = buildRoute(payload, PHYSICAL_ORDER);
    const digitalRoute = buildRoute(payload, DIGITAL_ORDER);

    // load orders
    try {
        const digital = yield api.get(digitalRoute);
        const physical = yield api.get(physicalRoute);

        yield put(
            recentOrdersActions.fetchRecentOrdersSuccess({
                key: BOTH_ORDER,
                data: {physical, digital},
            }),
        );
    } catch (error) {
        yield put(
            recentOrdersActions.fetchRecentOrdersError({
                key: BOTH_ORDER,
                data: {error},
            }),
        );
    }
}

function* resetRecentOrders() {
    yield setTimeout(() => {
        store.dispatch(recentOrdersActions.resetSuccess());
    }, 300);
}

export default function* recentOrdersSaga() {
    yield all([
        takeLeading(recentOrdersActions.FETCH_RECENT_ORDERS, fetchRecentOrders),
        takeEvery(recentOrdersActions.FETCH_BOTH_RECENT_ORDERS, fetchBothRecentOrders),
        takeLeading(recentOrdersActions.RESET_RECENT_ORDERS, resetRecentOrders),
    ]);
}
