const teamActions = {
    TOGGLE_SWITCHER_VISIBLE: 'TOGGLE_SWITCHER_VISIBLE',
    toggleSwitcherVisible: (payload) => ({
        type: teamActions.TOGGLE_SWITCHER_VISIBLE,
        payload,
    }),
    FETCH_TEAM_DATA_START: 'FETCH_TEAM_DATA_START',
    FETCH_TEAM_DATA_SUCCESS: 'FETCH_TEAM_DATA_SUCCESS',
    FETCH_TEAM_DATA_FAILURE: 'FETCH_TEAM_DATA_FAILURE',
    fetchTeamDataStart: () => ({
        type: teamActions.FETCH_TEAM_DATA_START,
    }),
    fetchTeamDataSuccess: (payload) => ({
        type: teamActions.FETCH_TEAM_DATA_SUCCESS,
        payload,
    }),
    fetchTeamDataFailure: (error) => ({
        type: teamActions.FETCH_TEAM_DATA_FAILURE,
        payload: error,
    }),

    FETCH_MEMBERS_DATA_START: 'FETCH_MEMBERS_DATA_START',
    FETCH_MEMBERS_DATA_SUCCESS: 'FETCH_MEMBERS_DATA_SUCCESS',
    FETCH_MEMBERS_DATA_SUCCESS_PAGE: 'FETCH_MEMBERS_DATA_SUCCESS_PAGE',
    FETCH_MEMBERS_DATA_FAILURE: 'FETCH_MEMBERS_DATA_FAILURE',
    fetchMembersDataStart: (payload) => ({
        type: teamActions.FETCH_MEMBERS_DATA_START,
        payload,
    }),
    fetchMembersDataSuccess: (payload) => ({
        type: teamActions.FETCH_MEMBERS_DATA_SUCCESS,
        payload,
    }),

    fetchMembersDataFailure: (error) => ({
        type: teamActions.FETCH_MEMBERS_DATA_FAILURE,
        payload: error,
    }),

    FETCH_ALL_MEMBERS_DATA_START: 'FETCH_ALL_MEMBERS_DATA_START',
    FETCH_ALL_MEMBERS_DATA_SUCCESS: 'FETCH_ALL_MEMBERS_DATA_SUCCESS',
    FETCH_ALL_MEMBERS_DATA_FAILURE: 'FETCH_ALL_MEMBERS_DATA_FAILURE',
    fetchAllMembersDataStart: (payload) => ({
        type: teamActions.FETCH_ALL_MEMBERS_DATA_START,
        payload: {...payload, paginate: false},
    }),
    fetchAllMembersDataSuccess: (payload) => ({
        type: teamActions.FETCH_ALL_MEMBERS_DATA_SUCCESS,
        payload,
    }),
    fetchAllMembersDataFailure: (error) => ({
        type: teamActions.FETCH_ALL_MEMBERS_DATA_FAILURE,
        payload: error,
    }),

    INVITE_EXISTING_MEMBERS_DATA_START: 'INVITE_EXISTING_MEMBERS_DATA_START',
    INVITE_EXISTING_MEMBERS_DATA_SUCCESS: 'INVITE_EXISTING_MEMBERS_DATA_SUCCESS',
    INVITE_EXISTING_MEMBERS_DATA_FAILURE: 'INVITE_EXISTING_MEMBERS_DATA_FAILURE',
    inviteExistingMembersDataStart: (payload) => ({
        type: teamActions.INVITE_EXISTING_MEMBERS_DATA_START,
        payload,
    }),
    inviteExistingMembersDataSuccess: () => ({
        type: teamActions.INVITE_EXISTING_MEMBERS_DATA_SUCCESS,
    }),
    inviteExistingMembersDataFailure: (error) => ({
        type: teamActions.INVITE_EXISTING_MEMBERS_DATA_FAILURE,
        payload: error,
    }),

    CREATE_INVITE_LINK_START: 'CREATE_INVITE_LINK_START',
    CREATE_INVITE_LINK_SUCCESS: 'CREATE_INVITE_LINK_SUCCESS',
    CREATE_INVITE_LINK_FAILURE: 'CREATE_INVITE_LINK_FAILURE',
    createInviteLinkStart: (payload) => ({
        type: teamActions.CREATE_INVITE_LINK_START,
        payload,
    }),
    createInviteLinkSuccess: () => ({
        type: teamActions.CREATE_INVITE_LINK_SUCCESS,
    }),
    createInviteLinkFailure: (error) => ({
        type: teamActions.CREATE_INVITE_LINK_FAILURE,
        payload: error,
    }),

    CREATE_INVITE_EMAIL_START: 'CREATE_INVITE_EMAIL_START',
    CREATE_INVITE_EMAIL_SUCCESS: 'CREATE_INVITE_EMAIL_SUCCESS',
    CREATE_INVITE_EMAIL_FAILURE: 'CREATE_INVITE_EMAIL_FAILURE',
    createInviteEmailStart: (payload) => ({
        type: teamActions.CREATE_INVITE_EMAIL_START,
        payload,
    }),
    createInviteEmailSuccess: () => ({
        type: teamActions.CREATE_INVITE_EMAIL_SUCCESS,
    }),
    createInviteEmailFailure: (error) => ({
        type: teamActions.CREATE_INVITE_EMAIL_FAILURE,
        payload: error,
    }),

    CANCEL_INVITE_DATA_START: 'CANCEL_INVITE_DATA_START',
    CANCEL_INVITE_DATA_SUCCESS: 'CANCEL_INVITE_DATA_SUCCESS',
    CANCEL_INVITE_DATA_FAILURE: 'CANCEL_INVITE_DATA_FAILURE',
    cancelInviteDataStart: (payload) => ({
        type: teamActions.CANCEL_INVITE_DATA_START,
        payload,
    }),
    cancelInviteDataSuccess: () => ({
        type: teamActions.CANCEL_INVITE_DATA_SUCCESS,
    }),
    cancelInviteDataFailure: (error) => ({
        type: teamActions.CANCEL_INVITE_DATA_FAILURE,
        payload: error,
    }),

    CHANGE_ROLE_DATA_START: 'CHANGE_ROLE_DATA_START',
    CHANGE_ROLE_DATA_SUCCESS: 'CHANGE_ROLE_DATA_SUCCESS',
    CHANGE_ROLE_DATA_FAILURE: 'CHANGE_ROLE_DATA_FAILURE',
    changeRoleDataStart: (payload) => ({
        type: teamActions.CHANGE_ROLE_DATA_START,
        payload,
    }),
    changeRoleDataSuccess: (payload) => ({
        type: teamActions.CHANGE_ROLE_DATA_SUCCESS,
        payload,
    }),
    changeRoleDataFailure: (error) => ({
        type: teamActions.CHANGE_ROLE_DATA_FAILURE,
        payload: error,
    }),

    REMOVE_MEMBER_DATA_START: 'REMOVE_MEMBER_DATA_START',
    REMOVE_MEMBER_DATA_SUCCESS: 'REMOVE_MEMBER_DATA_SUCCESS',
    REMOVE_MEMBER_DATA_FAILURE: 'REMOVE_MEMBER_DATA_FAILURE',
    removeMemberDataStart: (payload) => ({
        type: teamActions.REMOVE_MEMBER_DATA_START,
        payload,
    }),
    removeMemberDataSuccess: (payload) => ({
        type: teamActions.REMOVE_MEMBER_DATA_SUCCESS,
        payload,
    }),
    removeMemberDataFailure: (error) => ({
        type: teamActions.REMOVE_MEMBER_DATA_FAILURE,
        payload: error,
    }),

    TOGGLE_USER_DATA_START: 'TOGGLE_USER_DATA_START',
    TOGGLE_USER_DATA_SUCCESS: 'TOGGLE_USER_DATA_SUCCESS',
    TOGGLE_USER_DATA_FAILURE: 'TOGGLE_USER_DATA_FAILURE',
    toggleUserDataStart: (payload) => ({
        type: teamActions.TOGGLE_USER_DATA_START,
        payload,
    }),
    toggleUserDataSuccess: (payload) => ({
        type: teamActions.TOGGLE_USER_DATA_SUCCESS,
        payload,
    }),
    toggleUserDataFailure: (error) => ({
        type: teamActions.TOGGLE_USER_DATA_FAILURE,
        payload: error,
    }),

    CHANGE_TEAM_START: 'CHANGE_TEAM_START',
    CHANGE_TEAM_SUCCESS: 'CHANGE_TEAM_SUCCESS',
    CHANGE_TEAM_FAILURE: 'CHANGE_TEAM_FAILURE',
    changeTeamStart: (payload) => ({
        type: teamActions.CHANGE_TEAM_START,
        payload,
    }),
    changeTeamSuccess: (payload) => ({
        type: teamActions.CHANGE_TEAM_SUCCESS,
        payload,
    }),
    changeTeamFailure: (error) => ({
        type: teamActions.CHANGE_TEAM_FAILURE,
        payload: error,
    }),
};

export default teamActions;
