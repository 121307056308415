import React from 'react';
import PropTypes from 'prop-types';
import {Form, Skeleton} from 'components/UI';

/**
 * FormItem is a wrapper for Form* components.
 * It extends Antd Form.Item around the component
 * https://ant.design/components/form/#Form.Item
 *
 * you don't always want to the component to be affected by waiting for stores to load
 * ex. /signin inputs should never have to wait for anything to load first
 * use noLoad to prevent any type of preloading.

 * catch the loader like this
 * (loaded && !noLoad) ? children : renderSkeleton()

 * this an example of throwing out the skeleton or any wait object
 const renderSkeleton = () => {
      switch (skelType) {
        case 'input':
        case 'select':
          return <SkeletonInput active />;
        case 'avatar':
          return <SkeletonAvatar active />;
        case 'button':
          return <SkeletonButton active />;
        default:
          return '';
      }
    };

 * @param props.label
 * @param props.required
 * @param props.help
 * @param props.wrapperCol
 * @param props.children
 * @param props.skelType
 * @param props.loaded
 * @param props.noLoading
 * @returns {*}
 * @constructor
 */
class FormItem extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            status: null,
            error: false,
            help: '',
            stateable: Object.prototype.hasOwnProperty.call(props, 'field'),
            submitAttempt: false,
            loading: false,
        };

        this.layout =
            this.props.fieldLayout === 'horizontal'
                ? {
                      labelCol: {span: 12},
                      wrapperCol: {span: 12},
                  }
                : null;
    }

    renderSkeleton = () => {
        const imageSize = '138px';
        switch (this.props.skelType) {
            case 'input':
            case 'select':
                return <Skeleton.Input size="large" active />;
            case 'button':
                return <Skeleton.Button size="large" active block />;
            case 'avatar':
                return (
                    <Skeleton.Avatar
                        active
                        shape="square"
                        style={{width: imageSize, height: `calc(${imageSize} - 1.25rem)`}}
                    />
                );
            default:
                return '';
        }
    };

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            form,
            field: {name},
        } = this.props;
        const hasError = Object.prototype.hasOwnProperty.call(form.errors, name);

        if (!prevState.submitAttempt && form.isSubmitting) {
            this.setState({submitAttempt: form.isSubmitting});
        }

        if (
            prevState.error !== hasError &&
            form.dirty &&
            this.props.displayErrors &&
            (form.touched[name] || this.state.submitAttempt)
        ) {
            this.setState({
                error: hasError,
                status: hasError ? 'error' : null,
                help: hasError ? form.errors[name] : false,
            });
        }
    }

    render() {
        return (
            <Form.Item
                label={this.props.label}
                required={this.props.required}
                help={this.state.help || this.props.help}
                validateStatus={this.state.status || this.props.status}
                className={this.props.className}
                labelCol={this.props.labelCol}
                wrapperCol={this.props.wrapperCol}
                extra={this.props.loaded && this.props.extra}
                style={this.props.style}
                {...this.layout}
                data-tour={this.props.tour}
                disabled={this.props.disabled}
                colon={this.props.colon}
            >
                {this.props.noLoading || this.props.loaded ? this.props.children : this.renderSkeleton()}
            </Form.Item>
        );
    }
}

// TODO: Deprecated, move to params
FormItem.defaultProps = {
    displayErrors: true,
    field: {name: null},
    forceErrors: false,
    form: {dirty: false, isValid: true, errors: {}},
    inline: false,
    loaded: true,
    noLabel: false,
    noLoading: false,
    required: false,
    skelType: 'default',
};

FormItem.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    colon: PropTypes.bool,
    disabled: PropTypes.bool,
    displayErrors: PropTypes.bool,
    extra: PropTypes.any,
    field: PropTypes.object,
    fieldLayout: PropTypes.string,
    forceErrors: PropTypes.bool,
    form: PropTypes.object,
    help: PropTypes.any,
    inline: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    label: PropTypes.any,
    labelCol: PropTypes.any,
    layout: PropTypes.string,
    loaded: PropTypes.bool,
    noLabel: PropTypes.bool,
    noLoading: PropTypes.bool,
    required: PropTypes.bool,
    skelType: PropTypes.string.isRequired,
    status: PropTypes.any,
    style: PropTypes.object,
    tour: PropTypes.any,
    validation: PropTypes.bool,
    wrapperCol: PropTypes.any,
};

export default FormItem;
