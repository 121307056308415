import {store} from 'redux/store';
import {api} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';
import {getRoute} from 'library/helpers/routing';
import {removeLocalStore} from 'library/helpers/localStore';
import {ORDER_SUMMARY_STORE} from 'config/redux';
import profileActions from 'redux/profile/actions';
import {routes} from 'config/routes';

const campaignsService = {
    checkRecipientsToCampaign: (ids, campaignId) => {
        api.post(
            getRoute(apiEndpoints.validate.recipientsToCampaigns, {
                id: campaignId,
            }),
            {ids},
        );
    },
    requestSmartCampaignExtension: async (campaignId) => {
        return await api.put(
            getRoute(apiEndpoints.campaigns.extendSmartCampaign, {
                id: campaignId,
            }),
        );
    },
    getManagementData: async (data) => {
        const {get, show} = apiEndpoints.campaignManagement;
        let {id, ...params} = {...data};
        let route = get;

        //if we have an id we are going to update
        if (id) {
            route = getRoute(show, {id});
            params = null;
        }

        return await api.get(route, {params}).then(({data}) => data);
    },
    setCampaignContext: async (campaignId) => {
        const {dispatch} = store;

        const {campaign_id: campaignContext} = store.getState().profile?.data?.data?.context ?? {campaign_id: 0};

        const campaigns = store.getState().campaigns?.data?.data;

        if (campaigns?.length && campaignId === 0) {
            campaignId = campaigns[0].id ?? 0;
        }

        if (campaignContext === campaignId) return;

        await api.post(apiEndpoints.orderSummary.post, {items: []});

        removeLocalStore(ORDER_SUMMARY_STORE);

        try {
            const response = await api.post(apiEndpoints.campaigns.setContext, {
                campaign_id: campaignId,
            });

            const newCampaignId = response?.data?.campaign_id ?? 0;

            dispatch(profileActions.updateDataContextCampaignIdSuccess(newCampaignId));

            return response;
        } catch (e) {
            console.log(e);
            return e;
        }
    },
    cloneCampaign: async (campaignId) => {
        const route = getRoute(routes.private.paths.CREATE_CAMPAIGN.CLONE, {campaignId});
        return await api.put(route).then(({data}) => data);
    },
};

export default campaignsService;
