import {all, takeEvery, put} from 'redux-saga/effects';

import {api} from 'library/helpers/api';
import inviteActions from 'redux/invite/actions';
import {apiEndpoints} from 'config/api';
import {getRoute} from 'library/helpers/routing';

function* lookupInvite({payload}) {
    try {
        const route = getRoute(apiEndpoints.signUp.inviteLookup, {key: payload});
        const response = yield api.get(route);
        yield put(inviteActions.lookupInviteSuccess(response.data));
    } catch (error) {
        yield put(inviteActions.lookupInviteError(error));
    }
}
export default function* inviteSaga() {
    yield all([takeEvery(inviteActions.LOOKUP_INVITE_START, lookupInvite)]);
}
