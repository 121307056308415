import {all, put, select, takeEvery, takeLeading} from 'redux-saga/effects';
import savedDesignsCampaignActions from './actions';
import {api} from 'library/helpers/api';
import {getRoute} from 'library/helpers/routing';
import {apiEndpoints} from 'config/api';
import {CAMPAIGN_CUSTOM_DESIGNS_STORE} from 'config/redux';
import {setLocalStore} from 'library/helpers/localStore';
import {localFetch} from 'library/helpers/redux';
import {getCurrentCampaignId} from 'redux/selectors';
import productActions from 'redux/products/actions';
import profileActions from '../profile/actions';

function* fetchSavedDesignCampaign({payload}) {
    const cachedDesigns = yield localFetch(payload, CAMPAIGN_CUSTOM_DESIGNS_STORE);

    if (cachedDesigns) {
        return yield put(savedDesignsCampaignActions.fetchSavedDesignsCampaignSuccess(cachedDesigns));
    }

    try {
        const campaignId = yield select((state) => getCurrentCampaignId(state));

        if (campaignId === 0) {
            return yield put(
                savedDesignsCampaignActions.fetchSavedDesignsCampaignError({
                    data: {message: 'No campaigns available'},
                }),
            );
        }

        const customDesign = yield api
            .get(
                getRoute(apiEndpoints.savedDesign.listByCampaign, {
                    campaignId,
                }),
            )
            .then((res) => res.data);

        yield put(productActions.setCustom(customDesign.length > 0));
        yield put(savedDesignsCampaignActions.fetchSavedDesignsCampaignSuccess(customDesign));
        setLocalStore(CAMPAIGN_CUSTOM_DESIGNS_STORE, customDesign);
    } catch (error) {
        yield put(savedDesignsCampaignActions.fetchSavedDesignsCampaignError(error));
    }
}

function* forceFetch(payload) {
    try {
        yield fetchSavedDesignCampaign({
            payload: {
                forceFetch: true,
                ...payload,
            },
        });
    } catch (err) {
        console.log(err);
    }
}

export default function* savedDesignsCampaignSaga() {
    yield all([
        takeLeading(savedDesignsCampaignActions.FETCH_SAVED_DESIGNS_CAMPAIGN_START, fetchSavedDesignCampaign),
        takeEvery(profileActions.UPDATE_DATA_CONTEXT_CAMPAIGN_ID_SUCCESS, forceFetch),
    ]);
}
