import React from 'react';
import PropTypes from 'prop-types';
const FormGroup = ({children, hidden = false, style = {}, title}) => {
    return (
        <fieldset style={style}>
            <legend hidden={hidden}>{title}</legend>

            {children}
        </fieldset>
    );
};

FormGroup.propTypes = {
    children: PropTypes.node,
    hidden: PropTypes.bool,
    style: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default FormGroup;
