const modalsActions = {
    OPEN_MODAL: 'OPEN_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',

    open: (modalKey) => ({
        type: modalsActions.OPEN_MODAL,
        payload: {modalKey},
    }),

    close: (modalKey) => ({
        type: modalsActions.CLOSE_MODAL,
        payload: {modalKey},
    }),
};

export default modalsActions;
