import groupsActions from './actions';

import teamActions from 'redux/team/actions';
import {LOGOUT} from 'redux/auth/auth.types';

const initialState = {
    list: {
        data: null,
        error: null,
        loading: false,
        loaded: false,
    },
    data: null,
    error: null,
    loading: false,
    loaded: false,
    saving: false,
    saved: false,
    deleted: false,
    updatedRecipients: false,
    failedToLoad: [],
    newId: null,
    query: {page: '1', per_page: '25'},
    smartCampaign: null,
};

const groupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case groupsActions.LIST_GROUPS_START:
            return {
                ...state,
                list: {
                    ...state.list,
                    error: null,
                    loading: true,
                    loaded: false,
                },
            };
        case groupsActions.LIST_GROUPS_SUCCESS:
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    loading: false,
                    loaded: true,
                    error: null,
                },
            };
        case groupsActions.LIST_GROUPS_FAILURE:
            return {
                ...state,
                list: {
                    ...state.list,
                    error: action.payload,
                    loading: false,
                    loaded: false,
                },
            };

        case groupsActions.FETCH_GROUPS_DATA_START:
            return {
                ...state,
                query: action.payload,
                loading: true,
                loaded: false,
                error: null,
            };
        case groupsActions.FETCH_GROUPS_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                loaded: true,
                error: null,
            };
        case groupsActions.FETCH_GROUPS_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload,
            };

        case groupsActions.SAVE_GROUPS_DATA_START:
            return {
                ...state,
                newId: null,
                saving: true,
                saved: false,
                error: null,
            };
        case groupsActions.SAVE_GROUPS_DATA_SUCCESS:
            return {
                ...state,
                newId: action.payload,
                saving: false,
                saved: true,
                error: null,
            };
        case groupsActions.SAVE_GROUPS_DATA_FAILURE:
            return {
                ...state,
                newId: null,
                saving: false,
                saved: false,
                error: action.payload,
            };

        case groupsActions.DELETE_GROUPS_START:
            return {
                ...state,
                deleted: false,
                error: null,
            };
        case groupsActions.DELETE_GROUPS_SUCCESS:
            return {
                ...state,
                deleted: true,
                error: null,
            };
        case groupsActions.DELETE_GROUPS_FAILURE:
            return {
                ...state,
                deleted: false,
                error: action.payload,
            };

        case groupsActions.ADD_RECIPIENTS_TO_GROUP_DATA_START:
        case groupsActions.REMOVE_RECIPIENTS_FROM_GROUP_DATA_START:
            return {
                ...state,
                updatedRecipients: false,
                failedToLoad: [],
                error: null,
            };
        case groupsActions.ADD_RECIPIENTS_TO_GROUP_DATA_SUCCESS:
        case groupsActions.REMOVE_RECIPIENTS_FROM_GROUP_DATA_SUCCESS:
            return {
                ...state,
                updatedRecipients: true,
                failedToLoad: action.payload,
                error: null,
            };
        case groupsActions.ADD_RECIPIENTS_TO_GROUP_DATA_FAILURE:
        case groupsActions.REMOVE_RECIPIENTS_FROM_GROUP_DATA_FAILURE:
            return {
                ...state,
                updatedRecipients: false,
                failedToLoad: [],
                error: action.payload,
            };

        case groupsActions.VALIDATE_SMART_CAMPAIGN_GROUP_START:
            return {
                ...state,
                smartCampaign: {
                    loading: true,
                    error: false,
                    data: null,
                },
            };
        case groupsActions.VALIDATE_SMART_CAMPAIGN_GROUP_SUCCESS:
            return {
                ...state,
                smartCampaign: {
                    loading: false,
                    error: false,
                    data: action.payload,
                },
            };
        case groupsActions.VALIDATE_SMART_CAMPAIGN_GROUP_FAILURE:
            return {
                ...state,
                smartCampaign: {
                    loading: false,
                    error: action.payload,
                    data: null,
                },
            };

        case groupsActions.VALIDATE_SMART_CAMPAIGN_GROUP_RESET:
            return {
                ...state,
                smartCampaign: null,
            };
        case groupsActions.RESET_SAVED:
            return {
                ...state,
                saved: false,
                newId: null,
            };

        case LOGOUT:
        case teamActions.CHANGE_TEAM_SUCCESS:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default groupsReducer;
