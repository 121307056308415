import {api} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';
import {all, put, takeEvery, takeLeading} from 'redux-saga/effects';
import shippingAddressActions from './actions';
import {processError} from 'library/helpers/response';
import {getRoute} from 'library/helpers/routing';
import shippingAddressCampaignActions from 'redux/shippingAddressCampaign/actions';

function* fetchShippingAddresses() {
    try {
        const shippingAddresses = yield api.get(apiEndpoints.shippingAddress.index).then((res) => res.data);
        yield put(shippingAddressActions.fetchShippingAddressesSuccess(shippingAddresses));
    } catch (error) {
        yield put(shippingAddressActions.fetchShippingAddressesError(processError(error)));
    }
}

function* geocodeShippingAddress({payload}) {
    try {
        const response = yield api.post(apiEndpoints.shippingAddress.geocode, payload);
        yield put(shippingAddressActions.geocodeShippingAddressesSuccess(response.data));
    } catch (error) {
        yield put(shippingAddressActions.geocodeShippingAddressesError(processError(error)));
    }
}

function* saveShippingAddress({payload}) {
    try {
        const response = yield api.post(apiEndpoints.shippingAddress.create, payload);

        yield put(shippingAddressActions.saveShippingAddressesSuccess(response.data));

        yield put(shippingAddressActions.fetchShippingAddressesStart());
        yield put(shippingAddressCampaignActions.fetchShippingAddressesCampaignStart());
    } catch (error) {
        yield put(shippingAddressActions.saveShippingAddressesError(processError(error)));
    }
}

function* updateShippingAddress({payload}) {
    try {
        const route = getRoute(apiEndpoints.shippingAddress.update, {
            id: payload.id,
        });

        const response = yield api.put(route, payload);

        yield put(shippingAddressActions.updateShippingAddressesSuccess(response.data));
        yield put(shippingAddressCampaignActions.fetchShippingAddressesCampaignStart());
        yield put(shippingAddressActions.fetchShippingAddressesStart());
    } catch (error) {
        yield put(shippingAddressActions.updateShippingAddressesError(processError(error)));
    }
}

function* defaultShippingAddress({payload}) {
    try {
        const route = getRoute(apiEndpoints.shippingAddress.default, {
            id: payload.id,
        });
        const response = yield api.put(route);
        yield put(shippingAddressActions.defaultShippingAddressesSuccess(response.data));
        yield put(shippingAddressCampaignActions.fetchShippingAddressesCampaignStart());
        yield put(shippingAddressActions.fetchShippingAddressesStart());
    } catch (error) {
        yield put(shippingAddressActions.defaultShippingAddressesError(processError(error)));
    }
}

function* deleteShippingAddress({payload}) {
    try {
        const route = getRoute(apiEndpoints.shippingAddress.delete, {
            id: payload.id,
        });
        const response = yield api.delete(route);
        yield put(shippingAddressActions.deleteShippingAddressesSuccess(response.data));
        yield put(shippingAddressActions.fetchShippingAddressesStart());
    } catch (error) {
        yield put(shippingAddressActions.deleteShippingAddressesError(processError(error)));
    }
}

export default function* shippingAddressSaga() {
    yield all([
        takeLeading(shippingAddressActions.FETCH_SHIPPING_ADDRESSES_START, fetchShippingAddresses),
        takeLeading(shippingAddressActions.GEOCODE_SHIPPING_ADDRESSES_START, geocodeShippingAddress),
        takeLeading(shippingAddressActions.SAVE_SHIPPING_ADDRESSES_START, saveShippingAddress),
        takeLeading(shippingAddressActions.UPDATE_SHIPPING_ADDRESSES_START, updateShippingAddress),
        takeLeading(shippingAddressActions.DEFAULT_SHIPPING_ADDRESSES_START, defaultShippingAddress),
        takeEvery(shippingAddressActions.DELETE_SHIPPING_ADDRESSES_START, deleteShippingAddress),
    ]);
}
