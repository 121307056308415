import React from 'react';
import PropTypes from 'prop-types';
import {Layout, PageHeader, Typography, Col, Empty, Row, Breakpoint, FontAwesome} from 'components/UI';
import {siderWidth as siderWidthDefault} from 'assets/styles/common';
import {classNames} from "library/helpers/utilities"

const {Sider, Content} = Layout;
const {Paragraph} = Typography;


/**
 * MainLayout
 * @component
 * @param {object} props -  Component properties
 * @param {string|number} props.alert - The alert box to be displayed
 * @param {boolean} [props.back=false] - If true, shows a back button in the header
 * @param {string|bool} props.backLabel - Label of the back button
 * @param {function} props.onBack - Callback function when the back button is pressed
 * @param {Array} props.buttons - Additional buttons on the header
 * @param {string|number} props.content - Main content to be displayed in the layout
 * @param {string} props.contentClassName - CSS class for the content section
 * @param {string|number} props.empty - Default content when the layout is empty
 * @param {string} props.emptyDescription - Description of the empty content
 * @param {string} props.emptyText - Text for the empty content section
 * @param {string|number} props.extra - Extra content or features on the header
 * @param {string|number} props.footer - Footer content
 * @param {boolean} props.ghost - If true, renders the header as a floating element
 * @param {boolean} props.hasChat - If true, includes a chat interface in the layout
 * @param {string} props.layoutClassName - CSS class for the layout
 * @param {boolean} props.paddedContent - If true, adds padding to the content
 * @param {boolean} props.pageHeader - If true, includes a header on the page
 * @param {boolean|JSX.Element} props.sider - Content of the sider section
 * @param {number} props.siderWidth - Width of the sider section
 * @param {string} props.siderPosition - Position of the sider: 'left' or 'right'
 * @param {string|number} props.tabs - Tabs to be displayed in the header
 * @param {string|number} props.title - Title of the page
 * @param {string|number} props.titleContent - Content of the title section on the header
 * @param {import('antd/lib/layout/layout').LayoutTheme} props.theme - Theme of the layout
 * @returns {ReactElement} React element to render
 */
export const MainLayout = ({
                               back = false,
                               ghost = false,
                               hasChat = true,
                               paddedContent = true,
                               pageHeader = true,
                               sider = false,
                               siderPosition = 'left',
                               siderWidth = siderWidthDefault,
                               theme = 'light',
                               alert,
                               layoutClassName,
                               title,
                               backLabel,
                               buttons,
                               onBack,
                               content,
                               contentClassName,
                               empty,
                               emptyDescription,
                               emptyText,
                               extra,
                               footer,
                               tabs,
                               titleContent
                           }) => {

    return (
        <Layout className={layoutClassName && layoutClassName}>
            {pageHeader && (
                <PageHeader
                    title={title}
                    back={back}
                    backLabel={backLabel}
                    onBack={onBack}
                    buttons={buttons}
                    extra={extra}
                    content={titleContent}
                    ghost={ghost}
                    footer={tabs || footer}
                />
            )}
            {alert}
            {!empty ? (
                <Layout key="main-content">
                    {sider && siderPosition === 'left' && (
                        <Breakpoint xs={false} sm={false} md={false}>
                            <Sider width={siderWidth} theme={theme}>
                                {sider}
                            </Sider>
                        </Breakpoint>
                    )}

                    <Content
                        className={classNames(
                            paddedContent && 'main-content',
                            contentClassName && contentClassName,
                            hasChat && 'has-chat'
                         )
                        }
                    >
                        {content}
                    </Content>

                    {sider && siderPosition === 'right'  && (
                        <Breakpoint xs={false} sm={false} md={false}>
                            <Sider width={siderWidth} theme={theme}>
                                {sider}
                            </Sider>
                        </Breakpoint>
                    )}
                </Layout>
            ) : (
                <Layout key="empty-content">
                    <Content className='main-content content-centered'>
                        <Row justify="center" style={{width: '100%'}}>
                            <Col xs={24} md={12} xl={8}>
                                <Empty image={<FontAwesome empty />} description={emptyDescription}>
                                    <Paragraph>{emptyText}</Paragraph>
                                </Empty>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            )}
        </Layout>
    );
};

MainLayout.propTypes = {
    alert: PropTypes.any,
    back: PropTypes.bool,
    backLabel: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    onBack: PropTypes.func,
    buttons: PropTypes.array,
    content: PropTypes.any,
    contentClassName: PropTypes.string,
    empty: PropTypes.any,
    emptyDescription: PropTypes.string,
    emptyText: PropTypes.string,
    extra: PropTypes.any,
    footer: PropTypes.any,
    ghost: PropTypes.bool,
    hasChat: PropTypes.bool,
    layoutClassName: PropTypes.string,
    paddedContent: PropTypes.bool,
    pageHeader: PropTypes.bool,
    sider: PropTypes.any,
    siderWidth: PropTypes.number,
    siderPosition: PropTypes.oneOf(['left', 'right']),
    tabs: PropTypes.any,
    title: PropTypes.any,
    titleContent: PropTypes.any,
    theme: PropTypes.any,
};

export default MainLayout;
