import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Modal, TreeSelect, message} from 'components/UI';
import {lang} from 'config/lang';
import {FormItem, NoResults} from 'components/FormikForm';
import {useDispatch, useSelector} from 'react-redux';
import teamActions from 'redux/team/actions';
import {getTeamInfo, getTeams, getTeamSwitcherVisible} from 'redux/selectors';
import {clearLocalStore, removeLocalCache} from 'library/helpers/localStore';
import {useLocation} from 'react-router-dom';
import {usePrev} from 'hooks/utility/usePrev';
import {mapNestedTeamsSelect, mapTeamsToParent} from 'library/helpers/teams';
import {NEW_TEAM} from 'config/redux';
import {goToRoute} from 'library/helpers/routing';
import {routes} from 'config/routes';

const TeamSwitcher = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const teams = useSelector((state) => getTeams(state));
    const team = useSelector((state) => getTeamInfo(state));
    const visible = useSelector((state) => getTeamSwitcherVisible(state));

    const [teamSwitching, setTeamSwitching] = useState(false);
    const [selectedTeamId, setSelectedTeamId] = useState(undefined);

    const handleChangeTeamContext = useCallback(
        () => dispatch(teamActions.changeTeamStart(selectedTeamId)),
        [dispatch, selectedTeamId],
    );
    const handleHideTeamSwitcher = useCallback(() => dispatch(teamActions.toggleSwitcherVisible(false)), [dispatch]);

    const setSelectedTeamIdUndefined = useCallback(() => setSelectedTeamId(undefined), []);

    const prevChanged = usePrev(team.changed);
    useEffect(() => {
        if (team.changing) {
            message.loading({content: lang.messages.changing_teams});
            setTeamSwitching(true);
        }

        if (prevChanged !== null && prevChanged !== team.changed && team.changed) {
            const currentURL = location.pathname + location.hash + location.search;

            clearLocalStore();
            removeLocalCache(NEW_TEAM);

            // If we're on a product detail page and switch teams,
            // redirect to main product grid page to avoid an error
            // if the team does not have access to that design
            if (currentURL.indexOf('create') !== -1) {
                return goToRoute(routes.private.paths.CREATE);
            }

            return goToRoute(currentURL);
        }
    }, [dispatch, team, prevChanged, location]);

    const defaultTeam = teams.data && teams.data.filter((i) => i.parent_team_id === null);

    const parentTeamId =
        defaultTeam && defaultTeam.length ? defaultTeam[0].id : teams && teams.data ? teams.data[0].id : {};

    const teamData = mapTeamsToParent(teams ? teams.data : {}, parentTeamId);

    useEffect(() => {
        if (team.loading) return;

        setTeamSwitching(false);
        dispatch(teamActions.toggleSwitcherVisible(false));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team]);

    return (
        teams.data !== null &&
        !teams.loading && (
            <Modal
                open={visible}
                title={lang['switch_teams']}
                width={500}
                maskClosable={true}
                key="team-switch-modal"
                okText={lang.switch_teams}
                onCancel={handleHideTeamSwitcher}
                okButtonProps={{
                    loading: teamSwitching,
                    disabled: selectedTeamId === undefined,
                }}
                cancelButtonProps={{
                    disabled: teamSwitching,
                }}
                onOk={handleChangeTeamContext}
                afterClose={setSelectedTeamIdUndefined}
            >
                <div className="ant-form-vertical">
                    <FormItem label={lang['team']} style={{marginBottom: 0}} required>
                        <TreeSelect
                            onChange={setSelectedTeamId}
                            defaultValue={team.data && team.data.id}
                            disabled={teamSwitching}
                            treeData={mapNestedTeamsSelect(teamData)}
                            treeDefaultExpandAll
                            showSearch={true}
                            size="large"
                            notFoundContent={<NoResults />}
                        />
                    </FormItem>
                </div>
            </Modal>
        )
    );
};

TeamSwitcher.defaultProps = {
    link: false,
    selectStyles: {},
    selectWidth: 180,
    width: null,
    onCancel: null,
};

TeamSwitcher.propTypes = {
    link: PropTypes.bool,
    onCancel: PropTypes.func,
    selectStyles: PropTypes.object,
    selectWidth: PropTypes.number,
    width: PropTypes.number,
};

export default TeamSwitcher;
