import React, {forwardRef} from 'react';
import {Popover as AntPopover} from 'antd';
import {FontAwesome, Button, Typography} from 'components/UI';
import {iconStyle} from 'assets/styles/common';
import PropTypes from 'prop-types';

const {Text} = Typography;

// eslint-disable-next-line react/display-name
const Popover = forwardRef((props, ref) => {
    return (
        <AntPopover {...props} ref={ref} trigger={props.trigger}>
            {props.help || props.info ? (
                <Button
                    type={props.type}
                    className="popover-info-btn"
                    style={{
                        ...props.buttonStyle,
                    }}
                >
                    <Text>
                        <FontAwesome
                            icon={props.icon ? props.icon : props.help ? 'circle-question' : 'circle-info'}
                            size={props.size}
                            type={iconStyle}
                            {...props.iconProps}
                        />
                    </Text>
                </Button>
            ) : (
                props.children || ''
            )}
        </AntPopover>
    );
});

Popover.defaultProps = {
    help: false,
    icon: '',
    size: 'md',
    trigger: 'click',
    type: 'link',
};

Popover.propTypes = {
    buttonStyle: PropTypes.any,
    children: PropTypes.any,
    help: PropTypes.bool.isRequired,
    icon: PropTypes.string,
    iconProps: PropTypes.any,
    info: PropTypes.bool,
    size: PropTypes.string,
    trigger: PropTypes.any,
    type: PropTypes.string,
};

export default Popover;
