import {apiEndpoints} from 'config/api';
import {clearSessionCache, getSessionCache, setSessionCache} from '../localStore';
import {INTENDED_URL} from 'config/redux';
import {LOGIN_AS_PATH, PROCUREMENT_REDIRECT_PATH, SIGN_UP_PATH} from 'config/url';
import {DEFAULT_QUERY} from 'config/constants';
import {history, store} from 'redux/store';
import {publicRoutesList, routes} from 'config/routes';
import {matchPath} from 'react-router-dom';
import {historyActions} from 'redux/history/actions';
import {getHistory} from 'redux/history/selectors';
import {scalarToArray} from 'library/helpers/utilities';

export const shouldRedirectToLogin = (pathname) => {
    // Because the routes are keyed, and I don't know how to get the route match,
    // we are stripping out all the route variables from the  public route list
    return publicRoutesList
        .map((item) => (item.indexOf(':') > -1 ? item.substring(0, item.indexOf(':')) : item))
        .reduce((carry, item) => {
            if (carry && pathname.indexOf(item) > -1) {
                carry = false;
            }

            return carry;
        }, true);
};

export const buildQuery = (params) => {
    const getParams = (paramsArg) => {
        const searchParams = new URLSearchParams(paramsArg);

        searchParams.forEach(
            (value, key) => (value === 'null' || value === 'undefined' || value === null) && searchParams.delete(key),
        );
        searchParams.sort();

        return searchParams.toString();
    };

    return params
        ? getParams(params)
        : window.location.search
        ? window.location.search.substring(1)
        : getParams(DEFAULT_QUERY);
};

export const getRoute = (route, replacements = {}, appendDomain = false) => {
    if (replacements)
        Object.keys(replacements).forEach((key) => {
            route = route.replace(`:${key}`, replacements[key]);
        });

    route = route.replace(/\/:[a-zA-Z-_]*\?/g, '');

    // append the api url
    if (appendDomain) route = apiEndpoints.api + route;

    return route;
};

export const routeUrl = (route, replacements = {}) => {
    return getRoute(route, replacements, true);
};

export const isPublicRoute = (path) => {
    return Object.keys(routes.public.paths).some((route) => {
        // split up route
        const publicRoutes = routes.public.paths[route]
            .split('/')
            .filter((part) => {
                return part.indexOf(':') === -1;
            })
            .filter((isEmpty) => isEmpty);

        // split path. only match roots if path has the exact same root
        const regEx = new RegExp(`(/[\\da-z-]*){${publicRoutes.length}}`, 'g');
        const matchPath = path.match(regEx) || [''];

        return '/' + publicRoutes.join('/') === matchPath[0].replace(/\/$/, '');
    });
};

export const deepFlattenObject = (path) => {
    if (typeof path === 'string') return path;

    // now, we recursively call this on all children
    return Object.keys(path).reduce((carry, key) => {
        if (typeof path[key] === 'object') {
            return [...carry, ...deepFlattenObject(path[key])];
        }
        return [...carry, path[key]];
    }, []);
};

export const isPrivateRoute = (path) => {
    const flatRoutes = Object.keys(routes.private.paths)
        .map((key) => deepFlattenObject(routes.private.paths[key]))
        .flat();
    return flatRoutes.some((route) => matchPath(path, {path: route, exact: true}));
};

export const isNeutralRoute = (path) => {
    return Object.keys(routes.neutral.paths).some((route) => {
        // split up route
        const neutralRoutes = routes.neutral.paths[route]
            .split('/')
            .filter((part) => {
                return part.indexOf(':') === -1;
            })
            .filter((isEmpty) => isEmpty);

        // split path. only match roots if path has the exact same root
        const regEx = new RegExp(`(/[a-z-]*){${neutralRoutes.length}}`, 'g');
        const matchPath = path.match(regEx) || [''];

        return '/' + neutralRoutes.join('/') === matchPath[0].replace(/\/$/, '');
    });
};

export const isValidRoute = (path) => isNeutralRoute(path) || isPrivateRoute(path) || isPublicRoute(path);

export const getIntendedUrl = () => getSessionCache(INTENDED_URL) || routes.private.paths.DASHBOARD;

export const setIntendedUrl = (location) => {
    if (location.pathname === routes.neutral.paths.LOGOUT) return;
    if (location.pathname === routes.private.paths.DASHBOARD) return;

    setSessionCache(INTENDED_URL, location.pathname);
};
export const clearIntendedUrl = () => clearSessionCache(INTENDED_URL);

export const isLoginAsPath = (path) => path.startsWith(LOGIN_AS_PATH);

export const isPunchoutPath = (path) => path.startsWith(PROCUREMENT_REDIRECT_PATH);

export const isSignUpPath = (path) => path.startsWith(SIGN_UP_PATH);

export const getDigitalDetailLink = (field, idType = 'id') => {
    return getRoute(routes.private.paths.ORDERS_DETAIL_DIGITAL, {
        id: field.bulk_list_id ?? field[idType],
        type: field.bulk_list_id ? 'bulk' : 'smart',
    });
};

export const goToRoute = (route, options) => {
    store.dispatch(historyActions.pushHistory(route));

    return history.push(route, {...options, from: history.location.pathname});
};

/**
 *
 * @param options {object} - options for the function
 * @param omit {array|string|null} - the route to omit from matching when going back
 */
export const goToPreviousRoute = ({defaultRoute = null, omit = null} = {}) => {
    if (history?.location?.state?.from.includes(routes.public.paths.AUTH_CALLBACK)) {
        return goToRoute(routes.private.paths.DASHBOARD);
    }

    const internalHistory = getHistory(store.getState());

    let indexOfPreviousRoute = internalHistory.length - 2;
    let currentRoute = internalHistory[indexOfPreviousRoute];

    if (omit) {
        const checkIsOmission = (route) =>
            route &&
            scalarToArray(omit)
                .concat(history.location.pathname)
                .some((omitPath) => matchPath(route?.replace?.(/#.*/, ''), {path: omitPath}));
        while (indexOfPreviousRoute >= 0 && checkIsOmission(currentRoute)) {
            indexOfPreviousRoute--;

            if (indexOfPreviousRoute >= 0) {
                currentRoute = internalHistory[indexOfPreviousRoute];
            }
        }
    }

    return goToRoute(currentRoute || defaultRoute || routes.private.paths.DASHBOARD);
};

export const splitParamsFromRoute = (match) => ({
    match,
    base: match.path.split('/:')[0],
    params:
        match.path
            .split('/:')?.[1]
            ?.split?.('/')
            .map((param) => `:${param}`) ?? [],
});

window.goToRoute = goToRoute;
