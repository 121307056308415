import {all, takeEvery, put} from 'redux-saga/effects';
import recipientGroupsActions from 'redux/recipientGroups/actions';
import {api} from 'library/helpers/api';
import {processError} from 'library/helpers/response';
import {getRoute} from 'library/helpers/routing';
import {apiEndpoints} from 'config/api';

function* updateRecipientGroup({payload}) {
    const route = getRoute(apiEndpoints.recipients.processToGroups, {
        id: payload.id,
    });

    try {
        yield api.post(route, payload);
        yield put(recipientGroupsActions.updateRecipientGroupSuccess());
    } catch (error) {
        yield put(recipientGroupsActions.updateRecipientGroupFailure(processError(error)));
    }
}

export default function* recipientGroupSaga() {
    yield all([takeEvery(recipientGroupsActions.UPDATE_RECIPIENT_GROUP_DATA_START, updateRecipientGroup)]);
}
