import teamActions from './actions';

import {LOGOUT} from 'redux/auth/auth.types';

const initialState = {
    info: {
        data: null,
        error: null,
        loading: false,
        pristine: true,
        changed: false,
        changing: false,
        loaded: false,
    },
    members: {
        data: null,
        error: null,
        loading: false,
        changed: false,
        changing: false,
        loaded: false,
    },
    allMembers: {
        data: null,
        error: null,
        loading: false,
        changed: false,
        changing: false,
        loaded: false,
    },
    switcherVisible: false,
};
const teamsReducer = (state = initialState, action) => {
    switch (action.type) {
        case teamActions.TOGGLE_SWITCHER_VISIBLE:
            return {
                ...state,
                switcherVisible: action.payload,
            };
        case teamActions.FETCH_TEAM_DATA_START:
            return {
                ...state,
                info: {
                    ...state.info,
                    loading: true,
                    error: null,
                    loaded: false,
                },
            };
        case teamActions.FETCH_TEAM_DATA_SUCCESS:
            return {
                ...state,
                info: {
                    ...state.info,
                    data: action.payload,
                    loading: false,
                    error: null,
                    loaded: true,
                },
            };
        case teamActions.FETCH_TEAM_DATA_FAILURE:
            return {
                ...state,
                info: {
                    ...state.info,
                    loading: false,
                    error: action.payload,
                    loaded: false,
                },
            };

        case teamActions.FETCH_MEMBERS_DATA_START:
            return {
                ...state,
                members: {
                    ...state.members,
                    loading: true,
                    error: null,
                    loaded: false,
                },
            };
        case teamActions.FETCH_MEMBERS_DATA_SUCCESS:
            return {
                ...state,
                members: {
                    ...state.members,
                    data: action.payload,
                    loading: false,
                    error: null,
                    loaded: true,
                },
            };

        case teamActions.FETCH_MEMBERS_DATA_FAILURE:
            return {
                ...state,
                members: {
                    ...state.members,
                    loading: false,
                    error: action.payload,
                    loaded: false,
                },
            };

        case teamActions.FETCH_ALL_MEMBERS_DATA_START:
            return {
                ...state,
                allMembers: {
                    ...state.allMembers,
                    loading: true,
                    error: null,
                    loaded: false,
                },
            };

        case teamActions.FETCH_ALL_MEMBERS_DATA_SUCCESS:
            return {
                ...state,
                allMembers: {
                    ...state.allMembers,
                    data: action.payload,
                    loading: false,
                    error: null,
                    loaded: true,
                },
            };
        case teamActions.FETCH_ALL_MEMBERS_DATA_FAILURE:
            return {
                ...state,
                allMembers: {
                    ...state.allMembers,
                    loading: false,
                    error: action.payload,
                    loaded: false,
                },
            };

        case teamActions.CREATE_INVITE_LINK_START:
        case teamActions.CREATE_INVITE_EMAIL_START:
        case teamActions.INVITE_EXISTING_MEMBERS_DATA_START:
        case teamActions.CANCEL_INVITE_DATA_START:
        case teamActions.CHANGE_ROLE_DATA_START:
        case teamActions.REMOVE_MEMBER_DATA_START:
        case teamActions.TOGGLE_USER_DATA_START:
            return {
                ...state,
                members: {
                    ...state.members,
                    changed: false,
                    changing: true,
                    error: null,
                },
            };
        case teamActions.CREATE_INVITE_LINK_SUCCESS:
        case teamActions.CREATE_INVITE_EMAIL_SUCCESS:
        case teamActions.INVITE_EXISTING_MEMBERS_DATA_SUCCESS:
        case teamActions.CANCEL_INVITE_DATA_SUCCESS:
        case teamActions.CHANGE_ROLE_DATA_SUCCESS:
        case teamActions.REMOVE_MEMBER_DATA_SUCCESS:
        case teamActions.TOGGLE_USER_DATA_SUCCESS:
            return {
                ...state,
                members: {
                    ...state.members,
                    changed: true,
                    changing: false,
                    error: null,
                },
            };
        case teamActions.CREATE_INVITE_LINK_FAILURE:
        case teamActions.CREATE_INVITE_EMAIL_FAILURE:
        case teamActions.INVITE_EXISTING_MEMBERS_DATA_FAILURE:
        case teamActions.CANCEL_INVITE_DATA_FAILURE:
        case teamActions.CHANGE_ROLE_DATA_FAILURE:
        case teamActions.REMOVE_MEMBER_DATA_FAILURE:
        case teamActions.TOGGLE_USER_DATA_FAILURE:
            return {
                ...state,
                members: {
                    ...state.members,
                    changed: false,
                    changing: false,
                    error: action.payload,
                },
            };

        case teamActions.CHANGE_TEAM_START:
            return {
                ...state,
                info: {
                    ...state.info,
                    pristine: false,
                    changed: false,
                    changing: true,
                    error: null,
                },
            };
        case teamActions.CHANGE_TEAM_SUCCESS:
            return {
                ...state,
                info: {
                    ...state.info,
                    pristine: false,
                    changed: true,
                    changing: false,
                    error: null,
                },
            };
        case teamActions.CHANGE_TEAM_FAILURE:
            return {
                ...state,
                info: {
                    ...state.info,
                    pristine: false,
                    changed: false,
                    changing: false,
                    error: action.payload,
                },
            };

        case LOGOUT:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default teamsReducer;
