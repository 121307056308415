import React from 'react';
import {render as reactRender} from 'rc-util/lib/React/render'; // TODO: add cleanup via unmount as reactUnmount
import {Modal} from 'components/UI';
import {lang} from 'config/lang';
import Content from 'components/UI/Confirm/Prompt/Content';

const initialProperties = {
    title: 'Confirm Action',
    explainer: null,
    inputText: lang['understand'],
    onCancel: null,
    onOk: null,
    icon: false,
    cancelButtonProps: {
        size: 'large',
        type: 'default',
        ghost: false,
    },
    okButtonProps: {
        size: 'large',
        type: 'primary',
        danger: true,
    },
    cancelText: lang['go_back'],
    okText: 'Confirm',
    maskClosable: false,
    visible: true,
};

/**
 * @param {Object} props
 *
 * @param {JSXElement|string} props.title Title of the modal.
 * @param {Function} props.onOk Callback function to be called when the user confirms the action.
 * @param {JSXElement|string?} props.inputText The text to be typed in the input field, which will otherwise be 'I UNDERSTAND'.
 * @param {Function?} props.onCancel Callback function to be called when the modal is cancelled.
 * @param {JSXElement|string?} props.explainer The body of the modal.
 * @param {JSXElement|string?} props.cancelText Override for modal cancel button text, which will otherwise be 'Cancel'.
 * @param {JSXElement|string?} props.okText Override for modal confirm button text, which will otherwise be 'Confirm'.
 * @param {Object?} props.cancelButtonProps Override for modal cancel button props.
 * @param {Object?} props.okButtonProps Override for modal ok button props.
 * @param {JSXElement?} props.icon Icon for confirmation modal.
 * @param {boolean?} props.maskClosable Allows closing the modal by clicking on the mask.
 *
 * @returns {{show: ((function(): void)|*)}}
 */
const confirmPrompt = ({
    inputText,
    onCancel,
    onOk,
    explainer,
    title,
    icon,
    cancelButtonProps,
    okButtonProps,
    cancelText,
    okText,
    maskClosable,
    visible,
}) => {
    const container = document.createDocumentFragment();

    function render(props) {
        const execute = () => {
            if (props.inputValue?.toLowerCase() !== props.inputText?.toLowerCase()) return;

            onOk && onOk();
            render({...props, visible: false});
        };

        setTimeout(() => {
            reactRender(
                <Modal
                    open={props.visible}
                    onCancel={() => {
                        onCancel && onCancel();
                        render({...props, visible: false});
                    }}
                    onOk={() => execute()}
                    okText={props.okText}
                    cancelText={props.cancelText}
                    icon={props.icon}
                    cancelButtonProps={props.cancelButtonProps}
                    okButtonProps={{
                        ...props.okButtonProps,
                        disabled: props.inputValue?.toLowerCase() !== props.inputText?.toLowerCase(),
                    }}
                    title={props.title}
                    maskClosable={props.maskClosable}
                >
                    {explainer && explainer}

                    <Content inputText={inputText} props={props} execute={execute} render={render} />
                </Modal>,
                container,
            );
        });
    }

    render({
        title: title || initialProperties.title,
        inputText: inputText.toUpperCase() ?? initialProperties.inputText.toUpperCase(),
        onCancel: onCancel ?? initialProperties.onCancel,
        onOk: onOk ?? initialProperties.onOk,
        explainer: explainer ?? initialProperties.explainer,
        icon: icon ?? initialProperties.icon,
        cancelButtonProps: cancelButtonProps ?? initialProperties.cancelButtonProps,
        okButtonProps: okButtonProps ?? initialProperties.okButtonProps,
        cancelText: cancelText ?? initialProperties.cancelText,
        okText: okText ?? initialProperties.okText,
        maskClosable: maskClosable ?? initialProperties.maskClosable,
        visible: visible ?? initialProperties.visible,
    });
};

export default confirmPrompt;
