import recentOrdersActions from './actions';
import {DIGITAL_ORDER, PHYSICAL_ORDER} from 'config/orders';

export const recentOrdersInitialState = {
    current: PHYSICAL_ORDER,
    loading: false,
    resetting: false,
    physical: {
        data: null,
        loading: false,
        error: false,
    },
    digital: {
        data: null,
        loading: false,
        error: false,
    },
};

const getFetchData = (key, action, state, error) => {
    return {
        data:
            action.payload.data?.[key]?.data ||
            (action.payload.key === key ? action.payload.data : false) ||
            state[key].data ||
            recentOrdersInitialState[key].data,
        loading: false,
        error: error,
    };
};

const recentOrdersReducer = (state = recentOrdersInitialState, action) => {
    switch (action.type) {
        case recentOrdersActions.SET_CURRENT_RECENT_ORDERS:
            return {
                ...state,
                current: action.payload,
            };

        case recentOrdersActions.RESET_RECENT_ORDERS:
            // this will delay the trigger giving everything else a moment to do what it needs to
            return {
                ...recentOrdersInitialState,
                current: state.current,
                resetting: true,
            };

        case recentOrdersActions.RESET_RECENT_ORDERS_SUCCESS:
            return {
                ...recentOrdersInitialState,
                current: state.current,
            };

        case recentOrdersActions.FETCH_RECENT_ORDERS:
            return {
                ...state,
                current: action.payload.key,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    loading: true,
                },
                loading: true,
            };

        case recentOrdersActions.FETCH_BOTH_RECENT_ORDERS:
            return {
                ...state,
                current: state.current,
                digital: {
                    ...state[DIGITAL_ORDER],
                    loading: true,
                },
                physical: {
                    ...state[PHYSICAL_ORDER],
                    loading: true,
                },
                loading: true,
            };

        case recentOrdersActions.FETCH_RECENT_ORDERS_SUCCESS:
            return {
                ...state,
                physical: {...getFetchData(PHYSICAL_ORDER, action, state, false)},
                digital: {...getFetchData(DIGITAL_ORDER, action, state, false)},
                error: false,
                loading: false,
            };

        case recentOrdersActions.FETCH_RECENT_ORDERS_ERROR:
            return {
                ...state,
                physical: {...getFetchData(PHYSICAL_ORDER, action, state, true)},
                digital: {...getFetchData(DIGITAL_ORDER, action, state, true)},
                error: true,
                loading: false,
            };

        case recentOrdersActions.MODIFY_RECENT_ORDERS: {
            const {key, orderIds, newData} = action.payload;

            const updatedDataData = state[key].data.data.map((order) => {
                if (orderIds.includes(order.id)) {
                    return {...order, ...newData};
                }

                return order;
            });

            return {
                ...state,
                [key]: {
                    ...state[key],
                    data: {
                        ...state[key].data,
                        data: updatedDataData,
                    },
                },
            };
        }

        default:
            return state;
    }
};

export default recentOrdersReducer;
