import {
    AUTH_LOAD_FROM_STORAGE,
    AUTH_LOAD_FROM_STORAGE_COMPLETE,
    AUTH_RESET_DEFAULT_VALUE,
    AUTH_RESET_ERROR,
    AUTH_SET_ERROR,
    AUTH_SET_VERIFY,
    AUTHENTICATE,
    AUTHENTICATE_FAILED,
    AUTHENTICATE_SUCCESS,
    CONFIRM_REGISTER,
    CONFIRM_REGISTER_FAILURE,
    CONFIRM_REGISTER_SUCCESS,
    FETCH_USER,
    FETCH_USER_FAILURE,
    FETCH_USER_SUCCESS,
    LOGOUT,
    LOGOUT_COMPLETE,
    REGISTER_FAILED,
    REGISTER_RESET,
    REGISTER_START,
    REGISTER_SUCCESS,
    RESET_AUTHENTICATION_ERROR,
    SET_USER,
} from 'redux/auth/auth.types';

const initialState = {
    // is set when amplify is configured so it will try to configure more than once
    configured: false,
    // deprecated
    loading: false,
    // deprecated
    verify: null,

    //successfully registered details
    details: null,

    // this will can be used to set the defaultValue of what ever step we are in
    defaultValue: null,
    // redirect after successful login
    redirect: null,

    // registering
    registering: false,
    registered: false,

    //authenticating
    authenticating: false,
    authenticated: false,

    //confirming
    confirming: false,
    confirmed: false,

    // authenticated user information
    user: null,

    // error of any step we are in
    // should be null if not object
    // object if error exists; message is required {message: 'shit hit the fan'}
    error: null,
};
const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTHENTICATE:
            return {
                ...state,
                authenticating: true,
            };
        case AUTHENTICATE_SUCCESS:
            return {
                ...state,
                authenticated: true,
                authenticating: false,
                user: action.payload,
                error: null,
            };
        case AUTHENTICATE_FAILED:
            return {
                ...state,
                confirmed: false,
                authenticated: false,
                authenticating: false,
                error: action.payload,
            };
        case RESET_AUTHENTICATION_ERROR:
            return {
                ...state,
                error: action.payload,
            };

        case AUTH_RESET_DEFAULT_VALUE:
            return {
                ...state,
                defaultValue: null,
            };

        case CONFIRM_REGISTER:
            return {
                ...state,
                confirming: true,
            };
        case CONFIRM_REGISTER_SUCCESS:
            return {
                ...state,
                defaultValue: state.username,
                redirect: state.redirect,
                confirming: false,
                confirmed: true,
                verify: null,
            };
        case CONFIRM_REGISTER_FAILURE:
            return {
                ...state,
                confirming: false,
                confirmed: false,
                error: action.payload,
            };

        case AUTH_SET_VERIFY:
            return {
                ...state,
                verify: action.payload,
            };

        case AUTH_LOAD_FROM_STORAGE:
            return {
                ...state,
                loading: true,
            };
        case AUTH_LOAD_FROM_STORAGE_COMPLETE:
            return {
                ...state,
                loading: false,
            };

        case REGISTER_RESET:
            return {
                ...state,
                details: null,
                registering: false,
                registered: false,
            };
        case REGISTER_START:
            return {
                ...state,
                details: null,
                registering: true,
                registered: false,
            };
        case REGISTER_SUCCESS:
            return {
                ...state,
                details: action.payload,
                registering: false,
                registered: true,
                error: null,
            };
        case REGISTER_FAILED:
            return {
                ...state,
                details: null,
                registering: false,
                registered: false,
                error: action.payload,
            };

        case FETCH_USER:
            return {
                ...state,
                fetching: true,
                error: null,
            };
        case FETCH_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                fetching: false,
                error: null,
            };
        case FETCH_USER_FAILURE:
            return {
                ...state,
                user: null,
                fetching: false,
                error: action.payload,
            };

        case LOGOUT:
            return {
                ...initialState,
                loggingOut: true,
            };

        case LOGOUT_COMPLETE:
            return {
                ...initialState,
                loggingOut: false,
            };

        case SET_USER:
            return {
                ...state,
                user: action.payload,
            };

        case AUTH_SET_ERROR:
            return {
                ...state,
                error: action.payload,
            };

        case AUTH_RESET_ERROR:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export default authReducer;
