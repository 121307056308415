import senderNamesActions from 'redux/senderNames/actions';

const initialState = {
    data: null,
    loading: false,
    saved: false,
    deleted: false,
    error: null,
};

const senderNames = (state = initialState, action) => {
    switch (action.type) {
        case senderNamesActions.FETCH_SENDER_NAMES_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case senderNamesActions.FETCH_SENDER_NAMES_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null,
            };
        case senderNamesActions.FETCH_SENDER_NAMES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case senderNamesActions.SAVE_SENDER_NAMES_START:
            return {
                ...state,
                saved: false,
                saving: true,
                error: null,
            };
        case senderNamesActions.SAVE_SENDER_NAMES_SUCCESS:
            return {
                ...state,
                id: action?.payload?.data?.id,
                saved: true,
                saving: false,
                error: null,
            };
        case senderNamesActions.SAVE_SENDER_NAMES_ERROR:
            return {
                ...state,
                saved: false,
                saving: false,
                error: action.payload,
            };

        case senderNamesActions.DELETE_SENDER_NAMES_START:
            return {
                ...state,
                deleted: false,
                error: null,
            };
        case senderNamesActions.DELETE_SENDER_NAMES_SUCCESS:
            return {
                ...state,
                deleted: true,
                error: null,
            };
        case senderNamesActions.DELETE_SENDER_NAMES_ERROR:
            return {
                ...state,
                deleted: false,
                error: action.payload,
            };

        case senderNamesActions.RESET_SENDER_NAMES_ACTIONS:
            return {
                ...state,
                deleted: false,
                saved: false,
                error: null,
            };

        case senderNamesActions.CLEAR_DEFAULT_SENDER_NAMES_START:
            return {
                ...state,
                error: null,
            };
        case senderNamesActions.CLEAR_DEFAULT_SENDER_NAMES_SUCCESS:
            return {
                ...state,
                error: null,
            };
        case senderNamesActions.CLEAR_DEFAULT_SENDER_NAMES_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case senderNamesActions.SET_SENDER_NAMES:
            return {
                ...state,
                data: action.payload,
            };
        default:
            return state;
    }
};

export default senderNames;
