import React from 'react';

const icon = () => (
    <svg id="Layer_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <circle cx="256" cy="256" fill="#f0f0f0" r="256"/>
        <path
            d="m496.077 166.957h-480.154c-10.291 27.733-15.923 57.729-15.923 89.043s5.632 61.31 15.923 89.043h480.155c10.29-27.733 15.922-57.729 15.922-89.043s-5.632-61.31-15.923-89.043z"
            fill="#0052b4"/>
        <g fill="#d80027">
            <path
                d="m256 0c-77.591 0-147.114 34.524-194.061 89.043h388.121c-46.946-54.519-116.469-89.043-194.06-89.043z"/>
            <path
                d="m450.061 422.957h-388.122c46.947 54.519 116.47 89.043 194.061 89.043s147.114-34.524 194.061-89.043z"/>
        </g>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
    </svg>
);

export default icon;
