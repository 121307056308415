import {SEND_TO_RECIPIENTS} from 'config/orders/digital';

export const checkoutState = {
    campaignId: 0,
    productId: 0,
    sendTo: SEND_TO_RECIPIENTS,
    deliveryMethod: 'email',
    isCustom: false,
    denomination: 10,
    quantity: 10,
    greetingCard: 'none',
};
