import {all} from 'redux-saga/effects';

import adjustmentsSaga from 'redux/adjustments/saga';
import appSaga from 'redux/app/saga';
import authSaga from 'redux/auth/auth.saga';
import authenticationSaga from 'redux/authentication/saga';
import uploadSaga from 'redux/upload/saga';
import businessHoursSaga from 'redux/businessHours/saga';
import campaignCreditCardsSaga from './campaignCreditCards/saga';
import campaignSaga from './campaign/saga';
import campaignTypeSaga from 'redux/campaignTypes/saga';
import campaignsSaga from './campaigns/saga';
import checkoutLookupSaga from './checkoutLookup/saga';
import companySaga from './company/saga';
import contactPropertiesSaga from 'redux/contactProperties/saga';
import couponSaga from 'redux/coupon/saga';
import creditCardsSaga from './creditCards/saga';
import descendantsSaga from 'redux/descendants/saga';
import fundingSaga from 'redux/funding/saga';
import fundingAmountSaga from 'redux/fundingAmount/saga';
import fundingItemSaga from './fundingItem/saga';
import fundingMethodsSaga from './fundingMethods/saga';
import groupsSaga from 'redux/groups/saga';
import inviteSaga from 'redux/invite/saga';
import orderSaga from 'redux/order/saga';
import orderSummarySaga from 'redux/orderSummary/saga';
import ordersSaga from 'redux/orders/saga';
import productsSaga from 'redux/products/saga';
import primaryProductsSaga from 'redux/primaryProducts/saga';
import profileSaga from 'redux/profile/saga';
import recentOrdersSaga from 'redux/recentOrders/saga';
import recipientCampaignSaga from 'redux/recipientCampaigns/saga';
import recipientGroupSaga from 'redux/recipientGroups/saga';
import recipientSaga from 'redux/recipient/saga';
import recipientsSaga from 'redux/recipients/saga';
import recipientsUploadSaga from 'redux/recipientsUpload/saga';
import regionSaga from 'redux/regions/saga';
import savedDesignsCampaignSaga from 'redux/savedDesignsCampaign/saga';
import savedDesignsSaga from 'redux/savedDesigns/saga';
import savedMessagesSaga from 'redux/savedMessages/saga';
import sendSaga from 'redux/send/saga';
import senderNamesSaga from 'redux/senderNames/saga';
import shippingAddressCampaignSaga from 'redux/shippingAddressCampaign/saga';
import shippingAddressSaga from 'redux/shippingAddress/saga';
import shippingRatesSaga from 'redux/shippingRates/saga';
import signupSaga from 'redux/signup/saga';
import teamSaga from './team/saga';
import teamsSaga from './teams/saga';
import bulkOrderSaga from './bulkOrder/saga';
import notificationsSaga from './notifications/saga';
import integrationsSaga from 'redux/integrations/saga';
import fundingTransferSaga from 'redux/fundingTransfer/saga';
import brandKitsSaga from 'redux/brandKits/saga';

export default function* rootSaga() {
    yield all([
        integrationsSaga(),
        adjustmentsSaga(),
        appSaga(),
        authSaga(),
        authenticationSaga(),
        brandKitsSaga(),
        bulkOrderSaga(),
        businessHoursSaga(),
        campaignCreditCardsSaga(),
        campaignSaga(),
        campaignTypeSaga(),
        campaignsSaga(),
        checkoutLookupSaga(),
        companySaga(),
        contactPropertiesSaga(),
        couponSaga(),
        creditCardsSaga(),
        descendantsSaga(),
        fundingAmountSaga(),
        fundingMethodsSaga(),
        fundingSaga(),
        fundingItemSaga(),
        fundingTransferSaga(),
        groupsSaga(),
        inviteSaga(),
        notificationsSaga(),
        orderSaga(),
        orderSummarySaga(),
        ordersSaga(),
        productsSaga(),
        primaryProductsSaga(),
        profileSaga(),
        recentOrdersSaga(),
        recipientCampaignSaga(),
        recipientGroupSaga(),
        recipientSaga(),
        recipientsSaga(),
        recipientsUploadSaga(),
        regionSaga(),
        savedDesignsCampaignSaga(),
        savedDesignsSaga(),
        savedMessagesSaga(),
        sendSaga(),
        senderNamesSaga(),
        shippingAddressCampaignSaga(),
        shippingAddressSaga(),
        shippingRatesSaga(),
        signupSaga(),
        teamSaga(),
        teamsSaga(),
        uploadSaga(),
    ]);
}
