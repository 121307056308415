import {createSelector} from 'reselect';
const app = (state) => state.app;
const campaign = (state) => state.campaign;
const campaigns = (state) => state.campaigns;
const campaignCreditCards = (state) => state.campaignCreditCards;
const campaignShippingAddresses = (state) => state.shippingAddressCampaign;
const campaignTypes = (state) => state.campaignTypes;
const companies = (state) => state.companies;
const coupons = (state) => state.coupons;
const creditCards = (state) => state.creditCards;
const geocodeStore = (state) => state.shippingAddress.geocode;
const groupsList = (state) => state.groups.list;
const invite = (state) => state.invite;
const notifications = (state) => state.notifications;
const products = (state) => state.products;
const profile = (state) => state.profile;
const recentOrders = (state) => state.recentOrders;
const recipient = (state) => state.recipient;
const regions = (state) => state.regions;
const recipients = (state) => state.recipients;
const savedDesigns = (state) => state.savedDesigns;
const savedDesignsCampaign = (state) => state.savedDesignsCampaign;
const shippingRates = (state) => state.shippingRates;
const team = (state) => state.team;
const teams = (state) => state.teams;
const upload = (state) => state.upload;

export const getRecentOrders = createSelector([recentOrders], (recentOrders) => recentOrders);
export const getCurrentRecentOrders = createSelector([recentOrders], (recentOrders) => recentOrders.current);
const groups = (state) => state.groups;

export const filteredProductCategory = createSelector([products, profile], (products, profile) => {
    if (profile?.data?.data?.context?.campaign_id === 0 || products.data === null) return [];

    if (products.filteredBy === 'all') return products.data;

    return products.data.filter(
        (product) => product.categories.filter((item) => item.slug === products.filteredBy).length > 0,
    );
});
export const cachedFilteredProducts = createSelector([products], (state) => {
    return state.filteredData;
});
export const productCategories = createSelector([products], (products) => products.categories);
export const filteredSavedDesignsCampaign = createSelector(
    [savedDesignsCampaign, products],
    (savedDesignsCampaign, products) => {
        if (savedDesignsCampaign.data === null) return [];
        if (products.filteredBy === 'all') return savedDesignsCampaign.data;

        return savedDesignsCampaign.data
            .filter((design) => design.categories.filter((item) => item.slug === products.filteredBy).length > 0)
            .map((item) => {
                item.type = 'custom';
                return item;
            });
    },
);

export const productsLoaded = createSelector([products], (products) => {
    return products.data !== null;
});
export const getProducts = createSelector([products], (products) => {
    products.loaded = products.data !== null;
    return products;
});
export const getActiveStandardCampaignsList = createSelector([campaigns], (campaigns) => {
    if (campaigns.list.data === null) return [];
    return campaigns.list.data.filter((camp) => camp.active && camp['company_campaign_type_id'] === 4);
});
export const getCoupon = createSelector([coupons], (coupons) => coupons);
export const getShippingMethods = createSelector([shippingRates], (methods) => methods);

export const getCampaignShippingAddresses = createSelector([campaignShippingAddresses], (addresses) => addresses);

export const getCampaignShippingAddressesData = createSelector(
    [campaignShippingAddresses],
    (addresses) => addresses.data,
);
export const getGeocode = createSelector([geocodeStore], (geocode) => geocode);

export const getSavedDesigns = createSelector([savedDesigns], (savedDesigns) => savedDesigns);

export const getSavedDesignsCampaign = createSelector([savedDesignsCampaign], (savedDesignsCampaign) => {
    savedDesignsCampaign.loaded = savedDesignsCampaign.data !== null;
    return savedDesignsCampaign;
});
export const getProfile = createSelector([profile], (profile) => profile);

export const getProfileData = createSelector([profile], (profile) => profile.data);

export const getAbilities = createSelector([profile], (profile) =>
    profile.data !== null ? profile.data.abilities : [],
);

export const getRoles = createSelector([profile], (profile) => (profile.data !== null ? profile.data.roles : []));

export const getInvite = createSelector([invite], (invite) => invite);

export const getCampaignCreditCards = createSelector([campaignCreditCards], (cards) => cards);
export const getCreditCards = createSelector([creditCards], (cards) => cards);
export const getNewCreditCard = createSelector([creditCards], (cards) => cards.newCard);

export const getCampaigns = createSelector([campaigns, regions], (campaigns, regions) => {
    return {
        ...campaigns,
        data: campaigns?.data
            ? {
                  ...campaigns.data,
                  data: campaigns?.data?.data
                      ? [
                            ...campaigns.data.data.map((datum) => {
                                if (datum?.regions?.length === 0) {
                                    return {...datum, regions: regions?.data ?? []};
                                }

                                return datum;
                            }),
                        ]
                      : null,
              }
            : null,
    };
});

export const getCampaignTypes = createSelector([campaignTypes], (campaignTypes) => campaignTypes);

export const getCampaignsList = createSelector([campaigns], (campaigns) => campaigns.list);
export const getGroups = createSelector([groups], (groups) => groups);
export const getGroupsSmartCampaign = createSelector([groups], (groups) => groups.smartCampaign);
export const getGroupsList = createSelector([groupsList], (groupsList) => groupsList);
export const getAccountCredit = createSelector([profile], (profile) =>
    profile.data !== null ? profile.data.credit_available : false,
);

export const getCompany = createSelector([companies], (companies) => companies);
export const getCompanyData = createSelector([companies], (companies) => companies?.data?.data ?? null);
export const getCurrentCampaignId = createSelector(
    [profile],
    (profile) => profile?.data?.data?.context?.campaign_id || 0,
);

export const getCurrentCampaign = createSelector([campaigns, profile, regions], (campaigns, profile, regions) => {
    if (campaigns.list.data === null) return null;

    return campaigns.list.data
        .map((c) => {
            c.regions = c.regions?.length > 0 ? c.regions : regions.data;
            c.sending_type = c.sending_type === 'both' ? null : c.sending_type;

            return c;
        })
        .find((f) => Number(f.id) === Number(profile?.data?.data?.context?.campaign_id));
});
export const getCampaignProducts = createSelector([campaign], (campaign) => {
    return campaign.products;
});

export const getCurrentTeam = createSelector([profile], (profile) => profile?.data?.data?.context?.team_id || 0);

export const getTeamSwitcherVisible = createSelector([team], (team) => team.switcherVisible);
export const getTeamInfo = createSelector([team], (team) => team.info);
export const getTeamInfoData = createSelector([team], (team) => team.info.data);
export const getTeamData = createSelector([team], (team) => (team.info && team.info.data ? team.info.data : null));

export const getTeamMembers = createSelector([team], (team) => team.members);

export const getAllTeamMembers = createSelector([team], (team) => team?.allMembers?.data ?? []);
export const getActiveTeamMembers = createSelector([team], (team) => ({
    ...team.members,
    data: (team.members?.data?.data ?? []).filter((member) => member.status === 'active'),
}));
export const getTeamMembersData = createSelector([team], (team) => team.members.data);
export const getTeams = createSelector([teams], (teams) => teams);

export const getTeamsData = createSelector([teams], (teams) => teams.data);

export const getNotifications = createSelector([notifications], (notifications) => notifications);

export const getRecipients = createSelector([recipients], (recipients) => recipients);

export const getRecipient = createSelector([recipient], (recipient) => recipient);

// @depreciated
export const storesLoaded = createSelector(
    [app],
    () => true, //app.defaultStoresLoaded,
);

export const getUpload = createSelector([upload], (upload) => upload);

export const getUploadBulk = createSelector([upload], (upload) => upload.bulk);
export const getUploadMembers = createSelector([upload], (upload) => upload.members);
