import {all, put, takeEvery} from 'redux-saga/effects';
import appActions from 'redux/app/actions';
import profileActions from 'redux/profile/actions';
import teamActions from 'redux/team/actions';
import companyActions from 'redux/company/actions';
import contactPropertiesActions from 'redux/contactProperties/actions';
import teamsActions from 'redux/teams/actions';
import authenticationActions from 'redux/authentication/actions';
import groupsActions from 'redux/groups/actions';
import {clearLocalStore} from 'library/helpers/localStore';
import campaignsActions from 'redux/campaigns/actions';
import regionsActions from 'redux/regions/actions';
import businessHoursActions from 'redux/businessHours/actions';
import notificationsActions from 'redux/notifications/actions';
import primaryProductsActions from '../primaryProducts/actions';
import {loadInstalledIntegrationsStart} from 'redux/integrations/actions';

function* loadDefaultStores() {
    yield put(contactPropertiesActions.fetchContactPropertiesStart());
    yield put(profileActions.fetchProfileDataStart());
    yield put(teamActions.fetchTeamDataStart());
    yield put(campaignsActions.listCampaignsStart());
    yield put(companyActions.fetchCompanyStart());
    yield put(primaryProductsActions.fetchCompanyPrimaryProductsDataStart());
    yield put(primaryProductsActions.fetchAllPrimaryProductsDataStart());
    yield put(notificationsActions.fetchNotificationsMetadataStart());
    yield put(regionsActions.fetchRegionsStart());
    yield put(regionsActions.fetchAllRegionsStart());
    yield put(businessHoursActions.fetchBusinessHoursStart());
    yield put(groupsActions.listGroupsStart());
    yield put(teamsActions.fetchTeamsDataStart());
    yield put(teamActions.fetchMembersDataStart());
    yield put(teamActions.fetchAllMembersDataStart());
    yield put(loadInstalledIntegrationsStart());
}

function* initializeApp() {
    yield loadDefaultStores();

    yield put(appActions.initializeComplete());
}

function uninitializeApp() {
    clearLocalStore();
}

export default function* saga() {
    yield all([
        takeEvery(appActions.INITIALIZE, initializeApp),
        takeEvery(appActions.UNINITIALIZE, uninitializeApp),
        takeEvery(authenticationActions.AUTHENTICATION_FAILURE, uninitializeApp),
    ]);
}
