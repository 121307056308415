import 'styles/output/styles.css';
import './scripts/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import {getProcessEnvValue} from 'library/helpers/process';
import App from './providers/App';
import {SYSTEM_ENVIRONMENT} from 'config/constants';

const rootEl = document.getElementById('root');

ReactDOM.render(<App />, rootEl);

if (
    getProcessEnvValue('REACT_APP_ENV') === SYSTEM_ENVIRONMENT.DEVELOPMENT &&
    getProcessEnvValue('REACT_APP_OVERRIDE')
) {
    console.warn(
        `\n!----- WARNING -----!\n\nBackend is currently overridden to:\n${getProcessEnvValue(
            'REACT_APP_API_URL',
        )}\n\n!------ WARNING -----!`,
    );
}

if (getProcessEnvValue('REACT_APP_HOT_RELOAD') && module.hot) {
    module.hot.accept();
}
