import {createSelector} from 'reselect';

const profile = (state) => state.profile;

export const getProfile = createSelector([profile], (profile) => profile);
export const getProfileUpdating = createSelector([profile], (profile) => profile.updating);
export const getAbilities = createSelector([profile], (profile) => {
    if (!profile.data) return false;
    return profile.data.abilities;
});
export const getProfileUpdatingAndError = createSelector([profile], (profile) => {
    return {
        updating: profile.updating,
        error: profile.error,
    };
});

export const getProfileError = createSelector([profile], (profile) => profile.error);
export const passwordResetModalVisibility = createSelector([profile], (profile) => profile.showResetModal);
export const getProfileRegion = createSelector([profile], (profile) => (profile.data && profile.data.region) ?? null);
