import {all, takeEvery, takeLeading, put} from 'redux-saga/effects';
import {apiEndpoints} from 'config/api';
import {api} from 'library/helpers/api';
import {buildQuery, getRoute} from 'library/helpers/routing';
import bulkOrderActions from './actions';
import {BULK_ORDER, DIGITAL_ORDER} from 'config/orders';

const getErrorMessage = (error) => (error.response ? error.response.data.message : error.message);

function* fetchBulkOrderStart({payload}) {
    const {id} = payload;

    try {
        const route = getRoute(apiEndpoints.digitalOrders.getBulkList, {type: BULK_ORDER, id});

        const data = yield api.get(route).then((response) => response.data);

        return yield put(bulkOrderActions.fetchBulkOrderSuccess(data));
    } catch (error) {
        return yield put(bulkOrderActions.fetchBulkOrderFailure(yield getErrorMessage(error)));
    }
}

function* fetchBulkOrderRecipientsStart({payload = {}}) {
    const {id} = payload;

    const route = getRoute(apiEndpoints.digitalOrders.getRecipients, {id, type: BULK_ORDER});

    try {
        const data = yield api.get(`${route}?${buildQuery(payload)}`).then((response) => response.data);

        data.data.forEach((recipient) => {
            const first_name = recipient.first_name ? (recipient.first_name ?? '') + ' ' : '';
            const last_name = recipient.last_name ? recipient.last_name ?? '' : '';

            recipient.name = first_name + last_name;
        });

        return yield put(bulkOrderActions.fetchBulkOrderRecipientsSuccess(data));
    } catch (error) {
        return yield put(
            bulkOrderActions.fetchBulkOrderRecipientsFailure(
                yield api.get(`${route}?${buildQuery(payload)}`).then((response) => response.data),
            ),
        );
    }
}

function* cancelBulkOrderStart({payload}) {
    try {
        const {shouldRefresh = true} = payload;

        const route = getRoute(apiEndpoints.digitalOrders.cancelBulkList, {
            id: payload.id,
        });

        yield api.delete(route);

        if (shouldRefresh) {
            yield put(bulkOrderActions.fetchBulkOrderStart({id: payload.id, type: BULK_ORDER}));
            yield put(bulkOrderActions.fetchBulkOrderRecipientsStart({id: payload.id}));
        }

        return yield put(bulkOrderActions.cancelBulkOrderSuccess('Order successfully cancelled'));
    } catch (error) {
        return yield put(bulkOrderActions.cancelBulkOrderFailure(getErrorMessage(error)));
    }
}

function* cancelBulkOrderIndividualStart({payload}) {
    try {
        const {id, ids} = payload;

        yield api.delete(apiEndpoints.digitalOrders.cancel, {data: {ids}});

        yield put(bulkOrderActions.fetchBulkOrderRecipientsStart({id, type: DIGITAL_ORDER}));
        yield put(bulkOrderActions.fetchBulkOrderStart({id}));

        yield put(bulkOrderActions.cancelBulkOrderIndividualSuccess('Order successfully cancelled'));
    } catch (error) {
        yield put(
            bulkOrderActions.cancelBulkOrderIndividualFailure(
                (error ? error.data.message : error.message) ?? 'Order could not be cancelled.',
            ),
        );
    }
}

function* reorderBulkOrderIndividualStart({payload}) {
    try {
        const {email, id, ids, update} = payload;

        const data = {
            email: email,
            ids: ids,
            update_contact_email: update,
        };

        const response = yield api.post(apiEndpoints.digitalOrders.reorder, data);

        yield put(bulkOrderActions.reorderBulkOrderIndividualSuccess(response.data));

        if (response.data.queued === true) return;
        if (Object.values(response.data?.failed)?.length === ids.length) return;

        yield put(bulkOrderActions.fetchBulkOrderStart({id}));
        yield put(bulkOrderActions.fetchBulkOrderRecipientsStart({id}));
    } catch (error) {
        yield put(bulkOrderActions.reorderBulkOrderIndividualFailure(error));
    }
}

export default function* bulkOrderSaga() {
    yield all([
        takeEvery(bulkOrderActions.FETCH_BULK_ORDER_START, fetchBulkOrderStart),
        takeLeading(bulkOrderActions.FETCH_BULK_ORDER_RECIPIENTS_START, fetchBulkOrderRecipientsStart),
        takeEvery(bulkOrderActions.CANCEL_BULK_ORDER_START, cancelBulkOrderStart),
        takeEvery(bulkOrderActions.CANCEL_BULK_ORDER_INDIVIDUAL_START, cancelBulkOrderIndividualStart),
        takeEvery(bulkOrderActions.REORDER_BULK_ORDER_INDIVIDUAL_START, reorderBulkOrderIndividualStart),
    ]);
}
