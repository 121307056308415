const notificationsActions = {
    FETCH_NOTIFICATIONS_DATA_START: 'FETCH_NOTIFICATIONS_DATA_START',
    FETCH_NOTIFICATIONS_DATA_SUCCESS: 'FETCH_NOTIFICATIONS_DATA_SUCCESS',
    FETCH_NOTIFICATIONS_DATA_ERROR: 'FETCH_NOTIFICATIONS_DATA_ERROR',

    fetchNotificationsStart: (payload = {page: 1}) => ({
        type: notificationsActions.FETCH_NOTIFICATIONS_DATA_START,
        payload,
    }),

    fetchNotificationsSuccess: (notifications) => ({
        type: notificationsActions.FETCH_NOTIFICATIONS_DATA_SUCCESS,
        payload: notifications,
    }),

    fetchNotificationsError: (error) => ({
        type: notificationsActions.FETCH_NOTIFICATIONS_DATA_ERROR,
        payload: error,
    }),

    FETCH_NOTIFICATIONS_METADATA_START: 'FETCH_NOTIFICATIONS_METADATA_START',
    FETCH_NOTIFICATIONS_METADATA_SUCCESS: 'FETCH_NOTIFICATIONS_METADATA_SUCCESS',
    FETCH_NOTIFICATIONS_METADATA_ERROR: 'FETCH_NOTIFICATIONS_METADATA_ERROR',
    fetchNotificationsMetadataStart: () => ({
        type: notificationsActions.FETCH_NOTIFICATIONS_METADATA_START,
    }),

    fetchNotificationsMetadataSuccess: () => ({
        type: notificationsActions.FETCH_NOTIFICATIONS_METADATA_SUCCESS,
    }),

    fetchNotificationsMetadataError: (error) => ({
        type: notificationsActions.FETCH_NOTIFICATIONS_METADATA_ERROR,
        payload: error,
    }),

    FETCH_NOTIFICATIONS_TYPES_SUCCESS: 'FETCH_NOTIFICATIONS_TYPES_SUCCESS',

    fetchNotificationsTypesSuccess: (notificationsTypes) => ({
        type: notificationsActions.FETCH_NOTIFICATIONS_TYPES_SUCCESS,
        payload: notificationsTypes,
    }),

    FETCH_NOTIFICATIONS_COUNT_SUCCESS: 'FETCH_NOTIFICATIONS_COUNT_SUCCESS',

    fetchNotificationsCountSuccess: (notificationsCount) => ({
        type: notificationsActions.FETCH_NOTIFICATIONS_COUNT_SUCCESS,
        payload: notificationsCount,
    }),

    UPDATE_NOTIFICATIONS_SUBSCRIPTION_DATA: 'UPDATE_NOTIFICATIONS_SUBSCRIPTION_DATA',
    UPDATE_NOTIFICATIONS_SUBSCRIPTIONS_DATA: 'UPDATE_NOTIFICATIONS_SUBSCRIPTIONS_DATA',

    updateNotificationsSubscriptionData: (value, groupId) => ({
        type: notificationsActions.UPDATE_NOTIFICATIONS_SUBSCRIPTION_DATA,
        payload: {value, groupId},
    }),

    updateNotificationsSubscriptionsData: (value) => ({
        type: notificationsActions.UPDATE_NOTIFICATIONS_SUBSCRIPTIONS_DATA,
        payload: {value},
    }),

    MARK_NOTIFICATIONS_START: 'MARK_NOTIFICATIONS_START',
    MARK_NOTIFICATIONS_SUCCESS: 'MARK_NOTIFICATIONS_SUCCESS',
    MARK_NOTIFICATIONS_ERROR: 'MARK_NOTIFICATIONS_ERROR',

    markNotificationsStart: (payload = {}) => ({
        type: notificationsActions.MARK_NOTIFICATIONS_START,
        payload,
    }),

    markNotificationsSuccess: (payload = {}) => ({
        type: notificationsActions.MARK_NOTIFICATIONS_SUCCESS,
        payload,
    }),

    markNotificationsError: (payload = {}) => ({
        type: notificationsActions.MARK_NOTIFICATIONS_ERROR,
        payload,
    }),

    UPDATE_NOTIFICATIONS_COUNT: 'UPDATE_NOTIFICATIONS_COUNT',
    updateNotificationsCount: (payload) => ({
        type: notificationsActions.UPDATE_NOTIFICATIONS_COUNT,
        payload,
    }),
};

export default notificationsActions;
