import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {lang} from 'config/lang';
import {ROLES} from 'config/roles';
import {currencyFormatter} from 'library/helpers/format';
import {usePrev} from 'hooks/utility/usePrev';
import {getAccountDescendants, getAccountDescendantsLoading} from 'redux/descendants/selectors';
import fundingActions from 'redux/funding/actions';
import {getCurrentTeam, getProfileData} from 'redux/selectors';
import {getFunding, getFundingSubmitted} from 'redux/funding/selectors';
import profileActions from 'redux/profile/actions';
import descendantsActions from 'redux/descendants/actions';
import {PageTransition} from 'components/Layout';

import {
    Alert,
    Button,
    CurrencyValue,
    FontAwesome,
    Form,
    InputNumber,
    message,
    Select,
    Space,
    Typography,
} from 'components/UI';
import {Col, Row} from 'antd';
import useSelectList from 'containers/TransferFunds/hooks/useSelectList';
import {scalarToArray} from 'library/helpers/utilities';
import {fundingApiService} from 'library/helpers/api/services/funding';
import debounce from 'lodash/debounce';
import isString from 'lodash/isString';

const {Paragraph} = Typography;
export const DISPERSAL_METHODS = Object.freeze({
    ROUND_UP: {
        key: 'round_up',
        label: 'Round Up',
    },
    FULL_AMOUNT: {
        key: 'full_amount',
        label: 'Full Amount',
    },
});

const handleAmountFormatting = (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
const handleAmountParsing = (value) => value.replace(/\$\s?|(,*)/g, '');

/**
 *
 * @return {*|JSX.Element}
 * @constructor
 */
const TransferFundsForm = ({defaultTo, tab, currentTab}) => {
    const [responses, setResponses] = useState({});

    const dispatch = useDispatch();
    const descendantAccounts = useSelector(getAccountDescendants);
    const descendantMembersLoading = useSelector(getAccountDescendantsLoading);
    const fundingSubmitted = useSelector(getFundingSubmitted);
    const funding = useSelector(getFunding);
    const team = useSelector(getCurrentTeam);
    const profile = useSelector(getProfileData);

    const [form] = Form.useForm();

    const {getToSelectList, getFromSelectList} = useSelectList();

    const [checkingRoundUp, setCheckingRoundUp] = useState(false);

    const [fromList, setFromList] = useState([]);
    const [toList, setToList] = useState([]);

    const dataLoaded = useMemo(
        () => profile && !profile.loading && profile?.id && !descendantMembersLoading && descendantAccounts.length > 0,
        [descendantAccounts.length, descendantMembersLoading, profile],
    );

    const setFrom = useCallback(() => {
        if (dataLoaded) {
            setFromList(getFromSelectList(profile, descendantAccounts, form));
        }
    }, [dataLoaded, descendantAccounts, form, getFromSelectList, profile]);
    const setTo = useCallback(() => {
        if (dataLoaded) {
            setToList(getToSelectList(profile, descendantAccounts, form.getFieldValue('from')));
        }
    }, [dataLoaded, descendantAccounts, form, getToSelectList, profile]);

    // runs once on mount. loads profile and descendantMembers store if they are not already loaded
    useEffect(() => {
        let ignore = false;

        if (!ignore) {
            if (!profile) dispatch(profileActions.fetchProfileDataStart({forceFetch: true}));
            dispatch(descendantsActions.fetchAccountDescendantsStart());
        }

        return () => {
            ignore = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reset = useCallback(() => {
        form.resetFields();
    }, [form]);

    // this is to prevent the selects from being loaded multiple times
    const loadSelects = useCallback(() => {
        setFrom();
        setTo();
    }, [setFrom, setTo]);

    const prevDescendantMembersLoading = usePrev(descendantMembersLoading);
    useEffect(() => {
        if (prevDescendantMembersLoading !== null && prevDescendantMembersLoading && !descendantMembersLoading) {
            loadSelects();
        }
    }, [descendantMembersLoading, form, loadSelects, prevDescendantMembersLoading]);

    // initialized the selects
    useEffect(() => {
        if (form && (!fromList.length || !toList.length)) {
            if (!fromList.length) {
                setFrom();
            }
            if (!toList.length) {
                setTo();
            }
        }
    }, [form, fromList.length, setFrom, setTo, toList.length]);

    const getCanTransferFrom = useCallback(
        (id) => {
            return (
                descendantAccounts &&
                profile &&
                descendantAccounts
                    .filter((member) => member.id === id)
                    .map((member) => profile.roles.includes(ROLES.SUPER_ADMIN) || !member.is_superadmin)
            );
        },
        [descendantAccounts, profile],
    );

    const getMaxTransfer = useCallback(
        (id) =>
            descendantAccounts
                .filter((member) => member.id === id)
                .map((member) => {
                    return member.credit_available;
                }),
        [descendantAccounts],
    );

    const transferValidation = useMemo(
        () => [
            {required: true, message: 'Please enter the amount to transfer'},
            ({getFieldValue}) => ({
                validator: (_, value) =>
                    value <= getMaxTransfer(getFieldValue('from'))
                        ? Promise.resolve()
                        : Promise.reject(
                              new Error(
                                  'Amount cannot exceed ' + currencyFormatter(getMaxTransfer(getFieldValue('from'))),
                              ),
                          ),
            }),
        ],
        [getMaxTransfer],
    );

    const handleSubmit = useCallback(() => {
        dispatch(fundingActions.fundsResetError());

        form.validateFields().then((values) => {
            dispatch(
                fundingActions.submitTransferFundsStart({
                    team,
                    ...values,
                }),
            );
        });
    }, [dispatch, form, team]);

    const handleChangeFrom = useCallback(() => {
        form.setFieldsValue({amount: 0});
        loadSelects();
    }, [form, loadSelects]);

    // onSubmitted successfully
    const prevFundingSubmitted = usePrev(fundingSubmitted);
    useEffect(() => {
        // if we've submitted, and we have no error codes then we can assume it was successful
        if (!funding?.error && prevFundingSubmitted !== null && !prevFundingSubmitted && fundingSubmitted) {
            message.success(`${lang['funds_transferred_success']}`).then();
            reset();
        }
    }, [prevFundingSubmitted, fundingSubmitted, funding, reset]);

    const initialValues = useMemo(
        () => ({
            from: profile?.credit_available > 0 ? profile?.id : null,
            to: [],
            amount: '',
            dispersal_method: DISPERSAL_METHODS.FULL_AMOUNT.key,
            original_amount: '',
        }),
        [profile?.credit_available, profile?.id],
    );

    useEffect(() => {
        if (!defaultTo) return;
        if (!toList?.length) return;

        const to = toList.filter((item) => item.value === Number(defaultTo)).map((item) => item.value);

        form.setFieldsValue({to});
    }, [defaultTo, form, toList]);

    // if this is not the first tab we should reset everything
    const prevCurrentTab = usePrev(currentTab);
    useEffect(() => {
        if (prevCurrentTab && prevCurrentTab !== currentTab && currentTab !== tab) {
            reset();
        }
    }, [currentTab, prevCurrentTab, reset, tab]);

    const [buttonDisabled, setButtonDisabled] = useState(true);

    const [hasTransferErrors, transferErrors] = useMemo(() => {
        return [funding.error?.length > 0, funding.error];
    }, [funding.error]);

    const handleCheckAmount = useCallback(
        async (accountIds) => {
            const amount = form.getFieldValue('amount');
            if (amount > 0 && form.getFieldValue('dispersal_method') === DISPERSAL_METHODS.ROUND_UP.key) {
                setCheckingRoundUp(true);
                // send amount to back end and get the transfer amount
                return await fundingApiService
                    .getRoundUpTransferAmount({
                        accountIds,
                        amount,
                    })
                    .finally(() => setCheckingRoundUp(false));
            }

            return Promise.resolve(false);
        },
        [form],
    );

    const [invalidTransfers, setInvalidTransfers] = useState(false);

    const handleValuesChanged = useCallback(
        (v) => {
            setResponses({});
            const to = form.getFieldValue('to');
            const dispersalMethod = form.getFieldValue('dispersal_method');
            if ([dispersalMethod, v].includes(DISPERSAL_METHODS.ROUND_UP.key) && to?.length > 0) {
                handleCheckAmount(to).then((res) => {
                    if (!res.invalid || !res.valid) {
                        return;
                    }

                    setInvalidTransfers(res.total === 0);
                    setResponses(res);
                });
            }

            const amount = form.getFieldValue('amount');
            if ([dispersalMethod, v].includes(DISPERSAL_METHODS.FULL_AMOUNT.key) && to?.length > 0 && amount > 0) {
                const totalTo = to.length;
                setResponses({
                    valid: `A total of _total_ will be distributed to ${totalTo} users`,
                    total: amount * to.length,
                });
            }
        },
        [form, handleCheckAmount],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const valuesChangedDebounced = useCallback(
        debounce((value) => handleValuesChanged(value), 800, {leading: false, trailing: true}),
        [handleValuesChanged],
    );

    const handleAmountChange = useCallback(
        async (value) => {
            form.setFieldValue('amount', value);
            valuesChangedDebounced(value);
        },
        [form, valuesChangedDebounced],
    );

    const handleFieldsChanged = useCallback((f, fields) => {
        setResponses({});
        // todo: more validation can be done here if we think its necessary
        setButtonDisabled(fields.filter((f) => scalarToArray(f.value).length === 0).length > 0);
    }, []);

    // ensures the default properties are loaded on the form
    const formProps = useMemo(() => {
        return dataLoaded
            ? {
                  name: 'transfer-funds-form',
                  onFinish: handleSubmit,
                  layout: 'vertical',
                  form,
                  initialValues: initialValues,
                  onFieldsChange: handleFieldsChanged,
                  onValuesChange: valuesChangedDebounced,
              }
            : {};
    }, [dataLoaded, form, handleFieldsChanged, handleSubmit, valuesChangedDebounced, initialValues]);

    return (
        <Row>
            <Col xxl={10} xl={14} lg={18} md={18} sm={24} xs={24}>
                {!dataLoaded ? (
                    <PageTransition />
                ) : (
                    <>
                        {fromList.length &&
                            form.getFieldValue('from') &&
                            !getCanTransferFrom(form.getFieldValue('from'))?.[0] && (
                                <Alert type="error" message={lang['funds_transferred_permissions_error']} />
                            )}

                        <Paragraph>{lang.funding.transfer.explain}</Paragraph>

                        {hasTransferErrors && (
                            <Space direction="vertical" size={36}>
                                <Alert
                                    message={'There were issues with your transfer.'}
                                    description={
                                        <ul>
                                            {isString(transferErrors)
                                                ? transferErrors
                                                : transferErrors?.map?.((item, key) => <li key={key}>{item}</li>)}
                                        </ul>
                                    }
                                    type="error"
                                />
                            </Space>
                        )}

                        <Form {...formProps}>
                            <fieldset>
                                <Form.Item hidden name="original_amount">
                                    <input type="hidden" />
                                </Form.Item>
                                <Form.Item hidden name="dispersal_method">
                                    <input type="hidden" />
                                </Form.Item>
                                <Form.Item
                                    name="from"
                                    label={lang.funding.transfer.from}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a user to transfer funds from',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder={lang.funding.transfer.from_placeholder}
                                        options={fromList}
                                        onChange={handleChangeFrom}
                                        notFoundContent={lang.no_results}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="to"
                                    label={lang.funding.transfer.to_label}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select at least one user to transfer funds to',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder={lang.funding.transfer.to_placeholder}
                                        options={toList}
                                        allowClear
                                        showSearch
                                        mode="multiple"
                                        notFoundContent={lang.no_results}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="amount"
                                    label={lang.funding.transfer.amount}
                                    rules={transferValidation}
                                    extra={
                                        <div>
                                            {checkingRoundUp && (
                                                <div style={{marginLeft: '10px'}}>
                                                    <FontAwesome color="gray" waiting size="md" />
                                                </div>
                                            )}
                                            {responses?.valid?.length > 0 && (
                                                <div>
                                                    {responses.valid.split('_total_')[0]}
                                                    <CurrencyValue
                                                        amount={responses.total}
                                                        region={profile?.region}
                                                        exchange_rate
                                                    />
                                                    {responses.valid.split('_total_')[1]}
                                                </div>
                                            )}
                                            {responses?.invalid?.length > 0 && <div>{responses.invalid}</div>}
                                        </div>
                                    }
                                >
                                    <InputNumber
                                        wrapperCol={24}
                                        min={0.01}
                                        size="large"
                                        formatter={handleAmountFormatting}
                                        parser={handleAmountParsing}
                                        xchange={{
                                            display: true,
                                            inline: false,
                                            amount: (
                                                <CurrencyValue
                                                    amount={form.getFieldValue('amount') || 0}
                                                    region={profile?.region}
                                                    exchange_rate
                                                />
                                            ),
                                            marginTop: '0.5rem',
                                        }}
                                        onChange={handleAmountChange}
                                        autoFocus
                                        addonBefore={
                                            <Select
                                                size={'large'}
                                                defaultValue={DISPERSAL_METHODS.FULL_AMOUNT.label}
                                                options={Object.values(DISPERSAL_METHODS)
                                                    .map((dm) => ({
                                                        value: dm.key,
                                                        label: dm.label,
                                                    }))
                                                    .reverse()}
                                                onSelect={(value) => {
                                                    form.setFieldValue('dispersal_method', value);
                                                    valuesChangedDebounced(value);
                                                }}
                                            />
                                        }
                                    />
                                </Form.Item>
                            </fieldset>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    disabled={buttonDisabled || invalidTransfers}
                                    loading={funding.transferring}
                                >
                                    {lang.funding.transfer.label}
                                </Button>
                            </Form.Item>
                        </Form>
                    </>
                )}
            </Col>
        </Row>
    );
};

TransferFundsForm.propTypes = {
    defaultTo: PropTypes.number,
    tab: PropTypes.any,
    currentTab: PropTypes.any,
};

export default TransferFundsForm;
