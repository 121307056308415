import {all, put, takeEvery} from 'redux-saga/effects';
import {lang} from 'config/lang';
import {
    AUTH_LOAD_FROM_STORAGE,
    AUTH_SET_VERIFY,
    AUTHENTICATE,
    AUTHENTICATE_FEDERATED,
    AUTHENTICATE_FEDERATED_LEGACY,
    CHECK_USER,
    CONFIRM_REGISTER,
    CONFIRM_REGISTER_SUCCESS,
    FETCH_USER,
    LOGOUT,
    REGISTER_START,
    SOFT_AUTHENTICATE,
} from 'redux/auth/auth.types';
import {
    authenticateFailed,
    authenticateSuccess,
    confirmRegisterFailure,
    confirmRegisterSuccess,
    loadFromStorageComplete,
    registerFailed,
    registerSuccess,
    setUser,
    setVerify,
} from 'redux/auth/auth.actions';
import {Auth} from 'aws-amplify';

import {getLocalCache, removeLocalCache} from 'library/helpers/localStore';
import {apiEndpoints} from 'config/api';
import {api} from 'library/helpers/api';

import {laravelLogin} from 'redux/auth/services';
import {awsFederatedLogin, awsFederatedLoginLegacy, awsLogin, awsRegisterUser} from 'library/vendor/aws';
import appActions from 'redux/app/actions';
import {push} from 'react-router-redux';
import {isSandbox} from 'library/helpers/utilities';

const VERIFY_TOKEN_KEY = 'v-t';

function* authenticateFederated({payload}) {
    const creds = yield awsFederatedLogin(payload);
    console.log(creds);
}

function* authenticateLegacyFederated({payload}) {
    const creds = yield awsFederatedLoginLegacy(payload);
    console.log(creds);
}

function setSessionCookie({exp, path}) {
    document.cookie = `gg_sess=${+new Date()} ${exp || ''} ${path || ''}`;
}

function deleteSessionCookie() {
    setSessionCookie({exp: 'expires=Thu, 01 Jan 1970 00:00:00 UTC;'});
}

function createSessionCookie() {
    const d = new Date();
    const exp = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getMinutes() + 30));
    setSessionCookie({exp});
}

function logout() {
    deleteSessionCookie();
}

function* authenticate({payload}) {
    const user = yield awsLogin(payload);

    if (user.error) {
        deleteSessionCookie();
        return yield put(authenticateFailed({...user.error}));
    }

    if (!user.rawUser.attributes['email_verified']) {
        return yield put(
            authenticateFailed({
                code: 'UserNotVerified',
                email: user.rawUser.attributes.email,
            }),
        );
    }

    console.log({login: 'logging in'});

    createSessionCookie();

    return yield put(authenticateSuccess(user));
}

// TODO: is this used?
function* softAuthenticate({payload}) {
    yield retrieveCookie();
    const user = yield laravelLogin(payload);

    yield put(setUser(user));
    yield put(appActions.initialize());

    return user.data.success;
}

function* retrieveCookie() {
    return yield api
        .get(apiEndpoints.auth.csrf)
        .then((res) => res)
        .catch((e) => console.log(e));
}

function* loadFromStorage() {
    // grab the user data from cache
    let user = yield getLocalCache(VERIFY_TOKEN_KEY);

    // remove it here
    yield removeLocalCache(VERIFY_TOKEN_KEY);
    if (user) {
        user = JSON.parse(user);
    }

    yield put(setVerify(user));
    yield put(loadFromStorageComplete());
}

function* fetchUser() {
    const user = yield Auth.currentUserInfo().then((user) => user);
    yield put(authenticateSuccess(user));
}

function* register({payload}) {
    const signupStatus = yield awsRegisterUser(payload);
    if (signupStatus.error) {
        if (signupStatus?.error?.message?.includes('466')) {
            signupStatus.error.message = isSandbox()
                ? 'Sandbox accounts require pre-approval, please contact your account manager to setup your account'
                : 'Please enter a valid business email (Gmail, Yahoo, etc. are not allowed)';
        }

        if (signupStatus.error.code === 'ForbiddenException') {
            signupStatus.error.message = lang.signup.waf;
        }

        if (!signupStatus.error.message) {
            signupStatus.error.message = 'Something went wrong.';
        }

        // inform the store shit hit the fan
        yield put(registerFailed({...signupStatus.error}));

        return false;
    }

    yield put(registerSuccess(signupStatus.codeDeliveryDetails));
}

function* confirmRegistration(payload) {
    try {
        const {userName, code, redirect} = payload;
        const confirmation = yield Auth.confirmSignUp(userName, code);

        if (confirmation === 'SUCCESS') {
            localStorage.removeItem(VERIFY_TOKEN_KEY);
            return yield put(confirmRegisterSuccess({redirect, username: userName}));
        }
    } catch (e) {
        yield put(confirmRegisterFailure(e));
    }
}

function* confirmRegistrationSuccess(payload) {
    yield put(push(payload.redirect));
}

function storeConfirm(payload) {
    localStorage.setItem(VERIFY_TOKEN_KEY, JSON.stringify(payload));
}

function* checkUser() {
    const session = yield Auth.currentAuthenticatedUser();

    if (session !== 'not authenticated') yield fetchUser();
}

export default function* authSaga() {
    yield all([
        takeEvery(AUTHENTICATE, authenticate),
        takeEvery(SOFT_AUTHENTICATE, softAuthenticate),
        takeEvery(AUTHENTICATE_FEDERATED, authenticateFederated),
        takeEvery(LOGOUT, logout),
        takeEvery(AUTHENTICATE_FEDERATED_LEGACY, authenticateLegacyFederated),
        takeEvery(FETCH_USER, fetchUser),
        takeEvery(REGISTER_START, register),
        takeEvery(CONFIRM_REGISTER, confirmRegistration),
        takeEvery(CONFIRM_REGISTER_SUCCESS, confirmRegistrationSuccess),
        takeEvery(CHECK_USER, checkUser),
        takeEvery(AUTH_SET_VERIFY, storeConfirm),
        takeEvery(AUTH_LOAD_FROM_STORAGE, loadFromStorage),
    ]);
}
