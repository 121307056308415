import {apiEndpoints} from 'config/api';
import isEqual from 'react-fast-compare';
import Pusher from 'pusher-js/worker';
import {getProcessEnvValue} from 'library/helpers/process';

const pusherConfig = {
    key: btoa(getProcessEnvValue('REACT_APP_PUSHER_APP_KEY')),
    cluster: btoa(getProcessEnvValue('REACT_APP_PUSHER_APP_CLUSTER')),
    api_url: `${getProcessEnvValue('REACT_APP_API_URL')}${apiEndpoints.broadcasting.authorization.channel}`,
};

let channels = [];
let previousChannels = [];
let subscribedChannels = [];

export let pusher;

/**
 * @description Authorizer for pusher configuration.
 * @param channel {object}
 * @param id
 * @param channel.name {string}
 * @returns {{authorize}}
 */
const authorizer = (channel, id) => {
    return {
        /**
         * @param {string} socketId
         * @param {Function} callback
         */
        authorize: (socketId, callback) => {
            const data = new URLSearchParams();

            data.append('socket_id', socketId);
            data.append('channel_name', channel.name);
            data.append('id', id);

            fetch(pusherConfig.api_url, {
                method: 'POST',
                credentials: 'include',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: data,
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`Received ${response.statusCode} from ${pusherConfig.api_url}`);
                    }

                    return response.json();
                })
                .then((data) => {
                    callback(null, data);
                })
                .catch((err) => {
                    callback(new Error(`Error calling auth endpoint: ${err}`), {
                        auth: '',
                    });
                });
        },
    };
};

/**
 * @param {Function} callback
 * @param {array<string>} eventList
 */
const bindChannelEvent = (callback, eventList) => {
    if (!pusher) {
        return;
    }

    eventList.forEach((channelEvent) => {
        callback(channelEvent);
    });
};

export const subscribe = (id, privateChannel, eventList, updateChannelsCallback) => {
    if (!pusher) {
        createPusher(id);
    }

    /**
     * @param {json} payload
     * @param {string} channelEvent
     */
    const channelReducer = (payload, channelEvent) => {
        previousChannels = channels;

        const newChannels = {
            ...previousChannels,
            [channelEvent]: payload,
        };

        if (isEqual(newChannels, previousChannels)) {
            return;
        }

        subscribedChannels.push(channelEvent);

        updateChannelsCallback('PUSHER_EVENT', newChannels);

        channels = newChannels;
    };

    /**
     * @param {string} channelEvent
     */
    const subscribeCallback = (channelEvent) => {
        const fullChannel = `${privateChannel}.${channelEvent}`;

        if (subscribedChannels.includes(fullChannel)) {
            return;
        }

        if (!subscribedChannels.length > 0) {
            try {
                pusher.subscribe(`${privateChannel}`);
            } catch (error) {
                throw new Error(`Pusher Debug: Subscribe connection failed. Error: ${error}.`);
            }
        }

        /**
         * @param {json} payload
         */
        const scopedReducer = (payload) => channelReducer(payload, channelEvent);

        pusher.bind(channelEvent, scopedReducer);
        subscribedChannels.push(fullChannel);
    };

    bindChannelEvent(subscribeCallback, eventList);
};

/**
 * @param {string} privateChannel
 * @param eventList
 */
export const unsubscribe = (privateChannel, eventList) => {
    /**
     * @param {string} channelEvent
     */
    function unsubscribeCallback(channelEvent) {
        const fullChannel = `${privateChannel}.${channelEvent}`;

        if (subscribedChannels.includes(fullChannel)) {
            return;
        }

        pusher.unbind(fullChannel);
        subscribedChannels.splice(subscribedChannels.indexOf(fullChannel), 1);

        if (!subscribedChannels.length > 0) {
            try {
                pusher.unsubscribe(privateChannel);
            } catch (error) {
                throw new Error(`Pusher Debug: Unsubscribe connection failed. Error: ${error}.`);
            }
        }
    }

    bindChannelEvent(unsubscribeCallback, eventList);
};

export const createPusher = (id) => {
    subscribedChannels = [];

    pusher = new Pusher(atob(pusherConfig.key), {
        cluster: atob(pusherConfig.cluster),
        authorizer: (channel) => authorizer(channel, id),
    });
};

/**
 * @description Fallback for missing shared worker script.
 */
export const sharedWorkerFallback = (id, eventList, updateChannelsCallback) => {
    if (!id) {
        throw new Error("Pusher Debug: User's id is 0. Cannot initialize pusher.");
    }

    subscribe(id, `private-notifications.${id}`, eventList, updateChannelsCallback);
};
