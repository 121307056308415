const campaignTypesActions = {
  FETCH_CAMPAIGN_TYPES_DATA_START: 'FETCH_CAMPAIGN_TYPES_DATA_START',
  FETCH_CAMPAIGN_TYPES_DATA_SUCCESS: 'FETCH_CAMPAIGN_TYPES_DATA_SUCCESS',
  FETCH_CAMPAIGN_TYPES_DATA_FAILURE: 'FETCH_CAMPAIGN_TYPES_DATA_FAILURE',
  fetchCampaignTypesStart: () => ({
    type: campaignTypesActions.FETCH_CAMPAIGN_TYPES_DATA_START,
  }),
  fetchCampaignTypesSuccess: (campaigns) => {
    return {
      type: campaignTypesActions.FETCH_CAMPAIGN_TYPES_DATA_SUCCESS,
      payload: campaigns,
    }
  },
  fetchCampaignTypesError: (error) => ({
    type: campaignTypesActions.FETCH_CAMPAIGN_TYPES_DATA_FAILURE,
    payload: error,
  }),
}

export default campaignTypesActions
