export const iconStyle = 'regular';
export const buttonIconStyle = 'regular';

export const siderWidth = 280;

export const grid = 8;

export const borderRadius = 4;

export const gutter = [
    {xs: 16, sm: 32, md: 32, lg: 32},
    {xs: 12, sm: 32, md: 32, lg: 32},
];

export const container = '1200px';

export const breakpoints = {
    xs: '(max-width: 575.98px)',
    sm: '(min-width: 576px) and (max-width: 767.98px)',
    md: '(min-width: 768px) and (max-width: 991.98px)',
    lg: '(min-width: 992px) and (max-width: 1199.98px)',
    xl: '(min-width: 1200px)',
    xxl: '(min-width: 1600px)',
};
