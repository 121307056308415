import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import {Row, Col} from 'antd';

const Address = ({address}) => {
    const renderPostalCode = useCallback(() => {
        const suffix = address.postal_code_suffix || address.zip_suffix || '';

        return `${address.postal_code || address.zip}${suffix ? `-${suffix}` : ''}`;
    }, [address]);

    if (!address) return null;

    return (
        <address>
            <Row>
                <Col span={24}>
                    {address.first_name} {address.last_name}
                </Col>
            </Row>

            <Row>
                <Col span={24}>{address.company}</Col>
            </Row>

            <Row>
                <Col span={24}>
                    {address.street_number} {address.route}
                </Col>
            </Row>

            <Row>
                <Col span={24}>{address.subpremise}</Col>
            </Row>

            <Row>
                <Col span={24}>
                    {address.locality || address.city}, {address.administrative_area_level_1 || address.state}{' '}
                    {renderPostalCode()} {address.country}
                </Col>
            </Row>
        </address>
    );
};

Address.propTypes = {
    address: PropTypes.object,
};

export default connect()(Address);
