import React from 'react';

const icon = () => (
    <svg id="Layer_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <circle cx="256" cy="256" fill="#d80027" r="256"/>
        <g fill="#f0f0f0">
            <path
                d="m282.43 193.668c-5.818 24.199-16.155 19.584-21.228 40.688-29.883-7.185-48.287-37.235-41.102-67.12 7.182-29.883 37.235-48.287 67.119-41.102-10.147 42.207.416 45.891-4.789 67.534z"/>
            <path
                d="m204.887 211.6c21.217 13.01 13.633 21.417 32.135 32.764-16.068 26.201-50.333 34.416-76.535 18.349-26.201-16.067-34.418-50.334-18.35-76.535 37.006 22.692 43.771 13.785 62.75 25.422z"/>
            <path
                d="m197.978 290.892c18.931-16.158 24.58-6.348 41.089-20.438 19.955 23.377 17.179 58.505-6.197 78.46-23.378 19.955-58.506 17.176-78.458-6.199 33.014-28.183 26.633-37.372 43.566-51.823z"/>
            <path
                d="m271.254 321.965c-9.52-22.997 1.556-25.341-6.743-45.394 28.399-11.753 60.951 1.741 72.705 30.14 11.753 28.399-1.743 60.95-30.142 72.704-16.597-40.109-27.306-36.88-35.82-57.45z"/>
            <path
                d="m323.45 261.876c-24.813 1.947-23.622-9.313-45.257-7.615-2.403-30.639 20.491-57.43 51.132-59.835 30.64-2.399 57.43 20.493 59.832 51.135-43.277 3.393-43.516 14.576-65.707 16.315z"/>
        </g>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
        <g/>
    </svg>
);

export default icon;
