const signupActions = {
  SET_SIGNUP_DATA_START: 'SET_SIGNUP_DATA_START',
  SET_SIGNUP_DATA_SUCCESS: 'SET_SIGNUP_DATA_SUCCESS',
  SET_SIGNUP_DATA_FAILURE: 'SET_SIGNUP_DATA_FAILURE',
  setSignUpDataStart: () => ({
    type: signupActions.SAVE_SIGNUP_DATA_START,
  }),
  setSignUpDataSuccess: () => ({
    type: signupActions.SAVE_SIGNUP_DATA_SUCCESS,
  }),
  setSignUpDataFailure: () => ({
    type: signupActions.SAVE_SIGNUP_DATA_FAILURE,
  }),

  SAVE_SIGNUP_DATA_START: 'SAVE_SIGNUP_DATA_START',
  SAVE_SIGNUP_DATA_SUCCESS: 'SAVE_SIGNUP_DATA_SUCCESS',
  SAVE_SIGNUP_DATA_FAILURE: 'SAVE_SIGNUP_DATA_FAILURE',
  saveSignUpDataStart: () => ({
    type: signupActions.SAVE_SIGNUP_DATA_START,
  }),
  saveSignUpDataSuccess: () => ({
    type: signupActions.SAVE_SIGNUP_DATA_SUCCESS,
  }),
  saveSignUpDataFailure: () => ({
    type: signupActions.SAVE_SIGNUP_DATA_FAILURE,
  }),
}

export default signupActions
