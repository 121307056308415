import React, {isValidElement, useState, useEffect, useRef, useCallback} from 'react';
import {Button as AntButton} from 'antd';
import {FontAwesome} from 'components/UI';
import PropTypes from 'prop-types';
import {buttonIconStyle} from 'assets/styles/common';
import noop from 'lodash/noop';
import isNil from 'lodash/isNil';
import {GOOGLE} from 'config/vendor/google';
import {isProduction} from "library/helpers/utilities";

export const Button = ({
    disabled,
    icon = null,
    gaId = null,
    icontype = buttonIconStyle,
    loading,
    onClick,
    shouldTimeout = true,
    timeoutSeconds = 10,
    ...props
}) => {
    const [loadingTimeout, setLoadingTimeout] = useState(shouldTimeout && (loading || false));
    const [disableOverride, setDisableOverride] = useState(false);
    const timerRef = useRef(null);

    const cleanUpTimer = useCallback(() => {
        if (loadingTimeout) setLoadingTimeout(false);

        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
    }, [loadingTimeout]);

    useEffect(() => {
        if (!loading) return;
        if (!shouldTimeout) return;

        setLoadingTimeout(true);
    }, [loading, shouldTimeout]);

    useEffect(() => {
        if (!shouldTimeout) return;

        if (isNil(loading)) {
            cleanUpTimer();

            return;
        }

        if (loading && !timerRef.current) {
            timerRef.current = setTimeout(() => {
                setLoadingTimeout(false);
                setDisableOverride(true);
            }, timeoutSeconds * 1000);
        }

        if (loading === false) {
            setLoadingTimeout(false);

            cleanUpTimer();

            return;
        }

        return () => cleanUpTimer();
    }, [cleanUpTimer, loading, loadingTimeout, shouldTimeout, timeoutSeconds]);

    const handleOnClick = useCallback(
        (e) => {
            if (props.loading) return;

            if (
                window.gtag &&
                gaId &&
                isProduction()
            ) {
                window.gtag('event', gaId, {
                    event_category: 'user_interaction',
                    event_label: gaId,
                    send_to: GOOGLE.APP_ANALYTICS_TAG,
                });
            }

            onClick && onClick(e);
        },
        [onClick, gaId, props.loading],
    );
    return (
        <AntButton
            size="large"
            {...props}
            onClick={disabled ? noop : handleOnClick}
            icon={
                loadingTimeout || (!shouldTimeout && loading) ? (
                    <FontAwesome icon="spinner-third" spin />
                ) : (
                    icon && (isValidElement(icon) ? icon : <FontAwesome type={icontype} icon={icon ? icon : ''} />)
                )
            }
            disabled={disableOverride || disabled || !!loadingTimeout}
            loading={false}
        />
    );
};

Button.defaultProps = {
    icontype: buttonIconStyle,
};

Button.propTypes = {
    disabled: PropTypes.any,
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.bool]),
    icontype: PropTypes.any,
    gaId: PropTypes.string,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    shouldTimeout: PropTypes.bool,
    timeoutSeconds: PropTypes.number,
};

export default Button;
