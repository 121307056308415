import campaignsActions from './actions';

const initialData = {
    list: {
        data: null,
        error: null,
        loading: false,
        loaded: false,
    },
    data: null,
    standard: null,
    smart: null,
    error: null,
    query: {page: 1},
    loading: false,
    loaded: false,
    saved: false,
};

const campaignsReducer = (state = initialData, action) => {
    switch (action.type) {
        case campaignsActions.LIST_CAMPAIGNS_START:
            return {
                ...state,
                list: {
                    ...state.list,
                    error: null,
                    loading: true,
                    loaded: false,
                },
            };
        case campaignsActions.LIST_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload.campaigns,
                    loading: false,
                    loaded: true,
                    error: null,
                },
            };
        case campaignsActions.LIST_CAMPAIGNS_FAILURE:
            return {
                ...state,
                list: {
                    ...state.list,
                    error: action.payload,
                    loading: false,
                    loaded: false,
                },
            };

        case campaignsActions.FETCH_CAMPAIGNS_DATA_START:
            return {
                ...state,
                query: action.payload,
                error: null,
                loading: true,
                loaded: false,
            };
        case campaignsActions.FETCH_CAMPAIGNS_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload.campaigns,
                loading: false,
                loaded: true,
                error: null,
            };
        case campaignsActions.FETCH_CAMPAIGNS_DATA_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
                loaded: false,
            };

        case campaignsActions.SAVE_SMART_CAMPAIGN_DATA_START:
        case campaignsActions.SAVE_STANDARD_CAMPAIGN_DATA_START:
        case campaignsActions.TOGGLE_CAMPAIGNS_DATA_START:
            return {
                ...state,
                error: null,
                loading: true,
                loaded: false,
                saved: false,
            };
        case campaignsActions.SAVE_SMART_CAMPAIGN_DATA_SUCCESS:
        case campaignsActions.SAVE_STANDARD_CAMPAIGN_DATA_SUCCESS:
        case campaignsActions.TOGGLE_CAMPAIGNS_DATA_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                saved: true,
            };
        case campaignsActions.SAVE_SMART_CAMPAIGN_DATA_FAILURE:
        case campaignsActions.SAVE_STANDARD_CAMPAIGN_DATA_FAILURE:
        case campaignsActions.TOGGLE_CAMPAIGNS_DATA_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
                saved: false,
            };

        case campaignsActions.DELETE_CAMPAIGNS_DATA_START:
            return {
                ...state,
                error: null,
                loading: true,
                removed: false,
            };
        case campaignsActions.DELETE_CAMPAIGNS_DATA_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                removed: true,
            };
        case campaignsActions.DELETE_CAMPAIGNS_DATA_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
                removed: false,
            };

        case campaignsActions.RESET_CAMPAIGNS_SAVED_STATUS:
            return {
                ...state,
                saved: false,
            };

        case campaignsActions.RESET_CAMPAIGNS_ERROR:
            return {
                ...state,
                error: null,
            };

        case campaignsActions.SET_CURRENT_CAMPAIGN:
            return {
                ...state,
                current: action.payload,
            };
        default:
            return state;
    }
};

export default campaignsReducer;
