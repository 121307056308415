import React from 'react';
import {Space} from 'components/UI';
import {FormItem, FormSubmitButton, FormResetButton} from 'components/FormikForm';
import PropTypes from 'prop-types';

const FormFooter = (props) => {
    return (
        <FormItem
            skelType="button"
            className={'form-footer ' + props.className}
            noLabel={true}
            validation={false}
            style={{...props.style}}
            noLoading={props.noLoading}
            reverse={props.reverse}
            label={props.label}
        >
            {props.children ? (
                <Space size={16}>
                    {!props.reverse && props.children}
                    {props.reset && <FormResetButton {...props.reset} />}
                    {props.submit && <FormSubmitButton {...props.submit} />}
                    {props.reverse && props.children}
                </Space>
            ) : (
                <>
                    {props.reset && <FormResetButton {...props.reset} />}
                    {props.submit && <FormSubmitButton {...props.submit} />}
                </>
            )}
        </FormItem>
    );
};

FormFooter.defaultProps = {
    close: false,
    label: '',
    noLoading: false,
    reset: false,
    reverse: false,
    submit: true,
};

FormFooter.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    close: PropTypes.bool,
    label: PropTypes.string,
    noLoading: PropTypes.bool,
    reset: PropTypes.bool,
    reverse: PropTypes.bool,
    style: PropTypes.object,
    submit: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

export default FormFooter;
