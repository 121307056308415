import React from 'react';

const icon = () => (
    <svg viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <circle fill="#F0F0F0" cx="256" cy="256" r="256"></circle>
                <g transform="translate(8.819000, 8.819000)" fill="#0052B4">
                    <path d="M44.101,91.323 C23.992,117.486 8.829,147.641 -1.42108547e-14,180.4 L133.178,180.4 L44.101,91.323 Z"></path>
                    <path d="M494.362,180.4 C485.533,147.642 470.369,117.487 450.261,91.324 L361.186,180.4 L494.362,180.4 Z"></path>
                    <path d="M-1.59872116e-14,313.965 C8.83,346.723 23.993,376.878 44.101,403.04 L133.175,313.965 L-1.59872116e-14,313.965 Z"></path>
                    <path d="M403.039,44.102 C376.876,23.993 346.722,8.83 313.963,-1.42108547e-14 L313.963,133.177 L403.039,44.102 Z"></path>
                    <path d="M91.323,450.26 C117.486,470.369 147.641,485.532 180.399,494.362 L180.399,361.186 L91.323,450.26 Z"></path>
                    <path d="M180.398,-1.59872116e-14 C147.64,8.83 117.485,23.993 91.323,44.101 L180.398,133.176 L180.398,-1.59872116e-14 Z"></path>
                    <path d="M313.964,494.362 C346.722,485.532 376.877,470.369 403.039,450.261 L313.964,361.186 L313.964,494.362 Z"></path>
                    <path d="M361.186,313.965 L450.261,403.041 C470.369,376.879 485.533,346.723 494.362,313.965 L361.186,313.965 Z"></path>
                </g>
                <g id="Group" transform="translate(0.000000, 0.000000)" fill="#D80027">
                    <path d="M509.833,222.609 L289.393,222.609 L289.392,222.609 L289.392,2.167 C278.461,0.744 267.317,1.77635684e-15 256,1.77635684e-15 C244.681,1.77635684e-15 233.539,0.744 222.609,2.167 L222.609,222.607 L222.609,222.608 L2.167,222.608 C0.744,233.539 3.01980663e-14,244.683 3.01980663e-14,256 C3.01980663e-14,267.319 0.744,278.461 2.167,289.391 L222.607,289.391 L222.608,289.391 L222.608,509.833 C233.539,511.256 244.681,512 256,512 C267.317,512 278.461,511.257 289.391,509.833 L289.391,289.393 L289.391,289.392 L509.833,289.392 C511.256,278.461 512,267.319 512,256 C512,244.683 511.256,233.539 509.833,222.609 L509.833,222.609 Z"></path>
                    <path d="M322.783,322.783 L437.019,437.02 C442.273,431.768 447.285,426.277 452.067,420.585 L354.265,322.783 L322.783,322.783 L322.783,322.783 Z"></path>
                    <path d="M189.217,322.784 L189.215,322.784 L74.98,437.019 C80.232,442.273 85.723,447.285 91.415,452.067 L189.217,354.263 L189.217,322.784 Z"></path>
                    <path d="M189.217,189.219 L189.217,189.217 L74.981,74.98 C69.727,80.232 64.715,85.723 59.933,91.415 L157.736,189.219 L189.217,189.219 L189.217,189.219 Z"></path>
                    <path d="M322.783,189.219 L437.02,74.981 C431.768,69.727 426.277,64.715 420.585,59.934 L322.783,157.737 L322.783,189.219 Z"></path>
                </g>
            </g>
        </g>
    </svg>
);

export default icon;
