import {api} from 'library/helpers/api';

export const call = async (method, route, payload = {}) =>
    await api[method](route, payload)
        .then(({data}) => {
            return data;
        })
        .catch(({data, message}) => {
            throw Error(message || data?.message || data?.errors?.shift?.());
        });
