const shippingRatesActions = {
  FETCH_SHIPPING_RATES_START: 'FETCH_SHIPPING_RATES_START',
  FETCH_SHIPPING_RATES_SUCCESS: 'FETCH_SHIPPING_RATES_SUCCESS',
  FETCH_SHIPPING_RATES_ERROR: 'FETCH_SHIPPING_RATES_ERROR',
  fetchShippingRatesStart: () => ({
    type: shippingRatesActions.FETCH_SHIPPING_RATES_START,
  }),
  fetchShippingRatesSuccess: (payload) => {
    return {
      type: shippingRatesActions.FETCH_SHIPPING_RATES_SUCCESS,
      payload,
    }
  },
  fetchShippingRatesError: (error) => ({
    type: shippingRatesActions.FETCH_SHIPPING_RATES_ERROR,
    payload: error,
  }),
}

export default shippingRatesActions
