import {all, put, takeLeading} from 'redux-saga/effects';
import {apiEndpoints} from 'config/api';
import {api} from 'library/helpers/api';
import descendantsActions from './actions';
import {addSuperAdminToTeam} from 'library/helpers/data';

function* fetchTeamDescendants() {
    try {
        const response = yield api.get(apiEndpoints.descendants.teams);

        yield put(descendantsActions.fetchAccountDescendantsStart());
        yield put(descendantsActions.fetchCampaignDescendantsStart());
        yield put(descendantsActions.fetchTeamDescendantsSuccess(response.data));
    } catch (error) {
        yield put(descendantsActions.fetchTeamDescendantsFailure(error));
    }
}

function* fetchAccountDescendants() {
    try {
        const response = yield api.get(apiEndpoints.descendants.accounts);

        yield put(descendantsActions.fetchAccountDescendantsSuccess(addSuperAdminToTeam(response.data)));
    } catch (error) {
        yield put(descendantsActions.fetchAccountDescendantsFailure(error));
    }
}

function* fetchCampaignDescendants() {
    try {
        const response = yield api.get(apiEndpoints.descendants.campaigns);

        yield put(descendantsActions.fetchCampaignDescendantsSuccess(response.data));
    } catch (error) {
        yield put(descendantsActions.fetchCampaignDescendantsFailure(error));
    }
}

export default function* descendantsSaga() {
    yield all([
        takeLeading(descendantsActions.FETCH_TEAM_DESCENDANTS_START, fetchTeamDescendants),
        takeLeading(descendantsActions.FETCH_ACCOUNT_DESCENDANTS_START, fetchAccountDescendants),
        takeLeading(descendantsActions.FETCH_CAMPAIGN_DESCENDANTS_START, fetchCampaignDescendants),
    ]);
}
