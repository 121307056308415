import React, {useEffect} from 'react';

export default function useAutoFocus() {
    const inputRef = React.createRef();

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    return inputRef;
}
