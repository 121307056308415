import fundingMethodActions from './actions';
import teamActions from 'redux/team/actions';

const initialState = {
  data: null,
  error: null,
  loading: false,
};

const fundingMethodsReducer = (state = initialState, action) => {
  switch (action.type) {
    case fundingMethodActions.FETCH_FUNDING_METHODS_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case fundingMethodActions.FETCH_FUNDING_METHODS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case fundingMethodActions.FETCH_FUNDING_METHODS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case teamActions.CHANGE_TEAM_SUCCESS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default fundingMethodsReducer;
