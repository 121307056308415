import Payment from 'payment';

function clearNumber(value) {
    return String(value).replace(/\D+/g, '');
}

export const formatCreditCardNumber = (value) => {
    if (!value) {
        return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue;

    switch (issuer) {
        case 'amex':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 15)}`;
            break;
        case 'dinersclub':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 14)}`;
            break;
        default:
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(
                8,
                12,
            )} ${clearValue.slice(12, 16)}`;
            break;
    }

    return nextValue.trim();
};

export const formatCVC = (value, prevValue = '', allValues = {}) => {
    const clearValue = clearNumber(value);
    let maxLength = 4;

    if (allValues.number) {
        const issuer = Payment.fns.cardType(allValues.number);
        maxLength = issuer === 'amex' ? 3 : 4;
    }

    return clearValue.slice(0, maxLength);
};

export const formatExpirationDate = (value) => {
    const clearValue = clearNumber(value);

    if (clearValue.length >= 3) {
        return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }

    return clearValue;
};

export const getCreditCardType = (creditCardNumber) => {
    // first check for MasterCard
    if (/^(?:5([1-5])|2221|2720)/.test(creditCardNumber)) {
        return 'mastercard';
    }

    // then check for Visa
    if (/^4/.test(creditCardNumber)) {
        return 'visa';
    }

    // then check for Amex
    if (/^3[47]/.test(creditCardNumber)) {
        return 'amex';
    }

    // then check for Discover
    if (/^6(?:011|22|44|49|5)/.test(creditCardNumber)) {
        return 'discover';
    }

    return null;
};
