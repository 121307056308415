import React from 'react';
import PropTypes from 'prop-types';
import {Layout, Row, Col, Button} from 'components/UI';
import {Container} from 'components/Layout';
import {goToPreviousRoute} from 'library/helpers/routing';
import {routes} from 'config/routes';

const {Content} = Layout;

export const ProductDetailLayout = (props) => {
    return (
        <Layout key="main-content" className="content-centered">
            {props.alert}
            <Content className="main-content has-chat">
                <Button
                    type="text"
                    onClick={() =>
                        goToPreviousRoute({
                            defaultRoute: routes.private.paths.CREATE,
                            omit: routes.private.paths.CREATE_DESIGNER,
                        })
                    }
                    icon="arrow-left"
                >
                    Back to Designs
                </Button>

                <Container className="product-container">
                    <Row gutter={[64, 32]}>
                        <Col xxl={13} xl={13} lg={14} md={12} sm={24} xs={24}>
                            {props.content}
                        </Col>

                        <Col xxl={11} xl={11} lg={10} md={12} sm={24} xs={24}>
                            {props.sider}
                        </Col>
                    </Row>
                </Container>
            </Content>
        </Layout>
    );
};

ProductDetailLayout.propTypes = {
    alert: PropTypes.any,
    content: PropTypes.any,
    sider: PropTypes.any.isRequired,
};

export default ProductDetailLayout;
