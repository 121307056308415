import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Card, Modal, Form, Select, Typography, Button, Row, Col, Alert, Space, Skeleton} from 'components/UI';
import {lang} from 'config/lang';
import useIntegrationsStore from 'hooks/integrations/useIntegrationsStore';
import isEmpty from 'lodash/isEmpty';
import {usePrev} from 'hooks/utility/usePrev';
import isEqual from 'react-fast-compare';
import uniq from 'lodash/uniq';

const {Title} = Typography;

const TableInlineToolbar = ({
    selectOptions,
    filtersChangeOnSubmit,
    configuredFilters,
    loaded,
    renderedFilters,
    loading,
    handleSetConfiguredFilters,
}) => {
    const [showFilterSelectModal, setShowFilterSelectModal] = useState(false);
    const [filterForm] = Form.useForm();

    const {store} = useIntegrationsStore();

    const [filters, setFilters] = useState(null);
    const [options, setOptions] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState(
        configuredFilters?.filter((filter) => filter !== 'submit_filters'),
    );

    useEffect(() => {
        if (!selectOptions) return;

        setOptions(selectOptions);
        setFilters(selectOptions);
    }, [filters, selectOptions]);

    const prevSelectedFilters = usePrev(selectedFilters);
    const calculateOptions = useCallback(() => {
        let localOptions = [];

        filters?.forEach((filter) => {
            const filterValue = filter.value ?? filter.key;

            localOptions.push({
                label: filter.label,
                value: filterValue,
            });
        });

        if (!isEqual(selectedFilters, prevSelectedFilters)) {
            filterForm.setFieldsValue({
                selected_filters: (selectedFilters ?? []).filter((filter) =>
                    localOptions.map((option) => option.value ?? option.key).includes(filter),
                ),
            });
        }

        handleSetConfiguredFilters(
            selectedFilters?.length > 0 ? uniq([...selectedFilters, 'submit_filters']) : ['submit_filters'],
        );

        setOptions(localOptions?.sort?.((a, b) => a?.label.localeCompare(b?.label)) || []);
    }, [filters, selectedFilters, prevSelectedFilters, handleSetConfiguredFilters, filterForm]);

    const handleSaveChanges = useCallback(() => {
        filtersChangeOnSubmit(selectedFilters);
        setShowFilterSelectModal(false);
    }, [selectedFilters, filtersChangeOnSubmit]);

    const hasFilters = useMemo(
        () => configuredFilters?.filter((filter) => filter !== 'submit_filters').length > 0,
        [configuredFilters],
    );

    return (
        <>
            <Modal
                title={'Manage Filters'}
                open={showFilterSelectModal}
                onCancel={() => setShowFilterSelectModal(false)}
                onOk={handleSaveChanges}
                okText={'Save Filters'}
                forceRender
            >
                <Form
                    name="filter-select-form"
                    layout="vertical"
                    form={filterForm}
                    initialValues={{selected_filters: selectedFilters}}
                >
                    <Form.Item
                        name="selected_filters"
                        help={'Leave blank to import all available data'}
                        label={'Filters'}
                    >
                        <Select
                            mode="multiple"
                            size="large"
                            style={{width: '100%'}}
                            options={options}
                            placeholder="Select filters..."
                            onChange={(values) => {
                                setSelectedFilters(values);
                                calculateOptions();
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            <Card
                bordered={false}
                titleBorder={false}
                title={<Title level={5}>{lang['filters']}</Title>}
                extra={
                    hasFilters && (
                        <Button
                            type="default"
                            size="small"
                            onClick={() => setShowFilterSelectModal(true)}
                            disabled={!loaded}
                            icon="sliders-simple"
                        >
                            Manage Filters
                        </Button>
                    )
                }
            >
                {loaded && hasFilters && (
                    <Row gutter={[16, 16]}>
                        <Col span={24}>{renderedFilters}</Col>
                    </Row>
                )}
                {loaded && !hasFilters && (
                    <>
                        <Alert
                            type="info"
                            message="No filters applied"
                            description={'Apply filters to refine this data'}
                        />

                        <Button
                            type="default"
                            size="large"
                            onClick={() => setShowFilterSelectModal(true)}
                            disabled={!loaded || !isEmpty(store.error_message)}
                            icon="plus"
                            block
                        >
                            Add Filters
                        </Button>
                    </>
                )}
                {loading && !loaded && (
                    <Space direction="vertical" size={16}>
                        <Skeleton.Input active size="small" className="full-width" />
                        <Skeleton.Input active size="small" className="full-width" />
                        <Skeleton.Input active size="small" className="full-width" />
                        <Skeleton.Input active size="small" />
                    </Space>
                )}
            </Card>
        </>
    );
};

TableInlineToolbar.propTypes = {
    selectOptions: PropTypes.array,
    filtersChangeOnSubmit: PropTypes.func,
    configuredFilters: PropTypes.array,
    loaded: PropTypes.bool,
    renderedFilters: PropTypes.array,
    loading: PropTypes.bool,
    handleSetConfiguredFilters: PropTypes.func,
};

export default TableInlineToolbar;
