import shippingAddressActions from './actions';

import {LOGOUT} from 'redux/auth/auth.types';

const initialState = {
  geocode: {
    data: null,
    loading: null,
    saved: false,
    error: null,
  },
  data: null,
  error: false,
  loading: false,
  updated: false,
  newAddress: null,
};

const shippingAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case shippingAddressActions.FETCH_SHIPPING_ADDRESSES_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case shippingAddressActions.FETCH_SHIPPING_ADDRESSES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case shippingAddressActions.FETCH_SHIPPING_ADDRESSES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case shippingAddressActions.GEOCODE_SHIPPING_ADDRESSES_START:
      return {
        ...state,
        geocode: {
          ...state.geocode,
          data: null,
          loading: true,
          error: false,
        },
      };
    case shippingAddressActions.GEOCODE_SHIPPING_ADDRESSES_SUCCESS:
      return {
        ...state,
        geocode: {
          ...state.geocode,
          data: action.payload,
          loading: false,
          error: false,
        },
      };
    case shippingAddressActions.GEOCODE_SHIPPING_ADDRESSES_ERROR:
      return {
        ...state,
        geocode: {
          ...state.geocode,
          loading: false,
          error: action.payload,
        },
      };
    case shippingAddressActions.GEOCODE_SHIPPING_ADDRESSES_RESET:
      return {
        ...state,
        geocode: {
          ...state.geocode,
          data: null,
          saved: false,
          loading: false,
          error: false,
        },
      };

    case shippingAddressActions.SAVE_SHIPPING_ADDRESSES_START:
    case shippingAddressActions.UPDATE_SHIPPING_ADDRESSES_START:
      return {
        ...state,
        geocode: {
          ...state.geocode,
          saved: false,
        },
      };
    case shippingAddressActions.SAVE_SHIPPING_ADDRESSES_SUCCESS:
      return {
        ...state,
        newAddress: action.payload,
        geocode: {
          data: null,
          loading: false,
          saved: true,
          error: false,
        },
      };
    case shippingAddressActions.UPDATE_SHIPPING_ADDRESSES_SUCCESS:
      return {
        ...state,
        geocode: {
          data: null,
          loading: false,
          saved: true,
          error: false,
        },
      };

    case shippingAddressActions.SAVE_SHIPPING_ADDRESSES_ERROR:
    case shippingAddressActions.UPDATE_SHIPPING_ADDRESSES_ERROR:
      return {
        ...state,
        geocode: {
          ...state.geocode,
          loading: false,
          saved: false,
          error: action.payload,
        },
      };

    case shippingAddressActions.DELETE_SHIPPING_ADDRESSES_START:
    case shippingAddressActions.DEFAULT_SHIPPING_ADDRESSES_START:
      return {
        ...state,
        updated: false,
        loading: true,
      };
    case shippingAddressActions.DELETE_SHIPPING_ADDRESSES_SUCCESS:
    case shippingAddressActions.DEFAULT_SHIPPING_ADDRESSES_SUCCESS:
      return {
        ...state,
        updated: true,
      };
    case shippingAddressActions.DELETE_SHIPPING_ADDRESSES_ERROR:
    case shippingAddressActions.DEFAULT_SHIPPING_ADDRESSES_ERROR:
      return {
        ...state,
        updated: false,
        loading: false,
      };

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default shippingAddressReducer;
