import {all, put, takeLeading} from 'redux-saga/effects';
import contactPropertiesActions from 'redux/contactProperties/actions';
import {api} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';

function* fetchContactPropertiesData() {
    try {
        const contactProperties = yield api.get(apiEndpoints.recipientProperties.get).then((response) => response.data);

        yield put(contactPropertiesActions.fetchContactPropertiesSuccess(contactProperties));
    } catch (error) {
        yield put(contactPropertiesActions.fetchContactPropertiesError(error));
    }
}

export default function* contactPropertiesSaga() {
    yield all([takeLeading(contactPropertiesActions.FETCH_CONTACT_PROPERTIES_DATA_START, fetchContactPropertiesData)]);
}
