const savedDesignActions = {
  FETCH_SAVED_DESIGN_DATA_START: 'FETCH_SAVED_DESIGN_DATA_START',
  FETCH_SAVED_DESIGN_DATA_SUCCESS: 'FETCH_SAVED_DESIGN_DATA_SUCCESS',
  FETCH_SAVED_DESIGN_DATA_FAILURE: 'FETCH_SAVED_DESIGN_DATA_FAILURE',

  fetchSavedDesignsStart: () => ({
    type: savedDesignActions.FETCH_SAVED_DESIGN_DATA_START,
  }),
  fetchSavedDesignsSuccess: (savedDesigns) => ({
    type: savedDesignActions.FETCH_SAVED_DESIGN_DATA_SUCCESS,
    payload: savedDesigns,
  }),
  fetchSavedDesignsError: (error) => ({
    type: savedDesignActions.FETCH_SAVED_DESIGN_DATA_FAILURE,
    payload: error,
  }),

  DELETE_SAVED_DESIGN_START: 'DELETE_SAVED_DESIGN_START',
  DELETE_SAVED_DESIGN_SUCCESS: 'DELETE_SAVED_DESIGN_SUCCESS',
  DELETE_SAVED_DESIGN_FAILURE: 'DELETE_SAVED_DESIGN_FAILURE',

  deleteSavedDesignsStart: (payload) => ({
    type: savedDesignActions.DELETE_SAVED_DESIGN_START,
    payload,
  }),
  deleteSavedDesignsSuccess: () => ({
    type: savedDesignActions.DELETE_SAVED_DESIGN_SUCCESS,
  }),
  deleteSavedDesignsError: (error) => ({
    type: savedDesignActions.DELETE_SAVED_DESIGN_FAILURE,
    payload: error,
  }),
};

export default savedDesignActions;
