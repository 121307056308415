const descendantsActions = {
    FETCH_TEAM_DESCENDANTS_START: 'FETCH_TEAM_DESCENDANTS_START',
    FETCH_TEAM_DESCENDANTS_SUCCESS: 'FETCH_TEAM_DESCENDANTS_SUCCESS',
    FETCH_TEAM_DESCENDANTS_FAILURE: 'FETCH_TEAM_DESCENDANTS_FAILURE',
    fetchTeamDescendantsStart: () => ({
        type: descendantsActions.FETCH_TEAM_DESCENDANTS_START,
    }),
    fetchTeamDescendantsSuccess: (payload) => ({
        type: descendantsActions.FETCH_TEAM_DESCENDANTS_SUCCESS,
        payload,
    }),
    fetchTeamDescendantsFailure: (error) => ({
        type: descendantsActions.FETCH_TEAM_DESCENDANTS_FAILURE,
        payload: error,
    }),

    FETCH_ACCOUNT_DESCENDANTS_START: 'FETCH_ACCOUNT_DESCENDANTS_START',
    FETCH_ACCOUNT_DESCENDANTS_SUCCESS: 'FETCH_ACCOUNT_DESCENDANTS_SUCCESS',
    FETCH_ACCOUNT_DESCENDANTS_FAILURE: 'FETCH_ACCOUNT_DESCENDANTS_FAILURE',
    fetchAccountDescendantsStart: (payload) => ({
        type: descendantsActions.FETCH_ACCOUNT_DESCENDANTS_START,
        payload,
    }),
    fetchAccountDescendantsSuccess: (payload) => ({
        type: descendantsActions.FETCH_ACCOUNT_DESCENDANTS_SUCCESS,
        payload,
    }),
    fetchAccountDescendantsFailure: (error) => ({
        type: descendantsActions.FETCH_ACCOUNT_DESCENDANTS_FAILURE,
        payload: error,
    }),

    FETCH_CAMPAIGN_DESCENDANTS_START: 'FETCH_CAMPAIGN_DESCENDANTS_START',
    FETCH_CAMPAIGN_DESCENDANTS_SUCCESS: 'FETCH_CAMPAIGN_DESCENDANTS_SUCCESS',
    FETCH_CAMPAIGN_DESCENDANTS_FAILURE: 'FETCH_CAMPAIGN_DESCENDANTS_FAILURE',
    fetchCampaignDescendantsStart: () => ({
        type: descendantsActions.FETCH_CAMPAIGN_DESCENDANTS_START,
    }),
    fetchCampaignDescendantsSuccess: (payload) => ({
        type: descendantsActions.FETCH_CAMPAIGN_DESCENDANTS_SUCCESS,
        payload,
    }),
    fetchCampaignDescendantsFailure: (error) => ({
        type: descendantsActions.FETCH_CAMPAIGN_DESCENDANTS_FAILURE,
        payload: error,
    }),

    UPDATE_FUNDING_SELECTED: 'UPDATE_FUNDING_SELECTED',
    UPDATE_ORDERS_SELECTED: 'UPDATE_ORDERS_SELECTED',
    updateFundingSelected: (payload) => ({
        type: descendantsActions.UPDATE_FUNDING_SELECTED,
        payload,
    }),
    updateOrdersSelected: (payload) => ({
        type: descendantsActions.UPDATE_ORDERS_SELECTED,
        payload,
    }),
};

export default descendantsActions;
