import {put, takeEvery, takeLeading} from 'redux-saga/effects';
import profileActions from './actions';
import {apiEndpoints} from 'config/api';
import {api} from 'library/helpers/api';
import {getRoute} from 'library/helpers/routing';
import {Auth} from 'aws-amplify';
import {setAuthError} from 'redux/auth/auth.actions';
import campaignsActions from 'redux/campaigns/actions';
import {ggDate} from 'library/helpers/date';
import {store} from 'redux/store';
import isNil from 'lodash/isNil';

function* fetchUser() {
    let profile = yield api.get(apiEndpoints.account.get);

    profile = mapProfile(profile.data);

    return profile;
}

// TODO: Move to reducer
function mapProfile(profile) {
    const creditAvailable = parseFloat(profile.credit_available) || 0.0;

    return {
        ...profile,
        credit_available: creditAvailable,
        credit_limit: parseFloat(profile.credit_limit) || 0.0,
        full_name: profile.first_name + ' ' + profile.last_name,
        initials: profile.first_name.slice(0, 1) + profile.last_name.slice(0, 1),
    };
}

function* fetchProfileDataEffect() {
    try {
        const profile = yield fetchUser();

        yield put(profileActions.fetchProfileDataSuccess(profile));
    } catch (error) {
        yield put(profileActions.fetchProfileDataFailure(error));
    }
}

function* fetchProfileSuccess({payload}) {
    const timezone = payload.timezone ?? 'America/New_York';

    yield ggDate.setDefault(timezone);
}

function* updateProfileDataEffect({payload}) {
    try {
        yield api.put(apiEndpoints.account.put, payload);

        yield put(profileActions.updateProfileDataSuccess());
        yield put(profileActions.fetchProfileDataStart({forceFetch: true}));
        yield put(campaignsActions.fetchCampaignsStart());
    } catch (error) {
        yield put(profileActions.updateProfileDataFailure(error));
    }
}

function* resetProfilePasswordDataEffect({payload}) {
    try {
        // try to grab the current user
        const response = yield Auth.currentAuthenticatedUser()
            .then((user) => Auth.changePassword(user, payload.current_password, payload.password))
            .then(() => true)
            .catch((error) => error);

        if (response === true) {
            return yield put(profileActions.resetPasswordProfileDataSuccess());
        }

        if (response.code === 'NotAuthorizedException') response.message = 'Current password is invalid';

        yield put(setAuthError(response));
    } catch (error) {
        console.log(error);
        yield put(setAuthError(error));
    }
}

function* setWalkthroughItem({payload}) {
    const response = yield api.put(getRoute(apiEndpoints.account.walkthrough, {item: payload}));
    const profile = mapProfile(response.data);
    yield put(profileActions.fetchProfileDataSuccess(profile));
}

function* clearWalkthroughItem({payload}) {
    const response = yield api.delete(getRoute(apiEndpoints.account.walkthrough, {item: payload}));

    const profile = mapProfile(response.data);
    yield put(profileActions.fetchProfileDataSuccess(profile));
}

function* updateDataContext({payload}) {
    const profile = store.getState().profile;

    if (!profile.data) {
        yield fetchProfileDataEffect();
    }

    const {campaign_id, team_id} = payload;

    if (!isNil(campaign_id)) {
        yield put(profileActions.updateDataContextCampaignIdSuccess(campaign_id));
    }

    if (!isNil(team_id)) {
        yield put(profileActions.updateDataContextTeamIdSuccess(team_id));
    }
}

export default function* profileSaga() {
    yield takeLeading(profileActions.FETCH_PROFILE_DATA_START, fetchProfileDataEffect);
    yield takeEvery(profileActions.FETCH_PROFILE_DATA_SUCCESS, fetchProfileSuccess);
    yield takeEvery(profileActions.UPDATE_PROFILE_DATA_START, updateProfileDataEffect);
    yield takeLeading(profileActions.RESET_PROFILE_PASSWORD_DATA_START, resetProfilePasswordDataEffect);
    yield takeEvery(profileActions.WALKTHROUGH_SET, setWalkthroughItem);
    yield takeLeading(profileActions.WALKTHROUGH_CLEAR, clearWalkthroughItem);
    yield takeLeading(profileActions.UPDATE_DATA_CONTEXT, updateDataContext);
}
