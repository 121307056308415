import isEqual from 'react-fast-compare';
import React from 'react';

const useDeepCompareMemoize = (value) => {
    const ref = React.useRef(value);
    const signalRef = React.useRef(0);

    if (!isEqual(value, ref.current)) {
        ref.current = value;
        signalRef.current += 1;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useMemo(() => ref.current, [signalRef.current]);
};

const useDeepCompareEffect = (callback, dependencies) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useEffect(callback, useDeepCompareMemoize(dependencies));
};

export default useDeepCompareEffect;
