const recipientsUploadActions = {
  SET_RECIPIENTS_UPLOAD_DATA_START: 'SET_RECIPIENTS_UPLOAD_DATA_START',
  SET_RECIPIENTS_UPLOAD_DATA_SUCCESS: 'SET_RECIPIENTS_UPLOAD_DATA_SUCCESS',
  SET_RECIPIENTS_UPLOAD_DATA_FAILURE: 'SET_RECIPIENTS_UPLOAD_DATA_FAILURE',
  setRecipientsUploadStart: (payload) => {
    return {
      type: recipientsUploadActions.SET_RECIPIENTS_UPLOAD_DATA_START,
      payload,
    };
  },
  setRecipientsUploadSuccess: (payload) => ({
    type: recipientsUploadActions.SET_RECIPIENTS_UPLOAD_DATA_SUCCESS,
    payload,
  }),
  setRecipientsUploadFailure: (error) => ({
    type: recipientsUploadActions.SET_RECIPIENTS_UPLOAD_DATA_FAILURE,
    payload: error,
  }),

  REMOVE_RECIPIENTS_UPLOAD_DATA_START: 'REMOVE_RECIPIENTS_UPLOAD_DATA_START',
  REMOVE_RECIPIENTS_UPLOAD_DATA_SUCCESS:
    'REMOVE_RECIPIENTS_UPLOAD_DATA_SUCCESS',
  REMOVE_RECIPIENTS_UPLOAD_DATA_FAILURE:
    'REMOVE_RECIPIENTS_UPLOAD_DATA_FAILURE',
  removeRecipientsUploadStart: () => ({
    type: recipientsUploadActions.REMOVE_RECIPIENTS_UPLOAD_DATA_START,
  }),
  removeRecipientsUploadSuccess: () => {
    return {
      type: recipientsUploadActions.REMOVE_RECIPIENTS_UPLOAD_DATA_SUCCESS,
    };
  },
  removeRecipientsUploadFailure: (error) => ({
    type: recipientsUploadActions.REMOVE_RECIPIENTS_UPLOAD_DATA_FAILURE,
    payload: error,
  }),

  SAVE_RECIPIENTS_UPLOAD_DATA_START: 'SAVE_RECIPIENTS_UPLOAD_DATA_START',
  SAVE_RECIPIENTS_UPLOAD_DATA_SUCCESS: 'SAVE_RECIPIENTS_UPLOAD_DATA_SUCCESS',
  SAVE_RECIPIENTS_UPLOAD_DATA_FAILURE: 'SAVE_RECIPIENTS_UPLOAD_DATA_FAILURE',
  saveRecipientsUploadStart: (payload) => ({
    type: recipientsUploadActions.SAVE_RECIPIENTS_UPLOAD_DATA_START,
    payload,
  }),
  saveRecipientsUploadSuccess: (payload) => {
    return {
      type: recipientsUploadActions.SAVE_RECIPIENTS_UPLOAD_DATA_SUCCESS,
      payload,
    };
  },
  saveRecipientsUploadFailure: (error) => ({
    type: recipientsUploadActions.SAVE_RECIPIENTS_UPLOAD_DATA_FAILURE,
    payload: error,
  }),

  FETCH_RECIPIENTS_UPLOAD_DATA_START: 'FETCH_RECIPIENTS_UPLOAD_DATA_START',
  FETCH_RECIPIENTS_UPLOAD_DATA_SUCCESS: 'FETCH_RECIPIENTS_UPLOAD_DATA_SUCCESS',
  FETCH_RECIPIENTS_UPLOAD_DATA_FAILURE: 'FETCH_RECIPIENTS_UPLOAD_DATA_FAILURE',
  fetchRecipientsUploadStart: () => ({
    type: recipientsUploadActions.FETCH_RECIPIENTS_UPLOAD_DATA_START,
  }),
  fetchRecipientsUploadSuccess: (payload) => {
    return {
      type: recipientsUploadActions.FETCH_RECIPIENTS_UPLOAD_DATA_SUCCESS,
      payload,
    };
  },
  fetchRecipientsUploadFailure: (error) => ({
    type: recipientsUploadActions.FETCH_RECIPIENTS_UPLOAD_DATA_FAILURE,
    payload: error,
  }),

  CHECK_RECIPIENTS_JOB_RUNNING_START: 'CHECK_RECIPIENTS_JOB_RUNNING_START',
  CHECK_RECIPIENTS_JOB_RUNNING_SUCCESS: 'CHECK_RECIPIENTS_JOB_RUNNING_SUCCESS',
  CHECK_RECIPIENTS_JOB_RUNNING_FAILURE: 'CHECK_RECIPIENTS_JOB_RUNNING_FAILURE',
  checkRecipientsJobRunningStart: () => ({
    type: recipientsUploadActions.CHECK_RECIPIENTS_JOB_RUNNING_START,
  }),
  checkRecipientsJobRunningSuccess: (payload) => {
    return {
      type: recipientsUploadActions.CHECK_RECIPIENTS_JOB_RUNNING_SUCCESS,
      payload,
    };
  },
  checkRecipientsJobRunningFailure: (error) => ({
    type: recipientsUploadActions.CHECK_RECIPIENTS_JOB_RUNNING_FAILURE,
    payload: error,
  }),

  CLEAR_RECIPIENTS_UPLOAD_JOB_START: 'CLEAR_RECIPIENTS_UPLOAD_JOB_START',
  CLEAR_RECIPIENTS_UPLOAD_JOB_SUCCESS: 'CLEAR_RECIPIENTS_UPLOAD_JOB_SUCCESS',
  CLEAR_RECIPIENTS_UPLOAD_JOB_FAILURE: 'CLEAR_RECIPIENTS_UPLOAD_JOB_FAILURE',
  clearRecipientsUploadJobStart: () => ({
    type: recipientsUploadActions.CLEAR_RECIPIENTS_UPLOAD_JOB_START,
  }),
  clearRecipientsUploadJobSuccess: () => {
    return {
      type: recipientsUploadActions.CLEAR_RECIPIENTS_UPLOAD_JOB_SUCCESS,
    };
  },
  clearRecipientsUploadJobFailure: (error) => ({
    type: recipientsUploadActions.CLEAR_RECIPIENTS_UPLOAD_JOB_FAILURE,
    payload: error,
  }),

  RESET_UPLOAD_STORE: 'RESET_UPLOAD_STORE',
  resetUploadStore: () => ({
    type: recipientsUploadActions.RESET_UPLOAD_STORE,
  }),
};

export default recipientsUploadActions;
