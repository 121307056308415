import React from 'react';
import PropTypes from 'prop-types';
import {Redirect, Switch, useLocation} from 'react-router-dom';
import PageWrapper from 'containers/Routes/PrivateRoutes/PageWrapper';
import {getRouteComponents} from 'containers/Routes';
import {routes} from 'config/routes';
import {useRecoilValue} from 'recoil';
import {authState} from '../../../services/auth/atoms/auth';

const PrivateRoutes = (props) => {
    const {authenticated} = useRecoilValue(authState);

    const {pathname} = useLocation();

    // wait will we are loaded
    if (props.authState === 'loading') {
        return '';
    }

    // if we are in the root redirect us to dashboard
    if (pathname === '/') {
        return <Redirect to={'/dashboard'} />;
    }

    if (!authenticated) return null;

    return (
        <PageWrapper {...props}>
            <Switch>{getRouteComponents(routes.private.containers, props)}</Switch>
        </PageWrapper>
    );
};

PrivateRoutes.propTypes = {
    authState: PropTypes.string,
};

export default PrivateRoutes;
