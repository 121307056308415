const regionsActions = {
    FETCH_REGIONS_START: 'FETCH_REGIONS_START',
    FETCH_REGIONS_SUCCESS: 'FETCH_REGIONS_SUCCESS',
    FETCH_REGIONS_FAILURE: 'FETCH_REGIONS_FAILURE',
    fetchRegionsStart: () => ({
        type: regionsActions.FETCH_REGIONS_START,
    }),

    fetchRegionsSuccess: (payload) => {
        return {
            type: regionsActions.FETCH_REGIONS_SUCCESS,
            payload,
        };
    },

    fetchRegionsFailure: (error) => ({
        type: regionsActions.FETCH_REGIONS_FAILURE,
        payload: error,
    }),

    FETCH_ALL_REGIONS_START: 'FETCH_ALL_REGIONS_START',
    FETCH_ALL_REGIONS_SUCCESS: 'FETCH_ALL_REGIONS_SUCCESS',
    FETCH_ALL_REGIONS_FAILURE: 'FETCH_ALL_REGIONS_FAILURE',
    fetchAllRegionsStart: () => ({
        type: regionsActions.FETCH_ALL_REGIONS_START,
    }),
    fetchAllRegionsSuccess: (payload) => {
        return {
            type: regionsActions.FETCH_ALL_REGIONS_SUCCESS,
            payload,
        };
    },
    fetchAllRegionsFailure: (error) => ({
        type: regionsActions.FETCH_ALL_REGIONS_FAILURE,
        payload: error,
    }),

    UPDATE_REGIONS: 'UPDATE_REGIONS',
    updateRegions: (payload) => ({
        type: regionsActions.UPDATE_REGIONS,
        payload,
    }),
};

export default regionsActions;
