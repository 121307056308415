import {api} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';
import {all, put} from 'redux-saga/effects';
import {buildQuery, getRoute} from 'library/helpers/routing';
import checkoutLookupActions from './actions';
import {takeLatest} from '@redux-saga/core/effects';

function* fetchCheckoutLookup({payload}) {
    const route = getRoute(apiEndpoints.checkoutLookup.get, payload);
    const url = `${route}?${buildQuery(payload)}`;

    try {
        const checkoutLookupData = yield api.get(url).then((res) => res.data);

        Object.entries(checkoutLookupData).forEach(([key, value]) => {
            checkoutLookupData[key] = parseFloat(value);
        });

        yield put(checkoutLookupActions.fetchCheckoutLookupDataSuccess(checkoutLookupData));
    } catch (e) {
        yield put(checkoutLookupActions.fetchCheckoutLookupDataError(e?.data?.message));
    }
}

export default function* checkoutLookupSaga() {
    yield all([takeLatest(checkoutLookupActions.FETCH_CHECKOUT_LOOKUP_DATA_START, fetchCheckoutLookup)]);
}
