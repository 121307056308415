import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {DatePicker} from 'antd';
import isNull from 'lodash/isNull';

const {RangePicker} = DatePicker;

const DateRanger = ({onChange}) => {
    const handleChange = useCallback(
        /**
         *
         * @param {moment.Moment[]} v
         */
        (v) => {
            if (isNull(v)) {
                onChange({start_date: null, end_date: null});
                return;
            }

            onChange({start_date: v[0].format('YYYY-MM-DD'), end_date: v[1].format('YYYY-MM-DD')});
        },
        [onChange],
    );

    return <RangePicker allowClear size="large" bordered={false} onChange={handleChange} format="YYYY-MM-DD" />;
};

DateRanger.propTypes = {
    onChange: PropTypes.func,
};

export default DateRanger;
