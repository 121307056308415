import {all, put, takeLeading} from 'redux-saga/effects';
import regionsActions from 'redux/regions/actions';
import {api} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';

function* fetchRegions() {
    try {
        const {data} = yield api.get(apiEndpoints.regions.get);

        yield put(regionsActions.fetchRegionsSuccess(data));
    } catch (error) {
        yield put(regionsActions.fetchRegionsFailure(error));
    }
}

function* fetchAllRegions() {
    try {
        const {data} = yield api.get(apiEndpoints.regions.all);

        yield put(regionsActions.fetchAllRegionsSuccess(data));
    } catch (error) {
        yield put(regionsActions.fetchAllRegionsFailure(error));
    }
}

export default function* regionSaga() {
    yield all([
        takeLeading(regionsActions.FETCH_REGIONS_START, fetchRegions),
        takeLeading(regionsActions.FETCH_ALL_REGIONS_START, fetchAllRegions),
    ]);
}
