import React from 'react';
import {Layout} from 'components/UI';

const getContainer = (props) => {
    return (
        <Layout className="container white-bg" {...props}>
            {props.children}
        </Layout>
    );
};

const Container = (props) => {
    return getContainer(props);
};

export default Container;
