export const publicPaths = {
    AUTH_CALLBACK: '/auth/callback/:clientId?',
    REGISTER: '/register', // redirects to sign_up
    PAGE_404: '/404',
    PAGE_500: '/500',
    SIGN_IN: '/sign-in',
    FEDERATED_SIGN_IN: '/sign-in/:token',
    SIGN_UP: '/sign-up',
    SIGN_UP_STEP_2: '/sign-up/2',
    SIGN_UP_SUCCESS: '/sign-up/success',
    TERMS_CONDITIONS: '/terms-and-conditions',
    VERIFY_EMAIL: '/verify',
    FORGOT_PASSWORD: '/password/reset/:username/:code',
};
