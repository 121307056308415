import {all, takeEvery, put} from 'redux-saga/effects';
import senderNamesActions from 'redux/senderNames/actions';
import {api} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';
import {getRoute} from 'library/helpers/routing';

import campaignsActions from 'redux/campaigns/actions';

function* fetchSenderNames({payload = 'message'}) {
    const messages = yield api.get(`${apiEndpoints.senderNames.get}?type=${payload}`);

    if (messages.status !== 200) return yield put(senderNamesActions.fetchError(messages.error));

    yield put(senderNamesActions.fetchSuccess(messages.data));
}

function* saveSenderNames({payload}) {
    try {
        const data = {...payload, default: !!payload.default};

        const value = payload.id
            ? yield api.put(getRoute(apiEndpoints.senderNames.update, {id: payload.id}), data)
            : yield api.post(apiEndpoints.senderNames.create, data);

        yield put(senderNamesActions.saveSuccess(value));
    } catch (error) {
        yield put(senderNamesActions.saveError(error));
    }
}

function* deleteSenderNames({payload}) {
    try {
        yield api.delete(getRoute(apiEndpoints.senderNames.delete, {id: payload}));
        yield put(senderNamesActions.deleteSuccess());
        yield put(senderNamesActions.fetchStart());

        yield put(campaignsActions.fetchCampaignsStart());
    } catch (error) {
        yield put(senderNamesActions.deleteError(error));
    }
}

function* clearDefaultSenderNames() {
    try {
        yield api.delete(getRoute(apiEndpoints.senderNames.clearDefault));
        yield put(senderNamesActions.clearDefaultSuccess());
        yield put(senderNamesActions.fetchStart());
    } catch (error) {
        yield put(senderNamesActions.clearDefaultError(error));
    }
}

export default function* senderNamesSaga() {
    yield all([
        takeEvery(senderNamesActions.FETCH_SENDER_NAMES_START, fetchSenderNames),
        takeEvery(senderNamesActions.SAVE_SENDER_NAMES_START, saveSenderNames),
        takeEvery(senderNamesActions.DELETE_SENDER_NAMES_START, deleteSenderNames),
        takeEvery(senderNamesActions.CLEAR_DEFAULT_SENDER_NAMES_START, clearDefaultSenderNames),
    ]);
}
