import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import Animate from 'rc-animate';
import {getImagePath} from 'library/helpers/utilities';
import {Col, Row, Typography, InputNumber, Button, confirm, FontAwesome, Space, Alert, Tag} from 'components/UI';
import {lang} from 'config/lang';
import {currencyFormatter, getCurrencySymbol} from 'library/helpers/format';
import orderSummaryActions from 'redux/orderSummary/actions';
import {usePrev} from 'hooks/utility/usePrev';
import {getOrderSummary} from 'redux/orderSummary/selectors';

const {Text, Link} = Typography;

const OrderItem = ({
    deletable = true,
    isSend = false,
    minQuantity = 0,
    quantityEditable = true,
    item,
    errors,
    storefront,
}) => {
    const order = useSelector(getOrderSummary);

    const initState = {
        defaultQuantity: item.quantity,
        quantity: item.quantity,
        loading: item.loading,
        deleting: false,
    };

    const dispatch = useDispatch();
    const [state, setState] = useState(initState);

    const handleChange = (quantity) => setState({...state, quantity});

    const handleUpdate = () => {
        // if the quantity is null or zero we'll assume they want to remove this
        // item form the cart so start the delete process
        if (!state.quantity || state.quantity === 0) {
            handleDelete();
            return;
        }

        dispatch(
            orderSummaryActions.updateQuantityStart({
                quantity: state.quantity,
                id: item.id,
            }),
        );

        setState({...state, loading: true});
    };
    const handleDelete = () => {
        const {id} = item;

        const handleDelete = async (id) => {
            setState({...state, deleting: true});
            dispatch(orderSummaryActions.deleteFromOrderSummaryStart(id));
        };

        confirm({
            title: lang.order.OrderSummary.confirm_delete.title,
            content: <Alert type="warning" message={lang.order.OrderSummary.confirm_delete.message} />,
            autoFocusButton: null,
            icon: false,
            transitionName: 'ant-zoom-big-fast',
            cancelText: lang['go_back'],
            okText: lang.order.OrderSummary.confirm_delete.ok,
            okType: 'primary',
            okButtonProps: {
                size: 'large',
                danger: true,
            },
            cancelButtonProps: {
                size: 'large',
            },
            onOk: () => handleDelete(id),
        });
    };

    const prevQuantity = usePrev(item.quantity);
    useEffect(() => {
        // quantity updated so set state items
        if (item.quantity !== prevQuantity) {
            setState((prevState) => ({
                ...prevState,
                defaultQuantity: item.quantity,
                loading: false,
                quantity: item.quantity,
            }));
        }
    }, [item.quantity, prevQuantity]);

    const prevErrors = usePrev(errors);
    useEffect(() => {
        if (prevErrors !== errors) {
            setState((prevState) => ({...prevState, loading: false}));
        }
    }, [prevErrors, errors]);

    const renderImage = (item) => {
        //TODO: Fix this so its more streamlined, the whole cart/order summary process needs reworking
        const image = item.card?.preview ?? item.saved_design_card_path ?? item.product_card_path;

        const cardPath = getImagePath(image, 'small');

        return (
            <img
                alt={item.name}
                width={100}
                height="auto"
                src={cardPath}
                style={{borderRadius: '0.5rem', border: 'solid 1px #e3e4e5', maxWidth: '100%'}}
            />
        );
    };

    const renderDetails = (item) => {
        const renderEditableField = () => {
            return (
                <Space size={8}>
                    Qty:
                    <Space size={12}>
                        <InputNumber
                            style={{
                                width: 100,
                            }}
                            key={`input-quantity-${item.id}`}
                            onChange={handleChange}
                            disabled={state.loading}
                            defaultValue={item.quantity}
                            min={minQuantity}
                            value={state.quantity}
                        />
                        {state.defaultQuantity !== state.quantity && (
                            <Button onClick={handleUpdate} loading={state.loading} size="small">
                                Update
                            </Button>
                        )}
                    </Space>
                </Space>
            );
        };

        return (
            <>
                <Space direction="vertical" size={8}>
                    <Space size={4} align="start">
                        <Text ellipsis={true} strong>
                            {getCurrencySymbol(item.currency)}
                            {item.denomination}
                        </Text>
                        <Text strong>{item.name}</Text>
                    </Space>
                    {storefront && (
                        <Space size={8}>
                            <Link href={storefront['SampleCatalogLink']} target="_blank">
                                <Space size={8}>
                                    {storefront.description}
                                    <FontAwesome icon="square-arrow-up-right" />
                                </Space>
                            </Link>
                        </Space>
                    )}
                    <Text>
                        {quantityEditable ? (
                            renderEditableField()
                        ) : (
                            <Space size={8}>
                                <Text>{lang.qty}:</Text>
                                {item.quantity > 0 ? (
                                    <Text>{item.quantity}</Text>
                                ) : (
                                    <Text disabled>{lang.add_recipients}</Text>
                                )}
                            </Space>
                        )}
                    </Text>

                    {!isSend && (
                        <Space direction="vertical" size={6}>
                            {item.has_carriers && (
                                <Text>
                                    <Space size={4} align="center">
                                        <Text>+</Text> <FontAwesome icon="envelope" />{' '}
                                        <Text>{lang.greeting_cards}</Text>
                                    </Space>
                                </Text>
                            )}

                            {quantityEditable && (
                                <Space>
                                    <Text>{lang['subtotal']}:</Text>

                                    {currencyFormatter(item.subtotal)}

                                    {item.discount_amount > 0 && (
                                        <Text
                                            type="secondary"
                                            style={{display: 'flex', alignSelf: 'center', fontSize: '14px'}}
                                        >
                                            <s>{currencyFormatter(item.base_subtotal, 2)}</s>
                                        </Text>
                                    )}

                                    {item.discount_amount > 0 && <Tag discountContext="order" />}
                                </Space>
                            )}

                            {deletable && (
                                <Button
                                    key={item.id + '-delete'}
                                    title={lang['update']}
                                    type="link"
                                    danger
                                    onClick={handleDelete}
                                    icon="trash-can"
                                    disabled={order.changing || order.adding}
                                >
                                    {lang['delete']}
                                </Button>
                            )}
                        </Space>
                    )}
                </Space>
            </>
        );
    };

    return (
        <Animate transitionLeave transitionName="ant-fade">
            {!state.deleting && item.sendTo !== null ? (
                <Row gutter={16}>
                    <Col span={7}>{renderImage(item)}</Col>
                    <Col span={17}>{renderDetails(item)}</Col>
                </Row>
            ) : null}
        </Animate>
    );
};

OrderItem.propTypes = {
    deletable: PropTypes.bool,
    dispatch: PropTypes.func,
    errors: PropTypes.any,
    isSend: PropTypes.bool,
    item: PropTypes.object.isRequired,
    minQuantity: PropTypes.number,
    quantityEditable: PropTypes.bool,
    storefront: PropTypes.object,
};

export default OrderItem;
