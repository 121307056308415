import {all, takeEvery, put} from 'redux-saga/effects';
import recipientsUploadsActions from 'redux/recipientsUpload/actions';
import {api} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';

export const LOCAL_STORE_IMPORT_DATA = 'gg-import-data';

function* setRecipients({payload}) {
    try {
        const response = yield api.post(apiEndpoints.recipients.upload, payload, {
            headers: {'content-type': 'multipart/form-data'},
        });

        yield put(recipientsUploadsActions.setRecipientsUploadSuccess(response.data));
    } catch (error) {
        yield put(recipientsUploadsActions.setRecipientsUploadFailure(error));
    }
}

function* saveRecipients({payload}) {
    // send to api
    const data = {
        ...payload,
    };

    const saved = yield api.post('/file/upload/recipients/approve', data);

    if (saved) {
        // remove storage and store
        yield put(recipientsUploadsActions.removeRecipientsUploadStart());
        yield put(recipientsUploadsActions.saveRecipientsUploadSuccess());
    }
}

function* removeRecipients() {
    yield sessionStorage.removeItem(LOCAL_STORE_IMPORT_DATA);
    yield put(recipientsUploadsActions.removeRecipientsUploadSuccess());
}

function* checkRecipientsJob() {
    try {
        const response = yield api.get(apiEndpoints.recipients.uploadCheck);

        return response.data.success
            ? yield put(recipientsUploadsActions.checkRecipientsJobRunningSuccess())
            : yield put(recipientsUploadsActions.checkRecipientsJobRunningFailure());
    } catch (error) {
        yield put(recipientsUploadsActions.checkRecipientsJobRunningFailure());
    }
}

function* clearRecipientsJob() {
    yield api.delete(apiEndpoints.recipients.upload);
    yield put(recipientsUploadsActions.clearRecipientsUploadJobSuccess());
}

function* fetchRecipients() {
    const data = yield JSON.parse(sessionStorage.getItem(LOCAL_STORE_IMPORT_DATA));

    try {
        yield put(recipientsUploadsActions.setRecipientsUploadStart(data));
        yield put(recipientsUploadsActions.removeRecipientsUploadSuccess());
    } catch (error) {
        yield put(recipientsUploadsActions.setRecipientsUploadStart(error));
    }
}

export default function* recipientGroupSaga() {
    yield all([
        takeEvery(recipientsUploadsActions.SET_RECIPIENTS_UPLOAD_DATA_START, setRecipients),
        takeEvery(recipientsUploadsActions.SAVE_RECIPIENTS_UPLOAD_DATA_START, saveRecipients),
        takeEvery(recipientsUploadsActions.FETCH_RECIPIENTS_UPLOAD_DATA_START, fetchRecipients),
        takeEvery(recipientsUploadsActions.REMOVE_RECIPIENTS_UPLOAD_DATA_START, removeRecipients),
        takeEvery(recipientsUploadsActions.CLEAR_RECIPIENTS_UPLOAD_JOB_START, clearRecipientsJob),
        takeEvery(recipientsUploadsActions.CHECK_RECIPIENTS_JOB_RUNNING_START, checkRecipientsJob),
    ]);
}
