const fundingAmountActions = {
  FETCH_FUNDING_AMOUNT_START: 'FETCH_FUNDING_AMOUNT_START',
  FETCH_FUNDING_AMOUNT_SUCCESS: 'FETCH_FUNDING_AMOUNT_SUCCESS',
  FETCH_FUNDING_AMOUNT_ERROR: 'FETCH_FUNDING_AMOUNT_ERROR',

  fetchFundingAmountStart: (payload) => ({
    type: fundingAmountActions.FETCH_FUNDING_AMOUNT_START,
    payload,
  }),
  fetchFundingAmountSuccess: (payload) => {
    return {
      type: fundingAmountActions.FETCH_FUNDING_AMOUNT_SUCCESS,
      payload,
    }
  },
  fetchFundingAmountError: (error) => ({
    type: fundingAmountActions.FETCH_FUNDING_AMOUNT_ERROR,
    payload: error,
  }),

  CLEAR_FUNDING_AMOUNT: 'CLEAR_FUNDING_AMOUNT',
    clearFundingAmount: () => ({
        type: fundingAmountActions.CLEAR_FUNDING_AMOUNT,
    }),
}

export default fundingAmountActions;
