import campaignCreditCardsActions from './actions';

import {LOGOUT} from 'redux/auth/auth.types';

const initialState = {
    data: null,
    error: null,
    loading: false,
    updating: false,
};
const campaignCreditCardsReducer = (state = initialState, action) => {
    switch (action.type) {
        case campaignCreditCardsActions.FETCH_CAMPAIGN_CREDIT_CARDS_DATA_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case campaignCreditCardsActions.FETCH_CAMPAIGN_CREDIT_CARDS_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null,
            };
        case campaignCreditCardsActions.FETCH_CAMPAIGN_CREDIT_CARDS_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case LOGOUT:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default campaignCreditCardsReducer;
