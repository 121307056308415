import signupActions from './actions';

const data = {
    email: undefined,
    first_name: undefined,
    last_name: undefined,
    password: undefined,
    password_confirmation: undefined,
    company_name: undefined,
    phone: undefined,
    phone_extension: undefined,
    terms_conditions: undefined,
    step1_complete: false,
    submittable: false,
};

const INITIAL_DATA = {
    data,
    loading: false,
    error: null,
};

const signupReducer = (state = INITIAL_DATA, action) => {
    switch (action.type) {
        case signupActions.SET_SIGNUP_DATA_START:
            return {
                ...action.payload,
                loading: false,
                error: false,
            };
        case signupActions.SET_SIGNUP_DATA_SUCCESS:
            return {
                ...action.payload,
                loading: false,
                error: false,
            };
        case signupActions.SET_SIGNUP_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
            };

        case signupActions.SAVE_SIGNUP_DATA_START:
            return {
                ...action.payload,
                loading: false,
                error: false,
            };
        case signupActions.SAVE_SIGNUP_DATA_SUCCESS:
            return {
                ...action.payload,
                loading: false,
                error: false,
            };
        case signupActions.SAVE_SIGNUP_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default signupReducer;
