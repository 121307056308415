const recipientActions = {
    RESET_RECIPIENT: 'RESET_RECIPIENT',
    reset: () => ({
        type: recipientActions.RESET_RECIPIENT,
    }),
    RESET_RECIPIENT_SUCCESS: 'RESET_RECIPIENT_SUCCESS',
    resetSuccess: () => ({
        type: recipientActions.RESET_RECIPIENT_SUCCESS,
    }),
    FETCH_RECIPIENT_DATA_START: 'FETCH_RECIPIENT_DATA_START',
    FETCH_RECIPIENT_DATA_SUCCESS: 'FETCH_RECIPIENT_DATA_SUCCESS',
    FETCH_RECIPIENT_DATA_FAILURE: 'FETCH_RECIPIENT_DATA_FAILURE',
    fetchRecipientStart: (recipientId) => ({
        type: recipientActions.FETCH_RECIPIENT_DATA_START,
        payload: recipientId,
    }),
    fetchRecipientSuccess: (recipient) => {
        return {
            type: recipientActions.FETCH_RECIPIENT_DATA_SUCCESS,
            payload: recipient,
        };
    },
    fetchRecipientError: (error) => ({
        type: recipientActions.FETCH_RECIPIENT_DATA_FAILURE,
        payload: error,
    }),

    UPDATE_RECIPIENT_DATA_START: 'UPDATE_RECIPIENT_DATA_START',
    UPDATE_RECIPIENT_DATA_SUCCESS: 'UPDATE_RECIPIENT_DATA_SUCCESS',
    UPDATE_RECIPIENT_DATA_FAILURE: 'UPDATE_RECIPIENT_DATA_FAILURE',
    updateRecipientStart: (payload) => ({
        type: recipientActions.UPDATE_RECIPIENT_DATA_START,
        payload,
    }),
    updateRecipientSuccess: () => {
        return {
            type: recipientActions.UPDATE_RECIPIENT_DATA_SUCCESS,
        };
    },
    updateRecipientFailure: (error) => ({
        type: recipientActions.UPDATE_RECIPIENT_DATA_FAILURE,
        payload: error,
    }),

    TOGGLE_RECIPIENT_STATUS_START: 'TOGGLE_RECIPIENT_STATUS_START',
    TOGGLE_RECIPIENT_STATUS_SUCCESS: 'TOGGLE_RECIPIENT_STATUS_SUCCESS',
    TOGGLE_RECIPIENT_STATUS_FAILURE: 'TOGGLE_RECIPIENT_STATUS_FAILURE',
    toggleRecipientStatusStart: (payload) => ({
        type: recipientActions.TOGGLE_RECIPIENT_STATUS_START,
        payload,
    }),

    toggleRecipientStatusSuccess: () => {
        return {
            type: recipientActions.TOGGLE_RECIPIENT_STATUS_SUCCESS,
        };
    },

    toggleRecipientStatusFailure: (error) => ({
        type: recipientActions.TOGGLE_RECIPIENT_STATUS_FAILURE,
        payload: error,
    }),

    DELETE_RECIPIENT_DATA_START: 'DELETE_RECIPIENT_DATA_START',
    DELETE_RECIPIENT_DATA_SUCCESS: 'DELETE_RECIPIENT_DATA_SUCCESS',
    DELETE_RECIPIENT_DATA_FAILURE: 'DELETE_RECIPIENT_DATA_FAILURE',
    deleteRecipientStart: (payload) => ({
        type: recipientActions.DELETE_RECIPIENT_DATA_START,
        payload,
    }),
    deleteRecipientSuccess: () => {
        return {
            type: recipientActions.DELETE_RECIPIENT_DATA_SUCCESS,
        };
    },
    deleteRecipientFailure: (error) => ({
        type: recipientActions.DELETE_RECIPIENT_DATA_FAILURE,
        payload: error,
    }),
};

export default recipientActions;
