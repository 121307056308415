import React from 'react';
import {Tree as AntTree} from 'antd';
import FontAwesome from 'components/UI/FontAwesome';
import PropTypes from 'prop-types';

const {DirectoryTree} = AntTree;

export const TreeDirectory = (props) => {
    return (
        <DirectoryTree
            {...props}
            switcherIcon={<FontAwesome icon="caret-down" type="solid" />}
            showIcon={props.showIcon}
        />
    );
};

export default TreeDirectory;

TreeDirectory.defaultProps = {
    showIcon: false,
};

TreeDirectory.propTypes = {
    showIcon: PropTypes.bool.isRequired,
};
