import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Typography, Loading} from 'components/UI';
import Storefront from 'components/Storefront';
import CatalogModal from './modules/CatalogModal';
import {useSelector} from 'react-redux';
import {getCompanyPrimaryProducts} from 'redux/primaryProducts/selectors';

const {Link} = Typography;

// TODO: Flesh out the UX for this component
const Catalogs = ({products, disabled}) => {
    const companyPrimaryProducts = useSelector(getCompanyPrimaryProducts);

    const [campaignProductsModalVisible, setCampaignProductsModalVisible] = useState(false);

    const setCampaignProductsModalVisibleCallback = useMemo(() => {
        return {
            bool: (bool) => setCampaignProductsModalVisible(bool),
            true: () => setCampaignProductsModalVisible(true),
            false: () => setCampaignProductsModalVisible(false),
        };
    }, [setCampaignProductsModalVisible]);

    const storefront = useMemo(
        () => companyPrimaryProducts?.find((companyProduct) => companyProduct.id === products[0]),
        [products, companyPrimaryProducts],
    );

    if (!products?.length) return <Loading />;
    if (products.length === 1) return <Storefront storefront={storefront} />;

    return (
        <>
            <CatalogModal
                productIds={products}
                visible={campaignProductsModalVisible}
                setVisible={setCampaignProductsModalVisibleCallback['bool']}
            />

            <Link disabled={disabled} onClick={setCampaignProductsModalVisibleCallback['true']}>
                Show Catalogs
            </Link>
        </>
    );
};

Catalogs.defaultProps = {
    disabled: false,
};

Catalogs.propTypes = {
    products: PropTypes.arrayOf(PropTypes.number).isRequired,
    disabled: PropTypes.bool,
};

export default Catalogs;
