const recipientCampaignActions = {
  SET_RECIPIENT_CAMPAIGN_DATA_START: 'SET_RECIPIENT_CAMPAIGN_DATA_START',
  SET_RECIPIENT_CAMPAIGN_DATA_SUCCESS: 'SET_RECIPIENT_CAMPAIGN_DATA_SUCCESS',
  SET_RECIPIENT_CAMPAIGN_DATA_FAILURE: 'SET_RECIPIENT_CAMPAIGN_DATA_FAILURE',
  setRecipientCampaignStart: (payload) => ({
    type: recipientCampaignActions.SET_RECIPIENT_CAMPAIGN_DATA_START,
    payload,
  }),

  setRecipientCampaignSuccess: () => {
    return {
      type: recipientCampaignActions.SET_RECIPIENT_CAMPAIGN_DATA_SUCCESS,
    }
  },

  setRecipientCampaignFailure: (error) => ({
    type: recipientCampaignActions.SET_RECIPIENT_CAMPAIGN_DATA_FAILURE,
    payload: error,
  }),

  UPDATE_RECIPIENT_CAMPAIGN_DATA_START: 'UPDATE_RECIPIENT_CAMPAIGN_DATA_START',
  UPDATE_RECIPIENT_CAMPAIGN_DATA_SUCCESS:
    'UPDATE_RECIPIENT_CAMPAIGN_DATA_SUCCESS',
  UPDATE_RECIPIENT_CAMPAIGN_DATA_FAILURE:
    'UPDATE_RECIPIENT_CAMPAIGN_DATA_FAILURE',
  updateRecipientCampaignStart: () => ({
    type: recipientCampaignActions.UPDATE_RECIPIENT_CAMPAIGN_DATA_START,
  }),

  updateRecipientCampaignSuccess: (payload) => {
    return {
      type: recipientCampaignActions.UPDATE_RECIPIENT_CAMPAIGN_DATA_SUCCESS,
      payload,
    }
  },

  updateRecipientCampaignFailure: (error) => ({
    type: recipientCampaignActions.UPDATE_RECIPIENT_CAMPAIGN_DATA_FAILURE,
    payload: error,
  }),
}

export default recipientCampaignActions
