import {useLocation} from 'react-router-dom';
import React, {useMemo} from 'react';
import {isLoginAsPath, isSignUpPath} from 'library/helpers/routing';
import Alerts from 'components/NotificationBar';
import {MainNav} from 'components/Layout';
import {CartDrawer} from 'components/Cart';

const AuthedComponents = () => {
    const {pathname} = useLocation();

    const showTopBar = useMemo(() => !isLoginAsPath(pathname) && !isSignUpPath(pathname), [pathname]);

    if (!showTopBar) return null;

    return (
        <>
            <Alerts />
            <MainNav />
            <CartDrawer />
        </>
    );
};

export default AuthedComponents;
