import {all, put, takeEvery} from 'redux-saga/effects';
import fundingItemActions from './actions';
import {apiEndpoints} from 'config/api';
import {api} from 'library/helpers/api';
import {getRoute} from 'library/helpers/routing';
import {processError} from 'library/helpers/response';

function* fetchFundingItem({payload}) {
    try {
        let url = getRoute(apiEndpoints.funding.get, {id: payload});

        const fundingItem = yield api.get(url).then((response) => response.data);
        yield put(fundingItemActions.fetchFundingItemSuccess(fundingItem));
    } catch (error) {
        yield put(fundingItemActions.fetchFundingItemError(processError(error)));
    }
}

function* updateFundingItem({payload}) {
    try {
        let url = getRoute(apiEndpoints.funding.updateNotes, {id: payload.id});

        const updatedFields = yield api.put(url, payload).then((response) => {
            return {
                reference_number: response.data.data.reference_number,
                customer_notes: response.data.data.customer_notes,
                message: response.data.message,
            };
        });

        yield put(fundingItemActions.updateFundingItemSuccess(updatedFields));
    } catch (error) {
        yield put(fundingItemActions.updateFundingItemError(processError(error)));
    }
}

export default function* fundingItemSaga() {
    yield all([
        takeEvery(fundingItemActions.FETCH_FUNDING_ITEM_START, fetchFundingItem),
        takeEvery(fundingItemActions.UPDATE_FUNDING_ITEM_START, updateFundingItem),
    ]);
}
