import {Button} from 'components/UI';
import React from 'react';
import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';
import parse from 'html-react-parser';
import {ALLOWED_HTML_TAGS} from 'config/constants';
import {routes} from 'config/routes';

const NotificationDescription = ({payload}) => {
    return (
        <>
            <div className={'notifications-message'}>
                {parse(
                    sanitizeHtml(payload?.web.replace(/<br>|<br \/>|<br\/>/gi, ' '), {
                        allowedTags: ALLOWED_HTML_TAGS,
                    }),
                )}
            </div>

            <Button type="link" href={routes.private.paths.NOTIFICATIONS}>
                Go to notifications
            </Button>
        </>
    );
};

NotificationDescription.propTypes = {
    payload: PropTypes.object,
};

export default NotificationDescription;
