import React, {useCallback, useEffect, useState} from 'react';
import {Checkbox, Col, Row, Space, Form} from 'antd';
import {lang} from 'config/lang';
import {Input, Popover, Select, Typography} from 'components/UI';
import FormFooterButtons from 'components/Modal/FormFooterButtons';
import {formatCreditCardNumber, getCreditCardType} from 'containers/Settings/PaymentsSettings/config/creditCardUtils';
import {useDispatch, useSelector} from 'react-redux';
import {getCreditCards} from 'redux/selectors';
import PropTypes from 'prop-types';
import useReaction from 'hooks/utility/useReaction';
import CreditCardIcon from 'components/UI/CreditCard';
import {months, years} from 'config/constants';
import {usePrev} from 'hooks/utility/usePrev';
import useAutoFocus from 'hooks/useAutoFocus';
import creditCardsActions from 'redux/creditCards/actions';

const {Paragraph} = Typography;

const initialValues = {
    save: true,
    is_default: false,
    card_number: '',
    card_cvv: '',
    card_expiration_month: 1,
    card_expiration_year: 2025,
    first_name: '',
    last_name: '',
    address_1: '',
    zip: '',
    display_name: '',
};

const formKeys = Object.keys(initialValues).reduce(
    (acc, key) => ({
        ...acc,
        [key]: key,
    }),
    {},
);

const AddCreditCardForm = ({campaignId, saveCard = false, onCancel}) => {
    const creditCards = useSelector(getCreditCards);

    const dispatch = useDispatch();

    const [form] = Form.useForm();
    const autoFocusRef = useAutoFocus();

    const cardNumberObserver = Form.useWatch(formKeys.card_number, form);
    const saveCardObserver = Form.useWatch(formKeys.save, form);

    const [cardNumber, setCardNumber] = useState('');
    const [creditCardBrand, setCreditCardBrand] = useState(null);
    const [cvvLength, setCvvLength] = useState(4);
    const [cardType, setCardType] = useState(null);

    const handleSubmit = useCallback(
        (formValues) => {
            const payload = {
                ...formValues,
                default: formValues.is_default,
                card_brand: getCreditCardType(formValues.card_number),
                zip: formValues.zip.replace(/-.*/, ''),
            };

            if (campaignId) {
                payload.campaign_id = campaignId;
            }

            dispatch(creditCardsActions.createCreditCardsDataStart(payload));
        },
        [campaignId, dispatch],
    );

    const {alertErrorElement: creditCardError} = useReaction({
        value: creditCards?.error?.data,
        condition: creditCards?.error?.data,
        content: creditCards?.error?.data?.message ?? 'Something went wrong.',
        react: false,
        type: 'error',
    });

    const prevUpdating = usePrev(creditCards.updating);

    useEffect(() => {
        if (creditCards.updating) return;
        if (!prevUpdating) return;
        if (creditCards.error) return;

        onCancel();
    }, [creditCards.error, creditCards.updating, onCancel, prevUpdating]);

    const prevCardType = usePrev(cardType);

    useEffect(() => {
        if (!cardNumberObserver) return;

        const number = cardNumberObserver.replace(/\s/g, '');

        if (isNaN(number)) return;

        const formattedNumber = formatCreditCardNumber(number);

        setCardNumber(formattedNumber);

        form.setFieldsValue({card_number: formattedNumber});
        form.validateFields([formKeys.card_number]);

        const newCardType = getCreditCardType(number);

        setCvvLength(cardType === 'amex' ? 4 : 3);
        setCreditCardBrand(newCardType === 'amex' ? 'amex' : newCardType);
        setCardType(newCardType);
    }, [cardNumberObserver, cardType, form]);

    useEffect(() => {
        if (prevCardType === null) return;
        if (cardType === prevCardType) return;

        form.setFieldsValue({card_cvv: ''});
    }, [cardType, form, prevCardType]);

    useEffect(() => {
        if (saveCardObserver) return;

        form.setFieldsValue({is_default: false, display_name: ''});
    }, [form, saveCardObserver]);

    return (
        <Space direction="vertical">
            {creditCardError}

            <Paragraph>{saveCard ? lang.funding.credit_card.desc_alt : lang.funding.credit_card.desc}</Paragraph>

            <Form layout="vertical" form={form} onFinish={handleSubmit} initialValues={initialValues}>
                <Space direction="vertical">
                    <Form.Item noStyle hidden name={formKeys.save}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name={formKeys.card_number}
                        label={lang.funding.credit_card.number}
                        required
                        rules={[
                            {required: true, message: 'Credit card number is required'},
                            {
                                message: lang.invalidCreditCardType,
                                validator: (_, value) => {
                                    if (value === '') return Promise.resolve();

                                    if (getCreditCardType(value) !== null) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject();
                                },
                            },
                        ]}
                    >
                        <Input
                            ref={autoFocusRef}
                            value={cardNumber}
                            size="large"
                            prefix={
                                <CreditCardIcon
                                    card={creditCardBrand === null ? 'generic' : creditCardBrand}
                                    size={2}
                                />
                            }
                        />
                    </Form.Item>

                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name={formKeys.card_expiration_month}
                                required
                                label={lang.funding.credit_card.month}
                                rules={[{required: true, message: 'The month is required'}]}
                            >
                                <Select options={months} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name={formKeys.card_expiration_year}
                                required
                                label={lang.funding.credit_card.year}
                                rules={[{required: true, message: 'The year is required'}]}
                            >
                                <Select options={years} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name={formKeys.card_cvv}
                                required
                                label={lang.funding.credit_card.cvv}
                                rules={[
                                    {required: true, message: 'The cvv is required'},
                                    {
                                        message: lang.validation.cvv,
                                        validator: (_, value) => {
                                            if (value.length === cvvLength) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject();
                                        },
                                    },
                                ]}
                            >
                                <Input maxLength={cvvLength} placeholder="CVV" autoComplete="off" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label={lang['first_name']}
                                name={formKeys.first_name}
                                required
                                rules={[{required: true, message: 'The first name is required'}]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={lang['last_name']}
                                name={formKeys.last_name}
                                required
                                rules={[{required: true, message: 'The last name is required'}]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        label={lang['address_street']}
                        name={formKeys.address_1}
                        required
                        rules={[{required: true, message: 'The address is required'}]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={lang['postal_code']}
                        name={formKeys.zip}
                        required
                        rules={[
                            {required: true, message: 'The postal code is required'},
                            {
                                message: lang.validation.zip_code,
                                validator: (rule, value) => {
                                    if (value.length === 0 || (value.length > 2 && value.length < 12)) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(lang.validation.zip_code);
                                },
                            },
                        ]}
                    >
                        <Input maxLength={12} />
                    </Form.Item>

                    {saveCardObserver && (
                        <Form.Item
                            label={
                                <>
                                    {lang.nickname}
                                    <Popover
                                        info
                                        content="Make your payment method easily identifiable by adding a unique nickname"
                                    />
                                </>
                            }
                            name={formKeys.display_name}
                            rules={[
                                {
                                    max: 25,
                                    message: 'The nickname must be less than 25 characters',
                                },
                            ]}
                        >
                            <Input allowClear maxLength={25} />
                        </Form.Item>
                    )}

                    {saveCard && (
                        <>
                            <Form.Item name={formKeys.save} style={{marginBottom: 0}} valuePropName="checked">
                                <Checkbox>{lang.funding.credit_card.save}</Checkbox>
                            </Form.Item>
                        </>
                    )}

                    {saveCardObserver && (
                        <Form.Item name={formKeys.is_default} style={{marginBottom: 0}} valuePropName="checked">
                            <Checkbox>{lang.funding.credit_card.default}</Checkbox>
                        </Form.Item>
                    )}

                    <FormFooterButtons
                        loading={creditCards.updating}
                        onCancel={onCancel}
                        okText={lang.button.add_credit_card}
                    />
                </Space>
            </Form>
        </Space>
    );
};

AddCreditCardForm.propTypes = {
    campaignId: PropTypes.number,
    onCancel: PropTypes.func,
    saveCard: PropTypes.bool,
};

export default AddCreditCardForm;
