export const PRODUCTS_STORE = 'prods';
export const CAMPAIGN_CREDIT_CARDS_STORE = 'ccc';
export const ORDER_SUMMARY_STORE = 'order_sum';
export const CAMPAIGN_CUSTOM_DESIGNS_STORE = 'cust_des';
export const SHIPPING_RATES_STORE = 'rates';
export const CREDIT_CARD_STORE = 'ccs';
export const SEND_STORE = 'send_d';
export const SIGN_UP_MODAL = 'sign_up_modal';
export const DASHBOARD_JOYRIDE_STORE = 'dash_joyride';
export const DASHBOARD_NEW_USER_PROMPT = 'new_user_prompt';
export const INTENDED_URL = 'intended';
export const INVITE_KEY = 'invite_key';
export const SITE_DATA = 'sdata';
export const NEW_TEAM = 'nteam';
export const DEPLOY_ID = 'dpid';
export const LAST_REFRESH = 'last_refresh';
export const LARAVEL_AUTH_STATUS = 'larals';
export const AWS_FEDERATED_INFO = 'aws-amplify-federatedInfo';
export const SHIPPING_ALERT_DISMISSED_TIME = 'shipping_alert_dismissed_time';
export const PROCUREMENT_FUNDING_DISMISSED_TIME = 'procurement_funding_dismissed_time';
export const PROCUREMENT_PHYSICAL_DISMISSED_TIME = 'procurement_physical_dismissed_time';
export const PROCUREMENT_DIGITAL_DISMISSED_TIME = 'procurement_digital_dismissed_time';
export const RETRY_WINDOW_RELOAD = 'retry_window_reload';
export const BUILD_UPDATE_FAILED = 'build_update_failed';
export const HUBSPOT_REQUESTED_INTEGRATION = 'hubspot_requested_integration';
