import modalsActions from './actions';
const modalsReducer = (state = {}, action) => {
    switch (action.type) {
        case modalsActions.OPEN_MODAL:
            return {
                ...state,
                [action.payload.modalKey]: {
                    open: true,
                },
            };

        case modalsActions.CLOSE_MODAL:
            return {
                ...state,
                [action.payload.modalKey]: {
                    open: false,
                },
            };
        default:
            return state;
    }
};

export default modalsReducer;
