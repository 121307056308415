import {createSelector} from 'reselect';
import {getDefaultState} from 'redux/forms/config';
import {SEND_FORM} from 'config/forms';
import {sendValidator} from 'redux/forms/validators/send';

export const getForm = createSelector(
    [(state) => state.forms, (state) => state.profile, (state) => state.campaigns.list, (state, key) => key],
    (formsState, profile, campaigns, key) => {
        const form = formsState?.data?.[key] ?? getDefaultState(key, formsState, {campaigns, profile});

        switch (key) {
            case SEND_FORM:
                return sendValidator(form, SEND_FORM, {profile, campaigns});

            default:
                return form;
        }
    },
);
