import {api} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';
import {all, put, takeEvery} from 'redux-saga/effects';
import couponActions from './actions';
import {getRoute} from 'library/helpers/routing';
import orderSummaryActions from 'redux/orderSummary/actions';

function* fetchCoupon({payload}) {
    const url = getRoute(apiEndpoints.coupons.get, payload);

    try {
        const coupon = yield api.get(url).then((res) => res.data);

        yield put(orderSummaryActions.modifyFormDataItemsStart({coupon_id: coupon.id}));
        yield put(couponActions.fetchCouponSuccess(coupon));
    } catch (e) {
        yield put(couponActions.fetchCouponError(e.data.message));
    }
}

function* clearCoupon() {
    yield put(orderSummaryActions.modifyFormDataItemsStart({coupon_id: null}));
}

export default function* couponSaga() {
    yield all([
        takeEvery(couponActions.FETCH_COUPON_START, fetchCoupon),
        takeEvery(couponActions.CLEAR_COUPON, clearCoupon),
    ]);
}
