export const stripLeading = (str, replaceCharacter = '0') =>
    String(str ?? '').replace(new RegExp(`^${replaceCharacter}+`), '');

export const middleTrim = (string, max, length = null) => {
    if (!length) length = string.length;
    if (length <= max) return string;

    const overflow = length - max;
    const trimDifferential = Math.round(overflow / 2);

    const start = string.slice(0, string.length / 2 - trimDifferential);
    const end = string.slice(string.length / 2 + trimDifferential);

    return `${start}...${end}`;
};

export const trimEmail = (email) => {
    const address = email.split('@')[0];
    const domain = email.split('@')[1];

    return `${middleTrim(address, 30, email.length)}@${domain}`;
};
