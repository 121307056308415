const contactPropertiesActions = {
    FETCH_CONTACT_PROPERTIES_DATA_START: 'FETCH_CONTACT_PROPERTIES_DATA_START',
    FETCH_CONTACT_PROPERTIES_DATA_SUCCESS: 'FETCH_CONTACT_PROPERTIES_DATA_SUCCESS',
    FETCH_CONTACT_PROPERTIES_DATA_FAILURE: 'FETCH_CONTACT_PROPERTIES_DATA_FAILURE',

    fetchContactPropertiesStart: () => {
        return {
            type: contactPropertiesActions.FETCH_CONTACT_PROPERTIES_DATA_START,
        };
    },

    fetchContactPropertiesSuccess: (contactProperties) => {
        return {
            type: contactPropertiesActions.FETCH_CONTACT_PROPERTIES_DATA_SUCCESS,
            payload: contactProperties,
        };
    },
    fetchContactPropertiesError: (error) => ({
        type: contactPropertiesActions.FETCH_CONTACT_PROPERTIES_DATA_FAILURE,
        payload: error,
    }),
};

export default contactPropertiesActions;
