import primaryProductsActions from './actions';

const initialState = {
    all: {
        data: [],
        loading: false,
        loaded: false,
        error: null,
    },
    company: {
        data: [],
        loading: false,
        loaded: false,
        error: null,
    },
};

const primaryProductsReducer = (state = initialState, action) => {
    switch (action.type) {
        case primaryProductsActions.FETCH_COMPANY_PRIMARY_PRODUCTS_DATA_START:
            return {
                ...state,
                company: {
                    data: [],
                    loading: true,
                    loaded: false,
                    error: null,
                },
            };

        case primaryProductsActions.FETCH_COMPANY_PRIMARY_PRODUCTS_DATA_SUCCESS:
            return {
                ...state,
                company: {
                    data: action.payload,
                    loading: false,
                    loaded: true,
                    error: null,
                },
            };

        case primaryProductsActions.FETCH_COMPANY_PRIMARY_PRODUCTS_DATA_FAILURE:
            return {
                ...state,
                company: {
                    data: [],
                    loading: false,
                    loaded: false,
                    error: action.payload,
                },
            };

        case primaryProductsActions.FETCH_ALL_PRIMARY_PRODUCTS_DATA_START:
            return {
                ...state,
                all: {
                    data: [],
                    loading: true,
                    loaded: false,
                    error: null,
                },
            };

        case primaryProductsActions.FETCH_ALL_PRIMARY_PRODUCTS_DATA_SUCCESS:
            return {
                ...state,
                all: {
                    data: action.payload,
                    loading: false,
                    loaded: true,
                    error: null,
                },
            };

        case primaryProductsActions.FETCH_ALL_PRIMARY_PRODUCTS_DATA_FAILURE:
            return {
                ...state,
                all: {
                    data: [],
                    loading: false,
                    loaded: false,
                    error: action.payload,
                },
            };

        default:
            return state;
    }
};

export default primaryProductsReducer;
