import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {Select as AntSelect, Space} from 'antd';
import Divider from 'components/UI/Divider';

import {FontAwesome} from 'components/UI';
import {getReactElementInnerText} from 'library/helpers/utilities';

const defaultHandleFilterOption = (input, option) =>
    getReactElementInnerText(option?.label).toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    getReactElementInnerText(option?.value).toLowerCase().indexOf(input.toLowerCase()) >= 0;

// eslint-disable-next-line react/display-name
export const Select = forwardRef(({dropdownRender, options, size, showSearch, filterOption, footer, ...rest}, ref) => {
    return (
        <AntSelect
            {...rest}
            disabled={rest.disabled || rest.loading}
            options={options}
            ref={ref}
            size={size ? size : 'large'}
            suffixIcon={
                rest.loading ? (
                    <FontAwesome type="solid" icon="spinner-third" spin size="xs" />
                ) : (
                    <FontAwesome type="solid" icon="chevron-down" size="xs" />
                )
            }
            showSearch={showSearch || (options?.length && options.length > 100)}
            filterOption={filterOption || defaultHandleFilterOption}
            dropdownRender={(menu) => {
                if (dropdownRender) return dropdownRender(menu);

                return footer ? (
                    <>
                        {menu}
                        <Divider margin={false} />
                        <Space className="select-footer" align="center">
                            {footer}
                        </Space>
                    </>
                ) : (
                    [menu]
                );
            }}
        />
    );
});

const {Option} = AntSelect;

Select.propTypes = {
    disabled: PropTypes.bool,
    dropdownRender: PropTypes.func,
    filterOption: PropTypes.any,
    loading: PropTypes.bool,
    options: PropTypes.any,
    footer: PropTypes.node,
    showSearch: PropTypes.bool,
    size: PropTypes.string,
};

export default Select;
export {Option};
