import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {FormikConsumer} from 'formik';
import {Form} from 'components/UI';
import {Prompt} from 'react-router';
import {lang} from 'config/lang';
import isEqual from 'react-fast-compare';

let prevSavedFormProps, savedFormProps;

const FormikForm = (props) => {
    const {fieldLayout = 'vertical', promptIfDirty = false, onChange, layout, name, children} = props;

    useEffect(() => {
        if (onChange && savedFormProps && prevSavedFormProps && !isEqual(savedFormProps, prevSavedFormProps))
            onChange();
    }, [onChange]);

    return (
        <FormikConsumer>
            {(formProps) => {
                // append custom properties to formProps for the Field items
                formProps.layout = layout;
                formProps.fieldLayout = fieldLayout;
                formProps.promptIfDirty = promptIfDirty;

                // Update component-level prop variables for change hook
                if (onChange) {
                    prevSavedFormProps = savedFormProps;
                    savedFormProps = formProps?.values;
                }

                return (
                    <>
                        {formProps.promptIfDirty && <Prompt when={formProps.dirty} message={lang.form.prompt.dirty} />}
                        <Form
                            onFinish={formProps.handleSubmit}
                            onReset={formProps.handleSubmit}
                            name={name}
                            layout={fieldLayout}
                            initialValues={{layout: fieldLayout}}
                        >
                            {children}
                        </Form>
                    </>
                );
            }}
        </FormikConsumer>
    );
};

FormikForm.propTypes = {
    children: PropTypes.any,
    fieldLayout: PropTypes.any,
    layout: PropTypes.any,
    name: PropTypes.any,
    promptIfDirty: PropTypes.bool,
    onChange: PropTypes.func,
};

export default FormikForm;
