const procurementActions = {
    SET_REDIRECT_URL: 'SET_REDIRECT_URL',
    setRedirectUrl: (payload) => ({
        type: procurementActions.SET_REDIRECT_URL,
        payload,
    }),
    RESET_REDIRECT_URL: 'RESET_REDIRECT_URL',
    resetRedirectUrl: () => ({
        type: procurementActions.RESET_REDIRECT_URL,
    }),
};
export default procurementActions;
