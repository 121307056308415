// Generic constants

import range from 'lodash/range';
import {ggDate} from 'library/helpers/date';

export const ALLOWED_HTML_TAGS = ['br', 'p', 'a', 'strong', 'b', 'i', 'em', 'u', 'ul', 'ol', 'li'];

export const DEFAULT_QUERY = {
    status: '',
    value: '',
    page: '1',
    per_page: '25',
    sort_direction: 'asc',
};

export const SYSTEM_ENVIRONMENT = {
    PRODUCTION: 'production',
    SANDBOX: 'sandbox',
    STAGING: 'staging',
    DEVELOPMENT: 'development',
    TEST: 'test',
};

export const US_STATES = [
    {
        value: 'AL',
        label: 'Alabama',
    },
    {
        value: 'AK',
        label: 'Alaska',
    },
    {
        value: 'AZ',
        label: 'Arizona',
    },
    {
        value: 'AR',
        label: 'Arkansas',
    },
    {
        value: 'CA',
        label: 'California',
    },
    {
        value: 'CO',
        label: 'Colorado',
    },
    {
        value: 'CT',
        label: 'Connecticut',
    },
    {
        value: 'DE',
        label: 'Delaware',
    },
    {
        value: 'DC',
        label: 'District Of Columbia',
    },
    {
        value: 'FL',
        label: 'Florida',
    },
    {
        value: 'GA',
        label: 'Georgia',
    },
    {
        value: 'HI',
        label: 'Hawaii',
    },
    {
        value: 'ID',
        label: 'Idaho',
    },
    {
        value: 'IL',
        label: 'Illinois',
    },
    {
        value: 'IN',
        label: 'Indiana',
    },
    {
        value: 'IA',
        label: 'Iowa',
    },
    {
        value: 'KS',
        label: 'Kansas',
    },
    {
        value: 'KY',
        label: 'Kentucky',
    },
    {
        value: 'LA',
        label: 'Louisiana',
    },
    {
        value: 'ME',
        label: 'Maine',
    },
    {
        value: 'MD',
        label: 'Maryland',
    },
    {
        value: 'MA',
        label: 'Massachusetts',
    },
    {
        value: 'MI',
        label: 'Michigan',
    },
    {
        value: 'MN',
        label: 'Minnesota',
    },
    {
        value: 'MS',
        label: 'Mississippi',
    },
    {
        value: 'MO',
        label: 'Missouri',
    },
    {
        value: 'MT',
        label: 'Montana',
    },
    {
        value: 'NE',
        label: 'Nebraska',
    },
    {
        value: 'NV',
        label: 'Nevada',
    },
    {
        value: 'NH',
        label: 'New Hampshire',
    },
    {
        value: 'NJ',
        label: 'New Jersey',
    },
    {
        value: 'NM',
        label: 'New Mexico',
    },
    {
        value: 'NY',
        label: 'New York',
    },
    {
        value: 'NC',
        label: 'North Carolina',
    },
    {
        value: 'ND',
        label: 'North Dakota',
    },
    {
        value: 'OH',
        label: 'Ohio',
    },
    {
        value: 'OK',
        label: 'Oklahoma',
    },
    {
        value: 'OR',
        label: 'Oregon',
    },
    {
        value: 'PA',
        label: 'Pennsylvania',
    },
    {
        value: 'PR',
        label: 'Puerto Rico',
    },
    {
        value: 'RI',
        label: 'Rhode Island',
    },
    {
        value: 'SC',
        label: 'South Carolina',
    },
    {
        value: 'SD',
        label: 'South Dakota',
    },
    {
        value: 'TN',
        label: 'Tennessee',
    },
    {
        value: 'TX',
        label: 'Texas',
    },
    {
        value: 'UT',
        label: 'Utah',
    },
    {
        value: 'VT',
        label: 'Vermont',
    },
    {
        value: 'VA',
        label: 'Virginia',
    },
    {
        value: 'WA',
        label: 'Washington',
    },
    {
        value: 'WV',
        label: 'West Virginia',
    },
    {
        value: 'WI',
        label: 'Wisconsin',
    },
    {
        value: 'WY',
        label: 'Wyoming',
    },
];

export const CA_STATES = [
    {
        value: 'AB',
        label: 'Alberta',
    },
    {
        value: 'BC',
        label: 'British Columbia',
    },
    {
        value: 'MB',
        label: 'Manitoba',
    },
    {
        value: 'NB',
        label: 'New Brunswick',
    },
    {
        value: 'NL',
        label: 'Newfoundland and Labrador',
    },
    {
        value: 'NT',
        label: 'Northwest Territories',
    },
    {
        value: 'NS',
        label: 'Nova Scotia',
    },
    {
        value: 'NU',
        label: 'Nunavut',
    },
    {
        value: 'ON',
        label: 'Ontario',
    },
    {
        value: 'PE',
        label: 'Prince Edward Island',
    },
    {
        value: 'QC',
        label: 'Quebec',
    },
    {
        value: 'SK',
        label: 'Saskatchewan',
    },
    {
        value: 'YT',
        label: 'Yukon',
    },
];

export const months = [
    ...range(12).map((i) => Object.fromEntries(['value', 'label'].map((key) => [key, String(i + 1).padStart(2, '0')]))),
];

export const days = [...range(1, 32).map((num) => ({value: num, label: num}))];

export const years = [
    ...range(25).map((i) =>
        Object.fromEntries(['value', 'label'].map((key) => [key, ggDate().add(i, 'year').format('YYYY')])),
    ),
];
