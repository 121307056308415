import React from 'react';

const icon = () => (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <circle cx={256} cy={256} fill="#f0f0f0" r={256} />
        <path d="m144.696 345.043 22.261 151.036C194.689 506.37 224.686 512 256 512c110.07 0 203.906-69.472 240.076-166.957z" />
        <path
            d="m144.696 166.957 22.261-151.036C194.689 5.63 224.686 0 256 0c110.07 0 203.906 69.472 240.076 166.957z"
            fill="#6da544"
        />
        <path
            d="M0 256c0 110.071 69.473 203.906 166.957 240.077V15.923C69.473 52.094 0 145.929 0 256z"
            fill="#a2001d"
        />
    </svg>
);

export default icon;
