import fundingItemActions from './actions';

const initialState = {
    loading: false,
    data: null,
    message: null,
    error: null,
};

const fundingItemReducer = (state = initialState, action) => {
    switch (action.type) {
        case fundingItemActions.FETCH_FUNDING_ITEM_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case fundingItemActions.FETCH_FUNDING_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload,
            };
        case fundingItemActions.FETCH_FUNDING_ITEM_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case fundingItemActions.UPDATE_FUNDING_ITEM_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case fundingItemActions.UPDATE_FUNDING_ITEM_START:
            return {
                ...state,
                loading: true,
                updated: false,
                error: null,
            };
        case fundingItemActions.UPDATE_FUNDING_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                updated: true,
                message: action.payload.message,
                data: {
                    ...state.data,
                    reference_number: action.payload.reference_number,
                    customer_notes: action.payload.customer_notes,
                },
            };
        default:
            return state;
    }
};

export default fundingItemReducer;
