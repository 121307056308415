import React from 'react';

const icon = () => (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <circle fill="#D80027" cx="256" cy="256" r="256"></circle>
                <polygon
                    fill="#F0F0F0"
                    points="389.565 211.479 300.522 211.479 300.522 122.435 211.478 122.435 211.478 211.479 122.435 211.479 122.435 300.522 211.478 300.522 211.478 389.565 300.522 389.565 300.522 300.522 389.565 300.522"
                ></polygon>
            </g>
        </g>
    </svg>
);

export default icon;
