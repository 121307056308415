import {createSelector} from 'reselect';

const integrations = (state) => state.integrations;

/**
 * @return {IntegrationsState}
 */
export const getIntegrations = createSelector([integrations], (integrations) => integrations);

export const getSyncJob = createSelector([integrations], (integrations) => ({
    job: integrations.sync_job,
    loading_integrations: integrations.loading_integrations,
}));

export const getInstalledIntegrations = createSelector([integrations], (integrations) => integrations.installed);
