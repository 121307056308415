import React from 'react';
import {Provider} from 'react-redux';
import {history, persistor, store} from 'redux/store';
import {RecoilRoot} from 'recoil';
import {PersistGate} from 'redux-persist/integration/react';
import Router from 'containers/Router';
import {ConfigProvider as AntDConfigProvider} from 'antd';
import antdEn from 'antd/es/locale/en_US';

const App = () => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <AntDConfigProvider history={history} locale={antdEn}>
                    <RecoilRoot>
                        <Router />
                    </RecoilRoot>
                </AntDConfigProvider>
            </PersistGate>
        </Provider>
    );
};

export default App;
