import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'components/UI';
import {lang} from 'config/lang';

const ModalFooter = ({modalProps}) => {
    const {
        cancelButtonProps,
        cancelText,
        okButtonProps = {},
        okText,
        onCancel,
        onOk,
        showCancel,
        showOk,
        timerRemaining,
    } = modalProps;
    const {timer = {}} = okButtonProps;

    const hasTimer = Object.keys(timer)?.length;

    return (
        <>
            {showCancel && (
                <Button size="large" onClick={onCancel} {...cancelButtonProps}>
                    {cancelText}
                </Button>
            )}

            {showOk && (
                <Button
                    type="primary"
                    size="large"
                    onClick={onOk}
                    {...okButtonProps}
                    disabled={!!(okButtonProps.disabled || (hasTimer && timerRemaining))}
                >
                    {!timerRemaining || !hasTimer ? okText : `${lang['understand']} (${timerRemaining})`}
                </Button>
            )}
        </>
    );
};

ModalFooter.propTypes = {
    modalProps: PropTypes.shape({
        cancelButtonProps: PropTypes.object,
        cancelText: PropTypes.string,
        okButtonProps: PropTypes.object,
        okText: PropTypes.string,
        onCancel: PropTypes.func,
        onOk: PropTypes.func,
        showCancel: PropTypes.bool,
        showOk: PropTypes.bool,
        timerRemaining: PropTypes.number,
    }),
};

export default ModalFooter;
