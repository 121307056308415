const fundingMethodActions = {
  FETCH_FUNDING_METHODS_START: 'FETCH_FUNDING_METHODS_START',
  FETCH_FUNDING_METHODS_SUCCESS: 'FETCH_FUNDING_METHODS_SUCCESS',
  FETCH_FUNDING_METHODS_ERROR: 'FETCH_FUNDING_METHODS_ERROR',
  fetchFundingMethodsStart: () => ({
    type: fundingMethodActions.FETCH_FUNDING_METHODS_START,
  }),
  fetchFundingMethodsSuccess: (payload) => {
    return {
      type: fundingMethodActions.FETCH_FUNDING_METHODS_SUCCESS,
      payload,
    }
  },
  fetchFundingMethodsError: (error) => ({
    type: fundingMethodActions.FETCH_FUNDING_METHODS_ERROR,
    payload: error,
  }),
}

export default fundingMethodActions
