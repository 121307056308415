import {S3_URL} from 'config/url';
import cloneDeepWith from 'lodash/cloneDeepWith';
import fromPairs from 'lodash/fromPairs';
import isPlainObject from 'lodash/isPlainObject';
import omit from 'lodash/omit';
import range from 'lodash/range';
import sortBy from 'lodash/sortBy';
import toPairs from 'lodash/toPairs';
import {getProcessEnvValue} from 'library/helpers/process';
import {SYSTEM_ENVIRONMENT} from 'config/constants';
import {routes} from 'config/routes';
import {getRoute} from 'library/helpers/routing';

export const gotoHelp = () => {
    window.open(routes.neutral.paths.HELP, '_blank');
};

export function omitDeep(input, props) {
    const isNil = (value) => value === null || value === undefined;
    const isObject = (obj) => Object.prototype.toString.call(obj) === '[object Object]';

    function omitDeepOnOwnProps(obj) {
        if (typeof input === 'undefined') {
            return input;
        }

        if (!Array.isArray(obj) && !isObject(obj)) {
            return obj;
        }

        if (Array.isArray(obj)) {
            return omitDeep(obj, props);
        }

        const o = {};
        for (const [key, value] of Object.entries(obj)) {
            o[key] = !isNil(value) ? omitDeep(value, props) : value;
        }

        return omit(o, props);
    }

    if (arguments.length > 2) {
        props = Array.prototype.slice.call(arguments).slice(1);
    }

    if (Array.isArray(input)) {
        return input.map(omitDeepOnOwnProps);
    }

    return omitDeepOnOwnProps(input);
}

export const lowerFirst = (string) =>
    typeof string === 'string' ? string.charAt(0).toLowerCase() + string.slice(1) : '';

export const randomString = () =>
    Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

export const composeValidators =
    (...validators) =>
    (value) =>
        validators.reduce((error, validator) => error || validator(value), undefined);

export const downloadFile = (data, fileName, type = 'text/plain') => {
    // Create an invisible A element
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);

    // Set the HREF to a Blob representation of the data to be downloaded
    a.href = window.URL.createObjectURL(new Blob([data], {type}));

    // Use download attribute to set set desired file name
    a.setAttribute('download', fileName);

    // Trigger the download by simulating click
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
};

export const generateCsvTemplate = (headers) => {
    const customRow = [
        '12345',
        'Acme Co.',
        'Joe',
        'Smith',
        'CSR',
        'Customer Service',
        'joe.smith@acme.co',
        '555-555-5555',
        '',
        '12-30-1969',
        '12-30-1969',
    ];

    range(11, headers.length).forEach(() => {
        customRow.push('12-30-1969');
    });

    let csvContent = '';

    [headers, customRow].forEach((rowArray) => {
        const row = rowArray.join(',');

        csvContent += row + '\r\n';
    });

    return csvContent;
};

export const getCookie = (name) => {
    const cookieArr = document.cookie.split(';');

    for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split('=');
        if (name === cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }

    return null;
};

// cyrb53 algorithm
export const hash = (str, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed,
        h2 = 0x41c6ce57 ^ seed;

    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }

    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

export const appendKey = (data, keyId = 'id') =>
    data?.map((c) => {
        c.key = c[keyId];

        return c;
    }) ?? data;

export const isJSON = (item) => {
    if (typeof item === 'object') return true;
    if (typeof item !== 'string') return false;

    const content = item.trim();

    if (!content) return false;

    if (content[0] === '{' || content[0] === '[') return true;

    try {
        JSON.parse(item);
    } catch (_) {
        return false;
    }
};

export const scalarToArray = (item) => (Array.isArray(item) ? item : [item]);

export const deepStringifySort = (obj) => {
    const sortedObj = cloneDeepWith(obj, (value) => {
        if (isPlainObject(value)) {
            return fromPairs(sortBy(toPairs(value), 0));
        }
    });

    return JSON.stringify(sortedObj);
};

export const match = (value, cases) => {
    const returnVal = cases[value] || cases.default || new Error(`Unhandled value: ${value}`);

    return typeof returnVal === 'function' ? returnVal() : returnVal;
};

// /**
//  * generates multiple paths of an image
//  * @param {String} path
//  * @param {Array} sizes
//  * @param {Boolean} appendS3Url
//  * @returns {*}
//  */
// export const getImagePaths = (path, sizes, appendS3Url = true) => {
//     return sizes.reduce((prev, next) => ({...prev, [next]: getImagePath(path, next, appendS3Url)}), {});
// };
//
// /**
//  * Generate a product url
//  * @param {String} path
//  * @param {String} size
//  * @param {Boolean} appendS3Url
//  * @returns string
//  */
// export const getImagePath = (path, size = 'base', appendS3Url = true) => {
//     const parts = path.replace('/base', '').split('/');
//     parts.shift(); // first element will always be empty since there's a leading slash
//     const filename = parts.pop(); // remove and save the filename from the parts array
//     // build the path
//     const newPath = '/' + parts.join('/') + `/${size}/` + filename;
//
//     // if we need to append the S3Url
//     return appendS3Url ? S3_URL + newPath : path;
// };

/**
 * Generate a product url
 * @param {String} path
 * @param {String} size
 * @param {Boolean} appendS3Url
 * @returns string
 */
export const getImagePath = (path, size = 'base', appendS3Url = true) => {
    const parts = path?.replace('/base', '').split('/');

    parts?.shift(); // first element will always be empty since there's a leading slash
    const filename = parts?.pop(); // remove and save the filename from the parts array

    // build the path
    const newPath = parts ? '/' + parts.join('/') + `/${size}/` + filename : '';

    // if we need to append the S3Url
    return appendS3Url ? S3_URL + newPath : path;
};

/**
 * generates multiple paths of an image
 * @param {String} path
 * @param {Array} sizes
 * @param {Boolean} appendS3Url
 * @returns {*}
 */
export const getImagePaths = (path, sizes, appendS3Url = true) => {
    return sizes.reduce((prev, next) => ({...prev, [next]: getImagePath(path, next, appendS3Url)}), {});
};

export const isString = (str) => Object.prototype.toString.call(str) === '[object String]';

export const forEachUntil = (arr, callback) => {
    arr.some(function (item, index) {
        return callback(item, index, arr);
    });
};

/**
 * Capitalize first letter of each word
 * @param phrase
 * @return {*}
 */
export const capWords = (phrase) =>
    phrase
        .split(' ')
        .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
        .join(' ');

export const buildS3ImagePath = (path) => {
    if (typeof path !== 'string') return path;

    return S3_URL + '/' + path.replace(/^\//, '');
};

export const isSandbox = () => getProcessEnvValue('REACT_APP_ENV') === SYSTEM_ENVIRONMENT.SANDBOX;

export const isProduction = () => getProcessEnvValue('REACT_APP_ENV') === SYSTEM_ENVIRONMENT.PRODUCTION || isSandbox();

export const objectToQuerystring = (obj) =>
    Object.keys(obj)
        .map((key) => (obj[key] ? key + '=' + obj[key] : false))
        .filter(Boolean)
        .join('&');

export const encodePeriod = (str) => str?.replace(/\./g, '%2E');

export const getReactElementInnerText = (jsx) => {
    return match(true, {
        [jsx === null]: () => 'null',
        [typeof jsx === 'undefined']: () => 'undefined',
        [typeof jsx === 'boolean' || typeof jsx === 'number']: () => String(jsx),
        [typeof jsx === 'string']: () => jsx,
        [Array.isArray(jsx)]: () => jsx.reduce((previous, current) => previous + getReactElementInnerText(current), ''),
        [jsx && typeof jsx === 'object' && 'props' in jsx && 'children' in jsx.props]: () =>
            getReactElementInnerText(jsx.props.children),
        default: () => '',
    });
};

export const stopEventPropagation = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
};
export const classNames = (...args) => {
    const classes = [];

    const processStringOrNumber = (arg) => {
        classes.push(arg);
    };

    const processArray = (arg) => {
        const inner = classNames(...arg);

        if (inner) classes.push(inner);
    };

    const processObject = (arg) => {
        for (const key in arg) {
            if (arg[key]) classes.push(key);
        }
    };

    for (const arg of args) {
        if (!arg) continue;

        const argType = typeof arg;

        if (argType === 'string' || argType === 'number') {
            processStringOrNumber(arg);

            continue;
        }

        if (Array.isArray(arg) && arg.length) {
            processArray(arg);

            continue;
        }

        if (argType === 'object') {
            processObject(arg);
        }
    }

    return classes.join(' ');
};

export const copyToClipboard = async (text) => {
    try {
        await navigator.clipboard.writeText(text);
    } catch (err) {
        console.error('Failed to copy: ', err);
    }
};
export const openBrandKitPreviewPopup = (id) =>
    window.open(getRoute(routes.private.paths.BRAND_KIT.EMAIL_PREVIEW, {id}), `Preview Email`, 'width=750,height=950');
