export const SAVE_CAMPAIGN_START = 'SAVE_CAMPAIGN_START';
export const saveCampaignStart = (payload) => ({
    type: SAVE_CAMPAIGN_START,
    payload,
});
export const SAVE_CAMPAIGN_ERROR = 'SAVE_CAMPAIGN_ERROR';
export const saveCampaignError = (payload) => ({
    type: SAVE_CAMPAIGN_ERROR,
    payload,
});
export const SAVE_CAMPAIGN_SUCCESS = 'SAVE_CAMPAIGN_SUCCESS';
export const saveCampaignSuccess = () => ({
    type: SAVE_CAMPAIGN_SUCCESS,
});

export const RESET_CAMPAIGN_ERROR = 'RESET_CAMPAIGN_ERROR';
export const resetCampaignError = () => ({
    type: RESET_CAMPAIGN_ERROR,
});

const campaignActions = {
    FETCH_CAMPAIGN_START: 'FETCH_CAMPAIGN_START',
    FETCH_CAMPAIGN_SUCCESS: 'FETCH_CAMPAIGN_SUCCESS',
    FETCH_CAMPAIGN_ERROR: 'FETCH_CAMPAIGN_ERROR',
    fetchCampaignStart: (campaignId) => ({
        type: campaignActions.FETCH_CAMPAIGN_START,
        payload: campaignId,
    }),
    fetchCampaignSuccess: (payload) => {
        return {
            type: campaignActions.FETCH_CAMPAIGN_SUCCESS,
            payload,
        };
    },
    fetchCampaignError: (error) => ({
        type: campaignActions.FETCH_CAMPAIGN_ERROR,
        payload: error,
    }),

    PUT_CAMPAIGN_DATA: 'PUT_CAMPAIGN_DATA',

    putCampaignData: (payload) => ({
        type: campaignActions.PUT_CAMPAIGN_DATA,
        payload,
    }),

    UPDATE_STANDARD_CAMPAIGN_DATA_START: 'UPDATE_STANDARD_CAMPAIGN_DATA_START',
    UPDATE_STANDARD_CAMPAIGN_DATA_SUCCESS: 'UPDATE_STANDARD_CAMPAIGN_DATA_SUCCESS',
    UPDATE_STANDARD_CAMPAIGN_DATA_FAILURE: 'UPDATE_STANDARD_CAMPAIGN_DATA_FAILURE',
    updateStandardCampaignStart: (payload) => ({
        type: campaignActions.UPDATE_STANDARD_CAMPAIGN_DATA_START,
        payload,
    }),
    updateStandardCampaignSuccess: (payload) => {
        return {
            type: campaignActions.UPDATE_STANDARD_CAMPAIGN_DATA_SUCCESS,
            payload,
        };
    },
    updateStandardCampaignFailure: (error) => ({
        type: campaignActions.UPDATE_STANDARD_CAMPAIGN_DATA_FAILURE,
        payload: error,
    }),

    UPDATE_SMART_CAMPAIGN_DATA_START: 'UPDATE_SMART_CAMPAIGN_DATA_START',
    UPDATE_SMART_CAMPAIGN_DATA_SUCCESS: 'UPDATE_SMART_CAMPAIGN_DATA_SUCCESS',
    UPDATE_SMART_CAMPAIGN_DATA_FAILURE: 'UPDATE_SMART_CAMPAIGN_DATA_FAILURE',
    updateSmartCampaignStart: (payload) => ({
        type: campaignActions.UPDATE_SMART_CAMPAIGN_DATA_START,
        payload,
    }),
    updateSmartCampaignSuccess: (payload) => {
        return {
            type: campaignActions.UPDATE_SMART_CAMPAIGN_DATA_SUCCESS,
            payload,
        };
    },
    updateSmartCampaignFailure: (error) => ({
        type: campaignActions.UPDATE_SMART_CAMPAIGN_DATA_FAILURE,
        payload: error,
    }),

    RESET_CAMPAIGN_SAVED_STATUS: 'RESET_CAMPAIGN_SAVED_STATUS',
    resetCampaignSavedStatus: () => {
        return {
            type: campaignActions.RESET_CAMPAIGN_SAVED_STATUS,
        };
    },

    RESET_CAMPAIGN_ERROR: 'RESET_CAMPAIGN_ERROR',
    resetCampaignError: () => {
        return {
            type: campaignActions.RESET_CAMPAIGN_ERROR,
        };
    },

    CLEAR_CAMPAIGN: 'CLEAR_CAMPAIGN',
    clearCampaign: () => {
        return {
            type: campaignActions.CLEAR_CAMPAIGN,
        };
    },

    GET_CAMPAIGN_PRODUCTS_START: 'GET_CAMPAIGN_PRODUCTS_START',
    GET_CAMPAIGN_PRODUCTS_SUCCESS: 'GET_CAMPAIGN_PRODUCTS_SUCCESS',
    GET_CAMPAIGN_PRODUCTS_FAILURE: 'GET_CAMPAIGN_PRODUCTS_FAILURE',

    getCampaignProductsStart: (payload) => ({
        type: campaignActions.GET_CAMPAIGN_PRODUCTS_START,
        payload,
    }),
    getCampaignProductsSuccess: (payload) => ({
        type: campaignActions.GET_CAMPAIGN_PRODUCTS_SUCCESS,
        payload,
    }),
    getCampaignProductsFailure: (payload) => ({
        type: campaignActions.GET_CAMPAIGN_PRODUCTS_FAILURE,
        payload,
    }),
};
export default campaignActions;
