import {api} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';
import {all, put, takeLeading} from 'redux-saga/effects';
import shippingRatesActions from './actions';
import {localFetch} from 'library/helpers/redux';
import {SHIPPING_RATES_STORE} from 'config/redux';
import {setLocalStore} from 'library/helpers/localStore';

function* fetchShippingRates({payload}) {
    const cachedRates = yield localFetch(payload, SHIPPING_RATES_STORE);
    //we also dont want to use cache if it's empty
    if (cachedRates) {
        return yield put(shippingRatesActions.fetchShippingRatesSuccess(cachedRates));
    }

    try {
        const rates = yield api.get(apiEndpoints.physicalOrders.shippingRates).then((res) => res.data);
        yield put(shippingRatesActions.fetchShippingRatesSuccess(rates));

        yield setLocalStore(SHIPPING_RATES_STORE, rates);
    } catch (e) {
        yield put(shippingRatesActions.fetchShippingRatesError(e));
    }
}

export default function* shippingRatesSaga() {
    yield all([takeLeading(shippingRatesActions.FETCH_SHIPPING_RATES_START, fetchShippingRates)]);
}
