import {reactions} from 'components/UI';
import {lang} from 'config/lang';
import {BOTH_ORDER, DIGITAL_ORDER, PHYSICAL_ORDER} from 'config/orders';
import {ggDate} from '../date';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import mergeWith from 'lodash/mergeWith';
import uniqBy from 'lodash/uniqBy';
import dayjs from 'dayjs';
import {getMaxDenominations} from 'redux/company/selectors';
import {store} from 'redux/store';
import profileActions from 'redux/profile/actions';

export const setCampaignQueryString = (campaigns, history, dispatch) => {
    const urlParams = new URLSearchParams(history.location.search);

    // if there's no campaign in the url get out of here
    if (!urlParams.has('campaign')) return false;

    const campaignId = Number(urlParams.get('campaign'));

    const exists = campaigns?.some?.((c) => c.id === campaignId);

    // if the user doesn't have the campaign
    if (!exists) reactions.error('Invalid campaign');

    // set campaign store to new campaign id
    if (exists) dispatch(profileActions.updateDataContext({campaign_id: urlParams.get('campaign')}));

    // push remove campaign from url
    // delete campaign from url so we can set new value and push
    urlParams.delete('campaign');
    history.replace({
        ...history.location,
        search: urlParams.toString(),
    });
};

export const designOptions = [
    {
        label: lang.campaign.form.designs.all,
        value: 'all_designs',
    },
    {
        label: lang.campaign.form.designs.specific,
        value: 'specific_designs',
    },
];

export const detailOptions = [
    {value: BOTH_ORDER, label: lang.campaign.form.type.physical_digital},
    {value: PHYSICAL_ORDER, label: lang.campaign.form.type.physical},
    {value: DIGITAL_ORDER, label: lang.campaign.form.type.digital},
];

const campaignFormMap = {
    name: {key: 'name', value: ''},
    acceptedTerms: {key: 'accepted_terms', value: false},
    sendingType: {key: 'sending_type', value: BOTH_ORDER},
    giftingEnterEmail: {key: 'gifting_enter_email', value: true},
    allowDuplicateSending: {key: 'allow_duplicate_sending', value: true},
    allowEditSender: {key: 'allow_edit_sender', value: true},
    overrideSenderFrom: {key: 'override_sender_from', value: false},
    allowEditMessage: {key: 'allow_edit_message', value: true},
    message: {key: 'message', value: ''},
    subject: {key: 'subject', value: lang.send.message.default_subject},
    senderName: {key: 'sender_name', value: ''},
    senderNameId: {key: 'sender_name_id', value: null},
    primaryProductIds: {key: 'primary_product_ids', value: null},
    denomination: {key: 'denominations', value: []},
    designs: {key: 'designs', value: {products: [], saved_designs: []}},
    designsProducts: {key: 'products', value: []},
    designsSavedDesigns: {key: 'saved_designs', value: []},
    savedDesigns: {key: 'saved_designs', value: []},
    groups: {key: 'groups', value: []},
    paymentMethods: {key: 'payment_methods', value: []},
    shippingAddresses: {key: 'shipping_addresses', value: []},
    regionIds: {key: 'region_ids', value: 1},
    requireAdminApproval: {key: 'require_admin_approval', value: false},
    approvalThreshold: {key: 'approval_threshold', value: 0},
    budget: {key: 'budget', value: 0},
    brandKitId: {key: 'brand_kit_id', value: ''},

    // smart fields
    companyContactProperty: {key: 'company_contact_property', value: []},
    startDate: {
        key: 'start_date',
        value: ggDate().add(1, 'days'),
    },
    endDate: {key: 'end_date', value: ggDate().add(1, 'years')},

    type: {key: 'type', value: 5},
    smart_campaign_type: {key: 'smart_campaign_type', value: 'key_date'},
    interval_years: {key: 'interval_years', value: 1},
};

export const getCampaignKey = (key) => {
    if (!campaignFormMap?.[key]) {
        throw new Error('Invalid campaign form key');
    }

    return campaignFormMap[key].key;
};

export const getCampaignValue = (key) => {
    if (!campaignFormMap?.[key]) {
        throw new Error('Invalid campaign form key');
    }

    return campaignFormMap[key].value;
};

export const getSteps = (type) => {
    // if a new step is ever need we'll add that here.
    return [
        {
            title: lang.campaign.form.general,
            step: 0,
            key: 0,
            value: 0,
        },
        {
            title: lang.campaign.form.details,
            step: 1,
            key: 1,
            value: 1,
        },
        {
            title: lang.campaign.form.recipients.label,
            step: 2,
            key: 2,
            value: 2,
        },
        {
            title: type === 'smart' ? lang.campaign.form.billing.label : lang.campaign.form.billing_shipping,
            step: 3,
            key: 3,
            value: 3,
        },
    ];
};

export const parseProductsCategories = async (products) => {
    const result = {};

    const handler = () =>
        products
            .map((product) => {
                return product.categories.reduce((carry, category) => {
                    return {
                        ...carry,
                        [category.id]: {
                            ...category,
                            products: [product.id],
                        },
                    };
                }, {});
            })
            .forEach((category) => {
                mergeWith(result, category, (objValue, srcValue) => {
                    if (Array.isArray(objValue)) {
                        return objValue.concat(srcValue);
                    }
                });
            });

    await Promise.all([handler()]);
    return Object.values(result);
};

// list, min, max, step
export const generateDenominations = (products) => {
    const maxDenominations = getMaxDenominations(store.getState());
    const denominations = [];

    products.forEach((product) => {
        const {denomination_list, denomination_min, denomination_max, denomination_step, label} = product;

        const productDenominations = denomination_list
            ? denomination_list.split(',').map((denomination) => ({
                  value: parseInt(denomination),
                  label: `$${denomination}`,
                  name: label,
              }))
            : Array.from({length: Math.min(maxDenominations + 1, denomination_max + 1)}, (_, index) => ({
                  value: index,
                  label: `$${index}`,
                  name: label,
              })).filter(({value}) => value >= denomination_min && value % denomination_step === 0);

        denominations.push(productDenominations);
    });

    return {
        allDenominations: denominations,
        denominations: uniqBy(denominations.flat(), 'value').sort((a, b) => a.value - b.value),
    };
};

export const compareArrays = (originalArray, newArray) => {
    if (!Array.isArray(originalArray)) {
        originalArray = [originalArray];
    }

    return {
        valid: originalArray.filter((o) => newArray.includes(o)).map((v) => v),
        invalid: originalArray.filter((o) => !newArray.includes(o)).map((v) => v),
    };
};

export const addDesign = (id, searchOn, formDesigns, products, savedDesigns, isSmart) => {
    const searchOnId = (id, formDesigns, stateProducts, stateSavedDesigns, isSmart) => {
        // search products and savedDesigns  for item.
        const hasProduct = stateProducts.find((p) => p.id === id) || false;
        const hasSavedDesign = !hasProduct ? stateSavedDesigns.find((s) => s.id === id) : false;

        const prods = isSmart ? [] : formDesigns[getCampaignKey('designsProducts')] || [];
        const savedDesigns = isSmart ? [] : formDesigns[getCampaignKey('designsSavedDesigns')] || [];

        return {
            [getCampaignKey('designsProducts')]: [...prods, hasProduct].filter((f) => f),
            [getCampaignKey('designsSavedDesigns')]: [...savedDesigns, hasSavedDesign].filter((f) => f),
        };
    };

    const searchOnCategory = (id, formIds, productDesigns) => {
        const prods = isSmart ? [] : formDesigns[getCampaignKey('designsProducts')] || [];
        const savedDesigns = isSmart ? [] : formDesigns[getCampaignKey('designsSavedDesigns')] || [];

        const newProducts = productDesigns.filter((p) => {
            return p?.categories?.find((c) => c.id === id && !formIds.includes(c.id));
        });

        const constructProducts = (products, isCustom) => {
            const filterCustom = (isCustomBool) => (isCustom ? isCustomBool : !isCustomBool);

            return uniqBy(
                products.filter(Boolean).filter((product) => filterCustom(product?.category?.id === -1)),
                'id',
            );
        };

        const designsProducts = constructProducts([...prods, ...newProducts]);
        const designsSavedDesigns = constructProducts([...prods, ...newProducts, ...savedDesigns], true);

        return {
            [getCampaignKey('designsProducts')]: designsProducts,
            [getCampaignKey('designsSavedDesigns')]: designsSavedDesigns,
        };
    };

    let newProducts = null;

    // concat the formDesigns for fast searching
    const _products = formDesigns[getCampaignKey('designsProducts')] || [];
    const _savedDesigns = formDesigns[getCampaignKey('designsSavedDesigns')] || [];
    const allDesigns = [..._products.map((i) => i.id), ..._savedDesigns.map((i) => i.id)];

    // if this design already exists don't add it again
    if (searchOn === 'id' && allDesigns.find((f) => f === id)) {
        return false;
    }

    // adding product by id
    if (searchOn === 'id') {
        newProducts = searchOnId(id, formDesigns, products, savedDesigns, isSmart);
    }

    // add product(s) by category
    // only used on standard campaigns
    if (searchOn === 'category') {
        newProducts = searchOnCategory(
            id,
            allDesigns.map((fd) => fd.id),
            [...products, ...savedDesigns],
        );
    }

    return newProducts;
};
export const getTypeModeId = () => {
    const {pathname} = window.location;
    let id;
    let [mode, type] = pathname.split('/').filter((v) => v !== '' && v !== 'campaigns');

    if (Number(mode)) {
        id = Number(mode);
        mode = null;
    }

    return {mode, type, id};
};

/**
 * Merge existing campaign data into the form data
 * @param campaign
 * @returns {{'0': {[p: number]: *|ggDate|string, _isValid: boolean}, '1': {[p: number]: *, _isValid: boolean}, '2': {[p: number]: *, _isValid: boolean}, '3': {[p: number]: *, _isValid: boolean}}}
 */
export const mergeCampaignIntoForm = ({campaign}) => {
    const startDate = ggDate(campaign.start_date);
    const endDate = campaign.end_date ? ggDate(campaign.end_date) : '';

    const groups = campaign.campaignType === 'smart' ? campaign.group_id : campaign.data.groups;
    const subject = isEmpty(campaign.subject) ? lang.send.message.default_subject : campaign.subject;

    return {
        0: {
            _isValid: true,
            [getCampaignKey('name')]: campaign.name,
            [getCampaignKey('sendingType')]: campaign.sending_type,
            [getCampaignKey('regionIds')]: campaign.region_ids,
            [getCampaignKey('denomination')]: campaign?.data?.denominations,
            [getCampaignKey('primaryProductIds')]:
                campaign?.primary_product_ids?.length > 0 ? campaign.primary_product_ids : [],
            [getCampaignKey('startDate')]: startDate,
            [getCampaignKey('endDate')]: endDate,
            [getCampaignKey('companyContactProperty')]: campaign['company_contact_property_id'],
            [getCampaignKey('type')]: [5, 6, 7].includes(campaign.company_campaign_type_id)
                ? campaign.company_campaign_type_id
                : '',
            [getCampaignKey('smart_campaign_type')]:
                campaign.company_campaign_type_id && campaign.company_campaign_type_id !== 4
                    ? ![5, 6].includes(campaign.company_campaign_type_id)
                        ? 'enrollment'
                        : 'key_date'
                    : '',
            [getCampaignKey('interval_years')]: campaign.interval_months ? Number(campaign.interval_months) / 12 : 1,
            [getCampaignKey('requireAdminApproval')]: campaign.require_admin_approval,
            [getCampaignKey('approvalThreshold')]: campaign.approval_threshold,
            [getCampaignKey('budget')]: campaign.budget,
        },
        1: {
            _isValid: true,
            [getCampaignKey('senderNameId')]: campaign.sender_name_id,
            [getCampaignKey('subject')]: subject,
            [getCampaignKey('message')]: campaign.message,
            [getCampaignKey('allowEditMessage')]: campaign.data.allow_edit_message,
            [getCampaignKey('overrideSenderFrom')]: campaign.data.override_sender_from,
            [getCampaignKey('allowEditSender')]: campaign.data.allow_edit_sender,
            [getCampaignKey('designs')]: campaign.data.designs,
            [getCampaignKey('brandKitId')]: campaign.brand_kit_id,
        },
        2: {
            _isValid: true,
            [getCampaignKey('groups')]: groups,
            [getCampaignKey('giftingEnterEmail')]: campaign.data.gifting_enter_email,
            [getCampaignKey('allowDuplicateSending')]: campaign.data.allow_duplicate_sending,
        },
        3: {
            _isValid: true,
            [getCampaignKey('paymentMethods')]: campaign.data.payment_methods,
            [getCampaignKey('shippingAddresses')]: campaign.data.shipping_addresses,
        },
    };
};

/**
 * Takes an object and flattens its top level keys out
 * @param obj
 * @returns {unknown}
 */
export const flattenObject = (obj) => {
    return Object.values(obj).reduce((prev, next) => ({...prev, ...next}), {});
};

/**
 * takes an array of objects, loops through and sends each to flattenObjecy
 * @param objArray
 * @returns {*}
 */
export const flattenObjects = (objArray) => {
    return objArray.map((obj) => flattenObject(obj));
};

// form submission modifiers
export const submitDesignsModifier = (formData) => {
    const response = {...formData};
    // these keys are used multiple times so shorten them
    const designsKey = getCampaignKey('designs');
    const designsProductsKey = getCampaignKey('designsProducts');
    const designsSavedDesignsKey = getCampaignKey('designsSavedDesigns');

    // pull the objects into individual variables
    const products = formData[designsKey][designsProductsKey];
    const savedDesigns = formData[designsKey][designsSavedDesignsKey];

    // update designs object to only hold ids
    const getProductIds = () => products.map((p) => p.id);
    const getSavedDesignsIds = () => savedDesigns.map((sd) => sd.id);

    // if products is an empty array and saved designs is null we want to
    // use all products

    let designs;

    if (!products?.length && !savedDesigns) {
        // we can just set designs to the current state
        designs = formData[designsKey];
    }

    // if products is an array with items in it
    if (Array.isArray(products)) {
        designs = {
            [designsProductsKey]: savedDesigns?.length ? (!products?.length ? null : getProductIds()) : getProductIds(),
            [designsSavedDesignsKey]: savedDesigns?.length ? getSavedDesignsIds() : products?.length ? null : [],
        };
    }

    response[designsKey] = designs;

    return response;
};

export const submitSenderModifier = (formData) => {
    const response = {...formData};
    // we want to send a different value to the server if this is custom (equal to zero)
    if (formData[getCampaignKey('senderNameId')] === 0) {
        response[getCampaignKey('senderNameId')] = null;
    } else {
        response[getCampaignKey('senderName')] = null;
    }

    return response;
};

export const submitMessageModifier = (formData) => {
    const response = {...formData};
    let message = formData[getCampaignKey('message')] || '';

    if (isObject(message)) {
        message = JSON.stringify(message);
    }

    response[getCampaignKey('message')] = message;

    return response;
};
export const submitDatesModifier = (formData) => {
    const response = {...formData};
    const format = 'YYYY-MM-DD';
    const startDateKey = getCampaignKey('startDate');
    const endDateKey = getCampaignKey('endDate');

    if (formData[startDateKey]?._isAMomentObject || formData[startDateKey] instanceof dayjs) {
        response[startDateKey] = ggDate(formData[startDateKey]).format(format);
    }
    if (formData[endDateKey]?._isAMomentObject || formData[endDateKey] instanceof dayjs) {
        response[endDateKey] = ggDate(formData[endDateKey]).format(format);
    }

    return response;
};

export const submitDenominationsModifier = (formData) => {
    let response = {...formData};

    const denominationKey = getCampaignKey('denomination');

    // smart campaigns will create a number for denomination.
    // we'll wrap make it an array so the backend knows how to handle it

    if (!Array.isArray(formData[denominationKey])) {
        response[denominationKey] = [formData[denominationKey]];
    }

    return response;
};

export const submitCatalogsModifier = (formData) => {
    const response = {...formData};

    if (response.primary_product_ids) {
        response.primary_product_ids = response.primary_product_ids.filter(Boolean);
    }

    return response;
};

export const submitGroupsModifier = (formData) => {
    const response = {...formData};

    const groupsKey = getCampaignKey('groups');

    // if it's not an array make it one
    if (!Array.isArray(formData[groupsKey])) {
        response[groupsKey] = [formData[groupsKey]];
    }

    return response;
};

export const submitPaymentsModifier = (formData) => {
    let response = {...formData};
    const paymentKey = getCampaignKey('paymentMethods');
    // if payment methods are undefined we want to make it an empty array instead
    if (isUndefined(formData[paymentKey])) {
        response[paymentKey] = [];
    }

    return response;
};

export const submitShippingModifier = (formData) => {
    let response = {...formData};
    const shippingKey = getCampaignKey('shippingAddresses');

    // if shipping addressed are undefined we want to make it an empty array instead
    if (isUndefined(formData[shippingKey])) {
        response[shippingKey] = [];
    }

    return response;
};
