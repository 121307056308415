import {Grid} from 'antd';

import PropTypes from 'prop-types';

const {useBreakpoint} = Grid;

export const Breakpoint = ({xs, sm, md, lg, xl, xxl, inverse, children}) => {
    const screens = useBreakpoint();
    const breakpoints = {xs, sm, md, lg, xl, xxl};

    const isVisible = Object.keys(screens).filter((key) => screens[key] && breakpoints[key]).length;

    if (isVisible === 0) return inverse;

    return children;
};

Breakpoint.defaultProps = {
    xs: true,
    sm: true,
    md: true,
    lg: true,
    xl: true,
    xxl: true,
    children: '',
    inverse: '',
};

Breakpoint.propTypes = {
    xs: PropTypes.bool.isRequired,
    sm: PropTypes.bool.isRequired,
    md: PropTypes.bool.isRequired,
    lg: PropTypes.bool.isRequired,
    xl: PropTypes.bool.isRequired,
    xxl: PropTypes.bool.isRequired,
    children: PropTypes.node,
    inverse: PropTypes.any.isRequired,
};

export default Breakpoint;