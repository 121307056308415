import {all, put, select, takeLeading} from 'redux-saga/effects';
import productActions from './actions';
import {apiEndpoints} from 'config/api';
import {api} from 'library/helpers/api';
import {getRoute} from 'library/helpers/routing';
import {generateCategoryData} from 'library/helpers/products';
import {PRODUCTS_STORE} from 'config/redux';
import {setLocalStore} from 'library/helpers/localStore';
import {localFetch} from 'library/helpers/redux';
import {getCurrentCampaignId} from 'redux/selectors';
import profileActions from 'redux/profile/actions';

const url = (campaignId) => {
    return campaignId !== 0 ? getRoute(apiEndpoints.products.getByCampaign, {campaignId}) : apiEndpoints.products.get;
};

function* fetchProductDataEffect({payload}) {
    try {
        const cachedProducts = yield localFetch(payload, PRODUCTS_STORE);

        if (cachedProducts) {
            const categories = generateCategoryData(cachedProducts);

            return yield put(
                productActions.fetchProductsSuccess({
                    products: cachedProducts,
                    categories,
                }),
            );
        }

        const campaignId = yield payload && Object.prototype.hasOwnProperty.call(payload, 'campaign')
            ? payload.campaign
            : select((state) => getCurrentCampaignId(state));

        if (campaignId === 0) {
            return yield put(
                productActions.fetchProductsError({
                    data: {message: 'No campaigns available'},
                }),
            );
        }

        const products = yield api.get(url(campaignId)).then((res) => res.data);
        const categories = generateCategoryData(products);

        yield put(productActions.fetchProductsSuccess({products, categories}));

        setLocalStore(PRODUCTS_STORE, products);
    } catch (e) {
        console.log(e);
        return yield put(productActions.fetchProductsError(e?.data?.message));
    }
}

function* forceFetch(payload) {
    try {
        yield fetchProductDataEffect({
            payload: {
                forceFetch: true,
                ...payload,
            },
        });
    } catch (err) {
        console.log(err);
    }
}

export default function* productsSaga() {
    yield all([
        takeLeading(productActions.FETCH_PRODUCT_DATA_START, fetchProductDataEffect),
        takeLeading(profileActions.UPDATE_DATA_CONTEXT_CAMPAIGN_ID_SUCCESS, forceFetch),
        takeLeading(profileActions.UPDATE_DATA_CONTEXT_TEAM_ID_SUCCESS, forceFetch),
    ]);
}
