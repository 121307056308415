import {
  AUTH_LOAD_FROM_STORAGE,
  AUTH_LOAD_FROM_STORAGE_COMPLETE,
  AUTH_RESET_DEFAULT_VALUE,
  AUTH_RESET_ERROR,
  AUTH_SET_ERROR,
  AUTH_SET_VERIFY,
  AUTHENTICATE,
  AUTHENTICATE_FAILED,
  AUTHENTICATE_FEDERATED,
  AUTHENTICATE_FEDERATED_LEGACY,
  AUTHENTICATE_SUCCESS,
  CHECK_USER,
  CONFIRM_REGISTER,
  CONFIRM_REGISTER_FAILURE,
  CONFIRM_REGISTER_SUCCESS,
  FETCH_USER,
  LOGOUT,
  LOGOUT_COMPLETE,
  POST_LOGIN,
  REGISTER_FAILED,
  REGISTER_RESET,
  REGISTER_START,
  REGISTER_SUCCESS,
  RESET_AUTHENTICATION_ERROR,
  SET_USER,
  SOFT_AUTHENTICATE,
  START_AUTH,
} from 'redux/auth/auth.types';

export const authLogout = () => {
  return {
    type: LOGOUT,
  };
};
export const startAuth = () => {
  return {
    type: START_AUTH,
  };
};
export const postLogin = () => {
  return {
    type: POST_LOGIN,
  };
};
export const setAuthError = (payload) => {
  return {
    type: AUTH_SET_ERROR,
    payload,
  };
};
export const resetError = () => {
  return {
    type: AUTH_RESET_ERROR,
  };
};
export const loadFromStorage = () => {
  return {
    type: AUTH_LOAD_FROM_STORAGE,
  };
};
export const loadFromStorageComplete = () => {
  return {
    type: AUTH_LOAD_FROM_STORAGE_COMPLETE,
  };
};
export const signOut = () => {
  return {
    type: LOGOUT,
  };
};
export const signOutComplete = () => {
  return {
    type: LOGOUT_COMPLETE,
  };
};
export const fetchUser = () => {
  return {
    type: FETCH_USER,
  };
};
export const checkUser = () => {
  return {
    type: CHECK_USER,
  };
};
export const resetDefaultValue = () => {
  return {
    type: AUTH_RESET_DEFAULT_VALUE,
  };
};

export const setUser = (payload) => {
  return {
    type: SET_USER,
    payload,
  };
};
export const setVerify = (payload) => {
  return {
    type: AUTH_SET_VERIFY,
    payload,
  };
};

export const authenticate = (payload) => {
  return {
    type: AUTHENTICATE,
    payload,
  };
};
export const softAuthenticate = (payload) => {
  return {
    type: SOFT_AUTHENTICATE,
    payload,
  };
};
export const authenticateFederated = (payload) => {
  return {
    type: AUTHENTICATE_FEDERATED,
    payload,
  };
};
export const authenticateFederatedLegacy = (payload) => {
  return {
    type: AUTHENTICATE_FEDERATED_LEGACY,
    payload,
  };
};
export const authenticateSuccess = (payload) => {
  return {
    type: AUTHENTICATE_SUCCESS,
    payload,
  };
};
export const authenticateFailed = (payload) => {
  return {
    type: AUTHENTICATE_FAILED,
    payload,
  };
};
export const resetAuthenticateError = () => {
  return {
    type: RESET_AUTHENTICATION_ERROR,
  };
};

export const confirmRegister = (payload) => {
  return {
    type: CONFIRM_REGISTER,
    payload,
  };
};
export const confirmRegisterSuccess = (payload) => {
  return {
    type: CONFIRM_REGISTER_SUCCESS,
    payload,
  };
};
export const confirmRegisterFailure = (payload) => {
  return {
    type: CONFIRM_REGISTER_FAILURE,
    payload,
  };
};

export const registerReset = () => {
  return {
    type: REGISTER_RESET,
  };
};
export const register = (payload) => {
  return {
    type: REGISTER_START,
    payload,
  };
};
export const registerSuccess = (payload) => {
  return {
    type: REGISTER_SUCCESS,
    payload,
  };
};
export const registerFailed = (payload) => {
  return {
    type: REGISTER_FAILED,
    payload,
  };
};
