import authenticationActions from './actions';
import {getLocalCache, setLocalCache} from 'library/helpers/localStore';
import {INTENDED_URL} from 'config/redux';
import {LOGOUT} from 'redux/auth/auth.types';
import {routes} from 'config/routes';

const initState = {
    auth: false,
    error: false,
    authenticating: false,
    intended: routes.private.paths.DASHBOARD,
};

const authenticationReducer = (state = {...initState}, action) => {
    switch (action.type) {
        case authenticationActions.AUTHENTICATION_START:
            return {
                ...state,
                authenticating: true,
            };
        case authenticationActions.AUTHENTICATION_SUCCESS:
            return {
                ...state,
                auth: true,
                error: false,
                authenticating: false,
            };
        case authenticationActions.AUTHENTICATION_FAILURE:
            return {
                ...state,
                auth: false,
                error: true,
                authenticating: false,
            };
        case authenticationActions.AUTHENTICATION_CHECK:
            return {
                ...state,
                intended: getLocalCache(INTENDED_URL) || state.intended,
            };
        case LOGOUT:
            return {
                ...initState,
                intended: routes.private.paths.DASHBOARD,
            };
        case authenticationActions.SET_INTENDED:
            setLocalCache(INTENDED_URL, action.payload);
            return {
                ...state,
                intended: action.payload,
            };
        case authenticationActions.REFRESH:
            return {...state};
        default:
            return state;
    }
};

export default authenticationReducer;
