import {useLocation, useParams, useHistory} from 'react-router-dom';

export const objectFromQueryString = (querystring) => {
    if (!querystring) return null;

    return querystring
        .replace('?', '')
        .split('&')
        .reduce((carry, item) => {
            const values = item.split('=');

            let value = values[1];

            if (typeof value === 'string' && value === 'true') {
                value = true;
            }

            if (typeof value === 'string' && value === 'false') {
                value = false;
            }

            if (typeof value === 'undefined') {
                value = true;
            }

            if (typeof value === 'string' && values[0] === 'f') {
                value = JSON.parse(decodeURIComponent(value));
            }

            return {
                ...carry,
                [values[0]]: value,
            };
        }, {});
};

const useUrl = () => {
    const {hash, pathname, search} = useLocation();
    const params = useParams();
    const history = useHistory();

    return {
        ...history,
        hash,
        unhashed: hash ? hash.replace('#', '') : null,
        query: objectFromQueryString(search),
        search,
        params,
        pathname,
        id: params?.id ? Number(params.id) : undefined,
        productId: params?.productId ? Number(params.productId) : undefined,
    };
};

export default useUrl;
