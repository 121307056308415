import descendantsActions from './actions';
import {LOGOUT} from 'redux/auth/auth.types';

const initialState = {
    teams: {
        data: null,
        loading: false,
    },
    accounts: {
        data: null,
        loading: false,
    },
    campaigns: {
        data: null,
        loading: false,
    },
    funding: {
        team: 0,
        account: 0,
        campaign: 0,
    },
    orders: {
        team: 0,
        account: 0,
        campaign: 0,
    },
};

const descendantsReducer = (state = initialState, action) => {
    switch (action.type) {
        case descendantsActions.FETCH_TEAM_DESCENDANTS_START:
            return {
                ...state,
                teams: {
                    ...state.teams,
                    loading: true,
                },
            };
        case descendantsActions.FETCH_TEAM_DESCENDANTS_SUCCESS:
            return {
                ...state,
                teams: {
                    ...state.teams,
                    data: action.payload,
                    loading: false,
                },
            };

        case descendantsActions.FETCH_ACCOUNT_DESCENDANTS_START:
            return {
                ...state,
                accounts: {
                    ...state.accounts,
                    loading: true,
                },
            };
        case descendantsActions.FETCH_ACCOUNT_DESCENDANTS_SUCCESS:
            return {
                ...state,
                accounts: {
                    ...state.accounts,
                    data: action.payload,
                    loading: false,
                },
            };

        case descendantsActions.FETCH_CAMPAIGN_DESCENDANTS_START:
            return {
                ...state,
                campaigns: {
                    ...state.campaigns,
                    loading: true,
                },
            };
        case descendantsActions.FETCH_CAMPAIGN_DESCENDANTS_SUCCESS:
            return {
                ...state,
                campaigns: {
                    ...state.campaigns,
                    data: action.payload,
                    loading: false,
                },
            };

        case descendantsActions.UPDATE_FUNDING_SELECTED:
            return {
                ...state,
                funding: {
                    ...state.funding,
                    ...action.payload,
                },
            };

        case descendantsActions.UPDATE_ORDERS_SELECTED:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    ...action.payload,
                },
            };

        case LOGOUT:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default descendantsReducer;
