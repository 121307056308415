import productActions from './actions';

import {LOGOUT} from 'redux/auth/auth.types';

const initialState = {
    data: null,
    filteredBy: 'featured',
    filteredData: [],
    categories: [],
    hasCustom: false,
    loading: false,
    error: null,
};

const productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case productActions.FETCH_PRODUCT_DATA_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case productActions.FETCH_PRODUCT_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload.products,
                categories: action.payload.categories,
                loading: false,
                error: false,
            };
        case productActions.FETCH_PRODUCT_DATA_FAILURE:
            return {
                ...state,
                loading: null,
                error: true,
            };
        case productActions.FILTER_PRODUCTS_CATEGORY:
            return {
                ...state,
                filteredBy: action.payload,
            };
        case productActions.SAVE_FILTER_PRODUCTS:
            return {
                ...state,
                filteredData: action.payload,
            };
        case productActions.SET_CATEGORIES:
            return {
                ...state,
                categories: action.payload,
            };
        case productActions.SET_CUSTOM:
            return {
                ...state,
                hasCustom: action.payload,
            };
        case LOGOUT:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default productsReducer;
