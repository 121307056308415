import {Auth} from 'aws-amplify';
import logger from 'library/vendor/loggly';
import {lang} from 'config/lang';
import {getCookie, isProduction} from 'library/helpers/utilities';
import {AWS_FEDERATED_INFO} from 'config/redux';
import {stripLeading} from 'library/helpers/string';
import {getProcessEnvValue} from 'library/helpers/process';
import {authConfig as amplify} from 'config/auth';

export const awsSetPreferredMFA = async (type) => {
    return await Auth.currentAuthenticatedUser().then(async (user) => {
        // this is required for mfa. just go along with the program
        user.getCachedDeviceKeyAndPassword();

        return await Auth.setPreferredMFA(user, type)
            .then(async (user) => user)
            .catch((error) => error);
    });
};

export const awsIsSso = async () => {
    try {
        await Auth.currentSession();

        const user = await Auth.currentAuthenticatedUser();

        return !!user.attributes.identities;
    } catch (error) {
        console.error('Error in awsIsSso:', error);
    }
};

export const awsGetMFA = async () => {
    await Auth.currentSession();

    return await Auth.currentAuthenticatedUser().then(async (user) => {
        user.getCachedDeviceKeyAndPassword();
        const mfaType = await Auth.getPreferredMFA(user);
        return {
            mfaType,
            user,
        };
    });
};

export const awsUpdateUserAttributes = async (attributes) => {
    await Auth.currentAuthenticatedUser().then(async (user) => {
        user.getCachedDeviceKeyAndPassword();
        await Auth.updateUserAttributes(user, attributes);
    });
};

export const awsResendSignup = async (username) => {
    await Auth.resendSignUp(username);
};
export const awsCurrentUser = async () => {
    try {
        const user = await Auth.currentUserCredentials();
        return Promise.resolve(user);
    } catch (error) {
        return Promise.reject(error);
    }
};
export const awsValidateSession = async () => {
    return await Auth.currentSession()
        .then((sess) => sess)
        .catch(() => false);
};
export const awsGetToken = async (clientId) => {
    const config = amplify.config;

    if (clientId) {
        config.userPoolWebClientId = clientId;

        config.oauth = {
            ...config.oauth,
            redirectSignIn: `${window.location.origin}/auth/callback/${clientId}/`,
        };
    }

    await Auth.configure(config);

    // Pick up Authenticated User if we are getting it from a Federated Login
    const idToken = await Auth.currentAuthenticatedUser()
        .then((user) => user.getSignInUserSession()?.getIdToken())
        .catch(() => false);

    if (idToken) {
        return idToken?.getJwtToken();
    }

    // Not sure this is needed anymore or if we just return an error state if it falls through to here
    return await Auth.currentUserInfo()
        .then(async (user) => {
            // if we are not logged in this wil be
            if (!user) {
                return Promise.reject(lang.signin.error);
            }

            // handle a normal (cognito) login
            if (user.username) {
                const {idToken} = await Auth.currentSession();

                return idToken?.jwtToken;
            }

            //handle a federated login
            const fedInfo = JSON.parse(localStorage.getItem(AWS_FEDERATED_INFO));

            return fedInfo.token;
        })
        .catch((error) => {
            return {error};
        });
};
export const awsLogout = async (payload) => {
    await Auth.signOut(payload)
        .then(() => true)
        .catch((e) => e);
};
export const awsLogin = async (payload) => {
    try {
        const user = await Auth.signIn(payload);
        const {idToken} = user.signInUserSession;

        return {idToken};
    } catch (error) {
        return {error};
    }
};
export const awsFederatedLogin = async (provider) => {
    return await Auth.federatedSignIn(provider);
};
export const awsFederatedLoginLegacy = async ({provider, expires_at, token, user: {email, name}}) => {
    try {
        return await Auth.federatedSignIn(
            provider,
            {
                token,
                expires_at,
                identity_id: '',
            },
            {
                email,
                name,
            },
        )
            .then(() => {
                return true;
            })
            .catch((error) => {
                return {error};
            });
    } catch (error) {
        console.log({error});
        return {error};
    }
};

export const awsRegisterUser = async (payload) => {
    const signupInfo = {
        username: payload.email,
        password: payload.password,
        attributes: {
            email: payload.email,
            name: payload.full_name,
            'custom:marketing': String(+payload.marketing_opt_in),
            'custom:invite': payload.invite_key,
            'custom:phone': payload?.phone,
            'custom:phone_ext': payload?.phone_extension,
            'custom:hubspotinfo': getCookie('hubspotutk'),
            'custom:tokens': JSON.stringify({
                gclid: getCookie('_gcl_aw')?.split('.')?.slice(-1)?.[0],
                msclkid: getCookie('_uetmsclkid')?.replace('_uet', ''),
                liclid: getCookie('li_fat_id'),
            }),
        },
        clientMetadata: {
            invite_key: payload.invite_key, // you can only pass a string to the lambda custom number attributes
            // so this is why it's wrapped in a String()
            // which makes this not as crazy as it seems, outside of AWS crazy
            marketing_opt_in: String(+payload.marketing_opt_in),
            timezone_offset: String(new Date().getTimezoneOffset()),
        },
    };

    try {
        // register user at cognito
        return await Auth.signUp(signupInfo).then((res) => res);
    } catch (error) {
        const newError = {...error};

        if (error.code === 'UserLambdaValidationException') {
            newError.message = error.message.replace(/.*failed with error/gi, '').trim();
        }

        const shouldBubbleError = (e) => {
            if (!e?.message) return true;

            const suppressedErrors = [
                'An account with the given email already exists',
                'Provided password cannot be used for security reasons',
            ];

            return !suppressedErrors.includes(e?.message);
        };

        if (isProduction() && shouldBubbleError(error)) {
            logger.push({
                build_id: stripLeading(getProcessEnvValue('REACT_APP_BUILD_JOB_ID')) ?? '',
                errorMessage: 'Cognito Signup Failed',
                error,
                email: payload.email,
                name: payload.first_name,
                family_name: payload.last_name,
                invite_key: payload?.invite_key,
            });
        }

        return {error: newError};
    }
};
export const awsForgotPasswordStep1 = async (email) => {
    try {
        await Auth.forgotPassword(email);
        return {
            success: true,
            message: `A password recovery email was sent to ${email}.`,
        };
    } catch (err) {
        return {
            success: false,
            code: err?.code,
            message: err?.message,
        };
    }
};
export const awsForgotPasswordStep2 = async ({username, code, password}) => {
    try {
        return await Auth.forgotPasswordSubmit(username, code, password)
            .then((data) => {
                return {data};
            })
            .catch((error) => {
                console.log({error});
                return {error};
            });
    } catch (error) {
        console.log({error});
        return {
            error,
        };
    }
};
