import React from 'react';
import {isSandbox} from 'library/helpers/utilities';
import {Alert} from 'components/UI';
import {lang} from 'config/lang';
import {useSelector} from 'react-redux';
import {getCompany} from 'redux/selectors';

const Banner = () => {
    const companyId = useSelector(getCompany)?.data?.id;

    if (!companyId) return null;
    if (companyId === 1) return null;
    if (!isSandbox()) return null;

    return (
        <Alert
            banner={true}
            className='sandbox-banner'
            message={lang.sandbox.banner}
            showIcon={false}
            type='message'
        />
    );
}

export default Banner;