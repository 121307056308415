import React, {useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {CurrencyDisplay} from '../index';
import {useSelector} from 'react-redux';
import {getProfileRegion} from 'redux/profile/selectors';
import {InputNumber as AntInputNumber} from 'antd';
import {Row, Col} from 'components/UI';

const InputNumber = ({autoFocus, wrapperCol, ...props}) => {
    const inputRef = useRef(null);
    const profileRegion = useSelector(getProfileRegion);

    const shouldDisplayExchange = useMemo(() => {
        return (
            profileRegion &&
            profileRegion.currency !== 'USD' &&
            props?.xchange?.display &&
            !isNaN(parseInt(props?.xchange?.amount))
        );
    }, [profileRegion, props.xchange]);

    return (
        <Row>
            <Col span={wrapperCol}>
                <AntInputNumber ref={inputRef} size="large" {...props} />
            </Col>

            {!shouldDisplayExchange ? null : (
                <Col span={10}>
                    <span
                        className={props.xchange?.reactive ? 'xchange-amount' : ''}
                        style={{
                            // messy fixes here, should definitely be looked at
                            display: props.xchange?.reactive
                                ? ''
                                : props.xchange?.inline === false
                                ? 'block'
                                : 'inline-block',
                            position: props.xchange?.reactive
                                ? ''
                                : props.xchange?.inline === false
                                ? 'relative'
                                : 'absolute',
                            lineHeight: '2.375rem',
                            marginLeft: props.xchange?.reactive ? '' : props.xchange?.inline === false ? '0' : '1rem',
                            marginTop: props.xchange?.marginTop ?? 0,
                        }}
                    >
                        <CurrencyDisplay
                            currency={{
                                ...props.xchange.amount,
                                amount: props.xchange.amount,
                                currency: profileRegion?.currency,
                                usd_unit_price: props.xchange.amount,
                            }}
                            flagProps={{size: 1}}
                            valueRegion={profileRegion}
                            approximate
                        />
                    </span>
                </Col>
            )}
        </Row>
    );
};

// TODO: Deprecated, move to default params
InputNumber.defaultProps = {
    wrapperCol: 14,
};

InputNumber.propTypes = {
    autoFocus: PropTypes.bool,
    wrapperCol: PropTypes.number,
    xchange: PropTypes.object,
};

export default InputNumber;
