import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {OrderItem} from 'components/Cart';
import {Col, Row, FontAwesome, Space} from 'components/UI';
import {getProfile} from 'redux/profile/selectors';
import {getOrderSummary} from 'redux/orderSummary/selectors';
import {usePrev} from 'hooks/utility/usePrev';
import {getStorefront} from 'library/helpers/products';
import {getCompanyPrimaryProducts} from 'redux/primaryProducts/selectors';

const OrderSummary = ({errors}) => {
    const [state, setState] = useState({showPlaceholder: false});

    const companyPrimaryProducts = useSelector(getCompanyPrimaryProducts);
    const order = useSelector(getOrderSummary);
    const profile = useSelector(getProfile);

    useEffect(() => {
        if (order.adding) {
            setState((prevState) => ({...prevState, showPlaceholder: true}));
        }
    }, [order.adding]);

    const prevOrderAdding = usePrev(order.adding);
    useEffect(() => {
        if (prevOrderAdding && order.added) {
            setState((prevState) => ({...prevState, showPlaceholder: false}));
        }
    }, [prevOrderAdding, order.added]);

    const renderRows = () => {
        if (profile.data === null) return '';

        return order.data.items.map((item) => (
            <OrderItem
                storefront={getStorefront(companyPrimaryProducts, item.primary_product_id)}
                key={item.id}
                item={item}
                errors={errors}
            />
        ));
    };

    const renderPlaceHolder = () => {
        return (
            <Row key="order-item-placeholder">
                <Col span={24} style={{position: 'relative', textAlign: 'center'}}>
                    <FontAwesome waiting size="2xl" />
                </Col>
            </Row>
        );
    };

    return (
        <div className="cart-wrapper">
            <Space direction="vertical" size={24}>
                {state.showPlaceholder ? renderPlaceHolder() : null}
                {renderRows()}
            </Space>
        </div>
    );
};

OrderSummary.propTypes = {
    errors: PropTypes.any,
};

export default OrderSummary;
