import {SEND_TO_GROUPS, SEND_TO_LIST, SEND_TO_RECIPIENTS} from 'config/orders/digital';
import {exchangeToUsd} from 'library/helpers/format';
import {store} from 'redux/store';
import uniq from 'lodash/uniq';
import {getUploadBulk, getCompanyData, getProfile} from 'redux/selectors';
import {validSendNameCharacters} from 'config/expressions';
import {ggDate} from '../date';
import {getForm} from 'redux/forms/selectors';
import {SEND_FORM} from 'config/forms';
import {lang} from 'config/lang';
import {CREDIT_CARD_PAYMENT_METHOD_ID, PROCUREMENT_SYSTEM_METHOD_ID} from 'config/payments';

export const getDefaultSendData = ({profile, campaigns}) => {
    const currentCampaignId = profile?.data?.data?.context?.campaign_id ?? null;
    const currentCampaign = campaigns?.data?.find?.((campaign) => campaign.id === currentCampaignId) ?? {};
    const isPunchout = profile?.data?.is_punchout ?? false;

    const {message: campaignMessage, subject: campaignSubject, sender_name_id: senderNameId} = currentCampaign;

    // TODO: If procurement is an enum, make it lowercase.
    return {
        accepted_terms: false,
        sendTo: null,
        campaign_id: null,
        payment_method_id: isPunchout ? PROCUREMENT_SYSTEM_METHOD_ID : CREDIT_CARD_PAYMENT_METHOD_ID,
        payment_card_id: isPunchout ? 'procurement' : null,
        send_time: '',
        send_method: 'now',
        customer_notes: null,
        reference_number: null,
        sender_name_id: senderNameId ?? profile?.id,
        override_sender_from: null,
        message: {
            body: campaignMessage || '',
            original_subject: campaignSubject || lang.send.message.default_subject,
            subject: campaignSubject || lang.send.message.default_subject,
            name: null,
            save_new: false,
        },
        save_recipients: false,
        recipients: [],
        quantity: 0,
        group_id: null,
        groupName: null,
        group_recipient_count: 0,
        card: {
            denomination: null,
            product_id: null,
            saved_design_id: null,
            card_asset_id: null,
        },
        bulk_list_id: 0,
        preset: false,
        updateCount: 0,
        context: {...(profile?.data?.data?.context ?? {}), account_id: profile?.data?.id ?? 0},
        saved_at: ggDate().format('YYYY-MM-DD HH:mm:ss'),
        team_recipients_only: false,
        count: 0,
    };
};

export const calculateCosts = (exchangeRate, incrementQuantity = 0) => {
    if (!exchangeRate) return [];

    const bulkUpload = getUploadBulk(store.getState());
    const sendData = getForm(store.getState(), SEND_FORM);

    let cost;
    let quantity = (sendData?.recipients?.length ?? 0) + incrementQuantity;

    switch (sendData.sendTo) {
        case SEND_TO_RECIPIENTS:
            cost = exchangeToUsd(sendData.denomination, exchangeRate) * quantity;

            break;
        case SEND_TO_GROUPS:
            quantity = sendData.group_recipient_count;
            cost = exchangeToUsd(sendData.denomination, exchangeRate) * quantity;

            break;
        case SEND_TO_LIST:
            quantity =
                bulkUpload.data !== null && bulkUpload.data.errors.length === 0 ? bulkUpload.data.results.rowCount : 0;
            cost = bulkUpload.data !== null ? bulkUpload.data.results.cost : 0;

            break;
        default:
            quantity = 0;
            cost = 0;
    }

    return [cost, quantity];
};

export const checkExceedsLimits = (cost) => {
    const companyData = getCompanyData(store.getState());
    const profile = getProfile(store.getState());

    const creditCardsEnabled = companyData?.credit_card?.enabled;

    return (
        companyData &&
        cost > (!creditCardsEnabled ? 0 : companyData.credit_card.max_order) &&
        cost > profile.data.credit_available
    );
};

export const getInvalidSenderNameCharacters = (senderName) => {
    return uniq(
        senderName
            .split('')
            .map((character) => (character.match(new RegExp(validSendNameCharacters, 'g')) ? null : character))
            .filter(Boolean),
    );
};
