const uploadActions = {
    UPLOAD_START: 'UPLOAD_START',
    UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',
    UPLOAD_ERROR: 'UPLOAD_ERROR',
    UPLOAD_RESET: 'UPLOAD_RESET',

    uploadStart: (payload) => ({
        type: uploadActions.UPLOAD_START,
        payload,
    }),
    uploadSuccess: (payload) => {
        return {
            type: uploadActions.UPLOAD_SUCCESS,
            payload,
        };
    },
    uploadError: (error) => ({
        type: uploadActions.UPLOAD_ERROR,
        payload: error,
    }),
    uploadReset: (payload) => ({
        type: uploadActions.UPLOAD_RESET,
        payload,
    }),
};

export default uploadActions;
