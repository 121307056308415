import {all, put, call, takeEvery, takeLeading} from 'redux-saga/effects';
import teamActions from './actions';
import profileActions from 'redux/profile/actions';
import {apiEndpoints} from 'config/api';
import {buildQuery, getRoute, goToRoute} from 'library/helpers/routing';
import {api} from 'library/helpers/api';
import descendantsActions from 'redux/descendants/actions';
import recipientsActions from '../recipients/actions';
import groupsActions from '../groups/actions';
import {addSuperAdminToTeam} from 'library/helpers/data';
import creditCardsActions from '../creditCards/actions';
import formsActions from '../forms/actions';
import {SEND_FORM} from 'config/forms';
import omit from 'lodash/omit';
import campaignsService from 'library/helpers/api/services/campaigns';
import {routes} from 'config/routes';

function* fetchTeam() {
    try {
        const team = yield api.get(apiEndpoints.team.get).then((res) => res.data);

        yield put(teamActions.fetchTeamDataSuccess(team));
        yield put(descendantsActions.fetchTeamDescendantsStart());
    } catch (error) {
        yield put(teamActions.fetchTeamDataFailure(error));
    }
}

function* fetchTeamMembersHelper(payload) {
    const paginated = payload?.paginate !== false;

    try {
        const route = getRoute(apiEndpoints.team_members.get, {
            id: payload.team ?? 'current',
        });

        let members = yield api.get(`${route}?${buildQuery(omit(payload, 'team'))}`).then((res) => res.data);

        members.data = addSuperAdminToTeam(members.data);

        members.data = members.data.map((item) => {
            item.name = `${item.first_name} ${item.last_name}`;

            return item;
        });

        yield put(
            paginated ? teamActions.fetchMembersDataSuccess(members) : teamActions.fetchAllMembersDataSuccess(members),
        );
    } catch (error) {
        yield put(
            paginated ? teamActions.fetchMembersDataFailure(error) : teamActions.fetchAllMembersDataFailure(error),
        );
    }
}

function* fetchTeamMembers({
    payload = {
        team: 'current',
        per_page: 100,
        page: 1,
    },
}) {
    yield call(fetchTeamMembersHelper, payload);
}

function* inviteExistingTeamMembers({payload}) {
    try {
        const route = getRoute(apiEndpoints.invites.addExisting, {
            id: payload.team,
        });
        yield api.post(route, payload).then((res) => res.data);

        yield put(teamActions.fetchTeamDataStart());
        yield put(teamActions.inviteExistingMembersDataSuccess());
    } catch (error) {
        yield put(teamActions.inviteExistingMembersDataFailure(error));
    }
}

function* createInviteLink({payload}) {
    try {
        yield api.post(apiEndpoints.invites.createLink, payload).then((res) => res.data);
        yield put(teamActions.createInviteLinkSuccess());
    } catch (error) {
        yield put(teamActions.createInviteLinkFailure(error));
    }
}

function* createInviteEmail({payload}) {
    try {
        yield api.post(apiEndpoints.invites.createEmail, payload).then((res) => res.data);
        yield put(teamActions.createInviteEmailSuccess());
    } catch (error) {
        yield put(teamActions.createInviteEmailFailure(error));
    }
}

function* cancelInvite({payload}) {
    try {
        const route = getRoute(apiEndpoints.invites.cancel, {id: payload});
        yield api.delete(route).then((res) => res.data);

        yield put(teamActions.cancelInviteDataSuccess());
    } catch (error) {
        yield put(teamActions.cancelInviteDataFailure(error));
    }
}

function* changeMemberRole({payload}) {
    try {
        const route = getRoute(apiEndpoints.team_members.changeRole, {
            id: payload.team,
            memberId: payload.memberId,
        });
        yield api.post(route, {roles: payload.roles}).then((res) => res.data);

        yield put(teamActions.changeRoleDataSuccess());
        // yield put(teamActions.fetchMembersDataStart(payload));
    } catch (error) {
        yield put(teamActions.changeRoleDataFailure(error));
    }
}

function* toggleUser({payload}) {
    try {
        yield api.put(getRoute(apiEndpoints.account.toggle, {id: payload.id}), {
            active: !(payload.status === 'active'),
        });

        yield put(teamActions.toggleUserDataSuccess());
        // yield put(teamActions.fetchMembersDataStart(payload));
    } catch (error) {
        yield put(teamActions.toggleUserDataFailure(error));
    }
}

function* removeTeamMember({payload}) {
    try {
        const route = getRoute(apiEndpoints.team_members.remove, {
            id: payload.team,
            memberId: payload.memberId,
        });
        yield api.delete(route).then((res) => res.data);

        yield put(teamActions.removeMemberDataSuccess());
        yield put(teamActions.fetchTeamDataStart());
    } catch (error) {
        yield put(teamActions.removeMemberDataFailure(error));
    }
}

function* changeTeam({payload}) {
    try {
        const route = getRoute(apiEndpoints.team.changeContext, {
            id: payload?.team_id ?? payload,
        });

        yield api.post(route);
        yield campaignsService.setCampaignContext(0);

        yield put(teamActions.fetchTeamDataStart());
        yield call(fetchTeam);
        yield put(profileActions.fetchProfileDataStart());
        yield put(recipientsActions.fetchRecipientsStart());
        yield put(groupsActions.fetchGroupsDataStart());
        yield put(teamActions.changeTeamSuccess());
        yield put(teamActions.fetchAllMembersDataStart());

        setTimeout(() => goToRoute(routes.private.paths.DASHBOARD), 500);
    } catch (error) {
        console.log('err', error);
        yield put(teamActions.changeTeamFailure(error));
    }
}

function* changeTeamSuccess() {
    yield put(creditCardsActions.fetchCreditCardsDataStart());
    yield put(formsActions.reset(SEND_FORM));
}

export default function* teamSaga() {
    yield all([
        takeLeading(teamActions.FETCH_TEAM_DATA_START, fetchTeam),
        takeEvery([teamActions.FETCH_MEMBERS_DATA_START, teamActions.FETCH_ALL_MEMBERS_DATA_START], fetchTeamMembers),
        takeEvery(teamActions.INVITE_EXISTING_MEMBERS_DATA_START, inviteExistingTeamMembers),
        takeEvery(teamActions.CREATE_INVITE_LINK_START, createInviteLink),
        takeEvery(teamActions.CREATE_INVITE_EMAIL_START, createInviteEmail),
        takeEvery(teamActions.CANCEL_INVITE_DATA_START, cancelInvite),
        takeEvery(teamActions.CHANGE_ROLE_DATA_START, changeMemberRole),
        takeEvery(teamActions.REMOVE_MEMBER_DATA_START, removeTeamMember),
        takeEvery(teamActions.TOGGLE_USER_DATA_START, toggleUser),
        takeEvery(teamActions.CHANGE_TEAM_SUCCESS, changeTeamSuccess),
        takeLeading(teamActions.CHANGE_TEAM_START, changeTeam),
        takeLeading(profileActions.UPDATE_DATA_CONTEXT_TEAM_ID_SUCCESS, changeTeam),
    ]);
}
