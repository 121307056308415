import {all, takeEvery, put, takeLeading} from 'redux-saga/effects';
import recipientsActions from 'redux/recipients/actions';
import {buildQuery} from 'library/helpers/routing';
import {appendKey} from 'library/helpers/utilities';
import {apiEndpoints} from 'config/api';
import {api} from 'library/helpers/api';

function* fetchRecipients(query) {
    const url = `${apiEndpoints.recipients.get}?${query}`;
    const recipients = yield api.get(url).then((response) => response.data);

    recipients.data = appendKey(recipients.data);

    return recipients;
}

function* refetchRecipientsData({payload}) {
    const recipients = yield fetchRecipients(buildQuery(payload));

    yield put(recipientsActions.refetchRecipientsSuccess(recipients));
}

// this is terrible todd. do better
function* refetchRecipientsDataModify({payload}) {
    yield put(recipientsActions.fetchRecipientsSuccess(payload));
}

function* fetchRecipientsData({payload}) {
    try {
        const recipients = yield fetchRecipients(buildQuery(payload));

        yield put(recipientsActions.fetchRecipientsSuccess(recipients));
    } catch (error) {
        yield put(recipientsActions.fetchRecipientsError(error));
    }
}

function* toggleActiveRecipients({payload}) {
    try {
        const response = yield api.put(apiEndpoints.groups.toggleActive, {
            active: payload.active,
            recipients: payload.recipients,
        });

        if (!response) {
            yield put(recipientsActions.toggleStatusRecipientsToGroupFailure(response));

            return false;
        }

        yield put(recipientsActions.refetchRecipientsStart());
        yield put(recipientsActions.toggleStatusRecipientsToGroupSuccess());
    } catch (error) {
        yield put(recipientsActions.toggleStatusRecipientsToGroupFailure(error));
    }
}

function* deleteSelectedInactiveRecipients({payload}) {
    try {
        yield api.post(apiEndpoints.recipients.deleteInactive, {
            recipients: payload.recipients,
        });
        yield put(recipientsActions.deleteInactiveRecipientsSuccess());
    } catch (error) {
        yield put(recipientsActions.deleteInactiveRecipientsSuccess(error));
    }
}

export default function* recipientsSaga() {
    yield all([
        takeLeading(recipientsActions.FETCH_RECIPIENTS_DATA_START, fetchRecipientsData),
        takeEvery(recipientsActions.REFETCH_RECIPIENTS_DATA_START, refetchRecipientsData),
        takeEvery(recipientsActions.REFETCH_RECIPIENTS_DATA_SUCCESS, refetchRecipientsDataModify),
        takeEvery(recipientsActions.TOGGLE_RECIPIENTS_STATUS_START, toggleActiveRecipients),
        takeEvery(recipientsActions.DELETE_INACTIVE_RECIPIENTS_START, deleteSelectedInactiveRecipients),
    ]);
}
