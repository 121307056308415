import {createSelector} from 'reselect';

const descendants = (state) => state.descendants;
const app = (state) => state.app;
const profile = (state) => state.profile;

const nestedTreeSelect = (items, id = null, link = 'parent_team_id') =>
    items
        .filter((item) => item[link] === id)
        .map((item) => ({
            title: item.name,
            value: item.id,
            children: nestedTreeSelect(items, item.id),
        }));

export const getTeamDescendants = createSelector([descendants], (descendants) => descendants.teams.data ?? []);

export const getTeamDescendantsTreeSelect = createSelector([descendants, app, profile], (descendants, app) => {
    const teams = descendants.teams.data ?? [];

    return nestedTreeSelect(teams, profile?.data?.data?.context?.team_id, 'parent_team_id');
});
export const getTeamDescendantsChildren = createSelector([descendants, app, profile], (descendants, app) => {
    const teams = descendants.teams.data ?? [];

    return teams.filter((team) => team.id !== profile?.data?.data?.context?.team_id);
});
export const getTeamDescendantsLoading = createSelector(
    [descendants],
    (descendants) => descendants.teams.data === null || descendants.teams.loading,
);
export const getAccountDescendants = createSelector([descendants], (descendants) => descendants.accounts.data ?? []);
export const getAccountDescendantsLoading = createSelector(
    [descendants],
    (descendants) => descendants.accounts.data === null || descendants.accounts.loading,
);
export const getCampaignDescendants = createSelector([descendants], (descendants) => descendants.campaigns.data ?? []);
export const getCampaignDescendantsLoading = createSelector(
    [descendants],
    (descendants) => descendants.accounts.data === null || descendants.campaigns.loading,
);
export const getDescendantsFundingOptions = createSelector([descendants], (descendants) => descendants.funding);
export const getDescendantsOrdersOptions = createSelector([descendants], (descendants) => descendants.funding);
