const companyAction = {
    FETCH_COMPANY_DATA_START: 'FETCH_COMPANY_DATA_START',
    FETCH_COMPANY_DATA_SUCCESS: 'FETCH_COMPANY_DATA_SUCCESS',
    FETCH_COMPANY_DATA_FAILURE: 'FETCH_COMPANY_DATA_FAILURE',
    fetchCompanyStart: () => ({
        type: companyAction.FETCH_COMPANY_DATA_START,
    }),

    fetchCompanySuccess: (payload) => {
        return {
            type: companyAction.FETCH_COMPANY_DATA_SUCCESS,
            payload,
        };
    },

    fetchCompanyFailure: (error) => ({
        type: companyAction.FETCH_COMPANY_DATA_FAILURE,
        payload: error,
    }),

    UPDATE_COMPANY_DATA_START: 'UPDATE_COMPANY_DATA_START',
    UPDATE_COMPANY_DATA_SUCCESS: 'UPDATE_COMPANY_DATA_SUCCESS',
    UPDATE_COMPANY_DATA_FAILURE: 'UPDATE_COMPANY_DATA_FAILURE',
    updateCompanyDataStart: (payload) => ({
        type: companyAction.UPDATE_COMPANY_DATA_START,
        payload,
    }),

    updateCompanyDataSuccess: () => {
        return {
            type: companyAction.UPDATE_COMPANY_DATA_SUCCESS,
        };
    },

    updateCompanyDataFailure: (error) => ({
        type: companyAction.UPDATE_COMPANY_DATA_FAILURE,
        payload: error,
    }),

    UPDATE_COMPANY_SETTINGS_START: 'UPDATE_COMPANY_SETTINGS_START',
    UPDATE_COMPANY_SETTINGS_SUCCESS: 'UPDATE_COMPANY_SETTINGS_SUCCESS',
    UPDATE_COMPANY_SETTINGS_FAILURE: 'UPDATE_COMPANY_SETTINGS_FAILURE',
    updateCompanySettingsStart: (payload) => ({
        type: companyAction.UPDATE_COMPANY_SETTINGS_START,
        payload,
    }),

    updateCompanySettingsSuccess: () => {
        return {
            type: companyAction.UPDATE_COMPANY_SETTINGS_SUCCESS,
        };
    },

    updateCompanySettingsFailure: (error) => ({
        type: companyAction.UPDATE_COMPANY_SETTINGS_FAILURE,
        payload: error,
    }),

    UPDATE_COMPANY_LOGO_START: 'UPDATE_COMPANY_LOGO_START',
    UPDATE_COMPANY_LOGO_SUCCESS: 'UPDATE_COMPANY_LOGO_SUCCESS',
    UPDATE_COMPANY_LOGO_FAILURE: 'UPDATE_COMPANY_LOGO_FAILURE',
    updateCompanyLogoStart: (payload) => ({
        type: companyAction.UPDATE_COMPANY_LOGO_START,
        payload,
    }),

    updateCompanyLogoSuccess: (payload) => {
        return {
            type: companyAction.UPDATE_COMPANY_LOGO_SUCCESS,
            payload,
        };
    },

    updateCompanyLogoFailure: (error) => ({
        type: companyAction.UPDATE_COMPANY_LOGO_FAILURE,
        payload: error,
    }),

    CLEAR_COMPANY_DATA: 'CLEAR_COMPANY_DATA',
    clearCompanyData: (error) => ({
        type: companyAction.CLEAR_COMPANY_DATA,
        payload: error,
    }),
};

export default companyAction;
