import fundingActions from './actions';

import sendActions from 'redux/send/actions';
import orderSummaryActions from 'redux/orderSummary/actions';
import {LOGOUT} from 'redux/auth/auth.types';

const initialState = {
    submitted: false,
    data: null,
    error: null,
    loading: false,
    query: {page: 1},
    message: null,
};

const fundingReducer = (state = initialState, action) => {
    switch (action.type) {
        case fundingActions.FETCH_FUNDING_DATA_START:
            return {
                ...state,
                query: action.payload,
                loaded: false,
                loading: true,
                error: null,
            };
        case fundingActions.FETCH_FUNDING_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loaded: true,
                loading: false,
                error: null,
            };
        case fundingActions.FETCH_FUNDING_DATA_ERROR:
            return {
                ...state,
                data: null,
                loaded: false,
                loading: false,
                error: action.payload,
            };

        case fundingActions.ADD_FUNDS_START:
        case fundingActions.REQUEST_FUNDS_START:
            return {
                ...state,
                payload: action.payload,
                submitted: false,
                loading: true,
                error: null,
                message: null,
            };
        case fundingActions.ADD_FUNDS_SUCCESS:
        case fundingActions.REQUEST_FUNDS_SUCCESS:
            return {
                ...state,
                data: null,
                message: action.payload,
                loading: false,
                submitted: true,
                error: null,
            };
        case fundingActions.ADD_FUNDS_ERROR:
        case fundingActions.REQUEST_FUNDS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case fundingActions.TRANSFER_FUNDS_START:
            return {
                ...state,
                payload: action.payload,
                submitted: false,
                loading: true,
                error: null,
                message: null,
                transferring: true,
            };
        case fundingActions.TRANSFER_FUNDS_SUCCESS:
            return {
                ...state,
                data: null,
                message: action.payload,
                loading: false,
                submitted: true,
                error: null,
                transferring: false,
            };
        case fundingActions.TRANSFER_FUNDS_ERROR:
            return {
                ...state,
                loading: false,
                transferring: false,
                error: action.payload,
            };
        case fundingActions.FUNDS_RESET_ERROR:
            return {
                ...state,
                error: null,
            };

        case LOGOUT:
        case sendActions.SEND_SUBMIT_SUCCESS:
        case orderSummaryActions.RESET_ORDER_SUMMARY:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default fundingReducer;
