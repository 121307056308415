import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import regionsActions from 'redux/regions/actions';
import {getRegionsData} from 'redux/regions/selectors';
import {usePrev} from '../utility/usePrev';
import isEqual from 'react-fast-compare';

const useGetExchangeRates = (fetchNew = false) => {
    const dispatch = useDispatch();

    const regions = useSelector(getRegionsData);

    const [exchangeRates, setExchangeRates] = useState(null);

    const getExchangeRate = useCallback(
        (currency) => exchangeRates?.find?.((rate) => rate.currency === currency)?.exchange_rate,
        [exchangeRates],
    );

    useEffect(() => {
        if (regions && !fetchNew) return;

        dispatch(regionsActions.fetchAllRegionsStart());

        // eslint-disable-next-line
    }, []);

    const prevRegions = usePrev(regions);
    useEffect(() => {
        if (!regions) return;
        if (isEqual(prevRegions, regions)) return;

        const newExchangeRates = regions.map((region) => ({
            currency: region.currency,
            exchange_rate: region.exchange_rate,
        }));

        setExchangeRates(newExchangeRates);
    }, [prevRegions, regions]);

    return {
        exchangeRates: exchangeRates,
        getExchangeRate: getExchangeRate,
    };
};

export default useGetExchangeRates;
