import inviteActions from './actions';

const initialState = {
  type: null,
  key: null,
  email: '',
  company_id: null,
  error: null,
};

const inviteReducer = (state = initialState, action) => {
  switch (action.type) {
    case inviteActions.LOOKUP_INVITE_START:
      return {
        ...initialState,
      };
    case inviteActions.LOOKUP_INVITE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case inviteActions.LOOKUP_INVITE_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case inviteActions.RESET_INVITE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default inviteReducer;
