import {getSiteData, setSiteData} from 'library/helpers/siteData';

const storage = {
    session: sessionStorage,
    local: localStorage,
};
const prefix = 'ggca_';
export const identifier = prefix; // + process.env.REACT_APP_DEPLOY_ID;

// local storage functions
export const getLocalStore = (key, type = 'session') => {
    try {
        return JSON.parse(storage[type].getItem(key));
    } catch (e) {
        return {};
    }
};
export const setLocalStore = (key, value, type = 'session') => {
    try {
        storage[type].setItem(key, JSON.stringify(value));
    } catch {
        return false;
    }
};
export const removeLocalStore = (key, type = 'session') => {
    try {
        storage[type].removeItem(key);
    } catch {
        return false;
    }
};

// cache functions
export const setLocalCache = (key, value) => setLocalStore(identifier + '_' + key, value, 'local');
export const getLocalCache = (key) => getLocalStore(identifier + '_' + key, 'local');
export const removeLocalCaches = (keys) => keys.forEach((key) => removeLocalCache(key));
export const removeLocalCache = (key) => removeLocalStore(identifier + '_' + key, 'local');
export const clearLocalCache = () => {
    const siteData = getSiteData();
    localStorage.clear();
    setSiteData(siteData);
};

// session storage function s
export const setSessionCache = (key, value) => setLocalStore(key, value, 'session');
export const getSessionCache = (key) => getLocalStore(key);
export const clearSessionCache = () => storage.session.clear();
export const removeSessionCache = (key) => removeLocalStore(key, 'session');

export const clearLocalStore = (type = 'session') => {
    if (['all', 'local'].includes(type)) storage.local.clear();
    if (['all', 'session'].includes(type)) storage.session.clear();
};
