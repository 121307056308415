import {atom} from 'recoil';
import {recoilPersist} from 'library/vendor/recoil-persist';

export const defaultAuthState = {
    signUpRequired: false,
    authenticated: null,
    amazonAuthenticated: null,
    authenticating: false,
    error: null,
    token: null,
    ssoAuthenticated: false,
};

const {persistAtom} = recoilPersist({
    storage: localStorage,
});

export const authState = atom({
    key: 'authState',
    default: defaultAuthState,
    effects_UNSTABLE: [persistAtom],
});
