import {ggDate} from 'library/helpers/date';
import {getDefaultSendData} from 'library/helpers/send';

const isOldData = (send) => ggDate().diff(ggDate(send?.saved_at ?? '1900-01-01'), 'hour') >= 8;

const isContextInvalid = (send, key, profile) => {
    if (!profile) return false;

    if (!send?.context?.[key]) return false;
    if (!profile?.data?.data?.context?.[key]) return false;

    return send?.context?.[key] !== profile?.data?.data?.context?.[key];
};

export const sendValidator = (inboundState, key, {profile = {}, campaigns = {}} = {}) => {
    if (!Object.keys(profile)?.length) return inboundState;

    // TODO: Wrap this into every validator so it can be re-used
    // TODO: fix global reuse

    const newState = {...inboundState, __global: {profile, campaigns}};

    if (isOldData(newState)) return getDefaultSendData({profile, campaigns});

    if (isContextInvalid(newState, 'account_id', profile)) return getDefaultSendData({profile, campaigns});
    if (isContextInvalid(newState, 'campaign_id', profile)) return getDefaultSendData({profile, campaigns});
    if (isContextInvalid(newState, 'team_id', profile)) return getDefaultSendData({profile, campaigns});

    return newState;
};
