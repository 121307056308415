import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesome} from 'components/UI';

// Filler
const ComponentTransition = ({children, loading}) => {
    if (!loading) return children;

    return (
        <div style={{position: 'relative'}}>
            <div
                style={{
                    zIndex: 2000,
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                }}
            >
                <FontAwesome waiting size=" fa-3x" />
            </div>
            <div style={{opacity: 0.4, backgroundColor: 'grey'}}>{children}</div>
        </div>
    );
};

ComponentTransition.propTypes = {
    children: PropTypes.node,
    loading: PropTypes.bool,
};

export default ComponentTransition;
