import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'components/UI';

const FormFooter = ({children}) => {
    return (
        <div className="ant-modal-footer form-footer">
            <Form.Item>{children}</Form.Item>
        </div>
    );
};

FormFooter.propTypes = {
    children: PropTypes.any,
};

export default FormFooter;
