import React from 'react';

const icon = () => (
    <svg viewBox="0 0 512 513" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(0.000000, 0.500000)" fillRule="nonzero">
                <path
                    d="M512,256.5 C512,397.884 397.384,512.5 256,512.5 C114.616,512.5 0,397.884 0,256.5 C0,256.561 256,0.528 256,0.5 C397.384,0.5 512,115.116 512,256.5 Z"
                    fill="#0052B4"
                ></path>
                <g id="Group" fill="#F0F0F0">
                    <path d="M256,0.5 C255.986,0.5 255.971,0.5 255.957,0.5 L256,0.5 Z"></path>
                    <path d="M255.315,256.5 L256,256.5 C256,256.27 256,256.046 256,255.815 C255.772,256.044 255.544,256.272 255.315,256.5 Z"></path>
                    <path d="M256.001,134.065 C256.001,89.02 256.001,59.505 256.001,0.5 L255.957,0.5 C114.592,0.524 0,115.129 0,256.5 L133.565,256.5 L133.565,181.289 L208.777,256.5 L255.316,256.5 C255.544,256.272 255.773,256.044 256.001,255.815 C256.001,238.568 256.001,223.179 256.001,209.278 L180.788,134.065 L256.001,134.065 Z"></path>
                </g>
                <g id="Group" transform="translate(33.391000, 33.891000)" fill="#D80027">
                    <path d="M96.124,0 C56.085,22.799 22.799,56.085 2.84217094e-14,96.124 L2.84217094e-14,222.609 L66.783,222.609 L66.783,66.785 L66.783,66.783 L222.609,66.783 C222.609,45.72 222.609,25.654 222.609,0 L96.124,0 Z"></path>
                    <path d="M222.609,191.128 L131.656,100.175 C131.656,100.175 100.175,100.177 100.175,100.175 L100.175,100.177 L222.609,222.609 L222.609,222.609 C222.609,222.609 222.609,200.902 222.609,191.128 Z"></path>
                    <polygon points="409.939 156.568 415.464 173.574 433.345 173.574 418.879 184.084 424.405 201.089 409.939 190.58 395.472 201.089 400.998 184.084 386.531 173.574 404.412 173.574"></polygon>
                    <polygon points="346.02 279.002 354.309 304.512 381.13 304.512 359.43 320.276 367.72 345.785 346.02 330.02 324.32 345.785 332.61 320.276 310.91 304.512 337.731 304.512"></polygon>
                    <polygon points="348.434 78.655 355.342 99.914 377.693 99.914 359.609 113.049 366.517 134.307 348.434 121.168 330.352 134.307 337.259 113.049 319.177 99.914 341.527 99.914"></polygon>
                    <polygon points="286.931 155.826 295.219 181.336 322.041 181.336 300.341 197.1 308.631 222.609 286.931 206.844 265.231 222.609 273.521 197.1 251.821 181.336 278.642 181.336"></polygon>
                </g>
            </g>
        </g>
    </svg>
);

export default icon;
