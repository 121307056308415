import React, {Suspense} from 'react';
import PropTypes from 'prop-types';
import {Layout} from 'antd';
import usePrivateHooks from 'hooks/routes/usePrivateHooks';
import AuthedComponents from 'containers/Routes/PrivateRoutes/AuthedComponents';

const PageWrapper = ({children}) => {
    usePrivateHooks();

    return (
        <Layout className="page-wrapper" style={{minHeight: '100vh'}}>
            <AuthedComponents />

            <Suspense fallback={''}>{children}</Suspense>
        </Layout>
    );
};

PageWrapper.propTypes = {
    children: PropTypes.any.isRequired,
};

export default PageWrapper;
