const savedDesignsCampaignActions = {
  FETCH_SAVED_DESIGNS_CAMPAIGN_START: 'FETCH_SAVED_DESIGNS_CAMPAIGN_START',
  fetchSavedDesignsCampaignStart: (payload) => {
    return {
      type: savedDesignsCampaignActions.FETCH_SAVED_DESIGNS_CAMPAIGN_START,
      payload,
    };
  },

  FETCH_SAVED_DESIGNS_CAMPAIGN_SUCCESS: 'FETCH_SAVED_DESIGNS_CAMPAIGN_SUCCESS',
  fetchSavedDesignsCampaignSuccess: (savedDesigns) => ({
    type: savedDesignsCampaignActions.FETCH_SAVED_DESIGNS_CAMPAIGN_SUCCESS,
    payload: savedDesigns,
  }),

  FETCH_SAVED_DESIGNS_CAMPAIGN_FAILURE: 'FETCH_SAVED_DESIGNS_CAMPAIGN_FAILURE',
  fetchSavedDesignsCampaignError: (error) => ({
    type: savedDesignsCampaignActions.FETCH_SAVED_DESIGNS_CAMPAIGN_FAILURE,
    payload: error,
  }),
};

export default savedDesignsCampaignActions;
