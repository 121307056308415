export const privatePaths = {
    INTEGRATIONS_SYNC_JOBS: '/integrations/jobs',
    INTEGRATIONS_CREATE_SYNC_JOB: '/integrations/jobs/create',
    INTEGRATIONS_SYNC_JOB_EDIT: '/integrations/job/config/:id',
    INTEGRATIONS_SYNC_JOB_DETAILS: '/integrations/job/:id',
    INTEGRATION_CALLBACK: '/integration/callback/auth',
    IMPORT_CONTACT_CALLBACK: '/import/contacts/:integration',
    FINISH_COMPANY_SIGNUP: '/finish/sign-up',

    DASHBOARD: '/dashboard',
    CHANGE_TEAM: '/dashboard/change-team',

    CAMPAIGNS: '/campaigns',
    CAMPAIGN_DETAIL: '/campaigns/:id',

    RECIPIENTS: '/recipients',
    RECIPIENT_DETAILS: '/recipients/:id',
    RECIPIENT_IMPORT: '/recipients/import',

    GIFTOGRAMS: '/giftograms',
    CREATE: '/create',
    CREATE_DETAIL: '/create/:id',
    CUSTOM_DETAIL: '/create/:id/custom',
    CREATE_PRESET: '/create?preset=',
    CREATE_PRESET_DETAIL: 'create/:id?preset=',
    CREATE_DESIGNER: '/create/:productId/designer',
    CREATE_DESIGNER_CUSTOM: '/create/:productId/designer/custom',

    NOTIFICATIONS: '/notifications',

    GROUPS: '/groups',
    GROUP_DETAIL: '/groups/:id',

    SETTINGS: '/settings',
    SETTINGS_DETAIL: '/settings/:settingName/:submenu?/:submenu2?/:submenu3?',
    SETTINGS_DIGITAL_MESSAGES: '/settings/messages',
    SETTINGS_DIGITAL_SENDER: '/settings/sender',
    SETTINGS_USER: '/settings/user',
    SETTINGS_COMPANY: '/settings/company',
    SETTINGS_MEMBERS_INVITES: '/settings/members/invites',
    SETTINGS_MEMBERS: '/settings/members/list',
    SETTINGS_NOTIFICATIONS: '/settings/notifications',
    SETTINGS_TEAMS: '/settings/teams',
    SETTINGS_CREATE_TEAMS: '/settings/teams/create',
    SETTINGS_UPDATE_TEAMS: '/settings/teams/:submenu/update',
    SETTINGS_PAYMENTS: '/settings/payments',
    SETTINGS_SHIPPING: '/settings/shipping',
    SETTINGS_SECURITY: '/settings/security',
    SETTINGS_DESIGNS: '/settings/designs',
    SETTINGS_RECIPIENT_PROPERTIES: '/settings/properties',
    SETTINGS_ADMIN: '/settings/admin',
    SETTINGS_INTEGRATIONS: '/settings/integrations/active',
    SETTINGS_INTEGRATIONS_DIRECTORY: '/settings/integrations/directory',

    SETTINGS_API: {
        ROOT: '/settings/api',
        KEYS: '/settings/api/keys',
        CAMPAIGNS: '/settings/api/campaigns',
        SUBSCRIPTIONS: '/settings/api/subscriptions',
    },

    BRAND_KIT: {
        ROOT: '/settings/brand-kits',
        DETAILS: '/settings/brand-kits/:id/:tabName/:itemId?',
        EMAIL_PREVIEW: '/popup/brand-kits/:id/email-preview',
    },

    ORDERS: '/orders',
    ORDERS_TYPE: '/orders/:type',
    ORDERS_APPROVALS: '/orders/approvals',
    ORDERS_PHYSICAL: '/orders/physical',
    ORDERS_DIGITAL: '/orders/digital',
    ORDERS_DETAIL_DIGITAL: '/orders/digital/:type/:id',
    ORDERS_DETAIL_PHYSICAL: '/orders/physical/:id',
    ORDERS_ACTIVATION_PHYSICAL: '/orders/physical/:id/activation/:itemId',

    CREATE_CAMPAIGN: {
        ROOT: '/campaigns/create/:type',
        GENERAL: '/campaigns/create/:type/general',
        GIFTOGRAM: '/campaigns/create/:type/giftogram',
        RECIPIENTS: '/campaigns/create/:type/recipients',
        BILLING: '/campaigns/create/:type/billing',
        CLONE: '/campaigns/:campaignId/clone',
    },

    UPDATE_CAMPAIGN: {
        ROOT: '/campaigns/:id/update',
        GENERAL: '/campaigns/:id/update/general',
        GIFTOGRAM: '/campaigns/:id/update/giftogram',
        RECIPIENTS: '/campaigns/:id/update/recipients',
        BILLING: '/campaigns/:id/update/billing',
    },

    SEND: {
        ROOT: '/send',
        RECIPIENTS: {
            ROOT: '/send/recipients',
            INDIVIDUALS: '/send/recipients/individuals',
            GROUPS: '/send/recipients/groups',
            IMPORT: '/send/recipients/import',
        },
        MESSAGE: '/send/message',
        BILLING: '/send/billing',
        SCHEDULE: '/send/schedule',
    },

    SEND_SUCCESS: '/send/success',

    CHECKOUT: {
        ROOT: '/checkout',
        SHIPPING: '/checkout/shipping',
        BILLING: '/checkout/billing',
        REVIEW: '/checkout/review',
    },

    CHECKOUT_SUCCESS: '/checkout/success',

    BALANCE_HISTORY: '/balance',
    BALANCE_DETAIL: '/balance/:id',

    TRANSFER_FUNDS: '/transfer',
    DEPOSIT_FUNDS: '/deposit',
    REQUEST_FUNDS: '/request',

    EXPORTS: '/exports',
    EXPORTS_DOWNLOAD: '/exports/download',

    LOGIN_AS: '/loginAs/:id',
};
