const orderSummaryActions = {
  FETCH_ORDER_SUMMARY_START: 'FETCH_ORDER_SUMMARY_START',
  FETCH_ORDER_SUMMARY_SUCCESS: 'FETCH_ORDER_SUMMARY_SUCCESS',
  FETCH_ORDER_SUMMARY_ERROR: 'FETCH_ORDER_SUMMARY_ERROR',
  ADD_TO_ORDER_SUMMARY_START: 'ADD_TO_ORDER_SUMMARY_START',
  ADD_TO_ORDER_SUMMARY_SUCCESS: 'ADD_TO_ORDER_SUMMARY_SUCCESS',
  ADD_TO_ORDER_SUMMARY_ERROR: 'ADD_TO_ORDER_SUMMARY_ERROR',
  UPDATE_ORDER_SUMMARY_START: 'UPDATE_ORDER_SUMMARY_START',
  UPDATE_ORDER_SUMMARY_SUCCESS: 'UPDATE_ORDER_SUMMARY_SUCCESS',
  UPDATE_ORDER_SUMMARY_ERROR: 'UPDATE_ORDER_SUMMARY_ERROR',
  DELETE_FROM_ORDER_SUMMARY_START: 'DELETE_FROM_ORDER_SUMMARY_START',
  DELETE_FROM_ORDER_SUMMARY_SUCCESS: 'DELETE_FROM_ORDER_SUMMARY_SUCCESS',
  DELETE_FROM_ORDER_SUMMARY_ERROR: 'DELETE_FROM_ORDER_SUMMARY_ERROR',
  TOGGLE_VISIBLE_ORDER_SUMMARY: 'TOGGLE_VISIBLE_ORDER_SUMMARY',
  MODIFY_ORDER_FORM_ITEMS_START: 'MODIFY_ORDER_FORM_ITEMS_START',
  MODIFY_ORDER_FORM_ITEM_SUCCESS: 'MODIFY_ORDER_FORM_ITEM_SUCCESS',
  MODIFY_ORDER_FORM_DATA_ITEM_START: 'MODIFY_ORDER_DATA_ITEM_START_START',
  MODIFY_ORDER_FORM_DATA_ITEM_SUCCESS: 'MODIFY_ORDER_FORM_DATA_ITEM_SUCCESS',
  MODIFY_ORDER_FORM_DATA_ITEMS_START: 'MODIFY_ORDER_FORM_DATA_ITEMS_START',
  MODIFY_ORDER_DATA_START: 'MODIFY_ORDER_DATA_START',
  RESET_ORDER_FORM_DATA_START: 'RESET_ORDER_FORM_DATA_START',
  RESET_ORDER_SUMMARY: 'RESET_ORDER_SUMMARY',
  toggleVisibleOrderSummary: (payload) => ({
    type: orderSummaryActions.TOGGLE_VISIBLE_ORDER_SUMMARY,
    payload,
  }),
  fetchOrderSummaryStart: (payload) => ({
    type: orderSummaryActions.FETCH_ORDER_SUMMARY_START,
    payload,
  }),
  fetchOrderSummarySuccess: (orderSummary) => ({
    type: orderSummaryActions.FETCH_ORDER_SUMMARY_SUCCESS,
    payload: orderSummary,
  }),
  fetchOrderSummaryError: (error) => ({
    type: orderSummaryActions.FETCH_ORDER_SUMMARY_ERROR,
    payload: error,
  }),
  addToOrderSummaryStart: (payload) => ({
    type: orderSummaryActions.ADD_TO_ORDER_SUMMARY_START,
    payload,
  }),
  addToOrderSummarySuccess: () => ({
    type: orderSummaryActions.ADD_TO_ORDER_SUMMARY_SUCCESS,
  }),
  addToOrderSummaryError: (error) => ({
    type: orderSummaryActions.ADD_TO_ORDER_SUMMARY_ERROR,
    payload: error,
  }),

  deleteFromOrderSummaryStart: (payload) => ({
    type: orderSummaryActions.DELETE_FROM_ORDER_SUMMARY_START,
    payload,
  }),
  deleteFromOrderSummarySuccess: () => ({
    type: orderSummaryActions.DELETE_FROM_ORDER_SUMMARY_SUCCESS,
    payload: null,
  }),
  deleteFromOrderSummaryError: (error) => ({
    type: orderSummaryActions.DELETE_FROM_ORDER_SUMMARY_ERROR,
    payload: error,
  }),
  updateQuantityStart: (payload) => ({
    type: orderSummaryActions.UPDATE_ORDER_SUMMARY_START,
    payload,
  }),
  updateQuantitySuccess: () => ({
    type: orderSummaryActions.UPDATE_ORDER_SUMMARY_SUCCESS,
  }),
  updateQuantityError: (error) => ({
    type: orderSummaryActions.UPDATE_ORDER_SUMMARY_ERROR,
    payload: error,
  }),
  modifyDataStart: (payload) => ({
    type: orderSummaryActions.MODIFY_ORDER_DATA_START,
    payload,
  }),
  modifyFormItemsStart: (payload) => ({
    type: orderSummaryActions.MODIFY_ORDER_FORM_ITEMS_START,
    payload,
  }),
  modifyFormDataItemsStart: (payload) => ({
    type: orderSummaryActions.MODIFY_ORDER_FORM_DATA_ITEMS_START,
    payload,
  }),
  resetFormDataStart: () => ({
    type: orderSummaryActions.RESET_ORDER_FORM_DATA_START,
  }),
  resetOrderSummary: () => ({
    type: orderSummaryActions.RESET_ORDER_SUMMARY,
  }),
};

export default orderSummaryActions;
