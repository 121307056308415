const fundingItemActions = {
  FETCH_FUNDING_ITEM_START: 'FETCH_FUNDING_ITEM_START',
  FETCH_FUNDING_ITEM_SUCCESS: 'FETCH_FUNDING_ITEM_SUCCESS',
  FETCH_FUNDING_ITEM_ERROR: 'FETCH_FUNDING_ITEM_ERROR',
  fetchFundingItemStart: (payload) => ({
    type: fundingItemActions.FETCH_FUNDING_ITEM_START,
    payload: payload,
  }),
  fetchFundingItemSuccess: (payload) => ({
    type: fundingItemActions.FETCH_FUNDING_ITEM_SUCCESS,
    payload: payload,
  }),
  fetchFundingItemError: (error) => ({
    type: fundingItemActions.FETCH_FUNDING_ITEM_ERROR,
    payload: error,
  }),

  UPDATE_FUNDING_ITEM_START: 'UPDATE_FUNDING_ITEM_START',
  UPDATE_FUNDING_ITEM_SUCCESS: 'UPDATE_FUNDING_ITEM_SUCCESS',
  UPDATE_FUNDING_ITEM_ERROR: 'UPDATE_FUNDING_ITEM_ERROR',
  updateFundingItemStart: (payload) => ({
    type: fundingItemActions.UPDATE_FUNDING_ITEM_START,
    payload: payload,
  }),
  updateFundingItemSuccess: (payload) => ({
    type: fundingItemActions.UPDATE_FUNDING_ITEM_SUCCESS,
    payload: payload,
  }),
  updateFundingItemError: (error) => ({
    type: fundingItemActions.UPDATE_FUNDING_ITEM_ERROR,
    payload: error,
  }),
};

export default fundingItemActions;
