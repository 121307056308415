import React from 'react';

const icon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <circle cx="256" cy="256" r="256" fill="#f0f0f0"></circle>
        <g fill="#338af3">
            <path d="M256 0C154.506 0 66.81 59.065 25.402 144.696h461.195C445.19 59.065 357.493 0 256 0zM256 512c101.493 0 189.19-59.065 230.598-144.696H25.402C66.81 452.935 154.506 512 256 512z"></path>
        </g>
        <path
            fill="#ffda44"
            d="M332.515 256l-31.265 14.707 16.649 30.279-33.95-6.495-4.302 34.296L256 303.563l-23.648 25.224-4.301-34.296-33.95 6.494 16.648-30.279L179.485 256l31.265-14.707-16.649-30.278 33.949 6.494 4.303-34.296L256 208.437l23.648-25.224 4.301 34.296 33.951-6.494-16.649 30.279z"
        ></path>
    </svg>
);

export default icon;
