import couponActions from './actions';

import {LOGOUT} from 'redux/auth/auth.types';

const initialState = {
  data: null,
  search: null,
  loading: false,
  error: null,
};

const couponReducer = (state = initialState, action) => {
  switch (action.type) {
    case couponActions.FETCH_COUPON_START:
      return {
        ...state,
        search: action.payload,
        loading: true,
        error: null,
      };
    case couponActions.FETCH_COUPON_SUCCESS:
      return {
        ...state,
        data: action.payload,
        search: null,
        loading: false,
        error: null,
      };
    case couponActions.FETCH_COUPON_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case couponActions.CLEAR_COUPON:
    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default couponReducer;
