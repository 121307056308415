import {message} from 'antd';
import {CANCELLED_ERROR, THROTTLED_ERROR} from 'config/api';

const originalError = message.error.bind(message);

// Always suppress some errors when we blindly throw reactions

const errorWrapper = (content, duration, onClose) => {
    const contentString = content instanceof Error ? content.message : content;

    return ![THROTTLED_ERROR, CANCELLED_ERROR].includes(contentString)
        ? originalError(contentString, duration, onClose)
        : null;
};

message.config({
    duration: 4,
    maxCount: 1,
    transitionName: 'ant-move-down',
});

const reactions = {
    ...message,
    error: errorWrapper,
};

message.error = errorWrapper;

export {reactions};
export default message;
