import notificationsActions from './actions';
import {LOGOUT} from 'redux/auth/auth.types';

const initialState = {
    data: null,
    deleting: false,
    loading: false,
    error: null,
    loaded: false,
    query: {
        page: '1',
        per_page: '25',
        sort: 'last_name',
        sort_direction: 'asc',
    },
};

const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case notificationsActions.FETCH_NOTIFICATIONS_DATA_START:
            return {
                ...state,
                query: action.payload,
                loading: true,
                error: null,
                loaded: false,
                adding: null,
            };

        case notificationsActions.FETCH_NOTIFICATIONS_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null,
                loaded: true,
                adding: null,
            };

        case notificationsActions.FETCH_NOTIFICATIONS_DATA_ERROR:
            return {
                ...state,
                loading: null,
                error: action.payload,
                loaded: false,
                adding: null,
            };

        case notificationsActions.FETCH_NOTIFICATIONS_TYPES_SUCCESS:
            return {
                ...state,
                error: null,
                adding: null,
                types: {
                    data: action.payload,
                    loading: false,
                    loaded: true,
                },
            };

        case notificationsActions.MARK_NOTIFICATIONS_START:
            return {
                ...state,
                adding: true,
            };

        case notificationsActions.MARK_NOTIFICATIONS_SUCCESS: {
            const {items, status, unmark} = action.payload;

            return {
                ...state,
                adding: false,
                data: {
                    ...state.data,
                    data: state.data.data.map((notification) => {
                        if (Object.values(items).find((item) => item.id === notification.id)) {
                            return {
                                ...notification,
                                statuses: {
                                    ...notification.statuses,
                                    [status]: !unmark,
                                },
                            };
                        }

                        return notification;
                    }),
                },
            };
        }

        case notificationsActions.MARK_NOTIFICATIONS_ERROR: {
            return {
                ...state,
                adding: false,
                error: action.payload,
            };
        }
        case notificationsActions.UPDATE_NOTIFICATIONS_SUBSCRIPTION_DATA: {
            const {value, groupId} = action.payload;

            return {
                ...state,
                types: {
                    ...state.types,
                    data: [
                        ...state.types.data.map((type) => {
                            if (type.group_id === groupId) {
                                return {
                                    ...type,
                                    subscribed: value,
                                };
                            }

                            return type;
                        }),
                    ],
                },
            };
        }

        case notificationsActions.UPDATE_NOTIFICATIONS_SUBSCRIPTIONS_DATA: {
            const {value} = action.payload;

            return {
                ...state,
                types: {
                    ...state.types,
                    data: [...state.types.data.map((type) => ({...type, subscribed: value}))],
                },
            };
        }

        case notificationsActions.FETCH_NOTIFICATIONS_COUNT_SUCCESS:
            return {
                ...state,
                error: null,
                adding: null,
                count: {
                    data: action.payload,
                    loading: false,
                },
            };

        case notificationsActions.UPDATE_NOTIFICATIONS_COUNT:
            return {
                ...state,
                count: {
                    data: action.payload.unread,
                },
            };

        case LOGOUT:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default notificationsReducer;
