import {api} from 'library/helpers/api';
import {apiEndpoints} from 'config/api';
import {all, put, takeEvery} from 'redux-saga/effects';
import campaignActions, {
    SAVE_CAMPAIGN_START,
    SAVE_CAMPAIGN_SUCCESS,
    saveCampaignError,
    saveCampaignSuccess,
} from './actions';
import {getRoute, routeUrl} from 'library/helpers/routing';
import campaignsActions from 'redux/campaigns/actions';
import profileActions from 'redux/profile/actions';
import capitalize from 'lodash/capitalize';
import campaignsService from 'library/helpers/api/services/campaigns';
import {API_CAMPAIGN_TYPE_ID} from "config/campaigns";

function* fetchCampaignStart({payload}) {
    const url = getRoute(apiEndpoints.campaigns.show, {id: payload});

    try {
        const campaign = yield api.get(url).then((response) => response.data);
        yield put(campaignActions.fetchCampaignSuccess(campaign));
    } catch (error) {
        yield put(campaignActions.fetchCampaignError(error));
    }
}

function* updateSmart({payload}) {
    try {
        const url = getRoute(apiEndpoints.campaigns.updateSmart, {
            id: payload.id,
        });
        const campaign = yield api.put(url, payload.data).then((response) => response.data);
        yield put(campaignActions.updateSmartCampaignSuccess(campaign));

        //Refresh campaign list
        yield put(campaignsActions.fetchCampaignsStart());
        yield put(campaignsActions.listCampaignsStart());
    } catch (error) {
        yield put(campaignActions.updateSmartCampaignFailure(error));
    }
}

function* updateStandard({payload}) {
    try {
        const url = getRoute(apiEndpoints.campaigns.updateStandard, {
            id: payload.id,
        });

        const campaign = yield api.put(url, payload.data).then((response) => response.data);
        yield put(campaignActions.updateStandardCampaignSuccess(campaign));

        //Refresh campaign list
        yield put(campaignsActions.fetchCampaignsStart());
        yield put(campaignsActions.listCampaignsStart());
    } catch (error) {
        yield put(campaignActions.updateStandardCampaignFailure(error));
    }
}

function* reloadCampaigns() {
    yield put(campaignsActions.fetchCampaignsStart());
    yield put(campaignsActions.listCampaignsStart());
}

function* reloadCampaign(id) {
    // just make sure there's no funny business
    if (!id) return;

    yield campaignsService.setCampaignContext(id);
    yield put(profileActions.updateDataContext({campaign_id: id}));
    yield put(campaignActions.fetchCampaignStart(id));
}

function* save(type, action, payload, id = null) {
    // action and type will make up the endpoint
    const endpoint = action + capitalize(type);
    const route = apiEndpoints.campaignManagement?.[endpoint];

    // if route can't be found then we should continue.
    if (!route) {
        throw new Error('Invalid route');
    }
    // action is a synonym for method so set it
    const method = action === 'create' ? 'post' : 'put';

    // get the full route
    const url = routeUrl(route, {id});

    // this is an axios request config object
    const config = {
        method,
        url,
        data: payload,
    };

    try {
        const {data} = yield api(config);

        yield reloadCampaigns();

        if (!data.interval_months && data.company_campaign_type_id !== API_CAMPAIGN_TYPE_ID) yield reloadCampaign(data?.id);
    } catch ({data}) {
        return {error: data};
    }
}

function* handleError(error) {
    let message;

    // this can be shortened, but it's easier to read this way
    if (error?.errors) {
        message = error.errors;
    } else if (error?.message) {
        message = [error.message];
    }

    return yield put(saveCampaignError(message));
}

function* saveCampaign({payload}) {
    const {data, isAPI, isSmart, mode, id} = payload;
    const isCreate = mode === 'create';

    const type = isSmart ? 'smart' : isAPI ? 'api' : 'standard';
    const action = isCreate ? 'create' : 'update';

    const res = yield save(type, action, data, id);

    if (res?.error) {
        return yield handleError(res.error);
    }

    yield put(saveCampaignSuccess());
}

function* successfullySaved() {
    yield put(campaignsActions.fetchCampaignsStart());
}

function* getCampaignProducts({payload}) {
    const url = apiEndpoints.primary_products.campaign.replace(':campaignId', payload.campaignId);

    try {
        const products = yield api.get(url).then((response) => response.data);
        yield put(campaignActions.getCampaignProductsSuccess(products));
    } catch (error) {
        yield put(campaignActions.getCampaignProductsFailure(error));
    }
}

export default function* campaignSaga() {
    yield all([
        takeEvery(SAVE_CAMPAIGN_START, saveCampaign),
        takeEvery(SAVE_CAMPAIGN_SUCCESS, successfullySaved),
        takeEvery(campaignActions.GET_CAMPAIGN_PRODUCTS_START, getCampaignProducts),

        //old shit
        takeEvery(campaignActions.FETCH_CAMPAIGN_START, fetchCampaignStart),
        takeEvery(campaignActions.UPDATE_SMART_CAMPAIGN_DATA_START, updateSmart),
        takeEvery(campaignActions.UPDATE_STANDARD_CAMPAIGN_DATA_START, updateStandard),
    ]);
}
