import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Space, Input, Typography} from 'antd';

const {Paragraph, Text} = Typography;

const Content = ({props, execute, render, inputText}) => {
    const inputRef = useRef(null);

    // autofocus the input box
    useEffect(() => {
        inputRef.current.focus();
    }, []);

    return (
        <Space direction="vertical" size={8}>
            <Paragraph>
                <Text>
                    Please type <Text strong>{inputText.toUpperCase()}</Text> below to confirm.
                </Text>
            </Paragraph>

            <Input
                ref={inputRef}
                placeholder={inputText.toUpperCase()}
                size={'large'}
                onPaste={(evt) => evt.preventDefault()}
                onChange={(evt) => render({...props, inputValue: evt.target.value})}
                onKeyPress={(e) => {
                    if (e.key !== 'Enter') return;

                    execute();
                }}
            />
        </Space>
    );
};

Content.propTypes = {
    props: PropTypes.object,
    execute: PropTypes.func,
    render: PropTypes.func,
    inputText: PropTypes.string,
};

export default Content;
