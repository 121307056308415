const shippingAddressCampaignActions = {
  FETCH_SHIPPING_ADDRESSES_CAMPAIGN_START:
    'FETCH_SHIPPING_ADDRESSES_CAMPAIGN_START',
  FETCH_SHIPPING_ADDRESSES_CAMPAIGN_SUCCESS:
    'FETCH_SHIPPING_ADDRESSES_CAMPAIGN_SUCCESS',
  FETCH_SHIPPING_ADDRESSES_CAMPAIGN_ERROR:
    'FETCH_SHIPPING_ADDRESSES_CAMPAIGN_ERROR',
  fetchShippingAddressesCampaignStart: () => ({
    type:
      shippingAddressCampaignActions.FETCH_SHIPPING_ADDRESSES_CAMPAIGN_START,
  }),
  fetchShippingAddressesCampaignSuccess: (payload) => {
    return {
      type:
        shippingAddressCampaignActions.FETCH_SHIPPING_ADDRESSES_CAMPAIGN_SUCCESS,
      payload,
    };
  },
  fetchShippingAddressesCampaignError: (error) => ({
    type:
      shippingAddressCampaignActions.FETCH_SHIPPING_ADDRESSES_CAMPAIGN_ERROR,
    payload: error,
  }),
};

export default shippingAddressCampaignActions;
