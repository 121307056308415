import {all, put, takeEvery, takeLeading} from 'redux-saga/effects';
import fundingActions from './actions';
import {apiEndpoints} from 'config/api';
import {api} from 'library/helpers/api';
import {buildQuery} from 'library/helpers/routing';
import {processError} from 'library/helpers/response';
import profileActions from 'redux/profile/actions';
import descendantsActions from 'redux/descendants/actions';
import {appendKey} from 'library/helpers/utilities';
import procurementActions from 'redux/procurement/actions';

function* fetchFunding(query) {
    let url = apiEndpoints.funding.history;
    const funding = yield api.get(`${url}?${query}`).then((response) => response.data);
    funding.data = appendKey(funding.data);
    return funding;
}

function* fetchFundingDataEffect(fundData) {
    const query = buildQuery({sort: 'created', ...fundData.payload});

    try {
        const funding = yield fetchFunding(query);

        yield put(fundingActions.fetchFundingDataSuccess(funding));
    } catch (error) {
        yield put(fundingActions.fetchFundingDataError(processError(error)));
    }
}

function* submitAddFundingRequest({payload}) {
    try {
        const data = {
            amount: payload.amount,
            payment_method_id: payload.funding_method,
            payment_card_id: payload.card,
            customer_notes: payload.customer_notes,
            reference_number: payload.reference_number,
        };

        const response = yield api.post(apiEndpoints.funding.deposit, data);

        yield put(procurementActions.setRedirectUrl(response.data.punchout_redirect_url));
        yield put(fundingActions.submitAddFundingRequestSuccess(response.data));
    } catch (error) {
        yield put(fundingActions.submitAddFundingRequestError(error));
    }
}

function* submitRequestFunding({payload}) {
    try {
        const response = yield api.post(apiEndpoints.funding.request, payload);
        yield put(fundingActions.submitRequestFundingSuccess(response.data.message));
    } catch (error) {
        yield put(fundingActions.submitRequestFundingError(error));
    }
}

function* transferFunds({payload}) {
    try {
        yield api.post(apiEndpoints.funding.transfer, payload);
        yield put(fundingActions.submitTransferFundSuccess());
        yield put(descendantsActions.fetchAccountDescendantsStart());
        yield put(profileActions.fetchProfileDataStart({forceFetch: true}));
    } catch ({data}) {
        yield put(fundingActions.submitTransferFundsError(data));
    }
}

export default function* fundingSaga() {
    yield all([
        takeLeading(fundingActions.FETCH_FUNDING_DATA_START, fetchFundingDataEffect),
        takeEvery(fundingActions.ADD_FUNDS_START, submitAddFundingRequest),
        takeEvery(fundingActions.REQUEST_FUNDS_START, submitRequestFunding),
        takeEvery(fundingActions.TRANSFER_FUNDS_START, transferFunds),
    ]);
}
