import React from 'react';
import {AutoComplete as AntAutoComplete} from 'antd';
import {FontAwesome} from 'components/UI';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';

export const AutoComplete = (props) => {
    return (
        <div>
            <AntAutoComplete {...omit(props, ['forceClear'])} />
            {props.forceClear && (
                <div onClick={props.onClear}>
                    <FontAwesome
                        icon={'xmark'}
                        color={'rgba(153, 153, 153, 0.85)'}
                        style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '1rem',
                            top: '30%',
                            bottom: '25%',
                        }}
                    />
                </div>
            )}
        </div>
    );
};

// TODO: Deprecated, move to default params
AutoComplete.defaultProps = {
    forceClear: false,
};

AutoComplete.propTypes = {
    forceClear: PropTypes.bool,
    onClear: PropTypes.func,
};

export default AutoComplete;
