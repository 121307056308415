import {all, put, select, takeEvery} from 'redux-saga/effects';
import authenticationActions from 'redux/authentication/actions';
import {apiEndpoints} from 'config/api';
import {api} from 'library/helpers/api';
import appActions from 'redux/app/actions';
import {clearLocalStore} from 'library/helpers/localStore';
import {awsValidateSession} from 'library/vendor/aws';

function* startAuthentication({payload}) {
    // set auth cookie
    const cookie = yield retrieveCookie();

    // perform login
    if (cookie.status === 204) {
        try {
            const {status, data} = yield api.post(apiEndpoints.auth.login, payload);

            if (status === 200) {
                yield clearLocalStore();
                return yield put(authenticationActions.authenticationSuccess());
            }

            yield put(authenticationActions.authenticationError(data));
        } catch (e) {
            return yield put(authenticationActions.authenticationError(e));
        }
    }
    // auth failed
    // cookie failed
    yield put(authenticationActions.authenticationError(cookie));
}

function* setLogin() {
    yield put(authenticationActions.authenticationSuccess());
}

export const ping = async () => {
    return await awsValidateSession();
};

function* checkAuth() {
    const cookie = yield retrieveCookie();

    if (cookie.status === 204) {
        yield setLogin();
        //yield put(appActions.initialize());
        return;
    }

    const app = yield select((state) => state.app);
    if (app.initialized !== false) yield put(appActions.uninitialize());
}

function* retrieveCookie() {
    return yield api
        .get(apiEndpoints.auth.csrf)
        .then((res) => res)
        .catch((e) => console.log(e));
}

function* restartApp() {
    //yield bootApp(true);
}

export default function* authenticationSaga() {
    yield all([
        takeEvery(authenticationActions.AUTHENTICATION_CHECK, checkAuth),
        takeEvery(authenticationActions.AUTHENTICATION_START, startAuthentication),
        takeEvery(authenticationActions.REFRESH, retrieveCookie),
        takeEvery(appActions.UNINITIALIZE, restartApp),
        takeEvery(authenticationActions.PING, ping),
    ]);
}
