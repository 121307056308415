import {createSelector} from 'reselect';

const regions = (state) => state.regions;

export const getRegions = createSelector([regions], (regions) => regions);
export const getRegionsData = createSelector([regions], (regions) => regions.data);

export const getAllRegionsData = createSelector([regions], (regions) => regions.all);

export const regionsLoaded = createSelector([regions], (regions) => regions.loaded);

export const getIsMultiRegion = createSelector([regions], (regions) => regions?.data?.length > 1 || false);
