const appActions = {
    INITIALIZE: 'INITIALIZE',
    initialize: () => ({
        type: appActions.INITIALIZE,
    }),
    INITIALIZE_COMPLETE: 'INITIALIZE_COMPLETE',
    initializeComplete: () => ({
        type: appActions.INITIALIZE_COMPLETE,
        initialized: true,
    }),
    UNINITIALIZE: 'UNINITIALIZE',
    uninitialize: () => ({
        type: appActions.UNINITIALIZE,
    }),
    DEFAULT_STORES_LOADED: 'DEFAULT_STORES_LOADED',
    PING: 'PING',
    ping: () => ({
        type: appActions.PING,
    }),
};

export default appActions;
