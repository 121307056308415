import {getProfileData} from 'redux/selectors';
import {store} from 'redux/store';
import {ROLES} from 'config/roles';

export const addSuperAdminToTeam = (members) => {
    try {
        if (!members) return [];

        const profile = getProfileData(store.getState());

        if (!profile?.roles?.includes(ROLES.SUPER_ADMIN)) return members;
        if (members.find((item) => item.id === profile.id)) return members;

        return [...members, {...getProfileData(store.getState()), roles: null}];
    } catch (error) {
        console.error(error);
        return members;
    }
};
