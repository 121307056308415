import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Typography, Space, Ribbon, Skeleton, FontAwesome} from 'components/UI';
import {lang} from 'config/lang';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {goToRoute} from 'library/helpers/routing';

const {Text} = Typography;

const ProductCard = (props) => {
    const {image, linkTo, title, isCustom, removable, disabled, style} = props;
    const [imageLoaded, setImageLoaded] = useState(false);

    const setImageLoadedCallback = useMemo(() => {
        return {
            true: () => setImageLoaded(true),
        };
    }, [setImageLoaded]);

    const handleClick = useCallback(() => {
        if (disabled || !linkTo) return null;

        return goToRoute(linkTo);
    }, [disabled, linkTo]);

    return (
        <div
            className={
                'product-card-inner' + (removable || !linkTo ? ' removable' : '') + (disabled ? ' disabled' : '')
            }
            style={style}
        >
            <Space size={props.align === 'center' ? 24 : 16} direction="vertical">
                <div className={'card-wrapper' + (imageLoaded ? ' loaded' : '')}>
                    {isCustom && (
                        <Ribbon
                            text={
                                <Space size={3}>
                                    <FontAwesome icon="scribble" /> {lang.create.customized_tag}
                                </Space>
                            }
                            show={isCustom}
                        />
                    )}
                    <LazyLoadImage
                        alt="Giftogram card"
                        src={image}
                        onClick={handleClick}
                        className={!disabled && linkTo && !removable && 'clickable'}
                        afterLoad={setImageLoadedCallback['true']}
                        threshold={300}
                    />
                    {!imageLoaded && <Skeleton.Button active={true} shape={'square'} block={true} />}
                </div>

                <div className={'product-card-meta text-' + props.align}>
                    {title && (
                        <Skeleton title={true} paragraph={false} loading={!imageLoaded} active size="small">
                            <Text
                                strong
                                onClick={handleClick}
                                className={!disabled && linkTo && !removable ? 'clickable' : ''}
                                ellipsis={true}
                            >
                                {title}
                            </Text>
                        </Skeleton>
                    )}
                </div>
            </Space>
        </div>
    );
};

ProductCard.propTypes = {
    align: PropTypes.string,
    description: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.any,
    image: PropTypes.string,
    isCustom: PropTypes.bool,
    linkTo: PropTypes.string,
    removable: PropTypes.bool,
    style: PropTypes.object,
    title: PropTypes.string,
};

ProductCard.defaultProps = {
    align: 'center',
    disabled: false,
    style: {},
};

export default ProductCard;
