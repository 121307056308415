import React from 'react';
import PropTypes from 'prop-types';
import {Radio} from 'components/UI';
import {useField} from 'formik';
import {FormItem} from 'components/FormikForm';

const FormRadio = (props) => {
    // eslint-disable-next-line no-unused-vars
    const [field, meta, helpers] = useField(props.field.name);
    const groupProps = {...props};
    delete groupProps.options;
    delete groupProps.onChange;
    delete groupProps.activeOnSubmit;

    const setValue = (e) => {
        const {value} = e.target;

        helpers.setValue(value);

        if (props.onChange) props.onChange(value);
    };

    return (
        <FormItem label={props.label} type="input" {...props}>
            {field.value !== null && (
                <Radio.Group
                    {...field}
                    {...groupProps}
                    {...props.input}
                    {...props.ant}
                    onChange={setValue}
                    optionType={props.optionType}
                    options={props.options}
                />
            )}
        </FormItem>
    );
};

FormRadio.defaultProps = {
    activeOnSubmit: false,
    optionType: 'default',
};

FormRadio.propTypes = {
    activeOnSubmit: PropTypes.bool,
    field: PropTypes.any,
    label: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.any,
    optionType: PropTypes.any,
    input: PropTypes.object,
    ant: PropTypes.any,
};

export default FormRadio;
