import {all, takeEvery, takeLeading, put} from 'redux-saga/effects';
import savedDesignActions from './actions';
import {api} from 'library/helpers/api';
import {getRoute} from 'library/helpers/routing';
import {apiEndpoints} from 'config/api';

import savedDesignsCampaignActions from 'redux/savedDesignsCampaign/actions';

function* fetchSavedDesignData() {
    try {
        const savedDesigns = yield api.get(apiEndpoints.savedDesign.list).then((res) => res.data);

        yield put(savedDesignActions.fetchSavedDesignsSuccess(savedDesigns));
    } catch (error) {
        yield put(savedDesignActions.fetchSavedDesignsError(error));
    }
}

function* deleteSavedDesign({payload}) {
    try {
        yield api.delete(getRoute(apiEndpoints.savedDesign.delete, {id: payload}));

        yield put(savedDesignActions.deleteSavedDesignsSuccess());
        yield put(savedDesignActions.fetchSavedDesignsStart());
        yield put(
            savedDesignsCampaignActions.fetchSavedDesignsCampaignStart({
                forceFetch: true,
            }),
        );
        yield;
    } catch (error) {
        yield put(savedDesignActions.deleteSavedDesignsError(error));
    }
}

export default function* savedDesignsSaga() {
    yield all([
        takeLeading(savedDesignActions.FETCH_SAVED_DESIGN_DATA_START, fetchSavedDesignData),
        takeEvery(savedDesignActions.DELETE_SAVED_DESIGN_START, deleteSavedDesign),
    ]);
}
