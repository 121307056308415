import React from 'react';
import PropTypes from 'prop-types';
import {Space} from 'components/UI';
import {Switch as AntSwitch} from 'antd';

const Switch = (props) => {
    return (
        <Space>
            <AntSwitch {...props} />
            {props.label && props.label}
        </Space>
    );
};

Switch.propTypes = {
    ...AntSwitch.propTypes,
    label: PropTypes.string,
};

export default Switch;
