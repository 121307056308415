import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import {lang} from 'config/lang';
import {mainNav} from 'config/menus';
import usePermissions from 'hooks/auth/usePermissions';
import {FontAwesome, Menu, Space} from 'components/UI';
import {useSelector} from 'react-redux';
import {getInstalledIntegrations} from 'redux/integrations/selectors';
import isEmpty from 'lodash/isEmpty';

/**
 * @param {Object} menu
 * @return {Array<Object>}
 */
const getChildMenu = (menu) => Object.values(menu).map((m) => m);

const MainNavMenu = ({mode}) => {
    const installedIntegrations = useSelector(getInstalledIntegrations);

    const {pathname} = useLocation();
    const {canUser, isTeamAdmin, ready: permissionsReady} = usePermissions();

    const recipientsSubmenu = useMemo(() => {
        return {
            key: 'recipients-submenu',
            label: (
                <Space size={8}>
                    <span>{lang.recipients.title}</span>
                    {mode === 'horizontal' && <FontAwesome size="2xs" type="solid" icon="chevron-down" />}
                </Space>
            ),
            theme: 'light',
            children: getChildMenu(
                mainNav.getRecipientsSubmenu(
                    isTeamAdmin,
                    !isEmpty(installedIntegrations?.data?.data?.filter?.((data) => data?.integration)),
                ),
            ),
        };
    }, [installedIntegrations?.data?.data, isTeamAdmin, mode]);

    const historySubmenu = useMemo(() => {
        return {
            key: 'history-submenu',
            theme: 'light',
            label: (
                <Space size={8}>
                    <span>{lang.orders.title}</span>
                    {mode === 'horizontal' && <FontAwesome size="2xs" type="solid" icon="chevron-down" />}
                </Space>
            ),
            children: [
                ...getChildMenu(mainNav.getApprovalsSubmenu(isTeamAdmin)),
                mainNav.ordersSubmenu,
                ...getChildMenu(mainNav.historySubmenu),
            ],
        };
    }, [isTeamAdmin, mode]);

    /**
     * @type {Object[]}
     */
    const menuItems = useMemo(() => {
        if (!permissionsReady) return [mainNav.brandLogo, mainNav.loading];

        return [
            mainNav.brandLogo,
            canUser('send-gifts') && mainNav.create,
            recipientsSubmenu,
            mainNav.campaigns,
            historySubmenu,
            mainNav.support,
        ].filter(Boolean);
    }, [canUser, historySubmenu, permissionsReady, recipientsSubmenu]);

    return (
        <Menu
            theme="dark"
            mode={mode}
            defaultSelectedKeys={[pathname]}
            disabledOverflow
            triggerSubMenuAction="click"
            items={menuItems}
        />
    );
};

MainNavMenu.defaultProps = {
    mode: 'horizontal',
};

MainNavMenu.propTypes = {
    mode: PropTypes.string.isRequired,
};

export default MainNavMenu;
