import {all, put, takeEvery} from 'redux-saga/effects';
import sendActions from 'redux/send/actions';
import {apiEndpoints} from 'config/api';
import {api} from 'library/helpers/api';
import {getRoute} from 'library/helpers/routing';
import uploadActions from 'redux/upload/actions';
import isObject from 'lodash/isObject';
import formsActions from 'redux/forms/actions';
import {SEND_FORM} from 'config/forms';
import {match} from 'library/helpers/utilities';
import procurementActions from 'redux/procurement/actions';

function* submitDigitalOrder({payload}) {
    try {
        const {redirect, redirectTo} = payload;

        const route = getRoute(apiEndpoints.digitalOrders.submit, {
            type: match(payload.sendTo, {
                individuals: () => 'recipients',
                import: () => 'lists',
                groups: () => 'groups',
                default: () => payload.sendTo,
            }),
        });

        // enforce message is stringified
        if (isObject(payload.message.body)) {
            payload.message.body = JSON.stringify(payload.message.body);
        }

        // enforce save_new ruleset
        if (payload?.message?.save_new) {
            if (!payload?.message?.body || !payload?.message?.name) {
                payload.message.save_new = false;
            }
        }

        const productId = payload.extra.product_id;
        payload.card = {...payload.extra};
        delete payload.extra;

        const response = yield api.post(route, {...payload, product_id: productId});

        if (response.data.redirect_url) {
            yield put(procurementActions.setRedirectUrl(response.data.redirect_url));
            yield put(formsActions.reset(SEND_FORM));
            return;
        }

        redirect(redirectTo);
        yield put(formsActions.reset(SEND_FORM));
    } catch (e) {
        yield put(sendActions.submitError(e.data.errors || [e.data.message]));
    }
}

// move this
function* setBulkListId(action) {
    if (action.payload?.type !== 'bulk') return;

    yield put(formsActions.setValues(SEND_FORM, {bulk_list_id: action.payload.bulk_list_id ?? 0}));
}

export default function* sendSaga() {
    yield all([
        takeEvery(sendActions.SEND_SUBMIT, submitDigitalOrder),
        takeEvery(uploadActions.UPLOAD_SUCCESS, setBulkListId),
    ]);
}
