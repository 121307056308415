const fundingActions = {
    FETCH_FUNDING_DATA_START: 'FETCH_FUNDING_DATA_START',
    FETCH_FUNDING_DATA_SUCCESS: 'FETCH_FUNDING_DATA_SUCCESS',
    FETCH_FUNDING_DATA_ERROR: 'FETCH_FUNDING_DATA_ERROR',
    fetchFundingDataStart: (payload = {page: 1}) => ({
        type: fundingActions.FETCH_FUNDING_DATA_START,
        payload,
    }),
    fetchFundingDataSuccess: (payload) => ({
        type: fundingActions.FETCH_FUNDING_DATA_SUCCESS,
        payload,
    }),
    fetchFundingDataError: (error) => ({
        type: fundingActions.FETCH_FUNDING_DATA_ERROR,
        payload: error,
    }),

    ADD_FUNDS_START: 'ADD_FUNDS_START',
    ADD_FUNDS_SUCCESS: 'ADD_FUNDS_SUCCESS',
    ADD_FUNDS_ERROR: 'ADD_FUNDS_ERROR',
    submitAddFundingRequestStart: (payload) => ({
        type: fundingActions.ADD_FUNDS_START,
        payload,
    }),
    submitAddFundingRequestSuccess: (payload) => ({
        type: fundingActions.ADD_FUNDS_SUCCESS,
        payload,
    }),
    submitAddFundingRequestError: (error) => ({
        type: fundingActions.ADD_FUNDS_ERROR,
        payload: error,
    }),

    REQUEST_FUNDS_START: 'REQUEST_FUNDS_START',
    REQUEST_FUNDS_SUCCESS: 'REQUEST_FUNDS_SUCCESS',
    REQUEST_FUNDS_ERROR: 'REQUEST_FUNDS_ERROR',
    REQUEST_FUNDS_RESET: 'REQUEST_FUNDS_RESET',
    submitRequestFundingStart: (payload) => ({
        type: fundingActions.REQUEST_FUNDS_START,
        payload,
    }),
    submitRequestFundingSuccess: (payload) => ({
        type: fundingActions.REQUEST_FUNDS_SUCCESS,
        payload,
    }),
    submitRequestFundingError: (error) => ({
        type: fundingActions.REQUEST_FUNDS_ERROR,
        payload: error,
    }),
    resetRequestFunding: () => ({
        type: fundingActions.REQUEST_FUNDS_RESET,
    }),

    TRANSFER_FUNDS_START: 'TRANSFER_FUNDS_START',
    TRANSFER_FUNDS_SUCCESS: 'TRANSFER_FUNDS_SUCCESS',
    TRANSFER_FUNDS_ERROR: 'TRANSFER_FUNDS_ERROR',
    submitTransferFundsStart: (payload) => ({
        type: fundingActions.TRANSFER_FUNDS_START,
        payload,
    }),
    submitTransferFundSuccess: () => ({
        type: fundingActions.TRANSFER_FUNDS_SUCCESS,
    }),
    submitTransferFundsError: (error) => ({
        type: fundingActions.TRANSFER_FUNDS_ERROR,
        payload: error?.message,
    }),

    FUNDS_RESET_ERROR: 'FUNDS_RESET_ERROR',
    fundsResetError: () => ({
        type: fundingActions.FUNDS_RESET_ERROR,
    }),
};

export default fundingActions;
