import campaignActions, {
    RESET_CAMPAIGN_ERROR,
    SAVE_CAMPAIGN_ERROR,
    SAVE_CAMPAIGN_START,
    SAVE_CAMPAIGN_SUCCESS,
} from './actions';
import senderNamesActions from 'redux/senderNames/actions';
import campaignsActions from 'redux/campaigns/actions';

const initialState = {
    id: null,
    data: null,
    products: [],
    saved: false,
    loading: false,
    loaded: false,
    error: null,
    deleted: false,
    saving: false,
};

const campaignReducer = (state = initialState, action) => {
    switch (action.type) {
        // start the campaign saving
        // this will handle saving/update and determine smart/standard
        case SAVE_CAMPAIGN_START:
            return {
                ...state,
                saving: true,
                saved: false,
                error: null,
            };

        case SAVE_CAMPAIGN_ERROR:
            return {
                ...state,
                saving: false,
                saved: false,
                error: action.payload,
            };

        case SAVE_CAMPAIGN_SUCCESS:
            return {
                ...state,
                saving: false,
                saved: true,
                error: null,
            };

        case campaignActions.PUT_CAMPAIGN_DATA:
            return {
                ...state,
                data: {...state.data, ...action.payload},
            };

        case RESET_CAMPAIGN_ERROR:
            return {
                ...state,
                error: null,
            };

        // OLDER STUFF
        case campaignsActions.SAVE_SMART_CAMPAIGN_DATA_SUCCESS:
        case campaignsActions.SAVE_STANDARD_CAMPAIGN_DATA_SUCCESS:
            return {
                ...state,
                saving: false,
                saved: true,
                error: null,
            };
        case campaignsActions.SAVE_SMART_CAMPAIGN_DATA_FAILURE:
        case campaignsActions.SAVE_STANDARD_CAMPAIGN_DATA_FAILURE:
            return {
                ...state,
                saving: false,
                saved: false,
            };
        case campaignActions.FETCH_CAMPAIGN_START:
            return {
                ...state,
                data: null,
                loading: true,
                error: null,
                loaded: false,
            };
        case campaignActions.FETCH_CAMPAIGN_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null,
                loaded: true,
            };
        case campaignActions.FETCH_CAMPAIGN_ERROR:
            return {
                ...state,
                loading: null,
                error: action.payload,
                loaded: false,
            };

        case campaignActions.UPDATE_SMART_CAMPAIGN_DATA_START:
        case campaignActions.UPDATE_STANDARD_CAMPAIGN_DATA_START:
            return {
                ...state,
                error: null,
                loading: true,
                loaded: false,
                saved: false,
                saving: false,
            };
        case campaignActions.UPDATE_SMART_CAMPAIGN_DATA_SUCCESS:
        case campaignActions.UPDATE_STANDARD_CAMPAIGN_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: null,
                loading: false,
                saved: true,
                saving: false,
            };
        case campaignActions.UPDATE_SMART_CAMPAIGN_DATA_FAILURE:
        case campaignActions.UPDATE_STANDARD_CAMPAIGN_DATA_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
                saved: false,
            };

        case campaignActions.RESET_CAMPAIGN_SAVED_STATUS:
            return {
                ...state,
                saved: false,
                saving: false,
            };

        case campaignActions.RESET_CAMPAIGN_ERROR:
            return {
                ...state,
                error: null,
            };

        case campaignActions.CLEAR_CAMPAIGN:
        case senderNamesActions.DELETE_SENDER_NAMES_SUCCESS:
            return {
                ...initialState,
            };

        case campaignActions.GET_CAMPAIGN_PRODUCTS_START:
            return {
                ...state,
                products: [],
            };

        case campaignActions.GET_CAMPAIGN_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: action.payload,
            };
        case campaignActions.GET_CAMPAIGN_PRODUCTS_FAILURE:
            return {
                ...state,
                products: [],
                error: action.payload,
            };

        default:
            return state;
    }
};

export default campaignReducer;
