import React from 'react';

const icon = () => (
    <svg viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-0.000500, 0.000000)" fillRule="nonzero">
                <circle fill="#F0F0F0" cx="256.0005" cy="256" r="256"></circle>
                <path
                    d="M233.7395,256 C233.7395,256 75.1305,437.055 74.9805,437.019 C121.3065,483.346 185.3075,512 256.0005,512 C397.3845,512 512.0005,397.384 512.0005,256 L233.7395,256 Z"
                    fill="#D80027"
                ></path>
                <path
                    d="M74.9805,74.98 C-24.9935,174.954 -24.9935,337.045 74.9805,437.02 C116.2935,395.707 156.0265,355.974 256.0005,256 L74.9805,74.98 Z"
                    fill="#0052B4"
                ></path>
            </g>
        </g>
    </svg>
);

export default icon;
