import {all, put, takeEvery} from 'redux-saga/effects';
import fundingAmountActions from './actions';
import {fundingApiService} from "library/helpers/api/services/funding";

function* fetchFundingAmount({payload}) {
    try {
        const fundingAmount = yield fundingApiService
            .getFundingAmount(payload)
            .then((res) => res)
        ;

        yield put(fundingAmountActions.fetchFundingAmountSuccess(fundingAmount));
    } catch (error) {
        yield put(fundingAmountActions.fetchFundingAmountError(error));
    }
}

export default function* fundingMethodsSaga() {
    yield all([takeEvery(fundingAmountActions.FETCH_FUNDING_AMOUNT_START, fetchFundingAmount)]);
}
