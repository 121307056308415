const couponActions = {
  FETCH_COUPON_START: 'FETCH_COUPON_START',
  FETCH_COUPON_SUCCESS: 'FETCH_COUPON_SUCCESS',
  FETCH_COUPON_ERROR: 'FETCH_COUPON_ERROR',
  fetchCouponStart: (payload) => ({
    type: couponActions.FETCH_COUPON_START,
    payload,
  }),
  fetchCouponSuccess: (payload) => {
    return {
      type: couponActions.FETCH_COUPON_SUCCESS,
      payload,
    };
  },
  fetchCouponError: (payload) => ({
    type: couponActions.FETCH_COUPON_ERROR,
    payload,
  }),

  CLEAR_COUPON: 'CLEAR_COUPON',
  clearCoupon: () => ({
    type: couponActions.CLEAR_COUPON,
  }),
};

export default couponActions;
