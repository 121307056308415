import Bowser from 'bowser';
import {browserVersions} from 'config/browser';

const browser = Bowser.getParser(window.navigator.userAgent);
const data = browser.parsedResult;
const currentBrowserVersion = Number(parseInt(data.browser.version));

const minBrowserVersion = browserVersions[data.browser.name];

const isIE = data.engine.name === 'Trident';
const isOutdated = currentBrowserVersion < minBrowserVersion;

if (isIE) {
    window.location.href = 'https://appassets.giftogram.com/invalid-browser/unsupported_browser.html';

    throw new Error('Invalid Browser');
}

if (isOutdated) {
    window.location.href = 'https://appassets.giftogram.com/invalid-browser/update_browser.html';

    throw new Error('Invalid Browser');
}
