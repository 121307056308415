import {all, takeEvery, put} from 'redux-saga/effects';

import {fundingApiService} from 'library/helpers/api/services/funding';
import uploadActions from 'redux/upload/actions';
import {UPLOAD_FUNDS_TRANSFER} from 'config/upload';
import descendantActions from 'redux/descendants/actions';
import {fundingTransferActions} from 'redux/fundingTransfer/actions';

function* processUpload({payload}) {
    try {
        const response = yield fundingApiService.processUpload(payload);

        yield put(fundingTransferActions.processUploadTransferComplete(response));
        yield put(descendantActions.fetchTeamDescendantsStart());
    } catch (e) {
        yield put(fundingTransferActions.processUploadTransferError(e.message));
    }
}

function* processUploadComplete() {
    yield put(uploadActions.uploadReset({type: UPLOAD_FUNDS_TRANSFER}));
}

function* processUploadDeleteFile({payload}) {
    yield fundingApiService.deleteFile(payload);
}

export default function* fundingUploadSaga() {
    yield all([takeEvery(fundingTransferActions.PROCESS_UPLOAD_TRANSFER_START, processUpload)]);
    yield all([takeEvery(fundingTransferActions.PROCESS_UPLOAD_TRANSFER_DELETE_FILE, processUploadDeleteFile)]);
    yield all([takeEvery(fundingTransferActions.PROCESS_UPLOAD_TRANSFER_COMPLETE, processUploadComplete)]);
}
