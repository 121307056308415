import {all, put, takeEvery, takeLeading} from 'redux-saga/effects';
import creditCardsActions from './actions';
import {apiEndpoints} from 'config/api';
import {getRoute} from 'library/helpers/routing';
import {api} from 'library/helpers/api';
import campaignCreditCardsActions from 'redux/campaignCreditCards/actions';

function* fetchCreditCards() {
    try {
        let creditCards = yield api.get(apiEndpoints.creditCards.get);

        creditCards.data = mapFields(creditCards.data);
        yield put(creditCardsActions.fetchCreditCardsDataSuccess(creditCards.data));
    } catch (error) {
        yield put(creditCardsActions.fetchCreditCardsDataFailure(error));
    }
}

const mapFields = (creditCards) => {
    return creditCards.map((creditCard) => {
        creditCard.brand = creditCard.brand.toLowerCase();

        return creditCard;
    });
};

// TODO: Perhaps this shouldn't be in here, ditto w/ delete and maybe even update.
function* addNewCreditCard({payload}) {
    try {
        const response = yield api.post(apiEndpoints.creditCards.post, payload);
        let newCard = response.data;

        if (payload.save) {
            newCard = null;

            yield reFetch();

            yield put(
                campaignCreditCardsActions.fetchCampaignCreditCardsDataStart({
                    forceFetch: true,
                }),
            );
        }

        yield put(creditCardsActions.createCreditCardsDataSuccess(newCard));
    } catch (error) {
        yield put(creditCardsActions.createCreditCardsDataFailure(error));
    }
}

function* updateDefaultCreditCard({payload}) {
    try {
        yield api.put(getRoute(apiEndpoints.creditCards.put, {id: payload}), {
            is_default: true,
        });

        yield put(creditCardsActions.updateDefaultCreditCardsDataSuccess());
        yield reFetch();
    } catch (error) {
        yield put(creditCardsActions.updateDefaultCreditCardsDataFailure(error));
    }
}

function* reFetch() {
    yield put(creditCardsActions.fetchCreditCardsDataStart({forceFetch: true}));
}

function* deleteCreditCard({payload}) {
    try {
        yield api.delete(getRoute(apiEndpoints.creditCards.delete, {id: payload}));
        yield put(creditCardsActions.deleteCreditCardsDataSuccess());
        yield reFetch();
    } catch (error) {
        yield put(creditCardsActions.deleteCreditCardsDataFailure(error));
    }
}

function* updateDisplayName({payload}) {
    try {
        yield api.put(getRoute(apiEndpoints.creditCards.put, {id: payload.id}), {
            display_name: payload.display_name,
        });

        yield put(creditCardsActions.updateCreditCardDisplayNameSuccess(payload));
        yield reFetch();
    } catch (error) {
        yield put(creditCardsActions.updateCreditCardDisplayNameFailure(error));
    }
}

export default function* creditCardsSaga() {
    yield all([
        takeLeading(creditCardsActions.FETCH_CREDIT_CARDS_DATA_START, fetchCreditCards),
        takeEvery(creditCardsActions.UPDATE_DEFAULT_CREDIT_CARDS_START, updateDefaultCreditCard),
        takeEvery(creditCardsActions.UPDATE_CREDIT_CARD_DISPLAY_NAME_START, updateDisplayName),
        takeEvery(creditCardsActions.CREATE_CREDIT_CARDS_START, addNewCreditCard),
        takeEvery(creditCardsActions.DELETE_CREDIT_CARDS_START, deleteCreditCard),
    ]);
}
